<div class='card'>
	<kendo-gridlayout
		[rows]="['42.4px', '303px']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
			<div class="card-header">
				Schedule Performance Index <span style="font-size: 9px; position: relative; top: -1px;">(BETA) </span>
			</div>
			<tooltip-open type='{{isOverview ? "overview" : "spi"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
		</kendo-gridlayout-item>
		@if (noData) {
			<kendo-gridlayout-item [row]="2" [col]="1">
				<div class='warning-div'>
					No Actual Cost Data Available
				</div>
			</kendo-gridlayout-item>
		} @else {
			<kendo-gridlayout-item [row]='2' [col]='1'>
				<div style='height: 300px; margin-bottom: 3px'>
					<app-chart
						[categories]='categories'
						[seriesData]='seriesData'
						[legendVisible]='true'
						[valueAxis]='valueAxisItemSettings'
						[legendClickable]='true'
						[categoryPlotBands]="categoryPlotBands"
						[isSPIChart]="true"
					></app-chart>
				</div>
			</kendo-gridlayout-item>
		}
	</kendo-gridlayout>
</div>
