import {
	Component,
	OnInit,
	Output,
	ViewEncapsulation,
	Input,
	ViewChild,
	ViewContainerRef,
	TemplateRef,
	OnChanges,
	SimpleChanges,
	EventEmitter,
	ChangeDetectorRef,
	HostListener,
} from '@angular/core';
import { filterMenuOptions, PostMitigationRisk, PrePostMitigationRisk, RiskRegister } from '../../../../models/risk';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { ProjectInterface } from '../../../../models/Project';
import { RestService } from '../../../../services/common/rest.service';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import {
	allGroups,
	RiskRegisterColumn,
	RiskRegisterColumnGroup,
	RiskRegisterTableComponent,
} from './risk-register-table/risk-register-table.component';
import {
	DialogComponent,
	DialogRef,
	WindowComponent,
	WindowRef,
	WindowService,
	WindowState,
} from '@progress/kendo-angular-dialog';
import { NotificationRef } from '@progress/kendo-angular-notification';
import { areArraysEqualSets } from '../../../../util/strings';
import { NotificationService } from '../../../../services/common/notification.service';
import { StatusCheckService } from '../../../../services/infra/status-check.service';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { environment } from '../../../../environments/environment';
import { NavigationBarStorageService } from '../../../../services/common/navigation-bar-storage.service';
import { AppWindowService } from '../../../../services/common/window.service';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { columnsIcon, fileExcelIcon, filterIcon, importIcon, SVGIcon } from '@progress/kendo-svg-icons';

export interface RiskRegisterInfo {
	PreRiskScore?: number;
	PostRiskScore?: number;
	RiskScoreDelta?: number;
	StatusDate?: Date;
	PostRanking?: string;
}

@Component({
	selector: 'app-risk-register',
	templateUrl: './risk-register.component.html',
	styleUrls: ['./risk-register.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class RiskRegisterComponent implements OnInit {
	public icons = {
		columns: columnsIcon,
		filter: filterIcon,
		import: importIcon,
		excel: fileExcelIcon,
	};
	riskRegisters: Array<RiskRegister & RiskRegisterInfo> = [];
	filteredRiskRegisters: Array<RiskRegister & RiskRegisterInfo> = [];
	editingRiskRegister: RiskRegister;
	addRegisterOpen = false;
	nextRiskRegisterId = 0;
	expandedColumns = allGroups.groups || [];
	registersByActvCode = new Map<string, Set<number>>([]);
	isFormTouched = false;
	isFullscreenOpen = false;
	fullscreenWindowRef: WindowRef;
	hiddenColumns = {
		fullscreen: new Set<string>([]),
		riskPage: new Set<string>([]),
		scheduleSelector: new Set<string>([]),
	};
	_visibleColumns = {
		fullscreen: [],
		riskPage: [],
	};
	exportSubject: Subject<void> = new Subject<void>();
	allColumns = new Map<string, RiskRegisterColumn>([]);
	showColumnToggle = false;
	showFilterToggle = false;
	filterList = structuredClone(filterMenuOptions);
	selectedQuickFilter = 'allRisks';
	allGroups = allGroups;
	deleteWarningOpen = false;
	deletingRiskId = -1;
	fileName = '';
	notificationRef: NotificationRef;
	closeClicked = new BehaviorSubject(false);
	importDialogOpen = false;
	tableFilter: CompositeFilterDescriptor;
	isProduction = false;
	isRiskSettingsOpen: boolean = false;

	@Input() $projectData = new BehaviorSubject<ProjectInterface>(undefined);
	@Output() dialogStateChange = new EventEmitter<boolean>();
	dialogOpen: boolean = false;
	@ViewChild('riskRegisterContainer', { read: ViewContainerRef })
	public containerRef: ViewContainerRef;
	@ViewChild('riskRegisterContainerTitle', { read: TemplateRef })
	public titleRef: TemplateRef<any>;
	public defaultHiddenColumns = new Set<string>([]);
	public defaultVisibleColumns = new Set<string>([]);
	public columnToTreeViewCoord = new Map<string, string>([]);
	public riskSettingsCurrentlyOpen: boolean = false;

	constructor(
		private restService: RestService,
		public projectDashboardService: ProjectDashboardService,
		private sanitizer: DomSanitizer,
		private windowService: WindowService,
		private notificationService: NotificationService,
		public stateService: StatusCheckService,
		public navBarStorage: NavigationBarStorageService,
		public appWindowService: AppWindowService,
		private cdr: ChangeDetectorRef,
		public storage: ScheduleStorageService
	) {
		for (const group of allGroups.groups) {
			for (const column of group.columns) {
				column.group = group.title;
				if (column.default) {
					this.defaultVisibleColumns.add(column.field);
				} else {
					this.defaultHiddenColumns.add(column.field);
				}
				this.allColumns.set(column.field, column);
			}
		}
	}

	ngOnInit(): void {
		this.isProduction = environment.production;
		this.projectDashboardService.$currentProjectData.subscribe((projectData) => {
			const projectInfo = projectData as ProjectInterface;
			if (!projectInfo) {
				return;
			}
			this.fileName = 'RiskRegister_' + projectInfo.name + '.xlsx';
			this.riskRegisters = JSON.parse(JSON.stringify(projectInfo.riskMitigation || []));
			const matchingFilter = this.filterList.find((filter) => filter.name === this.selectedQuickFilter);
			this.quickFilterChange(matchingFilter);
			this._visibleColumns = {
				fullscreen: [],
				riskPage: [],
			};
			const userHiddenColumnsRiskPage = new Set<string>(
				projectInfo.preferences?.riskMitigation?.hiddenMitigationColumns?.riskPage || []
			);
			const userHiddenColumnsFullscreen = new Set<string>(
				projectInfo.preferences?.riskMitigation?.hiddenMitigationColumns?.fullscreen || []
			);
			for (let i = 0; i < allGroups.groups.length; i++) {
				const group = allGroups.groups[i];
				for (let j = 0; j < group.columns.length; j++) {
					const column = group.columns[j];
					column.group = group.title;
					const treeViewKey = `${i}_${j}`;
					this.columnToTreeViewCoord.set(column.field, treeViewKey);
					if (!column.default) {
						this.defaultHiddenColumns.add(column.field);
					}
					if (
						(userHiddenColumnsRiskPage.size > 0 && !userHiddenColumnsRiskPage.has(column.field)) ||
						(userHiddenColumnsRiskPage.size === 0 && column.default)
					) {
						this._visibleColumns.riskPage.push(treeViewKey);
					}
					if (
						(userHiddenColumnsFullscreen.size > 0 && !userHiddenColumnsFullscreen.has(column.field)) ||
						userHiddenColumnsFullscreen.size === 0
					) {
						this._visibleColumns.fullscreen.push(treeViewKey);
					}
					this.allColumns.set(column.field, column);
				}
			}
			this.hiddenColumns = {
				riskPage: userHiddenColumnsRiskPage.size ? userHiddenColumnsRiskPage : this.defaultHiddenColumns,
				fullscreen: userHiddenColumnsFullscreen,
				scheduleSelector: new Set<string>(
					projectData?.preferences?.riskMitigation?.hiddenMitigationColumns?.scheduleSelector || []
				),
			};
			this.createRegisterClasses();
		});
		this.appWindowService.$closeRiskRegisterWindows.subscribe((val) => {
			if (val) {
				this.closeAll();
			}
		});
	}

	createRegisterClasses(mitigations?: RiskRegister[]) {
		this.registersByActvCode.clear();
		this.riskRegisters = JSON.parse(JSON.stringify(mitigations || this.$projectData.value.riskMitigation || []));
		const matchingFilter = this.filterList.find((filter) => filter.name === this.selectedQuickFilter);
		this.quickFilterChange(matchingFilter);
		for (const register of this.riskRegisters) {
			if (register.riskId >= this.nextRiskRegisterId) {
				this.nextRiskRegisterId = register.riskId + 1;
			}
			register.preMitigation = new PrePostMitigationRisk(register.preMitigation);
			register.postMitigation = new PostMitigationRisk(register.postMitigation);
			const impactedTaskCodes = register.impactedTaskCodes || [];
			for (const code of impactedTaskCodes) {
				const codeRegisters = new Set<number>([...(this.registersByActvCode?.get(code) || [])]);
				codeRegisters.add(register.riskId);
				this.registersByActvCode.set(code, codeRegisters);
			}
			register.PreRiskScore = register.preMitigation.riskScore();
			register.PostRiskScore = register.postMitigation.riskScore(register.preMitigation.probability);
			register.RiskScoreDelta = register.PreRiskScore - register.PostRiskScore;
			register.PostRanking = register.postMitigation.postRanking();
			register.StatusDate = new Date(register.strategy.statusDate);
		}
		this.projectDashboardService.$currentRiskRegisters.next(this.riskRegisters);
	}

	toggleView() {
		this.showColumnToggle = false;
		this.showFilterToggle = false;
		this.isFullscreenOpen = !this.isFullscreenOpen;
		if (this.isFullscreenOpen) {
			if (!this.fullscreenWindowRef) {
				this.fullscreenWindowRef = this.windowService.open({
					appendTo: this.containerRef,
					title: 'Risk Registers',
					titleBarContent: this.titleRef,
					content: RiskRegisterTableComponent,
					state: 'maximized',
					cssClass: 'fullscreenRiskRegisterWindow',
				});
			}
			const riskRegistersTable = this.fullscreenWindowRef.content.instance;
			riskRegistersTable.riskRegisters = this.riskRegisters;
			riskRegistersTable.isFullscreen = true;
			riskRegistersTable.exportGrid = this.exportSubject.asObservable();
			riskRegistersTable.toggleColumnMenu = this.toggleColumnMenu;
			riskRegistersTable.toggleFilterMenu = this.toggleFilterMenu;
			riskRegistersTable.resetColumns = () => this.resetColumns(true);
			riskRegistersTable.resetFilters = () => this.resetFilters(true);
			riskRegistersTable.quickFilterChange = (filter) => this.quickFilterChange(filter);
			riskRegistersTable.showColumnToggle = this.showColumnToggle;
			riskRegistersTable.showFilterToggle = this.showFilterToggle;
			riskRegistersTable.isProduction = this.isProduction;
			riskRegistersTable.filterList = this.filterList;
			riskRegistersTable.selectedQuickFilter = this.selectedQuickFilter;
			riskRegistersTable.children = this.children;
			riskRegistersTable.hasChildren = this.hasChildren;
			riskRegistersTable.allColumns = this.allColumns;
			riskRegistersTable.defaultVisibleColumns = new Set<string>(this.columnToTreeViewCoord.keys());
			riskRegistersTable.columnToTreeViewCoord = this.columnToTreeViewCoord;
			riskRegistersTable.fileName = this.fileName;
			riskRegistersTable.editColumns = (columns: string[]) => this.setVisibleFullscreenColumns(columns);
			riskRegistersTable.saveRegister = this.saveRegister;
			riskRegistersTable.visibleColumns = this.visibleFullscreenColumns;
			riskRegistersTable.allHiddenColumns = this.hiddenColumns;
			const editEmitter = new EventEmitter<RiskRegister>();
			editEmitter.subscribe((register) => {
				this.editRegister(register);
			});
			riskRegistersTable.editRegister = editEmitter;
			const removeEmitter = new EventEmitter<RiskRegister>();
			removeEmitter.subscribe((register) => {
				this.deleteRisk(register.riskId);
			});
			riskRegistersTable.deleteRegister = removeEmitter;
			const importDialogEmitter = new EventEmitter<void>();
			importDialogEmitter.subscribe((val) => {
				this.openImportDialog();
			});
			riskRegistersTable.openImportDialogEmit = importDialogEmitter;
			const toggleEmitter = new EventEmitter<void>();
			toggleEmitter.subscribe((view) => {
				this.toggleView();
			});
			riskRegistersTable.toggleViewEmit = toggleEmitter;
			riskRegistersTable.tableFilter = this.tableFilter;
			this.dialogStateChanged(true);
		} else {
			this.fullscreenWindowRef.window.instance.cssClass = 'leaving-fullscreen';
			// wait .25 seconds for closing window animation to complete before actually closing the window
			setTimeout(() => {
				this.fullscreenWindowRef?.close();
				this.fullscreenWindowRef = undefined;
				this.dialogStateChanged(false);
			}, 250);
		}
	}

	requestFormClose() {
		this.editingRiskRegister = undefined;
		if (this.isFormTouched && this.storage.unsavedRiskRegisterFormChanges) {
			this.dialogOpen = true;
			this.appWindowService.unsavedChangesWindowOpen = true;
			this.appWindowService.setViewport('riskUnsavedChanges');
			this.dialogStateChanged(true);
		} else {
			this.addRegisterOpen = false;
			this.dialogStateChanged(false);
		}
		this.closeClicked.next(true);
	}

	setFormTouched(isTouched: boolean) {
		this.isFormTouched = isTouched;
	}

	editRegister(register: RiskRegister) {
		this.editingRiskRegister = register;
		this.addRegisterOpen = true;
		this.dialogStateChanged(true);
	}

	openNew() {
		this.editingRiskRegister = undefined;
		this.addRegisterOpen = true;
		this.dialogStateChanged(true);
	}

	saveRegister(register: RiskRegister) {
		if (!register) {
			this.saveRegisters();
			return;
		}
		register.preMitigation.activityImpact /= 100;
		register.postMitigation.activityImpact /= 100;
		register.preMitigation.impactVarianceMax /= 100;
		register.preMitigation.impactVarianceMin /= 100;
		register.postMitigation.impactVarianceMax /= 100;
		register.postMitigation.impactVarianceMin /= 100;
		let isExistingRegister = false;
		let maxId = 0;
		let hasActivityChanges = false;
		let hasImpactChanges = false;
		for (let i = 0; i < this.riskRegisters.length; i++) {
			if (this.riskRegisters[i].riskId === register.riskId) {
				const existingRegister = this.riskRegisters[i];
				const registerCodesFromProject = this.$projectData.value?.riskMitigation[i]?.impactedTaskCodes || [];
				if (!areArraysEqualSets(registerCodesFromProject, register.impactedTaskCodes || [])) {
					hasActivityChanges = true;
				}
				if (
					(existingRegister.preMitigation.activityImpact ?? 0) !== (register.preMitigation.activityImpact ?? 0) ||
					(existingRegister.preMitigation.impactVarianceMin ?? 0) !== (register.preMitigation.impactVarianceMin ?? 0) ||
					(existingRegister.preMitigation.impactVarianceMax ?? 0) !== (register.preMitigation.impactVarianceMax ?? 0) ||
					(existingRegister.postMitigation.impactVarianceMin ?? 0) !==
						(register.postMitigation.impactVarianceMin ?? 0) ||
					(existingRegister.postMitigation.impactVarianceMax ?? 0) !== (register.postMitigation.impactVarianceMax ?? 0)
				) {
					hasImpactChanges = true;
				}
				this.riskRegisters[i] = register;
				isExistingRegister = true;
				break;
			}
			if (this.riskRegisters[i].riskId > maxId) {
				maxId = this.riskRegisters[i].riskId;
			}
		}
		if (!isExistingRegister) {
			register.riskId = maxId + 1;
			this.riskRegisters.push(register);
			hasActivityChanges = register.impactedTaskCodes.length > 0;
		}
		this.saveRegisters(
			(hasActivityChanges && (hasImpactChanges || register.preMitigation.activityImpact > 0)) ||
				(hasImpactChanges && (hasActivityChanges || register.impactedTaskCodes.length > 0))
		);
		this.createRegisterClasses(this.riskRegisters);
		this.addRegisterOpen = false;
		this.dialogStateChanged(false);
		this.editingRiskRegister = null;
	}

	duplicateRegister(duplicationData: { register: RiskRegister; isFullscreen: boolean }): void {
		const duplicatedRegister = structuredClone(duplicationData.register);
		duplicatedRegister.riskId = this.nextRiskRegisterId;
		switch (duplicationData.isFullscreen) {
			case true: {
				this.editingRiskRegister = duplicatedRegister;
				break;
			}
			case false: {
				this.editRegister(duplicatedRegister);
				break;
			}
		}
		this.setFormTouched(true);
	}

	closeAll() {
		this.addRegisterOpen = false;
		this.dialogOpen = false;
		this.appWindowService.unsavedChangesWindowOpen = false;
		this.createRegisterClasses();
		this.isFormTouched = false;
		this.dialogStateChanged(false);
	}

	closeForm() {
		this.dialogOpen = false;
		this.appWindowService.unsavedChangesWindowOpen = false;
		this.dialogStateChanged(false);
	}

	exportToExcel() {
		this.exportSubject.next();
	}

	toggleColumnMenu() {
		this.showColumnToggle = !this.showColumnToggle;
		this.showFilterToggle = false;
	}

	/**
	 * toggles open state of filter menu and closes other possibly open menus
	 */
	toggleFilterMenu() {
		this.showFilterToggle = !this.showFilterToggle;
		this.showColumnToggle = false;
	}

	set visibleRiskPageColumns(columns: string[]) {
		this._visibleColumns.riskPage = columns;
		const visibleColumnFieldsRiskPage = new Set<string>([]);
		for (const col of columns) {
			const coords = col.split('_');
			if (coords.length === 2) {
				const column = allGroups.groups[coords[0]]?.columns?.[coords[1]];
				if (column) {
					visibleColumnFieldsRiskPage.add(column.field);
				}
			}
		}
		this.hiddenColumns.riskPage.clear();
		for (const [colField, column] of this.allColumns) {
			if (!visibleColumnFieldsRiskPage.has(colField)) {
				this.hiddenColumns.riskPage.add(colField);
			}
		}

		this.saveRegisters();
	}

	get visibleRiskPageColumns() {
		return this._visibleColumns.riskPage;
	}

	setVisibleFullscreenColumns(columns: string[]) {
		this._visibleColumns.fullscreen = columns;
		const visibleColumnFieldsFullscreen = new Set<string>([]);
		for (const col of columns) {
			const coords = col.split('_');
			if (coords.length === 2) {
				const column = allGroups.groups[coords[0]]?.columns?.[coords[1]];
				if (column) {
					visibleColumnFieldsFullscreen.add(column.field);
				}
			}
		}
		this.hiddenColumns.fullscreen.clear();
		for (const [colField, column] of this.allColumns) {
			if (!visibleColumnFieldsFullscreen.has(colField)) {
				this.hiddenColumns.fullscreen.add(colField);
			}
		}

		this.saveRegisters();

		this.fullscreenWindowRef.content.setInput('visibleColumns', columns);
	}

	set visibleFullscreenColumns(columns: string[]) {
		this.setVisibleFullscreenColumns(columns);
	}

	get visibleFullscreenColumns() {
		return this._visibleColumns.fullscreen;
	}

	deleteRisk(id: number) {
		this.deletingRiskId = id;
		this.deleteWarningOpen = true;
		this.appWindowService.deleteWindowOpen = true;
		this.appWindowService.setViewport('riskDelete');
		this.dialogStateChanged(true);
	}

	closeDeleteWarning(confirm: boolean) {
		this.deleteWarningOpen = false;
		this.appWindowService.deleteWindowOpen = false;
		this.dialogStateChanged(false);
		if (confirm) {
			const registerIndex = this.riskRegisters.findIndex((a) => a.riskId === this.deletingRiskId);
			const register = this.riskRegisters[registerIndex];
			if (register.impactedTaskCodes?.length) {
				for (const code of register.impactedTaskCodes) {
					const codeRegisters = this.registersByActvCode.get(code);
					codeRegisters.delete(this.deletingRiskId);
					this.registersByActvCode.set(code, codeRegisters);
				}
			}
			this.riskRegisters.splice(registerIndex, 1);
			this.saveRegisters(register.preMitigation.activityImpact > 0 && register.impactedTaskCodes.length > 0);
		}
	}

	saveRegisters(showRecalcNotification: boolean = false) {
		let unassignedCustomFields = this.$projectData.value?.preferences?.riskMitigation?.unassignedCustomFields;
		if (unassignedCustomFields === undefined) {
			unassignedCustomFields = {
				riskOwner: {
					name: [],
					email: [],
				},
				costOwner: {
					name: [],
					email: [],
				},
				responsibility: {
					name: [],
					email: [],
				},
				category: [],
			};
		}
		this.restService
			.post('project/riskregister/' + this.projectDashboardService.$currentProjectPageId.value, {
				data: this.riskRegisters,
				hiddenColumns: {
					riskPage: Array.from(this.hiddenColumns.riskPage),
					fullscreen: Array.from(this.hiddenColumns.fullscreen),
					scheduleSelector: Array.from(this.hiddenColumns.scheduleSelector),
				},
				unassignedCustomFields: {
					riskOwner: {
						name: Array.from(unassignedCustomFields?.riskOwner?.name) || [],
						email: Array.from(unassignedCustomFields?.riskOwner?.email) || [],
					},
					costOwner: {
						name: Array.from(unassignedCustomFields?.costOwner?.name) || [],
						email: Array.from(unassignedCustomFields?.costOwner?.email) || [],
					},
					responsibility: {
						name: Array.from(unassignedCustomFields?.responsibility?.name) || [],
						email: Array.from(unassignedCustomFields?.responsibility?.email) || [],
					},
					category: Array.from(unassignedCustomFields?.category) || [],
				},
			})
			.subscribe((resp) => {
				if (resp?.isErr) {
					console.log(resp);
				}
				this.createRegisterClasses(resp.proj?.riskMitigation);
				const projectData = this.$projectData.value;
				projectData.riskMitigation = this.riskRegisters;
				projectData.preferences.riskMitigation.hiddenMitigationColumns = {
					riskPage: Array.from(this.hiddenColumns.riskPage),
					fullscreen: Array.from(this.hiddenColumns.fullscreen),
					scheduleSelector: Array.from(this.hiddenColumns.scheduleSelector),
				};
				this.projectDashboardService.$currentProjectData.next(projectData);
				const projectReport = this.projectDashboardService.$currentProjectReport.value;
				projectReport.project = projectData;
				this.projectDashboardService.$currentProjectReport.next(projectReport);
			});
	}

	/**
	 * saves the imported registers and closes that dialog
	 * @param importedRegisters
	 */
	saveImportedRegisters(importedRegisters: RiskRegister[]): void {
		this.importDialogOpen = false;
		this.appWindowService.importWindowOpen = false;
		this.dialogStateChanged(false);
		importedRegisters.forEach((reg) => {
			const matchingIndex = this.riskRegisters.findIndex((r) => r.riskId === reg.riskId);
			if (matchingIndex === -1) {
				this.riskRegisters.push(reg);
			} else {
				//overwrite was chosen in import. remove old risk and add in new one
				this.riskRegisters[matchingIndex] = reg;
			}
		});
		const matchingFilter = this.filterList.find((filter) => filter.name === this.selectedQuickFilter);
		this.quickFilterChange(matchingFilter);
		this.saveRegisters();
	}

	resetColumns(isFullscreen?: boolean) {
		if (isFullscreen) {
			this.visibleFullscreenColumns = Array.from(this.columnToTreeViewCoord.values());
		} else {
			const columns = [];
			for (const col of this.defaultVisibleColumns) {
				columns.push(this.columnToTreeViewCoord.get(col));
			}
			this.visibleRiskPageColumns = columns;
		}
	}

	/**
	 * resets quick filter menu when the table filters are altered from the quick filter preset
	 */
	onQuickFilterOverride() {
		this.selectedQuickFilter = 'allRisks';
		this.tableFilter = {
			filters: [],
			logic: 'or', //normally should be 'and' but I am using this to not reset the filter list on the subscribe in the table component
		};
		this.showFilterToggle = false;
	}

	/**
	 * removes all filters and resets the list back to its original state
	 * @param isFullscreen
	 */
	resetFilters(isFullscreen?: boolean) {
		this.selectedQuickFilter = 'allRisks';
		const matchingFilter = this.filterList.find((filter) => filter.name === this.selectedQuickFilter);
		this.quickFilterChange(matchingFilter);
	}

	public children = (dataItem: any): Observable<RiskRegisterColumnGroup[]> => of(dataItem.columns);
	public hasChildren = (dataItem: any): boolean => !!dataItem.columns;
	public isDisabled = (dataItem: any) => dataItem.title === 'ID';
	freezeRecalcButtons: boolean = false;
	disableEdit = new BehaviorSubject<boolean>(false);

	doRefreshRecalc() {
		this.freezeRecalcButtons = true;
		const project = this.projectDashboardService.$currentProjectData.getValue();
		project.isMonteCarloDone = false;
		this.projectDashboardService.$currentProjectData.next(project);
		this.disableEdit.next(true);
		setTimeout(() => {
			this.dialogStateChanged(false);
			this.freezeRecalcButtons = false;
		}, 1000);
		this.restService.post(`report/calculate/${project._id}`, { onlySimulate: true }).subscribe(
			(val) => {
				this.disableEdit.next(false);
			},
			(response) => {
				console.log('POST call in error', response);
				this.disableEdit.next(false);
			},
			() => {
				this.disableEdit.next(false);
			}
		);
	}

	eeBool: boolean = false;
	eeCount: number = 0;
	intrvl: string[] = [];
	isEEVisible: boolean = false;

	@HostListener('window:keyup', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		if (event.key === 'F19') {
			const project = this.projectDashboardService.$currentProjectData.getValue();
			project.isMonteCarloDone = false;
			project.mcJobs = {};
			this.projectDashboardService.shotPut(project);
			this.notificationService.showNotification("Bippidy Boppity I've unlocked your property");
		}

		const word = 'sumatran';

		this.intrvl.push(event.key);
		if (this.intrvl.length > 8) {
			this.intrvl.shift();
		}

		if (this.intrvl.join('') === 'sumatran') {
			this.isEEVisible = true;
		} else {
			if (this.isEEVisible) {
				this.isEEVisible = false;
				this.eeCount = 0;
			}
		}
	}

	launchEE() {
		this.eeBool = true;
		this.eeCount = 0;
		clearInterval(this.stateService.tSSFunc);
	}

	openImportDialog(): void {
		this.importDialogOpen = true;
		this.appWindowService.importWindowOpen = true;
		this.appWindowService.setViewport('riskImport');
		this.dialogStateChanged(true);
	}

	closeImportDialog(): void {
		this.importDialogOpen = false;
		this.appWindowService.importWindowOpen = false;
		this.dialogStateChanged(false);
	}

	/**
	 * passes dialogStateChange value along
	 * @param newVal
	 */
	dialogStateChanged(newVal) {
		this.dialogStateChange.emit(newVal);
		if (newVal) {
			this.appWindowService.setViewport('riskAdd');
		}
	}

	stateChange(newVal) {
		this.riskSettingsCurrentlyOpen = newVal;
	}

	/**
	 * updates table filter based on quick filter preset
	 * @param filter
	 */
	quickFilterChange(filter) {
		this.selectedQuickFilter = filter.name;
		if (filter.name === 'allRisks') {
			this.tableFilter = {
				filters: [],
				logic: 'and',
			};
		} else {
			const newFilters = [];
			switch (filter.optionType) {
				case 'object': {
					filter.options.forEach((option) => {
						newFilters.push({
							field: filter.filterField,
							operator: 'eq',
							value: option,
						});
					});
					break;
				}
				case 'boolean': {
					newFilters.push({
						field: filter.filterField,
						operator: 'eq',
						value: true,
					});
					break;
				}
			}
			this.tableFilter = {
				filters: [
					{
						filters: newFilters,
						logic: 'or',
					},
				],
				logic: 'and',
			};
		}
		if (this.fullscreenWindowRef) {
			const riskRegistersTable = this.fullscreenWindowRef?.content?.instance;
			riskRegistersTable.tableFilter = this.tableFilter;
			riskRegistersTable.selectedQuickFilter = filter.name;
		}
	}

	/**
	 * forces window to stay within bounds of viewport
	 * @param window
	 */
	restrictMovement(window: string): void {
		this.appWindowService.restrictMovement(window, this.cdr);
	}
}
