<div class='card notes-visualizer-anchor regular-container' [class.saas-risk-component]='(this.userService.user | async)?.userType === "saasRisk"'>
	<kendo-gridlayout
		[rows]="['42.4px', 'calc(100% - 42.2px)']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
		style='page-break-inside: avoid'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container'>
			<kendo-gridlayout
				[rows]="['42.4px']"
				[gap]="{ rows: 0, cols: 2 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header">
						Comparison Report Summary
					</div>

				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='row-start-aligned-container' style='justify-content: flex-end; padding-right: 10px'>
						<div kendoTooltip style="text-align: center;display: flex; align-items: center; justify-content: center">
							<button kendoButton themeColor='base' title="Create Custom Comparison" class='header-btn'
											(click)='open(-1)'
											[imageUrl]='"/assets/icons/newIcons/noun-library-add-3643876.svg"'>
							</button>
					</div>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1' *ngIf="(projectService.$currentProjectReport | async)">
			<kendo-grid
				[kendoGridBinding]="comparisons"
				[size]='"small"'
				*ngIf='(needsRecalc | async) === false'
			>
				<kendo-grid-column field='Name' width='200'></kendo-grid-column>
				<kendo-grid-column field='Project'>
					<ng-template kendoGridCellTemplate let-dataItem>
						<div style='display: flex; justify-content: center'>
							{{ dataItem['Project'] }}
						</div>
					</ng-template>
				</kendo-grid-column>
				<kendo-grid-column field='Activities'>
					<ng-template kendoGridCellTemplate let-dataItem>
						<div style='display: flex; justify-content: center'>
							{{ dataItem['Activities'] }}
						</div>
					</ng-template>
				</kendo-grid-column>
				<kendo-grid-column field='Dates'>
					<ng-template kendoGridCellTemplate let-dataItem>
						<div style='display: flex; justify-content: center'>
							{{ dataItem['Dates'] }}
						</div>
					</ng-template>
				</kendo-grid-column>
				<kendo-grid-column field='Progress'>
					<ng-template kendoGridCellTemplate let-dataItem>
						<div style='display: flex; justify-content: center'>
							{{ dataItem['Progress'] }}
						</div>
					</ng-template>
				</kendo-grid-column>
				<kendo-grid-column field='Cost'>
					<ng-template kendoGridCellTemplate let-dataItem>
						<div style='display: flex; justify-content: center'>
							{{ dataItem['Cost'] }}
						</div>
					</ng-template>
				</kendo-grid-column>
				<kendo-grid-column field='Resources'>
					<ng-template kendoGridCellTemplate let-dataItem>
						<div style='display: flex; justify-content: center'>
							{{ dataItem['Resources'] }}
						</div>
					</ng-template>
				</kendo-grid-column>
				<kendo-grid-column field='Logic'>
					<ng-template kendoGridCellTemplate let-dataItem>
						<div style='display: flex; justify-content: center'>
							{{ dataItem['Logic'] }}
						</div>
					</ng-template>
				</kendo-grid-column>
				<kendo-grid-column title='View' class="no-padding center-align" width='60'>
					<ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
						<div style='display: flex; justify-content: center'>
							<button kendoButton fillMode='flat' (click)='open(rowIndex)' style="padding: 2px 4px;">
								<kendo-icon name='eye' size='xlarge'></kendo-icon>
							</button>
						</div>
					</ng-template>
				</kendo-grid-column>
				<kendo-grid-column title='Export' class="no-padding center-align" width='70'>
					<ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
						<div style='display: flex; justify-content: center'>
							<button kendoButton fillMode='flat' (click)='exportSheets([rowIndex])' style="padding: 2px 4px;">
								<kendo-icon name='excel' size='xlarge'></kendo-icon>
							</button>
						</div>
					</ng-template>
				</kendo-grid-column>
				<!--<kendo-grid-column title='Select'></kendo-grid-column>-->
			</kendo-grid>
			<div *ngIf='needsRecalc | async' style='padding: 10px; text-align: center'>
				<b>Project Recalculation Required</b>
			</div>
		</kendo-gridlayout-item>

	</kendo-gridlayout>
</div>
<kendo-window
	*ngIf="opened"
	(close)="close()"
	[minWidth]='700'
	[height]='740'
	[width]='1200'
	[(top)]="windowTop"

>
	<kendo-window-titlebar>
		<div style="font-size: 18px; line-height: 1.3em;">
			{{title}}
		</div>
		<div style='margin-right: 0; margin-left: auto'>
			<button kendoButton fillMode='flat' (click)='exportSheets([compareIndex])' [disabled]='loading || !comparisonData'>
				<kendo-icon name='excel' ></kendo-icon>
			</button>
			<button kendoWindowMaximizeAction></button>
			<button kendoWindowRestoreAction></button>
			<button kendoWindowCloseAction></button>
		</div>

	</kendo-window-titlebar>
	<div *ngIf='compareIndex === -1' style='grid-auto-flow: column; display: grid'>
		<div style='text-align: center'>
			<label for='xerFile1'>Upload Previous Schedule:</label>
			<br/>
			<input type="file" id='xerFile1' (change)="handleFileInput($event, 1)" accept=".xer" />
		</div>

		<div style='text-align: center'>
			<label for='xerFile2'>Upload Next Schedule:</label>
			<br/>
			<input type="file" id='xerFile2' (change)="handleFileInput($event, 2)" accept=".xer" />
		</div>
	</div>
	<div *ngIf='loading && compareIndex >= 0' style='display: flex; align-items: center; justify-content: center'>
		Loading...
	</div>
	<div *ngIf='!loading && !!comparisonData' style='display: grid; grid-template-columns: auto 1fr; grid-gap: 5px; height: 100%; overflow: hidden'>
		<div style='overflow-y: scroll; width: 330px'>
			<div style='display: grid; grid-auto-flow: row;' class='compare-btn-wrapper'>
				<!-- Activities/General -->
				<div>Activities</div>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.addedTasks.length'
								(click)='selectStat(comparisonData.addedTasks)'
								[attr.aria-pressed]="gridData === comparisonData.addedTasks">
					{{comparisonData.addedTasks.length}} Added Activities
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.deletedTasks.length'
								(click)='selectStat(comparisonData.deletedTasks)'
								[attr.aria-pressed]='gridData === comparisonData.deletedTasks'>
					{{comparisonData.deletedTasks.length}} Deleted Activities
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedNameTasks.length'
								(click)='selectStat(comparisonData.changedNameTasks)'
								[attr.aria-pressed]='gridData === comparisonData.changedNameTasks'>
					{{comparisonData.changedNameTasks.length}} Changed Activity Descriptions
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedCompleteTypeTasks.length'
								(click)='selectStat(comparisonData.changedCompleteTypeTasks)'
								[attr.aria-pressed]='gridData === comparisonData.changedCompleteTypeTasks'>
					{{comparisonData.changedCompleteTypeTasks.length}} Changed Percent Complete Types
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedTypeTasks.length'
								(click)='selectStat(comparisonData.changedTypeTasks)'
								[attr.aria-pressed]='gridData === comparisonData.changedTypeTasks'>
					{{comparisonData.changedTypeTasks.length}} Changed Activity Types
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedTargetDurTasks.length'
								(click)='selectStat(comparisonData.changedTargetDurTasks)'
								[attr.aria-pressed]='gridData === comparisonData.changedTargetDurTasks'>
					{{comparisonData.changedTargetDurTasks.length}} Changed Original Durations
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedCalendarTasks.length'
								(click)='selectStat(comparisonData.changedCalendarTasks)'
								[attr.aria-pressed]='gridData === comparisonData.changedCalendarTasks'>
					{{comparisonData.changedCalendarTasks.length}} Changed Activity Calendars
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedActvCodes.length'
								(click)='selectStat(comparisonData.changedActvCodes)'
								[attr.aria-pressed]='gridData === comparisonData.changedActvCodes'>
					{{comparisonData.changedActvCodes.length}} Changed Activity Codes
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedActvAssignments.length'
								(click)='selectStat(comparisonData.changedActvAssignments)'
								[attr.aria-pressed]='gridData === comparisonData.changedActvAssignments'>
					{{comparisonData.changedActvAssignments.length}} Changed Activity Code Assignments
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedWbs.length'
								(click)='selectStat(comparisonData.changedWbs)'
								[attr.aria-pressed]='gridData === comparisonData.changedWbs'>
					{{comparisonData.changedWbs.length}} Changed WBS Nodes
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedWbsAssignment.length'
								(click)='selectStat(comparisonData.changedWbsAssignment)'
								[attr.aria-pressed]='gridData === comparisonData.changedWbsAssignment'>
					{{comparisonData.changedWbsAssignment.length}} Changed WBS assignments
				</button>
				<br/>
				<div>Project</div>
				<!-- Project -->
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedCalendars.length'
								(click)='selectStat(comparisonData.changedCalendars)'
								[attr.aria-pressed]='gridData === comparisonData.changedCalendars'>
					{{comparisonData.changedCalendars.length}} Changed Calendars
				</button>

				<br/>
				<div>Progress</div>
				<!-- Progress -->
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedPctCompleteTasks.length'
								(click)='selectStat(comparisonData.changedPctCompleteTasks)'
								[attr.aria-pressed]='gridData === comparisonData.changedPctCompleteTasks'>
					{{comparisonData.changedPctCompleteTasks.length}} Changed Percent Completes
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedRemainingDurTasks.length'
								(click)='selectStat(comparisonData.changedRemainingDurTasks)'
								[attr.aria-pressed]='gridData === comparisonData.changedRemainingDurTasks'>
					{{comparisonData.changedRemainingDurTasks.length}} Changed Remaining Durations
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedTotalFloatTasks.length'
								(click)='selectStat(comparisonData.changedTotalFloatTasks)'
								[attr.aria-pressed]='gridData === comparisonData.changedTotalFloatTasks'>
					{{comparisonData.changedTotalFloatTasks.length}} Changed Total Floats
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedFreeFloatTasks.length'
								(click)='selectStat(comparisonData.changedFreeFloatTasks)'
								[attr.aria-pressed]='gridData === comparisonData.changedFreeFloatTasks'>
					{{comparisonData.changedFreeFloatTasks.length}} Changed Free Floats
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.shouldStartTasks.length'
								(click)='selectStat(comparisonData.shouldStartTasks)'
								[attr.aria-pressed]='gridData === comparisonData.shouldStartTasks'>
					{{comparisonData.shouldStartTasks.length}} Missed Start Dates
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.shouldFinishTasks.length'
								(click)='selectStat(comparisonData.shouldFinishTasks)'
								[attr.aria-pressed]='gridData === comparisonData.shouldFinishTasks'>
					{{comparisonData.shouldFinishTasks.length}} Missed Finish Dates
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedCriticalityTasks.length'
								(click)='selectStat(comparisonData.changedCriticalityTasks)'
								[attr.aria-pressed]='gridData === comparisonData.changedCriticalityTasks'>
					{{comparisonData.changedCriticalityTasks.length}} Changed Critical Status
				</button>
				<br/>
				<!-- Dates -->
				<div>Dates</div>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedActStartTasks.length'
								(click)='selectStat(comparisonData.changedActStartTasks)' [attr.aria-pressed]='gridData === comparisonData.changedActStartTasks'>
					{{comparisonData.changedActStartTasks.length}} Changed Actual Starts
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedActFinishTasks.length'
								(click)='selectStat(comparisonData.changedActFinishTasks)'
								[attr.aria-pressed]='gridData === comparisonData.changedActFinishTasks'>
					{{comparisonData.changedActFinishTasks.length}} Changed Actual Finishes
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.acceleratedStarts.length' (click)='selectStat(comparisonData.acceleratedStarts)'
								[attr.aria-pressed]='gridData === comparisonData.acceleratedStarts'>
					{{comparisonData.acceleratedStarts.length}} Accelerated Starts
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.acceleratedFinishes.length' (click)='selectStat(comparisonData.acceleratedFinishes)'
								[attr.aria-pressed]='gridData === comparisonData.acceleratedFinishes'>
					{{comparisonData.acceleratedFinishes.length}} Accelerated Finishes
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.delayedStarts.length' (click)='selectStat(comparisonData.delayedStarts)'
								[attr.aria-pressed]='gridData === comparisonData.delayedStarts'>
					{{comparisonData.delayedStarts.length}} Delayed Starts
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.delayedFinishes.length' (click)='selectStat(comparisonData.delayedFinishes)'
								[attr.aria-pressed]='gridData === comparisonData.delayedFinishes'>
					{{comparisonData.delayedFinishes.length}} Delayed Finishes
				</button>
				<br/>

				<!-- Logic -->
				<div>Logic</div>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.addedPredecessors.length'
								(click)='selectStat(comparisonData.addedPredecessors)'
								[attr.aria-pressed]='gridData === comparisonData.addedPredecessors'>
					{{comparisonData.addedPredecessors.length}} Added Relationships
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.deletedPredecessors.length'
								(click)='selectStat(comparisonData.deletedPredecessors)'
								[attr.aria-pressed]='gridData === comparisonData.deletedPredecessors'>
					{{comparisonData.deletedPredecessors.length}} Deleted Relationships
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.addedConstraintTasks.length'
								(click)='selectStat(comparisonData.addedConstraintTasks)'
								[attr.aria-pressed]='gridData === comparisonData.addedConstraintTasks'>
					{{comparisonData.addedConstraintTasks.length}} Added Constraints
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.deletedConstraintTasks.length' (click)='selectStat(comparisonData.deletedConstraintTasks)' [attr.aria-pressed]='gridData === comparisonData.deletedConstraintTasks'>
					{{comparisonData.deletedConstraintTasks.length}} Deleted Constraints
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedConstraintDateTasks.length' (click)='selectStat(comparisonData.changedConstraintDateTasks)' [attr.aria-pressed]='gridData === comparisonData.changedConstraintDateTasks'>
					{{comparisonData.changedConstraintDateTasks.length}} Changed Constraint Dates
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedLagPredecessors.length' (click)='selectStat(comparisonData.changedLagPredecessors)' [attr.aria-pressed]='gridData === comparisonData.changedLagPredecessors'>
					{{comparisonData.changedLagPredecessors.length}} Changed Relationship Lags
				</button>
				<br/>

				<!-- Costs/Resources -->
				<div>Costs/Resources</div>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedActvBudgetCost.length'
								(click)='selectStat(comparisonData.changedActvBudgetCost)'
								[attr.aria-pressed]='gridData === comparisonData.changedActvBudgetCost'>
					{{comparisonData.changedActvBudgetCost.length}} Changed Activity Budgeted Costs
				</button>
				<!--<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedActvActCost.length' (click)='selectStat(comparisonData.changedActvActCost)'
								[attr.aria-pressed]='gridData === comparisonData.changedActvActCost'>
					{{comparisonData.changedActvActCost.length}} Changed Activity Actual Costs
				</button>-->
<!--				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedActvForecastCost.length' (click)='selectStat(comparisonData.changedActvForecastCost)'
								[attr.aria-pressed]='gridData === comparisonData.changedActvForecastCost'>
					{{comparisonData.changedActvForecastCost.length}} Changed Activity Forecast Costs
				</button>-->
<!--				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedActvBudgetQty.length' (click)='selectStat(comparisonData.changedActvBudgetQty)' [attr.aria-pressed]='gridData === comparisonData.changedActvBudgetQty'>
					{{comparisonData.changedActvBudgetQty.length}} Changed Activity Budgeted Quantities
				</button>-->
<!--				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedActvActQty.length' (click)='selectStat(comparisonData.changedActvActQty)' [attr.aria-pressed]='gridData === comparisonData.changedActvActQty'>
					{{comparisonData.changedActvActQty.length}} Changed Activity Actual Quantities
				</button>-->
<!--				<button kendoButton fillMode="outline" [disabled]='!comparisonData.changedActvForecastQty.length' (click)='selectStat(comparisonData.changedActvForecastQty)' [attr.aria-pressed]='gridData === comparisonData.changedActvForecastQty'>
					{{comparisonData.changedActvForecastQty.length}} Changed Activity Forecast Quantities
				</button>-->
<!--				<button kendoButton fillMode="outline" [disabled]='!comparisonData.addedResources.length' (click)='selectStat(comparisonData.addedResources)' [attr.aria-pressed]='gridData === comparisonData.addedResources'>
					{{comparisonData.addedResources.length}} Added Resources
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.deletedResources.length' (click)='selectStat(comparisonData.deletedResources)' [attr.aria-pressed]='gridData === comparisonData.deletedResources'>
					{{comparisonData.deletedResources.length}} Deleted Resources
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.addedCostAccounts.length' (click)='selectStat(comparisonData.addedCostAccounts)' [attr.aria-pressed]='gridData === comparisonData.addedCostAccounts'>
					{{comparisonData.addedCostAccounts.length}} Added Cost Accounts
				</button>
				<button kendoButton fillMode="outline" [disabled]='!comparisonData.deletedCostAccounts.length' (click)='selectStat(comparisonData.deletedCostAccounts)' [attr.aria-pressed]='gridData === comparisonData.deletedCostAccounts'>
					{{comparisonData.deletedCostAccounts.length}} Deleted Cost Accounts
				</button>-->


			</div>
		</div>

		<div *ngIf='!!comparisonData' style="height: 100%; overflow: hidden; display: flex; flex-direction: column">
			<div>
				<h3 style="text-align: center">{{gridTitle}}</h3>
			</div>
				<kendo-grid
					#compareGrid
					id="compareGrid"
					[kendoGridBinding]="gridData"
					(dataStateChange)="onDataStateChange()"
					[sortable]='true'
					[resizable]="true"
					[autoSize]="true"
					*ngIf='!loadingGrid'
					style="flex-grow: 1; overflow: auto"
				>
					<ng-template kendoGridNoRecordsTemplate>
						Select a button to view
					</ng-template>
					<kendo-grid-column
						*ngFor='let column of gridColumns'
						[field]="column.field"
						[title]='column.title'
						[format]="column?.format"
						[width]="150"
						headerClass="header-class"
					>
					</kendo-grid-column>
				</kendo-grid>

			<h4 *ngIf='loadingGrid'>
				Loading...
			</h4>
		</div>
	</div>
</kendo-window>
