@if (((_projectDashboardService.$currentProjectReport | async)?.project?.riskMetricsType === 'riskRegister' || (_projectDashboardService.$currentProjectReport | async)?.project?.riskMetricsType === 'coreRisk') && currentProjectCompanyPermissions?.license !== 'ANALYTICS-BASIC-MPK' && currentProjectCompanyPermissions?.license !== 'ANALYTICS-BASIC-APK' && currentProjectCompanyPermissions?.license !== 'ANALYTICS-PRO-MPK' && currentProjectCompanyPermissions?.license !== 'ANALYTICS-PRO-APK') {
	<kendo-gridlayout
		[rows]="user?.userType === 'saasRisk' && !(_projectDashboardService.$currentProjectReport | async)?.updateIds?.length ? (activeRiskRegisters?.length > 5 ? ['auto', 'auto', 'auto', 'auto'] : ['auto', 'auto', 'auto']) : ['auto', 'auto']"
		[cols]="['100%']"
		[gap]="{ rows: 5, cols: 0 }"
		class='full-width full-height'
		[style.padding-bottom]='user?.userType === "saasRisk" ? "blue" : "5px"'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' *ngIf='activeRiskRegisters?.length > 5'>
			<app-top-risks [riskRegisters]='activeRiskRegisters'></app-top-risks>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='activeRiskRegisters?.length > 5 ? 2 : 1' [col]='1'>
			<app-risk-register [$projectData]='$projectData' (dialogStateChange)='dialogStateChanged($event)'></app-risk-register>
		</kendo-gridlayout-item>
		@if (((_projectDashboardService.$currentProjectReport | async)?.project?.riskMetricsType === 'riskRegister' || (_projectDashboardService.$currentProjectReport | async)?.project?.riskMetricsType === 'coreRisk') && (user?.userType !== 'saasRisk' || (_projectDashboardService.$currentProjectReport | async)?.project?.showMonteCarlo)) {
			<kendo-gridlayout-item [row]='activeRiskRegisters?.length > 5 ? 3 : 2' [col]='1'>
				<app-monte-carlo></app-monte-carlo>
			</kendo-gridlayout-item>
		}
		@if (user?.userType === 'saasRisk' && !(_projectDashboardService.$currentProjectReport | async)?.updateIds?.length) {
			<kendo-gridlayout-item [row]='activeRiskRegisters?.length > 5 ? 4 : 3' [col]='1'>
				<div class='saas-risk-upload-btn'>
					<button kendoButton size='large' class='shadow' (click)="openSaasRiskBaselineForm()" icon='file-add' title='Upload Schedule (XER)'>Upload Schedule (XER)</button>
				</div>
			</kendo-gridlayout-item>
		}
	</kendo-gridlayout>
}
@if ((_projectDashboardService.$currentProjectReport | async)?.project?.riskMetricsType === 'performanceFactor' && currentProjectCompanyPermissions?.license !== 'ANALYTICS-BASIC-MPK' && currentProjectCompanyPermissions?.license !== 'ANALYTICS-BASIC-APK' && currentProjectCompanyPermissions?.license !== 'ANALYTICS-PRO-MPK' && currentProjectCompanyPermissions?.license !== 'ANALYTICS-PRO-APK') {
	<kendo-gridlayout
		[rows]="['auto', 'auto', 'auto', 'auto']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='full-width full-height'
		style='padding-bottom: 5px;'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' *ngIf="(_projectDashboardService.$currentProjectReport | async)?.project?.riskMetricsType === 'performanceFactor'">
			<app-performance-factor></app-performance-factor>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
}
@if (user?.userType !== "saasRisk" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK") {
	<kendo-gridlayout
		[rows]="['auto', 'auto', 'auto']"
		[cols]="['100%']"
		[gap]="5"
		class='full-width full-height'
		style='margin-bottom: 5px'
	>
		<kendo-gridlayout-item [row]='1' [col]='1'>
			<app-index-trending></app-index-trending>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1'>
			<app-critical-path-risk></app-critical-path-risk>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='3' [col]='1'>
			<app-critical-path-reliability></app-critical-path-reliability>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
}
