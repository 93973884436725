import UpdateManager from './UpdateManager';
import { ProjectInterface } from '../models';
import { UpdateInterface } from '../../models/Update/Task';
import { ProjectReportInterface } from '../../models/ProjectReport/ProjectReport';
import { Calendar, XerActivity, XerTaskPredecessor } from '@rhinoworks/xer-parse';

export default class UpdateSeriesManager {
	public project: ProjectInterface;
	public updateManagers = new Map<string, UpdateManager>([]);
	public referenceReport: ProjectReportInterface;
	constructor() {}

	public get sortedManagers(): UpdateManager[] {
		return Array.from(this.updateManagers.values()).sort((a, b) => a.dataDate.getTime() - b.dataDate.getTime());
	}

	public get baseline(): UpdateManager | undefined {
		return this.sortedManagers[0];
	}

	public get current(): UpdateManager | undefined {
		const allManagers = this.sortedManagers;
		return allManagers[allManagers.length - 1];
	}

	public setUpdates(
		updates: UpdateInterface[],
		tasks: XerActivity[][],
		calendars: Map<number, Calendar>[],
		relationships: XerTaskPredecessor[][],
		report: ProjectReportInterface
	) {
		console.log('settings updates...', updates);
		let lastFinishMilestoneCode: string = null;
		for (let i = 0; i < updates.length; i++) {
			const update = updates[i];
			if (update.finishMilestone?.task_code) {
				lastFinishMilestoneCode = update.finishMilestone.task_code;
			}
			this.updateManagers.set(
				update._id,
				new UpdateManager({
					update,
					seriesManager: this,
					tasks: tasks[i],
					relationships: relationships[i],
					calendars: calendars[i],
					report,
				})
			);
			this.updateManagers.get(update._id).finishMilestoneCode = lastFinishMilestoneCode;
		}
		this.updateRelevantUpdates();
		console.log('done setting updates...');
	}

	public removeUpdate(...updates: UpdateInterface[]) {
		for (const update of updates) {
			this.updateManagers.delete(update._id);
		}
	}

	public updateRelevantUpdates() {
		if (this.updateManagers.size > 0) {
			this.baseline._taskSummary = this.baseline.tasksSummary;
		}
		if (this.updateManagers.size > 1) {
			if (this.updateManagers.size > 2) {
				this.current.previous._taskSummary = this.current.previous.tasksSummary;
			}
			this.current._taskSummary = this.current.tasksSummary;
		}
	}
}
