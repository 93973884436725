import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Inject,
	Input,
	OnInit,
	Output,
	Renderer2,
	ViewChild,
} from '@angular/core';
import { SelectEvent, StepperComponent } from '@progress/kendo-angular-layout';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RestService } from '../../../services/common/rest.service';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { FileUploadService } from '../../../services/common/fileUpload.service';
import { Update } from '../../../services/project/update/update.model';
import { UserService } from '../../../services/common/user.service';
import { DOCUMENT } from '@angular/common';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { ActivatedRoute, Router } from '@angular/router';
import { groupBy, orderBy } from '@progress/kendo-data-query';
import { ProjectDashboardService } from '../../../services/project/project.service';
import { AnalyticsDashboardService } from '../../../services/analytics/analytics.service';
import { ProjectInterface } from '../../../models/Project';
import { scheduleType } from '../../../util/projects';
import { NavigationBarStorageService } from '../../../services/common/navigation-bar-storage.service';
import { ProfileCompanyPermission } from '../../../models/auth/account-user';
import { caretAltDownIcon, xCircleIcon } from '@progress/kendo-svg-icons';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { UiStorageService } from '../../../services/common/ui-storage.service';

declare global {
	interface Window {
		initAutocomplete: () => void;
	}
}

interface WBSItem {
	id: number;
	parent_wbs_id: number | null;
	proj_flag: 'Y' | 'N';
	proj_id: number;
	seq_num: number;
	wbs_name: string;
	wbs_short_name: string;
}

interface WBSTreeNode {
	item: WBSItem;
	children: WBSTreeNode[];
}

@Component({
	selector: 'app-new-project',
	templateUrl: './new-project.component.html',
	styleUrls: ['./new-project.component.scss'],
})
export class NewProjectComponent implements OnInit, AfterViewInit {
	@ViewChild('window') public winWrapper: ElementRef;
	@Input() isFromProjectSettings: boolean = false;
	@Input() isEdit: boolean;
	@Input() isBaselineForm: boolean;
	@Input() hideSubmit: boolean;
	@Input() showMilestoneButtons: boolean;
	@Input() projectInfo: ProjectInterface;
	@Input() submitObservable: Observable<void>;
	@Input() afterSave: Observable<ProjectInterface>;
	@Output() canSaveEmit = new EventEmitter<boolean>();
	@Input() viewerDisabledMode: boolean = false;
	canSave: boolean = false;
	currentTab = 0;

	submitted = false;
	public currentStep = 0;
	public data: any = {
		avtar: [],
		files: [],
	};
	public icons = {
		caretDown: caretAltDownIcon,
		closeCircle: xCircleIcon,
	};
	@ViewChild('additionalMilestonesMultiselect') additionalMilestonesMultiselect: MultiSelectComponent;
	public fileUploaded = false;
	public wrapper: DOMRect;
	public height = 820;

	@ViewChild('stepper', { static: true })
	public stepper: StepperComponent;
	@ViewChild('projectDetails', { static: false }) projectDetails: ProjectDetailsComponent;
	@ViewChild('startMilestone', { static: false }) public startMilestone: any;

	private isStepValid = (index: number): boolean => this.getGroupAt(index).valid;

	private shouldValidate = (index: number): boolean =>
		this.isStepValid(index) && this.getGroupAt(index)?.touched && this.currentStep >= index;

	public steps = [
		{
			label: 'Details',
			isValid: this.isStepValid,
			validate: this.shouldValidate,
		},
		/*{
			label: 'Baseline',
			isValid: this.isStepValid,
			validate: this.shouldValidate,
			disabled: true,
		},*/
		{
			label: 'Start / Finish',
			isValid: this.isStepValid,
			validate: this.shouldValidate,
			disabled: true,
		},
		{
			label: 'Milestones / Risk',
			isValid: this.isStepValid,
			validate: this.shouldValidate,
			disabled: true,
			optional: true,
		},
		{
			label: 'Notes',
			isValid: this.isStepValid,
			validate: this.shouldValidate,
			disabled: true,
			optional: true,
		},
		{
			label: 'Submit',
			isValid: this.isStepValid,
			validate: this.shouldValidate,
			disabled: true,
		},
	];

	public form = null;

	fileUploadInProgress = false;
	uploadedUpdate: Update = null;
	baselineUpdate;
	selectedFiles = [];
	uploadedFiles;
	baselineUpdateId: string;

	startMilestoneList = [];
	finishMilestoneList = [];
	eligibilityData: any = {
		usage: 0,
		total: 0,
	};

	user: any = {
		userType: 'aegis',
	};
	addMilestoneFocused = false;
	currentProjectCompanyPermissions: ProfileCompanyPermission = null;
	_exceedsLicenseTaskLimit = false;
	zeroTasks = false;
	rmt = 'default';
	public virtual: any = {
		itemHeight: 28,
	};

	selectionDatabaseList: any[] = [];
	rawSelectionDatabaseList: any[] = [];
	selectionPCatList: any[] = [];
	rawSelectionPCatList: any[] = [];
	selectionPCatValList: any[] = [];
	rawSelectionPCatValList: any[] = [];
	selectionProjectList: any[] = [];

	disableParent = false;
	savedFromNewProject: boolean = false;
	savedSyncData;

	databaseInfo: any = {
		database: '',
		selectedPCat: '',
		selectedPCatVal: '',
		projectNumber: '',
		projectName: '',
		selectedId: '',
	};

	constructor(
		private httpClient: HttpClient,
		public fileUploadService: FileUploadService,
		private restService: RestService,
		public userService: UserService,
		private _renderer2: Renderer2,
		@Inject(DOCUMENT) private _document: Document,
		public router: Router,
		private route: ActivatedRoute,
		public projectService: ProjectDashboardService,
		public analyticsService: AnalyticsDashboardService,
		public navbarStorage: NavigationBarStorageService,
		private fb: FormBuilder,
		public uiStorage: UiStorageService,
		private cdr: ChangeDetectorRef
	) {
		this.restService.getAccount('v2/my/databases/').subscribe((res: any) => {
			this.selectionConnectionList = res;
			this.rawSelectionConnectionList = res;
			console.log(res);
			if (res.length === 1) {
				this.databaseInfo.selectedConnection = res[0];
				this.loadNewDatabasesToSelect(res[0]);
				this.cdr.detectChanges();
			}
			console.log('selectionConnectionList', this.selectionConnectionList);
		});

		this.form = this.fb.group({
			projectDetails: this.fb.group({
				baselineFile: this.fb.group({
					updateId: new FormControl('', Validators.required),
				}),
				//updateId: new FormControl('', Validators.required),
				projectCode: new FormControl('', Validators.required),
				projectName: new FormControl('', Validators.required),
				projectImage: new FormControl(this.data.avtar),
				projectType: new FormControl('', Validators.required),
				siteLocation: new FormControl('', Validators.required),
				lat: new FormControl(0),
				lng: new FormControl(0),
				sharepoint: new FormControl(''),
				isAegisManaged: new FormControl(true),
				memberIds: new FormControl([this.userService.user.value?._id], Validators.required),
				scheduleType: new FormControl(''),
				company: new FormControl(null, Validators.required),
				pocId: new FormControl({ value: null, disabled: true }, Validators.required),
				clientId: new FormControl({ value: null, disabled: true }, Validators.required),
			}),
			/*baselineFile: this.fb.group({
				updateId: new FormControl('', Validators.required),
			}),*/
			startFinish: this.fb.group({
				startMilestone: new FormControl(null, Validators.required),
				finishMilestone: new FormControl(null, Validators.required),
			}),
			startMilestone: new FormControl(null),
			finishMilestone: new FormControl(null),
			additionalMilestones: new FormControl([]),
			riskMetricsType: new FormControl('default', Validators.required),
			riskRegisterGlobalImpactMin: new FormControl(10),
			riskRegisterGlobalImpactMax: new FormControl(10),
			criticalPathSummary: new FormControl(''),
			impactsIssuesTiaNotes: new FormControl(''),
			aegisGeneratedSchedule: new FormControl(true, Validators.required),
			selected_proj_id: new FormControl(null),
		});
		this.userService?.user.subscribe((data) => {
			if (data) {
				this.user = data;
			}
		});
		//key listener to stop backspace from deleting additional milestones
		window.addEventListener(
			'keydown',
			(e) => {
				if (e.key === 'Backspace' && this.currentStep === 2 && this.addMilestoneFocused) {
					e.stopImmediatePropagation();
					e.preventDefault();
				}
			},
			true
		);
	}

	ngOnInit(): void {
		this.fileUploadService.data = {
			_id: '',
		};
		this.updateFormValues();
		if (this.userService.user.value.userType === 'aegis') {
			this.projectService.fetchAllProjects().then(() => {});
		}

		this.submitObservable?.subscribe(() => {
			this.submit();
			this.emitCanSave();
		});
		this.projectService.sqlProjects.subscribe(() => {
			this.emitCanSave();
		});
		this?.afterSave?.subscribe(() => {
			this.submitted = false;
		});
	}

	ngAfterViewInit(): void {
		if (this.winWrapper) {
			this.wrapper = this.winWrapper.nativeElement.getBoundingClientRect();
			this.height = this.wrapper.height - 10;
		}

		if (AnalyticsDashboardService.isGoogleMapsScriptLoaded) {
			this.projectDetails?.initAutocomplete();
		} else if (this.isEdit) {
			window.initMap = () => {
				AnalyticsDashboardService.isGoogleMapsScriptLoaded = true;
				console.log('Google Maps script loaded');
				this.initMap();
				this.projectDetails?.initAutocomplete();
			};
			const script = this._renderer2.createElement('script');
			script.type = `text/javascript`;
			script.src =
				'https://maps.googleapis.com/maps/api/js?key=AIzaSyDLqs4B7aCg4mZv8SAO4bLr6Oy8zD1qKTw&v=beta&libraries=marker,places&callback=initMap';
			this._renderer2.appendChild(this._document.body, script);
		}
		this.form.valueChanges.subscribe((newValues) => {
			this.updateStepState();
			this.emitCanSave();
			this.rmt = this.form.getRawValue().riskMetricsType;
		});
		// adjusts styles of new project form to make only form content scrollable, not entire window (other styles to make this work on template)
		const newProjectWindow = document.getElementById('newProjectFormWindow');
		if (newProjectWindow) {
			const contentContainer = newProjectWindow.querySelectorAll('.k-window-content')[0];
			if (contentContainer) {
				if (!this.isEdit) {
					contentContainer.classList.add('hide-overflow');
				}
			}
		}
		if (this.user?.userType === 'saasRisk') {
			this.initFormForSaasRiskUsers();
		}
	}

	/**
	 * update enabled state of steps based on form page being filled out
	 */
	updateStepState(): void {
		if (this.steps.length) {
			this.steps[1].disabled = !this.form.controls.projectDetails.valid;
			this.steps[2].disabled = !this.form.controls.projectDetails.valid || !this.form.controls.startFinish.valid;
			this.steps[3].disabled = this.steps[2].disabled;
			if (this.user?.userType !== 'saasRisk') {
				this.steps[4].disabled = this.steps[2].disabled;
			}
		}
	}

	/**
	 * update form construction to the saasRisk version of the new project form
	 */
	initFormForSaasRiskUsers(): void {
		this.form = this.fb.group({
			projectDetails: this.fb.group({
				baselineFile: this.fb.group({
					updateId: new FormControl(''),
				}),
				projectCode: new FormControl(''),
				projectName: new FormControl('', Validators.required),
				projectImage: new FormControl(this.data.avtar),
				projectType: new FormControl('', Validators.required),
				siteLocation: new FormControl('', Validators.required),
				lat: new FormControl(0),
				lng: new FormControl(0),
				sharepoint: new FormControl(''),
				isAegisManaged: new FormControl(true),
				memberIds: new FormControl([this.userService.user.value?._id], Validators.required),
				scheduleType: new FormControl(''),
				company: new FormControl(null, Validators.required),
				pocId: new FormControl({ value: null, disabled: true }, Validators.required),
				clientId: new FormControl({ value: null, disabled: true }, Validators.required),
			}),
			startFinish: this.fb.group({
				startMilestone: new FormControl(null),
				finishMilestone: new FormControl(null),
			}),
			startMilestone: new FormControl(null),
			finishMilestone: new FormControl(null),
			additionalMilestones: new FormControl([]),
			riskMetricsType: new FormControl('riskRegister', Validators.required),
			riskRegisterGlobalImpactMin: new FormControl(10),
			riskRegisterGlobalImpactMax: new FormControl(10),
			criticalPathSummary: new FormControl(''),
			impactsIssuesTiaNotes: new FormControl(''),
			aegisGeneratedSchedule: new FormControl(true, Validators.required),
			selected_proj_id: new FormControl(null),
		});
		if (this.isBaselineForm) {
			this.form.controls.projectDetails.controls.baselineFile.controls.updateId.setValidators(Validators.required);
			this.form.controls.projectDetails.controls.baselineFile.controls.updateId.updateValueAndValidity();
		}
		this.steps = [
			{
				label: 'Details',
				isValid: this.isStepValid,
				validate: this.shouldValidate,
			},
			{
				label: 'Start / Finish',
				isValid: this.isStepValid,
				validate: this.shouldValidate,
				disabled: true,
			},
			{
				label: 'Notes',
				isValid: this.isStepValid,
				validate: this.shouldValidate,
				disabled: true,
				optional: true,
			},
			{
				label: 'Submit',
				isValid: this.isStepValid,
				validate: this.shouldValidate,
				disabled: true,
			},
		];
		if (this.isBaselineForm) {
			this.updateFormValues();
		}
	}

	initMap() {
		this.projectDetails?.initAutocomplete();
	}

	public get currentGroup(): FormGroup {
		return this.getGroupAt(this.currentStep);
	}

	public next(): void {
		this.addMilestoneFocused = false;
		if (this.currentGroup.valid && this.currentStep !== this.steps.length) {
			this.currentStep += 1;
			if (this.user.userType === 'saasRisk' && this.currentStep === 1) {
				this.form.controls.startFinish.controls.startMilestone.setValidators(
					this.fileUploadService.data.xerFileName === undefined ? null : Validators.required
				);
				this.form.controls.startFinish.controls.finishMilestone.setValidators(
					this.fileUploadService.data.xerFileName === undefined ? null : Validators.required
				);
				this.form.controls.startFinish.controls.startMilestone.updateValueAndValidity();
				this.form.controls.startFinish.controls.finishMilestone.updateValueAndValidity();
				if (this.fileUploadService.data.xerFileName === undefined) {
					this.currentStep = 3;
				}
			}
		}

		this.currentGroup.markAllAsTouched();
		this.stepper?.validateSteps();
	}

	public prev(): void {
		this.addMilestoneFocused = false;
		this.currentStep -= 1;
		if (
			this.user.userType === 'saasRisk' &&
			this.currentStep === 2 &&
			this.fileUploadService.data.xerFileName === undefined
		) {
			this.currentStep = 0;
		}
	}

	/**
	 * update company permissions based on user company selection in details page of form
	 * @param newCompany
	 */
	updateCompanyPermissions(newCompany: number | undefined): void {
		this.currentProjectCompanyPermissions =
			newCompany === undefined ? null : this.navbarStorage.companyPermissionMap.get(newCompany);
		this._exceedsLicenseTaskLimit = this.exceedsLicenseTaskLimit;
		if (this._exceedsLicenseTaskLimit) {
			alert(
				`Maximum number of activities exceeded for company license: ${this.uploadedUpdate.xer.sortedActivities.length}/10000`
			);
		}
	}

	public emitCanSave() {
		const newDetails = this.projectDetailsGroup.getRawValue();
		const prevDetails = this.projectInfo;
		const differentDetails =
			newDetails.company !== prevDetails?.company ||
			newDetails.clientId !== prevDetails?.clientId ||
			newDetails.pocId !== prevDetails?.pocId ||
			newDetails.lat !== prevDetails?.lat ||
			newDetails.lng !== prevDetails.lng ||
			newDetails.projectType !== prevDetails?.projectType ||
			newDetails.scheduleType !== scheduleType(prevDetails) ||
			newDetails.projectCode !== prevDetails?.projectCode ||
			newDetails.projectName !== prevDetails?.name;
		const formDetails = this.form.getRawValue();
		const canSave =
			!(
				!formDetails.projectDetails.projectCode ||
				!formDetails.projectDetails.projectName ||
				!formDetails.projectDetails.company ||
				!formDetails.projectDetails.projectType ||
				!formDetails.projectDetails.pocId ||
				!formDetails.projectDetails.clientId ||
				!formDetails.projectDetails.lng ||
				!formDetails.projectDetails.lat ||
				!formDetails.projectDetails.siteLocation ||
				this.submitted ||
				(!this.projectService.sqlProjects.value.size && this.userService.user.value?.userType === 'aegis')
			) && differentDetails;
		this.canSave = canSave;
		this.canSaveEmit.emit(canSave);
	}

	public async submit(): Promise<void> {
		window.removeEventListener(
			'keydown',
			(e) => {
				if (e.key === 'Backspace' && this.currentStep === 2 && this.addMilestoneFocused) {
					e.stopImmediatePropagation();
					e.preventDefault();
				}
			},
			true
		);
		const existingClients = this.userService.clients.value;
		this.userService.newCompanyNoClients = false;
		const promises: Promise<void>[] = [];
		if (
			this.form.value.projectDetails.company &&
			this.form.value.projectDetails.clientId &&
			!existingClients.has(this.form.value.projectDetails.clientId)
		) {
			promises.push(
				new Promise<void>((resolve) =>
					this.restService
						.postAccount('v2/api/client/', undefined, undefined, {
							name: this.form.value.projectDetails.clientId,
							parent: this.form.value.projectDetails.company,
							viewerEmails: [],
						})
						.subscribe((results: any) => {
							this.projectDetailsGroup.patchValue({ clientId: results.client.id });
							resolve();
						})
				)
			);
		}
		await Promise.all(promises);
		const form = this.form.getRawValue();
		if (this.isEdit || this.isBaselineForm) {
			if (
				!form.projectDetails.projectCode ||
				!form.projectDetails.projectName ||
				!form.projectDetails.company ||
				!form.projectDetails.clientId ||
				!form.projectDetails.projectType ||
				!form.projectDetails.pocId ||
				!form.projectDetails.lng ||
				!form.projectDetails.lat ||
				!form.projectDetails.siteLocation ||
				!form.projectDetails.company ||
				(!this.canSave && !this.isBaselineForm)
			) {
				return;
			} else {
				const formData = this.form.getRawValue();
				const milestones = [];
				for (let i = 0; i < formData.additionalMilestones.length; i++) {
					milestones.push(formData.additionalMilestones[i].value || formData.additionalMilestones[i]);
				}
				const aegisPoc = this.projectService.availableAegisPocs.find((poc) => poc._id === form.projectDetails.pocId);
				this.submitted = true;
				setTimeout(() => {
					this.navbarStorage.showingNewProject = false;
				}, 500);
				if (this.isBaselineForm) {
					this.fileUploadService.additionalMilestonesFormControl.setValue(milestones);
					const newBaseline = this.fileUploadService.createBaselineUpdate(this.projectInfo._id, this.baselineUpdateId);
					if (newBaseline) {
						this.submitted = false;
					}
				} else {
					this.restService
						.post(`project/${this.projectInfo._id}`, {
							name: form.projectDetails.projectName,
							projectCode: form.projectDetails.projectCode,
							projectType: form.projectDetails.projectType,
							lat: form.projectDetails.lat,
							lng: form.projectDetails.lng,
							siteAddressReference: form.projectDetails.siteLocation,
							scheduleType: form.projectDetails.scheduleType,
							pocId: form.projectDetails.pocId,
							company: form.projectDetails.company,
							clientId: form.projectDetails.clientId,
						})
						.subscribe(
							(val) => {
								val.project._id;
							},
							(response) => {
								console.log('POST call in error', response);
							},
							() => {
								this.submitted = false;
							}
						);
				}
			}
			return;
		}
		if (!this.currentGroup.valid) {
			this.currentGroup.markAllAsTouched();
			this.stepper?.validateSteps();
		}
		if (this.form.valid) {
			this.submitted = true;
			const formData = this.form.getRawValue();
			const milestones = [];
			if (formData.projectDetails.baselineFile.updateId) {
				if (this.user?.userType !== 'saasRisk') {
					localStorage.setItem('updatesAdded', JSON.stringify([0]));
					localStorage.setItem('newUpdatesLength', JSON.stringify(1));
				}
				this.uploadedUpdate.data._id = this.form.getRawValue().projectDetails.baselineFile.updateId;
				formData.startMilestone = formData.startFinish.startMilestone.value || formData.startFinish.startMilestone;
				formData.finishMilestone = formData.startFinish.finishMilestone.value || formData.startFinish.finishMilestone;
				for (let i = 0; i < formData.additionalMilestones.length; i++) {
					milestones.push(formData.additionalMilestones[i].value || formData.additionalMilestones[i]);
				}
			}
			const availProjects = Array.from(this.uploadedUpdate?.xer.projects.values());
			const selectedProjectId =
				this.form.getRawValue().selected_proj_id || availProjects?.length
					? availProjects[availProjects.length - 1].id
					: null;
			this.navbarStorage.showingNewProject = false;
			this.restService
				.put('project', {
					data: { ...formData, additionalMilestones: milestones },
					update: {
						_id: this.form.getRawValue().projectDetails.baselineFile.updateId,
						taskArray: this.fileUploadService.xer.sortedActivities.map((actv) => actv.raw_entry),
						additionalFinishMilestones: [],
						additionalTrackingMilestones: this.fileUploadService.additionalMilestonesFormControl.value,
						finishMilestone: this.fileUploadService.data.finishMilestone,
						startMilestone: this.fileUploadService.data.startMilestone,
						generalNotes: this.fileUploadService.generalNotes,
						criticalPathSummary: this.fileUploadService.criticalPathSummary,
						impactsIssuesTiaNotes: this.fileUploadService.impactsIssuesTiaNotes,
						isAegisGeneratedSchedule: this.fileUploadService.data.isAegisGeneratedSchedule,
						xerFileName: this.uploadedUpdate?.data.xerFileName,
						xerFileBuffer: this.uploadedUpdate?.data.xerFileBuffer,
						isBaselineUpdate: true,
						updateName: 'Baseline',
						selectedProjectId,
						viewerEmails: existingClients.get(this.form.getRawValue().projectDetails.clientId)?.viewerEmails || [],
					},
				})
				.subscribe(
					(resp) => {
						const projectId = resp.project._id;
						if (projectId) {
							if (this.savedFromNewProject) {
								const savedData = this.savedSyncData;
								savedData.actions[0].analytics_project_id = projectId;
								this.restService.postAccountDbSync('api/v1/schedules/', {}, false, savedData).subscribe((res: any) => {
									const hasExistingSyncSettings = true;
									this.restService
										.patch(`project/${projectId}`, {
											hasExistingSyncSettings,
										})
										.subscribe((val) => {
											console.log('patch res', val.project.hasExistingSyncSettings);
										});

									console.log(res);
								});
							}

							this.router.navigate([`/project/${projectId}`], {
								relativeTo: this.route,
							});
						}
					},
					(err) => {
						console.log('error', err);
					}
				);
		}
	}

	private getGroupAt(index: number): FormGroup {
		const groups = Object.keys(this.form.controls).map((groupName) => this.form.get(groupName)) as FormGroup[];
		return groups[index];
	}

	async makeFileReadyForUpload(event) {
		this.getGroupAt(1).markAsTouched();
		this.uploadedUpdate = null;
		const file = event.target.files[0];
		const update = new Update();
		this.zeroTasks = false;
		await update.handleFileSelect(event);
		await this.fileUploadService.handleFileSelect(event);
		this.fileUploadService.xerFile = event;
		this.uploadedUpdate = update;
		if (update.xer.sortedActivities.length <= 0) {
			alert('Imported schedule has no activities. Select another schedule to continue.');
			this.zeroTasks = true;
		}
		if (update.xer.projects.size === 1) {
			this.projectChange([...update.xer.projects.keys()][0]);
		}
		this._exceedsLicenseTaskLimit = this.exceedsLicenseTaskLimit;
		if (this._exceedsLicenseTaskLimit) {
			alert(
				`Maximum number of activities exceeded for company license: ${this.uploadedUpdate.xer.sortedActivities.length}/10000`
			);
		}
		this.selectedFiles = Object.values(event.target.files);
		this.uploadXERFiles(this.uploadedUpdate, event.target.files);
	}

	projectChange(ev: number) {
		this.fileUploadService.assignTasks(
			this.fileUploadService.xer.sortedActivities.filter((task) => !ev || task.raw_entry.proj_id === ev)
		);
		this.fileUploadService.selectedProject = this.fileUploadService.xer.projects.get(ev).raw_entry;
		this.fileUploadService.data.selectedProjectId = ev;
	}

	uploadXERFiles(update: Update, files: any[]) {
		const apiCalls = {};
		this.fileUploadInProgress = true;
		this.selectedFiles.forEach((file: File, index) => {
			apiCalls[index] = this.uploadXERFile(file);
		});

		forkJoin(apiCalls).subscribe(
			(result) => {
				this.uploadedFiles = Object.values(result);
				// this.onNoClick();
			},
			(error) => console.log(error),
			() => {
				this.fileUploadInProgress = false;
				this.baselineUpdate = {
					additionalFinishMilestones: [],
					additionalTrackingMilestones: this.fileUploadService.additionalMilestonesFormControl.value,
					finishMilestone: this.fileUploadService.data.finishMilestone,
					startMilestone: this.fileUploadService.data.startMilestone,
					generalNotes: this.fileUploadService.generalNotes,
					criticalPathSummary: this.fileUploadService.criticalPathSummary,
					impactsIssuesTiaNotes: this.fileUploadService.impactsIssuesTiaNotes,
					isAegisGeneratedSchedule: this.fileUploadService.data.isAegisGeneratedSchedule,
					xerFileName: this.uploadedUpdate.data.xerFileName,
					xerFileBuffer: this.uploadedUpdate.data.xerFileBuffer,
					isBaselineUpdate: true,
					updateName: '',
				};
				this.startMilestoneList = this.fileUploadService.startMilestones;
				this.finishMilestoneList = this.fileUploadService.finishMilestones;
				this.updateStepState();
			}
		);
	}

	uploadXERFile(file: File) {
		const metadata = {
			name: file.name,
			type: 'text/plain',
			size: file.size,
		};
		const body = { metadata };

		return new Observable((subscriber) => {
			this.restService.post(`update/fileUpload`, body).subscribe(
				async (uploadResponse: any) => {
					const s3Upload = uploadResponse.data;
					this.baselineUpdateId = s3Upload.updateId;
					const tempUrlRes = s3Upload.credentials;
					const tempUrl = JSON.parse(JSON.stringify(tempUrlRes));
					if (uploadResponse.isErr === false) {
						const { s3Credentials, filePath } = s3Upload.credentials;
						let { type } = metadata;
						type = type ? type : 'text/plain';
						const uploadRes = await this.restService.uploadS3File(
							s3Credentials.replace('%2F', '/'),
							filePath,
							type,
							file
						);
						const uploadedFile = s3Credentials.split('?')[0];
						const data = { name: file.name, updateUrl: filePath, uploadedFile };
						this.form.get('projectDetails').get('baselineFile').setValue({ updateId: s3Upload.updateId });
						subscriber.next(data);
						subscriber.complete();
						this.fileUploaded = true;
					} else {
						console.log('error in temp url POST', tempUrl);
					}
				},
				(error) => {
					console.log('error', error);
				}
			);
		});
	}

	/**
	 * updates dropdownlist values based on search text
	 * @param value
	 * @param fileUploadServiceRef
	 * @param localList
	 */
	handleFilter(value, fileUploadServiceRef, localList): void {
		const newData = [];
		this[localList].forEach((group) => {
			const groupMatchingItems = group.items.filter(
				(item) => item.text.toLowerCase().indexOf(value.toLocaleString()) !== -1
			);
			newData.push(...groupMatchingItems);
		});
		const newDataGrouped = groupBy(
			orderBy(Array.from(newData), [
				{ field: 'category', dir: 'desc' },
				{ field: 'earlyFinish', dir: 'asc' },
			]),
			[{ field: 'category' }]
		);
		this.fileUploadService[fileUploadServiceRef] = newDataGrouped;
	}

	/**
	 * forces max 5 selected additional milestones
	 * @param newVals
	 */
	additionalMilestonesValueChanged(newVals): void {
		// if (this.form.controls.additionalMilestones.value.length > 5) {
		// 	for (let i = newVals.length; i > 5; i--) {
		// 		newVals.pop();
		// 	}
		this.form.controls.additionalMilestones.setValue(newVals);
		// }
	}

	addMileFocus(e, focused: boolean): void {
		this.addMilestoneFocused = focused;
	}

	/**
	 * updates form values of fields in groups bc they don't auto update for some reason. also update fileUploadService milestones if necessary
	 * @param ev
	 * @param groupName
	 * @param controlName
	 */
	updateValue(ev, groupName, controlName): void {
		this.form.controls[groupName].controls[controlName].setValue(ev);
		if (controlName === 'startMilestone') {
			this.fileUploadService.data.startMilestone = ev.value;
		} else if (controlName === 'finishMilestone') {
			this.fileUploadService.data.finishMilestone = ev.value;
		}
		this.updateStepState();
	}

	/**
	 * displays additional milestone tag milestone text if 1 milestone, otherwise shows x items selected
	 * @param tags
	 */
	public tagMapper(tags: any[]): any[] {
		return tags.length < 2 ? tags : [tags];
	}

	public get projectDetailsGroup(): FormGroup {
		return this.form.get('projectDetails') as FormGroup;
	}

	/**
	 * updates values in form to fill out already known fields for editing
	 */
	updateFormValues(): void {
		if (this.projectInfo) {
			this.projectDetailsGroup.patchValue({
				projectCode: this.projectInfo.projectCode,
				projectName: this.projectInfo.name,
				projectType: this.projectInfo.projectType,
				lat: this.projectInfo.lat,
				lng: this.projectInfo.lng,
				siteLocation: this.projectInfo.siteAddressReference,
				scheduleType: scheduleType(this.projectInfo),
				pocId: this.projectInfo.pocId,
				company: this.projectInfo.company,
				clientId: this.projectInfo.clientId,
				sharepoint: this.projectInfo.sharePoint,
			});
		}
	}

	/**
	 * update form based on saasRisk form values and call the canSave function to check if save should be enabled
	 * @param saasForm
	 */
	updateSaveBtnSaasRisk(saasForm): void {
		const form = saasForm.getRawValue();
		this.form.controls.projectDetails.patchValue({
			projectCode: form?.projectCode,
			projectName: form?.projectName,
			projectImage: form?.projectImage,
			projectType: form?.projectType,
			siteLocation: form?.siteLocation,
			lat: form?.lat,
			lng: form?.lng,
			sharepoint: form?.sharepoint,
			isAegisManaged: form?.isAegisManaged,
			memberIds: form?.memberIds,
			scheduleType: form?.scheduleType,
			company: form?.company,
			pocId: form?.pocId,
			clientId: form?.clientId,
		});
		this.form.controls.projectDetails.controls.baselineFile.patchValue({ updateId: form?.baselineFile?.updateId });
		this.form.controls.projectDetails.updateValueAndValidity();
		this.emitCanSave();
	}

	public get exceedsLicenseTaskLimit(): boolean {
		if (!this.currentProjectCompanyPermissions || !this.uploadedUpdate) {
			return false;
		}
		const restricted_licenses = ['ANALYTICS-BASIC-MPK', 'ANALYTICS-BASIC-APK'];
		return (
			restricted_licenses.includes(this.currentProjectCompanyPermissions.license) &&
			this.uploadedUpdate.xer.sortedActivities.length > 10_000
		);
	}

	toggleMultiselect(): void {
		this.additionalMilestonesMultiselect.toggle(!this.additionalMilestonesMultiselect.isOpen);
	}

	string_to_slug(str) {
		str = str.replace(/^\s+|\s+$/g, ''); // trim
		//str = str.toLowerCase();

		// remove accents, swap ñ for n, etc
		const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;' + 'àáäâèéëêìíïîòóöôùúüûñç'.toUpperCase();
		const to = 'aaaaeeeeiiiioooouuuunc------' + 'aaaaeeeeiiiioooouuuunc'.toUpperCase();
		for (let i = 0, l = from.length; i < l; i++) {
			str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
		}

		str = str
			.replace(/[^a-zA-Z0-9 -]/g, '') // remove invalid chars
			.replace(/\s+/g, '-') // collapse whitespace and replace by -
			.replace(/-+/g, '-'); // collapse dashes

		return str;
	}

	saveFromNewProject(ev): void {
		this.savedFromNewProject = true;
		this.savedSyncData = ev;
	}

	doSecretMagic() {
		this.restService
			.postAccountXerFetch(
				'v2/apps/importengine/convert/schedule/',
				{
					id: this.databaseInfo.selectedId,
					database: this.databaseInfo.database,
					projectNumber: this.databaseInfo.projectNumber,
				},
				'text'
			)
			.subscribe(async (response: any) => {
				// response will be a text file.
				console.log(response);
				const file = new File(
					[new Blob([response], { type: 'text/plain' })],
					this.string_to_slug(this.databaseInfo.projectName) + '.xer',
					{ type: 'text/plain' }
				);
				let event: any = { target: document.getElementById('upload-file-new-project') as HTMLInputElement };
				const dataTransfer = new DataTransfer();
				dataTransfer.items.add(file);
				event.target.files = dataTransfer.files;
				event = { target: { files: dataTransfer.files } };
				this.getGroupAt(1).markAsTouched();
				this.uploadedUpdate = null;
				const update = new Update();
				this.zeroTasks = false;
				await update.handleFileSelect(event);
				await this.fileUploadService.handleFileSelect(event);
				this.fileUploadService.xerFile = event;
				this.uploadedUpdate = update;
				if (update.xer.sortedActivities.length <= 0) {
					alert('Imported schedule has no activities. Select another schedule to continue.');
					this.zeroTasks = true;
				}
				if (update.xer.projects.size === 1) {
					this.projectChange([...update.xer.projects.keys()][0]);
				}
				this._exceedsLicenseTaskLimit = this.exceedsLicenseTaskLimit;
				if (this._exceedsLicenseTaskLimit) {
					alert(
						`Maximum number of activities exceeded for company license: ${this.uploadedUpdate.xer.sortedActivities.length}/10000`
					);
				}
				this.selectedFiles = Object.values(event.target.files);
				this.uploadXERFiles(this.uploadedUpdate, [event.target.files[0]]);
				alert('Schedule has been imported from P6 Database successfully.');
			});
	}
	pcatRaws: any[] = [];
	projectRaws: any[] = [];
	uploadType: number = 0;
	selectionConnectionList: any[] = [];
	rawSelectionConnectionList: any[] = [];
	databaseFailedConnect: boolean = false;
	loadNewFilterCodesAndProjects($event: any) {
		console.log($event);
		this.databaseInfo.database = $event;
		this.databaseInfo.filterCode = '';
		this.databaseInfo.filterCodeValue = '';
		this.restService
			.postAccount('v2/apps/importengine/connection/validate/', {}, false, {
				id: this.databaseInfo.selectedId,
				database: $event,
			})
			.subscribe((res) => {
				this.restService
					.postAccount('v2/apps/importengine/connection/wbs/', {}, false, {
						id: this.databaseInfo.selectedId,
						database: $event,
					})
					.subscribe((res2) => {
						const res = this.buildWbsProjectTree(res2.wbs);
						this.selectionProjectList = res;
						this.projectRaws = res;
					});
				console.log(res);
				//this.selectionProjectList = res.projects;
				this.selectionPCatList = res.pcats;
				this.rawSelectionPCatList = res.pcats;
				//this.projectRaws = res.projects;
				this.pcatRaws = res.pcats;
			});
	}

	buildWbsProjectTree(data: WBSItem[]): WBSTreeNode[] {
		const idToNodeMap: { [key: number]: WBSTreeNode } = {};

		// Create a map of nodes
		data.forEach((item) => {
			idToNodeMap[item.id] = {
				item,
				children: [],
			};
		});

		const rootNodes: any[] = [];

		// Link nodes to their parents
		data.forEach((item) => {
			const node = idToNodeMap[item.id];
			if (item.parent_wbs_id !== null && item.parent_wbs_id in idToNodeMap) {
				idToNodeMap[item.parent_wbs_id].children.push(node);
			} else {
				// If there's no valid parent, it must be a root node
				rootNodes.push(node);
			}
		});

		return rootNodes;
	}

	disableCloseForParents(event): void {
		if (!this.disableParent && this.databaseInfo.projectNumber && this.selectionProjectList.length > 0) {
			event.preventDefault();
		}
	}

	checkPCatFilterRequest($event: any) {
		this.databaseInfo.selectedPCat = $event;
		this.databaseInfo.selectedPCatVal = '';
		this.selectionProjectList = this.projectRaws;
		this.selectionPCatValList = [];
		this.rawSelectionPCatValList = [];
		for (let i = 0; i < this.pcatRaws.length; i++) {
			if (this.pcatRaws[i].id === $event) {
				this.selectionPCatValList = this.pcatRaws[i].values;
				this.rawSelectionPCatValList = this.pcatRaws[i].values;
				break;
			}
		}
	}

	filterValueChangeRequest($event: any) {
		if (!$event) {
			this.selectionProjectList = this.projectRaws;
			return;
		}
		this.databaseInfo.selectedPCatVal = $event;
		this.selectionProjectList = [];
		this.restService
			.postAccount('v2/apps/importengine/connection/filter/', {}, false, {
				id: this.databaseInfo.selectedId,
				database: this.databaseInfo.database,
				selectedPCat: this.databaseInfo.selectedPCat,
				selectedPCatVal: $event,
			})
			.subscribe((res) => {
				console.log(res);
				const p = [];
				for (const proj of res.filteredProjects) {
					p.push(Number(proj));
				}

				const filter = (nodes: WBSTreeNode[]): WBSTreeNode[] => {
					return nodes
						.filter((node) => node.item && !(node.item?.proj_flag === 'Y' && !p.includes(node.item.proj_id)))
						.map((node) => ({
							...node,
							children: node.children ? filter(node.children) : [],
						}));
				};

				this.selectionProjectList = filter(structuredClone(this.projectRaws));
			});
	}

	loadNewDatabasesToSelect($event: any) {
		this.databaseFailedConnect = false;
		this.databaseInfo.selectedId = $event;
		if ($event !== '') {
			this.restService.postAccount('v2/apps/importengine/connection/list/', {}, false, { id: $event }).subscribe(
				(res: any) => {
					this.selectionDatabaseList = res.dbs;
					this.rawSelectionDatabaseList = res.dbs;
					console.log('selectionDatabaseList', this.selectionDatabaseList);
				},
				(err) => {
					this.databaseFailedConnect = true;
				}
			);
		} else {
			this.selectionDatabaseList = [];
			this.rawSelectionDatabaseList = [];
			this.selectionPCatList = [];
			this.rawSelectionPCatList = [];
			this.selectionPCatValList = [];
			this.rawSelectionPCatValList = [];
			this.selectionProjectList = [];
		}
	}

	handleProjectNumber(item: any) {
		let found = false;
		this.databaseInfo.projectName = '';
		this.databaseInfo.projectNumber = null;

		// Recursive function to search for the item in the tree
		const findItem = (nodes: WBSTreeNode[]): boolean => {
			for (const node of nodes) {
				if (node.item.id === item.item.id) {
					this.databaseInfo.projectName = node.item.wbs_name;
					this.databaseInfo.projectNumber = node.item.proj_id;
					return item.item.proj_flag === 'Y';
				}
				if (node.children && node.children.length > 0) {
					if (findItem(node.children)) {
						return true;
					}
				}
			}
			return false;
		};

		found = findItem(this.selectionProjectList);
		this.disableParent = found;
	}

	autoComplete(value, rawFieldList, field: 'connection' | 'database' | 'filterCode' | 'filterCodeValue') {
		console.log(value, rawFieldList);
		switch (field) {
			case 'connection':
				return rawFieldList.filter((s) => s.friendly_name.toLowerCase().includes(value.toLowerCase()));
			case 'database':
				return rawFieldList.filter((s) => s.toLowerCase().includes(value.toLowerCase()));
			case 'filterCode':
				return rawFieldList.filter((s) => s.name.toLowerCase().includes(value.toLowerCase()));
			case 'filterCodeValue':
				return rawFieldList.filter((s) => s.value.toLowerCase().includes(value.toLowerCase()));
		}
	}

	public onSelect(e: SelectEvent): void {
		this.uploadType = e.index;
	}
}
