import { AfterViewInit, Component, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import {
	BaselineTargetDates,
	ExpandedMetrics,
	ExpandedMetricsValues,
} from '../../../../models/ProjectReport/ExpandedMetrics';
import { BehaviorSubject } from 'rxjs';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { ProjectReportInterface } from '../../../../models/ProjectReport/ProjectReport';
import { DCMAMetric, DCMAMetrics } from '../../../../models/ProjectReport/DCMA';
import { CalendarArrayInterface, TaskArrayInterface, TaskPredArrayInterface } from '../../../../models/Update/Task';
import { getLowercaseValues } from '../../../../util/tasks';
import { PlotBand } from '@progress/kendo-angular-charts';
import { AxisSettings, SeriesData, SeriesDataSettings } from '../../../../models/ChartSettings';
import { GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query/dist/npm/sort-descriptor';
import { ScheduleAnalysisColumn } from '../schedule-analysis/schedule-analysis.component';
import { toDCMAPct } from '../../../../util/pipes/dcma-score.pipe';
import { take } from 'rxjs/operators';

const metrics = require('./dcma-metrics.json');
export const allColumns = require('./dcma-assessment-columns.json') as {
	columns: ScheduleAnalysisColumn[];
	buttons: DcmaButton[];
};

export type DcmaButton = {
	columnsDisplayed: number[];
	displaySet?: string;
	dataType?: 'Activity' | 'Relationship';
	name: string;
	title?: string;
	hasTable?: boolean;
};

@Component({
	selector: 'app-dcma-assessment',
	templateUrl: './dcma-assessment.component.html',
	styleUrls: ['./dcma-assessment.component.scss'],
})
export class DcmaAssessmentComponent implements OnInit, AfterViewInit {
	@Input() visualizer: boolean = false;
	taskStatusDictionary = {
		TK_Complete: 'Completed',
		TK_NotStart: 'Not Started',
		TK_Active: 'Active',
	};
	taskTypeDictionary = {
		TT_Task: 'Task',
		TT_FinMile: 'Finish Milestone',
		TT_Mile: 'Milestone',
		TT_LOE: 'Level of Effort',
	};
	constraintDict = {
		CS_ALAP: 'As Late As Possible',
		CS_MANDFIN: 'Mandatory Finish',
		CS_MANDSTART: 'Mandatory Start',
		CS_MEO: 'Finish On',
		CS_MEOA: 'Finish On or After',
		CS_MEOB: 'Finish On or Before',
		CS_MSO: 'Start On',
		CS_MSOA: 'Start On or After',
		CS_MSOOB: 'Start On or Before',
	};
	linkTypeDictionary = {
		PR_FS: 'Finish to Start',
		PR_SF: 'Start to Finish',
		PR_FF: 'Finish to Finish',
		PR_SS: 'Start to Start',
	};
	calendars = new Map<number, CalendarArrayInterface>([]);
	@Input() expandedMetrics: BehaviorSubject<ExpandedMetrics> = new BehaviorSubject<ExpandedMetrics>(undefined);
	allTasksByCode = new Map<string, TaskArrayInterface>([]);
	allTasksById = new Map<number, TaskArrayInterface>([]);
	allRelationships = new Map<string, [TaskArrayInterface, TaskArrayInterface, TaskPredArrayInterface]>([]);
	baselineTargets = new Map<string, BaselineTargetDates>([]);

	totalActivities = 1;
	selectedTest = new BehaviorSubject<string>('overall');
	selectedIndex = 20;
	currentDisplaySet: ExpandedMetricsValues = [];
	unfilteredDisplaySet: ExpandedMetricsValues = [];
	tableSearch = '';
	tableDataType: 'Activity' | 'Relationship' = 'Activity';
	searchItemKeys: [CalendarArrayInterface | TaskArrayInterface | TaskPredArrayInterface, Set<string>][] = [];
	isLoading = true;

	scheduleAnalysisSearchBarString = '';
	public assessments: Array<DCMAMetric> = [];

	public valuePlotBands: PlotBand[] = [
		{
			from: 0,
			to: 70,
			color: '#DF5353',
			opacity: 0.2,
		},
		{
			from: 70,
			to: 85,
			color: '#4fc931',
			opacity: 0.2,
		},
		{
			from: 85,
			to: 100,
			color: '#0059FF',
			opacity: 0.2,
		},
	];
	categories: string[] = [];
	seriesData: SeriesDataSettings[] = [];
	valueAxisItemSettings: AxisSettings[] = [
		{
			title: {
				text: '',
				visible: false,
			},
			labels: {
				format: '{0}%',
			},
			plotBands: this.valuePlotBands,
			min: 0,
			max: 100,
			majorGridLines: {
				visible: false,
			},
		},
	];
	@ViewChild(GridComponent)
	public grid: GridComponent;
	public gridView: GridDataResult;
	gridData: Array<any> = [];
	public pageSize = 100;
	public skip = 0;
	public sort: SortDescriptor[] = [];
	selectedColumns: ScheduleAnalysisColumn[] = [];

	constructor(
		public project: ProjectDashboardService,
		private ngZone: NgZone
	) {}

	doHistoricals(historicalCounts: DCMAMetrics['historicalCounts']) {
		const historicalAssessments: Array<Array<DCMAMetric>> = [];

		for (let historicalUpdate = 0; historicalUpdate < historicalCounts.bei.length; historicalUpdate++) {
			const dcma = {
				bei: historicalCounts.bei[historicalUpdate],
				cpli: historicalCounts.cpli[historicalUpdate],
				hardConstraints: historicalCounts.hardConstraints[historicalUpdate],
				highDuration: historicalCounts.highDuration[historicalUpdate],
				highFloat: historicalCounts.highFloat[historicalUpdate],
				incompleteMissingPredSucc: historicalCounts.incompleteMissingPredSucc[historicalUpdate],
				incompleteMissingResources: historicalCounts.incompleteMissingResources[historicalUpdate],
				incompleteWithLag: historicalCounts.incompleteWithLag[historicalUpdate],
				invalidDates: historicalCounts.invalidDates[historicalUpdate],
				negativeFloat: historicalCounts.negativeFloat[historicalUpdate],
				negativeLags: historicalCounts.negativeLags[historicalUpdate],
				nonFSRelationships: historicalCounts.nonFSRelationships[historicalUpdate],
				numIncompleteRelationships: historicalCounts.numIncompleteRelationships[historicalUpdate],
				numIncompleteTasks: historicalCounts.numIncompleteTasks[historicalUpdate],
				passesCriticalPathTest: historicalCounts.passesCriticalPathTest[historicalUpdate],
				positiveLags: historicalCounts.positiveLags[historicalUpdate],
				slippedTasks: historicalCounts.slippedTasks[historicalUpdate],
			};

			const metricTypes = (metrics?.metrics as DCMAMetric[]) || [];
			let numPass = 0;
			const assessments: Array<DCMAMetric> = [];
			for (const metricType of metricTypes) {
				const dcmaMetric: DCMAMetric = {
					...metricType,
				};
				let metricValue = dcma[metricType.key];
				const metricPool = metricType.denominatorKey
					? metricType.denominatorKey === 'ALL'
						? this.totalActivities
						: metricType.denominatorKey === 'ALL_PRED'
							? this.allRelationships.size
							: dcma[metricType.denominatorKey] || 1
					: undefined;
				if (metricPool && +metricPool) {
					metricValue /= metricPool;
					const descValueStr = Math.round(metricValue * 100) + '%';

					dcmaMetric.description = dcmaMetric.description.replace('{value}', metricValue === 0 ? '0' : descValueStr);
				}
				dcmaMetric.value = metricValue;

				const min = metricType.min;
				const max = metricType.max;
				dcmaMetric.passes =
					metricValue === true || (min != null && metricValue >= min) || (max != null && metricValue <= max);
				if (dcmaMetric.passes) {
					assessments.push(dcmaMetric);
				} else {
					assessments.unshift(dcmaMetric);
				}
				numPass += dcmaMetric.title !== 'Resources' && dcmaMetric.passes ? 1 : 0;
			}

			assessments.unshift({
				title: 'Overall',
				description: 'All 13 assessments should pass (Resources excluded)',
				passes: numPass === 13,
				value: (100 * numPass) / 13,
				key: 'overall',
			});

			historicalAssessments.push(assessments);
		}

		return historicalAssessments;
	}

	ngOnInit(lateCall: string = ''): void {
		this.project.$expandedMetrics.subscribe((expandedMetrics) => {
			if (!expandedMetrics) {
				return;
			}
			this.allTasksByCode.clear();
			this.allRelationships.clear();
			this.allTasksById.clear();
			const tasks = expandedMetrics.totalActivities || [];
			this.totalActivities = tasks.length;
			for (const task of tasks) {
				if (task.task_code) {
					this.allTasksByCode.set(task.task_code, task);
				}
				this.allTasksById.set(task.task_id, task);
			}
			this.calendars.clear();
			const calendars = expandedMetrics.calendars || [];
			for (const calendar of calendars) {
				this.calendars.set(calendar.clndr_id, calendar);
			}
			const relationships = expandedMetrics.totalRelationships || [];
			for (const relationship of relationships) {
				const predTask = this.allTasksById.get(relationship.pred_task_id);
				const nextTask = this.allTasksById.get(relationship.task_id);
				const relationshipKey = `${predTask?.task_code},${nextTask?.task_code},${relationship.pred_type}`;
				this.allRelationships.set(relationshipKey, [predTask, nextTask, relationship]);
			}
			this.baselineTargets.clear();
			for (const baselineTarget of expandedMetrics.baselineTargets || []) {
				this.baselineTargets.set(baselineTarget.code, baselineTarget);
			}
			this.updateAssessmentItems(expandedMetrics, this.assessments);
			this.updateDCMAMetrics(this.project.$currentProjectReport.value);
		});
		this.selectedTest.subscribe((key: string) => {
			this.selectedIndex = 20;
			let selectedTest: DCMAMetric | undefined;
			for (let i = 0; i < this.assessments.length; i++) {
				if (this.assessments[i].key === key) {
					this.selectedIndex = i;
					selectedTest = this.assessments[i];
					break;
				}
			}

			this.searchItemKeys = [];

			if (selectedTest?.elements instanceof Array) {
				this.unfilteredDisplaySet = selectedTest.fullElements || [];
				if (selectedTest.denominatorKey === 'numIncompleteTasks') {
					this.tableDataType = 'Activity';
				} else if (
					selectedTest.denominatorKey === 'numIncompleteRelationships' ||
					selectedTest.denominatorKey === 'ALL_PRED'
				) {
					this.tableDataType = 'Relationship';
				}
				this.scheduleAnalysisSearchBarString = `Search ${this.tableDataType}...`;
				for (const item of this.unfilteredDisplaySet) {
					//eslint-disable-next-line
					const task = this.allTasksById.get(+item['task_id']);
					//eslint-disable-next-line
					const predTask = this.allTasksById.get(+item['pred_task_id']);
					const taskFilterKeys = ['task_id', 'task_name', 'task_code'];
					if (task) {
						this.searchItemKeys.push([item, getLowercaseValues(task, taskFilterKeys)]);
						item.succCode = task.task_code;
						item.succName = task.task_name;
						item.succStartAct = task.act_start_date;
						item.succStartEarly = task.early_start_date;
						item.succEndAct = task.act_end_date;
						item.succEndEarly = task.early_end_date;
						item.succRd = task.remain_drtn_hr_cnt / (this.calendars.get(+task.clndr_id)?.day_hr_cnt || 8);
						if (task.task_code && this.baselineTargets.has(task.task_code)) {
							item.baselineTargetStart = this.baselineTargets.get(task.task_code).start;
							item.baselineTargetFinish = this.baselineTargets.get(task.task_code).finish;
						}
						const hoursFields = [
							{
								taskField: 'remain_drtn_hr_cnt',
								customField: 'remainingHrs',
							},
							{
								taskField: 'target_drtn_hr_cnt',
								customField: 'odHrs',
							},
							{
								taskField: 'total_float_hr_cnt',
								customField: 'tfHrs',
							},
						];
						hoursFields.forEach((field) => {
							if (field.taskField in task) {
								item[field.customField] = task[field.taskField] / (this.calendars.get(+task.clndr_id)?.day_hr_cnt || 8);
							} else {
								item[field.customField] = 0;
							}
						});
					}
					if (predTask) {
						this.searchItemKeys.push([item, getLowercaseValues(predTask, taskFilterKeys)]);
						item.predCode = predTask.task_code;
						item.predName = predTask.task_name;
						item.predStartAct = predTask.act_start_date;
						item.predStartEarly = predTask.early_start_date;
						item.predEndAct = predTask.act_end_date;
						item.predEndEarly = predTask.early_end_date;
						item.predRd = predTask.remain_drtn_hr_cnt / (this.calendars.get(+predTask.clndr_id)?.day_hr_cnt || 8);
					}
					if ('lag_hr_cnt' in item) {
						item.lagHrs = this.divByCalendar(item.lag_hr_cnt, task || predTask);
					} else {
						item.lagHrs = 0;
					}
					const overviewDate = new Date(this.project.$currentProjectReport.value?.projectOverview?.dataDate);
					item.dataDate = new Date(
						overviewDate.getUTCFullYear(),
						overviewDate.getUTCMonth(),
						overviewDate.getUTCDate()
					);
				}
			}
			this.updateFilter({ searchTerm: this.tableSearch });
		});

		this.project.$currentProjectReport.subscribe((projectReport) => {
			this.updateDCMAMetrics(projectReport, lateCall);
		});
	}

	public ngAfterViewInit(): void {
		this.fitColumns();
	}

	updateFilter(args: { event?: any; searchTerm?: string }) {
		const searchTerm: string = args?.event?.target?.value?.toLowerCase() || args.searchTerm;
		this.tableSearch = searchTerm;
		let filteredDisplaySet = new Set<CalendarArrayInterface | TaskArrayInterface | TaskPredArrayInterface>([]);
		if (!searchTerm) {
			filteredDisplaySet = new Set<CalendarArrayInterface | TaskArrayInterface | TaskPredArrayInterface>(
				this.unfilteredDisplaySet || []
			);
		} else {
			for (const [item, valuesToLower] of this.searchItemKeys) {
				for (const valLower of valuesToLower) {
					if (valLower.includes(searchTerm)) {
						filteredDisplaySet.add(item);
					}
				}
			}
		}

		// update the rows
		this.currentDisplaySet = Array.from(filteredDisplaySet);
		const matchingBtnData = allColumns.buttons.find((btn) => btn.name === this.selectedTest.value);
		this.selectedColumns = [];
		if (matchingBtnData === undefined) {
			return;
		}
		allColumns.columns.forEach((column: ScheduleAnalysisColumn) => {
			if (matchingBtnData.columnsDisplayed.includes(column.id)) {
				this.selectedColumns.push(column);
			}
		});
		this.loadActivities();
	}

	updateAssessmentItems(expandedMetrics: ExpandedMetrics, assessments: DCMAMetric[]) {
		if (Object.keys(expandedMetrics || {}).length && assessments.length) {
			this.isLoading = false;
		}
		for (const assessment of assessments) {
			const assessmentItems: ExpandedMetricsValues = [];
			const assessmentElementCodes: string[] = assessment.elements || [];
			for (const element of assessmentElementCodes) {
				const taskItem = this.allTasksByCode.get(element);
				if (taskItem) {
					assessmentItems.push(taskItem);
				} else {
					if (element?.length === 3) {
						const relationKey = `${element[0]},${element[1]},${element[2]}`;
						const relationItem = this.allRelationships.get(relationKey);
						if (relationItem) {
							assessmentItems.push(relationItem[2]);
						}
					}
				}
			}
			assessment.fullElements = assessmentItems;
		}
	}

	updateDCMAMetrics(
		projectReport: Pick<ProjectReportInterface, 'dcma' | 'qualityControl' | 'baselineUpdateId' | 'updateIds'>,
		lateCall = ''
	) {
		const metricTypes = (metrics?.metrics as DCMAMetric[]) || [];
		const dcma = projectReport?.dcma?.currentMetrics;
		this.totalActivities = projectReport?.qualityControl.totalActivities || 1;
		if (!dcma) {
			return;
		}
		let numPass = 0;
		const assessments: Array<DCMAMetric> = [];
		for (const metricType of metricTypes) {
			const dcmaMetric: DCMAMetric = {
				...metricType,
			};
			let metricValue = dcma[metricType.key];
			if (metricValue instanceof Array) {
				dcmaMetric.elements = metricValue;
				metricValue = metricValue.length;
			}
			const metricPool = metricType.denominatorKey
				? metricType.denominatorKey === 'ALL'
					? this.totalActivities
					: metricType.denominatorKey === 'ALL_PRED'
						? this.allRelationships.size
						: dcma[metricType.denominatorKey] || 1
				: undefined;
			if (metricPool && +metricPool) {
				metricValue /= metricPool;
				const descValueStr = toDCMAPct(metricValue, [metricType.min, metricType.max]) + '%';

				dcmaMetric.description = dcmaMetric.description.replace('{value}', metricValue === 0 ? '0' : descValueStr);
			}
			dcmaMetric.value = metricValue;

			const min = metricType.min;
			const max = metricType.max;
			dcmaMetric.passes =
				metricValue === true || (min != null && metricValue >= min) || (max != null && metricValue <= max);
			if (dcmaMetric.passes) {
				assessments.push(dcmaMetric);
			} else {
				assessments.unshift(dcmaMetric);
			}
			numPass += dcmaMetric.title === 'Resources' ? 0 : dcmaMetric.passes ? 1 : 0;
		}

		assessments.unshift({
			title: 'Overall',
			description: 'All 13 out of 13 assessments should pass (Resources excluded)',
			passes: numPass === 13,
			value: (100 * numPass) / 13,
			key: 'overall',
		});

		const historicals = this.doHistoricals(this.project.$currentProjectReport.value.dcma.historicalCounts);
		const historicalOveralls = [];
		const categories: string[] = [];
		const data: SeriesData[] = [];
		historicals.forEach((val, index) => {
			historicalOveralls.push(val[0].value);
			const category =
				index === 0
					? 'Baseline'
					: 'Update ' + index + (projectReport?.baselineUpdateId === projectReport?.updateIds[index] ? ' ®' : '');
			categories.push(category);
			data.push({
				category,
				value: val[0].value,
			});
		});
		this.categories = categories;
		this.seriesData = [
			{
				type: 'line',
				data,
				name: 'Overall Score',
				visible: true,
				color: 'black',
			},
		];

		this.assessments = assessments;
		this.updateAssessmentItems(this.expandedMetrics.getValue(), this.assessments);
		this.selectedTest.next('overall');

		if (lateCall !== '') {
			this.selectedTest.next(lateCall);
		}
	}

	public pageChange(event: PageChangeEvent): void {
		this.skip = event.skip;
		this.loadActivities();
	}

	public sortChange(sort: SortDescriptor[]): void {
		this.sort = sort;
		this.loadActivities();
	}

	public loadActivities(): void {
		this.gridView = {
			data: this.currentDisplaySet?.slice(this.skip, this.skip + this.pageSize),
			total: this.currentDisplaySet?.length,
		};
		this.gridData = this.currentDisplaySet;
	}

	getCalendar(calendar_id: number) {
		for (let i = 0; i < this.project.$expandedMetrics.value.calendars.length; i++) {
			if (this.project.$expandedMetrics.value.calendars[i]?.clndr_id === calendar_id) {
				return this.project.$expandedMetrics.value.calendars[i];
			}
		}
		return null;
	}

	divByCalendar(value: any, row: any) {
		return value / (this.getCalendar(row?.clndr_id)?.day_hr_cnt || 8) || value / 8;
	}

	public onDataStateChange(): void {
		this.fitColumns();
	}

	private fitColumns(): void {
		this.ngZone.onStable
			.asObservable()
			.pipe(take(1))
			.subscribe(() => {
				this.grid?.autoFitColumns();
			});
	}
}
