<div class='card'>
	<kendo-gridlayout
		[rows]="['42.4px', 'auto']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
			<kendo-gridlayout
				[rows]="['42.4px']"
				[cols]="['226px', 'calc(100% - 231px)']"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header">
						Activity Cost Details <span style="font-size: 9px; position: relative; top: -1px;">(BETA) </span>
					</div>
					<tooltip-open type='{{isOverview ? "overview" : "activity-costs"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='toolbar-item-container'>
					<div style='height: min-content; margin: auto 10px auto 0; display: flex; justify-content: flex-end; width: 100%; gap: 10px;'>
						<kendo-multiselecttree
							#activityCodesMultiselectTree
							kendoMultiSelectTreeSummaryTag
							kendoMultiSelectTreeExpandable
							[kendoMultiSelectTreeHierarchyBinding]="allActivityCodes"
							[(value)]="selectedActivityCodes"
							textField="name"
							valueField="id"
							childrenField='subCodes'
							[valuePrimitive]='false'
							[filterable]='true'
							(valueChange)='filterChanged($event);'
							[clearButton]='false'
							[expandOnFilter]="{expandMatches: true}"
							[itemDisabled]="itemDisabled"
							[placeholder]='"Activity Code Filter"'
							style='width: 200px; margin: auto 0; cursor: pointer;'
							[disabled]='loading'
						>
							<ng-template kendoMultiSelectTreeGroupTagTemplate let-dataItems>
								<span>
									<kendo-svg-icon [icon]='icons.caretDown'></kendo-svg-icon>
									{{ codesTag }}
								</span>
							</ng-template>
						</kendo-multiselecttree>
						<kendo-buttongroup width='100%' selection="single" class='btn-group' style='height: 34px; margin: auto 0;'>
							<button
								*ngFor="let button of forwardOrBackwardButtons"
								kendoButton
								[toggleable]="true"
								[selected]="button.selected"
								(selectedChange)="selectionChange($event, button, false)"
								class='item-btn'
								[class.selected-item-btn]='button.selected'
								[disabled]='loading'
							>
								<span>
									{{ button.text }}
								</span>
							</button>
						</kendo-buttongroup>
						<div class='btngroup-label' style='margin: auto 0;'>
							Lookahead (Days):
						</div>
						<span class='slider-tooltips' kendoTooltip style='margin: auto 0;'>
							<kendo-buttongroup width='100%' selection="single" class='btn-group' [title]='selectedStatus === "Planned" || loading ? "" : "Select Planned to edit"'>
								<button
									*ngFor="let button of timespanButtons"
									kendoButton
									[toggleable]="true"
									[selected]="button.selected"
									(selectedChange)="selectionChange($event, button, true)"
									class='item-btn'
									[class.selected-item-btn]='button.selected'
									[disabled]='selectedStatus !== "Planned" || loading'
									[class.hide-selection-on-disabled]='selectedStatus !== "Planned" || loading'
								>
									<span>
										{{ button.text }}
									</span>
								</button>
							</kendo-buttongroup>
						</span>
						@if ((projectService.$currentProjectReport | async)?.updateIds?.length > 1 && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK") {
							<button kendoButton (click)="exportToExcel()"
											themeColor='base' class="header-btn" [imageUrl]='"/assets/icons/newIcons/excelExport.svg"' title='Export Activities' style="margin: auto 0;">
							</button>
						} @else if (currentProjectCompanyPermissions?.license === "ANALYTICS-BASIC-MPK" || currentProjectCompanyPermissions?.license === "ANALYTICS-BASIC-APK") {
							<div kendoTooltip style="text-align: center;">
								<div title='Upgrade to a Professional License to enable Exports'>
									<button kendoButton themeColor="base" class='header-btn' [imageUrl]='"/assets/icons/newIcons/excelExport.svg"' [disabled]='true' style="margin: auto 0;"></button>
								</div>
							</div>
						}
					</div>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1'>
			<kendo-grid
				[data]="gridView"
				[kendoGridBinding]="gridData"
				[height]="600"
				[loading]='loading'
				[size]='"small"'
				[sortable]='true'
				[sort]="sort"
				(sortChange)="sortChange($event)"
				[skip]="skip"
				[pageSize]="pageSize"
				(pageChange)="pageChange($event)"
				[rowHeight]='26'
				[navigable]="false"
				(excelExport)='onExcelExport($event)'
				scrollable="virtual"
				class='activity-grid'
				style="user-select: text;"
			>
				<ng-template kendoGridNoRecordsTemplate>
					@if (!loading) {
						<div>
							No Activities for Applied Filters
						</div>
					}
				</ng-template>
				<kendo-grid-column
					*ngFor='let column of selectedColumns'
					[field]="column.field"
					[title]="column.title"
					[width]='column.width'
					headerClass="header-class"
				>
					<ng-template kendoGridCellTemplate let-dataItem>
						@if (column?.type === 'date') {
							<span style='white-space: nowrap; text-overflow: ellipsis;' class="centered-data">{{dataItem[column.field] === null ? '-' : (dataItem[column.field] | date: 'MM/dd/yyyy') + ((column?.field === 'start' && dataItem?.startIsAct) || (column?.field === 'finish' && dataItem?.finishIsAct) ? ' A' : '')}}</span>
						} @else if (column?.type === 'currency') {
							<span style='white-space: nowrap; text-overflow: ellipsis;' class="centered-data">{{dataItem[column.field] === null ? '-' : dataItem[column.field] | currency: 'USD' : '$' :'1.0-0'}}</span>
						} @else if (column?.type === 'percent') {
							<span style='white-space: nowrap; text-overflow: ellipsis;' class="centered-data">{{dataItem[column.field] === null ? '-' : dataItem[column.field] | number: '1.0-0'}}%</span>
						} @else {
							<span style='white-space: nowrap; text-overflow: ellipsis;'>{{dataItem[column.field] === null ? '-' : dataItem[column.field]}}</span>
						}
					</ng-template>
				</kendo-grid-column>
				<kendo-grid-excel [fileName]="projectService?.$currentProjectData?.value?.name + ' Activity Cost Details.xlsx'" [fetchData]="allExportData">
					<kendo-excelexport-column
						*ngFor="let column of selectedColumns"
						[field]="column.field"
						[title]="column.title"
						[width]='column.width'
					>
					</kendo-excelexport-column>
					<kendo-excelexport-column
						*ngFor="let column of allSubCodes"
						[field]="'activityCode_' + column.shortName"
						[title]="column.title"
						[width]='null'
					>
					</kendo-excelexport-column>
				</kendo-grid-excel>
			</kendo-grid>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>
