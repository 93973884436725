import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScheduleStorageService } from '../../../services/project/schedule-storage.service';
import { ProfileCompanyPermission } from '../../../models/auth/account-user';
import { ProjectDashboardService } from '../../../services/project/project.service';
import { NavigationBarStorageService } from '../../../services/common/navigation-bar-storage.service';
import { Activity, XerActivity } from '@rhinoworks/xer-parse';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../../../services/common/user.service';
import { ProjectInterface } from '../../../models/Project';
import { fileExcelIcon } from '@progress/kendo-svg-icons';
import { EditedUpdate } from '../schedule-updates-list/schedule-updates-list.component';
import { differenceInCalendarDays, differenceInDays, isValid } from 'date-fns';
import { scheduleType } from '../../../util/projects';
import { AxisSettings, SeriesDataSettings } from '../../../models/ChartSettings';

@Component({
	selector: 'app-analysis-page',
	templateUrl: './analysis-page.component.html',
	styleUrls: ['./analysis-page.component.scss'],
})
export class AnalysisPageComponent implements OnInit {
	@Input() $projectData = new BehaviorSubject<ProjectInterface>(undefined);
	@Input() $updates = new BehaviorSubject<EditedUpdate[]>([]);
	@Input() visualizer: boolean = false;
	@Output() exportImpactAnalysis = new EventEmitter<void>();
	currentProjectCompanyPermissions: ProfileCompanyPermission = null;
	categories = [];
	selectedDelayActv: Activity = null;
	clearDelaySelection = new BehaviorSubject<boolean>(false);
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	numUpdates: number = 0;
	user: any = {
		userType: 'aegis',
	};
	public dataSource: EditedUpdate[];
	constructor(
		public scheduleStorage: ScheduleStorageService,
		public projectService: ProjectDashboardService,
		public navBarStorage: NavigationBarStorageService,
		public navbar: NavigationBarStorageService,
		public userService: UserService
	) {}

	ngOnInit() {
		this.projectService.$currentProjectData.subscribe((data) => {
			this.currentProjectCompanyPermissions =
				data === undefined ? null : this.navBarStorage.companyPermissionMap.get(data?.company);
		});

		this.scheduleStorage.$allUpdates.pipe(takeUntil(this._unsubscribeAll)).subscribe((updates) => {
			updates = updates?.[0]?.projectId === this.$projectData.value?._id ? updates : [] || [];
			if (this.numUpdates !== 0 && updates.length === 0 && this.user?.userType === 'saasRisk') {
				this.navbar.selectedTab = 'risk';
				const url = new URLSearchParams(new URL(window.location.href).search);
				url.set('tab', this.navbar.selectedTab);
				history.pushState(null, '', window.location.pathname + '?' + url.toString());
				this.navbar.$tabPointer.next('risk');
			}
			this.numUpdates = updates.length;
			const updatedUpdates: EditedUpdate[] = [];
			let startMilestone: XerActivity;
			let finishMilestoneCode: string;
			for (let i = 0; i < updates.length; i++) {
				const update = updates[i];
				const isBaseline = i === 0 || update.baseline;
				const finishMilestone = update?.finishMilestone;
				if (i === 0 || !startMilestone) {
					startMilestone = update.startMilestone;
				}
				if (finishMilestone) {
					finishMilestoneCode = finishMilestone.task_code;
				}
				const contractCompletion = new Date(
					finishMilestone?.act_end_date || finishMilestone?.cstr_date || finishMilestone?.early_end_date
				);
				const currentCompletion = new Date(finishMilestone?.act_end_date || finishMilestone?.early_end_date);
				const dataDate = update?.dataDate;
				const remainingDuration = isValid(currentCompletion)
					? differenceInCalendarDays(currentCompletion, dataDate)
					: undefined;
				const contractVariance =
					isValid(contractCompletion) && isValid(currentCompletion)
						? differenceInCalendarDays(contractCompletion, currentCompletion)
						: undefined;
				let previousVariance;
				if (!isBaseline && isValid(updatedUpdates[i - 1].currentCompletionDate) && isValid(currentCompletion)) {
					previousVariance = differenceInCalendarDays(updatedUpdates[i - 1].currentCompletionDate, currentCompletion);
				}
				const startDate = new Date(startMilestone?.early_start_date || startMilestone?.target_start_date);
				const daysElapsed = isValid(startDate) ? differenceInCalendarDays(dataDate, startDate) ?? undefined : undefined;
				let cpli;
				let tfci;
				if (this.projectService.$currentProjectReport?.value?.calculationFieldsHistorical[i]) {
					cpli = this.projectService.$currentProjectReport.value.calculationFieldsHistorical[i].CPLI / 100;
					tfci = this.projectService.$currentProjectReport.value.calculationFieldsHistorical[i].TFCI / 100;
				}
				const lastUploaded = new Date(update?.dataDate);
				const now = new Date();
				const dUpload = differenceInDays(now, lastUploaded);
				const type = scheduleType(this.projectService.$currentProjectReport.value?.project);
				const updatedUpdate: EditedUpdate = {
					...update,
					update,
					fileName: update.xerFileName,
					url: update.url,
					dataDate,
					updateName: updatedUpdates.length === 0 ? 'Baseline' : `Update ${updatedUpdates.length}`,
					createdAtDate: new Date(update.LastModified),
					contractCompletionDate: new Date(contractCompletion),
					currentCompletionDate: new Date(currentCompletion),
					contractVariance,
					previousVariance,
					cpli: isNaN(cpli) || !isFinite(cpli) ? '0' : cpli.toFixed(2),
					tfci: isNaN(tfci) || !isFinite(cpli) ? '0' : tfci.toFixed(2),
					_id: update._id,
					projectId: update.projectId,
					criticalPathNotes: update.criticalPathNotes,
					timeAnalysisNotes: update.timeAnalysisNotes,
					isAegisGeneratedSchedule: update?.isAegisGeneratedSchedule,
					toDelete: false,
					toAdd: false,
					toBaseline: false,
					index: i,
					missingUpdate: i < updates.length - 1 ? false : dUpload >= 45 && type === 'Active',
					isRebaseline: update.baseline || false,
				};
				updatedUpdates.push(updatedUpdate);
			}
			this.dataSource = updatedUpdates;
			this.scheduleStorage.$modifiedUpdates.next(updatedUpdates);
		});

		this.userService.user.subscribe((data) => {
			if (data) {
				this.user = data;
			}
		});
	}

	/**
	 * handles activity click events in the delay grid
	 * @param actv
	 */
	activityClickedDelay(actv: Activity): void {
		this.selectedDelayActv = actv;
	}

	/**
	 * handles gantt selection toggle
	 * @param originalSelectionExisted
	 */
	ganttSelection(originalSelectionExisted: boolean) {
		this.clearDelaySelection.next(true);
		if (!originalSelectionExisted) {
			this.scroll();
		}
	}

	/**
	 * scroll page to component start (using components above critical path comparison bc of the navbar/overview overhang)
	 */
	scroll() {
		const el = document.getElementById(
			this.numUpdates < 2 ? 'schedules-grid' : 'scheduleDelaysComponent'
		) as HTMLElement;
		if (el) {
			el.scrollIntoView();
		}
	}

	protected readonly svgExcel = fileExcelIcon;
}
