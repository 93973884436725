<div class="navbar">
	<kendo-gridlayout
		[rows]="['56px']"
		[cols]="_projectDashboardService.$currentProjectData.value === undefined ? ['121px', '1fr', '150px'] : ['121px', '35px', '1fr', '654px']"
		[gap]="{ rows: 0, cols: 0 }"
		class='full-width full-height'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-container'>
			<a class="company-image" href="/portfolio">
				<img src="/assets/images/logos/AegisAnalyticsNormalG.png" [routerLink]="'/portfolio'" (click)="onBack()" alt='Aegis Analytics' style='max-width: 100%; height: auto; vertical-align: top; border: none;'>
			</a>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-container' *ngIf='_projectDashboardService.$currentProjectData.value !== undefined'>
			<a
				(click)="onBack()"
				routerLink="/portfolio"
				class='anchor-wrapping-back-arrow'
			>
				<kendo-svg-icon [icon]="chevronLeft" class='back-icon'></kendo-svg-icon>
			</a>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='1' [col]='_projectDashboardService.$currentProjectData.value === undefined ? 2 : 3' class='gridlayout-container' style='overflow: hidden'>
			<div class='project-page-title' *ngIf='_projectDashboardService.$currentProjectData.value !== undefined'>
				{{ (_projectDashboardService.$currentProjectReport | async)?.project?.name }} <span kendoTooltip title='You are a viewer of this project, so some details may be specific to your company or not be editable.' *ngIf='(_projectDashboardService.$currentProjectReport | async)?.permissionData?.role === "viewer"' style='position: relative; top: 4px; left: 5px;'><ion-icon icon='eye-outline'></ion-icon> </span>
			</div>
			<span class='page-title' *ngIf='_projectDashboardService.$currentProjectData.value === undefined'>
				Project Portfolio</span>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='1' [col]='_projectDashboardService.$currentProjectData.value === undefined ? 3 : 4' class='gridlayout-container right-container'>
			<div #content *ngIf='_projectDashboardService.$currentProjectData.value !== undefined'>
				<kendo-gridlayout
					[rows]="['56px']"
					[cols]="['auto']"
					[gap]="{ rows: 0, cols: 0 }"
					class='full-width'
					*ngIf="(_projectDashboardService.$currentProjectReport | async)?.project?.updateIds?.length && !(_projectDashboardService.$currentProjectReport | async)?.project?.updateLock"
				>
					<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-container' style='padding-left: 15px;'>
						<ion-segment mode="ios" (ionChange)="segmentChanged($event)" class="tab-selector giantButton" [(ngModel)]='navBarStorage.selectedTab' *ngIf='user?.userType !== "saasRisk"'>
							<ion-segment-button value="overview" class="seg overview-btn" id="project-button" [ngClass]="{overviewTab: navBarStorage.selectedTab === 'overview' }">
								<ion-label>Overview</ion-label>
							</ion-segment-button>
							<div class="tab-selector-button-divider"></div>
							<ion-segment-button value="progress" class="seg" [ngClass]="{progressTab: navBarStorage.selectedTab === 'progress' }">
								<ion-label>Progress</ion-label>
							</ion-segment-button>
							<div class="tab-selector-button-divider"></div>
							<ion-segment-button value="qc" class="seg" [ngClass]="{qcTab: navBarStorage.selectedTab === 'qc' }">
								<ion-label>Quality</ion-label>
							</ion-segment-button>
							<div class="tab-selector-button-divider"></div>
							<ion-segment-button
								[ngClass]="{ costTab: navBarStorage.selectedTab === 'cost' }"
								value="cost"
								id="cost-button"
								class="seg"
								*ngIf='currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK" && isValidCostProject'
							>
								<ion-label>Cost <span style="font-size: 9px; position: relative; top: -1px;">(BETA)</span></ion-label>
							</ion-segment-button>
							<div kendoTooltip style="text-align: center;" *ngIf='currentProjectCompanyPermissions?.license === "ANALYTICS-BASIC-MPK" || currentProjectCompanyPermissions?.license === "ANALYTICS-BASIC-APK" || !isValidCostProject'>
								<div [title]='!isValidCostProject ? "No Valid Cost Data Available" : "Upgrade to a Professional License to access the Cost Tab"' (click)='$event.preventDefault(); $event.stopPropagation();'>
									<ion-segment-button
										id="cost-button2"
										class="seg"
										disabled
									>
										<ion-label>Cost <span style="font-size: 9px; position: relative; top: -1px;">(BETA)</span></ion-label>
									</ion-segment-button>
								</div>
							</div>
							<div class="tab-selector-button-divider"></div>
							<ion-segment-button
								[ngClass]="{ enabled: $isRiskEnabled, disabled: !$isRiskEnabled, riskTab: navBarStorage.selectedTab === 'risk' }"
								value="risk"
								id="risk-button"
								class="seg"
								*ngIf='currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK"'
							>
								<ion-label>Risk</ion-label>
							</ion-segment-button>
							<div kendoTooltip style="text-align: center;" *ngIf='currentProjectCompanyPermissions?.license === "ANALYTICS-BASIC-MPK" || currentProjectCompanyPermissions?.license === "ANALYTICS-BASIC-APK"'>
								<div title='Upgrade to a Professional License to access the Risk Tab' (click)='$event.preventDefault(); $event.stopPropagation();'>
									<ion-segment-button
										id="risk-button2"
										class="seg"
										disabled
									>
										<ion-label>Risk</ion-label>
									</ion-segment-button>
								</div>
							</div>
							<div class="tab-selector-button-divider"></div>
							<ion-segment-button (click)='togglePopover("schedulePopoverAnchor")' value="schedules" class="seg" [ngClass]="{schedulesTab: navBarStorage.selectedTab === 'schedules' || navBarStorage.selectedTab === 'analysis' || navBarStorage.selectedTab === 'gantt' }" kendoPopoverAnchor showOn="click" #scheduleAnchor="kendoPopoverAnchor" [popover]='schedulePopover' style="position: relative;">
								<ion-label>Schedules</ion-label>
								@if (!schedulePopoverAnchor?.popupRef) {
									<kendo-svg-icon [icon]="icons.caretAltDown" class="downArrowIcon selectedTab" style="position: absolute; top: 17px; left: 17.758px;" [class.nonSelectedTab]="navBarStorage.selectedTab !== 'schedules'"></kendo-svg-icon>
								}
							</ion-segment-button>
							<div class="tab-selector-button-divider"></div>
							<ion-segment-button value="compare" class="seg" [disabled]="!((_projectDashboardService.$currentProjectData | async)?.updateIds?.length > 1)" [ngClass]="{schedulesTab: navBarStorage.selectedTab === 'compare', disabled: !((_projectDashboardService.$currentProjectData | async)?.updateIds?.length > 1)}" kendoPopoverAnchor showOn="click" style="position: relative;">
								<ion-label>Compare</ion-label>
							</ion-segment-button>
						</ion-segment>
						<ion-segment mode="ios" (ionChange)="segmentChanged($event)" style="height: 36px" [(ngModel)]='navBarStorage.selectedTab' class="giantButton" *ngIf='user?.userType === "saasRisk" && updates > 0'>
							<ion-segment-button
								[ngClass]="{ enabled: $isRiskEnabled, disabled: !$isRiskEnabled, riskTab: navBarStorage.selectedTab === 'risk' }"
								value="risk"
								id="risk-button-saas"
								class="seg"
							>
								<ion-label>Risk</ion-label>
							</ion-segment-button>
							<div style="border-left: thin solid white; height: 20px; position: relative; top: 7px; border-color: rgba(255, 255, 255, 0.5);"></div>
							<ion-segment-button value="schedules" class="seg" [ngClass]="{schedulesTab: navBarStorage.selectedTab === 'schedules' }">
								<ion-label>Schedules</ion-label>
							</ion-segment-button>
						</ion-segment>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			</div>
			<div class='right-btn-group' kendoPopoverContainer #container="kendoPopoverContainer" showOn='none' [popover]='myPopover'>
				<span #anchor1>
					<app-add-project-button
						[clientList]='navBarStorage.clientList'
						*ngIf='_projectDashboardService.$currentProjectData.value === undefined'
						(click)='dismissPopup()'
					></app-add-project-button>
				</span>
				<span class="k-icon right-btn profile-img" title='Menu' *ngIf='_projectDashboardService.$currentProjectData.value !== undefined'>
					<img kendoPopoverAnchor showOn="click" #anchor="kendoPopoverAnchor" [popover]="projectMenuPopover" class='login-icon-setup' src='/assets/icons/ui/settings-icon2.png' (click)='togglePopover("projectPopoverAnchor")'>
					<kendo-svg-icon *ngIf="projectNotOpen" [icon]="icons.caretAltDown" class="downArrowIcon" style="color: #050C32;"></kendo-svg-icon>
					<kendo-svg-icon *ngIf="!projectNotOpen" [icon]="icons.caretAltDown" class="downArrowIcon" style="color: white;"></kendo-svg-icon>
				</span>
				<span class="k-icon right-btn profile-img">
					<img kendoPopoverAnchor #anchor3="kendoPopoverAnchor" showOn="click" [popover]="helpPopover" class='login-icon-setup' src='/assets/icons/ui/help-icon.png' (click)='togglePopover("helpPopoverAnchor")'>
					<kendo-svg-icon *ngIf="helpNotOpen" [icon]="icons.caretAltDown" class="downArrowIcon" style="color: #050C32"></kendo-svg-icon>
					<kendo-svg-icon *ngIf="!helpNotOpen" [icon]="icons.caretAltDown" class="downArrowIcon" style="color: white"></kendo-svg-icon>
				</span>
				<span class="k-icon right-btn profile-img">
					<img kendoPopoverAnchor #anchor2="kendoPopoverAnchor" showOn="click" [popover]="userPopover" class='login-icon-setup' src='/assets/icons/ui/user-icon2.png' (click)='togglePopover("userPopoverAnchor")'>
					<kendo-svg-icon *ngIf="userNotOpen" [icon]="icons.caretAltDown" class="downArrowIcon" style="color: #050C32"></kendo-svg-icon>
					<kendo-svg-icon *ngIf="!userNotOpen" [icon]="icons.caretAltDown" class="downArrowIcon" style="color: white"></kendo-svg-icon>
				</span>
			</div>

		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>

<kendo-popover #schedulePopover [width]="80" [callout]="false" (show)="openingSchedule()" (hidden)="closingSchedule()" position="bottom" [offset]="13">
	<ng-template kendoPopoverBodyTemplate>
		<kendo-svg-icon [icon]="icons.caretAltDown" class="downArrowIcon" style="color: white; position: absolute; top: -15px; left: 32px;"></kendo-svg-icon>
		<div class="schedules-popover-body-container">
			<ion-segment mode="ios" (ionChange)="segmentChanged($event)" class="tab-selector-schedules-popover giantButton" [(ngModel)]='navBarStorage.selectedTabSchedules'>
				<ion-segment-button value="manage" class="seg-schedules-popover" [ngClass]="{schedulesTab: navBarStorage.selectedTabSchedules === 'manage' }">
					<ion-label>Manage</ion-label>
				</ion-segment-button>
				<div class="tab-selector-button-divider-schedules-popover"></div>
				<ion-segment-button value="analysis" class="seg-schedules-popover" [ngClass]="{analysisTab: navBarStorage.selectedTabSchedules === 'analysis' }">
					<ion-label>Analysis</ion-label>
				</ion-segment-button>
				<div class="tab-selector-button-divider-schedules-popover"></div>
				<ion-segment-button value="gantt" class="seg-schedules-popover" [ngClass]="{ganttTab: navBarStorage.selectedTabSchedules === 'gantt' }">
					<ion-label>Reports</ion-label>
				</ion-segment-button>
			</ion-segment>
		</div>
	</ng-template>
</kendo-popover>

<kendo-popover
	#myPopover
	position="left"
	title=""
	[width]='381'
	class='test'
>
	<ng-template #bodyRef kendoPopoverBodyTemplate>
		<div style='margin-top: 2px; display: flex;'>
			<span style="white-space: nowrap; margin: auto 0;">
				Click the '+' button to create a new project.
			</span>
			<button kendoButton themeColor="primary" style="width: 68px; margin-top: -2px; margin-left: 8px;" (click)="dismissPopup()">Dismiss</button>
		</div>
	</ng-template>
</kendo-popover>

<kendo-popover #userPopover [width]="200" [callout]="false" (show)="openingUser()" (hidden)="closingUser()" position="bottom" [offset]="13" style="top: 35px">
	<ng-template kendoPopoverTitleTemplate>
		<div class="popover-title">{{userService.user.value?.profile?.email}}</div>
	</ng-template>

	<ng-template kendoPopoverBodyTemplate class='email-identifier' id='popover-body'>
		<!--
		<div class="rw-menu-selection">
			General Settings
		</div>
		-->

		<!--<div class='rw-menu-selection' (click)="openProfile()" *ngIf='navBarStorage._authorizationLevel !== "viewer"'>
			Profile
		</div>-->

		<!--<div class="rw-menu-selection" (click)='anchor.hide(); closeAdminPanel();'>
			User Settings
		</div>-->

		<div class="rw-menu-selection" (click)="loadUrl('https://account.rhino.works/#/me', false)">
			Account Settings
		</div>

		<div class="rw-menu-selection" (click)="loadUrl('https://account.rhino.works/#/settings', false)" *ngIf='navBarStorage._authorizationLevel === "admin" && navBarStorage._highestCompanyTier !== "ANALYTICS-BASIC-MPK" && navBarStorage._highestCompanyTier !== "ANALYTICS-BASIC-APK"'>
			Company Settings
		</div>

		<div class="rw-menu-selection" (click)='closeAdminPanel(); doLogout();'>
			Sign Out
		</div>

	</ng-template>
	<!--
		<ng-template kendoPopoverActionsTemplate>
			<button kendoButton fillMode="flat" themeColor="success">
				<span class="k-icon k-i-import"></span>
				Invite Users
			</button>
			<button kendoButton fillMode="flat" themeColor="info">
				<span class="k-icon k-i-plus-outline"></span>
				Switch Companies
			</button>
		</ng-template> -->
</kendo-popover>

<kendo-popover #helpPopover [width]="200" [callout]="false" (show)="openingHelp()" (hidden)="closingHelp()" position="bottom" [offset]="13" style="top: 35px">
	<ng-template  kendoPopoverTitleTemplate>
		<div class="popover-title">Help Menu</div>
	</ng-template>

	<ng-template kendoPopoverBodyTemplate class='popover-body'>
		<div class="rw-menu-selection" (click)="loadUrl('https://docs.rhino.works/docs/aegis-analytics/analytics', true)">
			Knowledgebase
		</div>

		<div class="rw-menu-selection" (click)="openJiraFeedbackCollector()">
			Contact Us
		</div>

		<div class="rw-menu-selection" (click)="loadUrl('https://status.rhino.works/', true)">
			Status
		</div>
	</ng-template>
</kendo-popover>

<kendo-popover #projectMenuPopover [width]="200" [callout]="false" (show)="openingProject()" (hidden)="closingProject()" [offset]="13" position="bottom" id='test' [style.background-color]="'black'">


	<ng-template  kendoPopoverTitleTemplate>
		<div class="popover-title">Project Menu</div>
	</ng-template>

	<ng-template kendoPopoverBodyTemplate class='popover-body'>



		<div class="rw-menu-selection" (click)="openUpdateMenu()">
			Project Settings
		</div>
		@if ((_projectDashboardService.$currentProjectData | async)?.sharePoint === '') {
			<div kendoTooltip style="text-align: center;">
				<div [title]='"Add a link to enable (Project Settings → Admin tab)"' (click)='$event.preventDefault(); $event.stopPropagation();'>
					<div class="rw-menu-selection disabled-item" (click)="openProjectFiles()" *ngIf='(_projectDashboardService.$currentProjectReport | async).permissionData?.role !== "viewer"  && !_projectDashboardService.$currentProjectData.value?.isArchived'>
						Project Files
					</div>
				</div>
			</div>
		} @else {
			<div class="rw-menu-selection" (click)="openProjectFiles()" *ngIf='(_projectDashboardService.$currentProjectReport | async).permissionData?.role !== "viewer"  && !_projectDashboardService.$currentProjectData.value?.isArchived'>
				Project Files
			</div>
		}
		<div class="rw-menu-selection" (click)="openBulkUpdate()" *ngIf='(_projectDashboardService.$currentProjectReport | async).permissionData?.role !== "viewer" && (user?.userType !== "saasRisk" || (user?.userType === "saasRisk" && _projectDashboardService?.$currentProjectData?.value?.updateIds?.length === 0)) && !_projectDashboardService.$currentProjectData.value?.isArchived'>
			{{user?.userType === "saasRisk" ? 'Upload Schedule' : 'Add Update'}}
		</div>
		<div class="rw-menu-selection" (click)="doExportPublish()" *ngIf='user?.userType !== "saasRisk" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK"'>
			<kendo-circularprogressbar
				[indeterminate]="true"
				[value]="30"
				[progressColor]='"#ffb500"'
				class='indeterminate-progressbar'
				*ngIf='doingExportPost'
			></kendo-circularprogressbar>
			<span [class.showing-loading-spinner]='doingExportPost'>
				Export Page (PDF)
			</span>
		</div>
		<div class="rw-menu-selection" (click)="toggleMonteCarlo()" *ngIf='user?.userType === "saasRisk"'>
			<kendo-circularprogressbar
				[indeterminate]="true"
				[value]="30"
				[progressColor]='"#ffb500"'
				class='indeterminate-progressbar'
				*ngIf='navBarStorage.doingMonteCarloToggle'
			></kendo-circularprogressbar>
			<span [class.showing-loading-spinner]='navBarStorage.doingMonteCarloToggle'>
				{{_projectDashboardService?.$currentProjectData?.value?.showMonteCarlo ? 'Hide ' : 'Show '}}Monte Carlo
			</span>
		</div>

	</ng-template>
</kendo-popover>
