import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectCompletionTrendingComponent } from './project-completion-trending.component';
import { IonicModule } from '@ionic/angular';
import { TooltipMenuModule } from '../../../portfolio/tooltip-menu/tooltip-menu.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { GridModule } from '@progress/kendo-angular-grid';
import { AegisChartModule } from '../../../shared/chart/chart.module';
import { GridLayoutModule } from '@progress/kendo-angular-layout';

@NgModule({
	declarations: [ProjectCompletionTrendingComponent],
	imports: [
		CommonModule,
		IonicModule,
		TooltipMenuModule,
		MatExpansionModule,
		GridModule,
		AegisChartModule,
		GridLayoutModule,
	],
	exports: [ProjectCompletionTrendingComponent],
})
export class ProjectCompletionTrendingModule {}
