import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CriticalPathReliabilityComponent } from './risk-critical-path-reliability/critical-path-reliability.component';
import { IonicModule } from '@ionic/angular';
import { TooltipMenuModule } from '../../../portfolio/tooltip-menu/tooltip-menu.module';
import { HighchartsChartModule } from 'highcharts-angular';
import { ChartModule } from '@progress/kendo-angular-charts';
import { AegisChartModule } from '../../../shared/chart/chart.module';
import { GridLayoutModule } from '@progress/kendo-angular-layout';

@NgModule({
	declarations: [CriticalPathReliabilityComponent],
	imports: [
		CommonModule,
		IonicModule,
		TooltipMenuModule,
		HighchartsChartModule,
		ChartModule,
		AegisChartModule,
		GridLayoutModule,
	],
	exports: [CriticalPathReliabilityComponent],
})
export class RiskCriticalPathReliabilityModule {}
