<div class='card notes-visualizer-anchor regular-container' [class.saas-risk-component]='user?.userType === "saasRisk"'>
	@if (!visualizer && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK") {
		<div style="break-after: page">
			<app-schedule-impact-analysis [$projectData]='$projectData'></app-schedule-impact-analysis>
		</div>
	}
	@if (visualizer && !scheduleStorage.isLoading) {
		<div style='padding: 15px;'>
			<div class *ngFor='let element of (dataSource !== null && dataSource !== undefined) ? dataSource : []' style='margin-bottom: 0.5em;'>
				{{element.updateName}}<br>
				<span *ngIf='element.criticalPathNotes !== "" && element.criticalPathNotes !== null && element.criticalPathNotes !== undefined'><strong>{{element.criticalPathNotes}}</strong></span>
				<span *ngIf='element.criticalPathNotes === "" || element.criticalPathNotes === null || element.criticalPathNotes === undefined'><em>No Critical Path Notes Provided for this Update</em></span>
			</div>
		</div>
	}
</div>

@if (currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK") {
	<app-driving-path></app-driving-path>
	<app-critical-path-comparison [selectedDelayActivity]='projectService.selectedDelayActv' (selectionByClick)='ganttSelection($event)'></app-critical-path-comparison>
	<app-schedule-comparison></app-schedule-comparison>
}


