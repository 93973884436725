import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
	GanttDependency,
	DependencyType,
	GanttComponent,
	TimelineViewType,
	CellClickEvent,
	TaskClickEvent,
} from '@progress/kendo-angular-gantt';
import { Task } from '../../../../models/ChartSettings';
import { ExpandedMetrics } from '../../../../models/ProjectReport/ExpandedMetrics';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { TaskArrayInterface, TaskPredArrayInterface, UpdateInterface } from '../../../../models/Update/Task';
import {
	Interval,
	addDays,
	isAfter,
	isBefore,
	startOfDay,
	getMonth,
	isSameDay,
	differenceInCalendarDays,
	isValid,
} from 'date-fns';
import { SortDescriptor } from '@progress/kendo-data-query';
import { UserService } from '../../../../services/common/user.service';
import { IButton } from '../../../../models/Project';
import { ProjectReportInterface } from '../../../../models/ProjectReport/ProjectReport';
import {
	ActvCodeFilterItem,
	ActvCompletionActv,
	SubCodeFilterItem,
} from '../activity-completion/activity-completion.component';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { XerActivity, XerActivityCode, XerActivityType, XerData, XerTaskPredecessor } from '@rhinoworks/xer-parse';
import { dataFromXer } from '../../../../util/tasks';
import { union } from 'lodash';
import { haveCommonItem } from '../../../../util/strings';
import { caretAltDownIcon } from '@progress/kendo-svg-icons';

@Component({
	selector: 'app-schedule-lookahead',
	templateUrl: './schedule-lookahead.component.html',
	styleUrls: ['./schedule-lookahead.component.scss'],
})
export class ScheduleLookaheadComponent implements OnInit {
	@Input() isOverview: boolean = false;
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	@ViewChild(GanttComponent)
	public gantt: GanttComponent;
	public data: Task[] = [];
	public dependencies: GanttDependency[] = [];
	allActivities = new Map<number, TaskArrayInterface>([]);
	criticalActivities = new Map<number, TaskArrayInterface>([]);
	allActivitiesActCodeKey = new Map<string, TaskArrayInterface>([]);
	allRelationships = new Map<number, TaskPredArrayInterface>([]);
	allRelationshipsPredIdKey = new Map<number, Set<TaskPredArrayInterface>>([]);
	linkTypeDictionary = {
		PR_FS: DependencyType.FS,
		PR_SF: DependencyType.SF,
		PR_FF: DependencyType.FF,
		PR_SS: DependencyType.SS,
	};
	public activeView: TimelineViewType = 'year';
	public slotWidth: number = 150;
	legendRight = 22;
	loading = false;
	public timespanButtons: IButton[] = [
		{
			text: '30',
			value: 30,
			selected: true,
		},
		{
			text: '60',
			value: 60,
		},
		{
			text: '90',
			value: 90,
		},
	];
	public taskTypeButtons: IButton[] = [
		{
			text: 'Critical',
			value: 0,
			selected: true,
		},
		{
			text: 'Show All',
			value: 1,
		},
	];
	selectedTimespan: Interval = {
		start: new Date(),
		end: addDays(new Date(), 30),
	};
	selectedTimespanDays = 30;
	dataDate: Date;
	allTimespans = {
		30: {
			start: new Date(),
			end: addDays(new Date(), 30),
		},
		60: {
			start: new Date(),
			end: addDays(new Date(), 60),
		},
		90: {
			start: new Date(),
			end: addDays(new Date(), 90),
		},
	};
	allGanttTasks = {
		30: {
			critical: [],
			nonCritical: [],
		},
		60: {
			critical: [],
			nonCritical: [],
		},
		90: {
			critical: [],
			nonCritical: [],
		},
	};
	showNonCriticalActivities = false;
	public selectionState: Set<number> = new Set();
	showLegend = true;
	_userSelectedSort: SortDescriptor[] = [];
	public sort: SortDescriptor[] = [
		{
			field: 'start',
			dir: 'asc',
		},
	];
	user: any;
	totalActivityCodes = [];
	public selectedActivityCodes: any[] = [];
	allActivityCodes: ActvCodeFilterItem[] = [];
	allSubCodes;
	codesTag: string = '';
	icons = {
		caretDown: caretAltDownIcon,
	};

	constructor(
		public project: ProjectDashboardService,
		public userService: UserService,
		public storage: ScheduleStorageService
	) {}

	ngOnInit() {
		this.project.$currentProjectReport.pipe(takeUntil(this._unsubscribeAll)).subscribe((report) => {
			if (this.allActivities.size > 0 && this.storage.$allUpdates.value.length === report.updateIds.length) {
				this.loadNewDisplaySet(this.storage.$allUpdates.value, report, false);
			}
		});
		this.storage.$allUpdates.pipe(takeUntil(this._unsubscribeAll)).subscribe((updates: UpdateInterface[]) => {
			if (this.project.$currentProjectReport.value && this.project.$currentProjectReport.value) {
				this.loadNewDisplaySet(updates, this.project.$currentProjectReport.value, false);
			}
		});
		this.userService.user.subscribe((data) => {
			if (data) {
				this.user = data;
			}
		});
		if (this.isOverview) {
			this.timespanButtons = [
				{
					text: '30',
					value: 30,
					selected: false,
				},
				{
					text: '60',
					value: 60,
					selected: false,
				},
				{
					text: '90',
					value: 90,
					selected: true,
				},
			];
			this.selectedTimespan = {
				start: this.allTimespans[90].start,
				end: this.allTimespans[90].end,
			};
			this.selectedTimespanDays = 90;
		}
		this.loadNewDisplaySet(this.storage.$allUpdates.value, this.project.$currentProjectReport.value, false);
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	/**
	 * initializes relevant data arrays based on new project report values
	 */
	async loadNewDisplaySet(
		updates: UpdateInterface[],
		report: Pick<ProjectReportInterface, 'projectOverview' | 'criticalLookahead'>,
		skipFilterReset: boolean = false
	) {
		this.loading = true;
		this.dependencies = [];
		this.dataDate = startOfDay(new Date(report?.projectOverview?.dataDate));
		this.allTimespans = {
			30: {
				start: this.dataDate,
				end: addDays(this.dataDate, 30),
			},
			60: {
				start: this.dataDate,
				end: addDays(this.dataDate, 60),
			},
			90: {
				start: this.dataDate,
				end: addDays(this.dataDate, 90),
			},
		};
		const criticalActivities = report?.criticalLookahead?.criticalActivityArray;
		if (criticalActivities === undefined) {
			return;
		}
		const selectedActivityCodes = new Set<string>(this.selectedActivityCodes.map((item) => item.shortName));
		const latestUpdateXerData = await this.storage.grabUpdateXer(updates[updates.length - 1]?._id);
		const currActvCodes = await this.storage.grabUpdateTable<XerActivityCode>(
			updates[updates.length - 1]._id,
			'ACTVCODE'
		);
		const currTaskActv = await this.storage.grabUpdateTable<{
			task_id: number;
			actv_code_type_id: number;
			actv_code_id: number;
			proj_id: number;
		}>(updates[updates.length - 1]._id, 'TASKACTV');
		const currActvsByTask: Record<
			number,
			Array<{
				task_id: number;
				actv_code_type_id: number;
				actv_code_id: number;
				proj_id: number;
			}>
		> = {};
		for (const ta of currTaskActv) {
			if (!currActvsByTask[ta.task_id]) {
				currActvsByTask[ta.task_id] = [];
			}
			currActvsByTask[ta.task_id].push(ta);
		}
		const currAllTasks = await this.storage.grabUpdateTable<XerActivity>(updates[updates.length - 1]._id, 'TASK');
		this.allActivities = new Map<number, XerActivity>(currAllTasks.map((task) => [task.task_id, task]));
		this.allActivitiesActCodeKey = new Map<string, XerActivity>(
			currAllTasks.map((task) => [task.task_code.toString(), task])
		);
		const currAllPreds = await this.storage.grabUpdateTable<XerTaskPredecessor>(
			updates[updates.length - 1]._id,
			'TASKPRED'
		);
		for (const relationship of currAllPreds) {
			this.allRelationships.set(relationship.task_pred_id, relationship as TaskPredArrayInterface);
			let set: Set<TaskPredArrayInterface> = this.allRelationshipsPredIdKey.get(relationship.pred_task_id);
			if (set === undefined) {
				set = new Set<TaskPredArrayInterface>();
				this.allRelationshipsPredIdKey.set(relationship.pred_task_id, set);
			}
			set.add(relationship as TaskPredArrayInterface);
			this.allRelationshipsPredIdKey.set(relationship.pred_task_id, set);
		}
		this.allActivityCodes = this.generateActivityCodeFilter(latestUpdateXerData);
		const allSubCodes = [];
		this.allActivityCodes.forEach((code) => {
			code.subCodes.forEach((subCode) => {
				const subcodeColumn = {
					title: subCode.name,
					shortName: subCode.shortName,
				};

				allSubCodes.push(subcodeColumn);
			});
		});
		this.allSubCodes = allSubCodes;
		const ganttTasks: Task[] = [];
		this.criticalActivities.clear();
		this.allGanttTasks = {
			30: {
				critical: [],
				nonCritical: [],
			},
			60: {
				critical: [],
				nonCritical: [],
			},
			90: {
				critical: [],
				nonCritical: [],
			},
		};
		let k: number = 0;
		const expandedMetricsInterval = setInterval(() => {
			if (this.allActivitiesActCodeKey.size > 0) {
				clearInterval(expandedMetricsInterval);
				criticalActivities.forEach((activity, i) => {
					const fullActivity = this.allActivitiesActCodeKey.get(activity.activityId);
					this.criticalActivities.set(fullActivity.task_id, fullActivity);
				});

				const currShortCodes = new Map<number, string[]>(
					currAllTasks.map((task) => [
						task.task_id,
						currActvCodes
							.filter((ac) => currActvsByTask[task.task_id]?.some((ta) => ta.actv_code_id === ac.actv_code_id))
							.map((t) => t.short_name) || [],
					])
				);

				this.allActivities.forEach((activity, id) => {
					const matchingRelationships = this.allRelationshipsPredIdKey.get(activity?.task_id);
					const subtasks: Task[] = [];
					if (matchingRelationships !== undefined) {
						// subtasks = this.getSubtasksAndRelationships(activity, matchingRelationships);
					}
					const startDate = startOfDay(new Date(activity?.act_start_date || activity?.early_start_date));
					const endDate = startOfDay(new Date(activity?.act_end_date || activity?.early_end_date));
					const totalFloat = activity?.total_float_hr_cnt ? activity?.total_float_hr_cnt / 8 : 0;
					let completionRatio: number = 0;
					const dataDate: Date = new Date(report.projectOverview.dataDate);
					const start: Date = startOfDay(new Date(activity?.act_start_date || activity?.early_start_date));
					const end: Date = startOfDay(new Date(activity?.act_end_date || activity?.early_end_date));
					if (isAfter(dataDate, start)) {
						const durationUntilDataDate: number = differenceInCalendarDays(dataDate, start);
						const taskDuration: number = differenceInCalendarDays(end, start);
						completionRatio = durationUntilDataDate / (taskDuration || 1);
						completionRatio = completionRatio > 1 ? 1 : completionRatio;
					}
					const title = activity?.task_code ? activity.task_code + ' - ' + activity?.task_name : activity?.task_name;
					const ganttTask = {
						id: activity?.task_id,
						title,
						start: startDate,
						startIsAct: isValid(new Date(activity?.act_start_date)),
						end: endDate,
						endIsAct: isValid(new Date(activity?.act_end_date)),
						completionRatio,
						// subtasks,
						tf: totalFloat,
						isCritical: this.criticalActivities.get(activity.task_id) !== undefined,
						activityCodes: (currShortCodes.get(activity?.task_id) || []).join(', '),
					};

					//assign gantt task to all timespans that it should be in for faster toggling between timespans later
					for (const timespan in this.allTimespans) {
						if (
							(activity.task_type === 'TT_Task' ||
								activity.task_type === 'TT_Mile' ||
								activity.task_type === 'TT_FinMile') &&
							(activity.status_code === 'TK_NotStart' || activity.status_code === 'TK_Active') &&
							(isBefore(ganttTask.start, this.allTimespans[timespan].end) ||
								isSameDay(ganttTask.start, this.allTimespans[timespan].end)) &&
							(isAfter(ganttTask.end, this.dataDate) || isSameDay(ganttTask.end, this.dataDate)) &&
							(!selectedActivityCodes.size ||
								haveCommonItem(new Set(currShortCodes.get(activity.task_id)), selectedActivityCodes))
						) {
							const accessor = ganttTask.isCritical ? 'critical' : 'nonCritical';
							if (this.allGanttTasks[timespan][accessor].findIndex((task) => task.id === ganttTask.id) === -1) {
								this.allGanttTasks[timespan][accessor].push(ganttTask);
							}
						}
					}
				});

				//set defaults to be the lowest timespan with critical activities > the lowest timespan with activities > none
				if (!skipFilterReset) {
					this.selectedTimespanDays = this.isOverview
						? 90
						: this.allGanttTasks['30'].critical.length
							? 30
							: this.allGanttTasks['60'].critical.length
								? 60
								: this.allGanttTasks['90'].critical.length
									? 90
									: this.allGanttTasks['30'].nonCritical.length
										? 30
										: this.allGanttTasks['60'].nonCritical.length
											? 60
											: this.allGanttTasks['90'].nonCritical.length
												? 90
												: 30;

					this.showNonCriticalActivities = !this.allGanttTasks['90'].critical.length;
				}
				this.selectedTimespan = {
					start: this.allTimespans[this.selectedTimespanDays].start,
					end: this.allTimespans[this.selectedTimespanDays].end,
				};
				this.timespanButtons.forEach((btn) => {
					btn.selected = btn.value === this.selectedTimespanDays;
				});
				this.taskTypeButtons.forEach((btn) => {
					btn.selected = btn.value === 0 ? !this.showNonCriticalActivities : this.showNonCriticalActivities;
				});

				this.loadTasksByTimespan();
				this.loading = false;
				this.updateSlotWidth();
			}
			if (k > 50) {
				clearInterval(expandedMetricsInterval);
				this.loadTasksByTimespan();
				this.loading = false;
				this.updateSlotWidth();
			}
			k++;
		}, 200);
		if (!skipFilterReset) {
			const allSubCodes = [];
			this.allActivityCodes.forEach((code) => {
				code.subCodes.forEach((subCode) => {
					const subcodeColumn = {
						title: subCode.name,
						shortName: subCode.shortName,
					};

					allSubCodes.push(subcodeColumn);
				});
			});
			this.allSubCodes = allSubCodes;
		}
	}

	drawDataDateLine(dataDate: Date): void {
		const i: number = 0;
		const waitForDOMInterval = setInterval(() => {
			const timeline = document.getElementsByClassName('k-gantt-timeline');
			if (timeline.length > 0 || i > 500) {
				clearInterval(waitForDOMInterval);
				const header = timeline.item(0).querySelector('.k-grid-header');
				if (this.gantt) {
					const minStart: Date = this.gantt.timelineSlots[0].start;
					const maxEnd: Date = this.gantt.timelineSlots[this.gantt.timelineSlots.length - 1].end;
					const totalTimespan: number = differenceInCalendarDays(maxEnd, minStart);
					const daysElapsed: number = differenceInCalendarDays(dataDate, minStart) + 0.75; // the .75 is not an exact science
					const rightPercent: number = 1 - daysElapsed / (totalTimespan || 1);
					const rightValue: number = header.clientWidth * rightPercent;
					const dataDateLine = document.getElementsByClassName('data-date-gantt-line');
					if (dataDateLine?.length) {
						dataDateLine.item(0).setAttribute('style', 'right:' + rightValue + 'px');
					}
					//hides data date line if its past the gantt timeline
					const ganttTimeline = timeline.item(0).getBoundingClientRect();
					const dateLine = dataDateLine?.item(0).getBoundingClientRect();

					if (dateLine.left < ganttTimeline.left || dateLine.left > ganttTimeline.right) {
						dataDateLine.item(0).setAttribute('style', 'display: none');
					}
				}
			}
		}, 200);
	}

	/**
	 * recursively creates subtask Task[] and updates the dependencies gantt input with relationships involving showing tasks.
	 * for now it just makes the dependencies.
	 * @param fullActivity
	 * @param matchingRelationships
	 */
	getSubtasksAndRelationships(
		fullActivity: TaskArrayInterface,
		matchingRelationships: Set<TaskPredArrayInterface>
	): Task[] {
		const subtasks: Task[] = [];
		if (matchingRelationships?.size > 0) {
			matchingRelationships.forEach((rel) => {
				const matchingSubtask = this.allActivities.get(rel.task_id);
				if (matchingSubtask !== undefined) {
					/*const subtaskRelationships = this.allRelationshipsPredIdKey.get(matchingSubtask?.task_id);
					// this recursion is broken for now
					// const subtasksSubtasks = this.getSubtasksAndRelationships(matchingSubtask, subtaskRelationships);
					const totalFloat = matchingSubtask?.total_float_hr_cnt ? matchingSubtask?.total_float_hr_cnt / 8 : 0;
					const newSubtask: Task = {
						id: matchingSubtask?.task_id,
						title: matchingSubtask?.task_name,
						start: startOfDay(new Date(matchingSubtask?.early_start_date)),
						end: startOfDay(new Date(matchingSubtask?.early_end_date)),
						// subtasks: subtasksSubtasks,
						tf: totalFloat,
						isCritical: this.criticalActivities.get(fullActivity.task_id) !== undefined,
					};
					subtasks.push(newSubtask);*/

					const newRelationship: GanttDependency = {
						id: rel.task_pred_id,
						fromId: rel.pred_task_id,
						toId: rel.task_id,
						type: this.linkTypeDictionary[rel.pred_type],
					};
					if (!this.dependencies.some((dep) => dep.id === newRelationship.id)) {
						this.dependencies.push(newRelationship);
					}
				}
			});
		}
		return subtasks;
	}

	/**
	 * applies style class to timeline task bars
	 * @param dataItem
	 */
	public taskCallback(dataItem: any): any {
		return dataItem?.isCritical ? 'red' : 'green';
	}

	/**
	 * buttongroup selection change handler
	 * @param selected
	 * @param btn
	 * @param isTimespan
	 */
	public selectionChange(selected: boolean, btn: IButton, isTimespan = false): void {
		btn.selected = selected;
		if (!isTimespan && selected) {
			this.showNonCriticalActivities = btn.value === 1;
		}
		if (isTimespan && selected) {
			this.selectedTimespan = {
				start: this.allTimespans[btn.value].start,
				end: this.allTimespans[btn.value].end,
			};
			this.selectedTimespanDays = btn.value;
		}
		this.loadTasksByTimespan();
	}

	/**
	 * grabs tasks for selected timespan without iterating/recreating the array
	 */
	loadTasksByTimespan(): void {
		this.loading = true;
		let ganttTasks = structuredClone(this.allGanttTasks[this.selectedTimespanDays].critical);
		if (this.showNonCriticalActivities) {
			ganttTasks = [...ganttTasks, ...structuredClone(this.allGanttTasks[this.selectedTimespanDays].nonCritical)];
		}

		if (this.selectedActivityCodes?.length <= 0) {
			let totalActivityCodes = [];
			ganttTasks.forEach((item) => {
				totalActivityCodes = union(item?.activityCodes?.split(', '), totalActivityCodes);
			});
			this.totalActivityCodes = totalActivityCodes;

			this.allActivityCodes.forEach((code) => {
				let disabledCount = 0;
				code.subCodes.forEach((subCode) => {
					subCode.alwaysDisabled = false;
					if (!totalActivityCodes.includes(subCode.shortName)) {
						subCode.alwaysDisabled = true;
						disabledCount += 1;
					}
				});

				code.alwaysDisabled = disabledCount === code.subCodes.length;
			});
		}

		this.data = ganttTasks;
		this.loading = false;
		this.updateSlotWidth();
	}

	/**
	 * 'show non-critical activites' switch value update handler
	 * @param show
	 */
	showSelected(show: boolean) {
		this.loadTasksByTimespan();
	}

	/**
	 * get keys from selected rows
	 */
	public get selectedKeys(): number[] {
		return Array.from(this.selectionState);
	}

	/**
	 * used by gantt to know if a row is selected
	 * @param dataItem
	 */
	public isSelected = (dataItem: Task): boolean => this.selectionState.has(dataItem.id);

	/**
	 * update selected list based on user click
	 * @param dataItem
	 * @param sender
	 * @param originalEvent
	 */
	public toggleSelection({ dataItem, sender, originalEvent }: CellClickEvent | TaskClickEvent): void {
		// prevents context menu opening
		originalEvent.preventDefault();

		if (this.isSelected(dataItem)) {
			this.selectionState.delete(dataItem.id);
		} else {
			this.selectionState.add(dataItem.id);
		}

		// manually trigger the Gantt to re-evaluate the isSelected callback for each task
		sender.updateView();
	}

	/**
	 * auto-fit gantt timeline pane width to container
	 */
	updateSlotWidth(skipLoadToggle = false): void {
		//timeout so the dom can finish updating to the expanded/collapsed width
		setTimeout(() => {
			const containerEl = document.getElementsByClassName('k-gantt-timeline')[0] as HTMLElement;
			const headerEl = containerEl?.children[0].children[0].children[0].children[0].children[1];
			const numberOfSubdivisions = headerEl?.children.length ?? 0;
			const containerWidth = containerEl?.getBoundingClientRect().width;
			if (numberOfSubdivisions) {
				this.slotWidth = containerWidth / numberOfSubdivisions;
			}
			this.sort = this._userSelectedSort.length ? this._userSelectedSort : [{ field: 'start', dir: 'asc' }];
			setTimeout(() => {
				this.loading = true;
				setTimeout(() => {
					this.loading = false;
					// this.gantt.updateView();
					this.updateLegendPositioning();
					setTimeout(() => {
						let minStart = this.data[0]?.start;
						this.data.forEach((bar: Task) => {
							if (isBefore(bar?.start, minStart)) {
								minStart = bar?.start;
							}
						});
						//show gantt vertical line borders on a yearly basis once zoom is small enough
						if (this.slotWidth <= 20 && minStart !== undefined) {
							const columns = document.getElementsByClassName('k-gantt-columns');
							const row = columns.item(0)?.children[1]?.children[0];
							const startNumber = 13 - getMonth(minStart);
							if (startNumber < row.children.length - 1) {
								for (let i = startNumber; i < row.children.length; i += 12) {
									row.children[i].setAttribute('style', 'border-left: 1px solid rgba(0, 0, 0, 0.08)');
								}
							}
						}
						this.drawDataDateLine(this.dataDate);
					});
				}, 500);
			}, 200);
		}, 500);
	}
	// /**
	//  * calculate slot width and update chart to show more of the chart in one frame
	//  */
	// updateSlotWidth(): void {
	// 	//timeout needed to ensure the elements have been created for the DOM (dont need to be visible on the screen yet though)
	// 	setTimeout(() => {
	// 		const timelinePaneOptions = document.getElementsByClassName('k-pane-static');
	// 		if (timelinePaneOptions?.length === 0) {
	// 			return;
	// 		}
	// 		const timelinePane = timelinePaneOptions[0] as HTMLElement;
	// 		const maxWidth = timelinePane.offsetWidth - 1;
	// 		const timelineHeaderWrapOptions = document.getElementsByClassName('k-grid-header-wrap');
	// 		let timelineHeaderWrap;
	// 		for (let i = 0; i < timelineHeaderWrapOptions.length; i++) {
	// 			const optionAsElement = timelineHeaderWrapOptions[i] as HTMLElement;
	// 			const offsetParentClassList = optionAsElement.offsetParent.classList;
	// 			if (offsetParentClassList.contains('k-gantt-timeline')) {
	// 				timelineHeaderWrap = optionAsElement;
	// 			}
	// 		}
	// 		if (timelineHeaderWrap !== undefined) {
	// 			const tr = timelineHeaderWrap.children[0].children[0].children[0];
	// 			const trChildren = tr.children;
	// 			let colspanTotal = 0;
	// 			for (let j = 0; j < trChildren.length; j++) {
	// 				const child = trChildren[j];
	// 				const colspan = child.colSpan;
	// 				colspanTotal += colspan;
	// 			}
	// 			this.loading = true;
	// 			//-12 is bc the pane where the bars are is 12px smaller than the width of the header pane due to the scroll bar/padding/outline
	// 			this.slotWidth = ((maxWidth - 12) * 7) / (colspanTotal || 1);
	// 			//forces chart to rerender with correct slotWidth
	// 			setTimeout(() => {
	// 				this.loading = false;
	// 				this.sort = this._userSelectedSort.length ? this._userSelectedSort : [{ field: 'start', dir: 'asc' }];
	// 			});
	// 		}
	// 		//forces dependency arrows to move from the clump in the top left to their correct positions
	// 		this.gantt.updateView();
	// 		this.updateLegendPositioning();
	// 		setTimeout(() => {
	// 			try {
	// 				document.getElementById('placehold-render-gantt').classList.add('finished-loading-gantt');
	// 			} catch (e) {}
	// 		}, 500);
	// 	}, 1000);
	// }

	/**
	 * updates the horizontal positioning of the legend to be in the center of the right pane
	 */
	updateLegendPositioning(): void {
		const timelinePane = document.getElementsByClassName('k-gantt-timeline')[0];
		if (timelinePane) {
			const timelinePaneWidth = timelinePane.getBoundingClientRect().width;
			const right = (timelinePaneWidth - 389) / 2;
			this.legendRight = right > 22 ? right : 22;
		}
	}

	/**
	 * handles the treelist pane collapse/expand event
	 * @param ev
	 */
	treelistPaneToggled(ev): void {
		setTimeout(() => {
			this.updateLegendPositioning();
		});
	}

	/**
	 * show/hide the legend
	 * @param newVal
	 */
	toggleLegend(newVal): void {
		this.showLegend = newVal;
	}

	/**
	 * saves user selected sort for later reference
	 * @param sort
	 */
	updateSort(sort): void {
		this._userSelectedSort = sort;
	}

	/**
	 * multiselect valueChange handler
	 * @param ev
	 */
	filterChanged(ev?: ActvCodeFilterItem[]): void {
		if (this.loading) {
			return;
		}
		this.updateTagText(ev);

		this.loadNewDisplaySet(this.storage.$allUpdates.value, this.project.$currentProjectReport.value, true).then(() => {
			this.allActivityCodes.forEach((code) => {
				let disabledCount = 0;
				code.subCodes.forEach((subCode) => {
					subCode.alwaysDisabled = false;
					if (!this.totalActivityCodes.includes(subCode.shortName)) {
						subCode.alwaysDisabled = true;
						disabledCount += 1;
					}
				});

				code.alwaysDisabled = disabledCount === code.subCodes.length;
			});

			/*this.allActivityCodes.forEach((code) => {
				code.disabled =
					this.selectedActivityCodes.length > 0
						? !this.selectedActivityCodes.some((c) => c.parentName === code.name)
						: false;
			});*/
		});
	}

	/**
	 * multiselect tag text updater
	 * @param ev
	 */
	updateTagText(ev?: ActvCodeFilterItem[]): void {
		if (ev?.length === 0) {
			this.codesTag = '';
		} else {
			const topLevelCode = ev.find((item) => item?.subCodes?.length > 0);
			if (topLevelCode !== undefined) {
				this.codesTag = topLevelCode.name;
			} else {
				this.codesTag = ev?.length === 1 ? ev[0].name : ev?.length + ' codes selected';
			}
		}
	}

	/**
	 * generate activity code/subcode items for the multiselect filter
	 * @param data
	 */
	generateActivityCodeFilter(data: XerData): ActvCodeFilterItem[] {
		const allActivityCodes: ActvCodeFilterItem[] = [];
		const actvTypes = dataFromXer<XerActivityType>(data, 'ACTVTYPE');
		actvTypes.forEach((actvType) => {
			const code: ActvCodeFilterItem = {
				alwaysDisabled: false,
				disabled:
					this.selectedActivityCodes?.length === 0
						? false
						: !this.selectedActivityCodes.some(
								(selActvCode) =>
									selActvCode.shortName === actvType.actv_code_type ||
									(selActvCode?.parentName === actvType.actv_code_type && selActvCode?.parentName !== undefined)
							),
				id: actvType.actv_code_type_id,
				name: actvType.actv_code_type.toString(),
				sequenceNumber: Number(actvType.seq_num),
				shortName: actvType.actv_code_type,
				subCodes: [],
				totalAssignments: 0,
			};
			allActivityCodes.push(code);
		});
		const actvCodes = dataFromXer<XerActivityCode>(data, 'ACTVCODE');
		actvCodes?.forEach((actvCode) => {
			const parent = allActivityCodes.find((code) => code.id === actvCode.actv_code_type_id);
			if (parent !== undefined) {
				const subCode: SubCodeFilterItem = {
					alwaysDisabled: false,
					disabled: false,
					id: actvCode.actv_code_id,
					name: actvCode?.actv_code_name?.toString() || actvCode?.short_name?.toString(),
					parentName: parent.name,
					sequenceNumber: actvCode.seq_num,
					shortName: actvCode.short_name,
					totalAssignments: actvCode.total_assignments || 0,
				};
				if (actvCode.total_assignments > 0) {
					parent.subCodes.push(subCode);
					parent.totalAssignments += actvCode.total_assignments;
				}
			}
		});
		return allActivityCodes.filter((code) => code.totalAssignments > 0);
	}

	public itemDisabled(dataItem: any) {
		return dataItem.alwaysDisabled || dataItem.disabled;
	}
}
