import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { LegendItemClickEvent, PlotBand, RenderEvent } from '@progress/kendo-angular-charts';
import { Rect } from '@progress/kendo-drawing/dist/npm/geometry/rect';
import { Group, Path, Text } from '@progress/kendo-drawing';
import { AxisSettings, PlotLineType, SeriesDataSettings } from '../../../models/ChartSettings';

@Component({
	selector: 'app-chart',
	templateUrl: './chart.component.html',
	styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, OnDestroy {
	@Input() title: string;
	@Input() subtitle: string;
	@Input() categories: string[];
	@Input() seriesData: SeriesDataSettings[];
	@Input() legendVisible: boolean;
	@Input() valueAxis: AxisSettings[];
	@Input() plotLines: PlotLineType[];
	@Input() isActivityCompletionChart: boolean;
	@Input() isSPIChart: boolean;
	@Input() isScheduleCompressionChart: boolean;
	@Input() legendClickable: boolean = false;
	@Input() categoryPlotBands: PlotBand[] = [];
	@Output() legendItemClicked = new EventEmitter<string>();
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	seriesColors = ['#ff6358', '#78d237', '#28b4c8', '#2d73f5', '#aa46be', '#000000'];
	public crossingValues: number[] = [0, 10000];

	constructor() {}

	ngOnInit() {}

	ngOnDestroy() {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	/**
	 * function called on render of kendo chart. used to draw additional plot lines
	 * @param args
	 */
	public onRender = (args: RenderEvent): void => {
		const chart = args.sender;

		// get the axes
		const valueAxis = chart.findAxisByName(this.valueAxis[0]?.name || 'valueAxis');
		const categoryAxis = chart.findAxisByName('categoryAxis');

		if (this.plotLines !== undefined) {
			this.plotLines.forEach((plotLine: PlotLineType) => {
				// get the coordinates of the value at which the plot band will be rendered
				const valueSlot = valueAxis.slot(plotLine.value) as Rect;

				// get the coordinates of the entire category axis range
				const range = categoryAxis.range();
				const categorySlot = categoryAxis.slot(range.min as number, range.max as number) as Rect;

				// draw the plot band based on the found coordinates
				const line = new Path({
					stroke: {
						color: plotLine.color,
						width: plotLine.width,
						dashType: plotLine.dashType,
					},
				})
					.moveTo(valueSlot.origin)
					.lineTo(categorySlot.topRight().x, valueSlot.origin.y);

				const label = new Text(plotLine.label, [0, 0], {
					fill: {
						color: plotLine.color,
					},
					font: '14px',
				});
				const bbox = label.bbox();
				label.position([categorySlot.topRight().x - bbox.size.width, valueSlot.origin.y - bbox.size.height]);

				const group = new Group();
				group.append(line, label);

				// Draw on the Chart surface to overlay the series
				//chart.surface.draw(group);

				// Or as a first element in the pane to appear behind the series
				chart.findPaneByIndex(0).visual.insert(0, group);
			});
		}
	};

	/**
	 * swaps the axis line for the pct chart
	 * @param e
	 */
	labelFunction(e) {
		if (e.format === 'portfolio' || e.format === 'c0') {
			if (e.value === 0) {
				return '0';
			}
			if (e.value < 1000000) {
				return Math.floor(e.value / 1000) + 'K';
			}
			if (e.value < 1000000000) {
				return Math.floor(e.value / 1000000) + 'M';
			}
			return Math.floor(e.value / 1000000000) + 'B';
		}
		if (e.format === '{0} cd' && e.value === 0) {
			return 'On Time';
		}
		return e.text;
	}

	/**
	 * set color dynamically per point instead of per series
	 * @param point
	 */
	public pointColor(point): string {
		const seriesName = point.series.name;
		const first3 = point.category.substring(0, 3);
		const isLookahead = (seriesName === 'Planned' && point.series?.markers?.type === 'rect') || false;
		return seriesName === 'Planned' && !isLookahead
			? '#8a8a8a'
			: first3 === 'Cri'
				? isLookahead
					? '#9f1e1e'
					: '#DF5353'
				: first3 === 'Nea'
					? isLookahead
						? '#307a1e'
						: '#4fc931'
					: first3 === 'Non'
						? isLookahead
							? '#00379d'
							: '#0059FF'
						: isLookahead
							? '#636363'
							: 'black';
	}

	/**
	 * set color dynamically per point instead of per series
	 * @param point
	 */
	public spiPointColor(point): string {
		return point.value < 1 ? '#DF5353' : '#4fc931';
	}

	/**
	 * set color dynamically per point instead of per series
	 * @param point
	 */
	public scheduleCompressionPointColor(point): string {
		return point.value <= 100 ? '#78D237FF' : '#FF6358FF';
	}

	/**
	 * sets series label value
	 * @param e
	 */
	public seriesLabels(e): string {
		return e.value;
	}

	/**
	 * lets parent component know which legend item was clicked
	 * @param e
	 */
	public onLegendItemClick(e: LegendItemClickEvent): void {
		/* Do not hide the series on legend click */
		e.preventDefault();
		console.log(e);
		this.legendItemClicked.emit(e.series.name);
	}

	public clickableLegendClick(e: LegendItemClickEvent): void {
		console.log(e);
	}
}
