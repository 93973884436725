import { Component, Input, OnInit } from '@angular/core';
import { ProjectDashboardService } from '../../../services/project/project.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { GaugeColorSettings } from '../../../models/ChartSettings';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ProjectReportInterface } from '../../../models/ProjectReport/ProjectReport';
import { ProjectInterface } from '../../../models/Project';
import { ExpandedMetrics } from '../../../models/ProjectReport/ExpandedMetrics';
import { hasObjChanged, scheduleType } from '../../../util/projects';
import { formatNumber } from '@progress/kendo-angular-intl';
import { differenceInDays } from 'date-fns';
import { FilterItem } from '../../portfolio/project-list/project-list/project-list.component';
import { HierarchyResponse } from '../../../models/company';
import { FilterOperator } from '@progress/kendo-data-query/dist/npm/filtering/operators.enum';

@Component({
	selector: 'app-project-overview',
	templateUrl: './project-overview.component.html',
	styleUrls: ['./project-overview.component.scss'],
})
export class ProjectOverviewComponent implements OnInit {
	@Input() visualizer: boolean = false;
	$qcScore = new BehaviorSubject<number>(0);
	qcColors: GaugeColorSettings[] = [
		{
			to: 80,
			color: '#DF5353',
		},
		{
			from: 80,
			to: 95,
			color: '#4fc931',
		},
		{
			from: 95,
			color: '#0059FF',
		},
	];
	$riskScore = new BehaviorSubject<number>(0);
	riskColors: GaugeColorSettings[] = [
		{
			to: 70,
			color: '#DF5353',
		},
		{
			from: 70,
			to: 85,
			color: '#4fc931',
		},
		{
			from: 85,
			color: '#0059FF',
		},
	];
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	expandedMetrics = new BehaviorSubject<ExpandedMetrics>({} as ExpandedMetrics);
	summaryScoreTrendData = [];
	summaryScoreCategories = [];

	constructor(public projectService: ProjectDashboardService) {}

	ngOnInit(): void {
		this.projectService.$currentProjectReport
			.pipe(takeUntil(this._unsubscribeAll), debounceTime(100))
			.subscribe((report) => {
				this.updateQCGraphs(report);
				this.updateRiskTrend(report);
			});
		this.projectService.$expandedMetrics.pipe(takeUntil(this._unsubscribeAll)).subscribe((metrics: ExpandedMetrics) => {
			metrics = metrics as ExpandedMetrics;
			if (!metrics) {
				return;
			}
			this.expandedMetrics.next(metrics);
		});
	}

	updateQCGraphs(report: Omit<ProjectReportInterface, 'project'> & { project?: ProjectInterface }): void {
		if (!report) {
			return;
		}
		this.$qcScore.next(report?.qualityControl?.qcScore);
		this.$riskScore.next(report?.riskScore);
	}

	updateRiskTrend(report: Omit<ProjectReportInterface, 'project'> & { project?: ProjectInterface }): void {
		let predictabilityScoreTrendData = [];
		let categories = [];
		report?.calculationFieldsHistorical.forEach((update, i) => {
			const category = i === 0 ? 'Baseline' : 'Update ' + i;
			categories.push(category);
			predictabilityScoreTrendData.push(
				update.predictabilityScore ? formatNumber(update.predictabilityScore, 'n0') : 0
			);
		});
		categories = categories.slice(-6);
		predictabilityScoreTrendData = predictabilityScoreTrendData.slice(-6);
		if (hasObjChanged(this.summaryScoreTrendData, predictabilityScoreTrendData)) {
			this.summaryScoreTrendData = predictabilityScoreTrendData;
		}
		if (hasObjChanged(this.summaryScoreCategories, categories)) {
			this.summaryScoreCategories = categories;
		}
	}
}
