import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ProjectReportInterface } from '../../../../models/ProjectReport/ProjectReport';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import UpdateSeriesManager from '../../../../pkg-test/services/UpdateSeriesManager';
import UpdateManager, { ActivityFilterType, FilterCases } from '../../../../pkg-test/services/UpdateManager';
import { ProjectInterface } from '../../../../models/Project';
import { RestService } from '../../../../services/common/rest.service';
import { Observable } from 'rxjs';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { AppWindowService } from '../../../../services/common/window.service';
import { Calendar, Xer, XerActivity, XerTaskPredecessor } from '@rhinoworks/xer-parse';
import { UpdateInterface } from '../../../../models/Update/Task';
import { ReportGenerator } from '@rhinoworks/analytics-calculations';

interface ScoreFilter {
	name: string;
	key: FilterCases;
	count: number;
	liveCount: number;
	selectedOptions?: Array<boolean>;
	typeCounts: Record<string, number>;
}

const COLUMN_ORDER: Array<ActivityFilterType> = ['task', 'milestone', 'loe', 'finishMilestone'];

export const DEFAULT_SCORE_FILTERS: Array<ScoreFilter> = [
	{
		name: 'FS w/ Lags',
		key: 'fsWithLag',
		count: 0,
		liveCount: 0,
		selectedOptions: [true, true, true, true],
		typeCounts: {},
	},
	{
		name: 'Missing Predecessors/Successors',
		key: 'missingPredSucc',
		count: 0,
		liveCount: 0,
		selectedOptions: [true, true, true, true],
		typeCounts: {},
	},
	{
		name: 'Out of Sequence',
		key: 'oos',
		count: 0,
		liveCount: 0,
		selectedOptions: [true, true, true, true],
		typeCounts: {},
	},
	{
		name: 'Negative Lags',
		key: 'negLag',
		count: 0,
		liveCount: 0,
		selectedOptions: [true, true, true, true],
		typeCounts: {},
	},
	{
		name: 'Hard Constraints',
		key: 'hardConstraints',
		count: 0,
		liveCount: 0,
		selectedOptions: [true, true, true, true],
		typeCounts: {},
	},
	{
		name: 'High Duration',
		key: 'highDuration',
		count: 0,
		liveCount: 0,
		selectedOptions: [true, true, true, true],
		typeCounts: {},
	},
	{
		name: 'High Float',
		key: 'highFloat',
		count: 0,
		liveCount: 0,
		selectedOptions: [true, true, true, true],
		typeCounts: {},
	},

	{
		name: 'Actuals After Data Date',
		key: 'actualsAfter',
		count: 0,
		liveCount: 0,
		selectedOptions: [true, true, true, true],
		typeCounts: {},
	},
	{
		name: 'No Progress with Actual Date',
		key: 'noProgressWithActual',
		count: 0,
		liveCount: 0,
		selectedOptions: [true, true, true, true],
		typeCounts: {},
	},
	{
		name: 'No Change in Progress',
		key: 'noDeltaInRemaining',
		count: 0,
		liveCount: 0,
		selectedOptions: [true, true, true, true],
		typeCounts: {},
	},
	{
		name: 'Critical',
		key: 'critical',
		count: 0,
		liveCount: 0,
		selectedOptions: [true, true, true, true],
		typeCounts: {},
	},
];

@Component({
	selector: 'app-score-playground',
	templateUrl: './score-playground.component.html',
	styleUrls: ['./score-playground.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ScorePlaygroundComponent implements OnInit {
	@Input() projectReport: ProjectReportInterface;
	@Input() projectData: ProjectInterface;
	@Input() saveChanges: Observable<void>;
	@Input() resetValues: Observable<void>;
	@Input() afterSave: Observable<ProjectInterface>;
	@Output() canSave = new EventEmitter<boolean>();
	@Output() closeWindow = new EventEmitter<void>();
	projectScore = 0;
	progressScore = 0;
	qcScore = 0;
	predictabilityScore = 0;
	public filtersByScore: ScoreFilter[] = DEFAULT_SCORE_FILTERS.map((filter) => ({
		...filter,
		selectedOptions: filter.selectedOptions.slice(),
	}));
	public currentManager: UpdateManager;
	saveDisabled = true;
	resetDisabled = true;
	columnBoxStates: number[] = [0, 0, 0, 0];
	typeTotalCounts: Record<ActivityFilterType, number> = {
		task: 0,
		milestone: 0,
		loe: 0,
		finishMilestone: 0,
	};
	trueVal = true;

	constructor(
		private scheduleStorage: ScheduleStorageService,
		private restService: RestService,
		public projectService: ProjectDashboardService,
		public appWindowService: AppWindowService
	) {}

	ngOnInit(): void {
		this.afterSave.subscribe((project) => {
			this.projectData.preferences = project.preferences;
			this.saveDisabled = true;
			this.projectService.saveDisabledScorePlayground = true;
			this.closeWindow.emit();
		});
		this.resetValues.subscribe(() => {
			this.reset();
		});
		const parent = new UpdateSeriesManager();
		parent.referenceReport = this.projectService.$currentProjectReport.value as unknown as ProjectReportInterface;
		const initManagers = async (updates: UpdateInterface[], report: ProjectReportInterface) => {
			const tasks: XerActivity[][] = new Array(updates.length).fill(null).map(() => []);
			const relationships: XerTaskPredecessor[][] = new Array(updates.length).fill(null).map(() => []);
			const calendars: Map<number, Calendar>[] = new Array(updates.length).fill(null).map(() => new Map());
			const xers: Xer[] = [];
			for (let i = Math.max(0, updates.length - 3); i < updates.length; i++) {
				const update = updates[i];
				tasks[i] = await this.scheduleStorage.grabUpdateTable<XerActivity>(update._id, 'TASK');
				relationships[i] = await this.scheduleStorage.grabUpdateTable<XerTaskPredecessor>(update._id, 'TASKPRED');
				await this.scheduleStorage.grabCalendar(update._id);
				calendars[i] = this.scheduleStorage.cachedCalendars.get(update._id);
				xers[i] = new Xer(this.scheduleStorage.cachedXers.get(update._id));
			}
			tasks[0] = await this.scheduleStorage.grabUpdateTable<XerActivity>(updates[0]._id, 'TASK');
			await this.scheduleStorage.grabCalendar(updates[0]._id);
			calendars[0] = this.scheduleStorage.cachedCalendars.get(updates[0]._id);
			xers[0] = new Xer(this.scheduleStorage.cachedXers.get(updates[0]._id));
			console.log(updates, tasks, calendars, relationships, report);

			const reportGenerator = new ReportGenerator(
				this.projectService.$currentProjectData.value,
				this.projectData.preferences?.scores
			);
			for (let i = 0; i < updates.length; i++) {
				const xer = xers[i];
				const update = updates[i];
				if (!xer) {
					continue;
				}
				reportGenerator.nextUpdate({ _id: update._id, ...update }, xer);
			}
			console.log('reportgenerator', reportGenerator.report);

			parent.setUpdates(updates, tasks, calendars, relationships, report);
			if (parent.current) {
				parent.current.report = this.projectReport;
			}

			this.currentManager = parent.current;
			if (!this.currentManager) {
				return;
			}
			this.currentManager.includedActivities = { ...this.projectData.preferences?.scores?.includeTypes };
			this.predictabilityScore = this.currentManager.predictabilityScore;
			this.qcScore = this.currentManager.qualityControlScore;
			this.progressScore = this.currentManager.progressScore;
			this.projectScore = this.currentManager.projectScore;

			const summary = this.currentManager._taskSummary;
			for (const column of COLUMN_ORDER) {
				this.typeTotalCounts[column] = Array.from(Object.values(summary.typeCounts)).reduce((a, b) => a + b[column], 0);
			}
			for (const filter of this.filtersByScore) {
				filter.selectedOptions[0] =
					!this.projectData.preferences?.scores?.includeTypes?.[filter.key] ||
					this.projectData.preferences.scores.includeTypes[filter.key].includes('task');
				filter.selectedOptions[1] =
					!this.projectData.preferences?.scores?.includeTypes?.[filter.key] ||
					this.projectData.preferences.scores.includeTypes[filter.key].includes('milestone');
				filter.selectedOptions[2] =
					!this.projectData.preferences?.scores?.includeTypes?.[filter.key] ||
					this.projectData.preferences.scores.includeTypes[filter.key].includes('loe');
				filter.selectedOptions[3] =
					!this.projectData.preferences?.scores?.includeTypes?.[filter.key] ||
					this.projectData.preferences.scores.includeTypes[filter.key].includes('finishMilestone');
				const defaultValue = DEFAULT_SCORE_FILTERS.find((f) => f.key === filter.key);
				switch (filter.name) {
					case 'FS w/ Lags':
						{
							filter.count = summary.totalVars.fsWithLag;
							filter.typeCounts = summary.typeCounts.fsWithLag;
						}
						break;
					case 'Missing Predecessors/Successors':
						{
							filter.count = summary.totalVars.missingPredSucc;
							filter.typeCounts = summary.typeCounts.missingPredSucc;
						}
						break;
					case 'Out of Sequence':
						{
							filter.count = summary.totalVars.oos;
							filter.typeCounts = summary.typeCounts.oos;
						}
						break;
					case 'Negative Lags':
						{
							filter.count = summary.totalVars.negLag;
							filter.typeCounts = summary.typeCounts.negLag;
						}
						break;
					case 'Hard Constraints':
						{
							filter.count = summary.totalVars.hardConstraints;
							filter.typeCounts = summary.typeCounts.hardConstraints;
						}
						break;
					case 'High Duration':
						{
							filter.count = summary.totalVars.highDuration;
							filter.typeCounts = summary.typeCounts.highDuration;
						}
						break;
					case 'High Float':
						{
							filter.count = summary.totalVars.highFloat;
							filter.typeCounts = summary.typeCounts.highFloat;
						}
						break;
					case 'Actuals After Data Date':
						{
							filter.count = summary.totalVars.actualsAfter;
							filter.typeCounts = summary.typeCounts.actualsAfter;
						}
						break;
					case 'No Progress with Actual Date':
						{
							filter.count = summary.totalVars.noProgressWithActual;
							filter.typeCounts = summary.typeCounts.noProgressWithActual;
						}
						break;
					case 'No Change in Progress':
						{
							filter.count = summary.totalVars.noDeltaInRemaining;
							filter.typeCounts = summary.typeCounts.noDeltaInRemaining;
						}
						break;
					case 'Critical':
						{
							filter.count = summary.totalVars.critical;
							filter.typeCounts = summary.typeCounts.critical;
						}
						break;
				}
				defaultValue.count = filter.count;
			}
			this.updateSelections();
		};
		this.scheduleStorage.$allUpdates.subscribe((updates) => {
			if (updates.length !== this.projectData.updateIds.length) {
				return;
			}
			initManagers(updates, this.projectReport);
		});
	}

	columnToggle(column: number) {
		for (const scenario of this.filtersByScore) {
			scenario.selectedOptions[column] = this.columnBoxStates[column] !== 1;
		}
		this.updateSelections();
	}

	updateSelections(): void {
		for (const filter of this.filtersByScore) {
			const filterIncluded = [];
			for (let i = 0; i < COLUMN_ORDER.length; i++) {
				const taskType = COLUMN_ORDER[i];
				if (filter.selectedOptions[i]) {
					filterIncluded.push(taskType);
				}
			}
			this.currentManager.includedActivities[filter.key] = filterIncluded;
		}
		for (let i = 0; i < COLUMN_ORDER.length; i++) {
			const taskType = COLUMN_ORDER[i];
			const numAvailable = DEFAULT_SCORE_FILTERS.length;
			const numSelected = this.filtersByScore.filter((f) => f.selectedOptions[i]).length;
			this.columnBoxStates[i] = numSelected / (numAvailable || 1);
		}
		this.currentManager.seriesManager.updateRelevantUpdates();
		this.predictabilityScore = this.currentManager.predictabilityScore;
		this.qcScore = this.currentManager.qualityControlScore;
		this.progressScore = this.currentManager.progressScore;
		this.projectScore = this.currentManager.projectScore;
		const summary = this.currentManager._taskSummary;
		for (const filter of this.filtersByScore) {
			const defaultValue = DEFAULT_SCORE_FILTERS.find((f) => f.key === filter.key);
			switch (filter.name) {
				case 'FS w/ Lags':
					{
						filter.liveCount = summary.vars.numFSLagged;
					}
					break;
				case 'Missing Predecessors/Successors':
					{
						filter.liveCount = summary.vars.numTaskNoPredSucc;
					}
					break;
				case 'Out of Sequence':
					{
						filter.liveCount =
							summary.vars.numOosNearCritical + summary.vars.numOosCritical + summary.vars.numOosNonCritical;
					}
					break;
				case 'Negative Lags':
					{
						filter.liveCount = summary.vars.numNegLag;
					}
					break;
				case 'Hard Constraints':
					{
						filter.liveCount = summary.vars.numConstrained;
					}
					break;
				case 'High Duration':
					{
						filter.liveCount = summary.vars.numHighDuration;
					}
					break;
				case 'High Float':
					{
						filter.liveCount = summary.vars.numHighFloat;
					}
					break;
				case 'Actuals After Data Date':
					{
						filter.liveCount = summary.vars.numActualsAfterDataDate;
					}
					break;
				case 'No Progress with Actual Date':
					{
						filter.liveCount = summary.vars.numNoProgressWithActual;
					}
					break;
				case 'No Change in Progress':
					{
						filter.liveCount = summary.vars.numNoDeltaInRemaining;
					}
					break;
				case 'Critical':
					{
						filter.liveCount = summary.vars.numCritical;
					}
					break;
			}
			defaultValue.liveCount = filter.liveCount;
		}
		this.resetDisabled =
			JSON.stringify(DEFAULT_SCORE_FILTERS.map((f): ScoreFilter => ({ ...f, typeCounts: {} }))) ===
			JSON.stringify(this.filtersByScore.map((f): ScoreFilter => ({ ...f, typeCounts: {} })));

		this.saveDisabled =
			!this.projectData.preferences?.scores?.includeTypes && this.resetDisabled
				? true
				: JSON.stringify(this.currentManager.includedActivities) ===
					JSON.stringify(this.projectData.preferences?.scores?.includeTypes);
		this.canSave.emit(!this.saveDisabled);
		this.projectService.saveDisabledScorePlayground = this.saveDisabled;
		this.projectService.resetDisabledScorePlayground = this.resetDisabled;
		this.projectService.loadingScoresTab = false;
	}

	reset(): void {
		for (const filter of this.filtersByScore) {
			filter.selectedOptions = COLUMN_ORDER.map(() => true);
		}
		this.updateSelections();
	}

	/**
	 * forces legend checkbox to remain true
	 * @param checkboxId
	 */
	keepTrue(checkboxId): void {
		(document.getElementById(checkboxId) as HTMLInputElement).checked = true;
	}
}

export const isDefaultScoreFilters = (includeTypes?: Record<FilterCases, ActivityFilterType[]>): boolean => {
	if (!includeTypes) {
		return true;
	}
	for (const defaultFilter of DEFAULT_SCORE_FILTERS) {
		for (let i = 0; i < COLUMN_ORDER.length; i++) {
			const colKey = COLUMN_ORDER[i];
			if (includeTypes[defaultFilter.key].includes(colKey) !== defaultFilter.selectedOptions[i]) {
				return false;
			}
		}
	}
	return true;
};
