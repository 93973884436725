<div class='form-container' id='rrFormContainer'>
  <div class='form-body'>
    <div class="wrapper">
      <kendo-tabstrip (tabSelect)='onTabSelect($event)'>
        <kendo-tabstrip-tab [selected]="tabIndex === 0" >
					<ng-template kendoTabTitle>
						<span>General</span>
						<kendo-badge-container style='margin: 0; padding-left: 10px' *ngIf='missingRequiredFields[0] === true'>
							<kendo-badge rounded="medium"></kendo-badge>
						</kendo-badge-container>
					</ng-template>
          <ng-template kendoTabContent>
            <div class="content">
              <form class="k-form k-form-vertical" [formGroup]="form">
								@if (project.$currentProjectData.value?.riskMetricsType !== 'coreRisk') {
									<div class='general-form'>
										<div>
											<kendo-formfield [orientation]='"horizontal"'>
												<kendo-label [for]="riskName" text="Risk Name"></kendo-label>
												<kendo-textbox
													formControlName="riskName"
													[clearButton]="true"
													#riskName
													(valueChange)='updateTabValidity()'
												></kendo-textbox>
												<kendo-formerror *ngIf="form.controls?.riskName.errors?.required" [align]="'end'">
													Risk Name is required
												</kendo-formerror>
											</kendo-formfield>
											<kendo-formfield [orientation]='"horizontal"'>
												<kendo-label [for]="riskOwnerName" text="Risk Owner Name"></kendo-label>
												<kendo-combobox #riskOwnerName
																				formControlName="riskOwnerName"
																				[data]="existingEntries['riskOwnerNames'] || []"
																				(valueChange)="updateExistingEntries('riskOwnerNames')"
																				[allowCustom]='true'>
												</kendo-combobox>
												<kendo-formerror *ngIf="form.controls?.riskOwnerName.errors?.required" [align]="'end'">
													Risk Owner Name is required
												</kendo-formerror>
											</kendo-formfield>
											<kendo-formfield [orientation]='"horizontal"'>
												<kendo-label [for]="riskOwnerEmail" text="Risk Owner Email"></kendo-label>
												<kendo-combobox #riskOwnerEmail
																				formControlName="riskOwnerEmail"
																				[data]="existingEntries['riskOwnerEmails'] || []"
																				(valueChange)="updateExistingEntries('riskOwnerEmails')"
																				[allowCustom]='true'>
												</kendo-combobox>
												<kendo-formhint [align]="'end'">(Optional)</kendo-formhint>
												<kendo-formerror *ngIf="form.controls?.riskOwnerEmail.errors?.email" [align]="'end'">
													Invalid email format
												</kendo-formerror>
											</kendo-formfield>
											<kendo-formfield [orientation]='"horizontal"'>
												<kendo-label [for]="costOwnerName" text="Cost Owner Name"></kendo-label>
												<kendo-combobox #costOwnerName
																				formControlName="costOwnerName"
																				[data]="existingEntries['costOwnerNames'] || []"
																				(valueChange)="updateExistingEntries('costOwnerNames')"
																				[allowCustom]='true'>
												</kendo-combobox>
												<kendo-formhint [align]="'end'">(Optional)</kendo-formhint>
											</kendo-formfield>
											<kendo-formfield [orientation]='"horizontal"'>
												<kendo-label [for]="costOwnerEmail" text="Cost Owner Email"></kendo-label>
												<kendo-combobox #costOwnerEmail
																				formControlName="costOwnerEmail"
																				[data]="existingEntries['costOwnerEmails'] || []"
																				(valueChange)="updateExistingEntries('costOwnerEmails')"
																				[allowCustom]='true'>
												</kendo-combobox>
												<kendo-formhint [align]="'end'">(Optional)</kendo-formhint>
												<kendo-formerror *ngIf="form.controls?.costOwnerEmail.errors?.email" [align]="'end'">
													Invalid email format
												</kendo-formerror>
											</kendo-formfield>
											<kendo-formfield [orientation]='"horizontal"'>
												<kendo-label [for]="responsibilityName" text="Responsibility Name"></kendo-label>
												<kendo-combobox #responsibilityName
																				formControlName="responsibilityName"
																				[data]="existingEntries['responsibilityNames'] || []"
																				(valueChange)="updateExistingEntries('responsibilityNames')"
																				[allowCustom]='true'>
												</kendo-combobox>
												<kendo-formerror *ngIf="form.controls?.responsibilityName.errors?.required" [align]="'end'">
													Responsibility Name is required
												</kendo-formerror>
											</kendo-formfield>
											<kendo-formfield [orientation]='"horizontal"'>
												<kendo-label [for]="responsibilityEmail" text="Responsibility Email"></kendo-label>
												<kendo-combobox #responsibilityEmail
																				formControlName="responsibilityEmail"
																				[data]="existingEntries['responsibilityEmails'] || []"
																				(valueChange)="updateExistingEntries('responsibilityEmails')"
																				[allowCustom]='true'>
												</kendo-combobox>
												<kendo-formhint [align]="'end'">(Optional)</kendo-formhint>
												<kendo-formerror *ngIf="form.controls?.responsibilityEmail.errors?.email" [align]="'end'">
													Invalid email format
												</kendo-formerror>
											</kendo-formfield>
										</div>
										<div>
											<kendo-formfield [orientation]='"horizontal"'>
												<kendo-label [for]="mitStatus" text="Status"></kendo-label>
												<kendo-dropdownlist
													#mitStatus
													[data]="presets.mitStatus"
													formControlName="mitStatus"
													(valueChange)='updateTabValidity()'
												>
												</kendo-dropdownlist>
												<kendo-formerror *ngIf="form.controls?.mitStatus.errors?.required" [align]="'end'">
													Status is required
												</kendo-formerror>
											</kendo-formfield>
											<kendo-formfield [orientation]='"horizontal"'>
												<kendo-label [for]="category" text="Category"></kendo-label>
												<kendo-combobox #category
																				formControlName="category"
																				[data]="existingEntries['category'] || []"
											(valueChange)="updateExistingEntries('category')"
																				[allowCustom]='true'>
												</kendo-combobox>
												<kendo-formerror *ngIf="form.controls?.category.errors?.required" [align]="'end'">
													Category is required
												</kendo-formerror>
											</kendo-formfield>
											<kendo-formfield [orientation]='"horizontal"'>
												<kendo-label [for]="triggerPhase" text="Trigger Phase"></kendo-label>
												<kendo-dropdownlist #triggerPhase formControlName="triggerPhase" [data]="presets.triggerPhase">
												</kendo-dropdownlist>
											</kendo-formfield>
											<kendo-formfield [orientation]='"horizontal"' [style.height.px]='118' style="margin-top: 19px;">
												<kendo-label
													[for]="description"
													text="Description of Impact"
													[optional]="true"
												></kendo-label>
												<kendo-textarea
													#description
													formControlName="description"
													resizable="none"
													[style.height.px]='101'
												></kendo-textarea>
												<kendo-formhint [align]="'end'">(Optional)</kendo-formhint>
											</kendo-formfield>
											<kendo-formfield [orientation]='"horizontal"' [style.height.px]='118' style="margin-top: 24px;">
												<kendo-label [for]="effect" text="Effect"></kendo-label>
												<kendo-textarea
													#effect
													formControlName="effect"
													resizable="none"
													[style.height.px]='101'
													(valueChange)='updateTabValidity()'
												></kendo-textarea>
												<kendo-formerror *ngIf="form.controls?.effect.errors?.required" [align]="'end'">
													Effect is required
												</kendo-formerror>
											</kendo-formfield>
										</div>
									</div>
								} @else {
									<div class="core-risk-mitigation-fieldset-wrapper">
										<fieldset class='schedule-impact-row' style="border-color: #ffffff">
											<legend>General</legend>
											<div style="display: grid; grid-template-columns: calc(50% - 5px) calc(50% - 5px); gap: 10px;">
												<kendo-formfield>
													<kendo-label [for]="riskName" text="Risk Name"></kendo-label>
													<kendo-textbox
														formControlName="riskName"
														[clearButton]="true"
														#riskName
														(valueChange)='updateTabValidity()'
													></kendo-textbox>
													<kendo-formerror *ngIf="form.controls?.riskName.errors?.required" [align]="'end'">
														Risk Name is required
													</kendo-formerror>
												</kendo-formfield>
												<kendo-formfield>
													<kendo-label [for]="mitStatus" text="Status"></kendo-label>
													<kendo-dropdownlist
														#mitStatus
														[data]="presets.mitStatus"
														formControlName="mitStatus"
														(valueChange)='updateTabValidity()'
													>
													</kendo-dropdownlist>
													<kendo-formerror *ngIf="form.controls?.mitStatus.errors?.required" [align]="'end'">
														Status is required
													</kendo-formerror>
												</kendo-formfield>
											</div>
											<kendo-formfield [style.height.px]='98' class="core-risk-desc-wrap">
												<kendo-label
													[for]="description"
													text="Description of Impact"
													[optional]="true"
													style="width: 122px; white-space: nowrap;"
												></kendo-label>
												<kendo-textarea
													#description
													formControlName="description"
													resizable="none"
													[style.height.px]='70'
												></kendo-textarea>
											</kendo-formfield>
										</fieldset>
									</div>
									<div class="core-risk-mitigation-fieldset-wrapper" style="padding-top: 5px;">
										<fieldset class='schedule-impact-row' style="border-color: #ffffff">
											<legend style="background-color: rgb(245, 194, 194); border-radius: 4px;">Pre-Mitigation</legend>
											<div class="core-risk-fieldset-wrap">
												<kendo-formfield>
													<kendo-label
														[for]="preMitigationActivityImpact"
														text="Activity Impact (%)"
													></kendo-label>
													<kendo-numerictextbox
														#preMitigationActivityImpact
														format="# '%'"
														formControlName="preMitigationActivityImpact"
														[step]="1"
														[spinners]='false'
														[changeValueOnScroll]='false'
														(valueChange)='updateActvImpact($event, "pre")'
														(keydown)='testNumericKeypress($event)'
														(paste)='testPaste($event)'
													></kendo-numerictextbox>
												</kendo-formfield>
												<kendo-formfield [ngClass]="{'no-cursor': disablePre, 'full-opacity-formfield': !disablePre}">
													<kendo-label
														[for]="preImpactVarianceMin"
														text="Min Variance (-Δ%)"
														class="core-risk-min-var-label"
													></kendo-label>
													<kendo-numerictextbox
														#preImpactVarianceMin
														format="'-'# '%'"
														formControlName="preMitigationImpactVarianceMin"
														[step]="1"
														[min]='0'
														[max]='preMitigationActvImpact'
														[spinners]='false'
														[changeValueOnScroll]='false'
														[disabled]='preMitigationActvImpact === null'
														(keydown)='testNumericKeypress($event)'
														(paste)='testPaste($event)'
														(valueChange)='updateTabValidity()'
													></kendo-numerictextbox>
													<kendo-formhint [align]="'end'" style="margin-left: -26px;">(Requires Activity Impact)</kendo-formhint>
													<kendo-formerror *ngIf="form.controls?.preMitigationImpactVarianceMin.value > preMitigationActvImpact || form.controls?.preMitigationImpactVarianceMin?.errors?.maxError" [align]="'end'">
														Error: Variance > Impact
													</kendo-formerror>
												</kendo-formfield>
												<kendo-formfield [ngClass]="{'no-cursor': disablePre, 'full-opacity-formfield': !disablePre}">
													<kendo-label
														[for]="preImpactVarianceMax"
														text="Max Variance (+Δ%)"
														class="core-risk-max-var-label"
													></kendo-label>
													<kendo-numerictextbox
														#preImpactVarianceMax
														format="+# '%'"
														formControlName="preMitigationImpactVarianceMax"
														[step]="1"
														[min]='0'
														[spinners]='false'
														[changeValueOnScroll]='false'
														[disabled]='preMitigationActvImpact === null'
														(keydown)='testNumericKeypress($event)'
														(paste)='testPaste($event)'
													></kendo-numerictextbox>
													<kendo-formhint [align]="'end'">(Requires Activity Impact)</kendo-formhint>
												</kendo-formfield>
												<kendo-formfield>
													<kendo-label
														[for]="preMitigationEstCost"
														text="Estimated Cost ($)"
													></kendo-label>
													<!--<kendo-numerictextbox
														#preMitigationEstCost
														[format]="'c0'"
														formControlName="preMitigationEstCost"
														[min]="0"
														[spinners]='false'
														[changeValueOnScroll]='false'
														placeholder=' '
													></kendo-numerictextbox>-->
													<kendo-textbox
														#preMitigationEstCost
														formControlName="preMitigationEstCostDisplay"
														placeholder=' '
														appMoneyMask
														(keydown)='testNumericKeypress($event, true)'
														(paste)='testPaste($event, true)'
														(blur)='roundExtraDecimals("preMitigationEstCost","preMitigationEstCostDisplay")'
													></kendo-textbox>
												</kendo-formfield>
											</div>
										</fieldset>
									</div>
									<div class="core-risk-mitigation-fieldset-wrapper" style="padding-top: 5px;">
										<fieldset class='schedule-impact-row' style="border-color: #ffffff">
											<legend style="background-color: rgb(192, 214, 236); border-radius: 4px;">Post-Mitigation</legend>
											<div class="core-risk-fieldset-wrap">
												<kendo-formfield>
													<kendo-label
														[for]="postMitigationActivityImpact"
														text="Activity Impact (%)"
													></kendo-label>
													<kendo-numerictextbox
														#postMitigationActivityImpact
														format="# '%'"
														formControlName="postMitigationActivityImpact"
														[step]="1"
														[spinners]='false'
														[changeValueOnScroll]='false'
														(valueChange)='updateActvImpact($event, "post")'
														(keydown)='testNumericKeypress($event)'
														(paste)='testPaste($event)'
													></kendo-numerictextbox>
												</kendo-formfield>
												<kendo-formfield [ngClass]="{'no-cursor': disablePost, 'full-opacity-formfield': !disablePost}">
													<kendo-label
														[for]="postImpactVarianceMin"
														text="Min Variance (-Δ%)"
														class="core-risk-min-var-label"
													></kendo-label>
													<kendo-numerictextbox
														#postImpactVarianceMin
														format="'-'# '%'"
														formControlName="postMitigationImpactVarianceMin"
														[step]="1"
														[min]='0'
														[max]='postMitigationActvImpact'
														[spinners]='false'
														[changeValueOnScroll]='false'
														[disabled]='postMitigationActvImpact === null'
														(keydown)='testNumericKeypress($event)'
														(paste)='testPaste($event)'
														(valueChange)='updateTabValidity()'
													></kendo-numerictextbox>
													<kendo-formhint [align]="'end'" style="margin-left: -26px;">(Requires Activity Impact)</kendo-formhint>
													<kendo-formerror *ngIf="form.controls?.postMitigationImpactVarianceMin.value > postMitigationActvImpact || form.controls?.postMitigationImpactVarianceMin?.errors?.maxError" [align]="'end'">
														Error: Variance > Impact
													</kendo-formerror>
												</kendo-formfield>
												<kendo-formfield [ngClass]="{'no-cursor': disablePost, 'full-opacity-formfield': !disablePost}">
													<kendo-label
														[for]="postImpactVarianceMax"
														text="Max Variance (+Δ%)"
														class="core-risk-max-var-label"
													></kendo-label>
													<kendo-numerictextbox
														#postImpactVarianceMax
														format="+# '%'"
														formControlName="postMitigationImpactVarianceMax"
														[step]="1"
														[min]='0'
														[spinners]='false'
														[changeValueOnScroll]='false'
														[disabled]='postMitigationActvImpact === null'
														(keydown)='testNumericKeypress($event)'
														(paste)='testPaste($event)'
													></kendo-numerictextbox>
													<kendo-formhint [align]="'end'">(Requires Activity Impact)</kendo-formhint>
												</kendo-formfield>
												<kendo-formfield>
													<kendo-label
														[for]="postMitigationEstCost"
														text="Estimated Cost ($)"
													></kendo-label>
													<!--	<kendo-numerictextbox
															#postMitigationEstCost
															[format]="'c0'"
															formControlName="postMitigationEstCost"
															[min]="0"
															[spinners]='false'
															[changeValueOnScroll]='false'
															placeholder=' '
														></kendo-numerictextbox>-->
													<kendo-textbox
														#postMitigationEstCost
														formControlName="postMitigationEstCostDisplay"
														placeholder=' '
														appMoneyMask
														(keydown)='testNumericKeypress($event, true)'
														(paste)='testPaste($event, true)'
														(blur)='roundExtraDecimals("postMitigationEstCost","postMitigationEstCostDisplay")'
													></kendo-textbox>
												</kendo-formfield>
											</div>
											<div>
												<kendo-formfield [style.height.px]='98' style="padding-top: 10px;" class="core-risk-desc-wrap">
													<kendo-label
														[for]="strategyMeasures"
														text="Mitigation Measures"
														[optional]="true"
														style="width: 122px; white-space: nowrap;"
													></kendo-label>
													<kendo-textarea
														#strategyMeasures
														formControlName="strategyMeasures"
														resizable="none"
														[style.height.px]='70'
													></kendo-textarea>
												</kendo-formfield>
											</div>
										</fieldset>
									</div>
								}
              </form>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
				@if (project.$currentProjectData.value?.riskMetricsType !== 'coreRisk') {
					<kendo-tabstrip-tab [selected]="tabIndex === 1">
						<ng-template kendoTabTitle>
							<span>Pre-Mitigation</span>
							<kendo-badge-container style='margin: 0; padding-left: 10px' *ngIf='missingRequiredFields[1] === true'>
								<kendo-badge rounded="medium"></kendo-badge>
							</kendo-badge-container>
						</ng-template>
						<ng-template kendoTabContent>
							<div class="content">
								<form class="k-form" [formGroup]="form">
									<div class='premitigation-form'>
										<div>
											<fieldset class='impact-row'>
												<legend>Pre-Mitigation Impact</legend>
												<kendo-formfield showErrors="initial">
													<kendo-label [for]="preMitigationProbability" text="Probability"></kendo-label>
													<kendo-dropdownlist #preMitigationProbability formControlName="preMitigationProbability" [data]="presets.scores"
																							(valueChange)='adjustMitigationScores()'>
														<ng-template kendoDropDownListItemTemplate let-dataItem>
															<span class="score-template">{{ dataItem }} - {{ scoreLabels[dataItem] }}</span>
														</ng-template>
													</kendo-dropdownlist>
													<kendo-formerror *ngIf="form.controls?.preMitigationProbability.errors?.required" [align]="'end'">
														Probability is required
													</kendo-formerror>
													<kendo-formerror *ngIf="form.controls?.preMitigationProbability.errors?.max || form.controls?.preMitigationProbability.errors?.min" [align]="'end'">
														Invalid Value
													</kendo-formerror>
												</kendo-formfield>
												<kendo-formfield showErrors="initial">
													<kendo-label [for]="preMitigationScheduleImpact" text="Schedule"></kendo-label>
													<kendo-dropdownlist #preMitigationScheduleImpact formControlName="preMitigationScheduleImpact" [data]="presets.scores" (valueChange)='adjustMitigationScores()'>
														<ng-template kendoDropDownListItemTemplate let-dataItem>
															<span class="score-template">{{ dataItem }} - {{ scoreLabels[dataItem] }}</span>
														</ng-template>
													</kendo-dropdownlist>
													<kendo-formerror *ngIf="form.controls?.preMitigationScheduleImpact.errors?.required" [align]="'end'">
														Schedule is required
													</kendo-formerror>
													<kendo-formerror *ngIf="form.controls?.preMitigationScheduleImpact.errors?.max || form.controls?.preMitigationScheduleImpact.errors?.min" [align]="'end'">
														Invalid Value
													</kendo-formerror>
												</kendo-formfield>
												<kendo-formfield showErrors="initial">
													<kendo-label [for]="preMitigationCostImpact" text="Cost Impact"></kendo-label>
													<kendo-dropdownlist #preMitigationCostImpact formControlName="preMitigationCostImpact" [data]="presets.scores" (valueChange)='adjustMitigationScores()'>
														<ng-template kendoDropDownListItemTemplate let-dataItem>
															<span class="score-template">{{ dataItem }} - {{ scoreLabels[dataItem] }}</span>
														</ng-template>
													</kendo-dropdownlist>
													<kendo-formerror *ngIf="form.controls?.preMitigationCostImpact.errors?.required" [align]="'end'">
														Cost Impact is required
													</kendo-formerror>
													<kendo-formerror *ngIf="form.controls?.preMitigationCostImpact.errors?.max || form.controls?.preMitigationCostImpact.errors?.min" [align]="'end'">
														Invalid Value
													</kendo-formerror>
												</kendo-formfield>
												<kendo-formfield showErrors="initial">
													<kendo-label [for]="preMitigationPerformanceImpact" text="Performance"></kendo-label>
													<kendo-dropdownlist #preMitigationPerformanceImpact formControlName="preMitigationPerformanceImpact" [data]="presets.scores" (valueChange)='adjustMitigationScores()'>
														<ng-template kendoDropDownListItemTemplate let-dataItem>
															<span class="score-template">{{ dataItem }} - {{ scoreLabels[dataItem] }}</span>
														</ng-template>
													</kendo-dropdownlist>
													<kendo-formerror *ngIf="form.controls?.preMitigationPerformanceImpact.errors?.required" [align]="'end'">
														Performance is required
													</kendo-formerror>
													<kendo-formerror *ngIf="form.controls?.preMitigationPerformanceImpact.errors?.max || form.controls?.preMitigationPerformanceImpact.errors?.min" [align]="'end'">
														Invalid Value
													</kendo-formerror>
												</kendo-formfield>
												<kendo-formfield showErrors="initial">
													<kendo-label [for]="preMitigationQualityImpact" text="Quality"></kendo-label>
													<kendo-dropdownlist #preMitigationQualityImpact formControlName="preMitigationQualityImpact" [data]="presets.scores" (valueChange)='adjustMitigationScores()'>
														<ng-template kendoDropDownListItemTemplate let-dataItem>
															<span class="score-template">{{ dataItem }} - {{ scoreLabels[dataItem] }}</span>
														</ng-template>
													</kendo-dropdownlist>
													<kendo-formerror *ngIf="form.controls?.preMitigationQualityImpact.errors?.required" [align]="'end'">
														Quality is required
													</kendo-formerror>
													<kendo-formerror *ngIf="form.controls?.preMitigationQualityImpact.errors?.max || form.controls?.preMitigationQualityImpact.errors?.min" [align]="'end'">
														Invalid Value
													</kendo-formerror>
												</kendo-formfield>
											</fieldset>
											<fieldset class='schedule-impact-row'>
												<legend>Schedule Impact</legend>
												<kendo-formfield>
													<kendo-label
														[for]="preMitigationActivityImpact"
														text="Activity Impact (%)"
													></kendo-label>
													<kendo-numerictextbox
														#preMitigationActivityImpact
														format="# '%'"
														formControlName="preMitigationActivityImpact"
														[step]="1"
														[spinners]='false'
														[changeValueOnScroll]='false'
														(valueChange)='updateActvImpact($event, "pre")'
														(keydown)='testNumericKeypress($event)'
														(paste)='testPaste($event)'
													></kendo-numerictextbox>
													<kendo-formhint [align]="'end'">(Optional)</kendo-formhint>
												</kendo-formfield>
												<kendo-formfield [ngClass]="{'no-cursor': disablePre, 'full-opacity-formfield': !disablePre}">
													<kendo-label
														[for]="preImpactVarianceMin"
														text="Min Variance (-Δ%)"
													></kendo-label>
													<kendo-numerictextbox
														#preImpactVarianceMin
														format="'-'# '%'"
														formControlName="preMitigationImpactVarianceMin"
														[step]="1"
														[min]='0'
														[max]='preMitigationActvImpact'
														[spinners]='false'
														[changeValueOnScroll]='false'
														[disabled]='preMitigationActvImpact === null'
														(keydown)='testNumericKeypress($event)'
														(paste)='testPaste($event)'
														(valueChange)='updateTabValidity()'
													></kendo-numerictextbox>
													<kendo-formhint [align]="'end'">(Requires Activity Impact)</kendo-formhint>
													<kendo-formerror *ngIf="form.controls?.preMitigationImpactVarianceMin.value > preMitigationActvImpact || form.controls?.preMitigationImpactVarianceMin?.errors?.maxError" [align]="'end'">
														Error: Variance > Impact
													</kendo-formerror>
												</kendo-formfield>
												<kendo-formfield [ngClass]="{'no-cursor': disablePre, 'full-opacity-formfield': !disablePre}">
													<kendo-label
														[for]="preImpactVarianceMax"
														text="Max Variance (+Δ%)"
													></kendo-label>
													<kendo-numerictextbox
														#preImpactVarianceMax
														format="+# '%'"
														formControlName="preMitigationImpactVarianceMax"
														[step]="1"
														[min]='0'
														[spinners]='false'
														[changeValueOnScroll]='false'
														[disabled]='preMitigationActvImpact === null'
														(keydown)='testNumericKeypress($event)'
														(paste)='testPaste($event)'
													></kendo-numerictextbox>
													<kendo-formhint [align]="'end'">(Requires Activity Impact)</kendo-formhint>
												</kendo-formfield>
											</fieldset>
											<fieldset class='cost-impact-row'>
												<legend>Cost Impact</legend>
												<kendo-formfield>
													<kendo-label
														[for]="preMitigationEstCost"
														text="Estimated Cost ($)"
													></kendo-label>
													<!--<kendo-numerictextbox
														#preMitigationEstCost
														[format]="'c0'"
														formControlName="preMitigationEstCost"
														[min]="0"
														[spinners]='false'
														[changeValueOnScroll]='false'
														placeholder=' '
													></kendo-numerictextbox>-->
													<kendo-textbox
														#preMitigationEstCost
														formControlName="preMitigationEstCostDisplay"
														placeholder=' '
														appMoneyMask
														(keydown)='testNumericKeypress($event, true)'
														(paste)='testPaste($event, true)'
														(blur)='roundExtraDecimals("preMitigationEstCost","preMitigationEstCostDisplay")'
													></kendo-textbox>
													<kendo-formhint [align]="'end'">(Optional)</kendo-formhint>
												</kendo-formfield>
												<kendo-formfield class='no-cursor'>
													<kendo-label
														[for]="costVarianceMin"
														text="Min Variance (-Δ%)"
													></kendo-label>
													<kendo-numerictextbox
														#costVarianceMin
														format="-# '%'"
														formControlName="costVarianceMin"
														[step]="1"
														[max]='preMitigationEstCost'
														[spinners]='false'
														[changeValueOnScroll]='false'
													></kendo-numerictextbox>
													<kendo-formhint [align]="'end'">(Coming Soon)</kendo-formhint>
												</kendo-formfield>
												<kendo-formfield class='no-cursor'>
													<kendo-label
														[for]="costVarianceMax"
														text="Max Variance (+Δ%)"
													></kendo-label>
													<kendo-numerictextbox
														#costVarianceMax
														format="+# '%'"
														formControlName="costVarianceMax"
														[step]="1"
														[min]="preMitigationEstCost"
														[spinners]='false'
														[changeValueOnScroll]='false'
													></kendo-numerictextbox>
													<kendo-formhint [align]="'end'">(Coming Soon)</kendo-formhint>
												</kendo-formfield>
											</fieldset>
										</div>
										<div class='score-card-col'>
											<div class='score-card'>
												<div>
													Score
												</div>
												<div>
													{{preMitigationScore | async}}
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</ng-template>
					</kendo-tabstrip-tab>
					<kendo-tabstrip-tab [selected]="tabIndex === 2">
						<ng-template kendoTabTitle>
							<span>Mitigation</span>
							<kendo-badge-container style='margin: 0; padding-left: 10px' *ngIf='missingRequiredFields[2] === true'>
								<kendo-badge rounded="medium"></kendo-badge>
							</kendo-badge-container>
						</ng-template>
						<ng-template kendoTabContent>
							<div class="content">
								<form class="k-form" [formGroup]="form">
									<div class='mitigation-form'>
										<div>
											<kendo-formfield [style.height.px]='72'>
												<kendo-label [for]="strategyStatusDate" text="Status Date"></kendo-label>
												<kendo-datepicker
													clearInputButton
													#strategyStatusDate
													formControlName="strategyStatusDate"
												>
												</kendo-datepicker>
												<kendo-formhint [align]="'end'">(Optional)</kendo-formhint>
											</kendo-formfield>
											<kendo-formfield showErrors="initial">
												<kendo-label [for]="strategyStrategy" text="Mitigation Strategy"></kendo-label>
												<kendo-dropdownlist #strategyStrategy formControlName="strategyStrategy" [data]="presets.mitigationStrategy" (valueChange)='updateTabValidity()'>
													<ng-template kendoDropDownListItemTemplate let-dataItem>
														<span>{{dataItem | titlecase}}</span>
													</ng-template>
													<ng-template kendoDropDownListValueTemplate let-dataItem>
														<span>{{dataItem | titlecase}}</span>
													</ng-template>
												</kendo-dropdownlist>
												<kendo-formerror *ngIf="form.controls?.strategyStrategy.errors?.required" [align]="'end'">
													Mitigation Strategy is required
												</kendo-formerror>
											</kendo-formfield>
										</div>
										<div>
											<kendo-formfield [style.height.px]='158'>
												<kendo-label
													[for]="strategyMeasures"
													text="Mitigation Measures"
													[optional]="true"
												></kendo-label>
												<kendo-textarea
													#strategyMeasures
													formControlName="strategyMeasures"
													resizable="none"
													[style.height.px]='116'
												></kendo-textarea>
												<kendo-formhint [align]="'end'">(Optional)</kendo-formhint>
											</kendo-formfield>
										</div>
									</div>
								</form>
							</div>
						</ng-template>
					</kendo-tabstrip-tab>
					<kendo-tabstrip-tab [selected]="tabIndex === 3">
						<ng-template kendoTabTitle>
							<span>Post-Mitigation</span>
							<kendo-badge-container style='margin: 0; padding-left: 10px' *ngIf='missingRequiredFields[3] === true'>
								<kendo-badge rounded="medium"></kendo-badge>
							</kendo-badge-container>
						</ng-template>
						<ng-template kendoTabContent>
							<div class="content">
								<form class="k-form" [formGroup]="form">
									<div class='premitigation-form'>
										<div>
											<fieldset class='impact-row'>
												<legend>Post-Mitigation Impact</legend>
												<kendo-formfield showErrors="initial">
													<kendo-label [for]="preMitigationProbability" text="Probability"></kendo-label>
													<kendo-dropdownlist #preMitigationProbability formControlName="preMitigationProbability" [data]="presets.scores" [disabled]='true' (valueChange)='adjustMitigationScores()'>
														<ng-template kendoDropDownListItemTemplate let-dataItem>
															<span class="score-template">{{ dataItem }} - {{ scoreLabels[dataItem] }}</span>
														</ng-template>
													</kendo-dropdownlist>
													<kendo-formerror *ngIf="form.controls?.preMitigationProbability.errors?.required" [align]="'end'"><span class='anchor-error-label' (click)='tabChange(-2)'>
													Probability</span> is required
													</kendo-formerror>
													<kendo-formerror *ngIf="form.controls?.preMitigationProbability.errors?.max || form.controls?.preMitigationProbability.errors?.min" [align]="'end'">
														Invalid Value
													</kendo-formerror>
												</kendo-formfield>
												<kendo-formfield showErrors="initial">
													<kendo-label [for]="postMitigationScheduleImpact" text="Schedule"></kendo-label>
													<kendo-dropdownlist #postMitigationScheduleImpact formControlName="postMitigationScheduleImpact" [data]="presets.scores" (valueChange)='adjustMitigationScores()'>
														<ng-template kendoDropDownListItemTemplate let-dataItem>
															<span class="score-template">{{ dataItem }} - {{ scoreLabels[dataItem] }}</span>
														</ng-template>
													</kendo-dropdownlist>
													<kendo-formerror *ngIf="form.controls?.postMitigationScheduleImpact.errors?.required" [align]="'end'">
														Schedule is required
													</kendo-formerror>
													<kendo-formerror *ngIf="form.controls?.postMitigationScheduleImpact.errors?.max || form.controls?.postMitigationScheduleImpact.errors?.min" [align]="'end'">
														Invalid Value
													</kendo-formerror>
												</kendo-formfield>
												<kendo-formfield showErrors="initial">
													<kendo-label [for]="postMitigationCostImpact" text="Cost Impact"></kendo-label>
													<kendo-dropdownlist #postMitigationCostImpact formControlName="postMitigationCostImpact" [data]="presets.scores" (valueChange)='adjustMitigationScores()'>
														<ng-template kendoDropDownListItemTemplate let-dataItem>
															<span class="score-template">{{ dataItem }} - {{ scoreLabels[dataItem] }}</span>
														</ng-template>
													</kendo-dropdownlist>
													<kendo-formerror *ngIf="form.controls?.postMitigationCostImpact.errors?.required" [align]="'end'">
														Cost Impact is required
													</kendo-formerror>
													<kendo-formerror *ngIf="form.controls?.postMitigationCostImpact.errors?.max || form.controls?.postMitigationCostImpact.errors?.min" [align]="'end'">
														Invalid Value
													</kendo-formerror>
												</kendo-formfield>
												<kendo-formfield showErrors="initial">
													<kendo-label [for]="postMitigationPerformanceImpact" text="Performance"></kendo-label>
													<kendo-dropdownlist #postMitigationPerformanceImpact formControlName="postMitigationPerformanceImpact" [data]="presets.scores" (valueChange)='adjustMitigationScores()'>
														<ng-template kendoDropDownListItemTemplate let-dataItem>
															<span class="score-template">{{ dataItem }} - {{ scoreLabels[dataItem] }}</span>
														</ng-template>
													</kendo-dropdownlist>
													<kendo-formerror *ngIf="form.controls?.postMitigationPerformanceImpact.errors?.required" [align]="'end'">
														Performance is required
													</kendo-formerror>
													<kendo-formerror *ngIf="form.controls?.postMitigationPerformanceImpact.errors?.max || form.controls?.postMitigationPerformanceImpact.errors?.min" [align]="'end'">
														Invalid Value
													</kendo-formerror>
												</kendo-formfield>
												<kendo-formfield showErrors="initial">
													<kendo-label [for]="postMitigationQualityImpact" text="Quality"></kendo-label>
													<kendo-dropdownlist #postMitigationQualityImpact formControlName="postMitigationQualityImpact" [data]="presets.scores" (valueChange)='adjustMitigationScores()'>
														<ng-template kendoDropDownListItemTemplate let-dataItem>
															<span class="score-template">{{ dataItem }} - {{ scoreLabels[dataItem] }}</span>
														</ng-template>
													</kendo-dropdownlist>
													<kendo-formerror *ngIf="form.controls?.postMitigationQualityImpact.errors?.required" [align]="'end'">
														Quality is required
													</kendo-formerror>
													<kendo-formerror *ngIf="form.controls?.postMitigationQualityImpact.errors?.max || form.controls?.postMitigationQualityImpact.errors?.min" [align]="'end'">
														Invalid Value
													</kendo-formerror>
												</kendo-formfield>
											</fieldset>
											<fieldset class='schedule-impact-row'>
												<legend>Schedule Impact</legend>
												<kendo-formfield>
													<kendo-label
														[for]="postMitigationActivityImpact"
														text="Activity Impact (%)"
													></kendo-label>
													<kendo-numerictextbox
														#postMitigationActivityImpact
														format="# '%'"
														formControlName="postMitigationActivityImpact"
														[step]="1"
														[spinners]='false'
														[changeValueOnScroll]='false'
														(valueChange)='updateActvImpact($event, "post")'
														(keydown)='testNumericKeypress($event)'
														(paste)='testPaste($event)'
													></kendo-numerictextbox>
													<kendo-formhint [align]="'end'">(Optional)</kendo-formhint>
												</kendo-formfield>
												<kendo-formfield [ngClass]="{'no-cursor': disablePost, 'full-opacity-formfield': !disablePost}">
													<kendo-label
														[for]="postImpactVarianceMin"
														text="Min Variance (-Δ%)"
													></kendo-label>
													<kendo-numerictextbox
														#postImpactVarianceMin
														format="'-'# '%'"
														formControlName="postMitigationImpactVarianceMin"
														[step]="1"
														[min]='0'
														[max]='postMitigationActvImpact'
														[spinners]='false'
														[changeValueOnScroll]='false'
														[disabled]='postMitigationActvImpact === null'
														(keydown)='testNumericKeypress($event)'
														(paste)='testPaste($event)'
														(valueChange)='updateTabValidity()'
													></kendo-numerictextbox>
													<kendo-formhint [align]="'end'">(Requires Activity Impact)</kendo-formhint>
													<kendo-formerror *ngIf="form.controls?.postMitigationImpactVarianceMin.value > postMitigationActvImpact || form.controls?.postMitigationImpactVarianceMin?.errors?.maxError">
														Error: Variance > Impact
													</kendo-formerror>
												</kendo-formfield>
												<kendo-formfield [ngClass]="{'no-cursor': disablePost, 'full-opacity-formfield': !disablePost}">
													<kendo-label
														[for]="postImpactVarianceMax"
														text="Max Variance (+Δ%)"
													></kendo-label>
													<kendo-numerictextbox
														#postImpactVarianceMax
														format="+# '%'"
														formControlName="postMitigationImpactVarianceMax"
														[step]="1"
														[min]='0'
														[spinners]='false'
														[changeValueOnScroll]='false'
														[disabled]='postMitigationActvImpact === null'
														(keydown)='testNumericKeypress($event)'
														(paste)='testPaste($event)'
													></kendo-numerictextbox>
													<kendo-formhint [align]="'end'">(Requires Activity Impact)</kendo-formhint>
												</kendo-formfield>
											</fieldset>
											<fieldset class='cost-impact-row'>
												<legend>Cost Impact</legend>
												<kendo-formfield>
													<kendo-label
														[for]="postMitigationEstCost"
														text="Estimated Cost ($)"
													></kendo-label>
													<!--	<kendo-numerictextbox
															#postMitigationEstCost
															[format]="'c0'"
															formControlName="postMitigationEstCost"
															[min]="0"
															[spinners]='false'
															[changeValueOnScroll]='false'
															placeholder=' '
														></kendo-numerictextbox>-->
													<kendo-textbox
														#postMitigationEstCost
														formControlName="postMitigationEstCostDisplay"
														placeholder=' '
														appMoneyMask
														(keydown)='testNumericKeypress($event, true)'
														(paste)='testPaste($event, true)'
														(blur)='roundExtraDecimals("postMitigationEstCost","postMitigationEstCostDisplay")'
													></kendo-textbox>
													<kendo-formhint [align]="'end'">(Optional)</kendo-formhint>
												</kendo-formfield>
												<kendo-formfield class='no-cursor'>
													<kendo-label
														[for]="postCostVarianceMin"
														text="Min Variance (-Δ%)"
													></kendo-label>
													<kendo-numerictextbox
														#postCostVarianceMin
														format="-# '%'"
														formControlName="costVarianceMin"
														[step]="1"
														[max]='postMitigationEstCost'
														[spinners]='false'
														[changeValueOnScroll]='false'
													></kendo-numerictextbox>
													<kendo-formhint [align]="'end'">(Coming Soon)</kendo-formhint>
												</kendo-formfield>
												<kendo-formfield class='no-cursor'>
													<kendo-label
														[for]="postCostVarianceMax"
														text="Max Variance (+Δ%)"
													></kendo-label>
													<kendo-numerictextbox
														#postCostVarianceMax
														format="+# '%'"
														formControlName="costVarianceMax"
														[step]="1"
														[min]="postMitigationEstCost"
														[spinners]='false'
														[changeValueOnScroll]='false'
													></kendo-numerictextbox>
													<kendo-formhint [align]="'end'">(Coming Soon)</kendo-formhint>
												</kendo-formfield>
											</fieldset>
										</div>
										<div class='score-card-col'>
											<div class='score-card'>
												<div>
													Score
												</div>
												<div>
													{{ postMitigationScore | async}}
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</ng-template>
					</kendo-tabstrip-tab>
				}
				<kendo-tabstrip-tab title="Schedule" [selected]="project.$currentProjectData.value?.riskMetricsType === 'coreRisk' ? tabIndex === 1 : tabIndex === 4">
					<ng-template kendoTabContent>
						<div class="content">
							<fieldset style='padding: 0'>
								<legend>Filter</legend>
								<div class='filter-container'>
									<kendo-filter #filter id='filter' [value]="filterValue" style='width: 100%' (valueChange)='onFilterChange($event)'>
										<kendo-filter-field *ngFor='let filter of filters' [field]='filter.field' [title]='filter.title' [editor]='filter.editor'></kendo-filter-field>
										<kendo-filter-field field='Status' editor="string" [operators]='["eq", "neq"]'>
											<ng-template kendoFilterValueEditorTemplate let-currentItem>
												<kendo-dropdownlist
													[data]="statusFilterList"
													[value]='currentItem.value'
													(valueChange)="editorValueChange($event, currentItem)"
												>
												</kendo-dropdownlist>
											</ng-template>
										</kendo-filter-field>
										<kendo-filter-field field='Start' editor='date'>
											<ng-template kendoFilterValueEditorTemplate let-currentItem>
												<kendo-datepicker
													[value]='currentItem.value'
													(valueChange)="editorValueChange($event, currentItem)"
													style='width: 165px'
												>
												</kendo-datepicker>
											</ng-template>
										</kendo-filter-field>
										<kendo-filter-field field='Finish' editor='date'>
											<ng-template kendoFilterValueEditorTemplate let-currentItem>
												<kendo-datepicker
													[value]='currentItem.value'
													(valueChange)="editorValueChange($event, currentItem)"
													style='width: 165px'
												>
												</kendo-datepicker>
											</ng-template>
										</kendo-filter-field>
										<kendo-filter-field field='Target_Duration' title='Original Duration' editor='number'>
											<ng-template kendoFilterValueEditorTemplate let-currentItem>
												<kendo-numerictextbox
													[format]="'n0'"
													[value]='currentItem.value'
													(valueChange)="editorValueChange($event, currentItem)"
													[changeValueOnScroll]='false'
													(keydown)='testNumericKeypress($event)'
													(paste)='testPaste($event)'
												></kendo-numerictextbox>
											</ng-template>
										</kendo-filter-field>
										@for (type of allActivityTypes; track type) {
											<kendo-filter-field [field]='"ActvTypeJoined"' [title]='type.actv_code_type?.toUpperCase()' editor='string' [operators]='["contains", "doesnotcontain"]'>
												<ng-template kendoFilterValueEditorTemplate let-currentItem>
													<div class="filter-row">
														<kendo-dropdownlist
															[data]="actvCodesByType.get(type.actv_code_type_id)"
															[textField]="'actv_code_name'"
															[valueField]="'actv_code_id'"
															(valueChange)="editorValueChange($event, currentItem, true)"
														>
														</kendo-dropdownlist>
													</div>
												</ng-template>
											</kendo-filter-field>
										}
									</kendo-filter>
								</div>
							</fieldset>
							<fieldset>
								<legend>Activities</legend>
								<kendo-grid
									[data]="gridView"
									[height]="410"
									[sortable]="true"
									[pageable]="true"
									[resizable]='false'
									[skip]='skip'
									[sort]='sort'
									[pageSize]="10"
									(cellClick)="toggleActivity($event)"
									(pageChange)="pageChange($event)"
									(sortChange)="sortChange($event)"
									(columnVisibilityChange)="columnChooserChange($event)"
									(columnResize)='columnResizeChange($event)'
									class='tagged-grid'
								>
									<ng-template kendoGridToolbarTemplate class='toolbar'>
										<button kendoButton fillMode="solid" [themeColor]='"success"' (click)='selectAllActivities()'>Tag All</button>
										<button kendoButton fillMode="solid" [themeColor]='"error"' (click)='selectAllActivities(true)'>Untag All</button>
										<kendo-buttongroup width='100%' selection="single" class='btn-group'>
											<button
												*ngFor="let button of tagTypeButtons"
												kendoButton
												[toggleable]="true"
												[selected]="button.selected"
												(selectedChange)="selectionChange($event, button)"
												class='item-btn'
												[class.selected-item-btn]='button.selected'
											>
												<span>
													{{ button.text }}
												</span>
											</button>
										</kendo-buttongroup>
										<kendo-grid-column-chooser class='column-chooser' [allowHideAll]='false'></kendo-grid-column-chooser>
									</ng-template>
									<kendo-grid-column
										field="task_code"
										title="ID"
										[width]="75"
										[style]='{"fontSize": "12px"}'
										[hidden]='hiddenColumns.has("task_code")'
									>
									</kendo-grid-column>
									<kendo-grid-column
										field="task_name"
										title="Name"
										[width]='239'
										[style]='{"fontSize": "12px", "lineHeight": "13px"}'
										[headerStyle]='{"alignItems": "end"}'
										[hidden]='hiddenColumns.has("task_name")'
									>
									</kendo-grid-column>
									<kendo-grid-column
										field="status_code"
										title="Status"
										[width]='70'
										[style]='{"fontSize": "12px"}'
										[hidden]='hiddenColumns.has("status_code")'
									>
										<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
											{{ dataItem['Status'] }}
										</ng-template>
									</kendo-grid-column>
									<kendo-grid-column
										field="target_drtn_hr_cnt"
										title="OD"
										[width]='30'
										[style]='{"fontSize": "12px"}'
										[headerStyle]="{'padding': '0px'}"
										[hidden]='hiddenColumns.has("target_drtn_hr_cnt")'
									>
										<ng-template kendoGridFilterCellTemplate let-filter let-column="column">
											<kendo-grid-numeric-filter-cell
												[column]="column"
												[filter]="filter"
												[format]='"#"'>
											</kendo-grid-numeric-filter-cell>
										</ng-template>
										<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
											{{ (dataItem.target_drtn_hr_cnt ?? 0) / 8 }}
										</ng-template>
									</kendo-grid-column>
									<kendo-grid-column
										field="Start"
										title="Start"
										[width]='70'
										[filterable]='false'
										[style]='{"fontSize": "12px"}'
										[hidden]='hiddenColumns.has("Start")'
									>
										<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
											{{ dataItem['Start'] | date: 'MM/dd/yyyy' }}
										</ng-template>
									</kendo-grid-column>
									<kendo-grid-column
										field="Finish"
										title="Finish"
										[width]='70'
										[filterable]='false'
										[style]='{"fontSize": "12px"}'
										[hidden]='hiddenColumns.has("Finish")'
									>
										<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
											{{ dataItem['Finish'] | date: 'MM/dd/yyyy' }}
										</ng-template>
									</kendo-grid-column>
									<kendo-grid-column
										field="total_float_hr_cnt"
										title="TF"
										[width]='35'
										[style]='{"fontSize": "12px"}'
										[hidden]='hiddenColumns.has("total_float_hr_cnt")'
										[headerStyle]="{'padding': '0px'}"
									>
										<ng-template kendoGridFilterCellTemplate let-filter let-column="column">
											<kendo-grid-numeric-filter-cell
												[column]="column"
												[filter]="filter"
												[format]='"#"'>
											</kendo-grid-numeric-filter-cell>
										</ng-template>
										<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
											{{ (dataItem.total_float_hr_cnt ?? 0) / 8 }}
										</ng-template>
									</kendo-grid-column>
									<kendo-grid-column
										field="Tag"
										title="Tag"
										[width]='50'
										[filterable]='false'
										[includeInChooser]='false'
										[headerStyle]="{'padding': '0px', 'align-content': 'center'}"
										[sortable]='false'>
										<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
											<kendo-badge-container *ngFor='let register of (registersByActvCode.get(dataItem.task_code) || [])' style="margin-left: 2px">
												<kendo-badge
													[rounded]="'full'"
													[fill]="'outline'"
													size="small"
												> {{
													register
													}}
												</kendo-badge>
											</kendo-badge-container>
											<button
												*ngIf='!registersByActvCode.get(dataItem.task_code)?.size'
												kendoButton
												[fillMode]='"flat"'
												(click)='toggleActivity(undefined, dataItem.task_code)'
												style="position: absolute; right: 8%; top: 0"
											>
												<svg style="fill: #37b400" width="20pt" height="20pt" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
													<path d="m600 120c-264.96 0-480 215.04-480 480s215.04 480 480 480 480-215.04 480-480-215.04-480-480-480zm192.72 526.56h-146.16v146.16c0 25.801-20.762 46.559-46.559 46.559-25.801 0-46.559-20.762-46.559-46.559v-146.16h-146.16c-25.801 0-46.559-20.762-46.559-46.559 0-25.801 20.762-46.559 46.559-46.559h146.16v-146.16c0-25.801 20.762-46.559 46.559-46.559 25.801 0 46.559 20.762 46.559 46.559v146.16h146.16c25.801 0 46.559 20.762 46.559 46.559 0.003906 25.801-20.879 46.559-46.559 46.559z"/>
												</svg>
											</button>
										</ng-template>
									</kendo-grid-column>
									<ng-template kendoPagerTemplate
															 let-totalPages="totalPages"
															 let-currentPage="currentPage"
									>
										<kendo-pager-prev-buttons></kendo-pager-prev-buttons>
										<kendo-pager-numeric-buttons [buttonCount]="6"></kendo-pager-numeric-buttons>

										<kendo-pager-next-buttons></kendo-pager-next-buttons>
										<div style='margin-left: auto'>
											{{gridView.total > 0 ? (currentPage - 1)*10 + 1 : 0}} - {{gridView.total > 0 ? currentPage*10 > gridView.total ? gridView.total : currentPage * 10 : 0}}
											of
											{{gridView.total | number}} items</div>
									</ng-template>
								</kendo-grid>
							</fieldset>
						</div>
					</ng-template>
				</kendo-tabstrip-tab>
			</kendo-tabstrip>
			<div style="text-align: right; padding-top: 10px; display: flex;">
				<button
					kendoButton
					fillMode="solid"
					themeColor='base'
					*ngIf='tabIndex > 0'
					(click)='tabChange(-1)'
					[disabled]='tabIndex <= 0'
				>
					Back
				</button>
				<span style='margin-left: auto; margin-right: 0; display: flex; flex-direction: row; justify-content: flex-end;'>
					<div *ngIf='riskMitigationData?.lastUpdated' style='margin: auto 10px auto 0;'>
						Last Updated: {{riskMitigationData.lastUpdated | date:'MM/dd/yyyy' }}
					</div>
					<button
						kendoButton
						fillMode="solid"
						themeColor='warning'
						style='margin-right: 10px;'
						[disabled]='form.pristine'
						(click)='submitForm(true)'
					>
						Save as Draft
					</button>
					<button
						kendoButton
						fillMode="solid"
						themeColor='info'
						style='margin-right: 10px;'
						*ngIf='tabIndex < 4'
						(click)='tabChange(1)'
						[disabled]='tabIndex >= 4'
					>
						Next
					</button>
					<button kendoButton fillMode="solid" themeColor='success' (click)='submitForm(false)' *ngIf='form.valid && impactsValid'>Save</button>
					<button kendoButton fillMode="solid" themeColor='secondary' (click)='goToFirstInvalidTab()' *ngIf='!form.valid || !impactsValid'>Save</button>
				</span>
			</div>
    </div>
  </div>
</div>
