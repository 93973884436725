import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { SlimmedTaskCommon, TaskArrayInterface, UpdateInterface } from '../../../../models/Update/Task';
import { CurrentProjectReport, ProjectDashboardService } from '../../../../services/project/project.service';
import {
	isCriticalPlanned,
	isLookaheadCriticalPlanned,
	isLookaheadNearCriticalPlanned,
	isLookaheadNonCriticalPlanned,
	isNearCriticalPlanned,
	isNonCriticalPlanned,
	shortNames,
	dataFromXer,
} from '../../../../util/tasks';
import { ProjectReportInterface } from '../../../../models/ProjectReport/ProjectReport';
import { RestService } from '../../../../services/common/rest.service';
import {
	AxisSettings,
	KendoColumn,
	PlotLineType,
	SeriesData,
	SeriesDataSettings,
} from '../../../../models/ChartSettings';
import { ExcelExportEvent, GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query/dist/npm/sort-descriptor';
import { hasObjChanged } from '../../../../util/projects';
import { FloatConsumptionView } from '../float-consumption/float-consumption-table-card/float-consumption-table-card.component';
import { formatExcelExport } from '../../../../util/excel';
import { cleanDateUTC } from '../../../../util/pipes/date.pipe';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import {
	Activity,
	ActivityTypeInterface,
	IActivityCode,
	Xer,
	XerActivity,
	XerActivityCode,
	XerActivityType,
	XerData,
	XerProject,
} from '@rhinoworks/xer-parse';
import { LOCAL_STORAGE } from '../../../portfolio/portfolio.component';
import { IButton, isQuickViewOption, ProjectInterface, QuickViewMapping } from '../../../../models/Project';
import { FilterItem } from '../../../portfolio/project-list/project-list/project-list.component';
import { haveCommonItem } from '../../../../util/strings';
import { NavigationBarStorageService } from '../../../../services/common/navigation-bar-storage.service';
import { ProfileCompanyPermission } from '../../../../models/auth/account-user';
import { caretAltDownIcon, fileExcelIcon, searchIcon, SVGIcon } from '@progress/kendo-svg-icons';

export const allColumns = require('./activity-completion-columns.json') as {
	columns: KendoColumn[];
};

export enum ActvCompletionView {
	overview = 'overview',
	critical = 'Critical',
	noncritical = 'Non Critical',
	nearcritical = 'Near Critical',
	total = 'Total',
}

export type ActvCompletionActv = SlimmedTaskCommon & {
	latest_status?: 'Not Started' | 'Completed' | 'Incomplete' | 'Deleted';
	latest_end_date?: Date;
	latest_start_date?: Date;
	category?: ActvCompletionView;
	activityCodes?: string; //comma separated shortcode string, mainly for excel export
};

const statusDict = {
	TK_Complete: 'Completed',
	TK_NotStart: 'Not Started',
	TK_Active: 'Incomplete',
};

export type ActvCodeFilterItem = CodeFilterItem & {
	subCodes: SubCodeFilterItem[];
};

function union<T>(array1: T[], array2: T[]): T[] {
	return [...new Set([...array1, ...array2])];
}

export type CodeFilterItem = {
	alwaysDisabled: boolean;
	disabled: boolean;
	id: number;
	name: string;
	sequenceNumber: number;
	shortName: string;
	totalAssignments: number;
};

export type SubCodeFilterItem = CodeFilterItem & {
	parentName: string;
	alwaysDisabled: boolean;
};

@Component({
	selector: 'app-activity-completion',
	templateUrl: './activity-completion.component.html',
	styleUrls: ['./activity-completion.component.scss'],
})
export class ActivityCompletionComponent implements OnInit {
	public svgExcel: SVGIcon = fileExcelIcon;
	public svgSearch: SVGIcon = searchIcon;
	icons = {
		caretDown: caretAltDownIcon,
	};
	@Input() visualizer: boolean = false;
	@Input() hideTrending: boolean = false;
	@Input() isOverview: boolean = false;
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	ActvCompletionView = ActvCompletionView;
	$criticalPercentComplete = new BehaviorSubject<number>(0);
	$nearCriticalPercentComplete = new BehaviorSubject<number>(0);
	$nonCriticalPercentComplete = new BehaviorSubject<number>(0);
	$totalPercentComplete = new BehaviorSubject<number>(0);
	categories: string[] = ['Critical\n(Longest Path)', 'Near Critical', 'Non Critical', 'Total'];
	seriesData: SeriesDataSettings[] = [];
	allSubCodes;
	lookahead30SeriesData: SeriesDataSettings[] = [];
	lookahead60SeriesData: SeriesDataSettings[] = [];
	lookahead90SeriesData: SeriesDataSettings[] = [];
	valueAxisItemSettings: AxisSettings[] = [
		{
			title: {
				text: 'Activity Count',
				visible: true,
			},
			labels: {
				format: '{0}',
			},
			majorGridLines: {
				visible: true,
			},
		},
	];
	trendCategories: string[] = [];
	trendSeriesData: SeriesDataSettings[] = [];
	trendValueAxisItemSettings: AxisSettings[] = [
		{
			title: {
				text: 'Completion Ratio',
				visible: true,
			},
			labels: {
				format: '{0}%',
			},
			min: 0,
			max: 100,
			majorGridLines: {
				visible: true,
			},
		},
	];
	trendPlotLines: PlotLineType[] = [
		{
			color: '#DF5353',
			dashType: 'dash',
			label: '100% Target',
			value: 100,
			width: 1,
		},
		{
			color: '#4fc931',
			dashType: 'dash',
			label: '80% Target',
			value: 80,
			width: 1,
		},
	];
	tableView: ActvCompletionView = ActvCompletionView.overview;
	allPrevActivities = new Map<string, ActvCompletionActv>([]);
	totalActivityCodes = [];
	currentDisplaySet: ActvCompletionActv[] = [];
	unfilteredDisplaySet: {
		[key in ActvCompletionView]?: ActvCompletionActv[];
	} = {
		[ActvCompletionView.critical]: [],
		[ActvCompletionView.noncritical]: [],
		[ActvCompletionView.nearcritical]: [],
		[ActvCompletionView.total]: [],
	};
	unfilteredLookahead30DisplaySet: {
		[key in ActvCompletionView]?: ActvCompletionActv[];
	} = {
		[ActvCompletionView.critical]: [],
		[ActvCompletionView.noncritical]: [],
		[ActvCompletionView.nearcritical]: [],
		[ActvCompletionView.total]: [],
	};
	unfilteredLookahead60DisplaySet: {
		[key in ActvCompletionView]?: ActvCompletionActv[];
	} = {
		[ActvCompletionView.critical]: [],
		[ActvCompletionView.noncritical]: [],
		[ActvCompletionView.nearcritical]: [],
		[ActvCompletionView.total]: [],
	};
	unfilteredLookahead90DisplaySet: {
		[key in ActvCompletionView]?: ActvCompletionActv[];
	} = {
		[ActvCompletionView.critical]: [],
		[ActvCompletionView.noncritical]: [],
		[ActvCompletionView.nearcritical]: [],
		[ActvCompletionView.total]: [],
	};
	tableSearch = '';
	exportProcessing: boolean = false;
	@ViewChild(GridComponent)
	public grid: GridComponent;
	public gridView: GridDataResult;
	gridData: Array<any> = [];
	loading: boolean = true;
	public sort: SortDescriptor[] = [
		{
			dir: 'asc',
			field: 'target_end_date',
		},
	];
	public pageSize = 100;
	public skip = 0;
	selectedColumns: KendoColumn[] = [];
	searchTerm = '';
	currentUpdateActivityMap: Map<string, SlimmedTaskCommon> = new Map<string, SlimmedTaskCommon>([]);
	latestUpdateActivityMap: Map<string, SlimmedTaskCommon> = new Map<string, SlimmedTaskCommon>([]);
	criticalPlanned: Map<string, SlimmedTaskCommon[]> = new Map<string, SlimmedTaskCommon[]>([]);
	criticalActual: Map<string, SlimmedTaskCommon[]> = new Map<string, SlimmedTaskCommon[]>([]);
	nearCriticalPlanned: Map<string, SlimmedTaskCommon[]> = new Map<string, SlimmedTaskCommon[]>([]);
	nearCriticalActual: Map<string, SlimmedTaskCommon[]> = new Map<string, SlimmedTaskCommon[]>([]);
	nonCriticalPlanned: Map<string, SlimmedTaskCommon[]> = new Map<string, SlimmedTaskCommon[]>([]);
	nonCriticalActual: Map<string, SlimmedTaskCommon[]> = new Map<string, SlimmedTaskCommon[]>([]);
	totalPlanned: Map<string, SlimmedTaskCommon[]> = new Map<string, SlimmedTaskCommon[]>([]);
	totalActual: Map<string, SlimmedTaskCommon[]> = new Map<string, SlimmedTaskCommon[]>([]);
	public selectedActivityCodes: any[] = [];
	allActivityCodes: ActvCodeFilterItem[] = [];
	codesTag: string = '';
	@ViewChild('activityCodesMultiselectTree', { static: true }) public multiSelectTree: any;
	public lastUpdateId: string;
	currentProjectCompanyPermissions: ProfileCompanyPermission = null;
	isLookahead = false;
	public timespanButtons: IButton[] = [
		{
			text: '30',
			value: 30,
			selected: true,
		},
		{
			text: '60',
			value: 60,
		},
		{
			text: '90',
			value: 90,
		},
	];
	selectedTimespan: number = 30;
	public forwardOrBackwardButtons: IButton[] = [
		{
			text: 'Actual',
			value: -1,
			selected: true,
		},
		{
			text: 'Planned',
			value: 1,
		},
	];

	constructor(
		public project: ProjectDashboardService,
		private restService: RestService,
		public storage: ScheduleStorageService,
		public navBarStorage: NavigationBarStorageService
	) {}

	ngOnInit(): void {
		this.project.$currentProjectData.subscribe((project) => {
			this.lastUpdateId = project?.updateIds[project.updateIds.length - 2];
		});
		this.project.$currentProjectReport.pipe(takeUntil(this._unsubscribeAll), debounceTime(100)).subscribe((report) => {
			// this.getScheduleTrendData(report);
			if (report?.project?.company) {
				this.currentProjectCompanyPermissions = this.navBarStorage.companyPermissionMap.get(report?.project?.company);
			}
			if (report && this.storage.$allUpdates.value.length === report.updateIds.length) {
				this.updateData(this.storage.$allUpdates.value, report, false);
			}
		});
		this.storage.$allUpdates.pipe(takeUntil(this._unsubscribeAll)).subscribe((updates: UpdateInterface[]) => {
			if (this.project.$currentProjectReport.value && this.project.$currentProjectReport.value) {
				this.updateData(updates, this.project.$currentProjectReport.value, false);
			}
		});
		if (this.hideTrending) {
			this.forwardOrBackwardButtons = [
				{
					text: 'Actual',
					value: -1,
					selected: false,
				},
				{
					text: 'Planned',
					value: 1,
					selected: true,
				},
			];
			this.timespanButtons = [
				{
					text: '30',
					value: 30,
					selected: false,
				},
				{
					text: '60',
					value: 60,
					selected: false,
				},
				{
					text: '90',
					value: 90,
					selected: true,
				},
			];
			this.selectedTimespan = 90;
			this.isLookahead = true;
			this.setTableView(this.tableView);
		}
	}

	OnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	/**
	 * update grid data and prepare 2 charts data
	 * @param updates
	 * @param report
	 * @param skipFilterReset
	 */
	async updateData(updates: UpdateInterface[], report: CurrentProjectReport, skipFilterReset: boolean = false) {
		if (updates.length < 2) {
			return;
		}
		this.loading = true;
		this.selectedColumns = allColumns.columns;
		this.criticalPlanned.clear();
		this.criticalActual.clear();
		this.nearCriticalPlanned.clear();
		this.nearCriticalActual.clear();
		this.nonCriticalPlanned.clear();
		this.nonCriticalActual.clear();
		this.totalPlanned.clear();
		this.totalActual.clear();
		const criticalData: SeriesData[] = (
			report.activityCompletionGraph.projectTrendGraph.criticalCompletionPercentageArray || []
		)
			.map((val, i) => ({
				category: `Update ${i}`,
				value: val,
			}))
			.slice(1);
		const nearCriticalData: SeriesData[] = (
			report.activityCompletionGraph.projectTrendGraph.nearCriticalCompletionPercentageArray || []
		)
			.map((val, i) => ({
				category: `Update ${i}`,
				value: val,
			}))
			.slice(1);
		const nonCriticalData: SeriesData[] = (
			report.activityCompletionGraph.projectTrendGraph.nonCriticalCompletionPercentageArray || []
		)
			.map((val, i) => ({
				category: `Update ${i}`,
				value: val,
			}))
			.slice(1);
		const totalData: SeriesData[] = (
			report.activityCompletionGraph.projectTrendGraph.totalCompletionPercentageArray || []
		)
			.map((val, i) => ({
				category: `Update ${i}`,
				value: val,
			}))
			.slice(1);
		const categories: string[] = [];
		const latestUpdate: UpdateInterface = updates[updates?.length - 1];
		const latestUpdateXerData = await this.storage.grabUpdateXer(latestUpdate._id);
		this.allActivityCodes = this.generateActivityCodeFilter(latestUpdateXerData);
		const allSubCodes = [];
		this.allActivityCodes.forEach((code) => {
			code.subCodes.forEach((subCode) => {
				const subcodeColumn = {
					title: subCode.name,
					shortName: subCode.shortName,
				};

				allSubCodes.push(subcodeColumn);
			});
		});
		this.allSubCodes = allSubCodes;

		const selectedActivityCodes = new Set<string>(this.selectedActivityCodes.map((item) => item.shortName));
		const prevActvCode = await this.storage.grabUpdateTable<XerActivityCode>(
			updates[updates.length - 2]._id,
			'ACTVCODE'
		);
		const prevTaskActv = await this.storage.grabUpdateTable<{
			task_id: number;
			actv_code_type_id: number;
			actv_code_id: number;
			proj_id: number;
		}>(updates[updates.length - 2]._id, 'TASKACTV');
		const prevActvsByTask: Record<
			number,
			Array<{
				task_id: number;
				actv_code_type_id: number;
				actv_code_id: number;
				proj_id: number;
			}>
		> = {};
		for (const ta of prevTaskActv) {
			if (!prevActvsByTask[ta.task_id]) {
				prevActvsByTask[ta.task_id] = [];
			}
			prevActvsByTask[ta.task_id].push(ta);
		}
		const currActvCodes = await this.storage.grabUpdateTable<XerActivityCode>(latestUpdate._id, 'ACTVCODE');
		const currTaskActv = await this.storage.grabUpdateTable<{
			task_id: number;
			actv_code_type_id: number;
			actv_code_id: number;
			proj_id: number;
		}>(latestUpdate._id, 'TASKACTV');
		const currActvsByTask: Record<
			number,
			Array<{
				task_id: number;
				actv_code_type_id: number;
				actv_code_id: number;
				proj_id: number;
			}>
		> = {};
		for (const ta of currTaskActv) {
			if (!currActvsByTask[ta.task_id]) {
				currActvsByTask[ta.task_id] = [];
			}
			currActvsByTask[ta.task_id].push(ta);
		}
		const prevProjects = await this.storage.grabUpdateTable<XerProject>(updates[updates.length - 2]._id, 'PROJECT');
		const taskPrev = await this.storage.grabUpdateTable<XerActivity>(updates[updates.length - 2]._id, 'TASK');
		const currAllTasks = await this.storage.grabUpdateTable<XerActivity>(updates[updates.length - 1]._id, 'TASK');
		const prevShortCodes = new Map<number, string[]>(
			taskPrev.map((task) => [
				task.task_id,
				prevActvCode
					.filter((ac) => prevActvsByTask[task.task_id]?.some((ta) => ta.actv_code_id === ac.actv_code_id))
					.map((t) => t.short_name) || [],
			])
		);
		const currShortCodes = new Map<number, string[]>(
			currAllTasks.map((task) => [
				task.task_id,
				currActvCodes
					.filter((ac) => currActvsByTask[task.task_id]?.some((ta) => ta.actv_code_id === ac.actv_code_id))
					.map((t) => t.short_name) || [],
			])
		);
		const prevTasks = new Map<string, SlimmedTaskCommon & XerActivity>(
			taskPrev
				.filter(
					(task) =>
						task.task_type !== 'TT_LOE' &&
						(!selectedActivityCodes.size ||
							haveCommonItem(new Set(prevShortCodes.get(task.task_id)), selectedActivityCodes))
				)
				.map((task) => [
					task.task_code,
					{
						...task,
						lastRecalcDate: new Date(prevProjects.find((p) => p.proj_id === task.proj_id).last_recalc_date),
						isCritical: !task.act_end_date && task.driving_path_flag === 'Y',
						activityCodeShortnames: prevShortCodes.get(task.task_id) || [],
						activityCodes: prevShortCodes.get(task.task_id) || [],
					},
				])
		);
		const currProjects = await this.storage.grabUpdateTable<XerProject>(updates[updates.length - 1]._id, 'PROJECT');
		const tasks = new Map<string, SlimmedTaskCommon>(
			currAllTasks
				.filter(
					(task) =>
						task.task_type !== 'TT_LOE' &&
						(!selectedActivityCodes.size ||
							haveCommonItem(new Set(currShortCodes.get(task.task_id)), selectedActivityCodes))
				)
				.map((task) => [
					task.task_code,
					{
						...task,
						lastRecalcDate: new Date(currProjects.find((p) => p.proj_id === task.proj_id).last_recalc_date),
						isCritical: !task.act_end_date && task.driving_path_flag === 'Y',
						activityCodeShortnames: currShortCodes.get(task.task_id),
						activityCodes: currShortCodes.get(task.task_id) || [],
					},
				])
		);
		this.latestUpdateActivityMap.clear();

		this.latestUpdateActivityMap = tasks;

		const update = updates[updates.length - 1];
		const dataDate = new Date(
			update.dataDate ||
				report.projectCompletionTrend.projectCompletionTrendArray[
					report.projectCompletionTrend.projectCompletionTrendArray.length - 1
				].dataDate
		);
		const category = 'Update ' + (updates.length - 1) + (update?.baseline ? ' ®' : '');
		categories.push(category);
		const criticalPlanned: SlimmedTaskCommon[] = [];
		const criticalActual: SlimmedTaskCommon[] = [];
		const nearCriticalPlanned: SlimmedTaskCommon[] = [];
		const nearCriticalActual: SlimmedTaskCommon[] = [];
		const nonCriticalPlanned: SlimmedTaskCommon[] = [];
		const nonCriticalActual: SlimmedTaskCommon[] = [];
		const criticalLookahead30: SlimmedTaskCommon[] = [];
		const criticalLookahead60: SlimmedTaskCommon[] = [];
		const criticalLookahead90: SlimmedTaskCommon[] = [];
		const nearCriticalLookahead30: SlimmedTaskCommon[] = [];
		const nearCriticalLookahead60: SlimmedTaskCommon[] = [];
		const nearCriticalLookahead90: SlimmedTaskCommon[] = [];
		const nonCriticalLookahead30: SlimmedTaskCommon[] = [];
		const nonCriticalLookahead60: SlimmedTaskCommon[] = [];
		const nonCriticalLookahead90: SlimmedTaskCommon[] = [];
		let totalLookahead30: SlimmedTaskCommon[] = [];
		let totalLookahead60: SlimmedTaskCommon[] = [];
		let totalLookahead90: SlimmedTaskCommon[] = [];
		for (const [code, task] of prevTasks) {
			const currTask = tasks.get(code);
			const nowComplete = currTask?.status_code === 'TK_Complete';
			if (isCriticalPlanned(task, dataDate)) {
				criticalPlanned.push(task);
				if (nowComplete) {
					criticalActual.push(currTask);
				}
			}
			if (isNearCriticalPlanned(task, dataDate)) {
				nearCriticalPlanned.push(task);
				if (nowComplete) {
					nearCriticalActual.push(currTask);
				}
			}
			if (isNonCriticalPlanned(task, dataDate)) {
				nonCriticalPlanned.push(task);
				if (nowComplete) {
					nonCriticalActual.push(currTask);
				}
			}
			this.unfilteredLookahead30DisplaySet = {
				[ActvCompletionView.critical]: [],
				[ActvCompletionView.noncritical]: [],
				[ActvCompletionView.nearcritical]: [],
				[ActvCompletionView.total]: [],
			};
			this.unfilteredLookahead60DisplaySet = {
				[ActvCompletionView.critical]: [],
				[ActvCompletionView.noncritical]: [],
				[ActvCompletionView.nearcritical]: [],
				[ActvCompletionView.total]: [],
			};
			this.unfilteredLookahead90DisplaySet = {
				[ActvCompletionView.critical]: [],
				[ActvCompletionView.noncritical]: [],
				[ActvCompletionView.nearcritical]: [],
				[ActvCompletionView.total]: [],
			};
		}
		for (const [code, task] of tasks) {
			const currTask = tasks.get(code);
			const nowComplete = currTask?.status_code === 'TK_Complete';
			if (!nowComplete) {
				if (isLookaheadCriticalPlanned(task, task.lastRecalcDate, 90)) {
					criticalLookahead90.push(task);
					if (isLookaheadCriticalPlanned(task, task.lastRecalcDate, 60)) {
						criticalLookahead60.push(task);
						if (isLookaheadCriticalPlanned(task, task.lastRecalcDate, 30)) {
							criticalLookahead30.push(task);
						}
					}
				}
				if (isLookaheadNearCriticalPlanned(task, task.lastRecalcDate, 90)) {
					nearCriticalLookahead90.push(task);
					if (isLookaheadNearCriticalPlanned(task, task.lastRecalcDate, 60)) {
						nearCriticalLookahead60.push(task);
						if (isLookaheadNearCriticalPlanned(task, task.lastRecalcDate, 30)) {
							nearCriticalLookahead30.push(task);
						}
					}
				}
				if (isLookaheadNonCriticalPlanned(task, task.lastRecalcDate, 90)) {
					nonCriticalLookahead90.push(task);
					if (isLookaheadNonCriticalPlanned(task, task.lastRecalcDate, 60)) {
						nonCriticalLookahead60.push(task);
						if (isLookaheadNonCriticalPlanned(task, task.lastRecalcDate, 30)) {
							nonCriticalLookahead30.push(task);
						}
					}
				}
			}
		}
		totalLookahead30 = [...criticalLookahead30, ...nearCriticalLookahead30, ...nonCriticalLookahead30];
		totalLookahead60 = [...criticalLookahead60, ...nearCriticalLookahead60, ...nonCriticalLookahead60];
		totalLookahead90 = [...criticalLookahead90, ...nearCriticalLookahead90, ...nonCriticalLookahead90];
		this.fitDataToType(criticalLookahead30, ActvCompletionView.critical, 'unfilteredLookahead30DisplaySet');
		this.fitDataToType(nearCriticalLookahead30, ActvCompletionView.nearcritical, 'unfilteredLookahead30DisplaySet');
		this.fitDataToType(nonCriticalLookahead30, ActvCompletionView.noncritical, 'unfilteredLookahead30DisplaySet');
		this.fitDataToType(criticalLookahead60, ActvCompletionView.critical, 'unfilteredLookahead60DisplaySet');
		this.fitDataToType(nearCriticalLookahead60, ActvCompletionView.nearcritical, 'unfilteredLookahead60DisplaySet');
		this.fitDataToType(nonCriticalLookahead60, ActvCompletionView.noncritical, 'unfilteredLookahead60DisplaySet');
		this.fitDataToType(criticalLookahead90, ActvCompletionView.critical, 'unfilteredLookahead90DisplaySet');
		this.fitDataToType(nearCriticalLookahead90, ActvCompletionView.nearcritical, 'unfilteredLookahead90DisplaySet');
		this.fitDataToType(nonCriticalLookahead90, ActvCompletionView.noncritical, 'unfilteredLookahead90DisplaySet');
		this.lookahead30SeriesData = [
			{
				type: 'column',
				data: [
					{
						category: 'Critical\n(Longest Path)',
						value: criticalLookahead30?.length || 0,
					},
					{
						category: 'Near Critical',
						value: nearCriticalLookahead30?.length || 0,
					},
					{
						category: 'Non Critical',
						value: nonCriticalLookahead30?.length || 0,
					},
					{
						category: 'Total',
						value: totalLookahead30?.length || 0,
					},
				],
				name: 'Planned',
				visible: true,
				color: '#8a8a8a',
				marker: 'rect',
			},
		];
		this.lookahead60SeriesData = [
			{
				type: 'column',
				data: [
					{
						category: 'Critical\n(Longest Path)',
						value: criticalLookahead60?.length || 0,
					},
					{
						category: 'Near Critical',
						value: nearCriticalLookahead60?.length || 0,
					},
					{
						category: 'Non Critical',
						value: nonCriticalLookahead60?.length || 0,
					},
					{
						category: 'Total',
						value: totalLookahead60?.length || 0,
					},
				],
				name: 'Planned',
				visible: true,
				color: '#8a8a8a',
				marker: 'rect',
			},
		];
		this.lookahead90SeriesData = [
			{
				type: 'column',
				data: [
					{
						category: 'Critical\n(Longest Path)',
						value: criticalLookahead90?.length || 0,
					},
					{
						category: 'Near Critical',
						value: nearCriticalLookahead90?.length || 0,
					},
					{
						category: 'Non Critical',
						value: nonCriticalLookahead90?.length || 0,
					},
					{
						category: 'Total',
						value: totalLookahead90?.length || 0,
					},
				],
				name: 'Planned',
				visible: true,
				color: '#8a8a8a',
				marker: 'rect',
			},
		];
		const totalPlanned: SlimmedTaskCommon[] = [...criticalPlanned, ...nearCriticalPlanned, ...nonCriticalPlanned];
		const totalActual: SlimmedTaskCommon[] = [...criticalActual, ...nearCriticalActual, ...nonCriticalActual];
		this.criticalPlanned.set(updates[updates.length - 2]._id, criticalPlanned);
		this.criticalActual.set(updates[updates.length - 1]._id, criticalActual);
		this.nearCriticalPlanned.set(updates[updates.length - 2]._id, nearCriticalPlanned);
		this.nearCriticalActual.set(updates[updates.length - 1]._id, nearCriticalActual);
		this.nonCriticalPlanned.set(updates[updates.length - 2]._id, nonCriticalPlanned);
		this.nonCriticalActual.set(updates[updates.length - 1]._id, nonCriticalActual);
		this.totalPlanned.set(updates[updates.length - 2]._id, totalPlanned);
		this.totalActual.set(updates[updates.length - 1]._id, totalActual);
		this.currentUpdateActivityMap.clear();
		this.currentUpdateActivityMap = new Map(currAllTasks.map((actv) => [actv.task_code, actv]));
		this.unfilteredDisplaySet = {
			[ActvCompletionView.critical]: [],
			[ActvCompletionView.noncritical]: [],
			[ActvCompletionView.nearcritical]: [],
			[ActvCompletionView.total]: [],
		};
		this.fitDataToType(this.criticalPlanned.get(updates[updates.length - 2]?._id), ActvCompletionView.critical);
		this.fitDataToType(this.nearCriticalPlanned.get(updates[updates.length - 2]?._id), ActvCompletionView.nearcritical);
		this.fitDataToType(this.nonCriticalPlanned.get(updates[updates.length - 2]?._id), ActvCompletionView.noncritical);
		this.currentDisplaySet =
			(this.isLookahead
				? this.selectedTimespan === 30
					? this.unfilteredLookahead30DisplaySet[this.tableView]
					: this.selectedTimespan === 60
						? this.unfilteredLookahead60DisplaySet[this.tableView]
						: this.unfilteredLookahead90DisplaySet[this.tableView]
				: this.unfilteredDisplaySet[this.tableView]) || [];
		this.loading = false;
		this.$criticalPercentComplete.next(
			100 *
				(this.criticalActual.get(updates[updates.length - 1]._id)?.length /
					(this.criticalPlanned.get(updates[updates.length - 2]._id)?.length || 1))
		);
		this.$nearCriticalPercentComplete.next(
			100 *
				(this.nearCriticalActual.get(updates[updates.length - 1]._id)?.length /
					(this.nearCriticalPlanned.get(updates[updates.length - 2]._id)?.length || 1))
		);
		this.$nonCriticalPercentComplete.next(
			100 *
				(this.nonCriticalActual.get(updates[updates.length - 1]._id)?.length /
					(this.nonCriticalPlanned.get(updates[updates.length - 2]._id)?.length || 1))
		);
		this.$totalPercentComplete.next(
			100 *
				(this.totalActual.get(updates[updates.length - 1]._id)?.length /
					(this.totalPlanned.get(updates[updates.length - 2]._id)?.length || 1))
		);
		const plannedData: SeriesData[] = [
			{
				category: 'Critical\n(Longest Path)',
				value: this.criticalPlanned.get(updates[updates.length - 2]._id)?.length || 0,
			},
			{
				category: 'Near Critical',
				value: this.nearCriticalPlanned.get(updates[updates.length - 2]._id)?.length || 0,
			},
			{
				category: 'Non Critical',
				value: this.nonCriticalPlanned.get(updates[updates.length - 2]._id)?.length || 0,
			},
			{
				category: 'Total',
				value: this.totalPlanned.get(updates[updates.length - 2]._id)?.length || 0,
			},
		];
		const completedData: SeriesData[] = [
			{
				category: 'Critical\n(Longest Path)',
				value: this.criticalActual.get(updates[updates.length - 1]._id)?.length || 0,
			},
			{
				category: 'Near Critical',
				value: this.nearCriticalActual.get(updates[updates.length - 1]._id)?.length || 0,
			},
			{
				category: 'Non Critical',
				value: this.nonCriticalActual.get(updates[updates.length - 1]._id)?.length || 0,
			},
			{
				category: 'Total',
				value: this.totalActual.get(updates[updates.length - 1]._id)?.length || 0,
			},
		];
		const percentageCompletedData: SeriesData[] = [
			{
				category: 'Critical\n(Longest Path)',
				value: this.$criticalPercentComplete.value || 0,
			},
			{
				category: 'Near Critical',
				value: this.$nearCriticalPercentComplete.value || 0,
			},
			{
				category: 'Non Critical',
				value: this.$nonCriticalPercentComplete.value || 0,
			},
			{
				category: 'Total',
				value: this.$totalPercentComplete.value || 0,
			},
		];
		this.getScheduleTrendData(
			plannedData,
			completedData,
			percentageCompletedData,
			criticalData,
			nearCriticalData,
			nonCriticalData,
			totalData,
			categories
		);
		if (!skipFilterReset) {
			const allSubCodes = [];
			this.allActivityCodes.forEach((code) => {
				code.subCodes.forEach((subCode) => {
					const subcodeColumn = {
						title: subCode.name,
						shortName: subCode.shortName,
					};

					allSubCodes.push(subcodeColumn);
				});
			});
			this.allSubCodes = allSubCodes;
		}
		this.loadActivities();
	}

	/**
	 * takes task data and fits it to type being used by the grids. also updates some values in the task to be from the
	 * correct update and be of the correct type
	 * @param taskArray
	 * @param type
	 * @param lookaheadField
	 */
	fitDataToType(taskArray: SlimmedTaskCommon[], type: ActvCompletionView, lookaheadField = ''): void {
		if (taskArray !== undefined) {
			taskArray.forEach((actv) => {
				const displayActv: ActvCompletionActv = { ...actv };
				const matchingActvInCurrentUpdate =
					lookaheadField !== ''
						? this.latestUpdateActivityMap.get(actv.task_code)
						: this.currentUpdateActivityMap.get(actv.task_code);
				displayActv.activityCodes = (actv.activityCodeShortnames || []).join(', ');
				displayActv.category = type;
				displayActv.target_start_date = actv.early_start_date;
				displayActv.target_end_date = actv.early_end_date;
				if (matchingActvInCurrentUpdate) {
					displayActv.latest_start_date = matchingActvInCurrentUpdate.act_start_date;
					displayActv.latest_end_date = matchingActvInCurrentUpdate.act_end_date;
					displayActv.latest_status = statusDict[matchingActvInCurrentUpdate.status_code];
				} else {
					displayActv.latest_status = 'Deleted';
				}

				// Added this section because the field and otherField implementation wouldn't allow for date sorting in the kendo grid. I only use the one field, so it's bounded for kendo sorting.
				// If the actual date does not exist, assign the otherField value to the main field. If the actual date does exist, assign null to the otherField. In the HTML it will check if the otherField is null or not,
				// and assign the ' A' accordingly. - KF
				if (!displayActv.latest_start_date) {
					displayActv.latest_start_date = actv.early_start_date;
				} else {
					displayActv.target_start_date = null;
				}
				if (!displayActv.latest_end_date) {
					displayActv.latest_end_date = actv.early_end_date;
				} else {
					displayActv.target_end_date = null;
				}

				if (lookaheadField === '') {
					this.unfilteredDisplaySet[type].push(displayActv);
					this.unfilteredDisplaySet.Total.push(displayActv);
				} else {
					this[lookaheadField][type].push(displayActv);
					this[lookaheadField].Total.push(displayActv);
				}
			});
		}
	}

	/**
	 * buttongroup selection change handler
	 * @param selected
	 * @param btn
	 * @param isTimespan
	 */
	public selectionChange(selected: boolean, btn: IButton, isTimespan = false): void {
		btn.selected = selected;
		if (btn.selected) {
			if (isTimespan) {
				this.selectedTimespan = btn.value;
			} else {
				this.isLookahead = btn.text === 'Planned';
			}
			this.setTableView(this.tableView);
		}
	}

	/**
	 * generate activity code/subcode items for the multiselect filter
	 * @param data
	 */
	generateActivityCodeFilter(data: XerData): ActvCodeFilterItem[] {
		const allActivityCodes: ActvCodeFilterItem[] = [];
		const actvTypes = dataFromXer<XerActivityType>(data, 'ACTVTYPE');
		actvTypes.forEach((actvType) => {
			const code: ActvCodeFilterItem = {
				alwaysDisabled: false,
				disabled:
					this.selectedActivityCodes?.length === 0
						? false
						: !this.selectedActivityCodes.some(
								(selActvCode) =>
									selActvCode.shortName === actvType.actv_code_type ||
									(selActvCode?.parentName === actvType.actv_code_type && selActvCode?.parentName !== undefined)
							),
				id: actvType.actv_code_type_id,
				name: actvType.actv_code_type.toString(),
				sequenceNumber: Number(actvType.seq_num),
				shortName: actvType.actv_code_type,
				subCodes: [],
				totalAssignments: 0,
			};
			allActivityCodes.push(code);
		});
		const actvCodes = dataFromXer<XerActivityCode>(data, 'ACTVCODE');
		actvCodes?.forEach((actvCode) => {
			const parent = allActivityCodes.find((code) => code.id === actvCode.actv_code_type_id);
			if (parent !== undefined) {
				const subCode: SubCodeFilterItem = {
					alwaysDisabled: false,
					disabled: false,
					id: actvCode.actv_code_id,
					name: actvCode?.actv_code_name?.toString() || actvCode?.short_name?.toString(),
					parentName: parent.name,
					sequenceNumber: actvCode.seq_num,
					shortName: actvCode.short_name,
					totalAssignments: actvCode.total_assignments || 0,
				};
				if (actvCode.total_assignments > 0) {
					parent.subCodes.push(subCode);
					parent.totalAssignments += actvCode.total_assignments;
				}
			}
		});
		return allActivityCodes.filter((code) => code.totalAssignments > 0);
	}

	/**
	 * determines if there is an intersection between a task's activity codes and the multiselect's selection
	 * @param task
	 */
	activityCodeIntersection(task: Activity): boolean {
		let returnVal = false;
		for (const actvCode of task.activityCodes) {
			if (this.selectedActivityCodes.some((selectedCode) => selectedCode.shortName === actvCode.shortName)) {
				returnVal = true;
				break;
			}
		}
		return returnVal;
	}

	/**
	 * sets table view to a filtered scope
	 * @param view
	 */
	setTableView(view: ActvCompletionView) {
		this.tableView = view;
		if (view === ActvCompletionView.overview) {
			view = ActvCompletionView.total; //makes sure activity codes can be disabled/enabled based on total activities for current display set
		}
		this.currentDisplaySet = this.isLookahead
			? this.selectedTimespan === 30
				? this.unfilteredLookahead30DisplaySet[view]
				: this.selectedTimespan === 60
					? this.unfilteredLookahead60DisplaySet[view]
					: this.unfilteredLookahead90DisplaySet[view]
			: this.unfilteredDisplaySet[view];
		this.loadActivities();
		this.searchTerm = '';
		this.reset();
		this.updateTrendLineVisibility(view);
	}

	/**
	 * updates trend chart and bar chart with latest data
	 * @param plannedData
	 * @param completedData
	 * @param percentageCompletedData
	 * @param criticalData
	 * @param nearCriticalData
	 * @param nonCriticalData
	 * @param totalData
	 * @param categories
	 */
	getScheduleTrendData(
		plannedData: SeriesData[],
		completedData: SeriesData[],
		percentageCompletedData: SeriesData[],
		criticalData: SeriesData[],
		nearCriticalData: SeriesData[],
		nonCriticalData: SeriesData[],
		totalData: SeriesData[],
		categories: string[]
	) {
		const seriesData: SeriesDataSettings[] = [
			{
				type: 'column',
				data: plannedData,
				name: 'Planned',
				visible: true,
				color: '#8a8a8a',
			},
			{
				type: 'column',
				data: completedData,
				name: 'Completed',
				visible: true,
				color: '#DF5353',
			},
			{
				type: 'column',
				data: percentageCompletedData,
				name: 'Percentage Completed',
				visible: false,
				color: 'black',
			},
		];

		if (hasObjChanged(this.seriesData, seriesData)) {
			this.seriesData = seriesData;
		}

		if (hasObjChanged(this.trendCategories, categories)) {
			this.trendCategories = categories;
		}
		const trendSeriesData: SeriesDataSettings[] = [
			{
				type: 'line',
				data: criticalData,
				name: 'Critical',
				visible: true,
				color: '#DF5353',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
			{
				type: 'line',
				data: nearCriticalData,
				name: 'Near Critical',
				visible: true,
				color: '#4fc931',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
			{
				type: 'line',
				data: nonCriticalData,
				name: 'Non Critical',
				visible: true,
				color: '#0059FF',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
			{
				type: 'line',
				data: totalData,
				name: 'Total',
				visible: true,
				color: 'black',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
		];
		if (hasObjChanged(this.trendSeriesData, trendSeriesData)) {
			this.trendSeriesData = trendSeriesData;
		}
	}

	updateFilter(args: { event?: any; searchTerm?: string }) {
		const searchTerm: string = args?.event?.target?.value?.toLowerCase() || args.searchTerm?.toLowerCase();
		this.tableSearch = searchTerm;
		let filteredDisplaySet: ActvCompletionActv[] = [];
		const unfilteredDisplaySet: ActvCompletionActv[] =
			(this.isLookahead
				? this.selectedTimespan === 30
					? this.unfilteredLookahead30DisplaySet[this.tableView]
					: this.selectedTimespan === 60
						? this.unfilteredLookahead60DisplaySet[this.tableView]
						: this.unfilteredLookahead90DisplaySet[this.tableView]
				: this.unfilteredDisplaySet[this.tableView]) || [];
		if (!searchTerm) {
			filteredDisplaySet = unfilteredDisplaySet;
		} else {
			for (const task of unfilteredDisplaySet) {
				if (
					task.task_code?.toLowerCase()?.includes(searchTerm) ||
					task.task_name?.toLowerCase()?.includes(searchTerm)
				) {
					filteredDisplaySet.push(task);
				}
			}
		}

		// update the rows
		this.currentDisplaySet = filteredDisplaySet || [];
		this.loadActivities();
	}

	/**
	 * multiselect valueChange handler
	 * @param ev
	 */
	filterChanged(ev?: ActvCodeFilterItem[]): void {
		if (this.loading) {
			return;
		}
		this.updateTagText(ev);

		this.updateData(this.storage.$allUpdates.value, this.project.$currentProjectReport.value, true).then(() => {
			this.allActivityCodes.forEach((code) => {
				let disabledCount = 0;
				code.subCodes.forEach((subCode) => {
					subCode.alwaysDisabled = false;
					if (!this.totalActivityCodes.includes(subCode.shortName)) {
						subCode.alwaysDisabled = true;
						disabledCount += 1;
					}
				});

				code.alwaysDisabled = disabledCount === code.subCodes.length;
			});

			/*this.allActivityCodes.forEach((code) => {
				code.disabled =
					this.selectedActivityCodes.length > 0
						? !this.selectedActivityCodes.some((c) => c.parentName === code.name)
						: false;
			});*/
		});
	}

	/**
	 * multiselect tag text updater
	 * @param ev
	 */
	updateTagText(ev?: ActvCodeFilterItem[]): void {
		if (ev?.length === 0) {
			this.codesTag = '';
		} else {
			const topLevelCode = ev.find((item) => item?.subCodes?.length > 0);
			if (topLevelCode !== undefined) {
				this.codesTag = topLevelCode.name;
			} else {
				this.codesTag = ev?.length === 1 ? ev[0].name : ev?.length + ' codes selected';
			}
		}
	}

	public itemDisabled(dataItem: any) {
		return dataItem.alwaysDisabled || dataItem.disabled;
	}

	doExport() {
		this.exportProcessing = true;
		this.restService
			.postToExporter('activity-completion/', {
				name: this.project.$currentProjectData.value.name + '\n' + cleanDateUTC(new Date(), 'MMMM d, yyyy'),
				sub_codes: this.allSubCodes,
				data: this.isLookahead
					? this.selectedTimespan === 30
						? this.unfilteredLookahead30DisplaySet[ActvCompletionView.total]
						: this.selectedTimespan === 60
							? this.unfilteredLookahead60DisplaySet[ActvCompletionView.total]
							: this.unfilteredLookahead90DisplaySet[ActvCompletionView.total]
					: this.unfilteredDisplaySet[ActvCompletionView.total],
			})
			.subscribe(
				(res: any) => {
					saveAs(res, 'AegisAnalytics-ExportActivityCompletionData.xlsx');
					this.exportProcessing = false;
				},
				(err) => {
					console.log(err);
					this.exportProcessing = false;
				}
			);
	}

	public pageChange(event: PageChangeEvent): void {
		this.skip = event.skip;
		this.loadActivities();
	}

	public sortChange(sort: SortDescriptor[]): void {
		this.sort = sort;
		this.loadActivities();
	}

	/**
	 * sets activities grid data to be the latest data at the current scroll/page spot
	 */
	public loadActivities(): void {
		//making sure on page load before setTableView is called, if on Overview get the activity Code Filters of total for current display set
		if (this.tableView === ActvCompletionView.overview) {
			const view = ActvCompletionView.total;
			this.currentDisplaySet = this.isLookahead
				? this.selectedTimespan === 30
					? this.unfilteredLookahead30DisplaySet[view]
					: this.selectedTimespan === 60
						? this.unfilteredLookahead60DisplaySet[view]
						: this.unfilteredLookahead90DisplaySet[view]
				: this.unfilteredDisplaySet[view];
		}

		if (this.selectedActivityCodes?.length <= 0) {
			let totalActivityCodes = [];
			this.currentDisplaySet.forEach((item: ActvCompletionActv) => {
				totalActivityCodes = union(item.activityCodes.split(', '), totalActivityCodes);
			});
			this.totalActivityCodes = totalActivityCodes;

			this.allActivityCodes.forEach((code) => {
				let disabledCount = 0;
				code.subCodes.forEach((subCode) => {
					subCode.alwaysDisabled = false;
					if (!totalActivityCodes.includes(subCode.shortName)) {
						subCode.alwaysDisabled = true;
						disabledCount += 1;
					}
				});

				code.alwaysDisabled = disabledCount === code.subCodes.length;
			});

			const exportData = this.isLookahead
				? this.selectedTimespan === 30
					? this.unfilteredLookahead30DisplaySet[ActvCompletionView.total]
					: this.selectedTimespan === 60
						? this.unfilteredLookahead60DisplaySet[ActvCompletionView.total]
						: this.unfilteredLookahead90DisplaySet[ActvCompletionView.total]
				: this.unfilteredDisplaySet[ActvCompletionView.total];

			exportData?.forEach((row) => {
				this.allSubCodes?.forEach((code) => {
					row[`activityCode_${code.shortName}`] = row.activityCodes.includes(code.shortName) ? 'x' : '';
				});
			});
		}

		this.gridView = {
			data: this.currentDisplaySet?.slice(this.skip, this.skip + this.pageSize),
			total: this.currentDisplaySet?.length,
		};
		this.gridData = this.currentDisplaySet;
	}

	reset() {
		this.skip = 0;
		this?.grid?.scrollTo({ row: 0, column: 0 });
	}

	/**
	 * makes only selected view line(s) visible in trend chart
	 * @param view
	 */
	updateTrendLineVisibility(view: ActvCompletionView): void {
		this.trendSeriesData.forEach((line) => {
			line.visible = view === 'overview' ? true : line.name === view;
		});
	}
}
