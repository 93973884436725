import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedMatModule } from 'components/shared/shared-mat.module';
import { IndexTrendingComponent } from './index-trending.component';
import { TooltipMenuModule } from '../../../portfolio/tooltip-menu/tooltip-menu.module';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { AegisChartModule } from '../../../shared/chart/chart.module';
import { GridLayoutModule } from '@progress/kendo-angular-layout';

@NgModule({
	declarations: [IndexTrendingComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		IonicModule,
		SharedMatModule,
		TooltipMenuModule,
		ChartsModule,
		AegisChartModule,
		GridLayoutModule,
	],
	exports: [IndexTrendingComponent],
})
export class IndexTrendingModule {}
