<div class='current-update-gantt-container' [class.current-update-gantt-container-month-view]="ganttService.slotWidth > 25">
	@if (ganttService.loading) {
		<kendo-grid [loading]='true' style='height: 100%; min-height: calc(100vh - 118.4px);'>
			<ng-template kendoGridNoRecordsTemplate>
			</ng-template>
		</kendo-grid>
	} @else {
		@if (ganttService.items?.length > 0) {
			<div class="toolbar-container" id="ganttLegendRow">
				<div class="data-date-blurb">
					Data Date: {{ganttService._dataDate | date: 'MMM d, yyyy' }}
				</div>
				<div class='gantt-legend-items-container' style="align-self: flex-start">
					<!--<div style='margin: auto 0;'>
						View:
					</div>
					<kendo-dropdownlist
						[data]="viewOptions"
						valueField='zoomVal'
						textField='display'
						(valueChange)='updateView($event)'
						[valuePrimitive]="true"
						[(ngModel)]="selectedGanttView"
						style='width: 100px; height: 24px; margin: auto 0;'
					></kendo-dropdownlist>-->
					<div style='margin: auto 0;'>
						Legend:
					</div>
					<div class='gantt-legend-item'>
						<div class='fake-gantt-bar' style='background-color: rgb(0 89 255); height: 20px;'></div>
						<div class='fake-gantt-bar-label'>Completed Work</div>
					</div>
					<div class='gantt-legend-item'>
						<div class='fake-gantt-bar' style='background-color: rgb(79, 201, 49); height: 20px;'></div>
						<div class='fake-gantt-bar-label'>Remaining Work</div>
					</div>
					<div class='gantt-legend-item'>
						<div class='fake-gantt-bar' style='background-color: rgb(223, 83, 83); height: 20px;'></div>
						<div class='fake-gantt-bar-label'>Critical Work</div>
					</div>
					<div class='gantt-legend-item'>
						<svg xmlns="http://www.w3.org/2000/svg"
								 width="20"
								 height="20"
								 viewBox="0 0 8 8"
								 style='height: 24px; width: 24px; transform: translate(-2px, 9px);'
						>
							<path d="M 3 0 L 6 3 L 3 6 L 0 3 L 3 0" fill="black"/>
						</svg>
						<div class='fake-gantt-bar-label' style='margin-left: -11px;'>Milestone</div>
					</div>
				</div>
			</div>
			<kendo-gantt
				style="align-self: flex-start; min-height: calc(100vh - 154.4px);"
				id="currentUpdateGantt"
				#currentUpdateGantt
				[currentTimeMarker]="false"
				[kendoGanttHierarchyBinding]="ganttService.items"
				childrenField="childTasks"
				kendoGanttExpandable
				[expandBy]='"id"'
				[initiallyExpanded]="true"
				[expandedKeys]='ganttService.expandedKeys'
				[dependencies]="ganttService.groupByApplied || ganttService.radioSelection !== null ? [] : ganttService.dependencies"
				[filterable]="false"
				[sortable]="true"
				[sort]="ganttService.sort"
				(sortChange)="sortChange($event)"
				[columnMenu]="false"
				[columnsResizable]="true"
				[columnsReorderable]="false"
				[navigable]='false'
				[timelinePaneOptions]='{ collapsed: false, collapsible: true, resizable: true, size: ganttService.selectedGroupByDelayed ? "calc(100% - 825px - " + ((ganttService?.radioSelectionDelayed === "wbs" ? ganttService.puppetLevel?.length * 10 : 0) + (ganttService?.radioSelectionDelayed === "actvCodes" ? (ganttService.selectedGroupByDelayed?.length * 10) + (ganttService.selectedGroupByDelayed?.length > 4 ? ((ganttService.selectedGroupByDelayed?.length * 24) - 100) : 0) : 0)) + "px)" : "calc(100% - 825px)" }'
				[treeListPaneOptions]='{ collapsed: false, collapsible: true }'
				[toolbarSettings]='{ position: "none" }'
				[activeView]="activeView"
				[taskClass]='taskCallback'
				[selectable]="true"
				[isSelected]="isSelected"
				(cellClick)="toggleSelection($event)"
				(taskClick)="toggleSelection($event)"
				[isExpanded]="isExpanded"
				class='current-update-gantt'
				[class.gantt-year-view-no-months]='ganttService.slotWidth <= 25'
				[class.currentlyDragging]='ganttService.isDragging'
				[class.hideMonthLines]='ganttService.slotWidth <= 35'
				[rowClass]="rowCallback"
				(rowExpand)="ganttService.toggleExpandRow($event, 'expand')"
				(rowCollapse)="ganttService.toggleExpandRow($event, 'collapse')"
			>
				<kendo-gantt-column
					[field]="null"
					[title]="null"
					[width]='ganttService.radioSelectionDelayed === "wbs" ? ganttService.puppetLevel?.length * 10 : ganttService.selectedGroupByDelayed?.length * 10'
					[headerClass]='["list-header"]'
					[hidden]='!ganttService.groupByApplied || (ganttService.selectedGroupByDelayed?.length === 0 && ganttService.highestLevel === 0)'
				>
					@if (ganttService.appliedGroupingType === 'wbs') {
						<ng-template kendoGanttCellTemplate let-dataItem>
							@for (col of ganttService.puppetLevel; track ganttService.puppetLevel; let i = $index) {
								<span style="width: 10px;" [style.background-color]="wbsColors(dataItem, i, 'wbs')">
								</span>
							}
						</ng-template>
					} @else {
						<ng-template kendoGanttCellTemplate let-dataItem>
							@for (col of ganttService.selectedGroupByDelayed; track ganttService.selectedGroupByDelayed; let i = $index) {
								<span style="width: 10px;" [style.background-color]="wbsColors(dataItem, i, 'actvCode')">
								</span>
							}
						</ng-template>
					}
				</kendo-gantt-column>
				<kendo-gantt-column
					*ngFor='let column of selectedColumns'
					[field]="column.field"
					[title]="column.title"
					[width]='(column.field === "taskCode" && ganttService?.puppetLevel?.length > 3) ? 9 + (ganttService?.puppetLevel?.length * 24) : (column.field === "taskCode" && this.ganttService.selectedGroupByDelayed?.length > 3) ? 9 + (this.ganttService.selectedGroupByDelayed?.length * 24) : column.width'
					[headerClass]='["list-header"]'
					[hidden]='ganttService.hiddenColumns.indexOf(column.field) > -1'
					[expandable]="column.field === 'taskCode' && ganttService.groupByApplied"
				>
					<ng-template kendoGanttCellTemplate let-dataItem>
						@if (column?.type === 'date') {
							<span style='white-space: nowrap; text-overflow: ellipsis;' class="centered-data">{{dataItem[column.field] === null ? '-' : (dataItem[column.field] | date: 'MMM d, yyyy') + ((column?.field === 'start' && dataItem?.startIsAct) || (column?.field === 'end' && dataItem?.endIsAct) ? ' A' : '')}}</span>
						} @else if (column?.type === 'currency') {
							<span style='white-space: nowrap; text-overflow: ellipsis;' class="centered-data">{{dataItem[column.field] === null ? '-' : dataItem[column.field] | currency: 'USD' : '$' :'1.0-0'}}</span>
						} @else if (column?.type === 'percent') {
							@if (dataItem[column.field] === null) {
								<span style='white-space: nowrap; text-overflow: ellipsis;' class="centered-data">-</span>
							} @else {
								<span style='white-space: nowrap; text-overflow: ellipsis;' class="centered-data">{{dataItem[column.field] | number: '1.0-0'}}%</span>
							}
						} @else if (column?.type === 'number') {
							<span style='white-space: nowrap; text-overflow: ellipsis;' class="centered-data">{{dataItem[column.field] === null ? '-' : dataItem[column.field] | number: '1.0-0'}}</span>
						} @else if (column?.field === 'taskCode') {
							<span style='white-space: nowrap; text-overflow: ellipsis; margin: auto 0;' class="centered-data">{{dataItem[column.field] === null ? '' : dataItem[column.field]}}</span>
						} @else {
							<span style='white-space: nowrap; text-overflow: ellipsis; margin: auto 0;'>{{dataItem[column.field] === null ? '-' : dataItem[column.field]}}</span>
						}
						<span id="reportPageLocator" class="invisible-but-there" [class.ready-to-ss]="ganttService.readyToSS"></span>
					</ng-template>
				</kendo-gantt-column>
				<kendo-gantt-timeline-year-view [slotWidth]="ganttService.slotWidth"></kendo-gantt-timeline-year-view>
			</kendo-gantt>
			<div class="data-date-gantt-line"></div>
		} @else {
			<div class="warning-div" style="height: 100%; position: absolute; ">
				No Activities for Applied Filters
			</div>
		}
	}
</div>
<ng-template #filterWindowContentTemplate style="min-height: 400px; height:auto;">
	<div style='border-radius: 0 0 4px 4px; background-color: white;' id='popupFiltersContainer'>
		<div style='overflow-x: hidden; overflow-y: auto;'>
			<div class='filters-container'>
				<div class='filter-container'>
					<kendo-filter #filter id='filter' [value]="ganttService.filterValue" style='width: 100%' (valueChange)='onFilterChange($event)' class="gantt-filter">
						<kendo-filter-field *ngFor='let filter of filters' [field]='filter.field' [title]='filter.title' [editor]='filter.editor'></kendo-filter-field>
						<kendo-filter-field field='Status' editor="string" [operators]='["eq", "neq"]'>
							<ng-template kendoFilterValueEditorTemplate let-currentItem>
								<kendo-dropdownlist
									[data]="statusFilterList"
									[value]='currentItem.value'
									(valueChange)="editorValueChange($event, currentItem)"
								>
								</kendo-dropdownlist>
							</ng-template>
						</kendo-filter-field>
						<kendo-filter-field field='start' editor='date' title="Start">
							<ng-template kendoFilterValueEditorTemplate let-currentItem>
								<kendo-datepicker
									[value]='currentItem.value'
									(valueChange)="editorValueChange($event, currentItem)"
									style='width: 165px'
								>
								</kendo-datepicker>
							</ng-template>
						</kendo-filter-field>
						<kendo-filter-field field='trueEnd' editor='date' title="Finish">
							<ng-template kendoFilterValueEditorTemplate let-currentItem>
								<kendo-datepicker
									[value]='currentItem.value'
									(valueChange)="editorValueChange($event, currentItem)"
									style='width: 165px'
								>
								</kendo-datepicker>
							</ng-template>
						</kendo-filter-field>
						@for (type of ganttService.allActivityTypes; track ganttService.allActivityTypes) {
							<kendo-filter-field [field]='"ActvType_" + type.actv_code_type_id' [title]='type.actv_code_type?.toUpperCase()' editor='string' [operators]='["contains", "doesnotcontain"]'>
								<ng-template kendoFilterValueEditorTemplate let-currentItem>
									<kendo-dropdownlist
										[data]="ganttService.actvCodesByType.get(type.actv_code_type_id)"
										[textField]="'actv_code_name'"
										[valueField]="'actv_code_id'"
										(valueChange)="editorValueChange($event, currentItem, true)"
									>
									</kendo-dropdownlist>
								</ng-template>
							</kendo-filter-field>
						}
						<kendo-filter-field field='tf' title="Total Float" editor="number" editorFormat="n0"></kendo-filter-field>
						<kendo-filter-field field='taskType' title="Task Type" editor="string" [operators]='["eq", "neq"]'>
							<ng-template kendoFilterValueEditorTemplate let-currentItem>
								<kendo-dropdownlist
									[data]="taskTypeFilterList"
									[value]='currentItem.value'
									(valueChange)="editorValueChange($event, currentItem)"
									[disabled]="currentItem === 'Level of Effort'"
								>
								</kendo-dropdownlist>
							</ng-template>
						</kendo-filter-field>
						<kendo-filter-field field='criticality' title="Criticality" editor="string" [operators]='["eq", "neq"]'>
							<ng-template kendoFilterValueEditorTemplate let-currentItem>
								<kendo-dropdownlist
									[data]="criticalityFilterList"
									[value]='currentItem.value'
									(valueChange)="editorValueChange($event, currentItem)"
								>
								</kendo-dropdownlist>
							</ng-template>
						</kendo-filter-field>
					</kendo-filter>
				</div>
			</div>
		</div>
	</div>
	<div style='padding: 5px 5px 0 5px; display: flex; justify-content: center; background-color: #c8cad6;'>
		<button style='margin-right: 8px;' kendoButton themeColor="error" title="Reset" [disabled]='ganttService.resetDisabled || ganttService.loading' (click)='ganttService.resetFilters()'>Reset</button>
		<button kendoButton themeColor="success" title="Apply" [disabled]='ganttService.applyDisabled || ganttService.loading' (click)='ganttService.applyFilterChanges(true)'>Apply</button>
	</div>
</ng-template>
<ng-template #columnSelectorPopupTemplate>
	<div id='columnSelectorContainer'>
		<div class='columns-btns-container'>
			<button
				kendoButton
				[size]="'small'"
				fillMode="outline"
				[rounded]="'medium'"
				[themeColor]="'info'"
				(click)='resetColumns()'
				style='display: block; width: 85%;'
			>
				Reset
			</button>
			<button
				kendoButton
				[size]="'small'"
				[rounded]="'medium'"
				fillMode="outline"
				[themeColor]="'primary'"
				style='display: block; width: 30px;'
				(click)="closeColumns()"
			>
				X
			</button>
		</div>
		<div style='max-height: 300px; overflow: auto;'>
			<kendo-treeview
				[nodes]="columnOptions"
				[children]="children"
				[hasChildren]="hasChildren"
				[isDisabled]='isDisabled'
				textField="title"
				[kendoTreeViewCheckable]="{checkParents: true}"
				[(checkedKeys)]="ganttService.checkedColumns"
				class="columns-treeview"
			>
			</kendo-treeview>
		</div>
	</div>
</ng-template>

<ng-template #presetSelectorPopupTemplate>
	<div id='presetSelectorContainer'>
		<div class="presets-btns-container">
			<button
				kendoButton
				[size]="'small'"
				fillMode="outline"
				[rounded]="'medium'"
				[themeColor]="'info'"
				(click)='openGroupsPopup()'
				title="Manage"
				style='display: block; width: 85%;'
			>
				Report Center
			</button>
			<button
				kendoButton
				[size]="'small'"
				fillMode="outline"
				[rounded]="'medium'"
				[themeColor]="'info'"
				(click)='ganttService.openAddNewReportPopup()'
				style='display: block; width: 85%;'
				class="icon-22"
				[svgIcon]="icons.plus"
			></button>
			<button
				kendoButton
				[size]="'small'"
				fillMode="outline"
				[rounded]="'medium'"
				[themeColor]="'info'"
				(click)='ganttService.toggleQuickSaveDialog(true)'
				style='display: block; width: 85%;'
				class="icon-20"
				[svgIcon]="icons.save"
			></button>
			<button
				kendoButton
				[size]="'small'"
				[rounded]="'medium'"
				fillMode="outline"
				[themeColor]="'primary'"
				style='display: block; width: 15%;'
				(click)="closePresets()"
			>
				X
			</button>
		</div>
		<div class="presets-popup-content">
			<div>
				{{ganttService.favoritePresets?.length ? 'Favorite Reports' : 'No Favorite Reports'}}
			</div>
			<div class="presets-wrapper" *ngIf="ganttService.favoritePresets?.length">
				<div *ngFor="let preset of ganttService.favoritePresets" class="preset-tile" (click)="presetClicked($event, preset)" [style.background-color]="ganttService.selectedGanttLayout === preset.id ? 'rgba(18, 116, 172, 0.25)' : 'transparent'">
					<div class="preset-tile-desc">
						<!--						<span *ngIf="preset?.isFavorite" class="default-preset">-->
						<!--							<kendo-svg-icon [icon]="icons.star" style="color: rgba(223, 83, 83, 0.9); height: 14px; width: 14px;"></kendo-svg-icon>-->
						<!--						</span>-->
						<span class="preset-name">
							{{preset?.name}}
						</span>
					</div>
					<div class="preset-action-btns">
						<button kendoButton fillMode='flat' title='Edit' [svgIcon]='icons.pencilIcon' (click)='ganttService.editPreset($event, preset, false)' style='color: dodgerblue;' class='larger-btn-icons'></button>
						<button kendoButton fillMode='flat' title='Duplicate' [svgIcon]='icons.copy' (click)='ganttService.editPreset($event, preset, true)' class='larger-btn-icons'></button>
						<button kendoButton fillMode='flat' title='Delete' [svgIcon]='icons.trashIcon' (click)='deletePreset($event, preset)' style='color: #f31700;'></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<kendo-dialog
	*ngIf="ganttService.ganttPresetQuickSaveOpen"
	(close)="ganttService.toggleQuickSaveDialog(false)"
	[minWidth]="250"
	[width]="400"
	[height]="282.5"
	id="presetQuickSaveDialog"
	style="z-index: 99999"
>
	<kendo-dialog-titlebar>
		<div class="dialog" style="text-align: left;font-size: 16px; color: black">Quick-Save</div>
	</kendo-dialog-titlebar>
	<div style="text-align: center; padding-bottom: 10px;">
		Save your current gantt report
	</div>
	<div class="quick-save-report-fields">
		<kendo-formfield class="preset-form-name report-name-field">
			<kendo-label [for]="layoutName" text="Name:" style="width: 44px;"></kendo-label>
			<kendo-textbox
				[(ngModel)]="ganttService.quickSaveName"
				#layoutName
				required
				[clearButton]="true"
			>
			</kendo-textbox>
			<kendo-formerror [align]="'end'" style="margin-top: 0px;">Report Name is required</kendo-formerror>
		</kendo-formfield>
		<div style="display: flex; flex-direction: column; justify-content: start; margin-top: 12px; gap: 5px;">
			<div style="display: flex;">
				<span class="dropdown-label" style="white-space: nowrap; margin: auto 0;">
					Folder:
				</span>
				<kendo-multiselect
					kendoMultiSelectSummaryTag
					[data]="ganttService.reportGroups"
					[(ngModel)]="selectedFolders"
					textField="name"
					valueField="id"
					[checkboxes]='true'
					[autoClose]='false'
					[clearButton]='false'
					style="margin-left: 4px; height: 30px;"
					[placeholder]="'All Reports'"
				>
					<ng-template kendoMultiSelectHeaderTemplate>
						<div>
							<input
								kendoCheckBox
								type='checkbox'
								[checked]="isSelectedAll()"
								(click)="onFilterSelectAllClick()"
							/>
							<kendo-label>Select all</kendo-label>
						</div>
					</ng-template>
					<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
						<span>
							<kendo-svg-icon [icon]='icons.caretDown'></kendo-svg-icon>
							{{ dataItems.length + ' selected' }}
						</span>
					</ng-template>
				</kendo-multiselect>
			</div>
			<div style="display: flex; height: 30px;">
				<kendo-label [for]="isFavoriteCheckbox" text="Favorite:" style="padding-right: 4px; margin: auto 0;"></kendo-label>
				<input type="checkbox" kendoCheckBox [(ngModel)]="ganttService.quickSaveIsFavorite" #isFavoriteCheckbox style="margin: auto 0;"/>
				<span style="font-size: 12px; font-style: italic; padding-left: 4px; white-space: nowrap; margin: auto 0;">Favorite reports are sorted first</span>
			</div>
		</div>
		<!--		<div>-->
		<!--			<kendo-label [for]="isFavoriteQuickSaveCheckbox" text="Favorite:" style="padding-right: 4px;"></kendo-label>-->
		<!--			<input type="checkbox" kendoCheckBox [(ngModel)]="quickSaveIsFavorite" #isFavoriteQuickSaveCheckbox style="margin-top: -2px;"/>-->
		<!--			<span style="font-size: 12px; font-style: italic; padding-left: 4px; white-space: nowrap;">Favorite layouts show up at the top of the list</span>-->
		<!--		</div>-->
	</div>
	<kendo-dialog-actions style='margin: 0; background-color: #c8cad6; border-top: none;  border-radius: 0 0 4px 4px;'>
		<button kendoButton (click)="ganttService.toggleQuickSaveDialog(false)">Cancel</button>
		<button kendoButton (click)="confirmQuickSave()" themeColor="success" [disabled]="ganttService.quickSaveName.length === 0">
			Confirm
		</button>
	</kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog
	*ngIf="ganttService.ganttPresetDeleteConfirmOpen"
	(close)="closeConfirmDialog()"
	[minWidth]="250"
	[width]="450"
	id="presetDeleteDialog"
>
	<kendo-dialog-titlebar>
		<div class="dialog" style="text-align: left;font-size: 16px; color: black">Confirm Delete - {{ganttService.deletingPreset?.name}}</div>
	</kendo-dialog-titlebar>
	Are you sure you want to delete report {{ganttService.deletingPreset?.name}}? This action cannot be undone.
	<kendo-dialog-actions style='margin: 0; background-color: #c8cad6; border-top: none;  border-radius: 0 0 4px 4px;'>
		<button kendoButton (click)="closeConfirmDialog()">Cancel</button>
		<button kendoButton (click)="ganttService.deleteGanttPreset(ganttService.deletingPreset)" themeColor="success">
			Confirm
		</button>
	</kendo-dialog-actions>
</kendo-dialog>
