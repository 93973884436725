import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectDashboardComponent } from './project.component';
import { QcPageModule } from './qc/qc-page.module';
import { TopRowModule } from './top-row/top-row.module';
import { ProjectOverviewModule } from './overview/project-overview.module';
import { RiskPageModule } from './risk/risk-page.module';
import { AddBaselineModule } from './top-row/add-baseline/add-baseline.module';
import { ProjectCompletionTrendingModule } from './overview/project-completion-trending/project-completion-trending.module';
import { CalculationsService } from '../../services/project/calculations.service';
import { FileUploadService } from 'services/common/fileUpload.service';
import { ScheduleUpdatesListModule } from './schedule-updates-list/schedule-updates-list.module';
import { SharedIonModule } from '../shared/shared-ion.module';
import { SharedMatModule } from '../shared/shared-mat.module';
import { ProjectRoutingModule } from './project-routing.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { VisualizeReportComponent } from './visualize-report/visualize-report.component';
import { GridLayoutModule, TabStripModule } from '@progress/kendo-angular-layout';
import { VisualizerHeaderComponent } from './visualize-report/visualizer-header/visualizer-header.component';
import { ReportVisualizerModule } from './visualize-report/report-visualizer.module';
import { SaasRiskBannerModule } from '../shared/saas-risk-banner/saas-risk-banner.module';
import { ScheduleLookaheadModule } from './overview/schedule-lookahead/schedule-lookahead.module';
import { NewProjectModule } from '../portfolio/new-project/new-project.module';
import { GlobalTooltipMenuModule } from '../portfolio/global-toolip-menu/global-tooltip-menu.module';
import { DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';
import { TooltipMenuModule } from '../portfolio/tooltip-menu/tooltip-menu.module';
import { AddBulkUpdateModule } from './top-row/add-bulk-update/add-bulk-update.module';
import { RiskCalculationMethodModule } from './risk-calculation-method/risk-calculation-method.module';
import { RiskSettingsModule } from './risk/risk-settings/risk-settings.module';
import { ProjectAdminSettingsComponent } from './project-admin-settings/project-admin-settings.component';
import { FilterMenuModule, GridModule } from '@progress/kendo-angular-grid';
import { FormFieldModule, InputsModule, RadioButtonModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { ReactiveFormsModule } from '@angular/forms';
import { TypographyModule } from '@progress/kendo-angular-typography';
import { LoaderModule } from '@progress/kendo-angular-indicators';
import { ScorePlaygroundComponent } from './project-admin-settings/score-playground/score-playground.component';
import { PipeModule } from '../../util/pipes/pipe.module';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { ClientViewerSettingsComponent } from './client-viewer-settings/client-viewer-settings.component';
import { ComboBoxModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { CostPageModule } from './cost/cost-page.module';
import { MatIconButton } from '@angular/material/button';
import { ProgressPageModule } from './progress/progress-page.module';
import { AnalysisPageModule } from './analysis/analysis-page.module';
import { MilestonesTabComponent } from './project-admin-settings/milestones-tab/milestones-tab.component';
import { GanttPresetWindowContentComponent } from './schedule-updates-list/current-update-gantt/gantt-preset-window-content/gantt-preset-window-content.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { FilterModule } from '@progress/kendo-angular-filter';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { CompareComponent } from './compare/compare.component';
import { DbSyncTabModule } from './db-sync-tab/db-sync-tab.module';
import { OverviewTabSettingsComponent } from './project-admin-settings/overview-tab-settings/overview-tab-settings.component';
import { DirectivesModule } from '../../util/directives/directives.module';
import { DragulaModule } from 'ng2-dragula';

@NgModule({
	declarations: [
		ProjectDashboardComponent,
		ProjectAdminSettingsComponent,
		ScorePlaygroundComponent,
		ClientViewerSettingsComponent,
		MilestonesTabComponent,
		GanttPresetWindowContentComponent,
		OverviewTabSettingsComponent,
	],
	exports: [
		ProjectDashboardComponent,
		ProjectAdminSettingsComponent,
		MilestonesTabComponent,
		GanttPresetWindowContentComponent,
		OverviewTabSettingsComponent,
	],
	providers: [CalculationsService, FileUploadService],
	imports: [
		CommonModule,
		TopRowModule,
		AddBaselineModule,
		ProjectCompletionTrendingModule,
		ProjectOverviewModule,
		SharedIonModule,
		ScheduleUpdatesListModule,
		RiskPageModule,
		CostPageModule,
		QcPageModule,
		SharedMatModule,
		ProjectRoutingModule,
		ImageCropperModule,
		GridLayoutModule,
		ReportVisualizerModule,
		SaasRiskBannerModule,
		ScheduleLookaheadModule,
		NewProjectModule,
		GlobalTooltipMenuModule,
		WindowModule,
		TooltipMenuModule,
		AddBulkUpdateModule,
		RiskCalculationMethodModule,
		RiskSettingsModule,
		TabStripModule,
		FilterMenuModule,
		FormFieldModule,
		LabelModule,
		RadioButtonModule,
		ReactiveFormsModule,
		TypographyModule,
		DialogsModule,
		LoaderModule,
		GridModule,
		PipeModule,
		ProgressBarModule,
		ComboBoxModule,
		MatIconButton,
		ProgressPageModule,
		AnalysisPageModule,
		MultiSelectModule,
		InputsModule,
		TreeViewModule,
		FilterModule,
		TooltipModule,
		CompareComponent,
		DbSyncTabModule,
		DirectivesModule,
		DragulaModule,
	],
})
export class ProjectModule {}
