<div class='card' style='page-break-inside: avoid;' [style.margin-bottom]='user?.userType === "saasRisk" ? "5px" : 0'>
  <kendo-gridlayout
	[rows]="['42.4px', (allGanttTasks[selectedTimespanDays][showNonCriticalActivities ? 'nonCritical' : 'critical'].length ? 'auto' : '150px')]"
	[cols]="['100%']"
	[gap]="{ rows: 0, cols: 0 }"
	class='full-width full-height'
  >
	<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
	  <kendo-gridlayout
		[rows]="['42.4px']"
		[cols]="small ? ['100%'] : ['200px', 'calc(100% - 205px)']"
		[gap]="{ rows: 0, cols: 5 }"
		class='full-width full-height'
	  >
		<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
		  <div class="card-header">
		  {{small ? 'Critical Path ' : 'Schedule '}} Lookahead
		  </div>
		  <tooltip-open type='{{isOverview ? "overview" : "critical-path"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
		</kendo-gridlayout-item>
		@if (!small) {
		  <kendo-gridlayout-item [row]='1' [col]='2' class='toolbar-item-container'>
				<kendo-multiselecttree
					#activityCodesMultiselectTree
					kendoMultiSelectTreeSummaryTag
					kendoMultiSelectTreeExpandable
					[kendoMultiSelectTreeHierarchyBinding]="allActivityCodes"
					[(value)]="selectedActivityCodes"
					textField="name"
					valueField="id"
					childrenField='subCodes'
					[valuePrimitive]='false'
					[filterable]='true'
					(valueChange)='filterChanged($event);'
					[clearButton]='false'
					[expandOnFilter]="{expandMatches: true}"
					[itemDisabled]="itemDisabled"
					[placeholder]='"Activity Code Filter"'
					style='width: 200px; margin: auto 0; cursor: pointer;'
					[disabled]='((project.$currentProjectData | async)?.updateIds.length || 0) <= 1'
				>
					<ng-template kendoMultiSelectTreeGroupTagTemplate let-dataItems>
						<span>
							<kendo-svg-icon [icon]='icons.caretDown'></kendo-svg-icon>
							{{ codesTag }}
						</span>
					</ng-template>
				</kendo-multiselecttree>
			<div class='btngroup-label' style="padding-left: 10px">
			  Lookahead (Days):
			</div>
			<span class='slider-tooltips' kendoTooltip>
			  <kendo-buttongroup width='100%' selection="single" class='btn-group'>
				<button
				  *ngFor="let button of timespanButtons"
				  kendoButton
				  [toggleable]="true"
				  [selected]="button.selected"
				  (selectedChange)="selectionChange($event, button, true)"
				  class='item-btn'
				  [class.selected-item-btn]='button.selected'
				  [title]="showNonCriticalActivities ? (allGanttTasks[button.value]['critical'].length + allGanttTasks[button.value]['nonCritical'].length) + ' activities in Timespan': allGanttTasks[button.value]['critical'].length + ' critical activities in Timespan'"
				>
				  <span>
				  	{{ button.text }}
				  </span>
				</button>
			  </kendo-buttongroup>
			</span>
			<span class='slider-tooltips' kendoTooltip>
			  <div class='btngroup-label'>
			  Activities:
			  </div>
			  <kendo-buttongroup width='100%' selection="single" class='btn-group'>
				<button
				  *ngFor="let button of taskTypeButtons"
				  kendoButton
				  [toggleable]="true"
				  [selected]="button.selected"
				  (selectedChange)="selectionChange($event, button)"
				  class='item-btn'
				  [class.selected-item-btn]='button.selected'
				  [title]="button.value === 1 ? (allGanttTasks[selectedTimespanDays]['critical'].length + allGanttTasks[selectedTimespanDays]['nonCritical'].length) + ' activities in Timespan' : allGanttTasks[selectedTimespanDays]['critical'].length + ' critical activities in Timespan'"
				>
				  <span>
					{{ button.text }}
				  </span>
				</button>
			  </kendo-buttongroup>
			</span>
		  </kendo-gridlayout-item>
		}
	  </kendo-gridlayout>
	</kendo-gridlayout-item>
	<kendo-gridlayout-item [row]='2' [col]='1' *ngIf='allGanttTasks[selectedTimespanDays][showNonCriticalActivities ? "nonCritical" : "critical"].length' class="sched-gantt-container" [class.sched-gantt-container-month-view]="slotWidth > 35">
		@if (loading) {
			<kendo-grid [loading]='true' style="height: 100%; min-height: 150px">
				<ng-template kendoGridNoRecordsTemplate>
				</ng-template>
			</kendo-grid>
		} @else {
			<kendo-gantt
				[style.max-height.px]="469"
				[currentTimeMarker]="false"
				[kendoGanttHierarchyBinding]="data"
				childrenField="subtasks"
				kendoGanttExpandable
				[initiallyExpanded]="false"
				[dependencies]="[]"
				[filterable]="false"
				[sortable]="true"
				[sort]="sort"
				[columnMenu]="false"
				[columnsResizable]="false"
				[columnsReorderable]="false"
				[navigable]='false'
				[timelinePaneOptions]='{ collapsed: false, collapsible: false, size: "calc(100% - 723px)", resizable: false }'
				[treeListPaneOptions]='{ collapsed: false, collapsible: false }'
				[toolbarSettings]='{ position: "none" }'
				[activeView]="activeView"
				[taskClass]='taskCallback'
				[selectable]="true"
				[isSelected]="isSelected"
				(cellClick)="toggleSelection($event)"
				(taskClick)="toggleSelection($event)"
				(sortChange)='updateSort($event)'

				class='schedule-lookahead-gantt'
				id='schedule-lookahead-gantt'
				[class.gantt-year-view-no-months-project]='slotWidth < 35'
				[class.hideMonthLines]='slotWidth <= 20'
			>
				<kendo-gantt-column
					field="title"
					title="Activity"
					[width]="400"
					[expandable]="false"
				>
				</kendo-gantt-column>
				<kendo-gantt-column
					field="start"
					title="Start"
					format="MMM dd, yyyy"
					[width]="125"
					filter="date"
				>
					<ng-template kendoGanttCellTemplate let-dataItem>
						{{(dataItem.start | date: 'MMM dd, yyyy')}}
						@if (dataItem?.startIsAct) {
							<span> A</span>
						}
					</ng-template>
				</kendo-gantt-column>
				<kendo-gantt-column
					field="end"
					title="End"
					format="MMM dd, yyyy"
					[width]="125"
					filter="date"
				>
					<ng-template kendoGanttCellTemplate let-dataItem>
						{{(dataItem.end | date: 'MMM dd, yyyy')}}
						@if (dataItem?.endIsAct) {
							<span> A</span>
						}
					</ng-template>
				</kendo-gantt-column>
				<kendo-gantt-column
					field="tf"
					title="TF"
					format='n0'
					[width]="60"
				>
				</kendo-gantt-column>
				<kendo-gantt-timeline-year-view [slotWidth]="slotWidth"></kendo-gantt-timeline-year-view>
				<ng-template kendoGanttTaskContentTemplate let-dataItem></ng-template>
			</kendo-gantt>
			<div class="data-date-gantt-line"></div>
		}
	  <!--<div class='floating-legend-container' [style.right]='legendRight + "px"'>
		<kendo-gridlayout
			[rows]="['20px']"
			[cols]="['30px', 'auto', '30px', 'auto', '17px', 'auto', '30px', 'auto']"
			[gap]="{ rows: 0, cols: 5 }"
			class='grid'
			*ngIf='showLegend'
		>
		  <kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-container'>
			<div class='rect-main-bar red-bar'>
			  <div class='rect-completed-bar'>
			  </div>
			</div>
		  </kendo-gridlayout-item>
		  <kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-container'>
			<div class='legend-item-label'>
			  Critical
			</div>
		  </kendo-gridlayout-item>
		  <kendo-gridlayout-item [row]='1' [col]='3' class='gridlayout-container'>
			<div class='rect-main-bar green-bar'>
			  <div class='rect-completed-bar'>
			  </div>
			</div>
		  </kendo-gridlayout-item>
		  <kendo-gridlayout-item [row]='1' [col]='4' class='gridlayout-container'>
			<div class='legend-item-label'>
			  Non-Critical
			</div>
		  </kendo-gridlayout-item>
		  <kendo-gridlayout-item [row]='1' [col]='5' class='gridlayout-container' style='margin: 0 auto'>
			<div class='milestone-icon'>
			</div>
		  </kendo-gridlayout-item>
		  <kendo-gridlayout-item [row]='1' [col]='6' class='gridlayout-container'>
			<div class='legend-item-label'>
			  Milestone
			</div>
		  </kendo-gridlayout-item>
		  <kendo-gridlayout-item [row]='1' [col]='7' class='gridlayout-container'>
			<div class='rect-main-bar blue-bar'>
			  <div class='rect-completed-bar'>
			  </div>
			</div>
		  </kendo-gridlayout-item>
		  <kendo-gridlayout-item [row]='1' [col]='8' class='gridlayout-container'>
			<div class='legend-item-label'>
			  Selected
			</div>
		  </kendo-gridlayout-item>
		</kendo-gridlayout>
	  </div>-->
	</kendo-gridlayout-item>
	<kendo-gridlayout-item [row]='2' [col]='1' *ngIf='!allGanttTasks[selectedTimespanDays][showNonCriticalActivities ? "nonCritical" : "critical"].length' class='gridlayout-container' style='margin: auto;'>
	  <div class='warning-div' style='color: #737373'>
		There are no planned{{showNonCriticalActivities ? ' ' : ' critical '}}activities in this timespan.
	  </div>
	</kendo-gridlayout-item>
  </kendo-gridlayout>
</div>
