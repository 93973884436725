import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedMatModule } from 'components/shared/shared-mat.module';
import { PerformanceFactorsComponent } from './performance-factors.component';
import { TooltipMenuModule } from '../../../portfolio/tooltip-menu/tooltip-menu.module';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { AegisChartModule } from '../../../shared/chart/chart.module';
import { GridLayoutModule } from '@progress/kendo-angular-layout';
import { TreeListModule } from '@progress/kendo-angular-treelist';
import { RiskPerformanceFactorModule } from '../risk-performance-factor/risk-performance-factor.module';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';

@NgModule({
	declarations: [PerformanceFactorsComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		IonicModule,
		SharedMatModule,
		TooltipMenuModule,
		ChartsModule,
		AegisChartModule,
		GridLayoutModule,
		TreeListModule,
		RiskPerformanceFactorModule,
		ProgressBarModule,
	],
	exports: [PerformanceFactorsComponent],
})
export class PerformanceFactorsModule {}
