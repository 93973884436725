import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewProjectComponent } from './new-project.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { StepperModule } from '@progress/kendo-angular-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { LayoutModule } from '@progress/kendo-angular-layout';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { FileUploadService } from '../../../services/common/fileUpload.service';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { DirectivesModule } from '../../../util/directives/directives.module';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@NgModule({
	declarations: [NewProjectComponent, ProjectDetailsComponent],
	imports: [
		CommonModule,
		DialogsModule,
		ButtonsModule,
		ReactiveFormsModule,
		StepperModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		LayoutModule,
		InputsModule,
		LabelModule,
		DropDownsModule,
		DateInputsModule,
		ButtonsModule,
		UploadsModule,
		IndicatorsModule,
		DirectivesModule,
		ProgressBarModule,
		SVGIconModule,
	],
	exports: [NewProjectComponent, ProjectDetailsComponent],
	providers: [FileUploadService, { provide: MAT_DIALOG_DATA, useValue: {} }],
})
export class NewProjectModule {}
