import { SelectedActivityCodeInterface } from './update';
import { MitigationEntity, RiskRegister } from './risk';
import { P85HistoricalTrend } from './report';

export type PrePortfolio = {
	crashId?: string;
	name?: string;
	baselineUpdateId?: string;
};

export interface ProjectInterface {
	/**
	 * Existing Fields
	 */
	_id: string;
	prePortfolio?: PrePortfolio;
	updaterIds?: string[];
	updateIds?: string[];
	baselineUpdateId?: string;
	projectSageId?: string;
	projectType?: string;
	name?: string;
	siteAddressReference?: string;
	lat?: any;
	lng?: any;
	creatorId?: string;
	companyId?: string;
	sharePoint?: string;
	memberIds?: string[];
	aegisPocName?: string;
	aegisPocPhone?: string;
	aegisPocEmail?: string;
	updateLock?: boolean;
	riskUpdateLock?: boolean;
	clientName: string;
	region: string;
	projectLead: string;

	/**
	 * New Fields
	 */
	pmIds?: string[];
	currentUpdateId?: string;
	projectCode?: string;
	ntpDate?: Date;
	contractCompletionDate?: Date;
	projectNoticeToProceedDate?: Date;
	createdAt?: Date;
	companyCode?: string;
	archivedUpdateIds?: string[];

	isMonteCarloDone?: boolean;
	mcJobs?: Record<
		string,
		Array<{
			updateId: string;
			jobCount: number;
			jobProgress: number;
			riskId?: number;
			isPre?: boolean;
		}>
	>;

	/**
	 * ProjectModel Report Fields
	 */
	currentProjectReport: string;
	projectReport: string[];

	/**
	 * Risk Page fields
	 */
	selectedActivityCodes?: SelectedActivityCodeInterface[];
	riskPagePurchased?: boolean;
	p85h?: P85HInterface[];

	isArchived?: boolean;
	isClosed?: boolean;
	isConsulting?: boolean;
	warnings?: string[];
	riskMitigation?: RiskRegister[];
	mitigationEntities?: MitigationEntity[];
	riskMetricsType?: string;
	pfCodes?: number[];
	preferences?: any;
	company: number;
	pocId: number;
	clientId?: number;
}

export interface P85HInterface {
	update: number;
	p85h: P85HistoricalTrend;
}
