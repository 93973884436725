<app-maintenance-bar *ngIf="!statusCheckService.maintenanceAlreadyRead || statusCheckService.isThisBleeding"></app-maintenance-bar>
<!--heatmap diagonal gradient-->
<svg xmlns="https://www.w3.org/2000/svg" version="1.1" width="0" height="0" style="visibility: hidden">
	<defs>
		<linearGradient id="svg-gradient" x1="0%" y1="100%" x2="100%" y2="0%">
			<stop offset="0%" style="stop-color:#a6e398; stop-opacity:1"/>
			<stop offset="30%" style="stop-color:#f5f2ae; stop-opacity:1"/>
			<stop offset="70%" style="stop-color:#ffbe96; stop-opacity:1"/>
			<stop offset="100%" style="stop-color:#f77d79; stop-opacity:1"/>
		</linearGradient>
	</defs>
</svg>
<div style='width: 100%'>
	<rhinoworks-navigation-bar id='rwanav' *ngIf='user && url !== "/auth/handoff" && url !== "/auth/login"'></rhinoworks-navigation-bar>
	<router-outlet></router-outlet>

</div>


<kendo-window
	*ngIf="tooltipOpened"
	(close)="closeTooltip(false)"
	[resizable]='false'
	[(top)]="windowService.windowPosition.tooltip.top"
	[(left)]="windowService.windowPosition.tooltip.left"
	[height]="windowService.windowPosition.tooltip.height"
	[width]="windowService.windowPosition.tooltip.width"
	(leftChange)="restrictMovement('tooltip')"
	(topChange)="restrictMovement('tooltip')"
	id="{{ 'tooltip-' + this.tooltipTitle }}"
>
	<kendo-window-titlebar>
		<div class="k-window-title">{{tooltipTitle}}</div>
		<button kendoWindowMinimizeAction></button>
		<button kendoWindowMaximizeAction></button>
		<button kendoWindowRestoreAction></button>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>
	<tooltip-content (tooltipName)="fillTooltipName($event)" [tooltipType]="tooltipType"></tooltip-content>
</kendo-window>

<kendo-window
	*ngIf="editProfileOpened"
	(close)="closeEditProfile(false)"
	[resizable]='false'
	[(top)]="windowService.windowPosition.profile.top"
	[(left)]="windowService.windowPosition.profile.left"
	[height]="windowService.windowPosition.profile.height"
	[width]="windowService.windowPosition.profile.width"
	(leftChange)="restrictMovement('profile')"
	(topChange)="restrictMovement('profile')"
	id='profileWindow'
	style="position: absolute"
>
	<kendo-window-titlebar>
		<div class="k-window-title">Profile</div>
		<button kendoWindowMinimizeAction></button>
		<button kendoWindowMaximizeAction></button>
		<button kendoWindowRestoreAction></button>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>
	<app-profile></app-profile>
</kendo-window>

<kendo-window
	*ngIf="navBarStorage.reportWindowOpen"
	(close)="closeReportWindow()"
	[resizable]='false'
	[minWidth]='700'
	(stateChange)="onStateChange($event)"
	[(top)]="windowService.windowPosition.report.top"
	[(left)]="windowService.windowPosition.report.left"
	[height]="windowService.windowPosition.report.height"
	[width]="windowService.windowPosition.report.width"
	(leftChange)="restrictMovement('report')"
	(topChange)="restrictMovement('report')"
	class='update-report-window'
	id='reportWindow'
>
	<kendo-window-titlebar>
		<div class="k-window-title">{{reportWindowTitle}}</div>
		<button kendoWindowMinimizeAction></button>
		<button kendoWindowMaximizeAction></button>
		<button kendoWindowRestoreAction></button>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>
	<app-update-report
		[$projectReport]='projectDashboardService.$currentProjectReport'
		[updateIndexes]='navBarStorage?.selectedUpdateIndexes'
		(windowTitle)='updateReportWindowTitle($event)'
	></app-update-report>
</kendo-window>

<kendo-window
	*ngIf="analyticsService.addUpdateOpenSchedulesTab.value"
	(close)="closeWindows()"
	[resizable]='false'
	[style.max-height]='navBarStorage.bulkUpdateFormMaxHeight'
	[(top)]="windowService.windowPosition.bulkUpdate.top"
	[(left)]="windowService.windowPosition.bulkUpdate.left"
	[height]="windowService.windowPosition.bulkUpdate.height"
	[width]="windowService.windowPosition.bulkUpdate.width"
	(leftChange)="restrictMovement('bulkUpdate')"
	(topChange)="restrictMovement('bulkUpdate')"
	id='bulkWindow'
	#bulkWindow
>
	<kendo-window-titlebar>
		<div class="k-window-title">Add Updates</div>
		<button kendoWindowMinimizeAction></button>
		<button kendoWindowMaximizeAction></button>
		<button kendoWindowRestoreAction></button>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>
	<app-add-bulk-update [projectData]='projectDashboardService.$currentProjectData.value' [isScheduleModification]='analyticsService.shouldBeSoftAdd.value' (resizedWindow)='setBulkWindowTop()'></app-add-bulk-update>
</kendo-window>

<kendo-window
	*ngIf="navBarStorage.showingNewProject"
	(close)="closeWindows()"
	[resizable]='false'
	[(top)]="windowService.windowPosition.baseline.top"
	[(left)]="windowService.windowPosition.baseline.left"
	[height]="windowService.windowPosition.baseline.height"
	[width]="windowService.windowPosition.baseline.width"

	(leftChange)="restrictMovement('baseline')"
	(topChange)="restrictMovement('baseline')"
	id='newProjectFormWindow'
>
	<kendo-window-titlebar>
		<div class="k-window-title">{{navBarStorage.showingBaselineProject ? 'Editing - ' + projectDashboardService.$currentProjectData.value?.name : 'New Project'}}</div>
		<button kendoWindowMinimizeAction></button>
		<button kendoWindowMaximizeAction></button>
		<button kendoWindowRestoreAction></button>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>
	<app-new-project
		#newProject
		[isBaselineForm]='navBarStorage.showingBaselineProject'
		[projectInfo]='navBarStorage.showingBaselineProject ? projectDashboardService.$currentProjectData.value : undefined'
	></app-new-project>
</kendo-window>

<kendo-window
	*ngIf="scheduleStorage.isLoading && (navBarStorage.selectedTab !== 'schedules' || navBarStorage.selectedTabSchedules !== 'manage') && projectDashboardService.$currentProjectData.value?._id"
	[resizable]='false'
	[height]="52"
	[minHeight]='52'
	[width]='300'
	[top]='66'
	[left]='loadingWindowLeft'
	class='loading-schedules-window'
	id='loading-schedules-window'
>
	<div style='width: 100%; text-align: center; font-weight: bold; font-size: 16px; padding-bottom: 8px; color: black;'>
		Loading Schedules... {{(scheduleService.numUpdatedLoaded | async)}} / {{(projectDashboardService.$currentProjectData | async)?.updateIds?.length}}
	</div>
	<ion-progress-bar
		style='height: 8px; border-radius: 4px;'
		[value]='(scheduleStorage.numUpdatedLoaded | async) / ((projectDashboardService.$currentProjectData | async)?.updateIds?.length || 1)'
	></ion-progress-bar>
</kendo-window>

<kendo-window
	class="container"
	*ngIf="globalTooltipOpened"
	(close)="closeTooltip(false, true)"
	[resizable]='false'
	[(top)]="windowService.windowPosition.globalTooltip.top"
	[(left)]="windowService.windowPosition.globalTooltip.left"
	[height]="windowService.windowPosition.globalTooltip.height"
	[width]="windowService.windowPosition.globalTooltip.width"
	(leftChange)="restrictMovement('globalTooltip')"
	(topChange)="restrictMovement('globalTooltip')"
	id="globalTooltipWindow"
>
	<kendo-window-titlebar>
		<div class="k-window-title">{{tooltipTitle}}</div>
		<button kendoWindowMinimizeAction></button>
		<button kendoWindowMaximizeAction></button>
		<button kendoWindowRestoreAction></button>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>
	<global-tooltip-content (tooltipName)="fillTooltipName($event)" [tooltipType]="tooltipType"></global-tooltip-content>
</kendo-window>

<kendo-window
	*ngIf="portfolioPresetWindowOpened"
	(close)="closeWindows()"
	[resizable]='false'
	[(top)]="windowService.windowPosition.portfolioPreset.top"
	[(left)]="windowService.windowPosition.portfolioPreset.left"
	[height]="windowService.windowPosition.portfolioPreset.height"
	[width]="windowService.windowPosition.portfolioPreset.width"
	(leftChange)="restrictMovement('portfolioPreset')"
	(topChange)="restrictMovement('portfolioPreset')"
	id="portfolioPresetWindow"
>
	<kendo-window-titlebar>
		<div class="k-window-title">{{isNew ? 'New ' : 'Edit '}}Portfolio Layout{{isNew ? '' : ' - ' + editingPreset?.name}}</div>
		<button kendoWindowMinimizeAction></button>
		<button kendoWindowMaximizeAction></button>
		<button kendoWindowRestoreAction></button>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>
	<app-preset-window-content></app-preset-window-content>
</kendo-window>

<kendo-window
	title="Debug"
	*ngIf="debugOpen"
	(close)="closeDebug()"
	[minWidth]="250"
	[width]="450"
>
 <app-debug></app-debug>
</kendo-window>

<div class="k-overlay" *ngIf="windowService.showingAdminSettings"></div>
<!--<div kendoWindowContainer></div>-->
<kendo-window
	*ngIf="windowService.showingAdminSettings"
	(close)="closeAdminSettings()"
	[resizable]='false'
	[(top)]="windowService.windowPosition.adminSettings.top"
	[(left)]="windowService.windowPosition.adminSettings.left"
	[height]="windowService.windowPosition.adminSettings.height"
	[width]="windowService.windowPosition.adminSettings.width"
	[minWidth]='750'
	(leftChange)="restrictMovement('adminSettings')"
	(topChange)="restrictMovement('adminSettings')"
	id='adminSettingsWindow'
	#adminSettingsWindow
>
	<kendo-window-titlebar>
		<div class="k-window-title">{{'Project Admin - ' + this.projectDashboardService.$currentProjectData.value.name}}</div>
		<button kendoWindowMinimizeAction></button>
		<button kendoWindowMaximizeAction></button>
		<button kendoWindowRestoreAction></button>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>
	<app-project-admin-settings id='projectAdminSettingsComponent' #projectAdminSettingsComponent [projectData]='projectDashboardService.$currentProjectData.value' [projectReport]='projectDashboardService.$currentProjectReport.value'></app-project-admin-settings>
</kendo-window>

@if ((analyticsService.$unsavedScheduleChangesOpen | async)) {
	<dialog-unsaved-schedule></dialog-unsaved-schedule>
}

@if ((ganttService.$currentUpdateGanttPresetWindowOpen | async) !== null) {
	<kendo-window
		(close)="ganttService.cancelReport()"
		[resizable]='false'
		[(top)]="windowService.windowPosition.ganttPreset.top"
		[(left)]="windowService.windowPosition.ganttPreset.left"
		[height]="windowService.windowPosition.ganttPreset.height"
		[width]="windowService.windowPosition.ganttPreset.width"
		(leftChange)="restrictMovement('ganttPreset')"
		(topChange)="restrictMovement('ganttPreset')"
		id="ganttPresetWindow"
	>
		<kendo-window-titlebar>
			<div class="k-window-title">{{isNew || isBasedOnOtherPreset ? 'New ' : 'Edit '}}Gantt Report{{isNew || isBasedOnOtherPreset ? '' : ' - ' + editingGanttPreset?.name}}</div>
			<button kendoWindowMinimizeAction></button>
			<button kendoWindowMaximizeAction></button>
			<button kendoWindowRestoreAction></button>
			<button kendoWindowCloseAction></button>
		</kendo-window-titlebar>
		<app-gantt-preset-window-content></app-gantt-preset-window-content>
	</kendo-window>
}

@if ((ganttService.$manageWindowOpen | async)) {
	<kendo-window
		(close)="ganttService.closeReportManage()"
		[resizable]='false'
		[(top)]="windowService.windowPosition.reportManage.top"
		[(left)]="windowService.windowPosition.reportManage.left"
		[height]="windowService.windowPosition.reportManage.height"
		[width]="windowService.windowPosition.reportManage.width"
		(leftChange)="restrictMovement('reportManage')"
		(topChange)="restrictMovement('reportManage')"
		id="reportManageWindow"
	>
		<kendo-window-titlebar>
			<div class="k-window-title">Report Center</div>
			<button kendoWindowMinimizeAction></button>
			<button kendoWindowMaximizeAction></button>
			<button kendoWindowRestoreAction></button>
			<button kendoWindowCloseAction></button>
		</kendo-window-titlebar>
		<app-manage-reports></app-manage-reports>
	</kendo-window>
}

@if ((ganttService.$addGroupOpen | async)) {
	<kendo-dialog
		[width]="318.5"
		(close)="ganttService.closeAddGroupDialog()"
		id="addGroupDialog"
		style="z-index: 99999"
	>
		<kendo-dialog-titlebar>
			<div class="k-window-title">{{ganttService.editingGroup === null ? 'Add Folder' : 'Edit Folder - ' + ganttService.editingGroup.name}}</div>
		</kendo-dialog-titlebar>
		<app-edit-group></app-edit-group>
		<kendo-dialog-actions style='margin: 0; background-color: #c8cad6; border-top: none;  border-radius: 0 0 4px 4px;'>
			<button kendoButton (click)="ganttService.closeAddGroupDialog()">Cancel</button>
			<button kendoButton (click)="ganttService.editWindowGroupSave()" themeColor="success" [disabled]="ganttService.newEditingGroup?.name.length === 0">
				Save
			</button>
		</kendo-dialog-actions>
	</kendo-dialog>
}

@if ((ganttService.$addToFolderDialogOpen | async)) {
	<kendo-dialog
		[width]="318.5"
		(close)="ganttService.closeAddToFolderDialog()"
		id="addToFolderDialog"
		style="z-index: 9999999"
	>
		<kendo-dialog-titlebar>
			<div class="k-window-title">Add Reports To Folders</div>
		</kendo-dialog-titlebar>
		<div style="display: flex;">
			<span class="dropdown-label" style="white-space: nowrap; margin: auto 0;">
				Add to Folder:
			</span>
			<kendo-multiselect
				kendoMultiSelectSummaryTag
				[data]="ganttService.reportGroups"
				[(ngModel)]="ganttService.selectedFoldersToAddReportsTo"
				textField="name"
				valueField="id"
				[checkboxes]='true'
				[autoClose]='false'
				[clearButton]='false'
				style="width: 175px; margin-left: 5px; height: 30px;"
				[placeholder]="'All Reports'"
			>
				<ng-template kendoMultiSelectHeaderTemplate>
					<div>
						<input
							kendoCheckBox
							type='checkbox'
							[checked]="isSelectedAll()"
							(click)="onFilterSelectAllClick()"
						/>
						<kendo-label>Select all</kendo-label>
					</div>
				</ng-template>
				<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
				<span>
					<kendo-svg-icon [icon]='icons.caretDown'></kendo-svg-icon>
					{{ dataItems.length + ' selected' }}
				</span>
				</ng-template>
			</kendo-multiselect>
		</div>
		<kendo-dialog-actions style='margin: 0; background-color: #c8cad6; border-top: none;  border-radius: 0 0 4px 4px;'>
			<button kendoButton (click)="ganttService.closeAddToFolderDialog()">Cancel</button>
			<button kendoButton (click)="ganttService.addToFolders()" themeColor="success" [disabled]="ganttService.selectedFoldersToAddReportsTo?.length === 0">
				Save
			</button>
		</kendo-dialog-actions>
	</kendo-dialog>
}

@if (ganttService.deleteGroupConfirmOpen) {
	<kendo-dialog
		(close)="closeGroupDeleteConfirm()"
		[minWidth]="250"
		[width]="450"
		id="presetDeleteDialog"
		style="z-index: 99999"
	>
		<kendo-dialog-titlebar>
			<div class="dialog" style="text-align: left;font-size: 16px; color: black">Confirm Delete - {{ganttService.deletingGroup?.name}}</div>
		</kendo-dialog-titlebar>
		Are you sure you want to delete group {{ganttService.deletingGroup?.name}}? This action cannot be undone.
		<kendo-dialog-actions style='margin: 0; background-color: #c8cad6; border-top: none;  border-radius: 0 0 4px 4px;'>
			<button kendoButton (click)="closeGroupDeleteConfirm()">Cancel</button>
			<button kendoButton (click)="confirmDeleteGroup()" themeColor="success">
				Confirm
			</button>
		</kendo-dialog-actions>
	</kendo-dialog>
}


@if ((navBarStorage.$adDialogOpen | async)) {
	<kendo-dialog
		(close)="closeAdDialog()"
		id="adDialog"
		style="z-index: 99999"
	>
		<kendo-dialog-titlebar>
			<div class="k-window-title">Purchase License</div>
		</kendo-dialog-titlebar>
		Click <a [href]="'https://rhino.works/analytics/pricing'" [target]="'_blank'">here</a> to see pricing details.
	</kendo-dialog>
}


@if ((analyticsService.$ganttGroupByOpen | async)) {
	<kendo-window
		(close)="closeWindows()"
		[resizable]='false'
		[(top)]="windowService.windowPosition.ganttGroupBy.top"
		[(left)]="windowService.windowPosition.ganttGroupBy.left"
		[height]="windowService.windowPosition.ganttGroupBy.height"
		[width]="windowService.windowPosition.ganttGroupBy.width"
		(leftChange)="restrictMovement('ganttGroupBy')"
		(topChange)="restrictMovement('ganttGroupBy')"
		id="ganttGroupByWindow"
	>
		<kendo-window-titlebar>
			<div class="k-window-title">Group By</div>
			<button kendoWindowMinimizeAction></button>
			<button kendoWindowMaximizeAction></button>
			<button kendoWindowRestoreAction></button>
			<button kendoWindowCloseAction></button>
		</kendo-window-titlebar>
		<app-gantt-group-by></app-gantt-group-by>
	</kendo-window>
}

@if (ganttService.unappliedChanges) {
	<kendo-window
		(close)="closeUnappliedChangesReport()"
		[resizable]='false'
		[(top)]="windowService.windowPosition.unappliedChangesReport.top"
		[(left)]="windowService.windowPosition.unappliedChangesReport.left"
		[height]="windowService.windowPosition.unappliedChangesReport.height"
		[width]="windowService.windowPosition.unappliedChangesReport.width"
		(leftChange)="restrictMovement('unappliedChangesReport')"
		(topChange)="restrictMovement('unappliedChangesReport')"
		id='unappliedChangesReportWindow'
	>
		<kendo-window-titlebar>
			<div class="k-window-title">Unsaved Changes</div>
			<button kendoWindowMinimizeAction></button>
			<button kendoWindowMaximizeAction></button>
			<button kendoWindowRestoreAction></button>
			<button kendoWindowCloseAction></button>
		</kendo-window-titlebar>
		<div style='background-color: white;margin: 0 -18px;padding: 8px 12px 18px 12px;'>
			<p style='padding: 12px 0 0 0; margin: 0;'>There are changes that haven’t been applied or saved. Are you sure you want to close?</p>
		</div>
		<div style='padding: 0; display: flex; flex-direction: row; justify-content: space-between;'>
			<button kendoButton style='background-color: #e04646; color: white; border-radius: 4px;'
							(click)="confirmCloseReport()">Close</button>
			<button kendoButton style='background-color: #2ebb2e; color: white; border-radius: 4px;'
							(click)='closeUnappliedChangesReport()'>Go Back</button>
		</div>
	</kendo-window>
}

<!--reference point for kendo window service-->
<div kendoWindowContainer></div>

<!-- ref point for pdf exports. do not edit. -->
<span id='schedule-done-loading' *ngIf='!scheduleStorage.isLoading' style='position:absolute; left: 0; top: 0;'>&nbsp;</span>
