<kendo-gridlayout
	[rows]="['345.4px', '719.96', '604.96px']"
	[cols]="[visualizer ? '50%' : '100%']"
	[gap]="{ rows: 5, cols: visualizer ? 5 : 0 }"
	class='grid'
>
	<kendo-gridlayout-item [row]='1' [col]='1' [colSpan]='visualizer ? 2 : 1'>
		<div class='card' style='page-break-inside: avoid;'>
			<kendo-gridlayout
				[rows]="['42.4px', '303px']"
				[cols]="['100%']"
				[gap]="{ rows: 0, cols: 0 }"
				class='grid'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container'>
					<div class="card-header">
						Quality Control Score
					</div>
					<tooltip-open type='qc-score-and-trend'></tooltip-open>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='2' [col]='1'>
					<kendo-gridlayout
						[rows]="['303px']"
						[cols]="['calc((400% / 12) - 2.5px)', 'calc((800% / 12) - 2.5px)']"
						[gap]="{ rows: 0, cols: 5 }"
						class='grid'
						style='page-break-inside: avoid;'
					>
						<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-container'>
							<app-gauge
								[value]='$qcScore'
								[colors]='qcColors'
								[height]='"222px"'
								[valueFontSize]='"32px"'
								[labelFontSize]='"20px"'
								style='page-break-inside: avoid;'
							></app-gauge>
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]='1' [col]='2'>
							<div class='chart-container'>
								<app-chart
									[title]='"Current QC Score Trend"'
									[categories]='categories'
									[seriesData]='seriesData'
									[legendVisible]='true'
									[valueAxis]='valueAxisItemSettings'
								></app-chart>
							</div>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</div>
	</kendo-gridlayout-item>
	<kendo-gridlayout-item [row]='visualizer ? 2 : 2' [col]='1'>
		<app-schedule-analysis [visualizer]='visualizer'></app-schedule-analysis>
	</kendo-gridlayout-item>
	<kendo-gridlayout-item [row]='visualizer ? 2 : 3' [col]='visualizer ? 2 : 1'>
		<app-dcma-assessment [visualizer]='visualizer'></app-dcma-assessment>
	</kendo-gridlayout-item>
</kendo-gridlayout>
