import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MilestonesComponent } from './milestones.component';
import { IonicModule } from '@ionic/angular';
import { TooltipMenuModule } from '../../../portfolio/tooltip-menu/tooltip-menu.module';
import { AegisChartModule } from '../../../shared/chart/chart.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { GridLayoutModule } from '@progress/kendo-angular-layout';

@NgModule({
	declarations: [MilestonesComponent],
	imports: [CommonModule, IonicModule, TooltipMenuModule, AegisChartModule, GridModule, GridLayoutModule],
	exports: [MilestonesComponent],
})
export class MilestonesModule {}
