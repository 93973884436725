import * as Highcharts from 'highcharts';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { PfTableFilterModalComponent } from '../../pf-table-filter-modal/pf-table-filter-modal/pf-table-filter-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { addDays, differenceInDays, endOfDay, format, fromUnixTime, isAfter, isSameDay, startOfDay } from 'date-fns';
import { cleanDateUTC } from '../../../../../util/pipes/date.pipe';
import { monteCarloChartOptions, p85HistoricalOptions, performanceFactorScoreOptions } from './pf-chart-options';
import { hitsInterface, ProjectReportInterface } from '../../../../../models/ProjectReport/ProjectReport';
import { ProjectInterface } from '../../../../../models/Project';
import { ProjectDashboardService } from '../../../../../services/project/project.service';
import { GaugeColorSettings } from '../../../../../models/ChartSettings';
import { RestService } from '../../../../../services/common/rest.service';
import { NavigationBarStorageService } from '../../../../../services/common/navigation-bar-storage.service';
import { ScheduleStorageService } from '../../../../../services/project/schedule-storage.service';
import { BuiltPfTable } from '../../../../../util/projects';

export interface PFTableValues {
	activityCodeId: number;
	activityCode: string;
	shortName: string;
	description: string;
	pf: number;
	isSelected: boolean;
	numStarted: number;
	typeId: number;
	parent?: number;
}

@Component({
	selector: 'app-performance-factor',
	templateUrl: './performance-factor.component.html',
	styleUrls: ['./performance-factor.component.scss'],
})
export class PerformanceFactorComponent implements OnInit, OnDestroy {
	@Input() isOverview: boolean = false;
	$projectData = new BehaviorSubject<ProjectInterface>(undefined);
	hasStartedMonte$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	updatedCharts = false;
	Highcharts = Highcharts;
	p85DateInput: Date = new Date();
	p85ProbInput = 85;
	hitSeriesDateInput = [];
	probSeriesDateInput = [];
	probSeriesDateInput2 = [];
	dateArray = [];
	lastUpdated = 'none';
	$p85Date = new BehaviorSubject<Date>(undefined);
	$p50Date = new BehaviorSubject<Date>(undefined);
	$currentCompletion = new BehaviorSubject<Date>(undefined);
	$p85Diff = new BehaviorSubject<number>(0);
	$p85CurrentCompletionDifferenceString = new BehaviorSubject<string>(undefined);
	yValue;
	monteCarloChartOptions = monteCarloChartOptions;
	performanceFactorScoreOptions = performanceFactorScoreOptions;
	p85HistoricalOptions = p85HistoricalOptions;
	pfRiskChart: Highcharts.Chart;
	p85HistoricalChart: Highcharts.Chart;
	p85HitsChart: Highcharts.Chart;
	monteCarloFinished = true;
	totalMCJobs = 0;
	totalMCJobsProgress = 0;
	unableToCalcReason: string = undefined;
	resetFilterClicked = new BehaviorSubject<boolean>(false);
	submitFilterClicked = new BehaviorSubject<boolean>(false);
	httpProcessing: boolean = false;
	cancelEnabled = false;
	pfTable: BuiltPfTable = {
		pfRows: [],
		selectedActivities: new Set([]),
		unselectedActivities: new Set([]),
	};

	rhinoFacts: string[] = [
		'Rhino Horns are Made from the Same Materials as Human Fingernails. One of the most unique facts about rhinos is that their horns are made of keratin, which is also the same key protein that makes up human hair and fingernails. Their horns tend to grow and curve towards the head due to keratin at the front growing at a faster rate than it does in the back. Interestingly, if a rhino loses its horn – due to bulls fighting each other over dominance or territory – it will eventually grow back.',
		'Rhinos Have Special Relationships with Birds. Another one of the many interesting facts about rhinos is that while they tend to be solitary animals, they have a symbiotic relationship with birds. This is most notable with the African rhinos and the African Oxpeckers. The bird would often sit on a rhino’s back and feed off the insects on it. Birds would also provide warning calls should any potential danger or enemies that approaches – as rhinos are quite short-sighted.',
		'There Are Five Different Rhino Species. White rhinos, Black rhinos, Greater one-horned, Sumatran and Javan rhinos. Black and white rhinos are also known together as the African rhinos, as they are found only on the African continent.',
		'Rhinos can weigh over 3 tonnes. Sumatran rhinos are the smallest of all rhinos, but they can still weigh 600kg (that’s almost 95 stone). On the other hand, white rhinos are the largest of the rhino species, weighing up to 3,500 kg. That is more than 550 stone, or well over 3 tonnes, which is mighty impressive considering they mainly eat grass and leaves.',
	];

	$performanceFactorScore = new BehaviorSubject<number>(0);
	pfColors: GaugeColorSettings[] = [
		{
			to: 50,
			color: '#DF5353',
		},
		{
			from: 50,
			to: 85,
			color: '#4fc931',
		},
		{
			from: 85,
			color: '#0059FF',
		},
	];
	label = 'On-Time Completion';

	private _unsubscribeAll = new Subject<void>();
	constructor(
		public dialog: MatDialog,
		public projectService: ProjectDashboardService,
		private restService: RestService,
		private navBarStorage: NavigationBarStorageService,
		public scheduleService: ScheduleStorageService
	) {
		Highcharts.setOptions({
			lang: {
				thousandsSep: ',',
			},
		});
	}

	selectedRhinoFact: string = '';

	ngOnInit(): void {
		this.selectedRhinoFact = this.rhinoFacts[Math.floor(Math.random() * this.rhinoFacts.length)];

		this.projectService.$currentProjectData.subscribe((project) => {
			this.$projectData.next(project);
			if (!project?.isMonteCarloDone || Object.keys(project?.mcJobs || {})?.length) {
				this.hasStartedMonte$.next(true);
			}
			const monteCarloFinished =
				!project?.updateLock &&
				project?.isMonteCarloDone &&
				project?.updateIds?.length > (project.riskMetricsType !== 'riskRegister' ? 1 : 0) &&
				!Object.keys(project?.mcJobs || {})?.length;
			if (monteCarloFinished !== this.monteCarloFinished && monteCarloFinished) {
				this.refreshWindow();
			}
			if (!monteCarloFinished) {
				this.totalMCJobs =
					project.riskMetricsType === 'performanceFactor' ? 1 : (project.riskMitigation?.length || 0) * 2 + 3;
				this.totalMCJobsProgress =
					this.totalMCJobs -
						Object.values(project?.mcJobs || {}).reduce((sum, updateJobs) => sum + updateJobs.length, 0) || 0;
			}
			this.monteCarloFinished = monteCarloFinished;
			this.updatePerformanceFactor(this.projectService.$currentProjectReport.value);
			this.unableToCalcReason = project.invalidXerReason;
		});

		this.projectService.$currentProjectReport
			.pipe(takeUntil(this._unsubscribeAll), debounceTime(100))
			.subscribe((report) => {
				this.updatePerformanceFactor(report);
			});

		setTimeout(() => {
			if (this.pfRiskChart) {
				this.pfRiskChart.reflow();
			}
			if (this.p85HitsChart) {
				this.p85HitsChart.reflow();
			}
			if (this.p85HistoricalChart) {
				this.p85HistoricalChart.reflow();
			}
		}, 2000);
		console.log('checking version...', this.projectService.$currentProjectData.value._id);
		if (this.unableToCalcReason === undefined || this.unableToCalcReason === 'true') {
			this.restService
				.fetch(`latestUpdateXerVersion/${this.projectService.$currentProjectData.value._id}`)
				.subscribe((response) => {
					console.log({ response });
					const version = +(response.version || 0);
					this.unableToCalcReason =
						version >= 19.12 && version <= 21.12
							? 'P6 versions 19.12-21.12 exports are not compatible with Monte Carlo'
							: response.hasHardConstraints
								? 'Schedule has hard constraints'
								: '';
				});
		} else {
			this.unableToCalcReason = this.projectService.$currentProjectData.value.invalidXerReason;
		}
	}
	ngOnDestroy() {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	updatePerformanceFactor(report: Omit<ProjectReportInterface, 'project'> & { project?: ProjectInterface }) {
		if (!report || !report?.project) {
			return;
		}
		if (report.project.riskPagePurchased) {
			this.getPerformanceFactorScore(report);
			this.getp85HistoricalData(report);
		}
	}

	/**
	 * helper sort function that sorts by pf descending
	 * @param a
	 * @param b
	 */
	compare(a, b) {
		return b.pf - a.pf;
	}

	/**
	 * updates many things about the charts so that dynamic input works
	 */
	getPerformanceFactorScore(report: Omit<ProjectReportInterface, 'project'> & { project?: ProjectInterface }) {
		const contractCompletionDate = report?.projectOverview?.contractCompletion?.split(' ')?.[0];
		const riskPage = report?.riskPage;
		const projectName = report?.project?.name;
		const updateNumber = report?.projectOverview?.scheduleName;

		this.hitSeriesDateInput = [];
		this.probSeriesDateInput = [];
		this.probSeriesDateInput2 = [];
		this.dateArray = [];
		const hitsByStartDate = new Map<number, number>([]);
		for (const { unixDate, hits } of riskPage?.performanceFactor?.hits || []) {
			const hitDate = startOfDay(unixDate * 1000);
			const existingVal = hitsByStartDate.get(hitDate.getTime()) || 0;
			hitsByStartDate.set(hitDate.getTime(), existingVal + hits);
		}
		const probsByStartDate = new Map<number, number>([]);
		for (const { unixDate, prob } of riskPage?.performanceFactor?.prob || []) {
			const probDate = startOfDay(unixDate * 1000);
			probsByStartDate.set(probDate.getTime(), prob);
		}
		const hitsObject: hitsInterface[] = Array.from(hitsByStartDate.entries()).map(([unixDate, hits]) => ({
			unixDate,
			hits,
		}));
		const probObject = Array.from(probsByStartDate.entries()).map(([unixDate, prob]) => ({
			unixDate,
			prob,
		}));

		hitsObject?.forEach((hit) => {
			this.hitSeriesDateInput.push(hit?.hits);
			this.dateArray.push(this.convertToDateString(hit?.unixDate));
		});
		probObject?.forEach((prob) => {
			this.probSeriesDateInput2.push(prob?.prob);
			this.probSeriesDateInput.push([prob?.unixDate, prob?.prob]);
		});
		this.updateSeries();

		this.monteCarloChartOptions.title = {
			text: projectName + ' - Monte Carlo Probability Distribution',
			style: {
				fontFamily: 'Muli, Helvetica Neue, Arial, sans-serif',
				fontWeight: 'normal',
				fontSize: '14',
				color: '#737373',
			},
			verticalAlign: 'top',
			// x: 48
			//y: -15
		};

		//get p85 y value from prob series or closest x to 85
		this.yValue = this.probSeriesDateInput?.[0]?.[0];
		let p85DateProb = 0;
		let haveWeFoundP50 = false;
		let indexOfP85 = this.dateArray.length - 1;
		if (this.probSeriesDateInput.length) {
			for (let i = 0; i < this.probSeriesDateInput.length; i++) {
				const point = this.probSeriesDateInput[i];
				if (!haveWeFoundP50 && point[1] >= 50) {
					const p50Date = fromUnixTime(point[0]);
					this.$p50Date.next(p50Date);
					haveWeFoundP50 = true;
				}
				if (point[1] >= 85) {
					this.yValue = point[0];
					p85DateProb = point[1];
					indexOfP85 = Math.min(indexOfP85, i);
					break;
				}
			}
		}
		const p85Date = fromUnixTime(this.yValue);
		this.$p85Date.next(this.yValue ? p85Date : undefined);
		this.p85DateInput = p85Date;
		this.p85ProbInput = p85DateProb;

		this.$currentCompletion.next(new Date(report?.projectTable?.currentCompletion));
		this.$p85Diff.next(
			this.$p85Date.value ? differenceInDays(this.$p85Date.value, new Date(report?.projectTable?.currentCompletion)) : 0
		);
		this.$p85CurrentCompletionDifferenceString.next(
			this.$p85Diff.value ? '(' + (this.$p85Diff.value > 0 ? '+' : '') + this.$p85Diff.value + ' days)' : ''
		);

		const overallScore = report?.riskPage?.performanceFactor?.overallScore;
		const normalizedScore = overallScore >= 100 ? 100 : overallScore <= 0 ? 0 : overallScore;
		this.$performanceFactorScore.next(normalizedScore || 0);
		this.monteCarloChartOptions.xAxis = {
			categories: this.dateArray,
			title: {
				text: 'Projected Date',
				margin: 10,
			},
			labels: {
				rotation: 315,
				align: 'right',
			},
			crosshair: true,
			plotBands: [
				{
					color: 'lightblue',
					from: indexOfP85 - 0.5,
					to: indexOfP85 + 0.5,
				},
			],
		};
		this.updatedCharts = true;
	}

	convertToDateString(unixDate: number) {
		return cleanDateUTC(new Date(unixDate), 'MMM dd, yyyy');
	}

	getp85HistoricalData(report: Omit<ProjectReportInterface, 'project'> & { project?: ProjectInterface }) {
		const riskPage = report?.riskPage;
		const p85HistoricalTrendArray = report?.project?.p85h || riskPage?.performanceFactor?.p85HistoricalTrend || [];
		const projectedDateVarianceArray = [];
		const p85DateVarianceArray = [];
		const previousVarianceArray = [];
		const dataDateArray = [];
		let count = report.project?.riskMetricsType === 'riskRegister' ? 0 : 1;

		const updateNumbersArray = [];
		for (const item of p85HistoricalTrendArray) {
			const p85h = 'p85h' in item ? item.p85h : item;
			if (!p85h?.dataDate) {
				continue;
			}
			const x: string = 'Update ' + count.toString() + '&' + format(new Date(p85h.dataDate), 'MMM dd, yyyy');
			updateNumbersArray.push(x);
			count++;
			if (p85h.previousVariance < 0) {
				previousVarianceArray.push({
					color: '#DF5353',
					y: Math.round(p85h.previousVariance),
				});
			} else {
				previousVarianceArray.push({
					color: '#55BF3B',
					y: Math.round(p85h.previousVariance),
					projectedDate: p85h.projectedDate,
					p85Date: p85h.p85Date,
				});
			}
			projectedDateVarianceArray.push({
				y: p85h.projectedDateVariance,
				projectedDate: format(new Date(p85h.projectedDate), 'MMM dd, yyyy'),
			});
			p85DateVarianceArray.push({
				y: p85h.p85DateVariance,
				p85Date: p85h.p85Date,
			});
			dataDateArray.push(format(new Date(p85h.dataDate), 'dd MMM yyyy'));
		}

		this.p85HistoricalOptions.series = [
			{
				name: 'Period Gain',
				type: 'spline',
				data: [],
				color: '#55BF3B',
				marker: { symbol: 'square', radius: 12, fillColor: '#55BF3B' },
			},
			{
				name: 'Period Loss',
				type: 'spline',
				data: [],
				color: '#DF5353',
				marker: { symbol: 'square', radius: 12, fillColor: '#DF5353' },
			},
			{
				name: 'Period Performance',
				type: 'column',
				data: previousVarianceArray,
				showInLegend: false,
			},
			{
				name: 'Projected Completion Trend',
				type: 'spline',
				data: projectedDateVarianceArray,
				marker: { fillColor: '#FFFFFF' },
			},
		];
	}

	/**
	 * updates the montecarlo chart to have the series configured as i want, and assigns the data to the series's data.
	 * separated in case adding another series temporarily to fake a selection on update of probValue ever worked
	 */
	updateSeries(): void {
		this.monteCarloChartOptions.series = [
			{
				name: 'Probability',
				data: this.probSeriesDateInput2,
				color: 'blue',
				yAxis: 1,
				zIndex: 4,
				type: 'spline',
				tooltip: {
					valueSuffix: '%',
				},
			},
			{
				name: 'Hits',
				data: this.hitSeriesDateInput,
				color: '#ffcc00',
				yAxis: 0,
				type: 'column',
			},
		] as any;
	}

	/**
	 * date was changed, make it the last updated value. will be later used to determine which update function to call
	 */
	dateChanged() {
		this.lastUpdated = 'date';
	}

	/**
	 * prob was changed, make it last updated value. will be used to determine which update function to call later.
	 * also updated the prob input to handle special case
	 * @param event
	 */
	probChanged(event) {
		this.lastUpdated = 'prob';
	}

	/**
	 * grabs prob value from chart and updates prob val input based on date input
	 */
	updateProbValue() {
		const comparisonString = format(new Date(this.p85DateInput), 'MM dd, yyyy');
		let newProbValue = 0;
		let closestMatchIndex = 0;
		let closestMatchDif = 999; //in days
		this.probSeriesDateInput.forEach((x, index) => {
			let dateInMoment = new Date(x[0] * 1000);
			if (typeof x[0] === 'undefined') {
				dateInMoment = new Date(x.x * 1000);
			}
			const comparisonString2 = format(dateInMoment, 'MM dd, yyyy');
			const momentCompare1 = new Date(comparisonString);
			const momentCompare2 = new Date(comparisonString2);
			const calc = Math.abs(differenceInDays(momentCompare1, momentCompare2));
			if (calc < closestMatchDif) {
				closestMatchDif = calc;
				closestMatchIndex = index;
			}
		});
		newProbValue = this.probSeriesDateInput[closestMatchIndex][1];
		if (typeof this.probSeriesDateInput[closestMatchIndex][1] === 'undefined') {
			newProbValue = this.probSeriesDateInput[closestMatchIndex].y;
		}
		this.p85ProbInput = newProbValue;
	}

	/**
	 * grabs date value from chart based on prob input and updates the date input
	 */
	updateDateValue() {
		let didTheyHoverOnChart = false;
		let closestMatchIndex = 0;
		let closestMatchDif = 999;
		this.probSeriesDateInput.forEach((x, index) => {
			let probVal = x[1];
			if (probVal === null || typeof x[1] === 'undefined') {
				//counter to weird thing with highcharts after hover changing data structure makeup
				probVal = x.y;
				didTheyHoverOnChart = true;
			}

			const calc = Math.abs(this.p85ProbInput - probVal);
			if (calc < closestMatchDif) {
				closestMatchDif = calc;
				closestMatchIndex = index;
			}
		});
		let momentDate;
		if (didTheyHoverOnChart) {
			momentDate = this.probSeriesDateInput[closestMatchIndex].x;
		} else {
			momentDate = this.probSeriesDateInput[closestMatchIndex][0];
		}
		this.p85DateInput = new Date(momentDate * 1000);
	}

	computeP85ProbDate() {
		if (this.lastUpdated === 'date') {
			this.updateProbValue();
		} else if (this.lastUpdated === 'prob') {
			this.updateDateValue();
		}
	}

	findProbabilityByDate() {
		this.p85DateInput = endOfDay(this.p85DateInput);
		let latestProbability = 0;

		for (const [unixDate, probability] of this.probSeriesDateInput) {
			const date = endOfDay(new Date(unixDate * 1000));
			if (isAfter(date, this.p85DateInput)) {
				break;
			} else {
				latestProbability = probability;
			}
		}
		this.p85ProbInput = latestProbability;
		const probInput = document.getElementById('p85ProbInput');
		const probInputInner = document.getElementById('finishByInput');
		probInput.classList.add('blink');
		probInputInner.classList.add('yellow-back');
		setTimeout(() => {
			probInput.classList.remove('blink');
			probInputInner.classList.remove('yellow-back');
		}, 2000);
	}

	findDateByProbability() {
		for (const [unixDate, probability] of this.probSeriesDateInput) {
			const date = endOfDay(new Date(unixDate * 1000));
			if (probability >= this.p85ProbInput) {
				this.p85DateInput = date;
				break;
			}
		}
		const dateInput = document.getElementById('p85DateInput');
		dateInput.classList.add('blink');
		setTimeout(() => {
			dateInput.classList.remove('blink');
		}, 2000);
	}

	setP85DateInput(event) {
		this.p85DateInput = addDays(new Date(event.target.value), 1);
	}

	setP85ProbInput(event) {
		this.p85ProbInput = event.target.value ?? 0;
	}

	refreshWindow() {
		setTimeout(() => {
			window.location.reload();
		}, 100);
	}

	launchPFPopup() {
		const dialogRef = this.dialog.open(PfTableFilterModalComponent, {
			width: '85%',
			disableClose: true,
			data: {
				riskPage: this.projectService.$currentProjectReport.value?.riskPage,
				project: this.$projectData.value,
			},
		});
	}

	pfRiskChartInstance(x: Highcharts.Chart): void {
		this.pfRiskChart = x;
	}

	p85HitInstance(x: Highcharts.Chart): void {
		this.p85HitsChart = x;
	}

	navToSchedules() {
		document.body.scrollTo(0, 0);
		this.navBarStorage.selectedTab = 'schedules';
		const url = new URLSearchParams(new URL(window.location.href).search);
		url.set('tab', this.navBarStorage.selectedTab);
		history.pushState(null, '', window.location.pathname + '?' + url.toString());
		this.navBarStorage.$tabPointer.next(this.navBarStorage.selectedTab);
	}

	/**
	 * cancel btn clicked
	 */
	resetFilter(): void {
		this.resetFilterClicked.next(true);
		this.cancelEnabled = false;
	}

	/**
	 * calculate btn clicked
	 */
	submitFilter(): void {
		this.submitFilterClicked.next(true);
		this.httpProcessing = true;
	}

	/**
	 * child component cancel enabled change
	 * @param ev
	 */
	cancelChange(ev: boolean): void {
		this.cancelEnabled = ev;
	}

	/**
	 * child component pf table change
	 * @param ev
	 */
	pfChange(ev): void {
		this.pfTable = ev;
	}
}
