<div class='card regular-container critical-path-card' style="page-break-before: always">
	<kendo-gridlayout
		[rows]="['42.4px', 'calc(100% - 42.2px)']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
		style='page-break-inside: avoid; margin-top: 5px'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
			<kendo-gridlayout
				[rows]="['42.4px']"
				[cols]="['calc(100% - 173px)', '168px']"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header">
						Critical Path Comparison
					</div>
					<tooltip-open type='{{isOverview ? "overview" : "critical-path-comparison"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='row-start-aligned-container' style='justify-content: flex-end;'>
					<span style='display: inline-flex; align-items: center;float: right' kendoTooltip>
						<div style="position: relative;" id="updatesBtn">
							<button kendoButton themeColor='base' title="Choose Updates" class='header-btn new-sched-select-icon'
											(click)='toggleUpdateSelector(updateSelector.element)'
											[disabled]="scheduleStorage.isLoading"
											style='margin-right: 10px;'
											[imageUrl]='"/assets/icons/newIcons/selectSchedule2.svg"'
											#updateSelector
							>
							</button>
							<kendo-svg-icon *ngIf='updateSelectorOpen' [icon]="icons.caretAltDown" class="downArrowIcon" style="color: white; position: absolute; left: 8px; margin-top: 25px;"></kendo-svg-icon>
						</div>
						<button kendoButton themeColor="base" [imageUrl]='allNodesExpanded ? "/assets/icons/newIcons/collapseAll.svg" : "/assets/icons/newIcons/expandAll.svg"' class='header-btn' style='margin-right: 10px;' [title]="allNodesExpanded ? 'Collapse All' : 'Expand All'" (click)='toggleAllNodes(!allNodesExpanded)' [disabled]='!selectedUpdates.length'></button>
					</span>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1' class="crit-path-gantt-container" [class.crit-path-gantt-container-month-view]="slotWidth > 35">
			@if(!scheduleStorage.isLoading) {
				<kendo-gantt
					[kendoGanttHierarchyBinding]="data"
					childrenField="subtasks"
					kendoGanttExpandable
					[expandBy]='"id"'
					[expandedKeys]='expandedKeys'
					[initiallyExpanded]="false"
					[dependencies]="[]"
					[filterable]="false"
					[columnMenu]="false"
					[columnsResizable]="false"
					[columnsReorderable]="false"
					[navigable]='false'
					[timelinePaneOptions]='{ collapsed: false, collapsible: false, size: "calc(100% - 693px)", resizable: false }'
					[treeListPaneOptions]='{ collapsed: treelistPaneCollapsed, collapsible: false }'
					[toolbarSettings]='{ position: "none" }'
					[activeView]="activeView"
					[taskClass]='taskCallback'
					[selectable]="true"
					[isSelected]="isSelected"
					[isExpanded]="isExpanded"
					[currentTimeMarker]="false"
					(cellClick)="toggleSelection($event)"
					(taskClick)="toggleSelection($event)"
					(rowExpand)="arrowToggle($event)"
					(rowCollapse)="arrowToggle($event)"
					*ngIf='!loading && selectedUpdates.length'
					class='critGantt'
					id='criticalPathComparisonGanttChart'
					#criticalPathComparisonGanttChart
					[class.gantt-year-view-no-months-project]='slotWidth < 35'
					[class.hideMonthLines]='slotWidth <= 20'
					[style.max-height.px]="500"
				>
					<kendo-gantt-column
						field="title"
						title="Activity"
						[width]="400"
						[expandable]="true"
					>
					</kendo-gantt-column>
					<kendo-gantt-column
						field="start"
						title="Start"
						format="MMM dd, yyyy"
						[width]="110"
						filter="date"
						[class]='["center-text"]'
					>
						<ng-template kendoGanttCellTemplate let-dataItem>
							{{(dataItem.start | date: 'MMM dd, yyyy')}}
							@if (dataItem?.startIsAct) {
								<span> A</span>
							}
						</ng-template>
					</kendo-gantt-column>
					<kendo-gantt-column
						field="end"
						title="End"
						format="MMM dd, yyyy"
						[width]="110"
						filter="date"
						[class]='["center-text"]'
					>
						<ng-template kendoGanttCellTemplate let-dataItem>
							{{(dataItem.end | date: 'MMM dd, yyyy')}}
							@if (dataItem?.endIsAct) {
								<span> A</span>
							}
						</ng-template>
					</kendo-gantt-column>
					<kendo-gantt-column
						field="tf"
						title="TF"
						format='n0'
						[width]="60"
						[class]='["center-text"]'
					>
					</kendo-gantt-column>
					<kendo-gantt-timeline-year-view [slotWidth]="slotWidth"></kendo-gantt-timeline-year-view>
					<ng-template kendoGanttTaskContentTemplate let-dataItem></ng-template>
				</kendo-gantt>
				@if (selectedUpdates.length) {
					<div class="data-date-gantt-line"></div>
				}
				@if (!selectedUpdates.length) {
					<div class='warning-div' style='height: 150px;'>
						<div>
							Select 2 updates in the <button kendoButton themeColor='base' title="Choose Updates" class='header-btn column-btn'
																							(click)='toggleUpdateSelector(updateSelector.element)'
																							[disabled]="scheduleStorage.isLoading"
																							style='margin: 0 auto;'
																							[imageUrl]='"/assets/icons/newIcons/selectSchedule2.svg"'
						>
						</button> Menu to compare their critical paths.
						</div>
					</div>
				}
			} @else if (!scheduleStorage.isLoading) {
				<div class='warning-div' style='height: 150px;'>
					Critical Path Comparison requires at least 1 update to enable.
				</div>
			} @else {
				<div class='warning-div' style='height: 150px;'>
					Loading...
				</div>
			}
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>

<ng-template #updateSelectorPopupTemplate>
	<div id='updateSelectorContainer'>
		<div class='columns-btns-container'>
			<button
				kendoButton
				[size]="'small'"
				fillMode="outline"
				[rounded]="'medium'"
				[themeColor]="'info'"
				(click)='resetUpdates()'
				style='display: block; width: 85%;'
			>
				Reset
			</button>
			<button
				kendoButton
				[size]="'small'"
				[rounded]="'medium'"
				fillMode="outline"
				[themeColor]="'primary'"
				style='display: block; width: 30px;'
				(click)="closeUpdates()"
			>
				X
			</button>
		</div>
		<div style='max-height: 300px; overflow: auto;'>
			<kendo-treeview
				[nodes]="updateOptions"
				[children]="children"
				[hasChildren]="hasChildren"
				[isDisabled]='isDisabled'
				textField="name"
				[kendoTreeViewCheckable]="{checkParents: true}"
				[(checkedKeys)]="checkedUpdates"
				class="updates-treeview"
			>
			</kendo-treeview>
		</div>
	</div>
</ng-template>
