<kendo-gridlayout
	[rows]="['auto']"
	[cols]="['100%']"
	[gap]="{ rows: 0, cols: 0 }"
	class='grid'
	style='padding-bottom: 5px;'
>
	<kendo-gridlayout-item [row]='1' [col]='1'>
		<app-project-completion-trending
			[visualizer]='visualizer'
		></app-project-completion-trending>
	</kendo-gridlayout-item>
</kendo-gridlayout>
<kendo-gridlayout
	[rows]="['auto']"
	[cols]="['100%']"
	[gap]="{ rows: 0, cols: 0 }"
	class='grid'
	style='padding-bottom: 5px;'
>
	<kendo-gridlayout-item [row]='1' [col]='1'>
		<app-progress-trending
		></app-progress-trending>
	</kendo-gridlayout-item>
</kendo-gridlayout>
<kendo-gridlayout
	[rows]="['auto']"
	[cols]="['100%']"
	[gap]="{ rows: 0, cols: 0 }"
	class='grid'
	style='padding-bottom: 5px;'
>
	<kendo-gridlayout-item [row]='1' [col]='1'>
		<app-schedule-compression
		></app-schedule-compression>
	</kendo-gridlayout-item>
</kendo-gridlayout>
@if ((projectService.$currentProjectReport | async)?.criticalLookahead?.criticalActivityArray?.length > 0) {
	<kendo-gridlayout
		[rows]="['auto']"
		[cols]="['100%']"
		[gap]="{ rows: 5, cols: 0 }"
		class='grid'
		style='padding-bottom: 5px'
	>
		<kendo-gridlayout-item [row]='1' [col]='1'>
			<app-schedule-lookahead></app-schedule-lookahead>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
}
@if ((projectService.$currentProjectReport | async)?.milestones?.milestoneArray?.length !== 0 && !visualizer && (projectService?.$currentProjectData | async)?.updateIds?.length > 1) {
	<kendo-gridlayout
		[rows]="['auto']"
		[cols]="['100%']"
		[gap]="{ rows: 5, cols: 0 }"
		class='grid'
		style='padding-bottom: 5px;'
	>
		<kendo-gridlayout-item [row]='1' [col]='1'>
			<app-milestones></app-milestones>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
} @else if ((projectService?.$currentProjectData | async)?.updateIds?.length === 1) {
	<kendo-gridlayout
		[rows]="['auto']"
		[cols]="['100%']"
		[gap]="{ rows: 5, cols: 0 }"
		class='grid'
		style='padding-bottom: 5px;'
	>
		<kendo-gridlayout-item [row]='1' [col]='1'>
			<div class='card' style='page-break-inside: avoid;'>
				<kendo-gridlayout
					[rows]="['42.4px', 'calc(100% - 42.4px)']"
					[cols]="['100%']"
					[gap]="{ rows: 0, cols: 0 }"
					class='grid'
				>
					<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container'>
						<div class="card-header">
							Milestones
						</div>
						<tooltip-open type='milestones'></tooltip-open>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item [row]='2' [col]='1'>
						<div class="warning-div" style="height: 150px;">
							Milestones requires at least 1 update to enable
						</div>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			</div>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
}
<kendo-gridlayout
	[rows]="['auto', 'auto']"
	[cols]="['100%']"
	[gap]="{ rows: 5, cols: 0 }"
	class='grid'
>
	<kendo-gridlayout-item [row]='1' [col]='1'>
		<app-float-consumption [visualizer]='visualizer'></app-float-consumption>
	</kendo-gridlayout-item>
	<kendo-gridlayout-item [row]='2' [col]='1'>
		<app-performance-factors></app-performance-factors>
	</kendo-gridlayout-item>
	<kendo-gridlayout-item [row]='3' [col]='1'>
		<app-activity-completion [visualizer]='visualizer'></app-activity-completion>
	</kendo-gridlayout-item>
</kendo-gridlayout>
