import { ComparisonData } from '@rhinoworks/analytics-calculations';

export function createExcelSheetsFromComparisonData(
	data: ComparisonData,
	sheetTitles: Record<keyof ComparisonData, string>
) {
	const sheets = Object.keys(data).map((key) => {
		const rows = data[key as keyof ComparisonData].map((item: any) => {
			return {
				cells: Object.keys(item).map((field) => ({
					value: item[field],
				})),
			};
		});

		return {
			name: sheetTitles[key] || key,
			rows: [
				{
					cells: Object.keys(data[key as keyof ComparisonData][0] || {}).map((field) => ({
						value: field,
					})),
				},
				...rows,
			],
		};
	});

	return sheets;
}
