import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedIonModule } from '../../shared/shared-ion.module';
import { TooltipMenuModule } from '../../portfolio/tooltip-menu/tooltip-menu.module';
import { PipeModule } from '../../../util/pipes/pipe.module';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { GridLayoutModule } from '@progress/kendo-angular-layout';
import { AegisChartModule } from '../../shared/chart/chart.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { LabelModule } from '@progress/kendo-angular-label';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { AnalysisPageComponent } from './analysis-page.component';
import { ScheduleUpdatesListModule } from '../schedule-updates-list/schedule-updates-list.module';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { ScheduleCompressionModule } from './schedule-compression/schedule-compression.module';

@NgModule({
	declarations: [AnalysisPageComponent],
	imports: [
		CommonModule,
		SharedIonModule,
		TooltipMenuModule,
		PipeModule,
		ButtonsModule,
		TooltipsModule,
		GridLayoutModule,
		AegisChartModule,
		GridModule,
		LabelModule,
		TextBoxModule,
		SVGIconModule,
		ScheduleUpdatesListModule,
		TreeViewModule,
		ScheduleCompressionModule,
	],
	exports: [AnalysisPageComponent],
})
export class AnalysisPageModule {}
