import { Component, OnInit, ViewChild } from '@angular/core';
import { PortfolioPreset, PresetInputs } from '../../../models/auth/account-user';
import {
	FilterItem,
	ProjectListColumn,
	ProjectListColumnGroup,
	scheduleTypeList,
} from '../project-list/project-list/project-list.component';
import { FilterOperator, groupBy, GroupResult } from '@progress/kendo-data-query';
import { caretAltDownIcon, heartIcon, heartOutlineIcon, xCircleIcon } from '@progress/kendo-svg-icons';
import { NavigationBarStorageService } from '../../../services/common/navigation-bar-storage.service';
import { getFiltersFromValToRef, ProjectFieldFilter, ProjectsFilter } from '../../../util/kendo';
import { IButton } from '../../../models/Project';
import { Observable, of } from 'rxjs';
import { UserService } from '../../../services/common/user.service';
import { hasObjChanged } from '../../../util/projects';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';

export const allGroups = require('../project-list/project-list/project-list-columns.json') as {
	groups: ProjectListColumnGroup[];
};
@Component({
	selector: 'app-preset-window-content',
	templateUrl: './preset-window-content.component.html',
	styleUrls: ['./preset-window-content.component.scss'],
})
export class PresetWindowContentComponent implements OnInit {
	banner: 'default' | 'cost' | 'risk' = 'default';
	filterValuesByField: Record<string, FilterItem[]> = {};
	clientList: FilterItem[] = [];
	filteredClientList: Array<FilterItem>;
	nestedClientList: GroupResult[] = [];
	companyList: FilterItem[] = [];
	nestedCompanyList: FilterItem[] = [];
	expandedCompanyNodes: string[] = ['0'];
	riskList: FilterItem[] = [];
	projectTypeList: FilterItem[] = [];
	filteredProjectTypeList: FilterItem[] = [];
	pocList: FilterItem[] = [];
	filteredPocList: FilterItem[] = [];
	nestedPocList: GroupResult[] = [];
	costLoaded: 'yes' | 'no' | 'all' = 'all';
	completionStatus: FilterItem[] = [];
	pastPeriodPerformanceList: FilterItem[] = [];
	readonly DEFAULT_SLIDER_VALUES = {
		projectScore: [0, 100],
		progressScore: [0, 100],
		qcScore: [0, 100],
		predictabilityScore: [0, 100],
		completionVar: [0, 5],
	};
	sliderValues = {
		...this.DEFAULT_SLIDER_VALUES,
	};
	sliderSettings = {
		min: 0,
		max: 100,
		smallStep: 5,
		largeStep: 4,
		tickPlacement: 'after',
		showButtons: true,
		incrementTitle: 'Increase',
		decrementTitle: 'Decrease',
		dragTitle: 'Drag',
	};
	completionVarianceNumberToTitleDict = {
		0: 'Behind',
		1: '-360',
		2: '-120',
		3: '-60',
		4: '-30',
		5: 'On Time',
	};
	completionVarLabel: string = '';
	completionVarianceValueToLabelDict = {
		0: '-',
		1: '360',
		2: '120',
		3: '60',
		4: '30',
		5: '-',
	};
	completionVarianceValueToFilterValueDict = {
		0: -Number.MAX_SAFE_INTEGER,
		1: -360,
		2: -120,
		3: -60,
		4: -30,
		5: 0,
	};
	updateStatus: 'current' | 'missing' | 'all' = 'all';
	viewOptions = [
		{
			display: 'Auto-Fit',
			zoomVal: null,
		},
		{
			display: 'Year',
			zoomVal: 25,
		},
		{
			display: 'Quarter',
			zoomVal: 40,
		},
		{
			display: 'Month',
			zoomVal: 55,
		},
	];
	selectedGanttView: number = null;
	name: string = '';
	projectsListView: 'grid' | 'gantt' = 'grid';
	public viewButtons: IButton[] = [
		{
			text: 'Table',
			value: 0,
			selected: true,
			disabled: false,
		},
		{
			text: 'Gantt',
			value: 1,
			disabled: false,
		},
	];
	isFavorite: boolean = false;
	defaultPreset: 'Yes' | 'No' = 'No';
	allGroups = allGroups;
	_visibleColumns = [];
	visibleColumns: ProjectListColumn[] = [];
	public columnToTreeViewCoord = new Map<string, string>([]);
	_id: string = null;
	rangeSliders = ['projectScore', 'progressScore', 'qcScore', 'predictabilityScore', 'completionVar'];
	loading: boolean = false;
	saving: boolean = false;
	public icons = {
		caretDown: caretAltDownIcon,
		closeCircle: xCircleIcon,
		heartOutline: heartOutlineIcon,
		heart: heartIcon,
	};
	ifilters: ProjectsFilter = new ProjectsFilter();
	isResetDisabled: boolean = false;
	isEdit: boolean = false;
	map: boolean = true;
	multiselectOptions: string[] = [
		'clientIdMultiselect',
		'scheduleTypeMultiselect',
		'riskMetricsTypeMultiselect',
		'projectTypeMultiselect',
		'pocIdMultiselect',
		'deltaVsCcdMultiselect',
		'prevVarianceMultiselect',
	];
	@ViewChild('clientIdMultiselect') clientIdMultiselect: MultiSelectComponent;
	@ViewChild('scheduleTypeMultiselect') scheduleTypeMultiselect: MultiSelectComponent;
	@ViewChild('rmtMultiselect') riskMetricsTypeMultiselect: MultiSelectComponent;
	@ViewChild('projectTypeMultiselect') projectTypeMultiselect: MultiSelectComponent;
	@ViewChild('pocIdMultiselect') pocIdMultiselect: MultiSelectComponent;
	@ViewChild('deltaVsCcdMultiselect') deltaVsCcdMultiselect: MultiSelectComponent;
	@ViewChild('prevVarMultiselect') prevVarianceMultiselect: MultiSelectComponent;

	constructor(
		public navBarStorage: NavigationBarStorageService,
		private userService: UserService
	) {}

	ngOnInit() {
		this.navBarStorage.$portfolioPresetWindowOpen.subscribe((preset: PresetInputs) => {
			this._id = null;
			if (preset !== null && preset) {
				this.loading = true;
				const existingPresets: PortfolioPreset[] = this.userService._userLayouts;
				this.isEdit =
					existingPresets !== undefined && existingPresets?.findIndex((p) => p.id === preset.preset.id) !== -1;
				this.clientList = preset.clientList;
				this.filteredClientList = preset.filteredClientList;
				this.nestedClientList = preset.nestedClientList;
				this.companyList = preset.companyList;
				this.nestedCompanyList = preset.nestedCompanyList;
				this.riskList = preset.riskList;
				this.projectTypeList = preset.projectTypeList;
				this.filteredProjectTypeList = preset.filteredProjectTypeList;
				this.pocList = preset.pocList;
				this.filteredPocList = preset.filteredPocList;
				this.nestedPocList = preset.nestedPocList;
				this.ifilters.filters = preset.currentFilters;
				this.completionStatus = preset.completionStatus;
				this.pastPeriodPerformanceList = preset.pastPeriodPerformanceList;
				this.setFilterInputs(this.ifilters.filters);
				this.selectedGanttView = preset.preset.ganttView;
				this.projectsListView = preset.preset.tableView;
				this.isFavorite = preset.preset.isFavorite;
				this.defaultPreset = this.isFavorite ? 'Yes' : 'No';
				this.banner =
					preset.preset.banner === 'default' || preset.preset.banner === 'cost' || preset.preset.banner === 'risk'
						? preset.preset.banner
						: 'default';
				this.name = preset.preset.name;
				this._id = preset.preset.id;
				this.map = preset.preset.showMap;
				this.setColumns(preset);
				this.loading = false;
			}
		});
	}

	toggleFavorite(newVal: boolean): void {
		this.isFavorite = newVal;
	}

	setColumns(preset: PresetInputs): void {
		const columns = [];
		this.visibleColumns = [];
		this._visibleColumns = [];
		for (let i = 0; i < allGroups.groups.length; i++) {
			const group = allGroups.groups[i];
			for (let j = 0; j < group.columns.length; j++) {
				const column = group.columns[j];
				column.group = group.title;
				const treeViewKey = `${i}_${j}`;
				this.columnToTreeViewCoord.set(column.field, treeViewKey);
				if (preset.preset.visibleColumns.includes(column.field)) {
					this._visibleColumns.push(treeViewKey);
					this.visibleColumns.push(column);
					columns.push(column);
				}
			}
		}
	}

	cancel(): void {
		this.navBarStorage.$portfolioPresetWindowOpen.next(null);
	}
	save(): void {
		const existingPresets: PortfolioPreset[] = structuredClone(this.userService._userLayouts);
		const matchingPreset: PortfolioPreset =
			existingPresets === undefined ? undefined : existingPresets.find((preset) => preset.id === this._id);
		const filters: ProjectFieldFilter[] = this.genFilters();
		if (matchingPreset === undefined) {
			const newPreset: PortfolioPreset = {
				banner: this.banner,
				filters: JSON.stringify(filters),
				ganttView: this.selectedGanttView,
				quickView: null,
				tableView: this.projectsListView,
				visibleColumns: this.visibleColumns.map((c) => c.field) || [],
				name: this.name,
				isFavorite: this.isFavorite,
				id: this._id,
				showMap: this.map,
			};
			this.userService.addNewPortfolioPreset(newPreset);
			this.cancel();
		} else {
			let newPresets: PortfolioPreset[] = [];
			existingPresets.forEach((preset: PortfolioPreset) => {
				if (preset.id === this._id) {
					newPresets.push({
						banner: this.banner,
						filters: JSON.stringify(filters),
						ganttView: this.selectedGanttView,
						quickView: null,
						tableView: this.projectsListView,
						visibleColumns: this.visibleColumns.map((c) => c.field) || [],
						name: this.name,
						isFavorite: this.isFavorite,
						id: this._id,
						showMap: this.map,
					});
				} else {
					newPresets.push(structuredClone(preset));
				}
			});
			newPresets = newPresets.sort((a: PortfolioPreset, b: PortfolioPreset) => {
				// if (a?.isFavorite && !b?.isFavorite) {
				// 	return -1;
				// }
				// if (b?.isFavorite && !a?.isFavorite) {
				// 	return 1;
				// }
				if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) {
					return -1;
				}
				if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
					return 1;
				}
				return 0;
			});
			this.userService.editPortfolioPreset(newPresets);
			this.cancel();
		}
	}
	reset(): void {
		const currentBanner = localStorage.getItem('currentBanner');
		this.ifilters.filters = this.defaultFilters(currentBanner === 'risk', currentBanner === 'cost');
		this.sliderValues = {
			projectScore: [0, 100],
			progressScore: [0, 100],
			qcScore: [0, 100],
			predictabilityScore: [0, 100],
			completionVar: [0, 5],
		};
		this.updateStatus = 'all';
		this.costLoaded = currentBanner === 'cost' ? 'yes' : 'all';
		this.isFavorite = false;
		this.selectedGanttView = null;
		this.projectsListView = 'grid';
		this.banner = 'default';
		this.map = true;
		this.setColumns(this.navBarStorage.$portfolioPresetWindowOpen.value);
		this.setFilterInputs(this.ifilters.filters);
		this.loading = false;
		// this.updateResetBtnState();
	}

	// updateResetBtnState(): void {
	// 	const defaultFilters: ProjectFieldFilter[] = this.defaultFilters(false, false);
	// 	if (hasObjChanged(this.ifilters.filters, defaultFilters)) {
	// 		console.log('filterschanged', this.ifilters.filters, defaultFilters);
	// 	}
	// 	this.isResetDisabled = false;
	// }

	genFilters(): ProjectFieldFilter[] {
		const filters: ProjectsFilter = new ProjectsFilter();
		filters.filters = this.defaultFilters();
		if (Object.keys(this.filterValuesByField).length > 0) {
			for (const [text, value] of Object.entries(this.filterValuesByField)) {
				filters.setFilterDescriptor(text, value, this.rangeSliders.includes(text) ? 'and' : 'or');
			}
		}
		return filters.filters;
	}

	public defaultFilters(isRisk?: boolean, isCost = false): ProjectFieldFilter[] {
		const filters: Array<ProjectFieldFilter> = [];
		filters.push({
			logic: 'or',
			text: 'companyName',
			filters: this.clientList.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		filters.push({
			logic: 'or',
			text: 'scheduleType',
			filters: this.scheduleTypeList
				.filter((schedType) => schedType.text !== 'Archived')
				.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		filters.push({
			logic: 'or',
			text: 'company',
			filters: this.companyList.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		if (isRisk) {
			filters.push({
				logic: 'or',
				text: 'riskMetricsType',
				filters: this.riskList
					.filter((risk) => risk.value.value === 'performanceFactor' || risk.value.value === 'riskRegister')
					.reduce((accumulator, f) => accumulator.concat(f.value), []),
			});
		} else {
			filters.push({
				logic: 'or',
				text: 'riskMetricsType',
				filters: this.riskList.reduce((accumulator, f) => accumulator.concat(f.value), []),
			});
		}
		filters.push({
			logic: 'or',
			text: 'pocId',
			filters: this.pocList.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		filters.push({
			logic: 'or',
			text: 'clientId',
			filters: this.clientList.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		filters.push({
			logic: 'or',
			text: 'projectType',
			filters: this.projectTypeList.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		filters.push({
			logic: 'or',
			text: 'deltaVsCcd',
			filters: this.completionStatus.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		filters.push({
			logic: 'or',
			text: 'prevVariance',
			filters: this.pastPeriodPerformanceList.reduce((accumulator, f) => accumulator.concat(f.value), []),
		});
		filters.push({
			logic: 'or',
			text: 'update-status',
			filters: [{ text: 'update-status', field: 'dUpload', operator: FilterOperator.IsNotNull, value: 0 }],
		});
		if (isCost) {
			filters.push({
				logic: 'or',
				text: 'cost-loaded',
				filters: [{ text: 'cost-loaded', field: 'costLoaded', operator: FilterOperator.EqualTo, value: true }],
			});
		} else {
			filters.push({
				logic: 'or',
				text: 'cost-loaded',
				filters: [{ text: 'cost-loaded', field: 'costLoaded', operator: FilterOperator.IsNotNull }],
			});
		}
		filters.push({
			logic: 'and',
			text: 'progressScore',
			filters: [
				{ text: 'progressScore', field: 'progressScore', operator: FilterOperator.GreaterThanOrEqual, value: 0 },
				{ text: 'progressScore', field: 'progressScore', operator: FilterOperator.LessThanOrEqual, value: 100 },
			],
		});
		filters.push({
			logic: 'and',
			text: 'projectScore',
			filters: [
				{ text: 'projectScore', field: 'projectScore', operator: FilterOperator.GreaterThanOrEqual, value: 0 },
				{ text: 'projectScore', field: 'projectScore', operator: FilterOperator.LessThanOrEqual, value: 100 },
			],
		});
		filters.push({
			logic: 'and',
			text: 'qcScore',
			filters: [
				{ text: 'qcScore', field: 'qcScore', operator: FilterOperator.GreaterThanOrEqual, value: 0 },
				{ text: 'qcScore', field: 'qcScore', operator: FilterOperator.LessThanOrEqual, value: 100 },
			],
		});
		filters.push({
			logic: 'and',
			text: 'predictabilityScore',
			filters: [
				{
					text: 'predictabilityScore',
					field: 'predictabilityScore',
					operator: FilterOperator.GreaterThanOrEqual,
					value: 0,
				},
				{
					text: 'predictabilityScore',
					field: 'predictabilityScore',
					operator: FilterOperator.LessThanOrEqual,
					value: 100,
				},
			],
		});
		filters.push({
			text: 'completionVar',
			filters: [
				{
					text: 'completionVar',
					field: 'cumulativeVariance',
					operator: FilterOperator.GreaterThanOrEqual,
					value: -Number.MAX_SAFE_INTEGER,
				},
				{
					text: 'completionVar',
					field: 'cumulativeVariance',
					operator: FilterOperator.LessThanOrEqual,
					value: Number.MAX_SAFE_INTEGER,
				},
			],
			logic: 'and',
		});
		return filters;
	}

	setFilterInputs(filters: ProjectFieldFilter[] = this.ifilters.filters) {
		if (!filters.length) {
			return;
		}
		this.loading = true;
		this.filterValuesByField.projectType = getFiltersFromValToRef(this.projectTypeList, filters, 'projectType');
		this.filterValuesByField.companyName = getFiltersFromValToRef(this.filteredClientList, filters, 'companyName');
		this.filterValuesByField.scheduleType = getFiltersFromValToRef(this.scheduleTypeList, filters, 'scheduleType');
		this.filterValuesByField.company = getFiltersFromValToRef(this.companyList, filters, 'company');
		this.filterValuesByField.pocId = getFiltersFromValToRef(this.pocList, filters, 'pocId');
		this.filterValuesByField.clientId = getFiltersFromValToRef(this.clientList, filters, 'clientId');
		this.filterValuesByField.riskMetricsType = getFiltersFromValToRef(this.riskList, filters, 'riskMetricsType');
		this.filterValuesByField.deltaVsCcd = getFiltersFromValToRef(this.completionStatus, filters, 'deltaVsCcd');
		this.filterValuesByField.prevVariance = getFiltersFromValToRef(
			this.pastPeriodPerformanceList,
			filters,
			'prevVariance'
		);
		// this.searchTerm = filters.find((filter) => filter.text === 'search')?.filters?.[0]?.value || '';
		const updateStatusFilters = (filters.find((filter) => filter.text === 'update-status')?.filters || []).filter(
			(filter) => filter.operator !== 'isnotnull'
		);
		const costLoadedFilters = (filters.find((filter) => filter.text === 'cost-loaded')?.filters || []).filter(
			(filter) => filter.operator !== 'isnotnull'
		);

		if (updateStatusFilters?.length === 1 && updateStatusFilters[0].operator === 'gte') {
			this.updateStatus = 'missing';
			this.filterValuesByField['update-status'] = [
				{
					text: 'update-status',
					value: {
						text: 'update-status',
						field: 'dUpload',
						operator: FilterOperator.GreaterThanOrEqual,
						value: 45,
					},
				},
			];
			this.filterValuesByField.scheduleType = [
				{
					text: 'Active',
					value: {
						text: 'Active',
						field: 'scheduleType',
						operator: FilterOperator.EqualTo,
						value: 'Active',
					},
				},
			];
		} else if (updateStatusFilters?.length === 1 && updateStatusFilters[0].operator === 'lt') {
			this.updateStatus = 'current';
			this.filterValuesByField['update-status'] = [
				{
					text: 'update-status',
					value: { text: 'update-status', field: 'dUpload', operator: FilterOperator.LessThan, value: 45 },
				},
			];
			this.filterValuesByField.scheduleType = [
				{
					text: 'Active',
					value: {
						text: 'Active',
						field: 'scheduleType',
						operator: FilterOperator.EqualTo,
						value: 'Active',
					},
				},
			];
		} else {
			this.updateStatus = 'all';
			this.filterValuesByField['update-status'] = [
				{
					text: 'update-status',
					value: { text: 'update-status', field: 'dUpload', operator: FilterOperator.IsNotNull, value: 0 },
				},
			];
		}
		if (costLoadedFilters?.length === 1 && costLoadedFilters[0].value === true) {
			this.costLoaded = 'yes';
		} else if (costLoadedFilters?.length === 1 && costLoadedFilters[0].value === false) {
			this.costLoaded = 'no';
		} else {
			this.costLoaded = 'all';
		}
		this.filterValuesByField['cost-loaded'] = [
			{
				text: 'cost-loaded',
				value: {
					text: 'cost-loaded',
					field: 'costLoaded',
					operator: this.costLoaded === 'all' ? FilterOperator.IsNotNull : FilterOperator.EqualTo,
					value: this.costLoaded === 'yes' ? true : this.costLoaded === 'no' ? false : null,
				},
			},
		];
		Object.keys(this.sliderValues).forEach((key) => {
			const savedFilterValue = filters.find((filter) => filter.text === key)?.filters?.[0]?.value;
			const savedFilterValueOther = filters.find((filter) => filter.text === key)?.filters?.[1]?.value;
			if (savedFilterValue !== undefined) {
				let newVal: number[] = [savedFilterValue, savedFilterValueOther];
				newVal = newVal.sort((a: number, b: number) => {
					if (a < b) {
						return -1;
					}
					if (b < a) {
						return 1;
					}
					return 0;
				});
				if (key === 'completionVar') {
					let normalizedLeft: number = newVal[0] < -360 ? 0 : newVal[0];
					let normalizedRight: number = newVal[1] > 0 ? 5 : newVal[1];
					for (const [key, value] of Object.entries(this.completionVarianceValueToFilterValueDict)) {
						if (normalizedLeft !== 0 && value === normalizedLeft) {
							normalizedLeft = Number(key);
						}
						if (normalizedRight !== 5 && value === normalizedRight) {
							normalizedRight = Number(key);
						}
					}
					this.sliderValues.completionVar = [normalizedLeft, normalizedRight];
					this.updateCompletionVarianceLabel([normalizedLeft, normalizedRight]);
					this.filterValuesByField.completionVar = [
						{
							text: 'completionVar',
							value: {
								text: 'completionVar',
								field: 'cumulativeVariance',
								operator: FilterOperator.LessThanOrEqual,
								value: newVal[1],
							},
						},
						{
							text: 'completionVar',
							value: {
								text: 'completionVar',
								field: 'cumulativeVariance',
								operator: FilterOperator.GreaterThanOrEqual,
								value: newVal[0],
							},
						},
					];
				}
				if (key !== 'completionVar') {
					this.sliderValues[key] = newVal;
					this.filterValuesByField[key] = [
						{
							text: key,
							value: {
								text: key,
								field: key,
								operator: FilterOperator.LessThanOrEqual,
								value: newVal[1],
							},
						},
						{
							text: key,
							value: {
								text: key,
								field: key,
								operator: FilterOperator.GreaterThanOrEqual,
								value: newVal[0],
							},
						},
					];
				}
			}
		});
		this.loading = true;
	}

	/**
	 * handler for radio button value change event. used to update filter values
	 * @param radioGroup
	 */
	checkboxChanged(radioGroup: string): void {
		switch (radioGroup) {
			case 'updateStatus': {
				let updateStatusFilter: FilterItem[] = [];
				let scheduleTypeFilter: FilterItem[] = [];
				switch (this.updateStatus) {
					case 'missing': {
						updateStatusFilter = [
							{
								text: 'update-status',
								value: {
									text: 'update-status',
									field: 'dUpload',
									operator: FilterOperator.GreaterThanOrEqual,
									value: 45,
								},
							},
						];
						scheduleTypeFilter = [
							{
								text: 'Active',
								value: {
									text: 'Active',
									field: 'scheduleType',
									operator: FilterOperator.EqualTo,
									value: 'Active',
								},
							},
						];
						break;
					}
					case 'current': {
						updateStatusFilter = [
							{
								text: 'update-status',
								value: { text: 'update-status', field: 'dUpload', operator: FilterOperator.LessThan, value: 45 },
							},
						];
						scheduleTypeFilter = [
							{
								text: 'Active',
								value: {
									text: 'Active',
									field: 'scheduleType',
									operator: FilterOperator.EqualTo,
									value: 'Active',
								},
							},
						];
						break;
					}
					case 'all': {
						updateStatusFilter = [
							{
								text: 'update-status',
								value: { text: 'update-status', field: 'dUpload', operator: FilterOperator.IsNotNull, value: 0 },
							},
						];
						scheduleTypeFilter = [
							{
								text: 'Active',
								value: {
									text: 'Active',
									field: 'scheduleType',
									operator: FilterOperator.EqualTo,
									value: 'Active',
								},
							},
							{
								text: 'Baseline',
								value: {
									text: 'Baseline',
									field: 'scheduleType',
									operator: FilterOperator.EqualTo,
									value: 'Baseline',
								},
							},
							{
								text: 'Closed',
								value: {
									text: 'Closed',
									field: 'scheduleType',
									operator: FilterOperator.EqualTo,
									value: 'Closed',
								},
							},
							{
								text: 'Consulting',
								value: {
									text: 'Consulting',
									field: 'scheduleType',
									operator: FilterOperator.EqualTo,
									value: 'Consulting',
								},
							},
						];
						break;
					}
				}
				this.ifilters.setFilterDescriptor('update-status', updateStatusFilter, 'or');
				this.ifilters.setFilterDescriptor('scheduleType', scheduleTypeFilter, 'or');
				this.filterValuesByField['update-status'] = updateStatusFilter;
				this.filterValuesByField.scheduleType = scheduleTypeFilter;
				break;
			}
			case 'cost': {
				const filter: FilterItem[] = [
					{
						text: 'cost-loaded',
						value: {
							text: 'cost-loaded',
							field: 'costLoaded',
							operator: this.costLoaded === 'all' ? FilterOperator.IsNotNull : FilterOperator.EqualTo,
							value: this.costLoaded === 'yes' ? true : this.costLoaded === 'no' ? false : null,
						},
					},
				];
				this.ifilters.setFilterDescriptor('cost-loaded', filter, 'or');
				this.filterValuesByField['cost-loaded'] = filter;
				break;
			}
		}
	}

	filterChanged(a?: string, b?: FilterItem[]): void {
		console.log('filter changed', a, b);
	}

	handleClientFilter(value: string) {
		this.filteredClientList = this.clientList.filter((s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1);
		this.nestedClientList = groupBy(this.filteredClientList, [{ field: 'companyName' }]) as GroupResult[];
	}

	public onFilterSelectAllClick(filter: string, baseFilters: FilterItem[]) {
		const newValue = this.isSelectedAll(filter, baseFilters) ? [] : baseFilters.slice();
		this.filterValuesByField[filter] = newValue.reduce((prev, f) => prev.concat(f.value), []);
	}
	public isSelectedAll(filter: string, baseFilters: FilterItem[]) {
		return !baseFilters.some((baseItem) => !(this.filterValuesByField[filter] || []).includes(baseItem));
	}
	public isIndet(filter: string, baseFilters: FilterItem[]): boolean {
		const selectedOfBase = (this.filterValuesByField[filter] || []).filter((selectedItem) =>
			baseFilters.includes(selectedItem)
		);
		return (selectedOfBase?.length || 0) !== 0 && selectedOfBase.length !== baseFilters.length;
	}

	public filterDropdownClose(filter: string, baseFilters: FilterItem[]) {
		if (this.filterValuesByField[filter].length === 0) {
			this.onFilterSelectAllClick(filter, baseFilters);
		}
	}

	handleProjectTypeFilter(value: string) {
		this.filteredProjectTypeList = this.projectTypeList.filter(
			(s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
		);
	}

	handlePocFilter(value: string) {
		this.filteredPocList = this.pocList.filter((s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1);
		this.nestedPocList = groupBy(this.filteredPocList, [{ field: 'companyName' }]) as GroupResult[];
	}

	/**
	 * assigns custom tick title to completion variance slider
	 * @param value
	 */
	public completionVarianceTickTitle = (value: number): string => `${this.completionVarianceNumberToTitleDict[value]}`;

	/**
	 * tracks completion variance slider value changes
	 * @param val
	 */
	updateCompletionVarianceSliderValue(val: number[]): void {
		this.sliderValues.completionVar = val;
	}

	completionVarianceSliderValueChanged(val: number[]): void {
		this.updateCompletionVarianceLabel(val);
		let leftVal = this.completionVarianceValueToFilterValueDict[val[0]];
		let rightVal = this.completionVarianceValueToFilterValueDict[val[1]];
		leftVal = leftVal === 0 ? -Number.MAX_SAFE_INTEGER : leftVal;
		rightVal = val[1] === 5 ? Number.MAX_SAFE_INTEGER : rightVal;
		const filter = [
			{
				text: 'completionVar',
				value: {
					text: 'completionVar',
					field: 'cumulativeVariance',
					operator: FilterOperator.LessThanOrEqual,
					value: rightVal,
				},
			},
			{
				text: 'completionVar',
				value: {
					text: 'completionVar',
					field: 'cumulativeVariance',
					operator: FilterOperator.GreaterThanOrEqual,
					value: leftVal,
				},
			},
		];
		this.ifilters.setFilterDescriptor('completionVar', filter, 'and');
		this.filterValuesByField.completionVar = filter;
	}

	/**
	 * create label for the completion variance slider based on the new values
	 * @param val
	 */
	updateCompletionVarianceLabel(val: number[]): void {
		if (val[0] !== 0 || val[1] !== 5) {
			if (val[1] === 5) {
				this.completionVarLabel = 'up to ' + this.completionVarianceValueToLabelDict[val[0]] + ' days behind';
			} else if (val[0] === 0) {
				this.completionVarLabel = this.completionVarianceValueToLabelDict[val[1]] + '+ days behind';
			} else {
				this.completionVarLabel =
					'min: -' +
					this.completionVarianceValueToLabelDict[val[0]] +
					' days, max: -' +
					this.completionVarianceValueToLabelDict[val[1]] +
					' days';
			}
		}
	}

	/**
	 * resets completion variance slider to default
	 */
	resetCompletionVariance(): void {
		this.updateCompletionVarianceSliderValue([0, 5]);
	}

	/**
	 * updates slider value tracker for display in the label above
	 * @param val
	 * @param sliderId
	 */
	updateSliderValue(val: number[], sliderId: string): void {
		this.sliderValues[sliderId] = val;
	}

	/**
	 * score slider value change handler
	 * @param val
	 * @param sliderId
	 */
	sliderValueChanged(val: number[], sliderId: string): void {
		this.ifilters.setFilterDescriptor(
			sliderId,
			[
				{
					text: sliderId,
					value: {
						text: sliderId,
						field: sliderId,
						operator: FilterOperator.LessThanOrEqual,
						value: val[1],
					},
				},
				{
					text: sliderId,
					value: {
						text: sliderId,
						field: sliderId,
						operator: FilterOperator.GreaterThanOrEqual,
						value: val[0],
					},
				},
			],
			'and'
		);
		this.filterValuesByField[sliderId] = [
			{
				text: sliderId,
				value: {
					text: sliderId,
					field: sliderId,
					operator: FilterOperator.LessThanOrEqual,
					value: val[1],
				},
			},
			{
				text: sliderId,
				value: {
					text: sliderId,
					field: sliderId,
					operator: FilterOperator.GreaterThanOrEqual,
					value: val[0],
				},
			},
		];
	}

	/**
	 * reset individual slider value
	 * @param sliderId
	 */
	resetSliderValue(sliderId: string): void {
		this.updateSliderValue([0, 100], sliderId);
		this.sliderValueChanged([0, 100], sliderId);
	}

	/**
	 * update gantt 'view'
	 * @param ev
	 */
	updateView(ev: number): void {
		console.log('update ganttView', ev);
	}

	/**
	 * buttongroup selection change handler
	 * @param selected
	 * @param btn
	 */
	public selectionChange(selected: boolean, btn: IButton): void {
		btn.selected = selected;
		if (btn.selected === true) {
			this.projectsListView = btn.text === 'Table' ? 'grid' : 'gantt';
		}
	}

	public children = (dataItem: any): Observable<ProjectListColumnGroup[]> => of(dataItem.columns);
	public hasChildren = (dataItem: any): boolean => !!dataItem.columns;
	public isDisabled = (dataItem: any) => dataItem.alwaysVisible;

	set visibleProjectListColumns(columns: string[]) {
		this._visibleColumns = columns;
		const visibleColumnFieldsProjectList = new Set<string>([]);
		const newVisibleColumnFields: ProjectListColumn[] = [];
		for (const col of columns) {
			const coords = col.split('_');
			if (coords.length === 2) {
				const column = allGroups.groups[coords[0]]?.columns?.[coords[1]];
				if (column) {
					visibleColumnFieldsProjectList.add(column.field);
					newVisibleColumnFields.push(column);
				}
			}
		}
		this.visibleColumns = structuredClone(newVisibleColumnFields).sort((a: ProjectListColumn, b: ProjectListColumn) =>
			a.sequence < b.sequence ? -1 : b.sequence < a.sequence ? 1 : 0
		);
	}

	get visibleProjectListColumns() {
		return this._visibleColumns;
	}

	/**
	 * close any other open multiselect once this one is clicked and opens this one. called on the summary tag click
	 * @param multiselect
	 */
	toggleMultiselect(multiselect: string): void {
		this.multiselectOptions.forEach((dropdown: string) => {
			this[dropdown].toggle(dropdown === multiselect ? !this[multiselect].isOpen : false);
		});
	}

	/**
	 * close any other open multiselect once this one is focused. called on the input click
	 * @param multiselect
	 */
	focus(multiselect: string): void {
		this.multiselectOptions.forEach((dropdown: string) => {
			if (dropdown !== multiselect) {
				this[dropdown].toggle(false);
			}
		});
	}

	protected readonly scheduleTypeList = scheduleTypeList;
}
