<div class='card' style='page-break-inside: avoid;'>
	<kendo-gridlayout
		[rows]="['42.4px', 'auto']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
			<div class="card-header">
				Project Completion Trending
			</div>
			<tooltip-open type='{{isOverview ? "overview" : "trending-chart"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1' style='page-break-inside: avoid'>
			<div class='chart-container'>
				<app-chart
					[subtitle]='completionMilestone'
					[categories]='categories'
					[seriesData]='seriesData'
					[legendVisible]='true'
					[valueAxis]='valueAxisItemSettings'
				></app-chart>
			</div>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>
