<div id="overviewTab">
	<kendo-gridlayout
		[rows]="['auto']"
		[cols]="['100%']"
		[gap]="{ rows: 5, cols: 0 }"
		class='grid'
		style='padding-bottom: 5px;'
	>
		<kendo-gridlayout-item [row]='1' [col]='1'>
			<app-project-overview-summary></app-project-overview-summary>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
	<kendo-gridlayout
		[rows]="['auto']"
		[cols]="['340px', 'auto']"
		[gap]="{ rows: 0, cols: 5 }"
		class='grid'
		style='padding-bottom: 5px;'
	>
		<kendo-gridlayout-item [row]='1' [col]='1'>
			<app-progress-trending [hideTrending]="true" [isOverview]="true"></app-progress-trending>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='1' [col]='2'>
			<app-project-completion-trending [visualizer]='visualizer' [isOverview]="true"></app-project-completion-trending>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
	@if ((projectService.$currentProjectReport | async)?.criticalLookahead?.criticalActivityArray?.length > 0) {
		<kendo-gridlayout
			[rows]="['auto']"
			[cols]="['100%']"
			[gap]="{ rows: 5, cols: 0 }"
			class='grid'
			style='padding-bottom: 5px;'
		>
			<kendo-gridlayout-item [row]='1' [col]='1'>
				<app-schedule-lookahead [isOverview]="true"></app-schedule-lookahead>
			</kendo-gridlayout-item>
		</kendo-gridlayout>
	}
	<kendo-gridlayout
		[rows]="['auto']"
		[cols]="['100%']"
		[gap]="{ rows: 5, cols: 0 }"
		class='grid'
		style='padding-bottom: 5px;'
	>
		<kendo-gridlayout-item [row]='1' [col]='1'>
			<app-activity-completion [visualizer]='visualizer' [hideTrending]="true" [isOverview]="true"></app-activity-completion>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
	<kendo-gridlayout
		[rows]="['auto']"
		[cols]="['100%']"
		[gap]="{ rows: 5, cols: 0 }"
		class='grid'
	>
		<kendo-gridlayout-item [row]='1' [col]='1'>
			<app-schedule-analysis [visualizer]='visualizer' [isOverview]="true"></app-schedule-analysis>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>
