import UpdateSeriesManager from './UpdateSeriesManager';
import {
	ActivityInfo,
	average,
	getLastPeriodItemVars,
	hasActualPastDate,
	intersectsDataDate,
	isCriticalPlanned,
	isNearCriticalPlanned,
	isNonCriticalPlanned,
	isPlannedToBeFinishedBy,
	isProblematicRelationship,
	LastPeriodItem,
	TotalFloatIndexArgs,
} from '../models';
import {
	AegisScoreVariables,
	bei,
	cpli,
	criticalPathDuration,
	CriticalPathReliabilityVariables,
	criticalPathRiskScore,
	getAegisScoreVars,
	getCriticalPathReliability,
	getQcScore,
	getQualityControlVars,
	periodicFloat,
	progress,
	projectPredictability,
	QualityControlVars,
	slimmedCriticalPathForward,
	slimmedCriticalPathReliabilityScore,
	TotalFloatIndex,
} from '../models/scores';
import { activityCompletionIndex } from '../models/graphs/ActivityCompletion';
import { SlimmedTaskCommon, UpdateInterface } from '../../models/Update/Task';
import { ProjectReportInterface } from '../../models/ProjectReport/ProjectReport';
import { arraysAreIdentical, compareObj, similarScore } from '../../util/misc';
import { Calendar, XerActivity, XerTaskPredecessor } from '@rhinoworks/xer-parse';
import { isCritical, isDriving } from '../../util/tasks';
import { ScheduleStorageService } from '../../services/project/schedule-storage.service';

export type ActivityFilterType = 'task' | 'milestone' | 'loe' | 'finishMilestone';
export type FilterCases =
	| 'fsWithLag'
	| 'missingPredSucc'
	| 'oos'
	| 'negLag'
	| 'hardConstraints'
	| 'highDuration'
	| 'highFloat'
	| 'actualsAfter'
	| 'noProgressWithActual'
	| 'noDeltaInRemaining'
	| 'softConstraints'
	| 'critical';

export const softConstraints = new Set<string>([
	'CS_ALAP',
	'CS_MEO',
	'CS_MEOA',
	'CS_MEOB',
	'CS_MSO',
	'CS_MSOA',
	'CS_MSOB',
]);

export const hardConstraints = new Set<string>(['CS_MANDSTART', 'CS_MANDFIN', 'CS_MSO', 'CS_MEO', 'CS_ALAP']);

const filterCases: FilterCases[] = [
	'fsWithLag',
	'missingPredSucc',
	'oos',
	'negLag',
	'hardConstraints',
	'highDuration',
	'highFloat',
	'actualsAfter',
	'noProgressWithActual',
	'noDeltaInRemaining',
	'critical',
];

const activityFilterTypes: ActivityFilterType[] = ['task', 'milestone', 'loe', 'finishMilestone'];

export default class UpdateManager {
	public seriesManager: UpdateSeriesManager;
	public report: ProjectReportInterface;
	public includedActivities: Record<FilterCases, ActivityFilterType[]> = {
		fsWithLag: ['task', 'milestone', 'loe', 'finishMilestone'],
		hardConstraints: ['task', 'milestone', 'loe', 'finishMilestone'],
		highDuration: ['task', 'milestone', 'loe', 'finishMilestone'],
		highFloat: ['task', 'milestone', 'loe', 'finishMilestone'],
		missingPredSucc: ['task', 'milestone', 'loe', 'finishMilestone'],
		negLag: ['task', 'milestone', 'loe', 'finishMilestone'],
		oos: ['task', 'milestone', 'loe', 'finishMilestone'],
		actualsAfter: ['task', 'milestone', 'loe', 'finishMilestone'],
		noProgressWithActual: ['task', 'milestone', 'loe', 'finishMilestone'],
		noDeltaInRemaining: ['task', 'milestone', 'loe', 'finishMilestone'],
		critical: ['task', 'milestone', 'loe', 'finishMilestone'],
		softConstraints: ['task', 'milestone', 'loe', 'finishMilestone'],
	};
	public dataDate: Date;
	public finishMilestoneCode: string;
	public _taskSummary: typeof this.tasksSummary;
	public _drivingTies: Map<string, string>;
	private _criticalActivities = new Set<XerActivity>([]);
	public taskPredecessors = new Map<string, XerTaskPredecessor[]>([]);
	public taskSuccessors = new Map<string, XerTaskPredecessor[]>([]);
	public tasksByCode = new Map<string, XerActivity>([]);
	public tasksById = new Map<number, XerActivity>([]);
	constructor(
		public args: {
			update: UpdateInterface;
			seriesManager?: UpdateSeriesManager;
			report?: ProjectReportInterface;
			tasks?: XerActivity[];
			relationships?: XerTaskPredecessor[];
			calendars?: Map<number, Calendar>;
		}
	) {
		this.seriesManager = args.seriesManager;
		this.dataDate = new Date(
			args.report.projectCompletionTrend.projectCompletionTrendArray[
				args.report.updateIds.indexOf(args.update._id)
			].dataDate
		);
		this.finishMilestoneCode = args.update.finishMilestone?.task_code;
		this.report = args.report;
		for (const task of args.tasks) {
			this.tasksByCode.set(task.task_code, task);
			this.tasksById.set(task.task_id, task);
			if (isCritical(task)) {
				this._criticalActivities.add(task);
			}
		}
		for (const pred of args.relationships) {
			const predTask = this.tasksById.get(pred.pred_task_id);
			const successorTask = this.tasksById.get(pred.task_id);
			const existingPred = this.taskPredecessors.get(successorTask?.task_code) || [];
			existingPred.push(pred);
			this.taskPredecessors.set(successorTask?.task_code, existingPred);
			const existingSucc = this.taskSuccessors.get(predTask?.task_code) || [];
			existingSucc.push(pred);
			this.taskSuccessors.set(predTask?.task_code, existingSucc);
		}
	}

	public get updateIndex(): number {
		const index = this.seriesManager.sortedManagers.indexOf(this);
		if (index < 0) {
			return this.seriesManager.updateManagers.size;
		}
		return index;
	}

	public get pathFloats(): number[] {
		const dataDate = this.dataDate;
		const pathFloats: Array<number> = [];
		const activitiesByTotalFloatHrs: Array<[number, XerActivity]> = [];
		for (const activity of this.tasksById.values()) {
			if (dataDate && intersectsDataDate(activity, dataDate)) {
				activitiesByTotalFloatHrs.push([activity.total_float_hr_cnt || 0, activity]);
			}
		}
		activitiesByTotalFloatHrs.sort((a: [number, XerActivity], b: [number, XerActivity]) => {
			if (a[0] === b[0] && a[1]?.act_start_date && b[1]?.act_start_date) {
				return a[1].act_start_date < b[1].act_start_date ? -1 : 1;
			}
			return a[0] - b[0];
		});
		let criticaledActivities = new Set<Partial<ActivityInfo>>([]);
		for (const [float, actv] of activitiesByTotalFloatHrs) {
			if (dataDate && !criticaledActivities.has(actv)) {
				const [, critPathForward] = slimmedCriticalPathForward(
					this.tasksById,
					actv,
					this.taskSuccessors,
					this.args.calendars
				);
				criticaledActivities = new Set<Partial<ActivityInfo>>([...criticaledActivities, ...critPathForward]);
				pathFloats.push(float / 8);
			}
		}
		return pathFloats;
	}

	public get tasksSummary(): {
		vars: QualityControlVars & AegisScoreVariables & CriticalPathReliabilityVariables;
		totalVars: Record<FilterCases, number>;
		typeCounts: Record<FilterCases, Record<ActivityFilterType, number>>;
		pathFloats: number[];
	} {
		const dataDate = this.dataDate;
		const vars: QualityControlVars & AegisScoreVariables & CriticalPathReliabilityVariables = {
			...getQualityControlVars(),
			...getAegisScoreVars(),
			...getCriticalPathReliability(),
		};
		let criticalPathFloatActv = Array.from(this.tasksById.values())
			.filter((a) => !!a.early_end_date && isCritical(a))
			.sort((a, b) => {
				const aee = a.early_end_date ? new Date(a.early_end_date).getTime() : 0;
				const bee = b.early_end_date ? new Date(b.early_end_date).getTime() : 0;
				return aee === bee ? (b.total_float_hr_cnt || 0) - (a.total_float_hr_cnt || 0) : bee - aee;
			})[0];
		if (this.finishMilestoneCode) {
			const finishMilestone = this.tasksByCode.get(this.finishMilestoneCode);
			if (
				finishMilestone?.early_end_date &&
				criticalPathFloatActv?.early_end_date &&
				criticalPathFloatActv?.early_end_date > finishMilestone.early_end_date
			) {
				console.log('setting actv from', criticalPathFloatActv);
				criticalPathFloatActv = finishMilestone;
			}
		}
		console.log({ criticalPathFloatActv }, this.tasksById.size);
		let criticalPathFloat = 0;
		const floatActv = criticalPathFloatActv;
		if (
			criticalPathFloatActv &&
			floatActv &&
			criticalPathFloatActv.late_end_date &&
			criticalPathFloatActv.early_end_date
		) {
			const cal = this.args.calendars?.get(criticalPathFloatActv.clndr_id);
			criticalPathFloat =
				criticalPathFloatActv.late_end_date && criticalPathFloatActv.early_end_date
					? cal.durationDays(criticalPathFloatActv.early_end_date, criticalPathFloatActv.late_end_date)
					: 0;
		}
		console.log({ criticalPathFloat });
		const totalVars: Record<FilterCases, number> = {
			actualsAfter: 0,
			critical: 0,
			fsWithLag: 0,
			hardConstraints: 0,
			highDuration: 0,
			highFloat: 0,
			missingPredSucc: 0,
			negLag: 0,
			noDeltaInRemaining: 0,
			noProgressWithActual: 0,
			oos: 0,
			softConstraints: 0,
		};
		const typeCounts: Record<FilterCases, Record<ActivityFilterType, number>> = Object.fromEntries(
			filterCases.map((filterCase) => [filterCase, Object.fromEntries(activityFilterTypes.map((type) => [type, 0]))])
		) as Record<FilterCases, Record<ActivityFilterType, number>>;
		for (const [code, prevActv] of this.previous?.tasksByCode || []) {
			const actvCurrent = this.tasksByCode.get(code);
			if (prevActv.driving_path_flag === 'Y' && !prevActv.act_end_date && this.countActivity(prevActv, 'critical')) {
				if (isCritical(actvCurrent)) {
					vars.maintainedPrevCritActivities.push(actvCurrent);
				}
				if (actvCurrent?.act_end_date) {
					vars.completedPrevCritActivities.push(actvCurrent);
				}
			}

			const prevActvPreds = this.taskPredecessors.get(prevActv.task_code) || [];
			for (const pred of prevActvPreds) {
				const prevPred = this.previous?.tasksById.get(pred.pred_task_id);
				if (isDriving(pred, prevPred, prevActv, this.args.calendars?.get(prevActv.clndr_id))) {
					const predsCurrent = this.taskPredecessors.get(actvCurrent.task_code);
					const currPred = predsCurrent.find((currPred) => {
						const predPredCode = this.tasksById.get(currPred.pred_task_id).task_code;
						return currPred.pred_type === pred.pred_type && predPredCode === prevPred.task_code;
					});
					if (!currPred) {
						vars.deletedDrivingTies.push(`${prevPred.task_code}-${pred.pred_type}-${code}`);
					} else if (currPred.lag_hr_cnt !== pred.lag_hr_cnt) {
						vars.currentLagChanges.push(`${prevPred.task_code}-${pred.pred_type}-${code}`);
					}
				}
			}
		}
		const activitiesByTotalFloatHrs: Array<[number, XerActivity]> = [];
		vars.numRelationships = this.args.relationships.length;
		vars.numRemainingTasks = this.args.tasks.filter((task) => !task.act_end_date).length;
		for (const activity of this.tasksById.values()) {
			if (activity.proj_id !== +this.args.update.selectedProjectId) {
				continue;
			}
			const actvInPrevUpdate = this.previous?.tasksByCode.get(activity.task_code);
			const actvInBaseline = this.seriesManager.baseline?.tasksByCode.get(activity.task_code);
			if (isCritical(activity)) {
				const preds = this.taskPredecessors.get(activity.task_code) || [];
				for (const relation of preds) {
					const prevCode = this.tasksById.get(relation.pred_task_id)?.task_code;
					const predKey = `${prevCode}-${relation.pred_type}-${activity.task_code}`;
					if (
						isDriving(
							relation,
							this.tasksById.get(relation.pred_task_id),
							activity,
							this.args.calendars.get(activity.clndr_id)
						) &&
						isCritical(this.tasksByCode.get(prevCode))
					) {
						vars.currentDrivingTies.set(predKey, predKey);
						if (!this.previous?.drivingTies.has(predKey)) {
							vars.addedDrivingTies.add(predKey);
						}
					}
				}
			}
			if (activity.task_type === 'TT_Task') {
				vars.numTotalTasks++;
			}
			if (activity.act_end_date) {
				vars.numTasksCompleted++;
				const actDuration = this.args.calendars
					?.get(activity.clndr_id)
					.durationMinutes(activity.act_start_date, activity.act_end_date);
				if ((actDuration || 0) / 60 <= (activity.target_drtn_hr_cnt || 0)) {
					vars.numTasksCompletedOnTime++;
				}
				const efp = actvInPrevUpdate?.early_end_date || actvInPrevUpdate?.target_end_date;
				if (
					dataDate &&
					this.previous?.dataDate &&
					efp &&
					!actvInPrevUpdate.act_end_date &&
					efp < dataDate &&
					activity.act_end_date <= dataDate
				) {
					vars.numTasksCompletedInPeriod++;
				}
			}
			const ef = activity.early_end_date || activity.target_end_date;
			if (dataDate && this.previous?.dataDate && ef && this.previous.dataDate <= ef && ef <= dataDate) {
				vars.numProjectedNumTasksCompletedInPeriod++;
			}
			if (
				actvInBaseline &&
				(actvInBaseline.early_end_date || actvInBaseline.target_end_date) &&
				(actvInBaseline.early_end_date || actvInBaseline.target_end_date) < dataDate
			) {
				vars.projectedNumBaseTasksCompleted++;
			}
			if (dataDate && intersectsDataDate(activity, dataDate) && activity.task_type !== 'TT_LOE') {
				activitiesByTotalFloatHrs.push([activity.total_float_hr_cnt || 0, activity]);
			}
			if (dataDate && hasActualPastDate(activity, dataDate)) {
				totalVars.actualsAfter++;
				typeCounts.actualsAfter[this.taskType(activity)]++;
				if (this.countActivity(activity, 'actualsAfter')) {
					vars.numActualsAfterDataDate++;
				}
			}
			const isHardConstraint = hardConstraints.has(activity.cstr_type);

			if (isHardConstraint) {
				totalVars.hardConstraints++;
				typeCounts.hardConstraints[this.taskType(activity)]++;
				if (this.countActivity(activity, 'hardConstraints')) {
					vars.numConstrained++;
				}
			}
			if (isCritical(activity)) {
				totalVars.critical++;
				typeCounts.critical[this.taskType(activity)]++;
				if (this.countActivity(activity, 'critical')) {
					vars.numCritical++;
					vars.critActivities.set(activity.task_code, activity);
				}
			}

			const preds =
				this.taskPredecessors.get(activity.task_code)?.filter((s) => {
					const prevTask = this.tasksById.get(s.pred_task_id);
					return prevTask.task_type !== 'TT_LOE' && prevTask.proj_id === activity.proj_id;
				}) || [];
			const successors = this.taskSuccessors.get(activity.task_code) || [];
			const succs = successors.filter((s) => {
				const nextTask = this.tasksById.get(s.task_id);
				return nextTask.task_type !== 'TT_LOE' && nextTask.proj_id === activity.proj_id;
			});
			if (!preds.length || !succs.length) {
				totalVars.missingPredSucc++;
				typeCounts.missingPredSucc[this.taskType(activity)]++;
				if (this.countActivity(activity, 'missingPredSucc')) {
					vars.numTaskNoPredSucc++;
				}
			}
			if (
				activity.act_start_date &&
				!activity.act_end_date &&
				activity.target_drtn_hr_cnt === activity.remain_drtn_hr_cnt
			) {
				totalVars.noProgressWithActual++;
				typeCounts.noProgressWithActual[this.taskType(activity)]++;
				if (this.countActivity(activity, 'noProgressWithActual')) {
					vars.numNoProgressWithActual++;
				}
			}
			if (
				actvInPrevUpdate &&
				activity.act_start_date &&
				!activity.act_end_date &&
				actvInPrevUpdate.remain_drtn_hr_cnt === activity.remain_drtn_hr_cnt
			) {
				totalVars.noDeltaInRemaining++;
				typeCounts.noDeltaInRemaining[this.taskType(activity)]++;
				if (this.countActivity(actvInPrevUpdate, 'noDeltaInRemaining')) {
					vars.numNoDeltaInRemaining++;
				}
			}

			if (!this.args.calendars.has(activity.clndr_id)) {
				console.log('missing cal', activity.clndr_id, this.args.calendars.keys(), this.updateIndex);
			}
			if (
				(activity.task_type === 'TT_Task' || activity.task_type === 'TT_Rsrc') &&
				activity.target_drtn_hr_cnt / (this.args.calendars.get(activity.clndr_id)?.calendar.day_hr_cnt || 8) > 44 &&
				!activity.act_end_date
			) {
				totalVars.highDuration++;
				typeCounts.highDuration[this.taskType(activity)]++;
				if (this.countActivity(activity, 'highDuration')) {
					vars.numHighDuration++;
				}
			}

			if (
				!activity.act_end_date &&
				activity.total_float_hr_cnt !== undefined &&
				activity.total_float_hr_cnt / (this.args.calendars.get(activity.clndr_id)?.calendar.day_hr_cnt || 8) >
					80 + criticalPathFloat
			) {
				totalVars.highFloat++;
				typeCounts.highFloat[this.taskType(activity)]++;
				if (this.countActivity(activity, 'highFloat')) {
					vars.numHighFloat++;
				}
			}
			if (activity.task_type === 'TT_Task' || activity.task_type === 'TT_Rsrc') {
				const isOpenStart =
					this.taskPredecessors.get(activity.task_code)?.length > 0 &&
					!this.taskPredecessors.get(activity.task_code)?.some((pred) => pred.pred_type !== 'PR_FF');
				const isOpenFinish = successors.length > 0 && !successors.some((succ) => succ.pred_type !== 'PR_SS');
				if (isOpenStart || isOpenFinish) {
					vars.numOpenStartFinish++;
				}
			}

			for (const pred of this.taskPredecessors.get(activity.task_code) || []) {
				const predActv = this.tasksById.get(pred.pred_task_id);
				if (!predActv) {
					continue;
				}
				if (pred.lag_hr_cnt < 0) {
					totalVars.negLag++;
					typeCounts.negLag[this.taskType(activity)]++;
					if (this.countActivity(activity, 'negLag')) {
						vars.numNegLag++;
					}
				}

				const isProblematic = isProblematicRelationship(predActv, pred.pred_type, pred.lag_hr_cnt, activity);
				if (isProblematic) {
					vars.numSSFFProblematic++;
				}

				switch (pred.pred_type) {
					case 'PR_FS':
						{
							if (pred.lag_hr_cnt) {
								totalVars.fsWithLag++;
								typeCounts.fsWithLag[this.taskType(activity)]++;
								if (this.countActivity(activity, 'fsWithLag')) {
									vars.numFSLagged++;
								}
							}
							if (!predActv.act_end_date) {
								if (activity.act_start_date && !activity.act_end_date) {
									totalVars.oos++;
									typeCounts.oos[this.taskType(activity)]++;
									if (this.countActivity(activity, 'oos')) {
										if (isCritical(activity)) {
											vars.numOosCritical++;
										} else if (actvInPrevUpdate && isNearCriticalPlanned(actvInPrevUpdate, this.dataDate)) {
											vars.numOosNearCritical++;
										} else {
											vars.numOosNonCritical++;
										}
									}
								}
							}
						}
						break;
					case 'PR_SF': {
						vars.numSfRelationships++;
					}
				}
			}
		}
		// Post-activities loop
		activitiesByTotalFloatHrs.sort((a: [number, XerActivity], b: [number, XerActivity]) => {
			if (a[0] === b[0] && a[1]?.act_start_date && b[1]?.act_start_date) {
				return a[1].act_start_date < b[1].act_start_date ? -1 : 1;
			}
			return a[0] - b[0];
		});
		const pathFloats: Array<number> = [];
		let criticaledActivities = new Set<Partial<ActivityInfo>>([]);
		for (const [float, actv] of activitiesByTotalFloatHrs) {
			if (dataDate && !criticaledActivities.has(actv)) {
				const [, critPathForward] = slimmedCriticalPathForward(
					this.tasksById,
					actv,
					this.taskSuccessors,
					this.args.calendars
				);
				criticaledActivities = new Set<Partial<ActivityInfo>>([...criticaledActivities, ...critPathForward]);
				pathFloats.push(float / 8);
			}
		}

		return {
			vars,
			totalVars,
			pathFloats,
			typeCounts,
		};
	}

	public countActivity(actv: XerActivity, scenario: FilterCases): boolean {
		const countedActivities = this.includedActivities[scenario] || [];
		return (
			(actv.task_type === 'TT_Task' && countedActivities.includes('task')) ||
			(actv.task_type === 'TT_LOE' && countedActivities.includes('loe')) ||
			(actv.task_type === 'TT_Mile' && countedActivities.includes('milestone')) ||
			(actv.task_type === 'TT_FinMile' && countedActivities.includes('finishMilestone'))
		);
	}

	public get previous(): UpdateManager | undefined {
		if (!this.seriesManager) {
			return undefined;
		}
		const managers = this.seriesManager.sortedManagers;
		const index = managers.indexOf(this);
		if (index === 0) {
			return undefined;
		}
		return managers[index - 1];
	}

	public get next(): UpdateManager | undefined {
		if (!this.seriesManager) {
			return undefined;
		}
		const managers = this.seriesManager.sortedManagers;
		const index = managers.indexOf(this);
		if (index === managers.length - 1) {
			return undefined;
		}
		return managers[index + 1];
	}

	public get totalFloatIndices(): TotalFloatIndexArgs {
		const args: TotalFloatIndexArgs = {
			floatLarge: 0,
			floatMonth: 0,
			floatWeek: 0,
			floatNegative: 0,
			floatNone: 0,
			floatAverage: 0,
		};
		const allFloats: number[] = [];
		const floatActvs = Array.from(this.tasksById.values()).filter(
			(actv) =>
				actv.total_float_hr_cnt !== undefined &&
				!actv.act_end_date &&
				['TT_Task', 'TT_Mile', 'TT_FinMile'].includes(actv.task_type)
		);
		for (const actv of floatActvs) {
			actv.total_float_hr_cnt = +actv.total_float_hr_cnt;
			allFloats.push(actv.total_float_hr_cnt / (this.args.calendars.get(actv.clndr_id)?.calendar.day_hr_cnt || 8));
			if (actv.total_float_hr_cnt < 0) {
				args.floatNegative++;
			} else if (actv.total_float_hr_cnt === 0) {
				args.floatNone++;
			} else if (actv.total_float_hr_cnt / (this.args.calendars.get(actv.clndr_id)?.calendar.day_hr_cnt || 8) <= 7) {
				args.floatWeek++;
			} else if (actv.total_float_hr_cnt / (this.args.calendars.get(actv.clndr_id)?.calendar.day_hr_cnt || 8) <= 30) {
				args.floatMonth++;
			} else {
				args.floatLarge++;
			}
		}
		args.floatAverage = average(allFloats, floatActvs.length);
		return args;
	}

	public get lastPeriodGraph(): LastPeriodItem {
		const prevUpdate: UpdateManager = this.previous;
		const graph: LastPeriodItem = getLastPeriodItemVars();

		for (const [code, prevActv] of prevUpdate?.tasksByCode || []) {
			const actv = this.tasksByCode.get(code);
			if (isCriticalPlanned(prevActv, this.dataDate)) {
				graph.countCriticalPlanned++;
				if (actv?.act_end_date) {
					graph.countCriticalCompleted++;
				}
			}

			if (isNearCriticalPlanned(prevActv, this.dataDate)) {
				graph.countNearCriticalPlanned++;
				if (actv?.act_end_date) {
					graph.countNearCriticalCompleted++;
				}
			}
			if (this.next && isNearCriticalPlanned(prevActv, this.next.dataDate)) {
				graph.countNearCriticalPlannedByNext++;
				if (this.next.tasksByCode.get(code)?.act_end_date) {
					graph.countNearCriticalCompletedByNext++;
				}
			}

			if (isNonCriticalPlanned(prevActv, this.dataDate)) {
				graph.countNonCriticalPlanned++;
				if (actv?.act_end_date) {
					graph.countNonCriticalCompleted++;
				}
			}

			if (isPlannedToBeFinishedBy(prevActv, this.dataDate)) {
				graph.countTotalPlanned++;
				if (actv?.act_end_date) {
					graph.countTotalCompleted++;
				}
			}
		}
		graph.criticalPercentComplete = graph.countCriticalCompleted / (graph.countCriticalPlanned || 1);
		graph.nearCriticalPercentComplete = graph.countNearCriticalCompleted / (graph.countNearCriticalPlanned || 1);
		graph.nonCriticalPercentComplete = graph.countNonCriticalCompleted / (graph.countNonCriticalPlanned || 1);
		graph.totalPercentComplete = graph.countTotalCompleted / (graph.countTotalPlanned || 1);
		return graph;
	}

	public get progressScore(): number {
		const lastPeriod = this.lastPeriodGraph;
		compareObj(this.seriesManager.referenceReport?.activityCompletionGraph.lastPeriodGraph, lastPeriod);
		const updateIndex = this.updateIndex;
		const managers = this.seriesManager.sortedManagers;
		const floatIndices: TotalFloatIndexArgs[] = [];
		for (let i = updateIndex; i >= 0; i--) {
			const manager = managers[i];
			floatIndices.unshift(manager.totalFloatIndices);
			if (manager.finishMilestoneCode !== this.finishMilestoneCode || manager.args.update.baseline) {
				break;
			}
		}
		compareObj(
			this.seriesManager.referenceReport?.floatHistorical[floatIndices.length - 1],
			floatIndices[floatIndices.length - 1]
		);
		const floats = TotalFloatIndex(floatIndices);
		const periodFloat = periodicFloat(floats.periodDelta);
		const baselineFloatIndex = periodicFloat(floats.baselineDelta);
		const actvCompletionIndex = activityCompletionIndex(lastPeriod);
		const result = progress(actvCompletionIndex, periodFloat, baselineFloatIndex);
		console.log({ actvCompletionIndex, periodFloat, baselineFloatIndex });
		if (this.seriesManager.referenceReport && this.seriesManager.referenceReport.progressScore !== 100 * result) {
			console.log(
				`discrepancy in progressScore. expected: ${this.seriesManager.referenceReport.progressScore}, actual: ${100 * result}`
			);
		}
		return 100 * result;
	}

	public get qualityControlScore(): number {
		const qcVars = this._taskSummary.vars;
		return 100 * getQcScore(qcVars, 1);
	}

	public get predictabilityScore(): number {
		const summary = this._taskSummary;
		const prevSummary = this.previous?._taskSummary;
		const prevCritPaths = prevSummary?.pathFloats?.slice(0, 3) || [];
		const critPaths = summary.pathFloats.slice(0, 3);

		if (this.seriesManager.referenceReport) {
			const cpr = this.seriesManager.referenceReport.riskPage.criticalPathRisk.cprTrends;
			const expCur: number[] = [
				cpr.firstPathTrend[cpr.firstPathTrend.length - 1],
				cpr.secondPathTrend[cpr.secondPathTrend.length - 1],
				cpr.thirdPathTrend[cpr.thirdPathTrend.length - 1],
			];
			const expPrv: number[] = [
				cpr.firstPathTrend[cpr.firstPathTrend.length - 2],
				cpr.secondPathTrend[cpr.secondPathTrend.length - 2],
				cpr.thirdPathTrend[cpr.thirdPathTrend.length - 2],
			];
			if (this.seriesManager.referenceReport && !arraysAreIdentical(prevCritPaths, expPrv)) {
				console.log(
					`discrepancy for prev crit path in update ${this.updateIndex}. expected ${expPrv}, actual: ${prevCritPaths}`
				);
			}
			if (this.seriesManager.referenceReport && !arraysAreIdentical(critPaths, expCur)) {
				console.log(
					`discrepancy for crit path in update ${this.updateIndex}. expected ${expCur}, actual: ${critPaths}`
				);
			}
		}

		const critPathRiskScore = !this.previous ? 0 : criticalPathRiskScore(prevCritPaths, critPaths);
		if (
			this.seriesManager.referenceReport &&
			!similarScore(critPathRiskScore, this.seriesManager.referenceReport.riskPage.criticalPathRisk.overallScore)
		) {
			console.log(
				`discrepancy for critPathRiskScore in update ${this.updateIndex}. expected ${this.seriesManager.referenceReport.riskPage.overallScore}, actual: ${critPathRiskScore}`
			);
		}
		const critPathReliabilityScore = !this.previous
			? 1
			: slimmedCriticalPathReliabilityScore({
					maintainedPrevCritActivities: summary.vars.maintainedPrevCritActivities.length,
					previousCritActivities: prevSummary?.vars?.critActivities?.size || 0,
					completedPrevCritActivities: summary.vars.completedPrevCritActivities.length,
					critActivities: summary.vars.critActivities.size,
					prevDrivingTies: prevSummary?.vars.currentDrivingTies?.size || 0,
					deletedDrivingTies: summary.vars.deletedDrivingTies.length,
					currentDrivingTies: summary.vars.currentDrivingTies.size,
					addedDrivingTies: summary.vars.addedDrivingTies.size,
					prevLagChanges: prevSummary?.vars.currentLagChanges?.length || 0,
					currentLagChanges: summary.vars.currentLagChanges.length,
				});
		if (
			this.seriesManager.referenceReport &&
			!similarScore(
				critPathReliabilityScore,
				this.seriesManager.referenceReport.riskPage.criticalPathReliability.overallScore
			)
		) {
			console.log({
				maintainedPrevCritActivities: summary.vars.maintainedPrevCritActivities.length,
				previousCritActivities: prevSummary?.vars?.critActivities?.size || 0,
				completedPrevCritActivities: summary.vars.completedPrevCritActivities.length,
				critActivities: summary.vars.critActivities.size,
				prevDrivingTies: prevSummary?.vars.currentDrivingTies?.size || 0,
				deletedDrivingTies: summary.vars.deletedDrivingTies.length,
				currentDrivingTies: summary.vars.currentDrivingTies.size,
				addedDrivingTies: summary.vars.addedDrivingTies.size,
				prevLagChanges: prevSummary?.vars.currentLagChanges?.length || 0,
				currentLagChanges: summary.vars.currentLagChanges.length,
			});
			console.log(
				`discrepancy for critPathReliabilityScore in update ${this.updateIndex}. expected ${this.seriesManager.referenceReport.riskPage.criticalPathReliability.overallScore}, actual: ${critPathReliabilityScore}`
			);
		}
		const cpliScore = cpli(
			criticalPathDuration(this.tasksByCode.get(this.finishMilestoneCode), this.dataDate),
			critPaths?.[0] || 0
		);
		console.log(
			this.tasksByCode.has(this.finishMilestoneCode),
			this.finishMilestoneCode,
			'ffff',
			this.tasksByCode.get(this.finishMilestoneCode),
			this.dataDate
		);
		if (
			this.seriesManager.referenceReport &&
			!similarScore(cpliScore, this.seriesManager.referenceReport.calculationFields.CPLI)
		) {
			console.log(
				`discrepancy for CPLI in update ${this.updateIndex}. expected ${this.seriesManager.referenceReport.calculationFields.CPLI}, actual: ${cpliScore}`
			);
		}
		const beiScore = bei(summary.vars.numTasksCompleted, summary.vars.projectedNumBaseTasksCompleted);
		if (
			this.seriesManager.referenceReport &&
			!similarScore(beiScore, this.seriesManager.referenceReport.calculationFields.BEI)
		) {
			console.log(
				`discrepancy for bei in update ${this.updateIndex}. expected ${this.seriesManager.referenceReport.calculationFields.BEI}, actual: ${beiScore}`
			);
		}
		const predictability = projectPredictability(
			100 * critPathRiskScore,
			100 * critPathReliabilityScore,
			100 * cpliScore,
			100 * beiScore,
			this.report?.riskPage?.performanceFactor?.overallScore,
			!this.previous
		);
		if (
			this.seriesManager.referenceReport &&
			!similarScore(predictability, this.seriesManager.referenceReport.riskScore)
		) {
			console.log(
				`discrepancy for predictability in update ${this.updateIndex}. expected ${this.seriesManager.referenceReport.riskScore}, actual: ${predictability}`
			);
		}
		return predictability;
	}

	public get projectScore(): number {
		const progressScore = this.previous ? this.progressScore : 0;
		const qcScore = this.qualityControlScore;
		const predictabilityScore = this.predictabilityScore;
		return (progressScore + qcScore + predictabilityScore) / (this.previous ? 3 : 2);
	}

	public get drivingTies(): typeof this._drivingTies {
		if (this._drivingTies) {
			return this._drivingTies;
		}
		const drivingTies = new Map<string, string>([]);
		for (const actv of this._criticalActivities) {
			const actvPreds = this.taskPredecessors.get(actv.task_code) || [];
			for (const relation of actvPreds) {
				if (
					isDriving(relation, this.tasksById.get(relation.pred_task_id), actv, this.args.calendars?.get(actv.clndr_id))
				) {
					const predKey = `${this.tasksById.get(relation.pred_task_id).task_code}-${relation.pred_type}-${actv.task_code}`;
					drivingTies.set(predKey, predKey);
				}
			}
		}
		this._drivingTies = drivingTies;
		return this._drivingTies;
	}

	public taskType(actv: XerActivity): ActivityFilterType {
		if (actv.task_type === 'TT_LOE') {
			return 'loe';
		}
		if (actv.task_type === 'TT_FinMile') {
			return 'finishMilestone';
		}
		if (actv.task_type === 'TT_Mile') {
			return 'milestone';
		}
		return 'task';
	}
}
