<div class='card'>
	<kendo-gridlayout
		[rows]="['42.4px', '303px']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='full-width full-height'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container'>
			<div class="card-header">
				Critical Path Reliability
			</div>
			<tooltip-open type='critical-path-reliability'></tooltip-open>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1'>
			<kendo-gridlayout
				[rows]="['303px']"
				[cols]="['calc((400% / 12) - 2.5px)', 'calc((800% / 12) - 2.5px)']"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width full-height'
			>
				<kendo-gridlayout-item [row]='1' [col]='1'>
					<kendo-gridlayout
						[rows]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
						[cols]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
						[gap]="{ rows: 5, cols: 5 }"
						class='full-width full-height'
						style='padding: 5px 0 5px 5px; height: calc(100% - 10px);'
					>
						<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item'>
							<kendo-gridlayout
								[rows]="['35%', '65%']"
								[cols]="['auto']"
								class='full-width full-height'
								[ngClass]="{
                            'score-blue': ($baseScore | async) >= 85,
                            'score-green': ($baseScore | async) < 85 && ($baseScore | async) >= 70,
                            'score-red': ($baseScore | async) < 70
                            }"
							>
								<kendo-gridlayout-item [row]='1' [col]='1' class='grid-label'>
									Base Logic
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]='2' [col]='1' class='grid-value'>
									{{$baseScore | async | number : '1.0-0' }}%
								</kendo-gridlayout-item>
							</kendo-gridlayout>
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]='1' [col]='2' class='gridlayout-item'>
							<kendo-gridlayout
								[rows]="['35%', '65%']"
								[cols]="['auto']"
								class='full-width full-height'
								[ngClass]="{
                            'score-blue': ($updateScore | async) >= 85,
                            'score-green': ($updateScore | async) < 85 && ($updateScore | async) >= 70,
                            'score-red': ($updateScore | async) < 70
                            }"
							>
								<kendo-gridlayout-item [row]='1' [col]='1' class='grid-label'>
									Update Logic
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]='2' [col]='1' class='grid-value'>
									{{$updateScore | async | number : '1.0-0' }}%
								</kendo-gridlayout-item>
							</kendo-gridlayout>
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]='2' [col]='1' class='gridlayout-item'>
							<kendo-gridlayout
								[rows]="['35%', '65%']"
								[cols]="['auto']"
								class='full-width full-height'
								[ngClass]="{
                            'score-blue': ($consistencyScore | async) >= 85,
                            'score-green': ($consistencyScore | async) < 85 && ($consistencyScore | async) >= 70,
                            'score-red': ($consistencyScore | async) < 70
                            }"
							>
								<kendo-gridlayout-item [row]='1' [col]='1' class='grid-label'>
									Activity Consistency
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]='2' [col]='1' class='grid-value'>
									{{$consistencyScore | async | number : '1.0-0' }}%
								</kendo-gridlayout-item>
							</kendo-gridlayout>
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]='2' [col]='2' class='gridlayout-item'>
							<kendo-gridlayout
								[rows]="['35%', '65%']"
								[cols]="['auto']"
								class='full-width full-height'
								[ngClass]="{
                            'score-blue': ($overallScore | async) >= 85,
                            'score-green': ($overallScore | async) < 85 && ($overallScore | async) >= 70,
                            'score-red': ($overallScore | async) < 70
                            }"
							>
								<kendo-gridlayout-item [row]='1' [col]='1' class='grid-label'>
									Overall Score
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]='2' [col]='1' class='grid-value'>
									{{$overallScore | async | number : '1.0-0' }}%
								</kendo-gridlayout-item>
							</kendo-gridlayout>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2'>
					<div style='height: 300px; margin-bottom: 3px'>
						<app-chart
							[title]='"Overall Score Trend"'
							[categories]='categories'
							[seriesData]='chartData'
							[legendVisible]='false'
							[valueAxis]='valueAxisItemSettings'
						></app-chart>
					</div>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>
