import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Papa } from 'ngx-papaparse';
import { ProjectDashboardService } from '../project/project.service';
import { datePipe } from '../../util/pipes/date.pipe';
import { groupBy, GroupResult, orderBy } from '@progress/kendo-data-query';
import { Activity, Xer, XerActivity, XerProject } from '@rhinoworks/xer-parse';
import { UpdateInterface } from '../../models/Update/Task';

@Injectable()
export class FileUploadService {
	data: UpdateInterface;
	xer: Xer;
	xerLoaded: boolean;
	xerFile: boolean;
	loading: false | 'file' | 'upload' | 'success';
	finishMilestones: GroupResult[] | { text: string; value: XerActivity; earlyFinish: Date; category: string }[];
	startMilestones: GroupResult[] | { text: string; value: XerActivity; category: string; earlyFinish: Date }[];
	generalNotes: string = '';
	criticalPathSummary: string;
	impactsIssuesTiaNotes: string;
	additionalMilestonesFormControl = new UntypedFormControl();
	selectedProject: XerProject;
	constructor(
		private papa: Papa,
		private _projectDashboardService: ProjectDashboardService
	) {}

	OnInit() {}

	async handleFileSelect(evt): Promise<void> {
		this.xerLoaded = false;
		const xerFile = evt.target.files[0];
		(window as any).global = window;
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		window.Buffer = window.Buffer || require('buffer').Buffer;
		this.data.xerFileName = xerFile.name;
		try {
			const arrayBuffer = await evt.target.files[0].arrayBuffer();
			const buf: Buffer = Buffer.from(arrayBuffer);

			const a: Xer = new Xer();
			a.buffer = buf;
			this.xer = a;
			this.data.xerFileBuffer = a.xerData;
			this.assignTasks(a.sortedActivities);
			this.loading = false;
			this.xerLoaded = true;
			const reader = new FileReader();
			reader.readAsText(xerFile);
		} catch (err) {
			console.log(err);
			this.loading = false;
			this.xerLoaded = true;
		}
	}

	assignTasks(activities: Array<Activity>) {
		const tasks = activities.map((actv) => actv.raw_entry);
		const finishMilestones = new Set<{ text: string; value: XerActivity; earlyFinish: Date; category: string }>(
			tasks
				.filter(
					(task) =>
						task.task_type === 'TT_FinMile' ||
						(task.task_type !== 'TT_Mile' &&
							!task.task_name.toLowerCase().includes('igmp') &&
							!task.task_name.toLowerCase().includes('fgmp'))
				)
				.map((task) => ({
					text: `${task?.task_code} - ${task?.task_name} - ${task?.act_end_date ? datePipe(task?.act_end_date, 'MMM d, y') + ' A' : datePipe(task?.early_end_date, 'MMM d, y')}`,
					value: task,
					earlyFinish: task.early_end_date,
					category: task.task_type === 'TT_FinMile' ? 'Finish Milestone' : 'All Activities',
				}))
		);
		const startMilestones = new Set<{ text: string; value: XerActivity; category: string; earlyFinish: Date }>(
			tasks
				.filter((task) => task.task_type !== 'TT_FinMile')
				.map((task) => ({
					text: `${task?.task_code} - ${task?.task_name} - ${task?.act_start_date ? datePipe(task?.act_start_date, 'MMM d, y') + ' A' : datePipe(task?.early_end_date, 'MMM d, y')}`,
					value: task,
					earlyFinish: task.early_end_date,
					category:
						task.task_type === 'TT_Mile' || task.task_name.includes('igmp') || task.task_name.includes('fgmp')
							? 'Start Milestone'
							: 'All Activities',
				}))
		);

		this.startMilestones = groupBy(
			orderBy(Array.from(startMilestones), [
				{ field: 'category', dir: 'desc' },
				{ field: 'earlyFinish', dir: 'asc' },
			]),
			[{ field: 'category' }]
		);
		this.finishMilestones = groupBy(
			orderBy(Array.from(finishMilestones), [
				{ field: 'category', dir: 'desc' },
				{ field: 'earlyFinish', dir: 'asc' },
			]),
			[{ field: 'category' }]
		);
	}

	createBaselineUpdate(currentProjectId: string, updateId: string): any {
		if (!currentProjectId) {
			return {};
		}

		const newBaseline = {
			additionalTrackingMilestones: this.additionalMilestonesFormControl.value,
			finishMilestone: this.data.finishMilestone,
			startMilestone: this.data.startMilestone,
			projectId: currentProjectId,
			generalNotes: this.generalNotes,
			criticalPathSummary: this.criticalPathSummary,
			impactsIssuesTiaNotes: this.impactsIssuesTiaNotes,
			isAegisGeneratedSchedule: this.data.isAegisGeneratedSchedule,
			xerFileName: this.data.xerFileName,
			xerFileBuffer: this.data.xerFileBuffer,
			isBaselineUpdate: true,
			updateName: '',
			_id: updateId,
			selectedProjectId: this.data.selectedProjectId,
		};

		this._projectDashboardService.addBaseline(currentProjectId, newBaseline);
		return newBaseline;
	}

	updateRisk(newRiskVal: boolean, newRiskMetricsType: string, currentProjectId: any) {
		this._projectDashboardService.updateProjectRisk(newRiskVal, newRiskMetricsType, currentProjectId, []);
	}
}
