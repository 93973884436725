import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityCompletionModule } from './activity-completion/activity.completion.module';
import { ScheduleLookaheadModule } from './schedule-lookahead/schedule-lookahead.module';
import { ProjectCompletionTrendingModule } from './project-completion-trending/project-completion-trending.module';
import { MilestonesModule } from './milestones/milestones.module';
import { ProjectOverviewComponent } from './project-overview.component';
import { SharedIonModule } from '../../shared/shared-ion.module';
import { FloatConsumptionComponent } from './float-consumption/float-consumption.component';
import { TooltipMenuModule } from '../../portfolio/tooltip-menu/tooltip-menu.module';
import { ChartModule } from '@progress/kendo-angular-charts';
import { GridLayoutModule } from '@progress/kendo-angular-layout';
import { VisualizerHeaderComponent } from '../visualize-report/visualizer-header/visualizer-header.component';
import { ReportVisualizerModule } from '../visualize-report/report-visualizer.module';
import { FloatConsumptionTableCardComponent } from './float-consumption/float-consumption-table-card/float-consumption-table-card.component';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonModule } from '@progress/kendo-angular-buttons';
import { SharedMatModule } from 'components/shared/shared-mat.module';
import { ProgressTrendingComponent } from './progress-trending/progress-trending.component';
import { AegisChartModule } from '../../shared/chart/chart.module';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { PipeModule } from '../../../util/pipes/pipe.module';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { GaugeModule } from '../../shared/gauge/gauge.module';
import { RiskWidgetsModule } from '../../shared/risk-widgets/risk-widgets.module';
import { SummaryComponent } from './summary/summary.component';
import { QcPageModule } from '../qc/qc-page.module';
import { DropDownTreesModule, SharedDirectivesModule } from '@progress/kendo-angular-dropdowns';

@NgModule({
	declarations: [
		ProjectOverviewComponent,
		FloatConsumptionComponent,
		FloatConsumptionTableCardComponent,
		ProgressTrendingComponent,
		SummaryComponent,
	],
	imports: [
		CommonModule,
		ActivityCompletionModule,
		ScheduleLookaheadModule,
		MilestonesModule,
		ProjectCompletionTrendingModule,
		SharedIonModule,
		TooltipMenuModule,
		ChartModule,
		GridLayoutModule,
		GridModule,
		LabelModule,
		InputsModule,
		ButtonModule,
		ExcelModule,
		SharedMatModule,
		AegisChartModule,
		ProgressBarModule,
		PipeModule,
		TooltipModule,
		SVGIconModule,
		GaugeModule,
		RiskWidgetsModule,
		QcPageModule,
		DropDownTreesModule,
		SharedDirectivesModule,
	],
	exports: [ProjectOverviewComponent, FloatConsumptionComponent, ProgressTrendingComponent, SummaryComponent],
})
export class ProjectOverviewModule {}
