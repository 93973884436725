import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-gauge',
	templateUrl: './gauge.component.html',
	styleUrls: ['./gauge.component.scss'],
})
export class GaugeComponent implements OnInit, OnDestroy {
	@Input() value: BehaviorSubject<number>;
	@Input() colors;
	@Input() label: string;
	@Input() height: string = '172px';
	@Input() valueFontSize: string = '24px';
	@Input() labelFontSize: string = '14px';
	@ViewChild('gauge') gauge;
	_value = 0;
	private _unsubscribeAll: Subject<void> = new Subject<void>();

	constructor() {}

	ngOnInit() {
		this.value.pipe(takeUntil(this._unsubscribeAll), debounceTime(100)).subscribe((val) => {
			this._value = val;
			this.gauge.resize();
		});
	}

	ngOnDestroy() {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
