import { max } from 'date-fns';
import { Activity } from '@rhinoworks/xer-parse';
import { ActivityInfo } from '../activity';
import { QualityControl } from '../report';

export interface QualityControlVars {
	numConstrained: number;
	numTotalTasks: number;
	numRemainingTasks: number;
	numFSLagged: number;
	numTaskNoPredSucc: number;
	numActualsAfterDataDate: number;
	numOosCritical: number;
	numOosNearCritical: number;
	numOosNonCritical: number;
	numNegLag: number;
	numRelationships: number;
	numTasks: number;
	numHighDuration: number;
	numHighFloat: number;
	numProblematicFloat: number;
	numNoProgressWithActual: number;
	numNoDeltaInRemaining: number;
	numCritical: number;
	numOpenStartFinish: number;
	numSSFFProblematic: number;
	numSfRelationships: number;
}

export function getQualityControlVars(): QualityControlVars {
	return {
		numConstrained: 0,
		numTotalTasks: 0,
		numRemainingTasks: 0,
		numFSLagged: 0,
		numTaskNoPredSucc: 0,
		numActualsAfterDataDate: 0,
		numRelationships: 0,
		numOosCritical: 0,
		numOosNearCritical: 0,
		numOosNonCritical: 0,
		numNegLag: 0,
		numTasks: 0,
		numHighDuration: 0,
		numHighFloat: 0,
		numProblematicFloat: 0,
		numNoProgressWithActual: 0,
		numNoDeltaInRemaining: 0,
		numCritical: 0,
		numOpenStartFinish: 0,
		numSSFFProblematic: 0,
		numSfRelationships: 0,
	};
}

export function getQcScore(args: QualityControlVars, i?: number): number {
	if (!args.numTotalTasks) {
		return 0;
	}
	const relationshipDensity = args.numRelationships / args.numTotalTasks;
	const highDuration = args.numHighDuration / args.numRemainingTasks;
	const highFloat = args.numHighFloat / args.numRemainingTasks;

	const openStartFinish = args.numOpenStartFinish / args.numRemainingTasks;
	const fsLagPenalty = args.numFSLagged > 2 ? Math.min(0.1, (args.numFSLagged - 2) * 0.01) : 0;
	const missingPredSuccPenalty = args.numTaskNoPredSucc > 2 ? Math.min(0.15, (args.numTaskNoPredSucc - 2) * 0.02) : 0;

	const oosCriticalPenalty = args.numOosCritical > 1 ? Math.min(0.05, (args.numOosCritical - 1) * 0.01) : 0;
	const oosNearCriticalPenalty =
		args.numOosNearCritical > 1 ? Math.min(0.025, (args.numOosNearCritical - 1) * 0.005) : 0;
	const pctOosNonCritical = args.numOosNonCritical / args.numRemainingTasks;
	const oosNonCriticalPenalty =
		pctOosNonCritical > 0.01 ? Math.min(0.025, Math.floor(pctOosNonCritical * 100 - 1) * 0.005) : 0;

	const negLagPenalty = args.numNegLag > 0 ? Math.min(0.12, args.numNegLag * 0.01) : 0;
	const hardConstraintPenalty = args.numConstrained > 1 ? Math.min(0.1, (args.numConstrained - 1) * 0.01) : 0;
	function computeUnits(x: number): number {
		if (x < 2) {
			return Math.floor((2 - x) / 0.05);
		} else if (x > 4) {
			return Math.floor((x - 4) / 0.05);
		} else {
			return 0;
		}
	}
	const relationshipDensityPenalty =
		relationshipDensity < 2 || relationshipDensity > 4 ? Math.min(0.05, computeUnits(relationshipDensity) * 0.05) : 0;
	const durationPenalty = highDuration > 0.1 ? Math.min(0.1, Math.floor((highDuration - 0.1) / 0.02) * 0.01) : 0;
	const floatPenalty = highFloat > 0.01 ? Math.min(0.15, Math.floor(highFloat * 100 - 1) * 0.01) : 0;

	const ssffProblematicPenalty = args.numSSFFProblematic > 0 ? 0.05 : 0;
	const openStartFinishPenalty = openStartFinish > 0.01 ? 0.05 : 0;
	const sfPenalty =
		args.numSfRelationships > 0
			? Math.min(0.03, Math.floor((args.numSfRelationships * 100) / args.numRelationships) * 0.01)
			: 0;

	if (i) {
		console.log(i, {
			fsLagPenalty,
			missingPredSuccPenalty,
			oosCriticalPenalty,
			oosNearCriticalPenalty,
			oosNonCriticalPenalty,
			negLagPenalty,
			hardConstraintPenalty,
			relationshipDensityPenalty,
			durationPenalty,
			floatPenalty,
			ssffProblematicPenalty,
			openStartFinishPenalty,
			sfPenalty,
			args,
			qcscore:
				1 -
				fsLagPenalty -
				missingPredSuccPenalty -
				oosCriticalPenalty -
				oosNearCriticalPenalty -
				oosNonCriticalPenalty -
				negLagPenalty -
				hardConstraintPenalty -
				relationshipDensityPenalty -
				durationPenalty -
				floatPenalty -
				ssffProblematicPenalty -
				openStartFinishPenalty -
				sfPenalty,
		});
	}
	return (
		1 -
		fsLagPenalty -
		missingPredSuccPenalty -
		oosCriticalPenalty -
		oosNearCriticalPenalty -
		oosNonCriticalPenalty -
		negLagPenalty -
		hardConstraintPenalty -
		relationshipDensityPenalty -
		durationPenalty -
		floatPenalty -
		ssffProblematicPenalty -
		openStartFinishPenalty -
		sfPenalty
	);
}

export function latestActualDate(activity: Activity | Partial<ActivityInfo>): Date | undefined {
	const start = activity instanceof Activity ? activity.start : activity.start;
	const finish = activity instanceof Activity ? activity.finish : activity.finish;
	if (start && finish) {
		return max([start, finish]);
	} else {
		return start || finish;
	}
}

export function hasActualPastDate(activity: Activity | Partial<ActivityInfo>, dataDate: Date | undefined): boolean {
	const latestActual = latestActualDate(activity);
	return latestActual && dataDate ? latestActual > dataDate : false;
}

export function getQualityControl(): QualityControl {
	return {
		totalActivities: 0,
		totalRelationships: 0,
		numberOfRelationshipsToOneActivity: 0,
		totalOutOfSequenceActivities: 0,
		percentOutOfSequenceActivities: 0,
		totalLogicChanges: 0,
		currentUpdateLogicChanges: 0,
		totalTaskActivities: 0,
		totalMilestoneActivities: 0,
		totalLOES: 0,
		percentFS: 0,
		percentSS: 0,
		percentFF: 0,
		percentSF: 0,
		missingPredecessorsSuccessors: 0,
		negativeLags: 0,
		ssFfProblematic: 0,
		fsLags: 0,
		highDurationActivities: 0,
		highFloatActivities: 0,
		actualsPastDataDate: 0,
		hardConstraints: 0,
		softConstraints: 0,
		qcScore: 0,
		qcTrending: [],
		problematicRelationshipsTrending: [],
		numActualsNoProgressTrending: [],
		oosTrending: [],
		actualsPastDataDateTrending: [],
		noChangeInRemainingTrending: [],
		numCriticalTrending: [],
		ssWithoutFFTrending: [],
		fsWithLagTrending: [],
		numNoPredSuccTrending: [],
		logicDensityHistorical: [],
		retainedLogicHistorical: [],
		countCriticalPlannedTrending: [],
		countTotalPlannedTrending: [],
		ssFfProblematicTrending: [],
		softConstraintsTrending: [],
	} as QualityControl;
}
