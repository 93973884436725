<div class='card' style='page-break-inside: avoid;'>
	<kendo-gridlayout
		[rows]="['42.4px', '303px']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
			<div class="card-header">
				Quality Control Score
			</div>
			<tooltip-open type='{{isOverview ? "overview" : "qc-score-and-trend"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1'>
			<kendo-gridlayout
				[rows]="['303px']"
				[cols]="['calc((400% / 12) - 2.5px)', 'calc((800% / 12) - 2.5px)']"
				[gap]="{ rows: 0, cols: 5 }"
				class='grid'
				style='page-break-inside: avoid;'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-container'>
					<app-gauge
						[value]='$qcScore'
						[colors]='qcColors'
						[height]='"222px"'
						[valueFontSize]='"32px"'
						[labelFontSize]='"20px"'
						style='page-break-inside: avoid;'
					></app-gauge>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2'>
					<div class='chart-container'>
						<app-chart
							[title]='"Current QC Score Trend"'
							[categories]='categories'
							[seriesData]='seriesData'
							[legendVisible]='true'
							[valueAxis]='valueAxisItemSettings'
						></app-chart>
					</div>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>
