import { Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../services/common/user.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { StatusCheckService } from 'services/infra/status-check.service';
import { AnalyticsDashboardService } from '../services/analytics/analytics.service';
import { NavigationBarStorageService } from '../services/common/navigation-bar-storage.service';
import { ScheduleStorageService } from '../services/project/schedule-storage.service';
import { ProjectDashboardService } from '../services/project/project.service';
import { WindowComponent } from '@progress/kendo-angular-dialog';
import { AppWindowService } from '../services/common/window.service';
import { PortfolioPreset, PresetInputs } from '../models/auth/account-user';
import { Title } from '@angular/platform-browser';
import { ProjectAdminSettingsComponent } from './project-page/project-admin-settings/project-admin-settings.component';
import { CurrentUpdateGanttPreset, CurrentUpdateGanttPresetInputs } from '../models/Project';
import { GanttService } from '../services/project/gantt.service';
import { caretAltDownIcon } from '@progress/kendo-svg-icons';

@Component({
	selector: 'app',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	editProfileOpened = false;
	tooltipOpened = false;
	globalTooltipOpened = false;
	tooltipType: string = null;
	tooltipTitle: string = null;
	user;
	url;
	loadingWindowLeft = 0;
	reportWindowTitle = '';
	@ViewChild('bulkWindow') bulkUpdateWindow: WindowComponent;
	debugOpen = false;
	portfolioPresetWindowOpened = false;
	editingPreset: PortfolioPreset = null;
	editingGanttPreset: CurrentUpdateGanttPreset = null;
	isNew: boolean = false;
	isBasedOnOtherPreset: boolean = false;
	@ViewChild('projectAdminSettingsComponent') projectAdminSettingsComponent: ProjectAdminSettingsComponent;
	icons = {
		caretDown: caretAltDownIcon,
	};

	// Private
	private _unsubscribeAll: Subject<void>;
	/**
	 * Constructor
	 *
	 * @param document
	 * @param _platform
	 * @param _translateService
	 * @param userService
	 * @param activatedRoute
	 * @param oauthService
	 * @param statusCheckService
	 * @param analyticsService
	 * @param navBarStorage
	 * @param scheduleService
	 * @param projectDashboardService
	 * @param router
	 * @param scheduleStorage
	 * @param cdr
	 * @param windowService
	 * @param titleService
	 */
	constructor(
		@Inject(DOCUMENT) private document: any,
		private _translateService: TranslateService,
		private _platform: Platform,
		private userService: UserService,
		public activatedRoute: ActivatedRoute,
		public oauthService: OAuthService,
		public statusCheckService: StatusCheckService,
		public analyticsService: AnalyticsDashboardService,
		public navBarStorage: NavigationBarStorageService,
		public scheduleService: ScheduleStorageService,
		public projectDashboardService: ProjectDashboardService,
		private router: Router,
		public scheduleStorage: ScheduleStorageService,
		private cdr: ChangeDetectorRef,
		public windowService: AppWindowService,
		private titleService: Title,
		public ganttService: GanttService
	) {
		this.userService.isFirstLoad = true;

		// Add languages
		this._translateService.addLangs(['en', 'tr']);

		// Set the default language
		this._translateService.setDefaultLang('en');

		// Use a language
		this._translateService.use('en');

		/**
		 * ----------------------------------------------------------------------------------------------------
		 * ngxTranslate Fix Start
		 * ----------------------------------------------------------------------------------------------------
		 */

		/**
		 * If you are using a language other than the default one, i.e. Turkish in this case,
		 * you may encounter an issue where some of the components are not actually being
		 * translated when your app first initialized.
		 *
		 * This is related to ngxTranslate module and below there is a temporary fix while we
		 * are moving the multi language implementation over to the Angular's core language
		 * service.
		 **/

		// Set the default language to 'en' and then back to 'tr'.
		// '.use' cannot be used here as ngxTranslate won't switch to a language that's already
		// been selected and there is no way to force it, so we overcome the issue by switching
		// the default language back and forth.
		/**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

		/**
		 * ----------------------------------------------------------------------------------------------------
		 * ngxTranslate Fix End
		 * ----------------------------------------------------------------------------------------------------
		 */

		// Add is-mobile class to the body if the platform is mobile
		if (this._platform.ANDROID || this._platform.IOS) {
			this.document.body.classList.add('is-mobile');
		}

		// Set the private defaults
		this._unsubscribeAll = new Subject<void>();
		this.userService.loadFromLocalStorage();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */

	catchTimer: any;

	ngOnInit(): void {
		const timestamp = localStorage.getItem('raz-timestamp') !== null ? localStorage.getItem('raz-timestamp') : 'new';

		if (timestamp === 'new') {
			localStorage.setItem('raz-timestamp', 'new');
		}

		this.loadingWindowLeft = window.innerWidth - 310;

		this.analyticsService.$tooltipType.subscribe((result) => {
			if (result !== '') {
				this.openTooltipWindow(result);
			} else {
				this.tooltipOpened = false;
			}
		});

		this.analyticsService.$globalTooltipType.subscribe((result) => {
			if (result !== '') {
				if (this.projectDashboardService.$currentProjectData.value !== undefined) {
					this.openTooltipWindow(result, true);
				}
			} else {
				this.globalTooltipOpened = false;
			}
		});

		this.analyticsService.$editProfileOpen.subscribe((result) => {
			this.editProfileOpened = result;
			if (this.editProfileOpened) {
				this.windowService.setViewport('profile');
			}
		});

		this.analyticsService.$updateReportOpen.subscribe((val) => {
			if (val) {
				this.windowService.setViewport('report');
			}
		});

		this.analyticsService.addUpdateOpenSchedulesTab.subscribe((val) => {
			if (val) {
				this.windowService.setViewport('bulkUpdate');
			}
		});

		this.analyticsService.baselineOpen.subscribe((val) => {
			if (val) {
				this.windowService.setViewport('baseline');
			}
		});

		this.userService.user.subscribe((data) => {
			if (data) {
				this.user = data;
			}
		});
		this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			this.closeAllWindows();
			this.analyticsService.closeAll();
			this.url = event.url;
			if (event.url === '/portfolio') {
				this.titleService.setTitle('Aegis Analytics - Portfolio');
			} else {
				let x: number = 0;
				const projectNameInterval = setInterval(() => {
					if (this.projectDashboardService.$currentProjectData.value?.name !== undefined) {
						this.titleService.setTitle(
							'Aegis Analytics - ' + this.projectDashboardService.$currentProjectData.value?.name
						);
						clearInterval(projectNameInterval);
					}
					x++;
					if (x > 10) {
						clearInterval(projectNameInterval);
					}
				}, 500);
			}
		});
		this.analyticsService.$closeAll.subscribe((val: boolean) => {
			if (val) {
				this.closeAllWindows();
			}
		});

		this.projectDashboardService.$currentProjectReport
			.pipe(takeUntil(this._unsubscribeAll), debounceTime(500))
			.subscribe((report) => {
				if (!!report && !this.navBarStorage.reportWindowOpen) {
					this.scheduleService.$allUpdates
						.pipe(takeUntil(this._unsubscribeAll), debounceTime(100))
						.subscribe((updates) => {
							if (updates.length) {
								const recentlyAddedUpdates = JSON.parse(localStorage.getItem('updatesAdded'));
								const correctUpdatesLength = JSON.parse(localStorage.getItem('newUpdatesLength'));
								//needs report, updates, and #of updates to match to open recently added report(s)
								if (!!recentlyAddedUpdates && updates.length === correctUpdatesLength) {
									this.openReports(recentlyAddedUpdates);
								}
							}
						});
				}
			});

		this.navBarStorage.$portfolioPresetWindowOpen.subscribe((presetInput: PresetInputs) => {
			if (presetInput !== null) {
				this.portfolioPresetWindowOpened = true;
				this.windowService.setViewport('portfolioPreset');
				const userPresets: PortfolioPreset[] = this.user?.portfolioPresets;
				const index: number =
					userPresets === undefined ? -1 : userPresets?.findIndex((p) => p.id === presetInput.preset.id);
				this.isNew = index === -1;
				this.editingPreset = presetInput.preset;
			} else {
				this.portfolioPresetWindowOpened = false;
			}
		});

		this.projectDashboardService.showArchiveConfirmation.subscribe((isArchived) => {
			if (isArchived) {
				this.closeAdminSettings();
			}
		});

		let lastKnownScrollPosition: number = 0;
		let ticking: boolean = false;
		document.addEventListener('scroll', (event) => {
			lastKnownScrollPosition = window.scrollY;
			if (!ticking) {
				window.requestAnimationFrame(() => {
					this.updateWindowBounds(lastKnownScrollPosition);
					ticking = false;
				});
				ticking = true;
			}
		});

		this.ganttService.$currentUpdateGanttPresetWindowOpen.subscribe((presetInput: CurrentUpdateGanttPresetInputs) => {
			if (presetInput !== null) {
				this.windowService.setViewport('ganttPreset');
				this.isNew = presetInput.isNew;
				this.isBasedOnOtherPreset = presetInput.isBasedOnOtherPreset;
				this.editingGanttPreset = presetInput.preset;
				this.ganttService.editingPresetVals = presetInput.preset;
			}
		});
	}

	goBackUnsavedChanges(): void {
		this.ganttService.unsavedChangesReport = false;
	}

	confirmCloseReport(): void {
		this.ganttService.unsavedChangesReport = false;
		this.ganttService.$currentUpdateGanttPresetWindowOpen.next(null);
	}

	closeAllWindows(): void {
		this.closeDebug();
		this.closeTooltip(false, false);
		this.closeTooltip(false, true);
		this.closeReportWindow();
		this.closeEditProfile(false);
		this.closeWindows();
		if (this.windowService.showingAdminSettings) {
			this.closeAdminSettings();
		}
		this.windowService.$closeFilters.next(true);
		this.closeNewProjectForm();
		this.goBackUnsavedChanges();
		this.confirmCloseReport();
		this.closeAdDialog();
		this.ganttService.closeAddGroupDialog();
		this.ganttService.closeAddToFolderDialog();
		this.ganttService.closeReportManage();
		this.closeGroupDeleteConfirm();
		this.ganttService.closeStructureWindow();
	}

	closeGroupDeleteConfirm(): void {
		this.ganttService.deleteGroupConfirmOpen = false;
		this.ganttService.deletingGroup = null;
	}

	confirmDeleteGroup(): void {
		this.ganttService.deleteGroup(this.ganttService.deletingGroup.id);
	}

	/**
	 * update window bounds so that restrictMovement functions will restrict based on current scrolled window frame instead of absolute top frame
	 * @param scrollPos
	 */
	updateWindowBounds(scrollPos: number) {
		this.windowService.currentPageScrollPos = {
			top: scrollPos,
		};
	}

	@HostListener('window:keydown', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent): void {
		if (event.ctrlKey && event.key === 'b') {
			event.preventDefault();
			this.debugOpen = true;
		}
	}

	closeDebug() {
		this.debugOpen = false;
	}

	/**
	 * open reports for recently added updates
	 * @param addedUpdates
	 */
	openReports(addedUpdates: number[]): void {
		const correctUpdatesLength = JSON.parse(localStorage.getItem('newUpdatesLength'));
		if (
			this.projectDashboardService.$currentProjectReport.value.calculationFieldsHistorical &&
			this.projectDashboardService.$currentProjectReport.value.updateIds?.length === correctUpdatesLength &&
			this.projectDashboardService.$currentProjectReport.value?.qualityControl?.noChangeInRemainingTrending[
				correctUpdatesLength - 1
			] !== undefined
		) {
			this.navBarStorage.selectedUpdateIndexes = addedUpdates;
			this.navBarStorage.reportFormMaxHeight = Math.min(window.innerHeight, 877);
			this.navBarStorage.reportWindowOpen = true;
			this.windowService.setViewport('report');
		}
	}

	openTooltipWindow(type, isGlobal = false): void {
		if (type === '') {
			return;
		}
		if (isGlobal) {
			this.globalTooltipOpened = true;
		} else {
			this.tooltipOpened = true;
		}
		this.tooltipType = type;
		this.windowService.setViewport(isGlobal ? 'globalTooltip' : 'tooltip');
	}

	public closeTooltip(isOpened: boolean, isGlobal = false): void {
		if (isGlobal) {
			this.globalTooltipOpened = isOpened;
			this.analyticsService.globalTooltipType.next('');
		} else {
			this.tooltipOpened = isOpened;
			this.analyticsService.tooltipType.next('');
		}
	}

	fillTooltipName(tooltipName) {
		this.tooltipTitle = tooltipName;
		this.windowService.tooltipTitle = tooltipName;
	}

	public closeEditProfile(isOpened: boolean) {
		this.editProfileOpened = isOpened;
		this.analyticsService.editProfileOpen.next(false);
	}

	/**
	 * close all kendo windows that can be spawned from this component
	 */
	closeWindows(): void {
		this.analyticsService.addUpdateOpenSchedulesTab.next(false);
		this.analyticsService.shouldBeSoftAdd.next(false);
		this.navBarStorage.showingNewProject = false;
		this.portfolioPresetWindowOpened = false;
		this.ganttService.$currentUpdateGanttPresetWindowOpen.next(null);
		this.analyticsService.ganttGroupByOpen.next(false);
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	@HostListener('window:beforeunload', ['$event'])
	beforeunloadHandler(event) {
		//localStorage.setItem('raz-timestamp', new Date().getTime().toString());
	}

	/**
	 * close newProjectForm edit
	 */
	closeNewProjectForm(): void {
		this.navBarStorage.showingEditProject = false;
		this.analyticsService.projectDetailsOpen.next(false);
	}

	/**
	 * closes report window
	 */
	closeReportWindow(): void {
		this.navBarStorage.reportWindowOpen = false;
	}

	/**
	 * report window state change handler
	 * @param state
	 */
	public onStateChange(state: string): void {
		this.navBarStorage.updateReportFormPrevFloatDivWidth();
	}

	/**
	 * updates report window title to reflect current update showing
	 * @param newTitle
	 */
	updateReportWindowTitle(newTitle: string): void {
		this.reportWindowTitle = newTitle;
	}

	/**
	 * sets offset top for bulk window to counteract the resize of the window, keeping it centered vertically
	 */
	setBulkWindowTop(): void {
		setTimeout(() => {
			const bulkWindow = document.getElementById('bulkWindow');
			const offsetTop = (window.innerHeight - bulkWindow.offsetHeight) / 2;
			this.bulkUpdateWindow.setOffset('top', offsetTop);
		});
	}

	/**
	 * forces window to stay within bounds of viewport
	 * @param window
	 */
	restrictMovement(window: string): void {
		this.windowService.restrictMovement(window, this.cdr);
	}

	closeAdminSettings(): void {
		if (!this.projectAdminSettingsComponent.hasUnsavedDetailChanges) {
			this.windowService.showingAdminSettings = false;
		} else {
			this.projectAdminSettingsComponent.closeWindowAwaiting = true;
			this.projectAdminSettingsComponent.unsavedChangesOpen = true;
		}
	}

	closeAdDialog(): void {
		this.navBarStorage.$adDialogOpen.next(false);
	}

	public isSelectedAll(): boolean {
		const allOptions: string[] = structuredClone(this.ganttService.reportGroups)
			.map((g) => g.id)
			.sort((a: string, b: string) => {
				return a < b ? -1 : b < a ? 1 : 0;
			});
		const selectedOptions: string[] = structuredClone(this.ganttService.selectedFoldersToAddReportsTo)
			.map((g) => g.id)
			.sort((a: string, b: string) => {
				return a < b ? -1 : b < a ? 1 : 0;
			});
		return JSON.stringify(allOptions) === JSON.stringify(selectedOptions);
	}

	/**
	 * toggle selectAll folders
	 */
	onFilterSelectAllClick(): void {
		if (this.ganttService.selectedFoldersToAddReportsTo?.length === this.ganttService.reportGroups?.length) {
			this.ganttService.selectedFoldersToAddReportsTo = [];
		} else {
			this.ganttService.selectedFoldersToAddReportsTo = structuredClone(this.ganttService.reportGroups);
		}
	}
}
