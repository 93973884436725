<div class='card'>
	<kendo-gridlayout
		[rows]="['42.4px', '303px']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='full-height'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
			<div class="card-header">
				Index Trending
			</div>
			<tooltip-open type='{{isOverview ? "overview" : "index-trending"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1'>
			<div style='height: 300px; margin-bottom: 3px'>
				<app-chart
					[categories]='categories'
					[seriesData]='seriesData'
					[legendVisible]='true'
					[valueAxis]='valueAxisItemSettings'
				></app-chart>
			</div>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>
