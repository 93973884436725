import { ActivityInfo, PredecessorInfo } from '../activity';
import { differenceInCalendarDays } from 'date-fns';
import UpdateManager from '../../services/UpdateManager';
import { SlimmedTaskCommon } from '../../../models/Update/Task';
import { Calendar, XerActivity, XerTaskPredecessor } from '@rhinoworks/xer-parse';
import { isDriving } from '../../../util/tasks';

export type CriticalPathReliabilityVariables = {
	maintainedPrevCritActivities: SlimmedTaskCommon[];
	previousCritActivities: Map<string, SlimmedTaskCommon>;
	completedPrevCritActivities: SlimmedTaskCommon[];
	deletedPrevCritActivities: SlimmedTaskCommon[];
	baselineCritPathDuration: number;
	currentCritPathDuration: number;
	prevDrivingTies: Map<string, string>;
	deletedDrivingTies: string[];
	currentDrivingTies: Map<string, string>;
	addedDrivingTies: Set<string>;
	prevLagChanges: string[];
	currentLagChanges: string[];
	critActivities: Map<string, SlimmedTaskCommon>;
};

export function getCriticalPathReliability(): CriticalPathReliabilityVariables {
	return {
		maintainedPrevCritActivities: [],
		previousCritActivities: new Map<string, SlimmedTaskCommon>([]),
		completedPrevCritActivities: [],
		deletedPrevCritActivities: [],
		baselineCritPathDuration: 0,
		currentCritPathDuration: 0,
		prevDrivingTies: new Map<string, string>([]),
		deletedDrivingTies: [],
		currentDrivingTies: new Map<string, string>([]),
		addedDrivingTies: new Set<string>([]),
		prevLagChanges: [],
		currentLagChanges: [],
		critActivities: new Map<string, SlimmedTaskCommon>([]),
	};
}

export function criticalPathRiskScore(previousPathFloats: number[], currentPathFloats: number[]): number {
	return (
		Math.max(0, Math.min(0.5 + 0.015 * ((currentPathFloats?.[0] || 0) - (previousPathFloats?.[0] || 0)), 0.5)) +
		Math.max(0, Math.min(0.3 + 0.01 * ((currentPathFloats?.[1] || 0) - (previousPathFloats?.[1] || 0)), 0.3)) +
		Math.max(0, Math.min(0.2 + 0.0075 * ((currentPathFloats?.[2] || 0) - (previousPathFloats?.[2] || 0)), 0.2))
	);
}
export function slimmedCriticalPathForward(
	activityMap: Map<number, XerActivity>,
	activity: XerActivity,
	actvSuccessors: Map<string, XerTaskPredecessor[]>,
	calendars: Map<number, Calendar>
): [number, Set<XerActivity>] {
	const pathHistory = new Set<XerActivity>([activity]);
	let totalFloat = 0;
	let activityPointer: XerActivity | undefined = activity;
	let actvPointerSuccessors = actvSuccessors.get(activity.task_code);
	while (actvPointerSuccessors?.length > 0 && activityPointer?.task_type !== 'TT_FinMile') {
		let lowestNextActivity: XerActivity | undefined;
		for (const successor of actvPointerSuccessors) {
			const nextActivity = activityMap.get(successor.task_id);
			if (
				isDriving(
					successor,
					activityMap.get(successor.pred_task_id),
					activityMap.get(successor.task_id),
					calendars.get(nextActivity.clndr_id)
				)
			) {
				lowestNextActivity = nextActivity;
				break;
			}
		}
		if (lowestNextActivity && !pathHistory.has(lowestNextActivity)) {
			activityPointer = lowestNextActivity;
			pathHistory.add(lowestNextActivity);
			actvPointerSuccessors = actvSuccessors.get(activityPointer.task_code);
		} else {
			activityPointer = undefined;
			break;
		}
	}
	return [totalFloat, pathHistory];
}

export function slimmedCriticalPathReliabilityScore(args: {
	maintainedPrevCritActivities: number;
	previousCritActivities: number;
	completedPrevCritActivities: number;
	critActivities: number;
	prevDrivingTies: number;
	deletedDrivingTies: number;
	currentDrivingTies: number;
	addedDrivingTies: number;
	prevLagChanges: number;
	currentLagChanges: number;
}): number {
	const actvConsistencyScore = activityConsistencyScore(
		args.maintainedPrevCritActivities,
		args.previousCritActivities,
		args.completedPrevCritActivities,
		args.critActivities
	);
	const prevLogicScore = logicScore(args.prevDrivingTies, args.deletedDrivingTies, args.prevLagChanges);
	const currentLogicScore = logicScore(args.currentDrivingTies, args.addedDrivingTies, args.currentLagChanges);
	const consistencyScore = logicConsistencyScore(prevLogicScore, currentLogicScore);
	return (actvConsistencyScore + consistencyScore) / 2;
}

export function activityConsistencyScore(
	maintainedPrevCritActivities: number = 0,
	previousCritActivities: number = 0,
	completedPrevCritActivities: number = 0,
	numCritActivities: number
): number {
	const a = Math.max(0, maintainedPrevCritActivities - 1) / (previousCritActivities - completedPrevCritActivities || 1);
	const b = Math.max(0, maintainedPrevCritActivities - 1) / (numCritActivities || 1);
	return a * b;
}

export function logicScore(drivingTies: number = 0, changedCritActivities: number = 0, lagChanges: number): number {
	return Math.min(1, (drivingTies - changedCritActivities + lagChanges) / (drivingTies || 1));
}

export function logicConsistencyScore(prevLogicScore: number = 0, currentLogicScore: number = 0): number {
	return (prevLogicScore + currentLogicScore) / 2;
}

export function criticalPathDuration(finishMilestone: SlimmedTaskCommon, dataDate: Date): number {
	if (!finishMilestone?.early_end_date || !dataDate) {
		return 0;
	}
	return differenceInCalendarDays(finishMilestone.early_end_date, dataDate);
}
