<div class='card regular-container' style="page-break-inside: avoid">
	<kendo-gridlayout
		[rows]="(projectService.$currentProjectReport | async)?.updateIds.length > 1 ? ['42.4px', '300px', 'auto'] : ['42.4px', '150px']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
		style='page-break-inside: avoid;'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container'>
			<kendo-gridlayout
				[rows]="['42.4px']"
				[cols]="['250px', 'calc(100% - 255px)']"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header">
						Schedule Compression
					</div>
					<!--<tooltip-open type="schedule-compression"></tooltip-open>-->
				</kendo-gridlayout-item>
				<!--<kendo-gridlayout-item [row]='1' [col]='2' class='row-start-aligned-container' style='justify-content: flex-end;'>
				<span style='display: inline-flex; align-items: center;float: right' kendoTooltip>
				<button kendoButton themeColor='base' title="Export Report (XLSX)" class='header-btn upload-btn'
								(click)="exportImpactAnalysis.emit()"
								[disabled]="scheduleStorage.isLoading || !(projectService.$currentProjectReport | async)?.progressDelayHistorical"
								style='margin-right: 10px;'
								[imageUrl]='"/assets/icons/newIcons/excelExport.svg"'>
				</button>
				</span>
				</kendo-gridlayout-item>-->
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		@if ((projectService.$currentProjectReport | async)?.updateIds.length > 1) {
			<kendo-gridlayout-item [row]='2' [col]='1'>
				<div class="schedule-compression-body">
					<div style="height: 300px; margin-bottom: 3px">
						<h3>
							Update {{(projectService.$currentProjectReport | async).updateIds?.length - 1}}
						</h3>
						<div>
							<div>
								Update Compression: {{scheduleCompressionIndexArray[scheduleCompressionIndexArray.length - 1] | pct | number: '1.0-0'}}%
							</div>
							<kendo-progressbar
								[value]="scheduleCompressionIndexArray[scheduleCompressionIndexArray.length - 1] || 0"
								[max]='100'
								[label]='{visible: false}'
								[progressCssStyle]='{background: isRoundedOver100(scheduleCompressionIndexArray[scheduleCompressionIndexArray.length - 1]) ? "#FF6358FF" : "#78D237FF"}'
							></kendo-progressbar>
						</div>
						<br/>
						<br/>
						<div>
							<div>
								Baseline Compression: {{baselineCompressionIndexArray[baselineCompressionIndexArray.length - 1] | pct | number: '1.0-0'}}%
							</div>
							<kendo-progressbar
								[value]="baselineCompressionIndexArray[baselineCompressionIndexArray.length - 1] || 0"
								[max]='100'
								[label]='{visible: false}'
								[progressCssStyle]='{background: isRoundedOver100(baselineCompressionIndexArray[baselineCompressionIndexArray.length - 1]) ? "#FF6358FF" : "#78D237FF"}'
							></kendo-progressbar>
						</div>
					</div>
					<div style='height: 300px; margin-bottom: 3px'>
						<app-chart
							[categories]='categories'
							[seriesData]='seriesData'
							[legendVisible]='true'
							[valueAxis]='valueAxisItemSettings'
							[isScheduleCompressionChart]="true"
						></app-chart>
					</div>
				</div>
			</kendo-gridlayout-item>
			<kendo-gridlayout-item [row]="3" [col]="1">
				<kendo-grid
					[data]="gridData"
					class="compression-grid"
				>
					@for (col of allColumns.columns; track allColumns.columns) {
						<kendo-grid-column
							[field]="col.field"
							[title]="col.title"
							[width]="col.width"
						>
							<ng-template kendoGridCellTemplate let-dataItem *ngIf='col.type === "percentage"'>
								<span style="display: block; text-align: center; padding: 0">
									{{ (dataItem[col.field] | number : '1.0-0') + '%' }}
								</span>
							</ng-template>
							<ng-template kendoGridCellTemplate let-dataItem *ngIf='col.type === "decimal"'>
								<span style="display: block; text-align: center; padding: 0">
									{{ dataItem[col.field] | number : '1.2-2' }}
								</span>
							</ng-template>
							<ng-template kendoGridCellTemplate let-dataItem *ngIf='col.type === "number"'>
								<span style="display: block; text-align: center; padding: 0">
									{{ dataItem[col.field] | number : '1.0-0' }}
								</span>
							</ng-template>
							<ng-template kendoGridCellTemplate let-dataItem *ngIf='col.sequence === 1 || col.sequence === 2'>
								<span style="display: block; text-align: center; padding: 0">
									{{ dataItem[col.field] }}
								</span>
							</ng-template>
						</kendo-grid-column>
					}
				</kendo-grid>
			</kendo-gridlayout-item>
		} @else {
			<kendo-gridlayout-item [row]='2' [col]='1'>
				<div class='warning-div' style='height: 150px;'>
					Schedule Compression requires at least 1 update to enable
				</div>
			</kendo-gridlayout-item>
		}
	</kendo-gridlayout>
</div>
