<div class='delay-details-body' *ngIf='!hideSummary'>
	<div class='delay-grid-container'>
		<kendo-grid [data]="delayList"
								[rowClass]='rowCallback'
								#schedDelayGridSummary>
			<kendo-grid-column field="activity.code" title="Activity Code" [width]='200'> </kendo-grid-column>
			<kendo-grid-column field="activity._activity.activityName" title="Activity Name"> </kendo-grid-column>
			<kendo-grid-column field="criticalStatus" title="Critical Status" [width]='150'> </kendo-grid-column>
			<kendo-grid-column field="type" title="Issue Type" [width]='100'>
			</kendo-grid-column>
			<kendo-grid-column field="variance" title="Delay" [width]='100'>
				<ng-template kendoGridCellTemplate let-dataItem>
				{{dataItem.variance }}
			</ng-template>
			</kendo-grid-column>
			<kendo-grid-column field="previous" title="Prev Finish" [width]='120'>
				<ng-template kendoGridCellTemplate let-dataItem>
					{{dataItem.previous | date: 'MM/dd/yyyy'}}
				</ng-template>
			</kendo-grid-column>
			<kendo-grid-column field="current" title="Current Finish" [width]='120'>
				<ng-template kendoGridCellTemplate let-dataItem>
					{{dataItem.current | date: 'MM/dd/yyyy'}}
				</ng-template>
			</kendo-grid-column>
		</kendo-grid>
	</div>
</div>
<div *ngIf='hideSummary'>
	<kendo-grid [data]="delayList"
							[rowClass]='rowCallback'
							[style.maxHeight.px]="300"
							class='scheduleDelayGrid'
							[size]='"small"'
							#schedDelayGrid>
		<kendo-grid-column field="schedule" title="Schedule">
			<ng-template kendoGridCellTemplate let-dataItem>
    		<span [style.paddingLeft.px]="dataItem.updateIndex !== null ? 0 : 30">
      		{{ dataItem.schedule }}
    		</span>
			</ng-template>
		</kendo-grid-column>
		<kendo-grid-column field="dataDate" title="Data Date" class="center-align">
			<ng-template kendoGridCellTemplate let-dataItem>
				{{dataItem.dataDate | date: 'MM/dd/yyyy'}}
			</ng-template>
		</kendo-grid-column>
		<kendo-grid-column field="currentCompletion" title="Current Completion" class="center-align">
			<ng-template kendoGridCellTemplate let-dataItem>
				{{dataItem.currentCompletion | date: 'MM/dd/yyyy'}}
			</ng-template>
		</kendo-grid-column>
		<kendo-grid-column field="updateDelta" title="Update ∆" class="center-align">
			<ng-template kendoGridCellTemplate let-dataItem>
					{{dataItem.updateDelta !== null && !dataItem.isBaseline ? dataItem.updateDelta : ''}}
			</ng-template>
		</kendo-grid-column>

		<kendo-grid-column field="progressImpact" title="Progress Impact" class="no-padding center-align">
			<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
				{{dataItem.progressImpact !== null && !dataItem.isBaseline ? dataItem.progressImpact : ''}}
			</ng-template>
		</kendo-grid-column>
		<kendo-grid-column field="logicImpact" title="Logic Impact" class="no-padding center-align">
			<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
				{{dataItem.logicImpact !== null && !dataItem.isBaseline ? dataItem.logicImpact : ''}}
			</ng-template>
		</kendo-grid-column>
		<kendo-grid-excel [fileName]="projectInfo.name + ' Schedule Impact Analysis.xlsx'"></kendo-grid-excel>
	</kendo-grid>
</div>
