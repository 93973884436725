<div class="group-by-content-container">
	<div class="rows">
		<div class="content-container" [style.height]="fromPresetWindow ? 'calc(100% - 4px)' : 'calc(100% - 55px)'">
			<div class="desc" style="padding-bottom: 10px;">
				You can group activities by WBS or by Activity Codes
			</div>
			<div class="radio">
				<kendo-radiobutton
					#wbs
					value="wbs"
					[(ngModel)]="ganttService.radioSelection"
					(checkedChange)="radioChoiceChanged($event, 'wbs')"
				></kendo-radiobutton>
				<kendo-label
					class="k-radio-label grouping-option-label"
					[for]="wbs"
					text="WBS"
					style="margin-right: 25px;"
				></kendo-label>
				<kendo-radiobutton
					#actvCodes
					value="actvCodes"
					[(ngModel)]="ganttService.radioSelection"
					(checkedChange)="radioChoiceChanged($event, 'actvCodes')"
				></kendo-radiobutton>
				<kendo-label
					class="k-radio-label grouping-option-label"
					[for]="actvCodes"
					text="Activity Codes"
				></kendo-label>
				@if (fromPresetWindow && ganttService.radioSelection !== null) {
					<button size="small" kendoButton [svgIcon]="icons.x" (click)="resetGroupBy()" style="margin-left: 20px; margin-top: 0px; padding: 0px; border-color: rgba(0, 0, 0, 0.24); background-color: rgba(133, 133, 133, 0.06);"></button>
				}
			</div>
			@if (ganttService.radioSelection !== null) {
				<div class="checkbox-row">
					<kendo-checkbox #summaryBarCheckbox [checkedState]="ganttService.hideSummaryBars" (checkedStateChange)="ganttService.checkedStateChange($event)"></kendo-checkbox>
					<kendo-label
						class="k-checkbox-label"
						[for]="summaryBarCheckbox"
						text="Hide Summary Bars"
					></kendo-label>
				</div>
			}
			@if (ganttService.radioSelection === 'wbs') {
				<div class="dropdown-container">
					<div class="dropdown-label">
						Through:
					</div>
					<kendo-dropdownlist
						[data]="ganttService.allWbsLevels"
						valueField='value'
						textField='text'
						(valueChange)='ganttService.updateWbsLevelSelection($event)'
						[(ngModel)]="ganttService.selectedWbsLevel"
						[itemDisabled]="itemDisabled"
						class='dropdown'
					></kendo-dropdownlist>
				</div>
				<div class="adjustment-row" style="overflow-y: auto; height: 420px;">
					<kendo-treeview
						kendoTreeViewExpandable
						[nodes]="ganttService.wbsTreeData"
						textField="wbs_name"
						[children]="fetchChildren"
						[hasChildren]="hasChildren"
						[isExpanded]="isExpanded"
						(collapse)="handleCollapse($event)"
						(expand)="handleExpand($event)"
					>
						<ng-template kendoTreeViewNodeTemplate let-dataItem>
							<span style="padding: 0 2px; border-radius: 4px;" [class.not-included]="ganttService.selectedWbsLevel?.value !== 0 && ganttService.selectedWbsLevel?.value !== null && dataItem?.level > ganttService.selectedWbsLevel?.value">
								{{ dataItem.level + ' - ' + dataItem.wbs_name }}
							</span>
						</ng-template>
					</kendo-treeview>
				</div>
			} @else if (ganttService.radioSelection === 'actvCodes') {
				<div class="desc" style="font-size: 12px;">
					Drag & Drop or click the arrow buttons to add Activity Codes to the Group By Panel or reorder them within the panel or click the X button to remove codes from the selection.
				</div>
				<div class="adjustment-row">
					<kendo-gridlayout
						[rows]="['100%']"
						[cols]="['calc(50% - 5px)', 'calc(50% - 5px)']"
						[gap]="{ rows: 0, cols: 10 }"
						class='grid'
						style="height: 100%; width: 100%;"
						[style.padding-bottom]="fromPresetWindow ? '16px' : '0'"
						id="optionContainer"
						#optionContainer
					>
						<kendo-gridlayout-item [row]='1' [col]='1' class="gridlayout-container">
							<div class="panel-title">
								Options
							</div>
							<div class="options-list">
								@for (type of currentGroupByOptions; track type) {
									@if(type.activitiesUnderCode !== undefined && type?.activitiesUnderCode > 0) {
										<div kendoDragTarget class="option" (onDragStart)="handleDragStart($event, type)">
											<div class="label" style="width: calc(100% - 22px)">
												{{type?.actv_code_type}} {{type?.scope !== undefined ? '(' + type?.scope + ')' : ''}}
											</div>
											<span class="btn-row">
												<button kendoButton themeColor="base" title="Move Up" [svgIcon]="icons.arrowRight" (click)='moveItem(type, "in")' class="itemBtn"></button>
											</span>
										</div>
									} @else {
										<span style='display: inline-flex; align-items: center;float: right' kendoTooltip>
											<div class="option disabled-type" title="No Activities Assigned">
												<div class="label" style="width: calc(100% - 22px)">
													{{type?.actv_code_type}}
												</div>
												<span class="btn-row">
													<button kendoButton themeColor="base" title="Move Up" [svgIcon]="icons.arrowRight" [disabled]="true" class="itemBtn"></button>
												</span>
											</div>
										</span>
									}
								}
							</div>
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]='1' [col]='2' class="gridlayout-container">
							<div class="panel-title">
								Group By
							</div>
							<div class="options-list" kendoDropTarget (onDrop)="dropOnPane($event)">
								@for (item of ganttService.selectedGroupBy; track item) {
									<div kendoDropTarget class="option" (onDrop)="handleDrop($event, item)">
										<span
											style="width: calc(100% - 66px); display: flex; align-items: center;"
											kendoDragTarget
											(onDragStart)="handleDragStart($event, item, true)"
										>
											{{item?.actv_code_type}}
										</span>
										<span class="btn-row">
											<button kendoButton themeColor="base" title="Move Up" [svgIcon]="icons.arrowUp" (click)='moveItem(item, "up")' class="itemBtn" [disabled]="ganttService.selectedGroupBy[0]?.actv_code_type_id === item?.actv_code_type_id"></button>
											<button kendoButton themeColor="base" title="Move Down" [svgIcon]="icons.arrowDown" (click)='moveItem(item, "down")' class="itemBtn" [disabled]="ganttService.selectedGroupBy[ganttService.selectedGroupBy?.length - 1]?.actv_code_type_id === item?.actv_code_type_id"></button>
											<button kendoButton themeColor="base" title="Deselect" [svgIcon]="icons.close" (click)='deselectItem(item)' class="itemBtn"></button>
										</span>
									</div>
								}
							</div>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				</div>
			}
		</div>
		@if (!fromPresetWindow) {
			<div style='padding: 5px 5px 0 5px; display: flex; justify-content: center; background-color: #c8cad6;'>
				<button style='margin-right: 8px;' kendoButton themeColor="error" title="Reset" [disabled]='ganttService.selectedGroupBy?.length === 0 && ganttService.selectedWbsLevel?.value === 0' (click)='resetGroupBy()'>Reset</button>
				<button kendoButton themeColor="success" title="Apply" [disabled]='!unsavedChanges || ganttService.loading'>Apply</button>
				<!-- (click)='applyGroupBy()' -->
			</div>
		}
	</div>
</div>
