import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AxisSettings, SeriesDataSettings } from '../../../../models/ChartSettings';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CashFlow } from '../../../../models/ProjectReport/ProjectReport';
import { hasObjChanged } from '../../../../util/projects';
import { CostService } from '../../../../services/common/cost.service';
import { PlotBand } from '@progress/kendo-angular-charts';

@Component({
	selector: 'app-schedule-index-trending',
	templateUrl: './schedule-index-trending.component.html',
	styleUrls: ['./schedule-index-trending.component.scss'],
})
export class ScheduleIndexTrendingComponent implements OnInit {
	@Input() isOverview: boolean = false;
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	categories: string[] = [];
	noData = false;
	seriesData: SeriesDataSettings[] = [];
	valueAxisItemSettings: AxisSettings[] = [
		{
			title: {
				text: 'Periodic Score',
				visible: true,
			},
			labels: {
				format: '{0:n2}',
			},
			name: 'periodicScore',
		},
		{
			title: {
				text: 'Cumulative Score',
				visible: true,
			},
			labels: {
				format: '{0:n2}',
			},
			name: 'cumulativeScore',
		},
	];
	currentMonth: string = '';
	categoryPlotBands: PlotBand[] = [];

	constructor(
		public projectService: ProjectDashboardService,
		public costService: CostService
	) {}

	ngOnInit() {
		this.projectService.$currentProjectReport
			.pipe(takeUntil(this._unsubscribeAll), debounceTime(100))
			.subscribe((report) => {
				if (!report?.cashFlowHistorical) {
					return;
				}
				const currentMonthSplit: string[] = report?.projectOverview?.dataDate.toString().split(' ');
				this.currentMonth = currentMonthSplit?.length > 2 ? currentMonthSplit[1] + ' ' + currentMonthSplit[3] : '';
				this.updateChartData(report?.cashFlowHistorical);
			});
	}

	/**
	 * updates chart values with new ones
	 * @param costs
	 */
	updateChartData(costs: CashFlow[]): void {
		const spiData = this.costService.generateSPIData(costs);
		this.noData = spiData === undefined;
		if (spiData) {
			const seriesData: SeriesDataSettings[] = [
				{
					type: 'column',
					data: spiData.spiPeriodic,
					name: 'Periodic SPI',
					visible: true,
					color: 'rgb(138, 138, 138)',
					yAxis: 'periodicScore',
					legendItem: {
						type: 'area',
						markers: {
							visible: false,
						},
						highlight: {
							visible: false,
						},
					},
				},
				{
					type: 'line',
					data: spiData.spiCumulative,
					name: 'Cumulative SPI',
					visible: true,
					color: 'black',
					legendItem: {
						type: 'line',
						markers: {
							visible: false,
						},
						highlight: {
							visible: false,
						},
					},
					yAxis: 'cumulativeScore',
				},
			];
			if (hasObjChanged(this.seriesData, seriesData)) {
				this.seriesData = seriesData;
			}
			if (hasObjChanged(spiData.categories, this.categories)) {
				this.categories = spiData.categories;
			}
			const plotBands: PlotBand[] = [];
			if (this.currentMonth !== '') {
				const index: number = this.categories.findIndex((i) => i === this.currentMonth);
				if (index !== -1) {
					plotBands.push({
						color: '#c8ddeb',
						from: index,
						opacity: 1,
					});
				}
			}
			this.categoryPlotBands = plotBands;
		}
	}
}
