import { AppComponent } from 'components/app.component';
import * as Sentry from '@sentry/angular-ivy';
import {
	CUSTOM_ELEMENTS_SCHEMA,
	APP_INITIALIZER,
	NgModule,
	ApplicationRef,
	ErrorHandler,
	ApplicationInitStatus,
} from '@angular/core';
import { Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { RestService } from '../services/common/rest.service';
import { Oauth2CallbackComponent } from './authentication/oauth2-callback/oauth2-callback.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { CommonModule } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './authentication/auth.module';
import { MaintenanceBarComponent } from './maintenance-bar/maintenance-bar.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ICON_SETTINGS, IconsModule } from '@progress/kendo-angular-icons';
import { GridModule } from '@progress/kendo-angular-grid';
import { FilterModule } from '@progress/kendo-angular-filter';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { UploadsModule } from '@progress/kendo-angular-upload';

import { DialogsModule } from '@progress/kendo-angular-dialog';
import { GaugesModule } from '@progress/kendo-angular-gauges';
import { RhinoworksModule } from '../@rhinoworks/rhinoworks.module';
import { FileUploadService } from '../services/common/fileUpload.service';
import { GanttModule } from '@progress/kendo-angular-gantt';
import { TooltipMenuModule } from './portfolio/tooltip-menu/tooltip-menu.module';
import { GlobalTooltipMenuModule } from './portfolio/global-toolip-menu/global-tooltip-menu.module';
import { NewProjectModule } from './portfolio/new-project/new-project.module';
import { PopupModule } from '@progress/kendo-angular-popup';
import { AddBulkUpdateModule } from './project-page/top-row/add-bulk-update/add-bulk-update.module';
import { ProfileModule } from './portfolio/profile/profile.module';
import { TypographyModule } from '@progress/kendo-angular-typography';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { UpdateReportModule } from './project-page/schedule-updates-list/update-report/update-report.module';
import { DebugComponent } from './shared/debug/debug.component';
import { PortfolioModule } from './portfolio/portfolio.module';
import { ProjectModule } from './project-page/project.module';
import { ScheduleUpdatesListModule } from './project-page/schedule-updates-list/schedule-updates-list.module';
import { FormsModule } from '@angular/forms';

@NgModule({
	declarations: [AppComponent, Oauth2CallbackComponent, MaintenanceBarComponent, DebugComponent],
	imports: [
		CommonModule,
		TranslateModule.forRoot(),
		IonicModule.forRoot({ mode: 'md' }),
		OAuthModule.forRoot(),
		BrowserAnimationsModule,
		HttpClientModule,
		BrowserModule,
		AppRoutingModule,
		AuthModule,
		LayoutModule,
		ButtonsModule,
		DropDownsModule,
		InputsModule,
		LabelModule,
		DateInputsModule,
		IconsModule,
		GridModule,
		FilterModule,
		IndicatorsModule,
		TooltipsModule,
		NotificationModule,
		UploadsModule,
		DialogsModule,
		GaugesModule,
		GanttModule,
		RhinoworksModule,
		TooltipMenuModule,
		GlobalTooltipMenuModule,
		NewProjectModule,
		PopupModule,
		AddBulkUpdateModule,
		ProfileModule,
		TypographyModule,
		PDFExportModule,
		UpdateReportModule,
		PortfolioModule,
		ProjectModule,
		ScheduleUpdatesListModule,
		FormsModule,
	],
	bootstrap: [AppComponent],
	providers: [
		RestService,
		ApplicationRef,
		ErrorHandler,
		ApplicationInitStatus,
		Platform,
		IonicModule,
		HttpClient,
		FileUploadService,
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		{ provide: ICON_SETTINGS, useValue: { type: 'svg' } },
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
