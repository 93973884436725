import { catchError, concatMap, delay, finalize, flatMap, map, retryWhen, tap } from 'rxjs/operators';
import { Observable, Observer, of, throwError, Subscriber, from } from 'rxjs';
import { Injectable } from '@angular/core';
import { Cookie } from './cookie';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { AuthenticationService } from '../auth/authentication.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { AccountUser } from '../../models/auth/account-user';

enum RequestMethod {
	Get = 'GET',
	Post = 'POST',
	Put = 'PUT',
	Delete = 'DELETE',
	Options = 'OPTIONS',
	Head = 'HEAD',
	Patch = 'PATCH',
}

/**
 * This class provides the NameList service with methods to read names and add names.
 */

@Injectable({
	providedIn: 'root',
})
export class RestService {
	restHeaders: HttpHeaders;
	restOptions: any;
	baseApi = '';
	oauthConnected: boolean = true;

	/**
	 * Creates a new NameListService with the injected Http.
	 */
	constructor(
		private httpClient: HttpClient,
		private alertCtrl: AlertController,
		private authService: AuthenticationService,
		private router: Router,
		private oauthService: OAuthService
	) {
		this.restHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
	}

	public fetchAccount(path: string, search?: any, absoluteUrl?: boolean, body?: any, isFile?: any): Observable<any> {
		let user = JSON.parse(localStorage.getItem('currentUserCredentials')) as AccountUser;
		const options = {
			headers: {
				'Content-Type': !isFile ? 'application/json' : 'text/plain',
				Authorization: 'Bearer ' + user.access_token,
			},
			body,
		};
		return this.httpClient.get('https://account.rhino.works/' + path, options);
	}

	public getAccount(path: string, responseType: any = 'json'): Observable<any> {
		let user = JSON.parse(localStorage.getItem('currentUserCredentials')) as AccountUser;
		const options = {
			headers: {
				Authorization: 'Bearer ' + user.access_token,
			},
			responseType: responseType,
		};
		return this.httpClient.get('https://account.rhino.works/' + path, options);
	}

	public postAccountXerFetch(path: string, body: any, responseType: any = 'json'): Observable<any> {
		let user = JSON.parse(localStorage.getItem('currentUserCredentials')) as AccountUser;
		const options = {
			headers: {
				Authorization: 'Bearer ' + user.access_token,
			},
			responseType: responseType,
		};
		return this.httpClient.post('https://account.rhino.works/' + path, body, options);
	}

	public postAccount(path: string, search?: any, absoluteUrl?: boolean, body?: any, isFile?: any): Observable<any> {
		let user = JSON.parse(localStorage.getItem('currentUserCredentials')) as AccountUser;
		const options = {
			headers: {
				'Content-Type': !isFile ? 'application/json' : 'text/plain',
				Authorization: 'Bearer ' + user.access_token,
			},
		};
		return this.httpClient.post('https://account.rhino.works/' + path, body, options);
	}

	/**
	 * Returns an Observable for the HTTP GET request for the JSON resource.
	 */
	public fetch(path: string, search?: any, absoluteUrl?: boolean, body?: any, isFile?: any): Observable<any> {
		return this.request(path, RequestMethod.Get, body, search, absoluteUrl, isFile);
	}

	/**
	 * Returns an Observable for the HTTP POST request for the JSON resource.
	 */
	public post(path: string, body: any, absoluteUrl?: boolean): Observable<any> {
		return this.request(path, RequestMethod.Post, body, undefined, absoluteUrl);
	}

	/**
	 * Returns an Observable for the HTTP PUT request for the JSON resource.
	 */
	public put(path: string, body: any, absoluteUrl?: boolean): Observable<any> {
		return this.request(path, RequestMethod.Put, body, undefined, absoluteUrl);
	}

	/**
	 *  Returns an Observable for the HTTP PATCH request for the JSON resource.
	 */
	public patch(path: string, body: any, absoluteUrl?: boolean): Observable<any> {
		return this.request(path, RequestMethod.Patch, body, undefined, absoluteUrl);
	}

	/**
	 * Returns an Observable for the HTTP DELETE request for the JSON resource.
	 */
	public delete(path: string, absoluteUrl?: boolean, body?: any): Observable<any> {
		return this.request(path, RequestMethod.Delete, body, undefined, absoluteUrl);
	}

	/**
	 * Returns an Observable for the HTTP FILE POST request for the JSON resource.
	 */
	public postFile(path: string, body: any, absoluteUrl?: boolean): Observable<any> {
		return this.request(path, RequestMethod.Post, body, undefined, absoluteUrl, true);
	}

	public postFileWithProgress(path: string, body: any, options: any): Observable<any> {
		const headers = new HttpHeaders();
		const _token = Cookie.get('_token');
		if (_token) {
			headers.set('Authorization', `${_token}`);
		}
		options['headers'] = headers;
		return this.httpClient.post(path, body, options);
	}

	/**
	 * Serialize search object to params
	 */
	public serializeSearch(obj: any): URLSearchParams {
		const params = new URLSearchParams();
		for (const p in obj) {
			if (obj.hasOwnProperty(p)) {
				params.set(encodeURIComponent(p), encodeURIComponent(obj[p]));
			}
		}
		return params;
	}

	public getRequestUrl(path: string): string {
		// return `${window.location.protocol}//${window.location.host}` + (path ? path : ``);
		return `${environment.baseURL}` + (path ? path : ``);
	}

	public getParentDomainRequestUrl(path: string): string {
		const host = window.location.hostname === 'localhost' ? window.location.host : `${this.getHost().host}`;
		return `${window.location.protocol}//${host}` + (path ? path : ``);
	}

	public getHost() {
		if (window.location.hostname !== 'localhost') {
			const rawHost = window.location.host.split('.');
			const hosts: any = [];
			const nonHosts: any = [];
			rawHost.forEach((innerHost: any, index: number) => {
				if (index >= rawHost.length - 2) {
					hosts.push(innerHost);
				} else if (index === rawHost.length - 3) {
					hosts.push(innerHost);
				} else {
					nonHosts.push(innerHost);
				}
			});
			return {
				host: hosts.join('.'),
				nonHost: nonHosts.join('.'),
			};
		} else {
			return {
				host: window.location.host,
				nonHost: '',
			};
		}
	}

	/**
	 * Actual rest request based on params
	 */
	private request(
		path: string,
		method: string,
		body?: any,
		search?: any,
		absoluteUrl?: boolean,
		isFile?: boolean,
		header?: HttpHeaders
	): Observable<any> {
		const url = absoluteUrl ? path : this.getRequestUrl(path);
		const _token = this.authService.getHeader();
		//console.log('_token', _token);

		const options = {
			body: !isFile ? JSON.stringify(body) : body,
			headers: {
				'Content-Type': !isFile ? 'application/json' : 'text/plain',
				Authorization: _token.accessToken,
				'X-ID-Token': _token.idToken,
			},
			responseType: isFile ? ('blob' as 'blob') : ('json' as 'json'), // I love Typescript!
		};
		this.httpClient.request(method, url, options).pipe(
			map((res: any) => {
				if (res.status >= 201 && res.status <= 226) {
					return null;
				} else {
					return res;
				}
			}),
			retryWhen((retryError: any) => {
				const retryCount = 2;

				return retryError.pipe(
					concatMap((error: any, count) => {
						if (count < retryCount && error.status === 401) {
							console.log('Retry ' + count);
							return of(error);
						}
						console.log('error : ', error, options);
						this.handleError(error);
						return null;
						//this.oauthService.loadDiscoveryDocumentAndLogin();
						//return throwError(error);
					}),
					delay(1500)
				);
			})
		);
		return this.httpClient.request(method, url, options).pipe(
			map((res: any) => {
				if (res.status >= 201 && res.status <= 226) {
					return null;
				} else {
					return res;
				}
			}),
			retryWhen((retryError: any) => {
				const retryCount = 2;

				return retryError.pipe(
					concatMap((error: any, count) => {
						if (count < retryCount && error.status === 401) {
							console.log('Retry ' + count);
							return of(error);
						}
						console.log('error : ', error, options);
						this.handleError(error);
						return null;
						//this.oauthService.loadDiscoveryDocumentAndLogin();
						//return throwError(error);
					}),
					delay(1500)
				);
			})
		);
	}

	logout(): Observable<any> {
		return new Observable<any>((observer: any) => {
			// clear token remove user from local storage to log user out
			this.removeAuthCookie();
			observer.next();
		});
	}

	removeAuthCookie() {
		const host = window.location.hostname === 'localhost' ? '' : `.${this.getHost().host}`;
		//localStorage.removeItem('_activeUser');
		Cookie.delete('_token', '/', host);
		Cookie.delete('_google_token', '/', host);
		Cookie.delete('_host', '/', host);
		Cookie.deleteAll('/', host || 'localhost');
	}

	/**
	 * Handle HTTP error
	 */
	async handleError(error: any) {
		// We'd also dig deeper into the error to get a better message
		let errMsg = error.status ? `${error.status} - ${error.statusText || error.message}` : 'Server error';
		if (error._body) {
			try {
				const json = error.json();
				if (json.message) {
					errMsg = json.message;
				} else {
					errMsg = json;
				}
			} catch (err) {}
		}
		if (error.status === 401) {
			if (environment.production) {
				if (this.authService.isAuthTokenExpired()) {
					window.location.href = 'https://account.rhino.works/onboarding/analytics/';
					console.log('We would href here.');
				} else {
					console.log('authToken401', 'you are being ridiculous james.');
				}
			} else {
				if (this.authService.isAuthTokenExpired()) {
					this.oauthService.loadDiscoveryDocumentAndLogin();
					console.log('We would oauth here.');
				} else {
					console.log('authToken401', 'you are being ridiculous james.');
					return true;
				}
			}
			// this.authService.localLogout().subscribe(logout => {
			//     this.router.navigate(['/auth/login']);
			//     // window.location.href = this.getRequestUrl('/auth/login');
			// });
		} else if (error.status === 403) {
			const alert = await this.alertCtrl.create({
				buttons: ['OK'],
				message: 'Internal error!',
				header: 'Oops!',
			});
			await alert.present();
			window.location.href = this.getRequestUrl('/dashboard');
		}
		// else {
		//   this.amToasterService.show('error', errMsg || 'Internal error!', 'Oops!');
		// }
		return throwError(errMsg);
	}

	uploadS3File(url: string, fileName: string, type: string, file: Blob): Promise<any> {
		const blobToFile = (theBlob: Blob, subFileName: string): File =>
			new File([theBlob], subFileName, { type: type, lastModified: Date.now() });
		const fileFromBlob = blobToFile(file, fileName);
		const headers = new HttpHeaders({
			'x-amz-acl': 'public-read',
			'Content-Type': type,
			'Access-Control-Allow-Origin': '*',
		});
		const options = {
			headers,
			responseType: 'text' as 'text',
		};
		return this.httpClient.put(url, fileFromBlob, options).toPromise();
	}

	postToExporter(schedule: string, u: any) {
		return this.httpClient.post('https://xls.services.analytics.rhinoworks.dev/export/' + schedule, u, {
			responseType: 'blob',
			headers: new HttpHeaders().append('Content-Type', 'application/json'),
		});
	}
}
