<div class='card' style='page-break-inside: avoid;'>
	<kendo-gridlayout
		[rows]="['42.4px', 'auto']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
		[style.margin]="isOverview ? 'auto 0' : '0'"
		style="height: 100%;"
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
			<div class="card-header">
				Progress Trending
			</div>
			<tooltip-open type='{{isOverview ? "overview" : "progress-trending"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1' style='page-break-inside: avoid'>
			<div class='progress-trending-body' *ngIf='(projectService.$currentProjectReport | async)?.updateIds?.length > 1'>
				<div style="height: 300px;">
					<h3>
						Update {{(projectService.$currentProjectReport | async).updateIds?.length - 1}}
					</h3>
					<div>
						<div>
							Planned Progress: {{plannedValues[plannedValues.length - 1] | pct | number: '1.0-0'}}%
						</div>
						<kendo-progressbar
							[value]="plannedValues[plannedValues.length - 1] || 0"
							[max]='100'
							[label]='{visible: false}'
							[progressCssStyle]='{background: "#808388"}'
						></kendo-progressbar>
					</div>
					<br/>
					<br/>
					<div>
						<div>
							Actual Progress: {{actualValues[actualValues.length - 1] | pct | number: '1.0-0'}}%
						</div>
						<kendo-progressbar
							[value]="actualValues[actualValues.length - 1] || 0"
							[max]='100'
							[label]='{visible: false}'
							[progressCssStyle]='{background: ($roundedActual | async) >= ($roundedPlanned | async) ? "#4fc931e6" : "#df5353e6"}'
						></kendo-progressbar>
					</div>
				</div>
				@if (!hideTrending) {
					<div class='chart-container'>
						<kendo-chart style='height: 300px;'>
							<kendo-chart-tooltip [shared]="true">
								<ng-template
									kendoChartSharedTooltipTemplate
									let-category="category"
									let-points="points"
								>
									<div><b>{{ category }}</b></div>
									<div *ngFor="let point of points">
										{{ point.series.name }} : <b>{{ point.value | number: '1.0-0' }}%</b>
									</div>
								</ng-template>
							</kendo-chart-tooltip>
							<kendo-chart-legend position="bottom" orientation="horizontal">
							</kendo-chart-legend>
							<kendo-chart-title text="Planned vs. Actual Progress Trend"></kendo-chart-title>
							<kendo-chart-category-axis>
								<kendo-chart-category-axis-item
									[categories]="categories"
									[majorGridLines]='{visible: false}'
									[labels]="{ rotation: 'auto' }"
								>
								</kendo-chart-category-axis-item>
							</kendo-chart-category-axis>
							<kendo-chart-value-axis>
								<kendo-chart-value-axis-item
									[min]='0'
									[max]='100'
									[labels]='{format: "{0}%"}'
									pane="pane"
								></kendo-chart-value-axis-item>
							</kendo-chart-value-axis>
							<kendo-chart-panes>
								<kendo-chart-pane name="pane" clip="false">
								</kendo-chart-pane>
							</kendo-chart-panes>
							<kendo-chart-series>
								<kendo-chart-series-item
									type="line"
									[data]="plannedValues"
									name='Planned Progress'
									color='#808388'
									[legendItem]="{type: 'line', markers: { visible: false, }, highlight: { visible: false }}"
								>
								</kendo-chart-series-item>
								<kendo-chart-series-item
									type="line"
									[data]="actualValues"
									name='Actual Progress'
									color='black'
									[legendItem]="{type: 'line', markers: { visible: false, }, highlight: { visible: false }}"
								>
								</kendo-chart-series-item>
							</kendo-chart-series>
						</kendo-chart>
					</div>
				}
			</div>
			@if ((projectService.$currentProjectReport | async)?.updateIds?.length === 1) {
				<div class='warning-div' [style.height]="isOverview ? '100%' : '150px'" [ngClass]="{'overview-progress-trending-warning': isOverview}">
					Progress Trending requires at least 1 update to enable
				</div>
			}
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>
