<kendo-gridlayout
	[rows]="hideTrending ? [(project.$currentProjectReport | async)?.updateIds?.length <= 1 ? '192.4px' : '380.6px'] : [(project.$currentProjectReport | async)?.updateIds?.length <= 1 ? '192.4px' : '380.6px', (project.$currentProjectReport | async)?.updateIds?.length <= 1 ? '192.4px' : '345.4px']"
	[cols]="['100%']"
	[gap]="{ rows: 5, cols: 0 }"
	class='full-height full-width'
	id='activity-completion-chart-anchor'
	[style]=' (visualizer) ? (((project.$currentProjectReport | async)?.milestones?.milestoneArray?.length !== 0) ? "counter: vis-3page;  page-break-inside: avoid; margin-top: 0.5em;" : "counter: vis-2page; page-break-inside: avoid; margin-top: 0.5em;") : ""'
>
	<kendo-gridlayout-item [row]='1' [col]='1'>
		<div class='card' style='break-inside: avoid;'>
			<kendo-gridlayout
				[rows]="['42.4px', (project.$currentProjectReport | async)?.updateIds?.length <= 1 ? '150px' : '338.2px']"
				[cols]="['100%']"
				[gap]="{ rows: 0, cols: 0 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
					<kendo-gridlayout
						[rows]="['42.4px']"
						[cols]="isOverview ? ['100%'] : ['220px', 'calc(100% - 225px)']"
						[gap]="{ rows: 0, cols: 5 }"
						class='full-width'
					>
						<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
							<div class="card-header">
								Activity Completion
							</div>
							<tooltip-open type='{{isOverview ? "overview" : "activity-completion"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
						</kendo-gridlayout-item>
						@if (!isOverview) {
							<kendo-gridlayout-item [row]='1' [col]='2' class='toolbar-item-container' style='gap: 10px;'>
								<kendo-multiselecttree
									#activityCodesMultiselectTree
									kendoMultiSelectTreeSummaryTag
									kendoMultiSelectTreeExpandable
									[kendoMultiSelectTreeHierarchyBinding]="allActivityCodes"
									[(value)]="selectedActivityCodes"
									textField="name"
									valueField="id"
									childrenField='subCodes'
									[valuePrimitive]='false'
									[filterable]='true'
									(valueChange)='filterChanged($event);'
									[clearButton]='false'
									[expandOnFilter]="{expandMatches: true}"
									[itemDisabled]="itemDisabled"
									[placeholder]='"Activity Code Filter"'
									style='width: 200px; margin: auto 0; cursor: pointer;'
									[disabled]='((project.$currentProjectData | async)?.updateIds.length || 0) <= 1'
								>
									<ng-template kendoMultiSelectTreeGroupTagTemplate let-dataItems>
									<span>
										<kendo-svg-icon [icon]='icons.caretDown'></kendo-svg-icon>
										{{ codesTag }}
									</span>
									</ng-template>
								</kendo-multiselecttree>
								<kendo-buttongroup width='100%' selection="single" class='btn-group' style='height: 34px; margin: auto 0;'>
									<button
										*ngFor="let button of forwardOrBackwardButtons"
										kendoButton
										[toggleable]="true"
										[selected]="button.selected"
										(selectedChange)="selectionChange($event, button, false)"
										class='item-btn'
										[class.selected-item-btn]='button.selected'
										[disabled]='((project.$currentProjectData | async)?.updateIds.length || 0) <= 1'
									>
										<span>
											{{ button.text }}
										</span>
									</button>
								</kendo-buttongroup>
								<div class='btngroup-label' style='margin: auto 0;'>
									Lookahead (Days):
								</div>
								<span class='slider-tooltips' kendoTooltip style='margin: auto 0;'>
									<kendo-buttongroup width='100%' selection="single" class='btn-group' [title]='isLookahead || ((project.$currentProjectData | async)?.updateIds.length || 0) <= 1 ? "" : "Select Planned to edit"'>
										<button
											*ngFor="let button of timespanButtons"
											kendoButton
											[toggleable]="true"
											[selected]="button.selected"
											(selectedChange)="selectionChange($event, button, true)"
											class='item-btn'
											[class.selected-item-btn]='button.selected'
											[disabled]='!isLookahead || ((project.$currentProjectData | async)?.updateIds.length || 0) <= 1'
											[class.hide-selection-on-disabled]='!isLookahead || ((project.$currentProjectData | async)?.updateIds.length || 0) <= 1'
										>
											<span>
												{{ button.text }}
											</span>
										</button>
									</kendo-buttongroup>
								</span>
								<div style='height: min-content; margin: auto 0; padding-right: 10px;'>
									<button kendoButton (click)="doExport()" *ngIf='(project.$currentProjectReport | async)?.updateIds?.length > 1 && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK"'
													[disabled]="exportProcessing" themeColor='base' class="header-btn" [imageUrl]='"/assets/icons/newIcons/excelExport.svg"' title='Export Activities'>
									</button>
									<div kendoTooltip style="text-align: center;" *ngIf='currentProjectCompanyPermissions?.license === "ANALYTICS-BASIC-MPK" || currentProjectCompanyPermissions?.license === "ANALYTICS-BASIC-APK"'>
										<div title='Upgrade to a Professional License to enable Exports'>
											<button kendoButton themeColor="base" class='header-btn' [imageUrl]='"/assets/icons/newIcons/excelExport.svg"' [disabled]='true'></button>
										</div>
									</div>
								</div>
							</kendo-gridlayout-item>
						}
					</kendo-gridlayout>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='2' [col]='1' *ngIf='(project.$currentProjectReport | async)?.updateIds?.length > 1'>
					<kendo-gridlayout
						[rows]="['100%']"
						[cols]="['calc((400% / 12) - 2.5px)', 'calc((800% / 12) - 2.5px)']"
						[gap]="{ rows: 0, cols: 5 }"
						class='full-height full-width'
					>
						<kendo-gridlayout-item [row]='1' [col]='1' style='padding: 5px 0 5px 5px'>
							<kendo-gridlayout
								[rows]="['42px', 'calc(100% - 47px)']"
								[cols]="['auto']"
								[gap]="{ rows: 5, cols: 0 }"
								class='full-height full-height'
							>
								<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-item'>
									<div class="center-row" style='background-color: #001489; border-radius: 4px;'
											 [class.selected-button]='tableView === ActvCompletionView.overview'
											 (click)='setTableView(ActvCompletionView.overview)'>
										Overview
									</div>
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]='2' [col]='1'>
									<kendo-gridlayout
										[rows]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
										[cols]="['calc(50% - 2.5px)', 'calc(50% - 2.5px)']"
										[gap]="{ rows: 5, cols: 5 }"
										class='full-height full-height'
									>
										<kendo-gridlayout-item
											[row]='1'
											[col]='1'
											class='gridlayout-item'
											[class.selected-button]='tableView === ActvCompletionView.critical'
											(click)='setTableView(ActvCompletionView.critical)'
										>
											<kendo-gridlayout
												[rows]="['35%', '65%']"
												[cols]="['auto']"
												class='full-height full-width score-red'
												[class.score-red-lookahead]='isLookahead'
											>
												<kendo-gridlayout-item [row]='1' [col]='1' class='grid-label'>
													Critical
												</kendo-gridlayout-item>
												<kendo-gridlayout-item [row]='2' [col]='1' class='grid-value' *ngIf='!isLookahead && criticalPlanned.get(lastUpdateId)?.length'>
													{{$criticalPercentComplete | async | number : '1.0-0' }}%
												</kendo-gridlayout-item>
												<kendo-gridlayout-item [row]='2' [col]='1' class='grid-value lookahead-label' *ngIf='isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySet.Critical?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet.Critical?.length : unfilteredLookahead90DisplaySet.Critical?.length)'>
													<span class='lookahead-value'>
														{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySet.Critical?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet.Critical?.length : unfilteredLookahead90DisplaySet.Critical?.length }}
													</span>
													<span class='lookahead-unit'>
														{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySet.Critical?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySet.Critical?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySet.Critical?.length === 1 ? ' Activity' : ' Activities') }}
													</span>
												</kendo-gridlayout-item>
												<kendo-gridlayout-item [row]='2' [col]='1' class='grid-value' style='font-size: 18px;' *ngIf='isLookahead ? (selectedTimespan === 30 ? !unfilteredLookahead30DisplaySet.Critical?.length : selectedTimespan === 60 ? !unfilteredLookahead60DisplaySet.Critical?.length : !unfilteredLookahead90DisplaySet.Critical?.length) : !criticalPlanned.get(lastUpdateId)?.length'>
													No Planned Activities
												</kendo-gridlayout-item>
											</kendo-gridlayout>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item
											[row]='1'
											[col]='2'
											class='gridlayout-item'
											[class.selected-button]='tableView === ActvCompletionView.nearcritical'
											(click)='setTableView(ActvCompletionView.nearcritical)'
										>
											<kendo-gridlayout
												[rows]="['35%', '65%']"
												[cols]="['auto']"
												class='full-height full-width score-green'
												[class.score-green-lookahead]='isLookahead'
											>
												<kendo-gridlayout-item [row]='1' [col]='1' class='grid-label'>
													Near Critical
												</kendo-gridlayout-item>
												<kendo-gridlayout-item [row]='2' [col]='1' class='grid-value' *ngIf='!isLookahead && nearCriticalPlanned.get(lastUpdateId)?.length'>
													{{$nearCriticalPercentComplete | async | number : '1.0-0' }}%
												</kendo-gridlayout-item>
												<kendo-gridlayout-item [row]='2' [col]='1' class='grid-value lookahead-label' *ngIf='isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySet["Near Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet["Near Critical"]?.length : unfilteredLookahead90DisplaySet["Near Critical"]?.length)'>
													<span class='lookahead-value'>
														{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySet["Near Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet["Near Critical"]?.length : unfilteredLookahead90DisplaySet["Near Critical"]?.length }}
													</span>
													<span class='lookahead-unit'>
														{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySet["Near Critical"]?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySet["Near Critical"]?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySet["Near Critical"]?.length === 1 ? ' Activity' : ' Activities') }}
													</span>
												</kendo-gridlayout-item>
												<kendo-gridlayout-item [row]='2' [col]='1' class='grid-value' style='font-size: 18px;' *ngIf='isLookahead ? (selectedTimespan === 30 ? !unfilteredLookahead30DisplaySet["Near Critical"]?.length : selectedTimespan === 60 ? !unfilteredLookahead60DisplaySet["Near Critical"]?.length : !unfilteredLookahead90DisplaySet["Near Critical"]?.length) : !nearCriticalPlanned.get(lastUpdateId)?.length'>
													No Planned Activities
												</kendo-gridlayout-item>
											</kendo-gridlayout>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item
											[row]='2'
											[col]='1'
											class='gridlayout-item'
											[class.selected-button]='tableView === ActvCompletionView.noncritical'
											(click)='setTableView(ActvCompletionView.noncritical)'
										>
											<kendo-gridlayout
												[rows]="['35%', '65%']"
												[cols]="['auto']"
												class='full-height full-width score-blue'
												[class.score-blue-lookahead]='isLookahead'
											>
												<kendo-gridlayout-item [row]='1' [col]='1' class='grid-label'>
													Non Critical
												</kendo-gridlayout-item>
												<kendo-gridlayout-item [row]='2' [col]='1' class='grid-value' *ngIf='!isLookahead && nonCriticalPlanned.get(lastUpdateId)?.length'>
													{{$nonCriticalPercentComplete | async | number : '1.0-0' }}%
												</kendo-gridlayout-item>
												<kendo-gridlayout-item [row]='2' [col]='1' class='grid-value lookahead-label' *ngIf='isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySet["Non Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet["Non Critical"]?.length : unfilteredLookahead90DisplaySet["Non Critical"]?.length)'>
													<span class='lookahead-value'>
														{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySet["Non Critical"]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet["Non Critical"]?.length : unfilteredLookahead90DisplaySet["Non Critical"]?.length }}
													</span>
													<span class='lookahead-unit'>
														{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySet["Non Critical"]?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySet["Non Critical"]?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySet["Non Critical"]?.length === 1 ? ' Activity' : ' Activities') }}
													</span>
												</kendo-gridlayout-item>
												<kendo-gridlayout-item [row]='2' [col]='1' class='grid-value' style='font-size: 18px;' *ngIf='isLookahead ? (selectedTimespan === 30 ? !unfilteredLookahead30DisplaySet["Non Critical"]?.length : selectedTimespan === 60 ? !unfilteredLookahead60DisplaySet["Non Critical"]?.length : !unfilteredLookahead90DisplaySet["Non Critical"]?.length) : !nonCriticalPlanned.get(lastUpdateId)?.length'>
													No Planned Activities
												</kendo-gridlayout-item>
											</kendo-gridlayout>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item
											[row]='2'
											[col]='2'
											class='gridlayout-item'
											[class.selected-button]='tableView === ActvCompletionView.total'
											(click)='setTableView(ActvCompletionView.total)'
										>
											<kendo-gridlayout
												[rows]="['35%', '65%']"
												[cols]="['auto']"
												class='full-height full-width score-black'
												[class.score-black-lookahead]='isLookahead'
											>
												<kendo-gridlayout-item [row]='1' [col]='1' class='grid-label'>
													Total
												</kendo-gridlayout-item>
												<kendo-gridlayout-item [row]='2' [col]='1' class='grid-value' *ngIf='!isLookahead && totalPlanned.get(lastUpdateId)?.length'>
													{{$totalPercentComplete | async | number : '1.0-0' }}%
												</kendo-gridlayout-item>
												<kendo-gridlayout-item [row]='2' [col]='1' class='grid-value lookahead-label' *ngIf='isLookahead && (selectedTimespan === 30 ? unfilteredLookahead30DisplaySet.Total?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet.Total?.length : unfilteredLookahead90DisplaySet.Total?.length)'>
													<span class='lookahead-value'>
														{{ selectedTimespan === 30 ? unfilteredLookahead30DisplaySet.Total?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet.Total?.length : unfilteredLookahead90DisplaySet.Total?.length }}
													</span>
													<span class='lookahead-unit'>
														{{ selectedTimespan === 30 ? (unfilteredLookahead30DisplaySet.Total?.length === 1 ? ' Activity' : ' Activities') : selectedTimespan === 60 ? (unfilteredLookahead60DisplaySet.Total?.length === 1 ? ' Activity' : ' Activities') : (unfilteredLookahead90DisplaySet.Total?.length === 1 ? ' Activity' : ' Activities') }}
													</span>
												</kendo-gridlayout-item>
												<kendo-gridlayout-item [row]='2' [col]='1' class='grid-value' style='font-size: 18px;' *ngIf='isLookahead ? (selectedTimespan === 30 ? !unfilteredLookahead30DisplaySet.Total?.length : selectedTimespan === 60 ? !unfilteredLookahead60DisplaySet.Total?.length : !unfilteredLookahead90DisplaySet.Total?.length) : !totalPlanned.get(lastUpdateId)?.length'>
													No Planned Activities
												</kendo-gridlayout-item>
											</kendo-gridlayout>
										</kendo-gridlayout-item>
									</kendo-gridlayout>
								</kendo-gridlayout-item>
							</kendo-gridlayout>
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]='1' [col]='2' style='padding: 5px 5px 5px 0'>
							<app-chart
								[title]='isLookahead ? (selectedTimespan === 30 ? "30 Day Planned Lookahead" : selectedTimespan === 60 ? "60 Day Planned Lookahead" : "90 Day Planned Lookahead") : "Last Period"'
								[categories]='categories'
								[seriesData]='isLookahead ? (selectedTimespan === 30 ? lookahead30SeriesData : selectedTimespan === 60 ? lookahead60SeriesData : lookahead90SeriesData) : seriesData'
								[legendVisible]='false'
								[valueAxis]='valueAxisItemSettings'
								[isActivityCompletionChart]='true'
								*ngIf='tableView === "overview"'
							></app-chart>
							<div *ngIf='tableView !== "overview"'>
								<div>
									<kendo-textbox
										class='searchInput'
										[placeholder]="'Search ' + tableView + '...'"
										[clearButton]="true"
										[size]='"large"'
										[(value)]='searchTerm'
										(valueChange)='updateFilter({searchTerm: $event})'
										[disabled]='loading'
									>
										<ng-template kendoTextBoxPrefixTemplate>
											<kendo-svgicon [icon]='svgSearch'></kendo-svgicon>
											<kendo-textbox-separator></kendo-textbox-separator>
										</ng-template>
									</kendo-textbox>
									<div style="text-align: center; font-size: large; padding: 5px 0;">
										<span>{{ tableView }} - Showing {{ currentDisplaySet.length || 0 }} of {{ (isLookahead ? (selectedTimespan === 30 ? unfilteredLookahead30DisplaySet[tableView]?.length : selectedTimespan === 60 ? unfilteredLookahead60DisplaySet[tableView]?.length : unfilteredLookahead90DisplaySet[tableView]?.length) : unfilteredDisplaySet[tableView]?.length) || 0 }}</span>
									</div>
									<kendo-grid
										[data]="gridView"
										[kendoGridBinding]="gridData"
										[height]="264"
										[loading]='loading'
										[size]='"small"'
										[sortable]='true'
										[sort]="sort"
										(sortChange)="sortChange($event)"
										[skip]="skip"
										[pageSize]="pageSize"
										[navigable]="false"
										scrollable="virtual"
										[rowHeight]="26"
										class='activity-grid'
									>
										<kendo-grid-column
											*ngFor='let column of selectedColumns'
											[field]="column.field"
											[title]="column.title"
											[width]='column.width'
											[format]="column?.format"
											headerClass="header-class"
										>
											@if (column?.type === 'actIfPossible') {
												<!--See comment in fitDataToType function for explanation on this change below-->
												<ng-template kendoGridCellTemplate let-dataItem>
													{{dataItem[column.otherField] === null ? (dataItem[column.field] | date: 'MM/dd/yyyy') + ' A' : (dataItem[column.field] | date: 'MM/dd/yyyy')}}
												</ng-template>
											}
										</kendo-grid-column>
									</kendo-grid>
								</div>
								<div style='width: 100%;text-align: center;height: 350px; display: flex;justify-content: center; align-items: center'>
									Project is missing previous expanded metrics. Try recalculating report!
								</div>

							</div>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='2' [col]='1' *ngIf='(project.$currentProjectReport | async)?.updateIds?.length <= 1'>
					<div class='warning-div'>
						Activity Completion requires at least 1 update to enable
					</div>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</div>
	</kendo-gridlayout-item>
	@if (!hideTrending) {
		<kendo-gridlayout-item [row]='2' [col]='1'>
			<div class='card'>
				<kendo-gridlayout
					[rows]="(project.$currentProjectReport | async)?.updateIds?.length <= 1 ? ['42.4px', '150px'] : ['42.4px', '303px']"
					[cols]="['100%']"
					[gap]="{ rows: 0, cols: 0 }"
					class='full-height full-width'
				>
					<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container'>
						<div class="card-header">
							Activity Completion Trending
						</div>
						<tooltip-open type='activity-completion-trending'></tooltip-open>
					</kendo-gridlayout-item>
					<kendo-gridlayout-item [row]='2' [col]='1'>
						<div class='chart-container' *ngIf='(project.$currentProjectReport | async)?.updateIds?.length > 1'>
							<app-chart
								[title]="'blank'"
								[categories]='trendCategories'
								[seriesData]='trendSeriesData'
								[legendVisible]='true'
								[valueAxis]='trendValueAxisItemSettings'
								[plotLines]='trendPlotLines'
								(legendItemClicked)='setTableView($event)'
							></app-chart>
						</div>
						<div *ngIf='(project.$currentProjectReport | async)?.updateIds?.length <= 1' class='warning-div' style='height: 150px;'>
							Activity Completion Trend requires at least 1 update to enable
						</div>
					</kendo-gridlayout-item>
				</kendo-gridlayout>
			</div>
		</kendo-gridlayout-item>
	}
</kendo-gridlayout>
