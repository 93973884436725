<div>
	<kendo-stepper
		#stepper
		*ngIf='!isEdit'
		[steps]="steps"
		stepType="full"
		[(currentStep)]="currentStep"
		[linear]='!fileUploaded'
	>
	</kendo-stepper>

	<form
		id='new-proj-form'
		class="k-form"
		[formGroup]="form"
		kendoTextBox
		style="padding-top: 16px; overflow-y: auto"
		[style.height]='isEdit ? "auto" : ((navbarStorage.newProjectFormMaxHeight - 257) + "px")'
	>
		<div *ngIf="!isEdit && currentStep === 0" class='file-upload-container' [hidden]="uploadType === 'auto'">

			<div *ngIf='uploadType === "manual"' style="text-align: center; margin-bottom: 4px">
				Upload {{user?.userType === 'saasRisk' ? 'a' : 'the baseline'}} schedule (.xer v7.0+ recommended)
			</div>
			<div class='center-h' [style]='"width: 100%; justify-content: center;" + (uploadType === "manual" ? "" : "display: none;")'>
				<input
					style="justify-content: center; text-align-last: center;"
					type="file"
					[value]="fileUploadService.data.xerFileName"
					(change)="makeFileReadyForUpload($event);"
					[disabled]="fileUploadService.loading === 'file'"
					id='upload-file-new-project'
				/>
			</div>
			<div *ngIf="uploadType === 'manual'" style="text-align: center;">
				or
			</div>
			<button
				*ngIf="uploadType === 'manual'"
				kendoButton
				[toggleable]="true"
				title="Import from Database"
				[selected]='uploadType === "auto"'
				(click)='uploadType = "auto"'
				style="border-color: rgba(0, 0, 0, 0.24);"
			>Import from Database</button>

			<div *ngIf='uploadedUpdate?.xer?.projects?.size > 1'>
				<kendo-label [for]="selected_proj_id" text="Select Project Schedule"></kendo-label>
				<kendo-dropdownlist
					#selected_proj_id
					formControlName="selected_proj_id"
					[data]="uploadedUpdate.xer.projects | keyvalue"
					valueField='key'
					textField='value.shortName'
					[valuePrimitive]='true'
					[filterable]="true"
					(valueChange)='projectChange($event)'
				>
				</kendo-dropdownlist>
			</div>
		</div>

		@if (!isEdit && currentStep === 0 && (uploadType === 'auto')) {
			<div style="margin-bottom: 5px">
				<button
					style="margin-right: 10px"
					kendoButton
					[toggleable]="true"
					title="Upload File Manually"
					(click)='uploadType = "manual"'
				>Upload File Manually</button>
			</div>

			<div class="same-row-grouping">
				<kendo-formfield>
					<kendo-label [for]="databaseConnection" text="Database Connection"></kendo-label>
					<kendo-combobox
						#databaseConnection
						[(ngModel)]='databaseInfo.selectedConnection'
						(valueChange)='loadNewDatabasesToSelect($event)'
						[data]="selectionConnectionList"
						[textField]="'friendly_name'"
						[valueField]="'id'"
						[ngModelOptions]='{standalone: true}'
						[filterable]="true"
						(filterChange)="this.selectionConnectionList = autoComplete($event, this.rawSelectionConnectionList, 'connection')"
					>
					</kendo-combobox>
					<kendo-formerror [align]="'end'">Database connection is required</kendo-formerror>
				</kendo-formfield>

				<kendo-formfield>
					<kendo-label [for]="database" text="Database"></kendo-label>
					<kendo-combobox
						#database
						[(ngModel)]='databaseInfo.database'
						(valueChange)='loadNewFilterCodesAndProjects($event)'
						[data]="selectionDatabaseList"
						[textField]="'name'"
						[valueField]="'id'"
						[valuePrimitive]="true"
						[placeholder]="'- Select -'"
						[ngModelOptions]='{standalone: true}'
						[filterable]="true"
						(filterChange)="this.selectionDatabaseList = autoComplete($event, this.rawSelectionDatabaseList, 'database')"
					>
					</kendo-combobox>
					<kendo-formerror [align]="'end'">Database selection is required</kendo-formerror>
				</kendo-formfield>

				<kendo-formfield>
					<kendo-label [for]="filterCode" text="Filter Code"></kendo-label>
					<kendo-combobox
						#filterCode
						[(ngModel)]='databaseInfo.filterCode'
						(valueChange)='checkPCatFilterRequest($event)'
						[data]="selectionPCatList"
						[textField]="'name'"
						[valueField]="'id'"
						[valuePrimitive]="true"
						[placeholder]="'- Select -'"
						[ngModelOptions]='{standalone: true}'
						[filterable]="true"
						(filterChange)="this.selectionPCatList = autoComplete($event, this.rawSelectionPCatList, 'filterCode')"
					>
					</kendo-combobox>
				</kendo-formfield>

				<kendo-formfield>
					<kendo-label [for]="filterCodeValue" text="Filter Code Value"></kendo-label>
					<kendo-combobox
						#filterCodeValue
						[(ngModel)]='databaseInfo.filterCodeValue'
						(valueChange)='filterValueChangeRequest($event)'
						[data]="selectionPCatValList"
						[textField]="'value'"
						[valueField]="'id'"
						[valuePrimitive]="true"
						[placeholder]="'- Select -'"
						[ngModelOptions]='{standalone: true}'
						[filterable]="true"
						(filterChange)="this.selectionPCatValList = autoComplete($event, this.rawSelectionPCatValList, 'filterCodeValue')"
					>
					</kendo-combobox>
					<kendo-formerror [align]="'end'">Filter code value is required</kendo-formerror>
				</kendo-formfield>

				<kendo-formfield>
					<kendo-label text="Project in Database to Convert"></kendo-label>
					<kendo-dropdowntree
						class="project-tree"
						style="background-color: transparent !important;"
						kendoDropDownTreeExpandable
						(valueChange)='handleProjectNumber($event)'
						[kendoDropDownTreeHierarchyBinding]="selectionProjectList"
						(close)="disableCloseForParents($event)"
						[textField]="'item.wbs_name'"
						[valueField]="'item.id'"
						[placeholder]="'- Select -'"
						[childrenField]="'children'"
					>
						<ng-template kendoDropDownTreeNodeTemplate let-dataItem>
						<span [ngClass]="{'disabled-item': dataItem.item.proj_flag === 'N'}">
							{{ dataItem.item.wbs_name }}
					</span>
						</ng-template>
					</kendo-dropdowntree>
					<kendo-formerror [align]="'end'">Project selection is required</kendo-formerror>
				</kendo-formfield>

				<div style="align-self: flex-end; text-align: center">
					<button themeColor='success' kendoButton (click)='doSecretMagic(); uploadType = "manual"'>Load Schedule</button>
				</div>
			</div>
		}


		<app-project-details
			#projectDetails
			[hidden]="!(currentStep === 0 && (uploadType === 'manual'))"
			*ngIf="currentStep === 0"
			[isEdit]='isEdit'
			[isBaseline]='isBaselineForm'
			[project]='projectInfo'
			[projectDetails]='projectDetailsGroup'
			[showMilestones]='showMilestoneButtons'
			[googleScriptLoaded]='analyticsService.isGoogleMapsScriptLoaded'
			[eligibilityData]='eligibilityData'
			(companyChanged)='updateCompanyPermissions($event)'
			(saasRiskEditFormChanges)='updateSaveBtnSaasRisk($event)'
			[viewerDisabledMode]='viewerDisabledMode'
		>
		</app-project-details>

		<div *ngIf='currentStep === 1' style='text-align: center'>
			<h2>Project Start Date</h2>
			<div class="mile-stone-selection" style='padding-bottom: 24px;'>
				<p class="mile-stone-selection-item" style="margin-bottom: 16px">
					Select the start milestone that represents the beginning of the project's contract duration from the dropdown
					menu below.
				</p>
				<kendo-label [for]="startMilestone" text="Select Start Milestone or Activity"></kendo-label>
				<kendo-dropdownlist
					#startMilestone
					formControlName="startMilestone"
					[data]="fileUploadService.startMilestones"
					[textField]='"text"'
					[valueField]='"value"'
					[valuePrimitive]='false'
					[filterable]="true"
					(valueChange)='updateValue($event, "startFinish", "startMilestone")'
					(filterChange)="handleFilter($event, 'startMilestones', 'startMilestoneList')"
					[virtual]="virtual"
				>
					<ng-template kendoComboBoxFixedGroupTemplate let-groupName>
						<div><strong><u>{{groupName}}</u></strong></div>
					</ng-template>
					<!--					<ng-template kendoDropDownListHeaderTemplate let-category>-->
					<!--						<div>-->
					<!--							<strong><u>{{ category }}</u></strong>-->
					<!--						</div>-->

					<!--					</ng-template>-->
					<ng-template kendoDropDownListGroupTemplate let-category>
						<div>
							<strong><u>{{ category }}</u></strong>
						</div>
					</ng-template>
				</kendo-dropdownlist>
			</div>
			<span class="k-form-separator" style='padding-bottom: 12px;'></span>
			<h2>Project Completion (CCD)</h2>
			<div class="mile-stone-selection">
				<p class="mile-stone-selection-item" style="margin-bottom: 16px">
					Select the Finish Milestone the represents the contractual completion of the project from the dropdown menu
					below.
				</p>
				<kendo-label [for]="finishMilestone" text="Select Finish Milestone or Activity"></kendo-label>
				<kendo-dropdownlist
					#finishMilestone
					formControlName="finishMilestone"
					[data]="fileUploadService.finishMilestones"
					valueField='value'
					textField='text'
					[valuePrimitive]='false'
					[filterable]="true"
					(valueChange)='updateValue($event, "startFinish", "finishMilestone")'
					(filterChange)="handleFilter($event, 'finishMilestones', 'finishMilestoneList')"
					[popupSettings]="{height: 227}"
					[virtual]="virtual"
				>
					<ng-template kendoComboBoxFixedGroupTemplate let-groupName>
						<div><strong><u>{{groupName}}</u></strong></div>
					</ng-template>
					<ng-template kendoDropDownListHeaderTemplate let-category>
						<div>
							<strong><u>{{ category }}</u></strong>
						</div>

					</ng-template>
					<ng-template kendoDropDownListGroupTemplate let-category>
						<div>
							<strong><u>{{ category }}</u></strong>
						</div>
					</ng-template>
				</kendo-dropdownlist>
			</div>
		</div>
		<div *ngIf='user?.userType !== "saasRisk" && currentStep === 2' style='text-align: center'>
			<h2 style='margin: 12px 0;'>Additional Milestones</h2>
			<div class="mile-stone-selection">
				<p class="mile-stone-selection-item" style="margin: 8px 0;">
					Select additional Finish Milestones or Activities that you would like to track in addition to Project Completion
					(CCD).
				</p>
				<kendo-multiselect
					#additionalMilestonesMultiselect
					[data]="fileUploadService.finishMilestones"
					[valuePrimitive]='false'
					[autoClose]="false"
					(valueChange)='additionalMilestonesValueChanged($event)'
					[filterable]="true"
					(filterChange)="handleFilter($event, 'finishMilestones', 'finishMilestoneList')"
					(focusin)='addMileFocus($event, true)'
					(focusout)='addMileFocus($event, false)'
					valueField='value'
					textField='text'
					formControlName='additionalMilestones'
					[tagMapper]="tagMapper"
					[virtual]="virtual"
				>
					<ng-template kendoComboBoxFixedGroupTemplate let-groupName>
						<div><strong><u>{{groupName}}</u></strong></div>
					</ng-template>
					<!--					<ng-template kendoDropDownListHeaderTemplate let-category>-->
					<!--						<div>-->
					<!--							<strong><u>{{ category }}</u></strong>-->
					<!--						</div>-->

					<!--					</ng-template>-->
					<ng-template kendoDropDownListGroupTemplate let-category>
						<div>
							<strong><u>{{ category }}</u></strong>
						</div>
					</ng-template>
					<ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
						<div class="invisible-multiselect-tag-selector" (click)="toggleMultiselect()"></div>
						<span>
							<kendo-svg-icon [icon]='icons.caretDown' (click)="toggleMultiselect()"></kendo-svg-icon>
							{{ dataItems.length + ' selected' }}
						</span>
					</ng-template>
					<ng-template kendoMultiSelectTagTemplate let-dataItem>
						<div class="invisible-multiselect-tag-selector" (click)="toggleMultiselect()"></div>
						<span>
							{{ dataItem?.text }}
						</span>
					</ng-template>
				</kendo-multiselect>
			</div>
			<span class="k-form-separator" *ngIf='currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-PRO-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-PRO-APK"'></span>
			<h2 style='margin: 12px 0;' *ngIf='currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-PRO-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-PRO-APK"'>Risk Calculation Method</h2>
			<div class="mile-stone-selection" *ngIf='currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-PRO-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-PRO-APK"'>
				<p class="mile-stone-selection-item" style="margin: 8px 0;">
					Select the risk metrics calculation method for your project
				</p>
			</div>
			<div style='display: flex; width: 100%; align-items: center; justify-content: center; padding-bottom: 8px;' *ngIf='currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-PRO-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-PRO-APK"'>
				<kendo-formfield showHints="initial" style="height: 100%;">
					<ul class="k-radio-list">
						<li class="k-radio-item">
							<input
								type="radio"
								#defaultRiskCalc
								value="default"
								kendoRadioButton
								formControlName="riskMetricsType"
							/>
							<kendo-label
								class="k-radio-label"
								[for]="defaultRiskCalc"
								text="Default - KPIs"
							></kendo-label>
						</li>
						<li class="k-radio-item">
							<input
								type="radio"
								#PFRiskCalc
								value="performanceFactor"
								kendoRadioButton
								formControlName="riskMetricsType"
							/>
							<kendo-label
								class="k-radio-label"
								[for]="PFRiskCalc"
								text="Monte Carlo - Performance Trending"
							></kendo-label>
						</li>
						<li class="k-radio-item">
							<input
								type="radio"
								#riskRegisterRiskCalc
								value="riskRegister"
								kendoRadioButton
								formControlName="riskMetricsType"
							/>
							<kendo-label
								class="k-radio-label"
								[for]="riskRegisterRiskCalc"
								text="Monte Carlo - Risk Register"
							></kendo-label>
						</li>
						<li class="k-radio-item">
							<input
								type="radio"
								#coreRiskRiskCalc
								value="coreRisk"
								kendoRadioButton
								formControlName="riskMetricsType"
							/>
							<kendo-label
								class="k-radio-label"
								[for]="coreRiskRiskCalc"
								text="Monte Carlo - Core Risk "
							></kendo-label>
							<span style="color: #8e8e8e; font-size: 12px;">(Simplified Risk Register)</span>
						</li>
					</ul>
					<kendo-formerror [align]="'end'">Error: This field is required</kendo-formerror>
				</kendo-formfield>
			</div>
			<div *ngIf='currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-PRO-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-PRO-APK"'>
				<span class="k-form-separator"></span>
				<div>
					<h4 style='margin: 12px 0;'><b [style.color]='rmt === "default" ? "#8e8e8e" : "black"'>Global Impact:</b></h4>
				</div>
				<div style='display: flex; justify-content: center; align-items: center'>
					<kendo-label
						class="k-form"
						style='display: flex; flex-direction: column; margin-right: 20px'
						[style.color]='rmt === "default" ? "#8e8e8e" : "black"'
						text="Minimum (-Δ%)"
					>
						<kendo-numerictextbox
							format="'-'# '%'"
							[style.width.px]="90"
							[min]="0"
							[step]='1'
							formControlName="riskRegisterGlobalImpactMin"
							[disabled]='rmt === "default"'
						>
						</kendo-numerictextbox>
					</kendo-label>
					<kendo-label
						class="k-form"
						style='display: flex; flex-direction: column;'
						[style.color]='rmt === "default" ? "#8e8e8e" : "black"'
						text="Maximum (+Δ%)"
					>
						<kendo-numerictextbox
							format="'+'# '%'"
							[style.width.px]="90"
							[min]="0"
							[step]='1'
							formControlName="riskRegisterGlobalImpactMax"
							[disabled]='rmt === "default"'
						>
						</kendo-numerictextbox>
					</kendo-label>
				</div>
			</div>
		</div>
		<div *ngIf='(user?.userType !== "saasRisk" && currentStep === 3) || (user?.userType === "saasRisk" && currentStep === 2)'>
			<div style='text-align: center'>
				<h2>{{ user?.userType === 'saasRisk' ? 'Notes' : 'Baseline Notes' }}</h2>
				<div class="baseline-notes">
					<p class="baseline-notes-item">Add notes to summarize the {{ user?.userType === 'saasRisk' ? 'schedule.' : 'baseline schedule.' }}</p>
				</div>
				<div>
					<kendo-formfield style='height: 170px;'>
						<kendo-label [for]="criticalPathSummary" text="Critical Path Summary"></kendo-label>
						<kendo-textarea
							#criticalPathSummary
							maxlength='3000'
							formControlName="criticalPathSummary"
							resizable="none"
							style='height: 150px;'
						>
						</kendo-textarea>
					</kendo-formfield>
					<kendo-formfield style='height: 170px; padding-top: 8px;'>
						<kendo-label [for]="impactsIssuesTiaNotes" text="Impacts / Issues / TIA Notes"></kendo-label>
						<kendo-textarea
							#impactsIssuesTiaNotes
							maxlength='3000'
							formControlName="impactsIssuesTiaNotes"
							resizable="none"
							style='height: 150px;'
						>
						</kendo-textarea>
					</kendo-formfield>
				</div>
			</div>
		</div>
		<div *ngIf='(user?.userType !== "saasRisk" && currentStep === 4) || (user?.userType === "saasRisk" && currentStep === 3)'>
			<div style='text-align: center'>
				<div style="margin-bottom: 8px">
					<h2>
						Review & Submit {{ user?.userType === 'saasRisk' ? 'Schedule' : 'Baseline Schedule' }}
					</h2>
				</div>
			</div>
			<div style="margin-top: 1rem">
				<hr style="margin: 2% 0" />
				<h3 style="margin: 0 0 10px 0; text-align: center; font-size: 18px;">Basic Information</h3>
				<div class='table-container'>
					<div class='table-row basic-info-row'>
						<div class='title'>
							Project Name
						</div>
						<div class='title'>
							Schedule ID
						</div>
						<div class='title'>
							Data Date
						</div>
						<div class='title'>
							Activity Count
						</div>
					</div>
					<div class='table-row basic-info-row'>
						<div>
							{{form.getRawValue()?.projectDetails?.projectName}}
						</div>
						<div>
							{{ fileUploadService?.selectedProject?.proj_short_name }}
						</div>
						<div>
							{{ fileUploadService?.selectedProject ? (fileUploadService?.selectedProject?.last_recalc_date | date: 'MMM d, y') : '' }}
						</div>
						<div>
							{{ fileUploadService?.xer.sortedActivities?.length }}
						</div>
					</div>
				</div>
				<hr style="margin: 2% 0" />
				<div style="margin-top: 1rem"></div>
				<h3 style="margin: 0 0 10px 0; text-align: center; font-size: 18px;" *ngIf='fileUploadService?.xerFile'>Milestones</h3>
				<div class='table-container' *ngIf='fileUploadService?.xerFile'>
					<div class='table-row'>
						<div class='title'>
							Project Start
						</div>
						<div class='title'>
							Project Completion
						</div>
					</div>
					<div class='table-row'>
						<div>
							{{ form.getRawValue()?.startFinish?.startMilestone?.value?.task_name }} - ({{
							form.getRawValue()?.startFinish?.startMilestone?.value?.task_code
							}})
						</div>
						<div>
							{{ form.getRawValue()?.startFinish?.finishMilestone?.value?.task_name }} - ({{
							form.getRawValue()?.startFinish?.finishMilestone?.value?.task_code
							}})
						</div>
					</div>
					<div class='table-row'>
						<div>
							{{ form.getRawValue()?.startFinish?.startMilestone?.value ? (form.getRawValue()?.startFinish?.startMilestone?.value?.early_end_date | date: 'MMM d, y') : '' }}
						</div>
						<div>
							{{ form.getRawValue()?.startFinish?.finishMilestone?.value ? (form.getRawValue()?.startFinish?.finishMilestone?.value?.early_end_date | date: 'MMM d, y') : '' }}
						</div>
					</div>
				</div>
			</div>
			<hr style="margin: 2% 0" *ngIf='fileUploadService?.xerFile' />
			<div style="margin-top: 1rem">
				<h3 style="margin: 0 0 10px 0; text-align: center; font-size: 18px;">{{ user?.userType === 'saasRisk' ? 'Notes' : 'Baseline Notes' }}</h3>
				<div class='table-container'>
					<div class='table-row notes-row'>
						<div class='name'>
							Critical Path Summary:
						</div>
						<div class='notes'>
							{{ form.getRawValue().criticalPathSummary || 'N/A'}}
						</div>
					</div>
					<div class='table-row notes-row' style='margin-top: 12px;'>
						<div class='name'>
							Impacts / Issues / TIA Notes:
						</div>
						<div class='notes'>
							{{ form.getRawValue().impactsIssuesTiaNotes || 'N/A' }}
						</div>
					</div>
				</div>
			</div>
		</div>

	</form>
	<div class='action-button-container' *ngIf='!hideSubmit'>
		<span class="k-form-separator"></span>
		<div *ngIf='_exceedsLicenseTaskLimit' style='margin-bottom: -20px; padding-top: 10px; color: red; text-align: center'>
			Warning: Number of activities exceeds company license limit of 10,000
		</div>
		<div *ngIf='zeroTasks' style='margin-bottom: -20px; padding-top: 10px; color: red; text-align: center'>
			Warning: Number of activities exceeds company license limit of 10,000
		</div>
		<div class="k-form-buttons k-buttons-end">
			<span class="page" *ngIf='!isEdit && (user?.userType !== "saasRisk" || fileUploadService.data.xerFileName !== undefined)'>Step {{ currentStep + 1 }} of {{ steps.length }}</span>
			<div>
				<button
					kendoButton
					class="k-button prev"
					*ngIf="currentStep !== 0 && !isEdit"
					(click)="prev()"
					style='margin-right: 8px;'
				>
					Previous
				</button>
				<button
					kendoButton
					class="k-button k-primary"
					(click)="next()"
					[disabled]='_exceedsLicenseTaskLimit || zeroTasks || !currentGroup.valid || (eligibilityData.total > eligibilityData.usage)'
					*ngIf="currentStep < steps.length - 1 && !isEdit && (user?.userType !== 'saasRisk' || fileUploadService.data.xerFileName !== undefined)"
					themeColor='info'
				>
					Next
				</button>
				<button
					kendoButton
					class="k-button k-primary"
					(click)="submit()"
					[disabled]='!form.valid || (eligibilityData.total > eligibilityData.usage) || submitted'
					*ngIf="(currentStep === steps.length - 1 || (currentStep === 0 && user?.userType === 'saasRisk' && fileUploadService.data.xerFileName === undefined)) && !isEdit"
					themeColor='success'
				>
					Submit
					<kendo-loader *ngIf="submitted" size="small"> </kendo-loader>
				</button>
				<button
					kendoButton
					class="k-button k-primary"
					(click)="submit()"
					[disabled]='!form.value.projectDetails.projectCode || !form.value.projectDetails.projectName ||
						!form.value.projectDetails.company || !form.value.projectDetails.projectType ||
						!form.value.projectDetails.pocId || !form.value.projectDetails.clientId || !form.value.projectDetails.lng ||
						!form.value.projectDetails.lat || !form.value.projectDetails.siteLocation || submitted ||
						!(projectService.sqlProjects | async).size'
					*ngIf="isEdit"
					themeColor='success'
				>

					Save
					<kendo-loader *ngIf="submitted" size="small"> </kendo-loader>
				</button>
			</div>
		</div>
	</div>
</div>
