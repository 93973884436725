<div class='card'>
	<kendo-gridlayout
		[rows]="['42.4px', '303px']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
			<div class="card-header">
				Critical Path Switch
			</div>
			<tooltip-open type='{{isOverview ? "overview" : "critical-path-switch"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1'>
			<kendo-gridlayout
				[rows]="['auto']"
				[cols]="['calc((400% / 12) - 2.5px)', 'calc((800% / 12) - 2.5px)']"
				[gap]="{ rows: 0, cols: 5 }"
				class='grid'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='gridlayout-container'>
					<app-gauge
						[value]='$overallScore'
						[colors]='cprColors'
						[label]='label'
						[height]='"222px"'
						[valueFontSize]='"32px"'
						[labelFontSize]='"20px"'
						*ngIf='(projectService.$currentProjectReport | async)?.updateIds?.length > 1'
					>
					</app-gauge>
					<div *ngIf='(projectService.$currentProjectReport | async)?.updateIds?.length <= 1' class='warning-div'>
						Critical Path Switch Score requires at least 1 update to enable
					</div>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2'>
					<div style='height: 300px; margin-bottom: 3px'>
						<app-chart
							[title]='"Critical Path Switch"'
							[categories]='categories'
							[seriesData]='seriesData'
							[legendVisible]='true'
							[valueAxis]='valueAxisItemSettings'
						></app-chart>
					</div>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>

