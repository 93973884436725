import { Component, Input, input, OnInit } from '@angular/core';
import { AnalyticsDashboardService } from '../../../../../services/analytics/analytics.service';
import { CurrentUpdateGanttItem, GanttService, WbsLevel } from '../../../../../services/project/gantt.service';
import { Wbs, XerActivityCode, XerActivityType } from '../../../../../models/Update/Task';
import { arrowDownIcon, arrowRightIcon, arrowUpIcon, xCircleIcon, xIcon } from '@progress/kendo-svg-icons';
import { AppWindowService } from '../../../../../services/common/window.service';
import { filterBy } from '@progress/kendo-data-query';
import { Observable, of } from 'rxjs';

export interface GanttActvType {
	actv_code_type_id: number;
	seq_num: number;
	actv_code_type: string;
	proj_id: number | undefined;
	activitiesUnderCode?: number;
	actv_code_type_scope: string;
	scope?: string;
}

@Component({
	selector: 'app-gantt-group-by',
	templateUrl: './gantt-group-by.component.html',
	styleUrls: ['./gantt-group-by.component.scss'],
})
export class GanttGroupByComponent implements OnInit {
	@Input() fromPresetWindow: boolean = false;
	allGroupByOptions: GanttActvType[] = [];
	currentGroupByOptions: GanttActvType[] = [];
	allActivityTypesSelected: boolean = false;
	currentlyDragging: GanttActvType = null;
	isWithin: boolean = false;
	originalSelectedList: GanttActvType[] = [];
	originalSelectedWbsLevel: WbsLevel = { text: 'Please Select', value: 0 };
	originalHideSummaryBars: boolean = true;
	unsavedChanges: boolean = false;
	icons = {
		close: xCircleIcon,
		arrowUp: arrowUpIcon,
		arrowDown: arrowDownIcon,
		arrowRight: arrowRightIcon,
		x: xIcon,
	};
	wbsKeys: string[] = [];

	constructor(
		public analyticsService: AnalyticsDashboardService,
		public ganttService: GanttService,
		public appWindowService: AppWindowService
	) {}

	ngOnInit() {
		/*this.ganttService.$reportApply.subscribe((val: boolean) => {
			if (val) {
				this.applyGroupBy();
			}
		});*/
		this.allGroupByOptions = this.getOptions();
		const optionNames: string[] = [];
		const duplicateNames: string[] = [];
		this.allGroupByOptions.forEach((option: GanttActvType) => {
			if (optionNames.includes(option.actv_code_type)) {
				if (!duplicateNames.includes(option.actv_code_type)) {
					duplicateNames.push(option.actv_code_type);
				}
			} else {
				optionNames.push(option.actv_code_type);
			}
		});
		if (duplicateNames?.length) {
			for (let i: number = 0; i < this.allGroupByOptions.length; i++) {
				this.allGroupByOptions[i].scope = duplicateNames.includes(this.allGroupByOptions[i].actv_code_type)
					? this.allGroupByOptions[i].actv_code_type_scope.split('AS_')[1]
					: undefined;
			}
		}
		this.currentGroupByOptions = structuredClone(this.allGroupByOptions).filter(
			(item) => !this.ganttService.selectedGroupBy.some((i) => i.actv_code_type_id === item.actv_code_type_id)
		);
		this.originalSelectedList = structuredClone(this.ganttService.selectedGroupBy);
		this.originalSelectedWbsLevel = structuredClone(this.ganttService.selectedWbsLevel);
		this.originalHideSummaryBars = structuredClone(this.ganttService.hideSummaryBars);
		this.ganttService.$selectedWbsLevelChanged.subscribe((val: boolean) => {
			if (!val) {
				this.originalSelectedWbsLevel = structuredClone(this.ganttService.selectedWbsLevel);
			}
			this.testSelectedWbs();
		});
		this.ganttService.$hideSummaryBarsChanged.subscribe((val: boolean) => {
			if (val) {
				if (this.ganttService.radioSelection === 'wbs') {
					this.testSelectedWbs();
				} else {
					this.testSelectedList();
				}
			}
		});
		this.ganttService.$resetOptions.subscribe((val: boolean) => {
			if (val) {
				this.resetOptions();
			}
		});
	}

	/*async applyGroupBy(): Promise<void> {
		if (!this.ganttService.applyPhysicallyClicked) {
			return;
		}
		this.originalHideSummaryBars = structuredClone(this.ganttService.hideSummaryBars);
		if (this.ganttService.radioSelection === 'wbs') {
			await this.ganttService.applyWbsGrouping();
			const test: number =
				this.ganttService.selectedWbsLevel?.value === null
					? this.ganttService.highestLevel
					: this.ganttService.selectedWbsLevel?.value;
			this.ganttService.puppetLevel = [];
			for (let i: number = 0; i < test; i++) {
				this.ganttService.puppetLevel.push(i);
			}
			this.originalSelectedWbsLevel = structuredClone(this.ganttService.selectedWbsLevel);
			this.testSelectedWbs();
		} else {
			await this.ganttService.applyGroupBy();
			this.originalSelectedList = structuredClone(this.ganttService.selectedGroupBy);
			this.testSelectedList();
		}
	}*/

	testSelectedWbs(): void {
		this.unsavedChanges =
			JSON.stringify(this.ganttService.selectedWbsLevel) !== JSON.stringify(this.originalSelectedWbsLevel) ||
			this.ganttService.hideSummaryBars !== this.originalHideSummaryBars;
	}

	testSelectedList(): void {
		this.unsavedChanges =
			JSON.stringify(this.ganttService.selectedGroupBy) !== JSON.stringify(this.originalSelectedList) ||
			this.ganttService.hideSummaryBars !== this.originalHideSummaryBars;
	}

	getOptions(): GanttActvType[] {
		return this.ganttService.allActivityTypes
			.map((a: XerActivityType) => ({
				actv_code_type_id: a.actv_code_type_id,
				seq_num: a.seq_num,
				actv_code_type: a.actv_code_type,
				proj_id: a.proj_id,
				activitiesUnderCode: this.getActivityCount(a.actv_code_type_id),
				actv_code_type_scope: a.actv_code_type_scope,
			}))
			.sort((a: GanttActvType, b: GanttActvType) => {
				return a?.activitiesUnderCode === 0
					? 1
					: b?.activitiesUnderCode === 0
						? -1
						: a.seq_num < b.seq_num
							? -1
							: b.seq_num < a.seq_num
								? 1
								: 0;
			});
	}

	getActivityCount(typeId: number): number {
		const codesForType: XerActivityCode[] = this.ganttService.actvCodesByType.get(typeId);
		let countOfActivities: number = 0;
		codesForType.forEach((code: XerActivityCode) => {
			const activitiesCount: number = this.ganttService.activitiesByCode.get(code.actv_code_id)?.length || 0;
			countOfActivities += activitiesCount;
		});
		return countOfActivities || 0;
	}

	radioChoiceChanged(ev, option: 'wbs' | 'actvCodes'): void {
		// const newHeight: number = option === 'wbs' ? 600 : 30 * this.allGroupByOptions?.length + 248;
		// this.appWindowService.windowPosition.ganttGroupBy.height = newHeight;
		// const groupByWindow = document.getElementById('ganttGroupByWindow');
		// groupByWindow.setAttribute('style', 'height:' + newHeight + 'px');
		// setTimeout(() => {
		// 	this.appWindowService.windowPosition.ganttGroupBy.top = (window.innerHeight - newHeight) / 2;
		// 	this.appWindowService.windowPosition.ganttGroupBy.left =
		// 		(window.innerWidth - this.appWindowService.windowPosition.ganttGroupBy.width) / 2;
		// 	this.appWindowService.restrictMovement('ganttGroupBy');
		// }, 500);
	}

	public handleDrop(ev, item: GanttActvType): void {
		const isBelowTarget: boolean = ev?.dropTarget?.getBoundingClientRect()?.top < ev?.dragEvent?.clientY;
		if (this.isWithin) {
			this.ganttService.selectedGroupBy = this.ganttService.selectedGroupBy.filter(
				(i: GanttActvType) => i.actv_code_type_id !== this.currentlyDragging.actv_code_type_id
			);
		}
		const index: number = this.ganttService.selectedGroupBy.findIndex(
			(i: GanttActvType) => i.actv_code_type_id === item.actv_code_type_id
		);
		if (index !== -1) {
			if (
				!this.ganttService.selectedGroupBy.some(
					(i: GanttActvType) => i.actv_code_type_id === this.currentlyDragging.actv_code_type_id
				)
			) {
				this.ganttService.selectedGroupBy.splice(isBelowTarget ? index + 1 : index, 0, this.currentlyDragging);
				this.currentGroupByOptions = this.currentGroupByOptions.filter(
					(i: GanttActvType) => i.actv_code_type_id !== this.currentlyDragging.actv_code_type_id
				);
			}
		}
		this.currentlyDragging = null;
		this.testSelectedList();
	}

	resetOptions(): void {
		this.currentGroupByOptions = structuredClone(this.allGroupByOptions)
			.sort((a: GanttActvType, b: GanttActvType) => {
				return a?.activitiesUnderCode === 0
					? 1
					: b?.activitiesUnderCode === 0
						? -1
						: a.seq_num < b.seq_num
							? -1
							: b.seq_num < a.seq_num
								? 1
								: 0;
			})
			.filter(
				(option: GanttActvType) =>
					this.ganttService.selectedGroupBy.findIndex(
						(o: GanttActvType) => o.actv_code_type_id === option.actv_code_type_id
					) === -1
			);
	}

	resetGroupBy(): void {
		this.ganttService.$groupByXClicked.next(true);
		// this.ganttService.selectedGroupBy = [];
		// this.ganttService.selectedGroupByDelayed = [];
		// this.resetOptions();
		// this.ganttService.groupByApplied = false;
		// this.ganttService.items = filterBy(this.ganttService.allItems, this.ganttService.filterValue).filter(
		// 	(item: CurrentUpdateGanttItem) => (this.ganttService.showOnlyCritical ? item.isCritical : true)
		// );
		// this.ganttService.resetGroups();
	}

	deselectItem(item: GanttActvType): void {
		this.ganttService.selectedGroupBy = this.ganttService.selectedGroupBy.filter(
			(i: GanttActvType) => i.actv_code_type_id !== item.actv_code_type_id
		);
		this.currentGroupByOptions.push(item);
		this.currentGroupByOptions = this.currentGroupByOptions.sort((a: GanttActvType, b: GanttActvType) => {
			return a?.activitiesUnderCode === 0
				? 1
				: b?.activitiesUnderCode === 0
					? -1
					: a.seq_num < b.seq_num
						? -1
						: b.seq_num < a.seq_num
							? 1
							: 0;
		});
		this.testSelectedList();
	}

	moveItem(item: GanttActvType, direction: 'up' | 'down' | 'in'): void {
		if (direction === 'in') {
			this.addToSelectedList(item);
		} else {
			this.doMove(item, direction);
		}
	}

	doMove(item: GanttActvType, direction: 'up' | 'down'): void {
		const currentSelectedGroupBy: GanttActvType[] = structuredClone(this.ganttService.selectedGroupBy);
		const currentIndexOfItem: number = currentSelectedGroupBy.findIndex(
			(i: GanttActvType) => i.actv_code_type_id === item.actv_code_type_id
		);
		if (currentIndexOfItem !== -1) {
			currentSelectedGroupBy.splice(currentIndexOfItem, 1);
			const newIndex: number = direction === 'up' ? currentIndexOfItem - 1 : currentIndexOfItem + 1;
			if (!currentSelectedGroupBy.some((i: GanttActvType) => i.actv_code_type_id === item.actv_code_type_id)) {
				currentSelectedGroupBy.splice(newIndex, 0, item);
				if (currentSelectedGroupBy?.length === this.ganttService.selectedGroupBy?.length) {
					this.ganttService.selectedGroupBy = currentSelectedGroupBy;
				}
			}
		}
		this.currentlyDragging = null;
		this.testSelectedList();
	}

	dropOnPane(ev): void {
		this.ganttService.selectedGroupBy = this.ganttService.selectedGroupBy.filter(
			(i: GanttActvType) => i.actv_code_type_id !== this.currentlyDragging.actv_code_type_id
		);
		this.addToSelectedList(this.currentlyDragging);
	}

	addToSelectedList(item: GanttActvType): void {
		this.ganttService.selectedGroupBy.push(item);
		this.currentGroupByOptions = this.currentGroupByOptions.filter(
			(a: GanttActvType) => a.actv_code_type_id !== item.actv_code_type_id
		);
		this.currentlyDragging = null;
		this.testSelectedList();
	}

	handleDragStart(ev, item: GanttActvType, isWithin: boolean = false): void {
		this.currentlyDragging = item;
		this.isWithin = isWithin;
	}

	public fetchChildren(node: Wbs): Observable<Wbs[]> {
		//Return the items collection of the parent node as children.
		return of(node.children);
	}

	public hasChildren(node: Wbs): boolean {
		//Check if the parent node has children.
		return node.children && node.children.length > 0;
	}

	public isExpanded = (dataItem: Wbs, index: string) => {
		return dataItem?.level === 1 || this.wbsKeys.indexOf(index) > -1;
	};

	/**
	 * A `collapse` event handler that will remove the node hierarchical index
	 * from the collection, collapsing its children.
	 */
	public handleCollapse(node) {
		this.wbsKeys = this.wbsKeys.filter((k) => k !== node.index);
	}

	/**
	 * An `expand` event handler that will add the node hierarchical index
	 * to the collection, expanding its children.
	 */
	public handleExpand(node) {
		this.wbsKeys = this.wbsKeys.concat(node.index);
	}

	public itemDisabled(itemArgs: { dataItem: string; index: number }) {
		return itemArgs.index === 1; // disables level 1
	}
}
