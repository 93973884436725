import { ActivityPredecessor, ICalendar, XerActivity, XerTaskPredecessor } from '@rhinoworks/xer-parse';
import { Activity } from '@rhinoworks/xer-parse';
import { addDays, isValid } from 'date-fns';
import { SlimmedTaskCommon } from '../../models/Update/Task';
import { isCritical } from '../../util/tasks';

export type PredecessorInfo = {
	id: number;
	predCode: string;
	succCode: string;
	relationType: XerTaskPredecessor['pred_type'];
	lagHrs: number;
	isDriving: boolean;
	criticalPathLogic: boolean;
};

export type ActivityInfo = {
	code: XerActivity['task_code'];
	statusCode: string;
	name: string;
	isComplete: boolean;
	isCritical: boolean;
	longestPath: boolean;
	type: string;
	isMilestone: boolean;
	isTask: boolean;
	isLOE: boolean;
	isFinishMilestone: boolean;
	isResource: boolean;
	isLookahead: boolean;
	isHardConstraint: boolean;
	isSoftConstraint: boolean;
	hasPredecessor: boolean;
	hasSuccessor: boolean;
	missingResources: boolean;
	totalFloatHrs: XerActivity['total_float_hr_cnt'];
	targetDurationHrs: XerActivity['target_drtn_hr_cnt'];
	remainingDurationHrs: XerActivity['remain_drtn_hr_cnt'];
	actualDurationHrs: XerActivity['target_drtn_hr_cnt'];
	calendarDailyHrs: ICalendar['day_hr_cnt'];
	late_start_date: Date | undefined;
	early_start_date: Date | undefined;
	late_end_date: Date | undefined;
	early_end_date: Date | undefined;
	targetStart: Date | undefined;
	targetFinish: Date | undefined;
	primaryConstraintDate: Date | undefined;
	finish: Date | undefined;
	start: Date | undefined;
	predecessors: Map<number, PredecessorInfo>;
	successors: Map<number, PredecessorInfo>;
	projectId: number;
};

export const softConstraints = new Set<string>([
	'CS_ALAP',
	'CS_MEO',
	'CS_MEOA',
	'CS_MEOB',
	'CS_MSO',
	'CS_MSOA',
	'CS_MSOB',
]);

export function isNearCriticalPlanned(activity: XerActivity, nextDataDate: Date | undefined): boolean {
	if (activity.driving_path_flag === 'Y' || !nextDataDate || activity.act_end_date) {
		return false;
	}
	if (activity.task_type === 'TT_Mile' && !!activity.late_start_date && !!activity.early_start_date) {
		return activity.early_start_date < nextDataDate && activity.late_start_date < nextDataDate;
	}
	return (
		!!activity.early_end_date &&
		!!activity.late_end_date &&
		activity.early_end_date < nextDataDate &&
		activity.late_end_date < nextDataDate
	);
}

export function isTask(activity: SlimmedTaskCommon): boolean {
	return activity.task_type === 'TT_Task';
}

export function isMilestone(activity: XerActivity): boolean {
	return activity.task_type === 'TT_Mile';
}

export function isFinishMilestone(activity: SlimmedTaskCommon): boolean {
	return activity.task_type === 'TT_FinMile';
}

export function isResource(activity: SlimmedTaskCommon): boolean {
	return activity.task_type === 'TT_Rsrc';
}

export function isLOE(activity: SlimmedTaskCommon): boolean {
	return activity.task_type === 'TT_LOE';
}

export function isProblematicRelationship(
	predActv: XerActivity,
	relationType: string,
	lagHrs: number,
	succActv: XerActivity
): boolean {
	if (predActv.act_end_date && succActv.act_end_date) {
		return false;
	}
	if (relationType === 'PR_FF') {
		return (succActv.remain_drtn_hr_cnt || 0) < lagHrs;
	}
	if (relationType === 'PR_SS') {
		if (predActv.act_start_date && !predActv.act_end_date) {
			return (predActv.remain_drtn_hr_cnt || 0) < lagHrs;
		}
		if (predActv.act_end_date) {
			return (predActv.target_drtn_hr_cnt || 0) < lagHrs;
		}
	}
	return false;
}

export function isCriticalPlanned(activity: XerActivity, dataDate: Date | undefined): boolean {
	if (!isCritical(activity) || !dataDate) {
		return false;
	}
	if (isMilestone(activity)) {
		return !!activity.early_start_date && activity.early_start_date < dataDate;
	}
	return !!activity.early_end_date && activity.early_end_date < dataDate;
}

export function isNonCriticalPlanned(activity: XerActivity, dataDate: Date | undefined): boolean {
	if (activity.driving_path_flag === 'Y' || !dataDate || !!activity.act_end_date) {
		return false;
	}
	if (isMilestone(activity)) {
		return (
			!!activity.early_start_date &&
			!!activity.late_start_date &&
			activity.early_start_date < dataDate &&
			activity.late_start_date >= dataDate
		);
	}
	return (
		!!activity.early_end_date &&
		!!activity.late_end_date &&
		activity.early_end_date < dataDate &&
		activity.late_end_date >= dataDate
	);
}

export function isPlannedToBeFinishedBy(activity: XerActivity, date: Date | undefined): boolean {
	if (!activity || !date || !!activity.act_end_date) {
		return false;
	}
	if (isMilestone(activity)) {
		return !!activity.early_start_date && activity.early_start_date < date;
	}
	return !!activity.early_end_date && activity.early_end_date < date;
}
