<div id='bulk-upload-container'>

	<!--<kendo-buttongroup *ngIf='uiStorage.databaseImportIsEnabled'>
		<button
			kendoButton
			[toggleable]="true"
			title="Upload File Manually"
			[selected]='uploadType === "manual"'
			(click)='uploadType = "manual"'
		>Upload File Manually</button>
		<button
			kendoButton
			[toggleable]="true"
			title="Import from Database"
			[selected]='uploadType === "auto"'
			(click)='uploadType = "auto"'
		>Import from Database</button>
	</kendo-buttongroup>-->
	<kendo-tabstrip [tabIndex]='uploadType' (tabSelect)="onSelect($event)" [showContentArea]="uploadType !== 0">
		<kendo-tabstrip-tab title="Upload" [selected]="uploadType === 0">
		</kendo-tabstrip-tab>
		<kendo-tabstrip-tab title="Import" [selected]="uploadType === 1">
			<ng-template kendoTabContent>
				@if (uploadType === 1) {
					<div class="same-row-grouping">
						<kendo-formfield>
							<kendo-label [for]="databaseConnection" text="Database Connection"></kendo-label>
							<kendo-combobox
								#databaseConnection
								[(ngModel)]='databaseInfo.selectedConnection'
								(valueChange)='loadNewDatabasesToSelect($event)'
								[data]="selectionConnectionList"
								[textField]="'friendly_name'"
								[valueField]="'id'"
								[ngModelOptions]='{standalone: true}'
								[filterable]="true"
								(filterChange)="this.selectionConnectionList = autoComplete($event, this.rawSelectionConnectionList, 'connection')"
							>
							</kendo-combobox>
							<kendo-formerror [align]="'end'">Database connection is required</kendo-formerror>
						</kendo-formfield>

						<kendo-formfield>
							<kendo-label [for]="database" text="Database"></kendo-label>
							<kendo-combobox
								#database
								[(ngModel)]='databaseInfo.database'
								(valueChange)='loadNewFilterCodesAndProjects($event)'
								[data]="selectionDatabaseList"
								[textField]="'name'"
								[valueField]="'id'"
								[valuePrimitive]="true"
								[placeholder]="'- Select -'"
								[ngModelOptions]='{standalone: true}'
								[filterable]="true"
								(filterChange)="this.selectionDatabaseList = autoComplete($event, this.rawSelectionDatabaseList, 'database')"
							>
							</kendo-combobox>
							<kendo-formerror [align]="'end'">Database selection is required</kendo-formerror>
						</kendo-formfield>

						<kendo-formfield>
							<kendo-label [for]="filterCode" text="Filter Code"></kendo-label>
							<kendo-combobox
								#filterCode
								[(ngModel)]='databaseInfo.filterCode'
								(valueChange)='checkPCatFilterRequest($event)'
								[data]="selectionPCatList"
								[textField]="'name'"
								[valueField]="'id'"
								[valuePrimitive]="true"
								[placeholder]="'- Select -'"
								[ngModelOptions]='{standalone: true}'
								[filterable]="true"
								(filterChange)="this.selectionPCatList = autoComplete($event, this.rawSelectionPCatList, 'filterCode')"
							>
							</kendo-combobox>
						</kendo-formfield>

						<kendo-formfield>
							<kendo-label [for]="filterCodeValue" text="Filter Code Value"></kendo-label>
							<kendo-combobox
								#filterCodeValue
								[(ngModel)]='databaseInfo.filterCodeValue'
								(valueChange)='filterValueChangeRequest($event)'
								[data]="selectionPCatValList"
								[textField]="'value'"
								[valueField]="'id'"
								[valuePrimitive]="true"
								[placeholder]="'- Select -'"
								[ngModelOptions]='{standalone: true}'
								[filterable]="true"
								(filterChange)="this.selectionPCatValList = autoComplete($event, this.rawSelectionPCatValList, 'filterCodeValue')"
							>
							</kendo-combobox>
							<kendo-formerror [align]="'end'">Filter code value is required</kendo-formerror>
						</kendo-formfield>

						<kendo-formfield>
							<kendo-label text="Project in Database to Convert"></kendo-label>
							<kendo-dropdowntree
								class="project-tree"
								style="background-color: transparent !important;"
								kendoDropDownTreeExpandable
								(valueChange)='handleProjectNumber($event)'
								[kendoDropDownTreeHierarchyBinding]="selectionProjectList"
								(close)="disableCloseForParents($event)"
								[textField]="'item.wbs_name'"
								[valueField]="'item.id'"
								[placeholder]="'- Select -'"
								[childrenField]="'children'"
							>
								<ng-template kendoDropDownTreeNodeTemplate let-dataItem>
						<span [ngClass]="{'disabled-item': dataItem.item.proj_flag === 'N'}">
							{{ dataItem.item.wbs_name }}
					</span>
								</ng-template>
							</kendo-dropdowntree>
							<kendo-formerror [align]="'end'">Project selection is required</kendo-formerror>
						</kendo-formfield>

						<div style="align-self: flex-end; text-align: center">
							<button themeColor='success' kendoButton (click)='doSecretMagic(); uploadType = 0'>Load Schedule</button>
						</div>
					</div>
				}
			</ng-template>
		</kendo-tabstrip-tab>
		<kendo-tabstrip-tab title="Sync" [selected]="uploadType === 2" [disabled]="projectData.hasExistingSyncSettings">
			<ng-template kendoTabContent>
				<app-db-sync-tab></app-db-sync-tab>
			</ng-template>
		</kendo-tabstrip-tab>
	</kendo-tabstrip>

	<div class='file-upload-container' [style.margin-bottom.px]='uploadedUpdates?.length ? 16 : 0' [hidden]="uploadType === 1 || uploadType === 2">
		<div style="text-align: center; margin-bottom: 5px; margin-top: 5px">
			Select up to 5 .xer Schedule Files (v7.0 and above recommended)
		</div>
		<div class='center-h' style='width: 100%; justify-content: center;'>
			<input
				style="justify-content: center; text-align-last: center;"
				type="file"
				[(ngModel)]="fileUploadService.xerFile"
				[ngModelOptions]="{ standalone: true }"
				(change)="makeFileReadyForUpload($event);"
				[disabled]="fileUploadService.loading === 'file'"
				accept='.xer'
				id='bulk-update-file-input'
				multiple
				#XERFileInput
				name="xerFile"
			/>
		</div>
	</div>



	<!--<div *ngIf='uploadType === "auto"'>
		<p>Database Connection</p>
		<select style='display: block' [(ngModel)]='databaseInfo.selectedConnection' (ngModelChange)='loadNewDatabasesToSelect($event)' [ngModelOptions]='{standalone: true}'>
			<option value=''>- Select -</option>
			<option *ngFor='let item of selectionConnectionList' [value]='item.id'>{{item.friendly_name}}</option>
		</select>
		<p *ngIf='databaseFailedConnect === true' style='color: red'>Failed to connect to Server to Fetch Databases. Please check your credentials in Account Settings.</p>
		<p>Database</p>
		<select style='display: block' [(ngModel)]='databaseInfo.database' (ngModelChange)='loadNewFilterCodesAndProjects($event)' [ngModelOptions]='{standalone: true}'>
			<option value=''>- Select -</option>
			<option *ngFor='let item of selectionDatabaseList' [value]='item'>{{item}}</option>
		</select>
		<p>Filter Code</p>
		<select style='display: block' [(ngModel)]='databaseInfo.filterCode' (ngModelChange)='checkPCatFilterRequest($event)' [ngModelOptions]='{standalone: true}'>
			<option value=''>- Select -</option>
			<option *ngFor='let item of selectionPCatList' [value]='item.id'>{{item.name}}</option>
		</select>
		<p>Value of the Filter Code to Display</p>
		<select style='display: block' [(ngModel)]='databaseInfo.filterCodeValue' (ngModelChange)='filterValueChangeRequest($event)' [ngModelOptions]='{standalone: true}'>
			<option value=''>- Select -</option>
			<option *ngFor='let item of selectionPCatValList' [value]='item.id'>{{item.value}}</option>
		</select>
		<p>Project in Database to Convert</p>
		<select style='display: block' [(ngModel)]='databaseInfo.projectNumber' [ngModelOptions]='{standalone: true}' (ngModelChange)='handleProjectNumber($event)'>
			<option value=''>- Select -</option>
			<option *ngFor='let item of selectionProjectList' [value]='item.id'>{{item.name}}</option>
		</select>
		<button kendoButton (click)='doSecretMagic(); uploadType = "manual"'>Add Schedule</button>
	</div>-->

	<div
		class='uploaded-updates-container'
		[style.max-height.px]='navBarService.bulkUpdateFormMaxHeight - 254'
		*ngIf='uploadedUpdates?.length'
		[hidden]="uploadType === 1 || uploadType === 2"
	>
		<div *ngFor='let update of uploadedUpdates; let i = index;' class='uploaded-update-form'>
			<div style='display: grid; grid-auto-flow: column; padding-bottom: 10px;'>
				<div style="margin-top: 3px;">{{(i + 1) + ') '}}File: {{update?.data.xerFileName}}</div>
				<div style='justify-content: flex-end; display: flex; align-items: end; flex-direction: column; gap: 5px;'>
					<div kendoTooltip style="text-align: center;">
						<kendo-label [title]="update.data.hasCostData ? '' : 'No Cost Data for Update'" [text]="'Cost Baseline'" class="sched-edit-label">
							<kendo-switch [(ngModel)]="update?.data.isCostBaseline" [disabled]='update.data?.hasCostData === false' style='margin-left: 5px' [size]="'small'"></kendo-switch>
						</kendo-label>
					</div>
					<kendo-label [text]="'Re-Baseline'" class="sched-edit-label">
						<kendo-switch [(ngModel)]="update?.data.baseline" (valueChange)='!$event ? closeConfirm(false) : confirmOpen = i' style='margin-left: 5px' [size]="'small'"></kendo-switch>
					</kendo-label>
				</div>
			</div>

			<div *ngIf='_exceedsLicenseTaskLimit[i]' style='color: red; text-align: center; margin-top: -5px; margin-bottom: 5px'>
				Warning: Number of activities exceeds company license limit of 10,000
			</div>
			<div *ngIf='noTasks[i]' style='color: red; text-align: center; margin-top: -5px; margin-bottom: 5px'>
				Warning: Number of activities is zero
			</div>
			<div class='same-row-grouping'>
				<div class='grouped-item'>
					<kendo-label [for]="dataDate" text="Data Date"> </kendo-label>
					<kendo-textbox
						[value]="update.xer?.projects.get(update.data.selectedProjectId)?.lastRecalculationDate ? (update.xer.projects.get(update.data.selectedProjectId).lastRecalculationDate | date: 'MM/dd/yyyy') : null"
						#dataDate
						[readonly]='true'
						class='readonly-textbox'
					>
					</kendo-textbox>
				</div>
				<div class='grouped-item'>
					<kendo-label [for]="schedule" text="Schedule"> </kendo-label>
					<kendo-dropdownlist
						#schedule
						[data]="update.xer?.projects | keyvalue"
						valueField='key'
						textField='value.shortName'
						[valuePrimitive]='true'
						[filterable]="true"
						[value]='update.data.selectedProjectId'
						[(ngModel)]='update.data.selectedProjectId'
						[readonly]='!uploadedUpdates?.length'
						[class.readonly-textbox]='!uploadedUpdates?.length'
					>
					</kendo-dropdownlist>
				</div>
			</div>
			<div class='same-row-grouping'>
				<div class='grouped-item' style='width: 100%;'>
					<kendo-label [for]="finishMilestone" text="Finish Milestone"> </kendo-label>
					<kendo-dropdownlist
						#finishMilestone
						[data]="update.finishMilestones"
						[(ngModel)]='update.data.finishMilestone'
						valueField='task_code'
						textField='text'
						[valuePrimitive]='false'
						[filterable]="true"
						(filterChange)="update.filterFinishMilestones($event)"
						[popupSettings]="{height: 227}"
					>
						<ng-template kendoComboBoxFixedGroupTemplate let-groupName>
							<div><strong><u>{{groupName}}</u></strong></div>
						</ng-template>
						<ng-template kendoDropDownListHeaderTemplate let-category>
							<div>
								<strong><u>{{ category }}</u></strong>
							</div>

						</ng-template>
						<ng-template kendoDropDownListGroupTemplate let-category>
							<div>
								<strong><u>{{ category }}</u></strong>
							</div>
						</ng-template>
					</kendo-dropdownlist>
				</div>
			</div>
			<div class='same-row-grouping'>
				<div class='grouped-item'>
					<kendo-label [for]="criticalPathNotes" text="Critical Path Summary"> </kendo-label>
					<kendo-textarea
						#criticalPathNotes
						maxlength='3000'
						[(ngModel)]='update.data.criticalPathNotes'
						resizable="none"
						style='height: 80px;'
						[readonly]='!uploadedUpdates?.length'
						[class.readonly-textbox]='!uploadedUpdates?.length'
					>
					</kendo-textarea>
				</div>
				<div class='grouped-item'>
					<kendo-label [for]="tiaNotes" text="Impacts / Issues / TIA Notes"> </kendo-label>
					<kendo-textarea
						#tiaNotes
						maxlength='3000'
						[(ngModel)]='update.data.timeAnalysisNotes'
						resizable="none"
						style='height: 80px;'
						[readonly]='!uploadedUpdates?.length'
						[class.readonly-textbox]='!uploadedUpdates?.length'
					>
					</kendo-textarea>
				</div>
			</div>
			<span class="k-form-separator" *ngIf='uploadedUpdates?.length && i < uploadedUpdates?.length - 1'></span>
		</div>
	</div>
	<div *ngIf='uploadedUpdates?.length' [hidden]="uploadType === 1 || uploadType === 2">
		<span class="k-form-separator"></span>
		<div class="k-form-buttons k-buttons-end" style='margin-top: 5px'>
			@if (errorMessage !== undefined) {
				<div style="color: red; margin-top: 10px;">
					{{errorMessage}}
				</div>
			}
			@if(errorMessage === undefined && (_exceedsLicenseTaskLimit.includes(true) || noTasks.includes(true) || httpProcessing || !uploadedUpdates.length || fileUploadInProgress || hasMissingFinishMilestones)) {
				<kendo-circularprogressbar
					style="transform: scale(0.1); position: absolute; right: 0;"
					[indeterminate]="true"
					[value]="30"
					[progressColor]='"#ffb500"'
				></kendo-circularprogressbar>
			}
			<button
				kendoButton
				class="k-button k-primary"
				(click)="isScheduleModification ? addUpdatesScheduleMod() : addUpdate()"
				[disabled]='errorMessage !== undefined || _exceedsLicenseTaskLimit.includes(true) || noTasks.includes(true) || httpProcessing || !uploadedUpdates.length || fileUploadInProgress || hasMissingFinishMilestones'
				themeColor='success'
			>
				Submit
			</button>
		</div>
	</div>
</div>
<kendo-window
	title="Confirm action"
	*ngIf="confirmOpen !== null"
	(close)="closeConfirm(false)"
	[minWidth]="250"
	[width]="450"
	left='50%'
	top='50%'
	[resizable]='false'
	[draggable]='false'
>
	<kendo-window-titlebar>
		<span class="k-window-title">Confirm action</span>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>
	<form class="k-form">
		All future updates will be calculated based on this schedule. Are you sure you want to continue?

		<div class="k-actions k-actions-end">
			<button kendoButton type="button" (click)="closeConfirm()">Cancel</button>
			<button
				kendoButton
				themeColor="primary"
				type="button"
				(click)="closeConfirm(true)"
			>
				Confirm
			</button>
		</div>
	</form>
</kendo-window>
