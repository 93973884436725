import { ChangeDetectorRef, Injectable } from '@angular/core';
import { NavigationBarStorageService } from './navigation-bar-storage.service';
import { BehaviorSubject } from 'rxjs';
import { UserService } from './user.service';
import { ProjectDashboardService } from '../project/project.service';

@Injectable({
	providedIn: 'root',
})
export class AppWindowService {
	wrapper = {
		//located in app
		tooltip: null,
		profile: null,
		report: null,
		bulkUpdate: null,
		baseline: null, //doubles as new project form window
		//located in portfolio
		eula: null,
		globalTooltip: null,
		projectGridFilters: null,
		//located in risk register
		riskAdd: null,
		riskUnsavedChanges: null,
		removeLogo: null,
		riskDelete: null,
		riskImport: null,
		//located in subdirectories within the schedule update list directory
		unsavedScheduleChanges: null,
		unappliedChangesReport: null,
		editNotesChanges: null,
		//located in top row
		image: null,
		archiveProjectWarning: null,
		//located in nav bar
		adminSettings: null,
		//located in gantt tab
		ganttFilters: null,
		ganttGroupBy: null,
		ganttPreset: null,
		reportManage: null,
	};
	windowPosition = {
		tooltip: {
			top: null,
			left: null,
			height: null,
			width: null,
		},
		profile: {
			top: null,
			left: null,
			height: 403,
			width: 400,
		},
		report: {
			top: null,
			left: null,
			height: null,
			width: null,
		},
		bulkUpdate: {
			top: null,
			left: null,
			height: 400,
			width: 600,
		},
		baseline: {
			top: null,
			left: null,
			height: null,
			width: 600,
		},
		eula: {
			top: null,
			left: null,
			height: 220,
			width: 450,
		},
		globalTooltip: {
			top: null,
			left: null,
			height: null,
			width: null,
		},
		riskAdd: {
			top: null,
			left: null,
			height: null,
			width: 1000,
		},
		riskUnsavedChanges: {
			top: null,
			left: null,
			height: 200,
			width: 450,
		},
		removeLogo: {
			top: null,
			left: null,
			height: 200,
			width: 450,
		},
		riskDelete: {
			top: null,
			left: null,
			height: 200,
			width: 450,
		},
		riskImport: {
			top: null,
			left: null,
			height: null,
			width: 900,
		},
		unsavedScheduleChanges: {
			top: null,
			left: null,
			height: null,
			width: 509,
		},
		unappliedChangesReport: {
			top: null,
			left: null,
			height: null,
			width: 509,
		},
		editNotesChanges: {
			top: null,
			left: null,
			height: 619,
			width: 700,
		},
		image: {
			top: null,
			left: null,
			height: null,
			width: 390,
		},
		archiveProjectWarning: {
			top: null,
			left: null,
			height: 200,
			width: 450,
		},
		adminSettings: {
			top: null,
			left: null,
			height: Math.min(window.innerHeight - 132, 683),
			width: null,
		},
		projectGridFilters: {
			top: null,
			left: null,
			height: null,
			width: null,
		},
		ganttFilters: {
			top: null,
			left: null,
			height: null,
			width: 650,
		},
		portfolioPreset: {
			top: null,
			left: null,
			height: 694.3,
			width: 1000,
		},
		ganttGroupBy: {
			top: null,
			left: null,
			height: 500,
			width: 800,
		},
		ganttPreset: {
			top: null,
			left: null,
			height: 687.3,
			width: 1000,
		},
		reportManage: {
			top: null,
			left: null,
			height: 518,
			width: 700,
		},
	};
	tooltipTitle: string = null;
	$bulkUpdateHeight = new BehaviorSubject<number>(190);
	$addRiskHeight = new BehaviorSubject<number>(670);
	$unsavedChangesHeight = new BehaviorSubject<number>(221);
	$editNotesHeight = new BehaviorSubject<number>(619);
	$imageWidth = new BehaviorSubject<number>(390);
	$adminSettingsPosition = new BehaviorSubject<{ top?: number; left?: number; height?: number; width?: number }>(null);
	$closeRiskRegisterWindows = new BehaviorSubject<boolean>(false);
	$closeTopRowWindows = new BehaviorSubject<boolean>(false);
	unsavedChangesWindowOpen = false;
	deleteWindowOpen = false;
	importWindowOpen = false;
	showingAdminSettings = false;
	user: any = {
		userType: 'aegis',
	};
	currentPageScrollPos = {
		top: 0,
	};
	$closeFilters: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

	constructor(
		public navBarStorage: NavigationBarStorageService,
		public userService: UserService,
		public _projectDashboardService: ProjectDashboardService
	) {
		this.$bulkUpdateHeight.subscribe((newHeight) => {
			if (this.wrapper.bulkUpdate) {
				const newTop: number = (window.innerHeight + 56 - newHeight) / 2 + this.wrapper.bulkUpdate.top;
				this.windowPosition.bulkUpdate.height = newHeight;
				setTimeout(() => {
					this.restrictMovement('bulkUpdate');
					//fixes the window jumping to the top of the screen if you upload a file while scrolled down on the page - RS
					this.windowPosition.bulkUpdate.top = newTop;
				}, 500);
			}
		});
		this.$addRiskHeight.subscribe((newHeight) => {
			this.windowPosition.riskAdd.height = newHeight;
			this.restrictMovement('riskAdd');
		});
		this.$unsavedChangesHeight.subscribe((newHeight) => {
			setTimeout(() => {
				this.windowPosition.unsavedScheduleChanges.height = newHeight;
				this.windowPosition.unsavedScheduleChanges.width = 509;
				this.windowPosition.unsavedScheduleChanges.top =
					(window.innerHeight - this.windowPosition.unsavedScheduleChanges.height) / 2;
				this.windowPosition.unsavedScheduleChanges.left =
					(window.innerWidth - this.windowPosition.unsavedScheduleChanges.width) / 2;
				this.restrictMovement('unsavedScheduleChanges');
			});
		});
		this.$editNotesHeight.subscribe((newHeight) => {
			setTimeout(() => {
				this.windowPosition.editNotesChanges.height = newHeight;
				this.windowPosition.editNotesChanges.top =
					(window.innerHeight - this.windowPosition.editNotesChanges.height) / 2;
				this.windowPosition.editNotesChanges.left =
					(window.innerWidth - this.windowPosition.editNotesChanges.width) / 2 - 112;
				this.restrictMovement('editNotesChanges');
			});
		});
		this.$imageWidth.subscribe((newWidth) => {
			this.windowPosition.image.width = newWidth;
			this.restrictMovement('image');
		});
		this.userService.user.subscribe((data) => {
			if (data) {
				this.user = data;
			}
		});
		this.windowPosition = {
			tooltip: {
				top: null,
				left: null,
				height: null,
				width: null,
			},
			profile: {
				top: null,
				left: null,
				height: 403,
				width: 400,
			},
			report: {
				top: null,
				left: null,
				height: this.navBarStorage.reportFormMaxHeight,
				width: null,
			},
			bulkUpdate: {
				top: null,
				left: null,
				height: 195,
				width: 600,
			},
			baseline: {
				top: null,
				left: null,
				height: this.navBarStorage.newProjectFormMaxHeight,
				width: 600,
			},
			eula: {
				top: null,
				left: null,
				height: 220,
				width: 450,
			},
			globalTooltip: {
				top: null,
				left: null,
				height: null,
				width: null,
			},
			riskAdd: {
				top: null,
				left: null,
				height: null,
				width: 1000,
			},
			riskUnsavedChanges: {
				top: null,
				left: null,
				height: 200,
				width: 450,
			},
			removeLogo: {
				top: null,
				left: null,
				height: 200,
				width: 450,
			},
			riskDelete: {
				top: null,
				left: null,
				height: 200,
				width: 450,
			},
			riskImport: {
				top: null,
				left: null,
				height: null,
				width: 900,
			},
			unsavedScheduleChanges: {
				top: null,
				left: null,
				height: null,
				width: 509,
			},
			unappliedChangesReport: {
				top: null,
				left: null,
				height: null,
				width: 509,
			},
			editNotesChanges: {
				top: null,
				left: null,
				height: 619,
				width: 700,
			},
			image: {
				top: null,
				left: null,
				height: null,
				width: 390,
			},
			archiveProjectWarning: {
				top: null,
				left: null,
				height: 200,
				width: 450,
			},
			adminSettings: {
				top: null,
				left: null,
				height: Math.min(window.innerHeight - 132, 683),
				width: null,
			},
			projectGridFilters: {
				top: null,
				left: null,
				height: null,
				width: null,
			},
			ganttFilters: {
				top: null,
				left: null,
				height: null,
				width: 650,
			},
			portfolioPreset: {
				top: null,
				left: null,
				height: 694.3,
				width: 1000,
			},
			ganttGroupBy: {
				top: null,
				left: null,
				height: 500,
				width: 800,
			},
			ganttPreset: {
				top: null,
				left: null,
				height: 687.3,
				width: 1000,
			},
			reportManage: {
				top: null,
				left: null,
				height: 518,
				width: 700,
			},
		};
	}

	/**
	 * sets initial values for the window and the viewport so that the restrictMovement function has bounds to work with
	 * @param windowName
	 */
	setViewport(windowName: string): void {
		const oldScrollY = structuredClone(this.currentPageScrollPos.top);
		const viewport = document.getElementsByTagName('body')[0];
		this.wrapper[windowName] = viewport.getBoundingClientRect();
		this.wrapper[windowName].height = window.innerHeight;
		this.setEmptyDimensions();
		setTimeout(() => {
			const windowId =
				windowName === 'tooltip'
					? 'tooltip-' + this.tooltipTitle
					: windowName === 'profile'
						? 'profileWindow'
						: windowName === 'report'
							? 'reportWindow'
							: windowName === 'bulkUpdate'
								? 'bulkWindow'
								: windowName === 'baseline'
									? 'newProjectFormWindow'
									: windowName === 'eula'
										? 'eulaWindow'
										: windowName === 'globalTooltip'
											? 'globalTooltipWindow'
											: windowName === 'riskAdd'
												? 'addRiskWindow'
												: windowName === 'riskUnsavedChanges'
													? 'riskUnsavedChangesWindow'
													: windowName === 'riskDelete'
														? 'riskDeleteWindow'
														: windowName === 'riskImport'
															? 'riskImportWindow'
															: windowName === 'unsavedScheduleChanges'
																? 'unsavedScheduleChangesWindow'
																: windowName === 'unappliedChangesReport'
																	? 'unappliedChangesReportWindow'
																	: windowName === 'editNotesChanges'
																		? 'editNotesChangesWindow'
																		: windowName === 'image'
																			? 'imageWindow'
																			: windowName === 'archiveProjectWarning'
																				? 'archiveProjectWarningWindow'
																				: windowName === 'adminSettings'
																					? 'adminSettingsWindow'
																					: windowName === 'projectGridFilters'
																						? 'projectsGridFiltersWindow'
																						: windowName === 'ganttFilters'
																							? 'ganttFiltersWindow'
																							: windowName === 'removeLogo'
																								? 'removeLogoWindow'
																								: windowName === 'ganttGroupBy'
																									? 'ganttGroupByWindow'
																									: windowName === 'ganttPreset'
																										? 'ganttPresetWindow'
																										: windowName === 'reportManage'
																											? 'reportManageWindow'
																											: '';
			const windowOpenedRect = document.getElementById(windowId)?.getBoundingClientRect();
			if (windowOpenedRect && !this.navBarStorage.showingBaselineProject) {
				this.windowPosition[windowName].height = windowOpenedRect.height;
				this.windowPosition[windowName].width = windowOpenedRect.width;
				if (
					windowName === 'riskAdd' &&
					(this.unsavedChangesWindowOpen || this.deleteWindowOpen || this.importWindowOpen)
				) {
					return;
				}
				this.windowPosition[windowName].top = windowOpenedRect.top - (this.isAppOrWeird(windowName) ? 0 : 112);
				this.windowPosition[windowName].left = windowOpenedRect.left;
			}
			this.windowPosition[windowName].top =
				oldScrollY + (window.innerHeight - this.windowPosition[windowName].height) / 2;
			window.scrollTo(0, oldScrollY);
			this.currentPageScrollPos.top = oldScrollY;
			this.wrapper[windowName].y = oldScrollY;
		});
	}

	/**
	 * boolean eval for location of window being app.component.ts
	 * @param window
	 */
	isAppOrWeird(window): boolean {
		const appOrWeirdItems = [
			'tooltip',
			'profile',
			'report',
			'bulkUpdate',
			'baseline',
			'unsavedScheduleChanges',
			'unappliedChangesReport',
			'editNotesChanges',
			'adminSettings',
			'portfolioPreset',
			'image',
			'riskAdd',
			'archiveProjectWarning',
			'ganttGroupBy',
			'ganttPreset',
			'reportManage',
		];
		if (this.user?.userType === 'saasRisk' && this._projectDashboardService.$currentProjectData.value !== undefined) {
			appOrWeirdItems.push('globalTooltip');
		}
		return appOrWeirdItems.includes(window);
	}

	/**
	 * forces window to stay within bounds of viewport
	 * @param window
	 * @param cdr
	 */
	restrictMovement(window: string, cdr?: ChangeDetectorRef): void {
		if (cdr) {
			cdr.detectChanges();
		}
		if (this.wrapper[window] === null || this.wrapper[window].y < 0) {
			return;
		}
		const isApp: boolean = this.isAppOrWeird(window);
		//edit notes dialog is really weird. might be bc its fusing a dialog and window
		// const isReallyWeird: boolean = ['editNotesChanges'].includes(window);
		const minTop: number = isApp ? 56 + this.currentPageScrollPos.top : this.currentPageScrollPos.top;
		const minLeft = this.wrapper[window].left;
		const maxTop: number =
			this.currentPageScrollPos.top + this.wrapper[window].height - this.windowPosition[window].height;
		const maxLeft: number = this.wrapper[window].left + this.wrapper[window].width - this.windowPosition[window].width;
		if (this.windowPosition[window].top < minTop) {
			this.windowPosition[window].top = minTop;
		}
		if (this.windowPosition[window].left < minLeft) {
			this.windowPosition[window].left = minLeft;
		}
		if (this.windowPosition[window].top > maxTop) {
			this.windowPosition[window].top = maxTop;
		}
		if (this.windowPosition[window].left > maxLeft) {
			this.windowPosition[window].left = maxLeft;
		}
	}

	/**
	 * sets window dimensions to null values so auto-positioning kicks in. also ensures previously opened windows have
	 * no bearing on the new one
	 */
	setEmptyDimensions(): void {
		this.windowPosition = {
			tooltip: {
				top: null,
				left: null,
				height: null,
				width: null,
			},
			profile: {
				top: null,
				left: null,
				height: 403,
				width: 400,
			},
			report: {
				top: null,
				left: null,
				height: this.navBarStorage.reportFormMaxHeight,
				width: null,
			},
			bulkUpdate: {
				top: null,
				left: null,
				height: 195,
				width: 600,
			},
			baseline: {
				top: null,
				left: null,
				height: this.navBarStorage.newProjectFormMaxHeight,
				width: 600,
			},
			eula: {
				top: null,
				left: null,
				height: 220,
				width: 450,
			},
			globalTooltip: {
				top: null,
				left: null,
				height: null,
				width: null,
			},
			riskAdd: {
				top: null,
				left: null,
				height: null,
				width: 1000,
			},
			riskUnsavedChanges: {
				top: null,
				left: null,
				height: 200,
				width: 450,
			},
			removeLogo: {
				top: null,
				left: null,
				height: 200,
				width: 450,
			},
			riskDelete: {
				top: null,
				left: null,
				height: 200,
				width: 450,
			},
			riskImport: {
				top: null,
				left: null,
				height: null,
				width: 900,
			},
			unsavedScheduleChanges: {
				top: null,
				left: null,
				height: null,
				width: 509,
			},
			unappliedChangesReport: {
				top: null,
				left: null,
				height: null,
				width: 509,
			},
			editNotesChanges: {
				top: null,
				left: null,
				height: 619,
				width: 700,
			},
			image: {
				top: null,
				left: null,
				height: null,
				width: 390,
			},
			archiveProjectWarning: {
				top: null,
				left: null,
				height: 200,
				width: 450,
			},
			adminSettings: {
				top: null,
				left: null,
				height: Math.min(window.innerHeight - 132, 683),
				width: null,
			},
			projectGridFilters: {
				top: null,
				left: null,
				height: null,
				width: null,
			},
			ganttFilters: {
				top: null,
				left: null,
				height: null,
				width: 650,
			},
			portfolioPreset: {
				top: null,
				left: null,
				height: 694.3,
				width: 1000,
			},
			ganttGroupBy: {
				top: null,
				left: null,
				height: 500,
				width: 800,
			},
			ganttPreset: {
				top: null,
				left: null,
				height: 687.3,
				width: 1000,
			},
			reportManage: {
				top: null,
				left: null,
				height: 518,
				width: 700,
			},
		};
	}
}
