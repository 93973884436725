import { NgModule } from '@angular/core';
import { DialogUnsavedScheduleChangesComponent, ScheduleUpdatesListComponent } from './schedule-updates-list.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SharedMatModule } from '../../shared/shared-mat.module';
import { PipeModule } from '../../../util/pipes/pipe.module';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridLayoutModule, TabStripModule } from '@progress/kendo-angular-layout';
import { ProjectNotesComponent } from './project-notes/project-notes.component';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';
import { AddBulkUpdateModule } from '../top-row/add-bulk-update/add-bulk-update.module';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { ScheduleDelaysComponent } from './schedule-delays/schedule-delays.component';
import { CriticalPathComparisonComponent } from './critical-path-comparison/critical-path-comparison.component';
import { GanttModule } from '@progress/kendo-angular-gantt';
import { DropDownListModule, DropDownTreesModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipMenuModule } from '../../portfolio/tooltip-menu/tooltip-menu.module';
import { LabelModule } from '@progress/kendo-angular-label';
import { ScheduleComparisonComponent } from './schedule-comparison/schedule-comparison.component';
import { NgxGanttModule } from '@worktile/gantt';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DirectivesModule } from '../../../util/directives/directives.module';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatDialogActions, MatDialogContent } from '@angular/material/dialog';
import { DialogEditNotesComponent } from './edit-notes/edit-notes.component';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { CurrentUpdateGanttComponent } from './current-update-gantt/current-update-gantt.component';
import { RouterLink } from '@angular/router';
import { CurrentUpdateGanttChartComponent } from './current-update-gantt/current-update-gantt-chart/current-update-gantt-chart.component';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { FilterModule } from '@progress/kendo-angular-filter';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { GanttGroupByComponent } from './current-update-gantt/gantt-group-by/gantt-group-by.component';
import { DragAndDropModule } from '@progress/kendo-angular-utils';
import { ManageReportsComponent } from './current-update-gantt/manage-reports/manage-reports.component';
import { EditGroupComponent } from './current-update-gantt/manage-reports/edit-group/edit-group.component';
import { ReportSelectionComponent } from './current-update-gantt/manage-reports/report-selection/report-selection.component';
import { WbsStructureComponent } from './current-update-gantt/gantt-group-by/wbs-structure/wbs-structure.component';

@NgModule({
	declarations: [
		ScheduleUpdatesListComponent,
		DialogUnsavedScheduleChangesComponent,
		DialogEditNotesComponent,
		ProjectNotesComponent,
		ScheduleDelaysComponent,
		CriticalPathComparisonComponent,
		ScheduleComparisonComponent,
		CurrentUpdateGanttComponent,
		CurrentUpdateGanttChartComponent,
		GanttGroupByComponent,
		ManageReportsComponent,
		EditGroupComponent,
		ReportSelectionComponent,
		WbsStructureComponent,
	],
	imports: [
		CommonModule,
		IonicModule,
		SharedMatModule,
		PipeModule,
		TooltipsModule,
		ButtonsModule,
		GridLayoutModule,
		ExcelModule,
		GridModule,
		WindowModule,
		AddBulkUpdateModule,
		SVGIconModule,
		ProgressBarModule,
		GanttModule,
		MultiSelectModule,
		PopupModule,
		TooltipMenuModule,
		DropDownListModule,
		LabelModule,
		NgxGanttModule,
		InputsModule,
		TabStripModule,
		DirectivesModule,
		MatSelectModule,
		FormsModule,
		MatIconButton,
		MatDialogContent,
		MatDialogActions,
		MatButton,
		DialogsModule,
		TreeViewModule,
		RouterLink,
		DropDownTreesModule,
		DatePickerModule,
		FilterModule,
		PDFExportModule,
		ReactiveFormsModule,
		DragAndDropModule,
	],
	exports: [
		ScheduleUpdatesListComponent,
		DialogUnsavedScheduleChangesComponent,
		DialogEditNotesComponent,
		ProjectNotesComponent,
		ScheduleDelaysComponent,
		CriticalPathComparisonComponent,
		ScheduleComparisonComponent,
		CurrentUpdateGanttComponent,
		CurrentUpdateGanttChartComponent,
		GanttGroupByComponent,
		ManageReportsComponent,
		EditGroupComponent,
		ReportSelectionComponent,
		WbsStructureComponent,
	],
})
export class ScheduleUpdatesListModule {}
