<div class='card notes-visualizer-anchor regular-container' [class.saas-risk-component]='user?.userType === "saasRisk"'>
	@if (!visualizer && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK") {
		<div style="break-after: page">
			<kendo-gridlayout
				[rows]="['42.4px', 'calc(100% - 42.2px)']"
				[cols]="['100%']"
				[gap]="{ rows: 0, cols: 0 }"
				class='grid'
				style='page-break-inside: avoid;'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container'>
					<kendo-gridlayout
						[rows]="['42.4px']"
						[cols]="['250px', 'calc(100% - 255px)']"
						[gap]="{ rows: 0, cols: 5 }"
						class='full-width'
					>
						<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
							<div class="card-header">
								Schedule Impact Analysis
							</div>
							<tooltip-open type='schedule-impact-analysis'></tooltip-open>
						</kendo-gridlayout-item>
						<kendo-gridlayout-item [row]='1' [col]='2' class='row-start-aligned-container' style='justify-content: flex-end;'>
					<span style='display: inline-flex; align-items: center;float: right' kendoTooltip>
					<button kendoButton themeColor='base' title="Export Report (XLSX)" class='header-btn upload-btn'
									(click)="exportImpactAnalysis.emit()"
									[disabled]="scheduleStorage.isLoading || !(projectService.$currentProjectReport | async)?.progressDelayHistorical || (projectService.$currentProjectReport | async)?.updateIds.length <= 1"
									style='margin-right: 10px;'
									[imageUrl]='"/assets/icons/newIcons/excelExport.svg"'>
					</button>
					</span>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				</kendo-gridlayout-item>
				<!--		<kendo-gridlayout-item [row]='2' [col]='1' class='warning-div' style='height: 253px;'>
							<img src='assets/img/schedule-impact-analysis-teaser.png' class='blurred'>
							<span style='text-align: center; z-index: 2; font-size: 24px; font-weight: bold;'>
								Schedule Impact Analysis Coming Soon
							</span>
						</kendo-gridlayout-item>-->
				@if ((projectService.$currentProjectReport | async)?.updateIds.length > 1) {
					<kendo-gridlayout-item [row]='2' [col]='1'>
						<app-schedule-delays
							[projectInfo]='$projectData | async'
							[pageSize]='8'
							[hideSummary]='true'
							[exportEvent]='exportImpactAnalysis'
							(activityClicked)='activityClickedDelay($event)'
							[clearGridSelection]='clearDelaySelection'
							id='scheduleDelaysComponent'
						></app-schedule-delays>
					</kendo-gridlayout-item>
				} @else {
					<kendo-gridlayout-item [row]='2' [col]='1'>
						<div class='warning-div' style='height: 150px;'>
							Schedule Analysis requires at least 1 update to enable
						</div>
					</kendo-gridlayout-item>
				}
			</kendo-gridlayout>
		</div>
	}
	@if (visualizer && !scheduleStorage.isLoading) {
		<div style='padding: 15px;'>
			<div class *ngFor='let element of (dataSource !== null && dataSource !== undefined) ? dataSource : []' style='margin-bottom: 0.5em;'>
				{{element.updateName}}<br>
				<span *ngIf='element.criticalPathNotes !== "" && element.criticalPathNotes !== null && element.criticalPathNotes !== undefined'><strong>{{element.criticalPathNotes}}</strong></span>
				<span *ngIf='element.criticalPathNotes === "" || element.criticalPathNotes === null || element.criticalPathNotes === undefined'><em>No Critical Path Notes Provided for this Update</em></span>
			</div>
		</div>
	}
</div>

@if (currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK") {
	<app-critical-path-comparison [selectedDelayActivity]='selectedDelayActv' (selectionByClick)='ganttSelection($event)'></app-critical-path-comparison>
	<app-schedule-comparison></app-schedule-comparison>
}


