import { AfterViewInit, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ButtonGroupModule, ButtonModule } from '@progress/kendo-angular-buttons';
import { ExcelExportModule, Workbook, WorkbookSheet } from '@progress/kendo-angular-excel-export';
import { ExcelModule, GridComponent, GridModule, SharedModule } from '@progress/kendo-angular-grid';
import { GridLayoutModule } from '@progress/kendo-angular-layout';
import { IonicModule } from '@ionic/angular';
import { PipeModule } from '../../../util/pipes/pipe.module';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { ScheduleUpdatesListModule } from '../schedule-updates-list/schedule-updates-list.module';
import { TooltipMenuModule } from '../../portfolio/tooltip-menu/tooltip-menu.module';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { UserService } from '../../../services/common/user.service';
import { ProjectDashboardService } from '../../../services/project/project.service';
import { BehaviorSubject } from 'rxjs';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { ComparisonData, comparisonStats } from '@rhinoworks/analytics-calculations';
import { ScheduleStorageService } from '../../../services/project/schedule-storage.service';
import { Xer } from '@rhinoworks/xer-parse';
import { ScheduleAnalysisColumn } from '../qc/schedule-analysis/schedule-analysis.component';
import { createExcelSheetsFromComparisonData } from './compare-util';
import { FileSelectModule } from '@progress/kendo-angular-upload';
import * as Papa from 'papaparse';
import { take } from 'rxjs/operators';
import { format, isValid } from 'date-fns';

export interface ScheduleComparisonRow {
	Name: string;
	Project: number;
	Activities: number;
	Dates: number;
	Progress: number;
	Cost: number;
	Resources: number;
	Logic: number;
}

const compareSheetTitle: Record<keyof ComparisonData, string> = {
	addedTasks: 'Added Activities',
	deletedTasks: 'Deleted Activities',
	changedNameTasks: 'Changed Activity Descriptions',
	changedCompleteTypeTasks: 'Changed Percent Complete Types',
	changedTypeTasks: 'Changed Activity Types',
	changedTargetDurTasks: 'Changed Original Durations',
	changedCalendarTasks: 'Changed Activity Calendars',
	changedActvCodes: 'Changed Activity Codes',
	changedActvAssignments: 'Changed Actv Code Assignments',
	changedWbs: 'Changed WBS Nodes',
	changedWbsAssignment: 'Changed WBS Assignments',
	changedCalendars: 'Changed Calendars',
	changedPctCompleteTasks: 'Changed Percent Completes',
	changedRemainingDurTasks: 'Changed Remaining Durations',
	changedTotalFloatTasks: 'Changed Total Floats',
	changedFreeFloatTasks: 'Changed Free Floats',
	shouldStartTasks: 'Missed Start Dates',
	shouldFinishTasks: 'Missed Finish Dates',
	changedCriticalityTasks: 'Changed Critical Status',
	changedActStartTasks: 'Changed Actual Starts',
	changedActFinishTasks: 'Changed Actual Finishes',
	acceleratedStarts: 'Accelerated Starts',
	acceleratedFinishes: 'Accelerated Finishes',
	delayedStarts: 'Delayed Starts',
	delayedFinishes: 'Delayed Finishes',
	addedPredecessors: 'Added Relationships',
	deletedPredecessors: 'Deleted Relationships',
	addedConstraintTasks: 'Added Constraints',
	deletedConstraintTasks: 'Deleted Constraints',
	changedConstraintDateTasks: 'Changed Constraint Dates',
	changedLagPredecessors: 'Changed Relationship Lags',
	changedActvBudgetCost: 'Changed Activity Budgeted Costs',
	changedActvActCost: 'Changed Activity Actual Costs',
	changedActvForecastCost: 'Changed Activity Forecast Costs',
	changedActvBudgetQty: 'Changed Activity Budgeted Qty',
	changedActvActQty: 'Changed Activity Actual Qty',
	changedActvForecastQty: 'Changed Activity Forecast Qty',
	addedResources: 'Added Resources',
	deletedResources: 'Deleted Resources',
	addedCostAccounts: 'Added Cost Accounts',
	deletedCostAccounts: 'Deleted Cost Accounts',
};
@Component({
	selector: 'app-compare',
	standalone: true,
	imports: [
		AsyncPipe,
		ButtonModule,
		ExcelExportModule,
		ExcelModule,
		GridLayoutModule,
		GridModule,
		IonicModule,
		NgForOf,
		NgIf,
		PipeModule,
		ProgressBarModule,
		SVGIconModule,
		ScheduleUpdatesListModule,
		SharedModule,
		SharedModule,
		TooltipMenuModule,
		TooltipModule,
		WindowModule,
		FileSelectModule,
		ButtonGroupModule,
	],
	templateUrl: './compare.component.html',
	styleUrl: './compare.component.scss',
})
export class CompareComponent implements OnInit, AfterViewInit {
	@ViewChild('compareGrid')
	public grid: GridComponent;
	public windowTop = 100; // Prevent window from opening out of the screen
	public opened = false;
	public comparisons: ScheduleComparisonRow[] = [
		{
			Name: 'Baseline vs Update 1',
			Project: 2,
			Activities: 50,
			Dates: 3,
			Progress: 90,
			Cost: 10000,
			Logic: 4,
			Resources: 0,
		},
	];

	public needsRecalc = new BehaviorSubject<boolean>(false);
	public compareIndex = 0;
	public title = '';
	public gridTitle = '';
	public comparisonData: ComparisonData;
	public loading = false;
	public loadingGrid = false;
	public updateIds: string[] = [];
	public gridData = [];
	public customPrevXer: Xer;
	public customNextXer: Xer;
	public gridColumns: Array<{ field: string; title: string; format?: string }> = []; // kendo complains when keys have spaces
	public selectedGroup = '';
	public toggleable = true;
	constructor(
		public userService: UserService,
		public projectService: ProjectDashboardService,
		public scheduleService: ScheduleStorageService,
		private ngZone: NgZone
	) {}

	ngOnInit() {
		this.projectService.$currentProjectData.subscribe((project) => {
			this.updateIds = project.updateIds;
		});
		this.projectService.$currentProjectReport.subscribe((report) => {
			if (!report) {
				return;
			}
			if (!report.compareSummaries) {
				this.needsRecalc.next(true);
				return;
			}
			this.comparisons = report.compareSummaries
				.map((summary, index) => {
					const prevName = index === 0 ? 'Baseline' : `Update ${index}`;
					if (!summary) {
						return undefined;
					}
					return {
						Name: `${prevName} vs Update ${index + 1}`,
						Project: summary.project,
						Activities: summary.activities,
						Dates: summary.dates,
						Progress: summary.progress,
						Cost: summary.cost,
						Logic: summary.logic,
						Resources: summary.resources,
					};
				})
				.filter((s) => s);
		});
	}

	public ngAfterViewInit(): void {
		this.fitColumns();
	}

	public onDataStateChange(): void {
		this.fitColumns();
	}

	public close(): void {
		this.opened = false;
	}

	public async open(index: number) {
		this.compareIndex = index;
		this.windowTop = 100;
		if (index >= 0) {
			this.loading = true;
			const prevName = index === 0 ? 'Baseline' : `Update ${index}`;
			this.title = `${prevName} vs Update ${index + 1}`;
			this.opened = true;
			const prevXerData = await this.scheduleService.grabUpdateXer(this.updateIds[index]);
			const currentXerData = await this.scheduleService.grabUpdateXer(this.updateIds[index + 1]);
			const prevXer = new Xer(prevXerData);
			const currentXer = new Xer(currentXerData);
			const stats = comparisonStats(prevXer, currentXer);
			this.comparisonData = stats.allData;
			for (const [k, v] of Object.entries(this.comparisonData)) {
				this.comparisonData[k] = v.map((item) => {
					const newItem: Record<string, unknown> = {};
					for (const key of Object.keys(v[0])) {
						const asDate = new Date(item[key]);
						if (key.endsWith('_date') && isValid(asDate) && asDate.getTime() > 10000) {
							item[key] = format(asDate, 'MM/dd/yyyy');
						}
						newItem[key.replaceAll(' ', '_').replace('%', 'PCT')] = key.includes('%')
							? parseInt(item[key]) / 100
							: item[key];
					}
					return newItem;
				});
			}
			delete this.comparisonData.changedActvForecastCost;
			delete this.comparisonData.changedActvBudgetQty;
			delete this.comparisonData.changedActvActQty;
			delete this.comparisonData.changedActvForecastQty;
			delete this.comparisonData.addedResources;
			delete this.comparisonData.deletedResources;
			delete this.comparisonData.addedCostAccounts;
			delete this.comparisonData.deletedCostAccounts;
			this.loading = false;
		} else {
			this.title = `Custom Comparison`;
			this.customPrevXer = null;
			this.customNextXer = null;
			this.comparisonData = null;
			this.opened = true;
		}
		setTimeout(() => {
			window.scrollTo({ top: 0, behavior: 'instant' });
		}, 500);
	}

	public selectStat(items: typeof this.gridData) {
		this.loadingGrid = true;
		this.gridTitle = Object.entries(compareSheetTitle).find(([field]) => items === this.comparisonData[field])[1];
		const sampleItem = items[0];
		this.gridColumns = Object.keys(sampleItem).map((field) => ({
			title: field.replaceAll('_', ' ').replace('PCT', '%'),
			field: field.replaceAll(' ', '_').replace('%', 'PCT'),
			format: field.includes('PCT') ? 'p0' : null,
		}));
		this.gridData = items;
		setTimeout(() => {
			this.loadingGrid = false;
			this.fitColumns();
		}, 200);
		setTimeout(() => {
			this.fitColumns();
		}, 2000);
	}

	public async exportSheets(indices: number[]) {
		if (indices[0] === -1) {
			const sheets: WorkbookSheet[] = createExcelSheetsFromComparisonData(this.comparisonData, compareSheetTitle);
			const workbook = new Workbook({
				sheets,
			});
			workbook.toDataURL().then((dataUrl) => {
				saveAs(dataUrl, `Custom Comparison.xlsx`);
			});
			return;
		}
		for (const index of indices) {
			const prevName = index === 0 ? 'Baseline' : `Update ${index}`;
			const prevXerData = await this.scheduleService.grabUpdateXer(this.updateIds[index]);
			const prevXer = new Xer(prevXerData);
			const currentXerData = await this.scheduleService.grabUpdateXer(this.updateIds[index + 1]);
			const currentXer = new Xer(currentXerData);
			const stats = comparisonStats(prevXer, currentXer);
			const data = stats.allData;
			const sheetName = `${prevName} vs Update ${index + 1}`;
			const sheets: WorkbookSheet[] = createExcelSheetsFromComparisonData(data, compareSheetTitle);
			const workbook = new Workbook({
				sheets,
			});
			const dataUrl = await workbook.toDataURL();
			saveAs(dataUrl, `${sheetName}.xlsx`);
		}
	}

	handleFileInput(event: Event, fileNum: number): void {
		const input = event.target as HTMLInputElement;
		if (input?.files?.length) {
			const file = input.files[0];
			this.parseXerFile(file, fileNum);
		}
	}

	parseXerFile(file: File, fileNumber: number): void {
		this.loading = true;
		const reader: FileReader = new FileReader();
		reader.readAsText(file);
		reader.onload = () => {
			const data: string = reader.result as string;
			Papa.parse<string[]>(data, {
				header: false, // Set to false to get 2D array
				quoteChar: '""',
				complete: (result) => {
					if (fileNumber === 1) {
						this.customPrevXer = new Xer(result.data);
					} else {
						this.customNextXer = new Xer(result.data);
					}
					this.loadXers(this.customPrevXer, this.customNextXer);
				},
				error: (error) => {
					console.error('Error parsing CSV:', error);
				},
			});
		};
	}

	loadXers(prevXer?: Xer, currentXer?: Xer) {
		if (!prevXer || !currentXer) {
			this.comparisonData = null;
			this.loading = false;
			return;
		}
		this.loading = true;
		const stats = comparisonStats(prevXer, currentXer);
		this.comparisonData = stats.allData;
		setTimeout(() => {
			this.loading = false;
		}, 200);
	}

	private fitColumns(): void {
		this.ngZone.onStable
			.asObservable()
			.pipe(take(1))
			.subscribe(() => {
				this.grid?.autoFitColumns();
			});
	}
}
