export interface AegisScoreVariables {
	criticalPathDuration: number;
	totalFloat: number;
	actualDuration: number;
	numTasksCompleted: number;
	numProjectedNumTasksCompletedInPeriod: number;
	numTasksCompletedInPeriod: number;
	projectedNumBaseTasksCompleted: number;
	numTasksCompletedOnTime: number;
	numTotalTasks: number;
	numPreviousNearCritActivitiesCompleted: number;
	numPreviousNearCritActivities: number;
}

export function getAegisScoreVars(): Required<AegisScoreVariables> {
	return {
		criticalPathDuration: 0,
		totalFloat: 0,
		actualDuration: 0,
		numTasksCompleted: 0,
		numProjectedNumTasksCompletedInPeriod: 0,
		numTasksCompletedInPeriod: 0,
		projectedNumBaseTasksCompleted: 0,
		numTasksCompletedOnTime: 0,
		numTotalTasks: 0,
		numPreviousNearCritActivitiesCompleted: 0,
		numPreviousNearCritActivities: 0,
	};
}

/**
 * Critical Path Length Index:
 * The CPLI indicates the probability of finishing a project on time. It is a function of the total float value and the remaining time left on the project. A
 CPLI of 1.0 indicates healthy progress and projects an on-time project completion. A CPLI greater than 1.0 indicates that a project is progressing
 more efficiently than planned, and is likely to reach the final milestone on time or better. A CPLI below 1.0 indicates a project is at risk of not
 meeting the project deadline.
 * @param criticalPathLength
 * @param totalFloat
 */
export function cpli(criticalPathLength: number = 0, totalFloat: number = 0): number {
	if (!criticalPathLength) {
		return 0;
	}
	return Math.max(0, Math.min(1, (criticalPathLength + totalFloat) / criticalPathLength));
}

/**
 * Total Float Consumption Index:
 * This is a complementary metric to the CPLI which indicates the probability of finishing a project on time. It is a function of the total float value and
 the actual duration expended so far on the project. A TFCI of 1.0 indicates healthy progress and projects an on-time project completion. A TFCI
 greater than 1.0 indicates that a project is progressing more efficiently than planned, and is likely to reach the final milestone on time or better. A
 TFCI below 1.0 indicates a project is at risk of not meeting the project deadline.
 * @param actualDuration
 * @param totalFloat
 */
export function tfci(actualDuration: number = 0, totalFloat: number = 0): number {
	if (!actualDuration) {
		return 0;
	}
	return Math.min(1, 1 - totalFloat / actualDuration);
}

export function bei(numTasksCompleted: number = 0, projectedNumBaseTasksCompleted: number = 0): number {
	if (!projectedNumBaseTasksCompleted) {
		return 0;
	}
	return Math.min(1, numTasksCompleted / projectedNumBaseTasksCompleted);
}

export function cei(numTasksCompletedInPeriod: number = 0, numProjectedNumTasksCompletedInPeriod: number = 0): number {
	if (!numProjectedNumTasksCompletedInPeriod) {
		return 0;
	}
	return numTasksCompletedInPeriod / numProjectedNumTasksCompletedInPeriod;
}

export function tda(numTasksCompletedOnTime: number = 0, numTotalTasks: number = 0): number {
	if (!numTotalTasks) {
		return 0;
	}
	return numTasksCompletedOnTime / numTotalTasks;
}

export function nccr(
	numPreviousNearCritActivitiesCompleted: number = 0,
	numPreviousCritActivities: number = 0
): number {
	if (!numPreviousCritActivities) {
		return 0;
	}
	return numPreviousNearCritActivitiesCompleted / numPreviousCritActivities;
}

export function aegisScore(
	cpli: number = 0,
	tfci: number = 0,
	bei: number = 0,
	cei: number = 0,
	tda: number = 0,
	nccr: number = 0
): number {
	return 0.2 * cpli + 0.2 * tfci + 0.15 * bei + 0.15 * cei + 0.1 * tda + 0.2 * nccr;
}
