export function projectPredictability(
	cpRiskScore: number | undefined,
	cpReliabilityScore: number,
	CPLI: number,
	BEI: number,
	mcScore: number | undefined,
	isBaseline: boolean = false
): number {
	if (isBaseline) {
		return (
			((CPLI + BEI) / 2 + cpReliabilityScore + (mcScore || 0)) /
			(mcScore === undefined || Number.isNaN(mcScore) ? 2 : 3)
		);
	}
	if (mcScore === undefined || Number.isNaN(mcScore)) {
		return (cpRiskScore + cpReliabilityScore + (Math.min(100, CPLI) + Math.min(100, BEI)) / 2) / 3;
	}
	return (
		0.5 * mcScore +
		0.2 * cpRiskScore +
		0.2 * cpReliabilityScore +
		(0.1 * (Math.min(100, CPLI) + Math.min(100, BEI))) / 2
	);
}

export function criticalPathRisk(cpfDelta: [number, number, number]): number {
	return (
		Math.max(0, Math.min(0.5 + 0.015 * cpfDelta[0], 0.5)) +
		Math.max(0, Math.min(0.3 + 0.01 * cpfDelta[1], 0.3)) +
		Math.max(0, Math.min(0.2 + 0.0075 * cpfDelta[2], 0.2))
	);
}
