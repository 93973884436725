<fieldset style="height: 365px" [ngClass]="{'padding-db': fromNewProject}">
	<legend>Edit/Add Schedule Sync</legend>
	<form [formGroup]="scheduleFormGroup" style="padding-top: 5px">
		<kendo-formfield showHints="initial">
			<!-- Row 1 -->
			<div [ngClass]="{'form-row': fromNewProject}">
				<label>Connection</label>
				<kendo-combobox
					[disabled]="fieldsDisabled"
					formControlName="connection"
					[(ngModel)]='databaseInfo.selectedConnection'
					(valueChange)='loadNewDatabasesToSelect($event)'
					[data]="selectionConnectionList"
					[textField]="'friendly_name'"
					[valueField]="'id'"
					[filterable]="true"
					(filterChange)="this.selectionConnectionList = autoComplete($event, this.rawSelectionConnectionList, 'connection')"
				>
				</kendo-combobox>
			</div>

			<!-- Row 2 -->
			<div [ngClass]="{'form-row': fromNewProject}">
				<label>Database</label>
				<kendo-combobox
					[disabled]="fieldsDisabled"
					formControlName="database"
					[(ngModel)]='databaseInfo.database'
					(valueChange)='loadNewFilterCodesAndProjects($event)'
					[data]="selectionDatabaseList"
					[textField]="'name'"
					[valueField]="'id'"
					[valuePrimitive]="true"
					[placeholder]="'- Select -'"
					[filterable]="true"
					(filterChange)="this.selectionDatabaseList = autoComplete($event, this.rawSelectionDatabaseList, 'database')"
				>
				</kendo-combobox>
			</div>

			<!-- Row 3 -->
			<div [ngClass]="{'form-row': fromNewProject}">
				<label>Mode</label>
				<kendo-dropdownlist
					[disabled]="fieldsDisabled"
					formControlName="mode"
					[ngModel]="selectedMode"
					[data]="modeOptions"
					textField="text"
					valueField="value"
					(valueChange)="adjustScheduleSelectFromMode($event)"
				></kendo-dropdownlist>
			</div>

			<!-- Row 4 (Two dropdowns on the same line) -->
			<div class="same-row-grouping">
				<kendo-formfield>
					<kendo-label text="Select Schedule(s)"></kendo-label>
					<kendo-combobox
						[disabled]="fieldsDisabled"
						formControlName="filterCode"
						[hidden]="!filtersShowing"
						[(ngModel)]='databaseInfo.filterCode'
						(valueChange)='checkPCatFilterRequest($event)'
						[data]="selectionPCatList"
						[textField]="'name'"
						[valueField]="'id'"
						[valuePrimitive]="true"
						[placeholder]="'- Select -'"
						[filterable]="true"
						(filterChange)="this.selectionPCatList = autoComplete($event, this.rawSelectionPCatList, 'filterCode')"
					>
					</kendo-combobox>
				</kendo-formfield>

				<kendo-formfield>
					<kendo-label text=""></kendo-label>
					<kendo-combobox
						[disabled]="fieldsDisabled"
						formControlName="filterValue"
						[hidden]="!filtersShowing"
						[(ngModel)]='databaseInfo.filterCodeValue'
						(valueChange)='filterValueChangeRequest($event)'
						[data]="selectionPCatValList"
						[textField]="'value'"
						[valueField]="'id'"
						[valuePrimitive]="true"
						[placeholder]="'- Select -'"
						[filterable]="true"
						(filterChange)="this.selectionPCatValList = autoComplete($event, this.rawSelectionPCatValList, 'filterCodeValue')"
					>
					</kendo-combobox>
				</kendo-formfield>
			</div>

			<!-- Row 5 -->
			<div [ngClass]="{'form-row': fromNewProject}">
				<label></label>
				<kendo-dropdowntree
					formControlName="schedule"
					class="project-tree"
					style="background-color: transparent !important;"
					kendoDropDownTreeExpandable
					[ngModel]="preLoadedProject"
					(valueChange)='handleProjectNumber($event)'
					[kendoDropDownTreeHierarchyBinding]="selectionProjectList"
					(close)="disableCloseForParents($event)"
					[textField]="'item.wbs_name'"
					[valueField]="'item.id'"
					[placeholder]="'- Select -'"
					[childrenField]="'children'"
					[disabled]="fieldsDisabled"
				>
					<ng-template kendoDropDownTreeNodeTemplate let-dataItem>
						<span [ngClass]="{'disabled-item': dataItem.item.proj_flag === (onlyCanSelectSingleSchedule ? 'N' : 'Y')}">
							{{ dataItem.item.wbs_name }}
					</span>
					</ng-template>
				</kendo-dropdowntree>
			</div>

			<!-- Row 6 -->
			<div [ngClass]="{'form-row': fromNewProject}">
				<label>Frequency</label>
				<kendo-dropdownlist
					[disabled]="fieldsDisabled"
					formControlName="frequency"
					[ngModel]="selectedFrequency"
					[data]="frequencyOptions"
					textField="text"
					valueField="value"
					(valueChange)="updateFrequency($event)"
				></kendo-dropdownlist>
			</div>

			<!-- Bottom Row (Checkbox and Submit Button) -->
			<div class="form-bottom">
				<div class="checkbox-container">
					<kendo-checkbox formControlName="isActive" [disabled]="fieldsDisabled" (checkedStateChange)="updateSyncActive($event)" [checkedState]="isSyncActive"></kendo-checkbox>
					<kendo-label text="Sync is Active"></kendo-label>
				</div>

				<div class="buttons-form-bottom">
					@if (this.hasExistingSync) {
						<button kendoButton
										themeColor="error"
										(click)="removeSyncSetting()"
						>
							Delete
						</button>

						<button kendoButton
										[hidden]="editClicked"
										themeColor="warning"
										(click)="editSyncSetting()"
						>
							Edit
						</button>
					}

					<button kendoButton
									[hidden]="fieldsDisabled"
									[disabled]="!this.scheduleFormGroup.valid"
									themeColor="success"
									(click)="onSubmit()"
					>
						{{editClicked ? 'Save' : 'Add'}}
					</button>
				</div>
			</div>


			<kendo-formerror [align]="'end'">Error: This field is required</kendo-formerror>
		</kendo-formfield>
	</form>
</fieldset>
