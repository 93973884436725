<div class='card' *ngIf='(projects | async) === null && !loading'>
	<kendo-gridlayout
		[rows]="['42.4px', '303px']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' [class.overviewHeader]="isOverview">
			<div class="card-header">
				S-Curve <span style="font-size: 9px; position: relative; top: -1px;">(BETA) </span>
			</div>
			<tooltip-open type='{{isOverview ? "overview" : "s-curve"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1'>
			<div style='height: 300px; margin-bottom: 3px'>
				<app-chart
					[categories]='categories'
					[seriesData]='seriesData'
					[legendVisible]='true'
					[valueAxis]='valueAxisItemSettings'
					[legendClickable]='true'
					[categoryPlotBands]="categoryPlotBands"
				></app-chart>
			</div>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>
<app-chart
	[categories]='categories'
	[seriesData]='seriesData'
	[legendVisible]='false'
	[valueAxis]='valueAxisItemSettings'
	*ngIf='(projects | async) !== null && !loading'
></app-chart>
