import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CalculationFieldInterface, ProjectReportInterface } from '../../../../models/ProjectReport/ProjectReport';
import { ProjectInterface } from '../../../../models/Project';
import { AxisSettings, SeriesData, SeriesDataSettings } from '../../../../models/ChartSettings';
import { hasObjChanged } from '../../../../util/projects';
import { CurrentProjectReport, ProjectDashboardService } from '../../../../services/project/project.service';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { differenceInCalendarDays, isValid } from 'date-fns';
import { UpdateInterface } from '../../../../models/Update/Task';
import { ProjectListColumnGroup } from '../../../portfolio/project-list/project-list/project-list.component';
import { cleanDateUTC } from '../../../../util/pipes/date.pipe';

export type ScheduleCompressionColumn = {
	field: string;
	title: string;
	width?: number;
	type?: string;
	sequence?: number;
};

export type ScheduleCompressionData = {
	schedule: string;
	dataDate: string;
	currentCompletion: string;
	remainingDuration: number;
	remainingActivities: number;
	activitiesPerDay: number;
	baselineCompression: string;
	updateCompression: string;
};

export const allColumns = require('./schedule-compression.columns.json') as {
	columns: ScheduleCompressionColumn[];
};

@Component({
	selector: 'app-schedule-compression',
	templateUrl: './schedule-compression.component.html',
	styleUrls: ['./schedule-compression.component.scss'],
})
export class ScheduleCompressionComponent implements OnInit {
	@Input() isOverview: boolean = false;
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	activitiesPerDayArray = [];
	categories = [];
	scheduleCompressionIndexArray = [];
	baselineCompressionIndexArray = [];
	seriesData: SeriesDataSettings[] = [];
	valueAxisItemSettings: AxisSettings[] = [
		{
			title: {
				text: 'Update Compression',
				visible: true,
			},
			labels: {
				format: '{0}%',
			},
			majorGridLines: {
				visible: true,
			},
			name: 'updateCompression',
		},
		{
			title: {
				text: 'Baseline Compression',
				visible: true,
			},
			labels: {
				format: '{0}%',
			},
			majorGridLines: {
				visible: true,
			},
			name: 'baselineCompression',
		},
	];
	gridData: ScheduleCompressionData[];

	constructor(
		public projectService: ProjectDashboardService,
		public scheduleStorage: ScheduleStorageService
	) {}

	ngOnInit(): void {
		this.scheduleStorage.$allUpdates.pipe(takeUntil(this._unsubscribeAll)).subscribe((updates) => {
			if (!updates || !this.projectService.$currentProjectReport.value) {
				return;
			}
			this.updateChartData(updates, this.projectService.$currentProjectReport.value);
		});
		this.projectService.$currentProjectReport.pipe(takeUntil(this._unsubscribeAll)).subscribe((report) => {
			if (!report || !this.scheduleStorage.$allUpdates.value) {
				return;
			}
			this.updateChartData(this.scheduleStorage.$allUpdates.value, report);
		});
	}

	updateChartData(updates: UpdateInterface[], report: CurrentProjectReport): void {
		const activitiesPerDayArray = [];
		const scheduleCompressionIndexArray = [];
		const baselineCompressionIndexArray = [];
		const categories = [];
		const barColorArray = [];

		for (let i = 0; i < updates.length; i++) {
			const update = updates[i];
			const finishMilestone = update?.finishMilestone;

			const currentCompletionFromUpdate = new Date(finishMilestone?.act_end_date || finishMilestone?.early_end_date);
			const currentCompletion: Date = new Date(
				currentCompletionFromUpdate.getUTCFullYear(),
				currentCompletionFromUpdate.getUTCMonth(),
				currentCompletionFromUpdate.getUTCDate(),
				currentCompletionFromUpdate.getUTCHours()
			);

			const dataDateFromUpdate: Date = new Date(
				update?.dataDate || report?.projectCompletionTrend?.projectCompletionTrendArray?.[i]?.dataDate
			);
			//timezones make non-matching dates :) so we make UTC
			const dataDate: Date = new Date(
				dataDateFromUpdate.getUTCFullYear(),
				dataDateFromUpdate.getUTCMonth(),
				dataDateFromUpdate.getUTCDate(),
				dataDateFromUpdate.getUTCHours()
			);
			const remainingDuration = isValid(currentCompletion)
				? differenceInCalendarDays(currentCompletion, dataDate)
				: undefined;

			activitiesPerDayArray.push((report.remainingActivities?.[i] || 0) / remainingDuration);
			scheduleCompressionIndexArray.push(
				i === 0 ? 100 : (activitiesPerDayArray[i] / activitiesPerDayArray[i - 1]) * 100
			);
			baselineCompressionIndexArray.push((activitiesPerDayArray[i] / activitiesPerDayArray[0]) * 100);
			categories.push(i === 0 ? 'Baseline' : `Update ${i}`);
		}

		this.categories = categories;
		this.activitiesPerDayArray = activitiesPerDayArray;
		this.baselineCompressionIndexArray = baselineCompressionIndexArray;
		this.scheduleCompressionIndexArray = scheduleCompressionIndexArray;

		const seriesData: SeriesDataSettings[] = [
			{
				type: 'column',
				data: scheduleCompressionIndexArray,
				name: 'Update Compression',
				visible: true,
				yAxis: 'updateCompression',
				legendItem: {
					type: 'area',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
			{
				type: 'line',
				data: baselineCompressionIndexArray,
				name: 'Baseline Compression',
				color: 'black',
				visible: true,
				yAxis: 'baselineCompression',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
		];

		if (hasObjChanged(this.seriesData, seriesData)) {
			this.seriesData = seriesData;
		}
		if (hasObjChanged(categories, this.categories)) {
			this.categories = categories;
		}

		this.updateTable(updates, report);
	}

	updateTable(updates: UpdateInterface[], report: CurrentProjectReport): void {
		const gridData: ScheduleCompressionData[] = [];
		let i = 0;
		if (updates && report) {
			updates.forEach((update) => {
				const schedule = i === 0 ? 'Baseline' : `Update ${i}`;
				const dataDateFromUpdate: Date = new Date(
					update?.dataDate || report.projectCompletionTrend.projectCompletionTrendArray[i].dataDate
				);
				//timezones make non-matching dates :) so we make UTC
				const dataDateDate: Date = new Date(
					dataDateFromUpdate.getUTCFullYear(),
					dataDateFromUpdate.getUTCMonth(),
					dataDateFromUpdate.getUTCDate(),
					dataDateFromUpdate.getUTCHours()
				);
				const dataDate = isValid(dataDateDate) ? cleanDateUTC(dataDateDate, 'MM/dd/yy') : '';

				const currentCompletionFromUpdate = new Date(
					update?.finishMilestone?.act_end_date || update?.finishMilestone?.early_end_date
				);
				const currentCompletionDate: Date = new Date(
					currentCompletionFromUpdate.getUTCFullYear(),
					currentCompletionFromUpdate.getUTCMonth(),
					currentCompletionFromUpdate.getUTCDate(),
					currentCompletionFromUpdate.getUTCHours()
				);
				const currentCompletion = isValid(currentCompletionDate) ? cleanDateUTC(currentCompletionDate, 'MM/dd/yy') : '';

				const remainingDuration = isValid(currentCompletionDate)
					? differenceInCalendarDays(currentCompletionDate, dataDateDate)
					: undefined;

				const currentUpdateData: ScheduleCompressionData = {
					schedule,
					dataDate,
					currentCompletion,
					remainingDuration,
					remainingActivities: report?.remainingActivities[i] || undefined,
					activitiesPerDay: this.activitiesPerDayArray[i],
					baselineCompression: this.baselineCompressionIndexArray[i],
					updateCompression: this.scheduleCompressionIndexArray[i],
				};
				gridData.push(currentUpdateData);
				i += 1;
			});
		}
		console.log(gridData);
		this.gridData = gridData;
	}

	isRoundedOver100(num) {
		return Math.round(num) > 100;
	}

	protected readonly allColumns = allColumns;
	protected readonly of = of;
	protected readonly validDate = isValid;
}
