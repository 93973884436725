<kendo-chart class='aegis-chart' (render)="onRender($event)" (legendItemClick)="legendClickable ? clickableLegendClick($event) : onLegendItemClick($event)" [seriesColors]='seriesColors'>
	<kendo-chart-legend position="bottom" orientation="horizontal" [visible]='legendVisible' [margin]='0' [labels]='{font: valueAxis[0].labels.format === "portfolio" ? "10px Muli, Helvetica Neue, Arial, sans-serif": "14px Muli, Helvetica Neue, Arial, sans-serif"}'>
	</kendo-chart-legend>
	<kendo-chart-title [text]="title" [padding]='0' [margin]='0' *ngIf='title' [color]="title === 'blank' ? 'transparent' : '#424242'"></kendo-chart-title>
	<kendo-chart-subtitle [text]="subtitle" [padding]='0' [margin]='0' *ngIf='subtitle'> </kendo-chart-subtitle>
	<kendo-chart-value-axis>
		<kendo-chart-value-axis-item
			*ngFor="let item of valueAxis"
			[name]="item.name || 'valueAxis'"
			pane="pane"
			[line]='{ visible: false }'
			[title]="{ text: item.title.text, visible: item.title?.visible }"
			[labels]="{ format: item.labels.format, content: labelFunction, step: item?.labels?.format === 'portfolio' ? 2 : 1, font: valueAxis[0].labels.format === 'portfolio' ? '10px Muli, Helvetica Neue, Arial, sans-serif': '12px Muli, Helvetica Neue, Arial, sans-serif' }"
			[plotBands]="item?.plotBands"
			[min]='item?.min'
			[max]='item?.max'
			[majorGridLines]='{ visible: item?.majorGridLines?.visible, step: item?.labels?.format === "portfolio" ? 2 : 1 }'
		>
		</kendo-chart-value-axis-item>
	</kendo-chart-value-axis>
	<kendo-chart-panes>
		<kendo-chart-pane name="pane" [clip]="false">
		</kendo-chart-pane>
	</kendo-chart-panes>
	<kendo-chart-category-axis>
		<kendo-chart-category-axis-item
			name="categoryAxis"
			[line]='{ width: valueAxis[0]?.majorGridLines?.width }'
			[categories]="categories"
			[crosshair]="{ color: '#bbbbbb', visible: true, width: 1 }"
			[title]="{ text: 'Update Number', visible: false }"
			[labels]="{ rotation: 'auto', font: valueAxis[0].labels.format === 'portfolio' ? '10px Muli, Helvetica Neue, Arial, sans-serif': '12px Muli, Helvetica Neue, Arial, sans-serif' }"
			[majorGridLines]='{visible: false}'
			[axisCrossingValue]="crossingValues"
			[plotBands]="categoryPlotBands"
		>
			<kendo-chart-category-axis-item-labels
				[position]="'start'"
				[rotation]="'auto'"
			>
			</kendo-chart-category-axis-item-labels>
		</kendo-chart-category-axis-item>
	</kendo-chart-category-axis>
	<kendo-chart-tooltip [shared]='true'>
		<ng-template kendoChartSeriesTooltipTemplate let-value="value" let-dataItem="dataItem" let-point="point" *ngIf='valueAxis[0].labels.format !== "{0}%" && valueAxis[0].labels.format !== "c" && valueAxis[0].labels.format !== "c0" && valueAxis[0].labels.format !== "portfolio" && valueAxis[0].labels.format !== "{0}" && valueAxis[0].labels.format !== "{0} cd"'>
			<b>
				{{ value | number : '1.2-2' }}
			</b>
		</ng-template>
		<ng-template kendoChartSeriesTooltipTemplate let-value="value" let-dataItem="dataItem" let-point="point" *ngIf='valueAxis[0].labels.format === "c" || valueAxis[0].labels.format === "c0"'>
			<b *ngIf='dataItem.excludeFromTooltip !== true'>
				${{ value | number : '1.0-0' }}
			</b>
		</ng-template>
		<ng-template kendoChartSeriesTooltipTemplate let-value="value" let-dataItem="dataItem" let-point="point" *ngIf='valueAxis[0].labels.format === "portfolio"'>
			${{ value | number : '1.0-0' }}
		</ng-template>
		<ng-template kendoChartSeriesTooltipTemplate let-value="value" let-dataItem="dataItem" let-point="point" *ngIf='valueAxis[0].labels.format === "{0}%"'>
			<b>
				{{ value | number : '1.0-0' }}%
			</b>
		</ng-template>
		<ng-template kendoChartSeriesTooltipTemplate let-value="value" let-dataItem="dataItem" let-point="point" *ngIf='(valueAxis[0].labels.format === "{0}" && !isActivityCompletionChart) || valueAxis[0].labels.format === "{0} cd"'>
			<b>
				{{ value | number : '1.0-0' }} cd
			</b>
		</ng-template>
		<ng-template kendoChartSeriesTooltipTemplate let-value="value" let-dataItem="dataItem" let-point="point" *ngIf='isActivityCompletionChart'>
			<b>
				{{ point.series.name === 'Percentage Completed' ? ((value * 100) | number : '1.0-0') + '%' : (value | number : '1.0-0') + (value === 1 ? ' Activity' : ' Activities') }}
			</b>
		</ng-template>
	</kendo-chart-tooltip>
	<kendo-chart-series>
		<kendo-chart-series-item
			*ngFor='let item of seriesData'
			[type]="item.type"
			[style]="'smooth'"
			[data]="item.data"
			[name]='item.name'
			[opacity]='item.visible ? 100 : 0'
			[markers]='{ visible: item?.visible, type: item?.marker }'
			[legendItem]="item?.legendItem"
			[color]='isActivityCompletionChart ? pointColor : (isSPIChart && item?.name === "Periodic SPI") ? spiPointColor : (isScheduleCompressionChart && item?.name === "Update Compression") ? scheduleCompressionPointColor: item?.color'
			[stack]='item?.stack'
			[labels]='{ content: seriesLabels, visible: isActivityCompletionChart && item.name !== "Percentage Completed", font: "bold 12px Muli, \"Helvetica Neue\", Arial, sans-serif", padding: 0 }'
			[visible]='item?.visible'
			[missingValues]='"gap"'
			[axis]="item?.yAxis"
			[dashType]="item?.dashType"
			[border]="item?.border"
		></kendo-chart-series-item>
	</kendo-chart-series>
</kendo-chart>
