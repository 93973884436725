import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AxisSettings, SeriesData, SeriesDataSettings } from '../../../../models/ChartSettings';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CashFlow } from '../../../../models/ProjectReport/ProjectReport';
import { hasObjChanged } from '../../../../util/projects';
import { addMonths, isBefore, isSameDay, subMonths, toDate } from 'date-fns';
import { ProjectListItem } from '../../../portfolio/project-list/project-list/project-list.component';
import { CostService } from '../../../../services/common/cost.service';
import { PlotBand } from '@progress/kendo-angular-charts';

interface HistoricalPerformanceChartData {
	plannedData: SeriesData[];
	actualData: SeriesData[];
	plannedDataCumulative: SeriesData[];
	actualDataCumulative: SeriesData[];
	categories: string[];
}

@Component({
	selector: 'app-historic-performance',
	templateUrl: './historic-performance.component.html',
	styleUrls: ['./historic-performance.component.scss'],
})
export class HistoricPerformanceComponent implements OnInit {
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	@Input() projects = new BehaviorSubject<Array<ProjectListItem>>(null);
	@Input() loading = false;
	@Input() categoriesHistorical = [];
	@Input() isOverview: boolean = false;
	categories: string[] = [];
	seriesData: SeriesDataSettings[] = [];
	valueAxisItemSettings: AxisSettings[] = [
		{
			name: '',
			title: {
				text: '',
			},
			labels: {
				format: '',
			},
			min: 0,
		},
	];
	plannedData: SeriesData[] = [];
	actualData: SeriesData[] = [];
	months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	currentMonth: string = '';
	categoryPlotBands: PlotBand[] = [];

	constructor(
		public projectService: ProjectDashboardService,
		public costService: CostService
	) {}

	ngOnInit() {
		this.projectService.$currentProjectReport
			.pipe(takeUntil(this._unsubscribeAll), debounceTime(100))
			.subscribe((report) => {
				if (!report?.cashFlowHistorical) {
					return;
				}
				this.valueAxisItemSettings = [
					{
						name: 'periodic',
						title: {
							text: 'Periodic Costs',
						},
						labels: {
							format: 'c0',
						},
						min: 0,
					},
					{
						name: 'cumulative',
						title: {
							text: 'Cumulative Costs',
						},
						labels: {
							format: 'c0',
						},
						min: 0,
					},
				];
				const currentMonthSplit: string[] = report?.projectOverview?.dataDate.toString().split(' ');
				this.currentMonth = currentMonthSplit?.length > 2 ? currentMonthSplit[1] + ' ' + currentMonthSplit[3] : '';
				const monthIndex: number = this.months.findIndex(
					(month) => report?.projectOverview?.dataDate.toString().split(' ')[1] === month
				);
				if (monthIndex !== -1) {
					const prevIndex: number = monthIndex === 0 ? 11 : monthIndex - 1;
					const nextIndex: number = monthIndex === 11 ? 0 : monthIndex + 1;
					this.costService.$monthNames.next([this.months[prevIndex], this.months[monthIndex], this.months[nextIndex]]);
				}
				this.updateChartData(report?.cashFlowHistorical);
			});
		this.projects.subscribe((projects: ProjectListItem[]) => {
			if (projects !== null) {
				const historicCategoriesInterval = setInterval(() => {
					if (this.categoriesHistorical.length > 0) {
						this.valueAxisItemSettings = [
							{
								name: 'periodic',
								title: {
									text: 'Periodic Costs',
									visible: false,
								},
								labels: {
									format: 'portfolio',
								},
								min: 0,
							},
						];
						this.updatePortfolioChartData(projects);
						clearInterval(historicCategoriesInterval);
					}
				}, 500);
			}
		});
	}

	/**
	 * grabs the latest month + previous 5 months worth of periodic historical performance data for the portfolio chart
	 * @param projects
	 */
	updatePortfolioChartData(projects: ProjectListItem[]): void {
		const plannedPeriodicByCategory = new Map<string, number>([]);
		const actualPeriodicByCategory = new Map<string, number>([]);
		let cumulativePlanned = 0;
		let cumulativeActual = 0;
		const latestDate: Date = this.projectService.dateStringToDate(
			this.categoriesHistorical[this.categoriesHistorical.length - 1]
		);
		const prevMonthDate = subMonths(latestDate, 1);
		const prevMonth = prevMonthDate.getMonth();
		const thisMonth = latestDate.getMonth();
		const nextMonthDate = addMonths(latestDate, 1);
		const nextMonth = nextMonthDate.getMonth();
		const prevMonthDateStr = this.projectService.dateToDateStr(prevMonthDate);
		const thisMonthDateStr = this.projectService.dateToDateStr(latestDate);
		const nextMonthDateStr = this.projectService.dateToDateStr(nextMonthDate);
		let thisCumulative = 0;
		let nextCumulative = 0;
		this.costService.$monthNames.next([this.months[prevMonth], this.months[thisMonth], this.months[nextMonth]]);
		projects.forEach((project: ProjectListItem) => {
			const historicData = this.costService.projectHistoricalPerformanceData.get(project._id);
			if (historicData) {
				historicData.plannedPeriodic.forEach((point: SeriesData) => {
					const thisDate: Date = this.projectService.dateStringToDate(point.category);
					if (isBefore(thisDate, latestDate) || isSameDay(thisDate, latestDate)) {
						cumulativePlanned += point.value;
					}
					if (this.categoriesHistorical.includes(point.category)) {
						const existingPlanned = plannedPeriodicByCategory.get(point.category);
						const plannedPeriodic = existingPlanned === undefined ? point.value : existingPlanned + point.value;
						plannedPeriodicByCategory.set(point.category, plannedPeriodic);
					}
					if (point.category === thisMonthDateStr) {
						thisCumulative += point.value;
					}
					if (point.category === nextMonthDateStr) {
						nextCumulative += point.value;
					}
				});
				historicData.actualPeriodic.forEach((point: SeriesData) => {
					cumulativeActual += point.value || 0;
					if (this.categoriesHistorical.includes(point.category)) {
						const existingActual = actualPeriodicByCategory.get(point.category);
						const actualPeriodic = existingActual === undefined ? point.value : existingActual + point.value;
						actualPeriodicByCategory.set(point.category, actualPeriodic);
					}
				});
			}
		});
		this.costService.$previousMonth.next(actualPeriodicByCategory.get(prevMonthDateStr));
		this.costService.$thisMonth.next(thisCumulative);
		this.costService.$nextMonth.next(nextCumulative);
		this.costService.$latestSPI.next(cumulativeActual / (cumulativePlanned || 1));
		const portfolioPlannedData: SeriesData[] = [];
		const portfolioActualData: SeriesData[] = [];
		const categories: string[] = [];
		this.categoriesHistorical.forEach((category: string) => {
			portfolioPlannedData.push({
				category: category.split(' ')[0],
				value: plannedPeriodicByCategory.get(category) || null,
			});
			portfolioActualData.push({
				category: category.split(' ')[0],
				value: actualPeriodicByCategory.get(category) || null,
			});
			categories.push(category.split(' ')[0]);
		});
		const seriesData: SeriesDataSettings[] = [
			{
				type: 'column',
				data: portfolioActualData,
				name: 'Actual',
				visible: true,
				color: 'rgb(0, 89, 255)',
				yAxis: 'periodic',
			},
			{
				type: 'column',
				data: portfolioPlannedData,
				name: 'Baseline',
				visible: true,
				color: 'rgb(95, 202, 70)',
				yAxis: 'periodic',
			},
		];
		if (hasObjChanged(this.seriesData, seriesData)) {
			this.seriesData = seriesData;
		}
		if (hasObjChanged(this.categories, categories)) {
			this.categories = categories;
		}
	}

	/**
	 * updates chart values with new ones
	 * @param costs
	 */
	updateChartData(costs: CashFlow[]): void {
		if (costs.length === 0) {
			return;
		}
		const historicPerfData = this.costService.generateHistoricPerformanceData(costs);
		if (historicPerfData) {
			const seriesData: SeriesDataSettings[] = [
				{
					type: 'column',
					data: historicPerfData.actualPeriodic,
					name: 'Actual',
					visible: true,
					color: 'rgba(0, 89, 255, .75)',
					yAxis: 'periodic',
					border: {
						opacity: 0.35,
					},
				},
				{
					type: 'column',
					data: historicPerfData.plannedPeriodic,
					name: 'Baseline (Early)',
					visible: true,
					color: 'rgba(138, 138, 138, .75)',
					yAxis: 'periodic',
					border: {
						opacity: 0.35,
					},
				},
				{
					type: 'column',
					data: historicPerfData.historicPlannedLatePeriodic,
					name: 'Baseline (Late)',
					visible: true,
					color: 'rgba(0, 0, 0, .75)',
					yAxis: 'periodic',
					border: {
						opacity: 0.35,
					},
				},
				{
					type: 'line',
					data: historicPerfData.actualCumulative,
					name: 'Cumulative Actual',
					visible: true,
					color: 'rgb(0, 89, 255)',
					yAxis: 'cumulative',
					legendItem: {
						type: 'line',
						markers: {
							visible: false,
						},
						highlight: {
							visible: false,
						},
					},
				},
				{
					type: 'line',
					data: historicPerfData.plannedCumulative,
					name: 'Cumulative Baseline (Early)',
					visible: true,
					color: 'rgb(138, 138, 138, .75)',
					yAxis: 'cumulative',
					legendItem: {
						type: 'line',
						markers: {
							visible: false,
						},
						highlight: {
							visible: false,
						},
					},
				},
				{
					type: 'line',
					data: historicPerfData.historicPlannedLateCumulative,
					name: 'Cumulative Baseline (Late)',
					visible: true,
					color: 'rgba(0, 0, 0, .75)',
					yAxis: 'cumulative',
					legendItem: {
						type: 'line',
						markers: {
							visible: false,
						},
						highlight: {
							visible: false,
						},
					},
				},
			];
			if (hasObjChanged(this.seriesData, seriesData)) {
				this.seriesData = seriesData;
			}
			if (hasObjChanged(historicPerfData.categories, this.categories)) {
				this.categories = historicPerfData.categories;
			}
			const plotBands: PlotBand[] = [];
			if (this.currentMonth !== '') {
				const index: number = this.categories.findIndex((i) => i === this.currentMonth);
				if (index !== -1) {
					plotBands.push({
						color: '#c8ddeb',
						from: index,
						opacity: 1,
					});
				}
			}
			this.categoryPlotBands = plotBands;
		}
	}
}
