export interface MitigationRiskScoring {
	probability: number;
	scheduleImpact: number;
	costImpact: number;
	performanceImpact: number;
	qualityImpact: number;
	activityImpact: number;
	impactVarianceMin: number;
	impactVarianceMax: number;
	riskScore(): number;
}
export interface PostMitigationRiskInterface extends MitigationRiskScoring {
	postRanking(): Optimism;
}

export interface MitigationStrategy {
	strategy: 'accept' | 'reduce' | 'mitigate';
	measures: string;
	statusDate: Date;
}

export class PrePostMitigationRisk implements MitigationRiskScoring {
	probability: number = 0;
	scheduleImpact: number = 0;
	costImpact: number = 0;
	performanceImpact: number = 0;
	qualityImpact: number = 0;
	activityImpact: number = 0;
	impactVarianceMin: number = 0;
	impactVarianceMax: number = 0;
	riskScore(): number {
		return (this.probability || 0) * Math.max(this.scheduleImpact || 0, this.costImpact || 0, this.qualityImpact || 0);
	}
}

export type Optimism = 'optimistic' | 'slightly optimistic' | 'neutral' | 'slightly pessimistic' | 'pessimistic';

export class PostMitigationRisk extends PrePostMitigationRisk implements PostMitigationRiskInterface {
	postRanking(): Optimism {
		const riskScore = this.riskScore();
		if (riskScore <= 2) {
			return 'optimistic';
		}
		if (riskScore < 5) {
			return 'slightly optimistic';
		}
		if (riskScore === 5) {
			return 'neutral';
		}
		if (riskScore < 7) {
			return 'slightly pessimistic';
		}
		return 'pessimistic';
	}
}

export type RiskRegister = {
	riskId: number;
	riskName: string;
	mitStatus: string;
	category: string;
	triggerPhase: string;
	riskOwner: MitigationEntity;
	costOwner: MitigationEntity;
	responsibility: MitigationEntity;
	description: string;
	effect: string;
	impactedTaskCodes: string[];
	strategy: MitigationStrategy;
	preMitigation: PrePostMitigationRisk;
	postMitigation: PostMitigationRisk;
	disabled?: boolean;
	costVarianceMin?: number;
	costVarianceMax?: number;
	isDraft?: boolean;
};

export type MitigationEntity = {
	name: string;
	email?: string;
};
