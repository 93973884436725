import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ExpandedMetrics, ExpandedMetricsValues } from '../../../../models/ProjectReport/ExpandedMetrics';
import { CalendarArrayInterface, TaskArrayInterface, TaskPredArrayInterface } from '../../../../models/Update/Task';
import { BehaviorSubject, Subject } from 'rxjs';
import { getLowercaseValues } from '../../../../util/tasks';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ProjectReportInterface } from '../../../../models/ProjectReport/ProjectReport';
import { GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query/dist/npm/sort-descriptor';
import { CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { RestService } from '../../../../services/common/rest.service';
import { AnalyticsDashboardService } from '../../../../services/analytics/analytics.service';
import { saveAs } from 'file-saver';
import { NavigationBarStorageService } from '../../../../services/common/navigation-bar-storage.service';
import { ProjectInterface } from '../../../../models/Project';
import { ProfileCompanyPermission } from '../../../../models/auth/account-user';
import { fileExcelIcon, searchIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { Xer, XerActivity, XerTaskPredecessor } from '@rhinoworks/xer-parse';
import { multipleTies, redundantTies } from '@rhinoworks/analytics-calculations';

const allColumns = require('./schedule-analysis-columns.json') as {
	columns: ScheduleAnalysisColumn[];
	buttons: ScheduleAnalysisButton[];
};

export type ScheduleAnalysisColumn = {
	id: number;
	field: string;
	otherField?: string;
	title: string;
	type: string;
	usePredecessorData: boolean;
	useSuccessorData: boolean;
	width: number;
	format?: string;
};

export type ScheduleAnalysisButton = {
	columnsDisplayed: number[];
	displaySet: string;
	dataType: 'Activity' | 'Relationship';
	name: string;
	title: string;
};

export const constraintDict = {
	CS_ALAP: 'As Late As Possible',
	CS_MANDFIN: 'Mandatory Finish',
	CS_MANDSTART: 'Mandatory Start',
	CS_MEO: 'Finish On',
	CS_MEOA: 'Finish On or After',
	CS_MEOB: 'Finish On or Before',
	CS_MSO: 'Start On',
	CS_MSOA: 'Start On or After',
	CS_MSOOB: 'Start On or Before',
};

export const linkTypeDictionary = {
	PR_FS: 'Finish to Start',
	PR_SF: 'Start to Finish',
	PR_FF: 'Finish to Finish',
	PR_SS: 'Start to Start',
};

export const taskStatusDictionary = {
	TK_Complete: 'Completed',
	TK_NotStart: 'Not Started',
	TK_Active: 'Active',
};

export const taskTypeDictionary = {
	TT_Task: 'Task',
	TT_FinMile: 'Finish Milestone',
	TT_Mile: 'Milestone',
	TT_LOE: 'Level of Effort',
};

export interface RedundantLogicTie {
	'Predecessor ID': string;
	'Predecessor Name': string;
	'Predecessor Task Type': string;
	'Successor ID': string;
	'Successor Name': string;
	'Successor Task Type': string;
	Link: string;
	Lag: number;
}
@Component({
	selector: 'app-schedule-analysis',
	templateUrl: './schedule-analysis.component.html',
	styleUrls: ['./schedule-analysis.component.scss'],
})
export class ScheduleAnalysisComponent implements OnInit, OnDestroy {
	public constraintDict = constraintDict;
	public linkTypeDictionary = linkTypeDictionary;
	public taskStatusDictionary = taskStatusDictionary;
	public taskTypeDictionary = taskTypeDictionary;
	public svgExcel: SVGIcon = fileExcelIcon;
	public svgSearch: SVGIcon = searchIcon;
	@Input() visualizer: boolean = false;
	@Input() small: boolean = false;
	@Input() isOverview: boolean = false;
	@ViewChild(GridComponent)
	public grid: GridComponent;
	public gridView: GridDataResult;
	gridData: Array<any> = [];
	public pageSize = 100;
	public skip = 0;
	public sort: SortDescriptor[] = [];
	public filter: CompositeFilterDescriptor = {
		logic: 'and',
		filters: [],
	};
	selectedColumns: ScheduleAnalysisColumn[] = [];
	loading: boolean = true;

	scheduleAnalysisSearchBarString = 'Search All Activities...';
	currentDisplayButton: string = 'totalActivities';
	currentDisplaySet: ExpandedMetricsValues = [];
	unfilteredDisplaySet: ExpandedMetricsValues = [];
	allActivities = new Map<number, TaskArrayInterface>([]);
	remainingActivities = new Map<number, TaskArrayInterface>([]);
	loes = new Map<number, TaskArrayInterface>([]);
	allTasksArray: TaskArrayInterface[] = [];
	allRelationships = new Map<number, TaskPredArrayInterface>([]);
	calendars = new Map<number, CalendarArrayInterface>([]);
	expandedMetrics = new BehaviorSubject<ExpandedMetrics>({} as ExpandedMetrics);
	allTasks = new Map<number, TaskArrayInterface>([]);
	searchItemKeys: [CalendarArrayInterface | TaskArrayInterface | TaskPredArrayInterface, Set<string>][] = [];
	$fs = new BehaviorSubject<number>(null);
	$ss = new BehaviorSubject<number>(null);
	$ff = new BehaviorSubject<number>(null);
	$sf = new BehaviorSubject<number>(null);
	redundantLogic = new Map<number, XerTaskPredecessor>([]);
	multipleTies = new Map<number, XerTaskPredecessor>([]);
	fsLagPenalty: number = 0;
	missingPredSuccPenalty: number = 0;
	oosCriticalPenalty: number = 0;
	oosNearCriticalPenalty: number = 0;
	oosNonCriticalPenalty: number = 0;
	negLagPenalty: number = 0;
	hardConstraintPenalty: number = 0;
	relationshipDensityPenalty: number = 0;
	durationPenalty: number = 0;
	floatPenalty: number = 0;
	ssffProblematicPenalty: number = 0;
	openStartFinishPenalty: number = 0;
	sfPenalty: number = 0;

	tableTitle = '';
	tableSearch = '';
	tableDataType: 'Activity' | 'Relationship' = 'Activity';

	isExportRunning: boolean = false;
	searchTerm = '';
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	currentProjectCompanyPermissions: ProfileCompanyPermission = null;
	isProblematicScheduleActivitiesBtn: boolean = false;

	scoreImpactGreenColor: string = 'black';
	scoreImpactRedColor: string = 'red';

	constructor(
		public project: ProjectDashboardService,
		private rest: RestService,
		public analyticsService: AnalyticsDashboardService,
		public navBarStorage: NavigationBarStorageService,
		public schedStorage: ScheduleStorageService
	) {}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
	}

	ngOnInit(): void {
		this.project.$expandedMetrics.pipe(takeUntil(this._unsubscribeAll)).subscribe(async (metrics: ExpandedMetrics) => {
			metrics = metrics as ExpandedMetrics;
			if (!metrics) {
				this.loading = true;
				return;
			}
			this.expandedMetrics.next(metrics);
			this.currentDisplayButton = 'totalActivities';
			this.allActivities.clear();
			this.remainingActivities.clear();
			this.loes.clear();
			this.allTasks.clear();
			const tasks = await this.schedStorage.grabUpdateTable<XerActivity>(metrics.updateId, 'TASK');
			const xer = new Xer(this.schedStorage.cachedXers.get(metrics.updateId));
			const preds: XerTaskPredecessor[] = await this.schedStorage.grabUpdateTable<XerTaskPredecessor>(
				metrics.updateId,
				'TASKPRED'
			);
			this.multipleTies = new Map<number, XerTaskPredecessor>(
				multipleTies(preds).map((tie) => [tie.task_pred_id, tie])
			);
			const invalidLogics: XerTaskPredecessor[] = [];
			xer.reschedule();
			for (const [predId, succId] of xer.invalidDependencies) {
				const preds = xer.activitiesMap.get(succId)?.predecessors.filter((p) => p.prevActivityId === predId) || [];
				invalidLogics.push(...preds.map((p) => p.raw_entry));
			}
			this.redundantLogic = new Map<number, XerTaskPredecessor>(
				[...redundantTies(xer), ...invalidLogics].map((logic) => [logic.task_pred_id, logic])
			);
			Promise.all(tasks).then(() => {
				for (const task of tasks) {
					// task.customStart = !!task.act_start_date ? task.act_start_date : task.target_start_date;
					// task.customEnd = !!task.act_end_date ? task.act_end_date : task.target_end_date;
					this.allActivities.set(task.task_id, task);
					if (task.task_type === 'TT_Task') {
						this.allTasks.set(task.task_id, task);
					}
					if (task.status_code !== 'TK_Complete' && task.task_type !== 'TT_LOE' && task.task_type !== 'TT_WBS') {
						this.remainingActivities.set(task.task_id, task);
					}
					if (task.task_type === 'TT_LOE') {
						this.loes.set(task.task_id, task);
					}
				}
				this.allTasksArray = tasks;

				this.allRelationships.clear();
				const relationships = metrics.totalRelationships || [];
				for (const relationship of relationships) {
					this.allRelationships.set(relationship.task_pred_id, relationship);
				}

				this.calendars.clear();
				const calendars = metrics.calendars || [];
				for (const calendar of calendars) {
					this.calendars.set(calendar.clndr_id, calendar);
				}

				this.$fs.next(metrics.fs?.length ? (100 * metrics.fs.length) / (metrics.totalRelationships.length || 1) : 0);
				this.$ss.next(metrics.ss?.length ? (100 * metrics.ss.length) / (metrics.totalRelationships.length || 1) : 0);
				this.$ff.next(metrics.ff?.length ? (100 * metrics.ff.length) / (metrics.totalRelationships.length || 1) : 0);
				this.$sf.next(metrics.sf?.length ? (100 * metrics.sf.length) / (metrics.totalRelationships.length || 1) : 0);

				this.loadNewDisplaySet(this.currentDisplayButton);
			});
		});
		this.project.$currentProjectReport.pipe(takeUntil(this._unsubscribeAll), debounceTime(500)).subscribe((report) => {
			if (report?.project?.company) {
				this.fsLagPenalty = report?.qualityControl?.fsLagPenalty;
				this.missingPredSuccPenalty = report?.qualityControl?.missingPredSuccPenalty;
				this.oosCriticalPenalty = report?.qualityControl?.oosCriticalPenalty;
				this.oosNearCriticalPenalty = report?.qualityControl?.oosNearCriticalPenalty;
				this.oosNonCriticalPenalty = report?.qualityControl?.oosNonCriticalPenalty;
				this.negLagPenalty = report?.qualityControl?.negLagPenalty;
				this.hardConstraintPenalty = report?.qualityControl?.hardConstraintPenalty;
				this.relationshipDensityPenalty = report?.qualityControl?.relationshipDensityPenalty;
				this.durationPenalty = report?.qualityControl?.durationPenalty;
				this.floatPenalty = report?.qualityControl?.floatPenalty;
				this.ssffProblematicPenalty = report?.qualityControl?.ssffProblematicPenalty;
				this.openStartFinishPenalty = report?.qualityControl?.openStartFinishPenalty;
				this.sfPenalty = report?.qualityControl?.sfPenalty;

				this.currentProjectCompanyPermissions = this.navBarStorage.companyPermissionMap.get(report?.project?.company);
			}
		});
	}

	loadNewDisplaySet(content: string = 'totalActivities', metrics = this.project.$expandedMetrics.value) {
		this.skip = 0;
		let switchFlag = false;
		const problematicScheduleActivitiesBtns: string[] = [
			'highDurationActivities',
			'highFloatActivities',
			'actualsPastDD',
			'ssFFWithLags',
			'softConstraints',
			'missingPredSuccessors',
			'negativeLags',
			'fsWithLags',
			'hardConstraints',
			'oosCrit',
			'oosNearCrit',
			'oosNonCrit',
			'openStartFinish',
			'logicDensity',
			'sf_prob',
			'multipleTies',
			'redundantLogic',
		];
		if (this.isOverview && content === this.currentDisplayButton) {
			this.currentDisplayButton = 'totalActivities';
		} else {
			this.currentDisplayButton = content;
		}
		this.isProblematicScheduleActivitiesBtn = problematicScheduleActivitiesBtns.includes(this.currentDisplayButton);
		//since sf is repeated in potential schedule issues I added this so it loads the correct table with all the data needed related to sf then switch back to sf_prob at the end to make sure the correct box is highlighted
		if (content === 'sf_prob') {
			switchFlag = true;
			this.currentDisplayButton = 'sf';
		}
		const matchingBtnData = allColumns.buttons.find((btn) => btn.name === this.currentDisplayButton);
		if (matchingBtnData === undefined) {
			return;
		}
		const filterOutOfSequence = (filterSet) => {
			return metrics.outOfSequence.filter((item) => {
				const task = 'task_id' in item ? this.allActivities.get(item.task_id) : undefined;
				const pred_task = 'pred_task_id' in item ? this.allActivities.get(item.pred_task_id) : undefined;
				item.succCode = task?.task_code;
				item.predCode = pred_task?.task_code;
				return filterSet?.find((p) => p.predCode === item.predCode && p.succCode === item.succCode);
			});
		};
		switch (this.currentDisplayButton) {
			case 'missingPredSuccessors':
				{
					this.currentDisplaySet = [...metrics.missingPredecessors, ...metrics.missingSuccessors];
				}
				break;
			case 'Tasks':
				{
					this.currentDisplaySet = this.allTasksArray.filter((task) => {
						if (task.task_type === 'TT_Task') {
							return task;
						}
						return undefined;
					});
				}
				break;
			case 'remainingActivities':
				{
					this.currentDisplaySet = this.allTasksArray.filter((task) => {
						if (task.status_code !== 'TK_Complete' && task.task_type !== 'TT_LOE' && task.task_type !== 'TT_WBS') {
							return task;
						}
						return undefined;
					});
				}
				break;
			case 'totalActivities':
				{
					this.currentDisplaySet = this.allTasksArray.filter((task) => {
						if (task.task_type !== 'TT_LOE' && task.task_type !== 'TT_WBS') {
							return task;
						}
						return undefined;
					});
				}
				break;
			case 'LOES':
				{
					this.currentDisplaySet = this.allTasksArray.filter((task) => task.task_type === 'TT_LOE');
				}
				break;
			case 'oosCrit':
				{
					this.currentDisplaySet = filterOutOfSequence(metrics.outOfSequenceCritical);
				}
				break;
			case 'oosNearCrit':
				{
					this.currentDisplaySet = filterOutOfSequence(metrics.outOfSequenceNearCritical);
				}
				break;
			case 'oosNonCrit':
				{
					this.currentDisplaySet = metrics.outOfSequence.filter((item) => {
						const task = 'task_id' in item ? this.allActivities.get(item.task_id) : undefined;
						const pred_task = 'pred_task_id' in item ? this.allActivities.get(item.pred_task_id) : undefined;
						item.succCode = task?.task_code;
						item.predCode = pred_task?.task_code;
						const isOosCrit = metrics.outOfSequenceCritical?.find(
							(p) => p.predCode === item.predCode && p.succCode === item.succCode
						);
						const isOosNearCrit = metrics.outOfSequenceNearCritical?.find(
							(p) => p.predCode === item.predCode && p.succCode === item.succCode
						);
						return isOosCrit === undefined && isOosNearCrit === undefined ? item : undefined;
					});
				}
				break;
			case 'redundantLogic':
				{
					this.currentDisplaySet = [...this.redundantLogic.values()] as unknown as TaskPredArrayInterface[];
				}
				break;
			case 'multipleTies':
				{
					this.currentDisplaySet = [...this.multipleTies.values()] as unknown as TaskPredArrayInterface[];
				}
				break;
			default: {
				this.currentDisplaySet = metrics[matchingBtnData.displaySet];
			}
		}

		this.tableDataType = matchingBtnData.dataType;
		this.tableTitle = matchingBtnData.title;
		this.scheduleAnalysisSearchBarString = `Search ${this.tableTitle}...`;
		this.unfilteredDisplaySet = this.currentDisplaySet?.length ? [...this.currentDisplaySet] : [];
		this.searchItemKeys = [];
		for (const item of this.unfilteredDisplaySet) {
			const task = 'task_id' in item ? this.allActivities.get(item.task_id) : undefined;
			const pred_task = 'pred_task_id' in item ? this.allActivities.get(item.pred_task_id) : undefined;
			const taskFilterKeys = ['task_id', 'task_name', 'task_code'];
			if (task) {
				this.searchItemKeys.push([item, getLowercaseValues(task, taskFilterKeys)]);
				item.customStart = task.act_start_date ? task.act_start_date : task.early_start_date;
				item.customEnd = task.act_end_date ? task.act_end_date : task.early_end_date;
				item.succCode = task.task_code;
				item.succName = task.task_name;
				item.succType = task.task_type;
				item.succStartAct = task.act_start_date;
				item.succStartEarly = task.early_start_date;
				item.succEndAct = task.act_end_date;
				item.succEndEarly = task.early_end_date;
				item.succRd = task.remain_drtn_hr_cnt / (this.calendars.get(+task.clndr_id)?.day_hr_cnt || 8);
				const openStartFinishItem = this.expandedMetrics.value.openStartFinish?.find(
					(eitem) => eitem.task_id === task.task_id
				);
				if (openStartFinishItem) {
					item.issueType =
						openStartFinishItem.openStart && openStartFinishItem.openFinish
							? 'Open Start & Finish'
							: openStartFinishItem.openStart
								? 'Open Start'
								: openStartFinishItem.openFinish
									? 'Open Finish'
									: '';
				}
				if (matchingBtnData.columnsDisplayed.includes(16)) {
					item.calendar = this.calendars.get(+task.clndr_id)?.clndr_name;
				}
				const hoursFields = [
					{
						taskField: 'remain_drtn_hr_cnt',
						customField: 'remainingHrs',
					},
					{
						taskField: 'target_drtn_hr_cnt',
						customField: 'odHrs',
					},
					{
						taskField: 'total_float_hr_cnt',
						customField: 'tfHrs',
					},
				];
				hoursFields.forEach((field) => {
					if (field.taskField in task) {
						item[field.customField] = Math.round(
							task[field.taskField] / (this.calendars.get(+task.clndr_id)?.day_hr_cnt || 8)
						);
					} else {
						item[field.customField] = 0;
					}
				});
			}
			if (pred_task) {
				this.searchItemKeys.push([item, getLowercaseValues(pred_task, taskFilterKeys)]);
				item.predCode = pred_task.task_code;
				item.predName = pred_task.task_name;
				item.predType = pred_task.task_type;
				item.predStartAct = pred_task.act_start_date;
				item.predStartEarly = pred_task.early_start_date;
				item.predEndAct = pred_task.act_end_date;
				item.predEndEarly = pred_task.early_end_date;
				item.predRd = pred_task.remain_drtn_hr_cnt / (this.calendars.get(+pred_task.clndr_id)?.day_hr_cnt || 8);
				/*const isOosCrit = metrics.outOfSequenceCritical?.find(
					(p) => p.predCode === item.predCode && p.succCode === item.succCode
				);
				const isOosNearCrit = metrics.outOfSequenceNearCritical?.find(
					(p) => p.predCode === item.predCode && p.succCode === item.succCode
				);
				item.criticality = isOosCrit ? 'Critical' : isOosNearCrit ? 'Near Critical' : 'Non Critical';*/
			}
			if ('lag_hr_cnt' in item) {
				item.lagHrs = this.divByCalendar(item.lag_hr_cnt, task || pred_task);
			} else {
				item.lagHrs = 0;
			}
		}
		if (this.tableSearch) {
			this.updateFilter({ searchTerm: this.tableSearch });
		}
		this.loadData();
		this.selectedColumns = [];
		allColumns.columns.forEach((column: ScheduleAnalysisColumn) => {
			if (matchingBtnData.columnsDisplayed.includes(column.id)) {
				this.selectedColumns.push(column);
			}
		});
		if (switchFlag) {
			this.currentDisplayButton = 'sf_prob';
		}
		this.loading = false;
	}

	updateFilter(args: { event?: any; searchTerm?: string }) {
		const searchTerm: string = args?.event?.target?.value?.toLowerCase() || args.searchTerm.toLowerCase();
		this.tableSearch = searchTerm;
		let filteredDisplaySet: ExpandedMetricsValues = [];
		if (!searchTerm) {
			filteredDisplaySet = this.unfilteredDisplaySet || [];
		} else {
			for (const [item, valuesToLower] of this.searchItemKeys) {
				for (const valLower of valuesToLower) {
					if (valLower.includes(searchTerm)) {
						filteredDisplaySet.push(item);
					}
				}
			}
		}

		// update the rows
		this.currentDisplaySet = filteredDisplaySet;
		this.loadActivities();
	}

	getCalendar(calendar_id: number) {
		for (let i = 0; i < this.project.$expandedMetrics.value.calendars.length; i++) {
			if (this.project.$expandedMetrics.value.calendars[i]?.clndr_id === calendar_id) {
				return this.project.$expandedMetrics.value.calendars[i];
			}
		}
		return null;
	}

	divByCalendar(value: any, row: any) {
		return value / (this.getCalendar(row?.clndr_id)?.day_hr_cnt || 8) || value / 8;
	}

	doExport($event: MouseEvent) {
		this.isExportRunning = true;
		this.rest.postToExporter('qc/' + this.project.$currentProjectPageId.value, []).subscribe({
			next: (res: any) => {
				saveAs(
					res,
					'QC_' +
						this.project.$currentProjectReport.value.projectTable.projectName +
						'_' +
						this.analyticsService.yyyymmdd() +
						'.xlsx'
				);
				this.isExportRunning = false;
			},
			error: (err: any) => {
				this.isExportRunning = false;
				console.log(err);
			},
		});
	}

	//do not remove this function. -MS
	fn: any = (task) => {
		if (task.task_type === 'TT_Task') {
			return task;
		}
		return undefined;
	};

	//do not remove this function. :) -RS
	fn2: any = (task) => {
		if (task.task_type !== 'TT_LOE' && task.task_type !== 'TT_WBS') {
			return task;
		}
		return undefined;
	};

	public pageChange(event: PageChangeEvent): void {
		this.skip = event.skip;
		this.loadActivities();
	}

	public sortChange(sort: SortDescriptor[]): void {
		this.sort = sort;
		this.loadActivities();
	}

	public loadData(): void {
		this.currentDisplaySet = filterBy(this.currentDisplaySet, this.filter);
		this.loadActivities();
	}

	public loadActivities(): void {
		this.gridView = {
			data: this.currentDisplaySet?.slice(this.skip, this.skip + this.pageSize),
			total: this.currentDisplaySet?.length,
		};
		this.gridData = this.currentDisplaySet;
	}

	public filterChange(filter: CompositeFilterDescriptor): void {
		this.filter = filter;
		this.loadData();
	}
}
