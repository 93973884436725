<kendo-gridlayout
	[rows]="['345.4px', '719.96', '604.96px']"
	[cols]="[visualizer ? '50%' : '100%']"
	[gap]="{ rows: 5, cols: visualizer ? 5 : 0 }"
	class='grid'
>
	<kendo-gridlayout-item [row]='1' [col]='1' [colSpan]='visualizer ? 2 : 1'>
		<app-qc-trend [visualizer]='visualizer'></app-qc-trend>
	</kendo-gridlayout-item>
	<kendo-gridlayout-item [row]='visualizer ? 2 : 2' [col]='1'>
		<app-schedule-analysis [visualizer]='visualizer'></app-schedule-analysis>
	</kendo-gridlayout-item>
	<kendo-gridlayout-item [row]='visualizer ? 2 : 3' [col]='visualizer ? 2 : 1'>
		<app-dcma-assessment [visualizer]='visualizer'></app-dcma-assessment>
	</kendo-gridlayout-item>
</kendo-gridlayout>
