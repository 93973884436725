import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { BehaviorSubject } from 'rxjs';
import { ProjectInterface } from '../../../../models/Project';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { Activity } from '@rhinoworks/xer-parse';

@Component({
	selector: 'app-schedule-impact-analysis',
	templateUrl: './schedule-impact-analysis.component.html',
	styleUrls: ['./schedule-impact-analysis.component.scss'],
})
export class ScheduleImpactAnalysisComponent implements OnInit {
	@Input() isOverview: boolean = false;
	@Input() $projectData = new BehaviorSubject<ProjectInterface>(undefined);
	@Output() exportImpactAnalysis = new EventEmitter<void>();
	clearDelaySelection = new BehaviorSubject<boolean>(false);
	constructor(
		public projectService: ProjectDashboardService,
		public scheduleStorage: ScheduleStorageService
	) {}

	ngOnInit() {}

	/**
	 * handles activity click events in the delay grid
	 * @param actv
	 */
	activityClickedDelay(actv: Activity): void {
		this.projectService.selectedDelayActv = actv;
	}
}
