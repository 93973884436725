import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { takeUntil } from 'rxjs/operators';
import { AxisSettings, SeriesData, SeriesDataSettings } from '../../../../models/ChartSettings';
import { PlotBand } from '@progress/kendo-angular-charts';
import { hasObjChanged } from '../../../../util/projects';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import {
	ProjectCompletionTrendArrayInterface,
	ProjectReportInterface,
} from '../../../../models/ProjectReport/ProjectReport';
import { UpdateInterface } from '../../../../models/Update/Task';
import { differenceInCalendarDays, isValid } from 'date-fns';
import { XerActivity } from '@rhinoworks/xer-parse';

@Component({
	selector: 'app-project-completion-trending',
	templateUrl: './project-completion-trending.component.html',
	styleUrls: ['./project-completion-trending.component.scss'],
})
export class ProjectCompletionTrendingComponent implements OnInit, OnDestroy {
	@Input() visualizer: boolean = false;
	@Input() isOverview: boolean = false;
	categories: string[] = [];
	seriesData: SeriesDataSettings[] = [];
	public valuePlotBands: PlotBand[] = [
		{
			from: -999999,
			to: 0,
			color: '#DF5353',
			opacity: 0.2,
		},
		{
			from: 0,
			to: 999999,
			color: '#4fc931',
			opacity: 0.2,
		},
	];
	valueAxisItemSettings: AxisSettings[] = [
		{
			title: {
				text: 'Completion Variance',
				visible: true,
			},
			labels: {
				format: '{0} cd',
			},
			plotBands: this.valuePlotBands,
			majorGridLines: {
				visible: true,
				width: 3,
			},
		},
	];
	completionMilestone;

	constructor(
		public scheduleStorage: ScheduleStorageService,
		public projectService: ProjectDashboardService
	) {}
	private _unsubscribeAll: Subject<void> = new Subject<void>();

	ngOnInit() {
		this.projectService.$currentProjectReport.pipe(takeUntil(this._unsubscribeAll)).subscribe((report) => {
			const allUpdates = this.scheduleStorage.$allUpdates.value || [];
			if (report && allUpdates.length > 0) {
				this.getProjectCompletionTrending(report as unknown as ProjectReportInterface, allUpdates);
			}
		});
		this.scheduleStorage.$allUpdates.pipe(takeUntil(this._unsubscribeAll)).subscribe((updates) => {
			const report = this.projectService.$currentProjectReport.value as unknown as ProjectReportInterface;
			if (report && updates.length > 0) {
				this.getProjectCompletionTrending(report, updates || []);
			}
		});
	}

	getProjectCompletionTrending(report: ProjectReportInterface, updates: UpdateInterface[] = []) {
		const hasConstraints: boolean =
			updates[updates?.length - 1]?.finishMilestone?.cstr_date !== null &&
			updates[updates?.length - 1]?.finishMilestone?.cstr_date !== undefined;
		const projectCompletionTrendArray = report?.projectCompletionTrend?.projectCompletionTrendArray || [];
		const categories = [];
		const periodPerformance: SeriesData[] = [];
		const cumulativePerformance: SeriesData[] = [];
		let contractVariance: number = Number(
			report?.projectCompletionTrend?.projectCompletionTrendArray[0]?.contractVariance
		);
		for (let i: number = 0; i < projectCompletionTrendArray.length; i++) {
			const reportUpdateItem: ProjectCompletionTrendArrayInterface = projectCompletionTrendArray?.[i];
			const update: UpdateInterface = updates?.[i];
			const updateName: string = i === 0 ? 'Baseline' : 'Update ' + i + (update?.baseline ? ' ®' : '');
			const finishMilestone: XerActivity = update.finishMilestone;
			const contractCompletion: Date = new Date(
				finishMilestone?.act_end_date || finishMilestone?.cstr_date || finishMilestone?.early_end_date
			);
			const currentCompletion: Date = new Date(finishMilestone?.act_end_date || finishMilestone?.early_end_date);
			const prevFinMile: XerActivity = i === 0 ? null : updates[i - 1].finishMilestone;
			const prevCurrentCompletion: Date =
				prevFinMile === null || prevFinMile === undefined
					? null
					: new Date(prevFinMile?.act_end_date || prevFinMile?.early_end_date);
			const contractVarianceConstraint: number =
				isValid(contractCompletion) && isValid(currentCompletion)
					? differenceInCalendarDays(contractCompletion, currentCompletion)
					: undefined;
			if (update?.baseline === true) {
				contractVariance =
					isValid(prevCurrentCompletion) && isValid(currentCompletion)
						? differenceInCalendarDays(prevCurrentCompletion, currentCompletion)
						: contractVariance;
			} else {
				contractVariance += +reportUpdateItem?.previousVariance || 0;
			}
			categories.push(updateName);
			periodPerformance.push({
				category: updateName,
				value: differenceInCalendarDays(prevCurrentCompletion, currentCompletion),
			});
			cumulativePerformance.push({
				category: updateName,
				value: hasConstraints ? contractVarianceConstraint : contractVariance,
			});
		}
		const lastUpdateFinMile = updates[updates.length - 1]?.finishMilestone;
		const completionMilestone = lastUpdateFinMile
			? lastUpdateFinMile.task_code + ' - ' + lastUpdateFinMile.task_name
			: undefined;
		if (completionMilestone !== this.completionMilestone) {
			this.completionMilestone = completionMilestone;
		}
		if (hasObjChanged(this.categories, categories)) {
			this.categories = categories;
		}
		const seriesData: SeriesDataSettings[] = [
			{
				type: 'column',
				data: periodPerformance,
				name: 'Update Δ',
				visible: true,
				color: '#8a8a8a',
				stack: true,
			},
			{
				type: 'line',
				data: cumulativePerformance,
				name: hasConstraints ? 'Contract Δ' : 'Cumulative Δ',
				visible: true,
				color: 'black',
				stack: true,
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
		];
		if (hasObjChanged(this.seriesData, seriesData)) {
			this.seriesData = seriesData;
		}
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
