<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
<div class='card-header-container' style='padding: 0.7em 11px' *ngIf='visualizer' [class.overviewHeader]="isOverview">
	<div class="card-header">
		DCMA 14-Point Assessment
	</div>
</div>
<div class='card' style='page-break-inside: avoid;'>
	<kendo-gridlayout
		[rows]="visualizer ? ['672.56px'] : ['42.4px', '562.56px']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='full-width full-height'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' *ngIf='!visualizer' [class.overviewHeader]="isOverview">
			<div class="card-header">
				DCMA 14-Point Assessment <span style="font-size: 9px; position: relative; top: -1px;">(INCOMPLETE ACTIVITIES)</span>
			</div>
			<tooltip-open type='{{isOverview ? "overview" : "dcma"}}' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1' *ngIf="!assessments.length && !visualizer">
			<div class='warning-div'>
				<p style="padding: 20em 1em 1em 0">
					<ion-icon name="alert-circle-outline" size="large"></ion-icon>
					<br><strong>Please Update your Project.</strong>&nbsp;<span>In order to use this feature in full, this project must have a new update added to it or the current update recalculated.</span>
				</p>-
			</div>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='visualizer ? 1 : 2' [col]='1' *ngIf="assessments.length">
			<kendo-gridlayout
				[rows]="visualizer ? ['672.56px'] : ['562.56px']"
				[cols]="visualizer ? ['calc(100% - 5px)'] : ['calc((400% / 12) - 2.5px)', 'calc((800% / 12) - 2.5px)']"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='btn-grid' style='padding: 5px 0 5px 5px;'>
					<div *ngFor='let assessment of assessments;let i = index'
							 [class.selected-button]='assessment.key === (selectedTest | async)'
							 (click)='!isLoading ? selectedTest.next(assessment.key) : ngOnInit(assessment.key)'
					>
						<div>{{assessment.title}}</div>
						<div class="score-text">
							{{ (assessment.value !== true && assessment.value > 1 ? ('Score: ' + (assessment.value | number: '1.0-0') + '%') : '') }}
							<span *ngIf="(assessment.value !== true && assessment.value > 1 ? ('Score: ' + (assessment.value | number: '1.0-0') + '%') : '') === ''">
							{{ (assessment.description.split(' ')[0] === 'Schedule' ? (assessment.value ? 'Pass' : 'Fail') : ((assessment.value | DCMAPct: [assessment.min, assessment.max]) + '%')) }}
								<span *ngIf='assessment?.hasOwnProperty("min")'>of min {{(assessment.min > 1 ? assessment.min : assessment.min * 100) + '%'}}</span>
							<span *ngIf='assessment?.hasOwnProperty("max")'>of max {{(assessment.max > 1 ? assessment.max : assessment.max * 100) + '%'}}</span>
						</span>
						</div>
						<div class='pass-icon'>
							<mat-icon class='icon'
												[class.green-icon]='assessment.passes'
												[class.red-icon]='!assessment.passes'
							>
								{{assessment.passes ? 'check' : 'priority_high'}}
							</mat-icon>
						</div>
					</div>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='task-table' *ngIf='!visualizer'>
					<div style="display: table; width: calc(100% - 5px); height: 95.5px; margin: 5px 0;">
						<div *ngIf='(selectedTest | async) && assessments[selectedIndex]' style='width: 100%; text-align: center; background: #EDEDED; border-radius: 5px; padding: 2px 0 2px 0; display: table-cell; vertical-align: middle;'>
							<h2>{{assessments[selectedIndex].title}} -
								<span *ngIf='!assessments[selectedIndex].passes' class='red-icon'>
								FAILED
							</span>
								<span *ngIf='assessments[selectedIndex].passes' class='green-icon'>
								PASS
							</span>
							</h2>
							<h4 style='width: 100%; text-align: center' [class.red-icon]='!assessments[selectedIndex]?.passes'>

								{{ (assessments[selectedIndex].value !== true && assessments[selectedIndex].value > 1 ? ('Score: ' + (assessments[selectedIndex].value | number: '1.0-0') + '%') : '') }}
								<span *ngIf="(assessments[selectedIndex].value !== true && assessments[selectedIndex].value > 1 ? ('Score: ' + (assessments[selectedIndex].value | number: '1.0-0') + '%') : '') === ''">
									{{ (assessments[selectedIndex].description.split(' ')[0] === 'Schedule' ? (assessments[selectedIndex].title === 'Critical Path Test' ? 'Schedule Has Critical Path' : 'Pass') : ((assessments[selectedIndex].value | DCMAPct: [assessments[selectedIndex].min, assessments[selectedIndex].max]) + '%')) }}
									<span *ngIf='assessments[selectedIndex].hasOwnProperty("min")'>of min {{(assessments[selectedIndex].min > 1 ? assessments[selectedIndex].min : assessments[selectedIndex].min * 100) + '%'}}</span>
									<span *ngIf='assessments[selectedIndex].hasOwnProperty("max")'>of max {{(assessments[selectedIndex].max > 1 ? assessments[selectedIndex].max : assessments[selectedIndex].max * 100) + '%'}}</span>
								</span>
								<br>

								{{ assessments[selectedIndex]?.description }}
								<span *ngIf='assessments[selectedIndex]?.hasOwnProperty("min")'>(minimum {{(assessments[selectedIndex].min > 1 ? assessments[selectedIndex].min : assessments[selectedIndex].min * 100) + '%'}})</span>
								<span *ngIf='assessments[selectedIndex]?.hasOwnProperty("max")'>(maximum {{(assessments[selectedIndex].max > 1 ? assessments[selectedIndex].max : assessments[selectedIndex].max * 100) + '%'}})</span>
							</h4>
						</div>
					</div>

					<div *ngIf='(selectedTest | async) === "overall"' style="height: 412px;">
						<app-chart
							[title]='"Overall Score Trend"'
							[categories]='categories'
							[seriesData]='seriesData'
							[legendVisible]='false'
							[valueAxis]='valueAxisItemSettings'
						></app-chart>
					</div>

					<div *ngIf='(selectedTest | async) && assessments[selectedIndex] && assessments[selectedIndex]?.elements?.length' style='margin-right: 5px'>
						<kendo-grid
							#grid
							id='grid'
							[data]="gridView"
							[kendoGridBinding]="gridData"
							[height]="412"
							[rowHeight]='40'
							[loading]='isLoading'
							[size]='"small"'
							[sortable]='true'
							[sort]="sort"
							(sortChange)="sortChange($event)"
							(pageChange)="pageChange($event)"
							[skip]="skip"
							[pageSize]="pageSize"
							scrollable="virtual"
							[navigable]="false"
							class='sched-grid'
							(dataStateChange)="onDataStateChange()"
						>
							<kendo-grid-column
								*ngFor='let column of selectedColumns'
								[field]="column.field"
								[title]="column.title"
								[format]="column?.format"
								[width]='column.width'
								headerClass="header-class"
							>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "string"'>
									{{ dataItem[column.field] }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "img"'>
									<img [src]="column.id === 13 ? '/assets/ui/gantt/' + dataItem.pred_type + '.png' : '/assets/ui/activity_type/' + dataItem.task_type + '.png'" style='vertical-align: -1.5px'/>
									{{ column.id === 0 ? dataItem.task_code : column.id === 17 ? taskTypeDictionary[dataItem.task_type] : linkTypeDictionary[dataItem.pred_type] }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "date"'>
									{{ (dataItem[column.field] | date: 'MM/dd/yyyy') || 'N/A' }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "actIfPossible"'>
									{{ dataItem[column.field] ? ((dataItem[column.field] | date: 'MM/dd/yyyy') + ' A') : dataItem[column.otherField] ? (dataItem[column.otherField] | date: 'MM/dd/yyyy') : 'N/A' }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "hours"'>
									{{ dataItem[column.field] | floor }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "calendar"'>
									{{ dataItem?.calendar || 'N/A' }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "status"'>
									{{ tableDataType === 'Relationship' ? taskStatusDictionary[allTasksById.get(+dataItem.task_id)?.status_code || allTasksById.get(+dataItem.pred_task_id)?.status_code] : taskStatusDictionary[dataItem.status_code] }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "missing"'>
									Missing {{(expandedMetrics | async)?.missingSuccessors?.includes(dataItem) ? 'Successor' : 'Predecessor'}}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "constraint"'>
									{{ constraintDict[dataItem[column.field]] || 'N/A' }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "lag"'>
									{{ dataItem.lagHrs | floor }}
								</ng-template>
							</kendo-grid-column>
						</kendo-grid>
					</div>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>
