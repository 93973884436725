import { Component, Input, OnInit } from '@angular/core';
import { ProfileCompanyPermission } from '../../../models/auth/account-user';

@Component({
	selector: 'app-cost-page',
	templateUrl: './cost-page.component.html',
	styleUrls: ['./cost-page.component.scss'],
})
export class CostPageComponent implements OnInit {
	@Input() currentProjectCompanyPermissions: ProfileCompanyPermission = null;

	constructor() {}

	ngOnInit() {}
}
