import { NgModule } from '@angular/core';
import { CostPageComponent } from './cost-page.component';
import { GridLayoutModule } from '@progress/kendo-angular-layout';
import { CumulativeCashFlowComponent } from './cumulative-cash-flow/cumulative-cash-flow.component';
import { HistoricPerformanceComponent } from './historic-performance/historic-performance.component';
import { CommonModule } from '@angular/common';
import { ScheduleIndexTrendingComponent } from './schedule-index-trending/schedule-index-trending.component';
import { AegisChartModule } from '../../shared/chart/chart.module';
import { CostService } from '../../../services/common/cost.service';
import { ActivityCostsComponent } from './activity-costs/activity-costs.component';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { ButtonGroupModule, ButtonModule } from '@progress/kendo-angular-buttons';
import { TooltipMenuModule } from '../../portfolio/tooltip-menu/tooltip-menu.module';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { DropDownTreesModule, SharedDirectivesModule } from '@progress/kendo-angular-dropdowns';
import { SVGIconModule } from '@progress/kendo-angular-icons';
import { BodyModule } from '@progress/kendo-angular-treelist';

@NgModule({
	providers: [CostService],
	declarations: [
		CostPageComponent,
		CumulativeCashFlowComponent,
		HistoricPerformanceComponent,
		ScheduleIndexTrendingComponent,
		ActivityCostsComponent,
	],
	exports: [
		CostPageComponent,
		CumulativeCashFlowComponent,
		HistoricPerformanceComponent,
		ScheduleIndexTrendingComponent,
		ActivityCostsComponent,
	],
	imports: [
		GridLayoutModule,
		CommonModule,
		AegisChartModule,
		GridModule,
		ButtonModule,
		TooltipMenuModule,
		TooltipModule,
		ExcelModule,
		ButtonGroupModule,
		DropDownTreesModule,
		SVGIconModule,
		SharedDirectivesModule,
		BodyModule,
	],
})
export class CostPageModule {}
