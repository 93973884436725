import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CompositeFilterDescriptor, FilterDescriptor, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { UpdateInterface } from '../../../../../models/Update/Task';
import { ScheduleStorageService } from '../../../../../services/project/schedule-storage.service';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { CellClickEvent, TaskClickEvent, TimelineViewType, GanttComponent } from '@progress/kendo-angular-gantt';
import { differenceInCalendarDays } from 'date-fns';
import { WindowRef, WindowService } from '@progress/kendo-angular-dialog';
import { AppWindowService } from '../../../../../services/common/window.service';
import { FilterComponent, FilterExpression } from '@progress/kendo-angular-filter';
import { ProjectDashboardService } from '../../../../../services/project/project.service';
import { PopupRef, PopupService } from '@progress/kendo-angular-popup';
import { AnalyticsDashboardService } from '../../../../../services/analytics/analytics.service';
import { NavigationBarStorageService } from '../../../../../services/common/navigation-bar-storage.service';
import { CurrentUpdateGanttItem, GanttService } from '../../../../../services/project/gantt.service';
import { CurrentUpdateGanttPreset } from '../../../../../models/Project';
import { copyIcon, gearIcon, pencilIcon, plusIcon, saveIcon, trashIcon } from '@progress/kendo-svg-icons';

export const allColumns = require('../current-update-gantt-columns.json') as {
	columns: CurrentUpdateGanttColumn[];
};

export interface CurrentUpdateGanttColumn {
	field: string;
	title: string;
	width: number;
	type: string;
	isDefault: boolean;
	sequence: number;
}

@Component({
	selector: 'app-current-update-gantt-chart',
	templateUrl: './current-update-gantt-chart.component.html',
	styleUrls: ['./current-update-gantt-chart.component.scss'],
})
export class CurrentUpdateGanttChartComponent implements OnInit {
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	public activeView: TimelineViewType = 'year';
	selectedColumns: CurrentUpdateGanttColumn[] = [];
	groupSelectedKeys = [];
	collapseIndex: number = 0;
	@Input() $filtersOpened = new BehaviorSubject<ElementRef | HTMLElement>(undefined);
	@Input() $columnSelectorOpened = new BehaviorSubject<ElementRef | HTMLElement>(undefined);
	@Input() $groupSelectorOpened = new BehaviorSubject<ElementRef | HTMLElement>(undefined);
	@Input() $presetSelectorOpened = new BehaviorSubject<ElementRef | HTMLElement>(undefined);
	@Input() $resetClicked = new BehaviorSubject<boolean>(null);
	@Input() $showLines = new BehaviorSubject<boolean>(null);
	@Input() $showOnlyCritical = new BehaviorSubject<boolean>(null);
	@Input() $toggleExpand = new BehaviorSubject<boolean>(true);
	@Input() $expandOneLevel = new BehaviorSubject<boolean>(false);
	@Input() $collapseOneLevel = new BehaviorSubject<boolean>(false);
	@Output() updateProjectName: EventEmitter<string> = new EventEmitter<string>();
	@Output() resetStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() columnSelectorPopupState = new EventEmitter<boolean>();
	@Output() groupSelectorPopupState = new EventEmitter<boolean>();
	@Output() presetSelectorPopupState = new EventEmitter<boolean>();
	@Output() updateButtonGroupsOnPresetClick = new EventEmitter<number[]>();
	@Output() updateFullyExpanded = new EventEmitter<boolean>();
	@Output() updateFullyCollapsed = new EventEmitter<boolean>();
	public selectionState: Set<number> = new Set();
	viewOptions = [
		{
			display: 'Auto-Fit',
			zoomVal: null,
		},
		{
			display: 'Year',
			zoomVal: 25,
		},
		{
			display: 'Quarter',
			zoomVal: 35,
		},
		{
			display: 'Month',
			zoomVal: 55,
		},
	];
	showingFilters: boolean = false;
	private filterWindowRef: WindowRef;
	@ViewChild('filterWindowContentTemplate') filterWindowContentTemplateRef: TemplateRef<any>;
	public filters: FilterExpression[] = [
		{
			field: 'taskCode',
			title: 'ID',
			editor: 'string',
		},
		{
			field: 'name',
			title: 'Name',
			editor: 'string',
		},
	];
	public statusFilterList: Array<string> = ['Complete', 'Incomplete', 'Not Started'];
	public taskTypeFilterList: Array<string> = [
		'Task Dependent',
		'Resource Dependent',
		'Milestone',
		'Finish Milestone',
		'WBS Summary',
		'Level of Effort',
	];
	public criticalityFilterList: Array<string> = ['Critical', 'Non Critical']; //todo: add 'Near Critical' back when near critical vs critical distinctions are accurate - RS
	@ViewChild('filter') filter: FilterComponent;
	private columnSelectorPopupRef: PopupRef;
	private groupSelectorPopupRef: PopupRef;
	@ViewChild('columnSelectorPopupTemplate') columnSelectorTemplateRef: TemplateRef<any>;
	@ViewChild('groupSelectorPopupTemplate') groupSelectorTemplateRef: TemplateRef<any>;
	columnSelectorOpen: boolean = false;
	groupSelectorOpen: boolean = false;
	columnOptions: CurrentUpdateGanttColumn[] = [];
	@ViewChild('currentUpdateGantt') currentUpdateGanttChart: GanttComponent;
	public icons = {
		pencilIcon,
		trashIcon,
		copy: copyIcon,
		gear: gearIcon,
		save: saveIcon,
		plus: plusIcon,
	};
	maskedFilterValue: CompositeFilterDescriptor = null;
	private presetSelectorPopupRef: PopupRef;
	@ViewChild('presetSelectorPopupTemplate') presetSelectorTemplateRef: TemplateRef<any>;
	presetSelectorOpen: boolean = false;
	user: any = {
		userType: 'aegis',
	};
	wbsColorStrings: string[] = ['#0000ff', '#9b9a9a', 'black', '#00ffff', '#ff6cff'];

	constructor(
		public scheduleService: ScheduleStorageService,
		public windowService: AppWindowService,
		public kendoWindowService: WindowService,
		public projectService: ProjectDashboardService,
		private popupService: PopupService,
		private analyticsService: AnalyticsDashboardService,
		private navBarStorage: NavigationBarStorageService,
		public ganttService: GanttService
	) {
		this.resetColumns();
	}

	ngOnInit() {
		this.projectService.$currentProjectReport
			.pipe(takeUntil(this._unsubscribeAll), debounceTime(100))
			.subscribe((report) => {
				this.updateProjectName.emit(report?.project?.name);
			});

		this.scheduleService.$allUpdates.pipe(takeUntil(this._unsubscribeAll)).subscribe((updates: UpdateInterface[]) => {
			if (updates?.length > 0) {
				this.ganttService.updateGanttData(updates);
			}
		});

		this.$filtersOpened.subscribe((anchor) => {
			if (this.showingFilters && this.filterWindowRef !== undefined) {
				this.closeFilterWindow();
			} else {
				if (this.filterWindowContentTemplateRef !== undefined) {
					this.closeColumns();
					this.closeGroups();
					this.closePresets();
					this.filterWindowRef = this.kendoWindowService.open({
						title: 'Gantt Filters',
						content: this.filterWindowContentTemplateRef,
						cssClass: 'project-list-filters-window-container',
						top: 362,
						left: this.windowService.windowPosition.ganttFilters.left,
						height: null,
						width: this.windowService.windowPosition.ganttFilters.width,
						resizable: false,
					});
					this.showingFilters = true;
					this.filterWindowRef.window.location.nativeElement.setAttribute('id', 'ganttFiltersWindow');
					this.filterWindowRef.result.subscribe(() => {
						this.closeFilterWindow();
					});
					// this.windowService.setViewport('ganttFilters');
				}
			}
		});

		this.$columnSelectorOpened.subscribe((anchor) => {
			const popupColumnSelectorContainer = document.getElementById('columnSelectorContainer');
			if (popupColumnSelectorContainer !== null || anchor === null) {
				this.closeColumns();
			} else {
				if (anchor !== undefined) {
					this.closeFilterWindow();
					this.closeGroups();
					this.closePresets();
					this.columnSelectorPopupRef = this.popupService.open({
						anchor,
						content: this.columnSelectorTemplateRef,
						popupClass: ['columns-popup-class'],
						margin: { vertical: 10, horizontal: 0 },
					});
					this.columnSelectorPopupState.emit(true);
				}
			}
		});

		this.$groupSelectorOpened.subscribe((anchor) => {
			const popupColumnSelectorContainer = document.getElementById('groupSelectorContainer');
			if (popupColumnSelectorContainer !== null || anchor === null) {
				this.closeGroups();
			} else {
				if (anchor !== undefined) {
					this.closeFilterWindow();
					this.closeColumns();
					this.closePresets();
					this.groupSelectorPopupRef = this.popupService.open({
						anchor,
						content: this.groupSelectorTemplateRef,
						popupClass: ['columns-popup-class'],
						margin: { vertical: 10, horizontal: 0 },
					});
					this.groupSelectorPopupState.emit(true);
				}
			}
		});

		this.$presetSelectorOpened.subscribe((anchor) => {
			const popupColumnSelectorContainer = document.getElementById('presetSelectorContainer');
			if (popupColumnSelectorContainer !== null || anchor === null) {
				this.closePresets();
			} else {
				if (anchor !== undefined) {
					this.closeFilterWindow();
					this.closeColumns();
					this.closeGroups();
					this.presetSelectorPopupRef = this.popupService.open({
						anchor,
						content: this.presetSelectorTemplateRef,
						popupClass: ['presets-popup-class'],
						margin: { vertical: 10, horizontal: 0 },
					});
					this.presetSelectorPopupState.emit(true);
				}
			}
		});

		this.$showLines.subscribe((val: boolean) => {
			this.ganttService.showLines = val === true;
			if (val !== null && this.ganttService.groupByApplied !== true && this.ganttService.radioSelection === null) {
				this.ganttService.selectedGanttLayout = null;
				if (val === true) {
					let i = 0;
					const waitingForExpandedMetrics = setInterval(() => {
						if (this.projectService.$expandedMetrics.value?.totalRelationships !== undefined || i > 500) {
							clearInterval(waitingForExpandedMetrics);
							this.ganttService.generateDependencies(this.projectService.$expandedMetrics.value);
						}
						i++;
					}, 200);
				} else {
					this.ganttService.updateGanttVisibleItems();
				}
			}
		});

		this.$showOnlyCritical.subscribe((val: boolean) => {
			//this.showOnlyCritical = val === true; <- commented out this line because val was passing null on page load which was toggling showOnlyCritical to false even though the button had critical as selected
			if (val !== undefined && val !== null) {
				this.ganttService.selectedGanttLayout = null;
				this.ganttService.showOnlyCritical = val;
				this.ganttService.updateGanttVisibleItems(true);
			}
		});

		this.analyticsService.$closeAll.subscribe((val) => {
			this.closeFilterWindow();
			this.closeColumns();
			this.closeGroups();
			this.closePresets();
		});

		this.$toggleExpand.subscribe((val: boolean) => {
			if (val !== null) {
				this.ganttService.allNodesExpanded = val;
				this.ganttService.updateExpandedKeys();
			}
		});

		this.$expandOneLevel.subscribe((val: boolean) => {
			if (val) {
				this.collapseIndex = this.collapseIndex - 1;

				const highestIndex =
					this.ganttService.radioSelection === 'wbs'
						? this.ganttService.puppetLevel.length
						: this.ganttService.selectedGroupByDelayed.length;
				this.ganttService.updateExpandedKeysOneLevel('expand', this.collapseIndex, highestIndex);
			}
		});
		this.$collapseOneLevel.subscribe((val: boolean) => {
			if (val) {
				this.collapseIndex = this.collapseIndex + 1;

				const highestIndex =
					this.ganttService.radioSelection === 'wbs'
						? this.ganttService.puppetLevel.length
						: this.ganttService.selectedGroupByDelayed.length;
				this.ganttService.updateExpandedKeysOneLevel('collapse', this.collapseIndex, highestIndex);
			}
		});

		this.ganttService.$reportApply.subscribe((val: boolean) => {
			if (val) {
				this.ganttService.fullyExpanded.next(this.ganttService.checkIfAllExpanded(this.ganttService.items));
				this.ganttService.fullyCollapsed.next(this.ganttService.checkIfAllCollapsed(this.ganttService.items));
				this.collapseIndex = 0;
			}
		});

		window.addEventListener('scroll', this.navBarStorage.scrollGantt, true);

		this.ganttService.$drawDataDateLine.subscribe((val: boolean) => {
			if (val) {
				this.drawDataDateLine(this.ganttService.currentUpdateDataDate);
			}
		});

		this.ganttService.fullyExpanded.subscribe((val: boolean) => {
			if (val !== null) {
				this.updateFullyExpanded.emit(val);
			}
		});

		this.ganttService.fullyCollapsed.subscribe((val: boolean) => {
			if (val !== null) {
				this.updateFullyCollapsed.emit(val);
			}
		});

		this.projectService.$currentProjectData.subscribe((val) => {
			if (val) {
				this.ganttService.userPresets =
					val?.ganttPresets.sort((a: CurrentUpdateGanttPreset, b: CurrentUpdateGanttPreset) =>
						a?.name?.toLowerCase() < b?.name?.toLowerCase()
							? -1
							: b?.name?.toLowerCase() < a?.name?.toLowerCase()
								? 1
								: 0
					) || [];
				this.ganttService.favoritePresets = this.ganttService.userPresets.filter((p) => p.isFavorite);
				this.ganttService.$ganttPresetsUpdated.next(true);
			}
		});

		//this one is for batch exports
		this.ganttService.$applyReportPreset.subscribe((preset: CurrentUpdateGanttPreset) => {
			this.ganttService.readyToSS = false;
			if (preset !== null) {
				this.headlessBrowserApplyPreset(null, preset);
			}
		});

		//this one is for manually clicking in report center
		this.ganttService.$applyPreset.subscribe((preset: CurrentUpdateGanttPreset) => {
			if (preset !== null) {
				this.presetClicked(null, preset);
			}
		});
	}

	/**
	 * used by gantt to know if a row is expanded
	 * @param dataItem
	 */
	public isExpanded = (dataItem: CurrentUpdateGanttItem): boolean =>
		this.ganttService.expandedKeys.includes(dataItem.id);

	closeFilterWindow(): void {
		this.showingFilters = false;
		if (this.filterWindowRef) {
			this.filterWindowRef.close();
		}
	}

	drawDataDateLine(dataDate: Date): void {
		const i: number = 0;
		const waitForDOMInterval = setInterval(() => {
			const timeline = document.getElementsByClassName('k-gantt-timeline');
			if (timeline.length > 0 || i > 500) {
				clearInterval(waitForDOMInterval);
				const header = timeline.item(0).querySelector('.k-grid-header');
				const minStart: Date = this.currentUpdateGanttChart.timelineSlots[0].start;
				const maxEnd: Date =
					this.currentUpdateGanttChart.timelineSlots[this.currentUpdateGanttChart.timelineSlots.length - 1].end;
				const totalTimespan: number = differenceInCalendarDays(maxEnd, minStart);
				const daysElapsed: number = differenceInCalendarDays(dataDate, minStart) + 0.75; // the .75 is not an exact science
				const rightPercent: number = 1 - daysElapsed / (totalTimespan || 1);
				const gridContent: Element = timeline.item(0).querySelector('.k-grid-content');
				const table: Element = gridContent.querySelector('.k-gantt-tables');
				const scrollbarOffset: number =
					gridContent.getBoundingClientRect()?.width === table.getBoundingClientRect()?.width ? 5 : 15;
				const rightValue: number = header.clientWidth * rightPercent + scrollbarOffset;
				const dataDateLine = document.getElementsByClassName('data-date-gantt-line');
				if (dataDateLine?.length) {
					dataDateLine.item(0).setAttribute('style', 'right:' + rightValue + 'px');
				}
				//hides data date line if its past the gantt timeline
				const ganttTimeline = timeline.item(0).getBoundingClientRect();
				const dateLine = dataDateLine?.item(0).getBoundingClientRect();

				if (dateLine.left < ganttTimeline.left || dateLine.left > ganttTimeline.right) {
					dataDateLine.item(0).setAttribute('style', 'display: none');
				}
			}
		}, 200);
	}

	/**
	 * gantt column sort handler
	 * @param sort
	 */
	public sortChange(sort: SortDescriptor[]): void {
		if (this.ganttService.groupByApplied) {
			this.ganttService.applyGroupBySort(sort);
		} else {
			this.ganttService.sort = structuredClone(sort);
		}
	}

	/**
	 * applies style class to timeline task bars
	 * @param dataItem
	 */
	public taskCallback(dataItem: CurrentUpdateGanttItem): string {
		return dataItem?.childTasks?.length
			? dataItem.hideSummaryBar
				? 'hidden-summary-bar'
				: 'grey-current-update-gantt'
			: dataItem?.isMilestoneAndIsDone
				? 'blue-current-update-gantt'
				: dataItem?.isCritical
					? 'red-current-update-gantt'
					: 'green-current-update-gantt';
	}

	/**
	 * used by gantt to know if a row is selected
	 * @param dataItem
	 */
	public isSelected = (dataItem: CurrentUpdateGanttItem): boolean => this.selectionState.has(dataItem.id);

	/**
	 * update selected list based on user click
	 * @param dataItem
	 * @param sender
	 * @param originalEvent
	 */
	public toggleSelection({ dataItem, sender, originalEvent }: CellClickEvent | TaskClickEvent): void {
		// prevents context menu opening
		originalEvent.preventDefault();
		if (this.isSelected(dataItem)) {
			this.selectionState.delete(dataItem.id);
		} else {
			this.selectionState.add(dataItem.id);
		}
		// manually trigger the Gantt to re-evaluate the isSelected callback for each task
		sender.updateView();
	}

	onFilterChange(filter: CompositeFilterDescriptor) {
		this.ganttService.onFilterChange(filter);
	}

	public editorValueChange(value, currentItem?: FilterDescriptor): void {
		if (currentItem) {
			currentItem.value = value;
		}
		this.ganttService.tempFilters = this.filter.value;
		if (this.ganttService.tempFilters !== this.ganttService.filterValue) {
			this.ganttService.applyDisabled = false;
		}
	}

	closeGroups(): void {
		const popupGroupSelectorContainer: HTMLElement = document.getElementById('groupSelectorContainer');
		if (popupGroupSelectorContainer !== null && this.groupSelectorPopupRef !== undefined) {
			this.groupSelectorPopupRef.close();
			this.groupSelectorOpen = false;
		}
		this.groupSelectorPopupState.emit(false);
	}

	closeColumns(): void {
		const popupColumnSelectorContainer: HTMLElement = document.getElementById('columnSelectorContainer');
		if (popupColumnSelectorContainer !== null && this.columnSelectorPopupRef !== undefined) {
			this.columnSelectorPopupRef.close();
			this.columnSelectorOpen = false;
		}
		this.columnSelectorPopupState.emit(false);
	}

	resetColumns(): void {
		const columnOptions: CurrentUpdateGanttColumn[] = structuredClone(allColumns.columns);
		this.columnOptions = columnOptions;
		this.selectedColumns = columnOptions;
		this.ganttService.checkedColumns = ['0', '1', '2', '3', '4', '5', '6'];
	}

	public children = (dataItem: any): Observable<CurrentUpdateGanttColumn[]> => of(dataItem?.children);
	public hasChildren = (dataItem: any): boolean => !!dataItem?.children;
	public isDisabled = (dataItem: any) => dataItem?.sequence === 0;

	public rowCallback({ dataItem, index }: any): any {
		const hasChildren: boolean = dataItem?.childTasks !== null && dataItem?.childTasks?.length > 0;
		const isTopLevel: boolean =
			dataItem?.childTasks !== null && dataItem?.childTasks?.length > 0 && dataItem?.level % 5 === 1;
		const isLevel2: boolean =
			dataItem?.childTasks !== null && dataItem?.childTasks?.length > 0 && dataItem?.level % 5 === 2;
		const isLevel3: boolean =
			dataItem?.childTasks !== null && dataItem?.childTasks?.length > 0 && dataItem?.level % 5 === 3;
		const isLevel4: boolean =
			dataItem?.childTasks !== null && dataItem?.childTasks?.length > 0 && dataItem?.level % 5 === 4;
		const isLevel5: boolean =
			dataItem?.childTasks !== null && dataItem?.childTasks?.length > 0 && dataItem?.level % 5 === 0;
		return {
			ganttTopLevel: isTopLevel,
			ganttLevel2: isLevel2,
			ganttLevel3: isLevel3,
			ganttLevel4: isLevel4,
			ganttLevel5: isLevel5,
			ganttChild: !hasChildren,
		};
	}

	closePresets(): void {
		const popupPresetSelectorContainer: HTMLElement = document.getElementById('presetSelectorContainer');
		if (popupPresetSelectorContainer !== null && this.presetSelectorPopupRef !== undefined) {
			this.presetSelectorPopupRef.close();
			this.presetSelectorOpen = false;
		}
		this.presetSelectorPopupState.emit(false);
	}

	/**
	 * applies the report to the gantt for the headless chrome browser to ss. waits longer than it needs to for safety
	 * @param ev
	 * @param preset
	 */
	headlessBrowserApplyPreset(ev: Event, preset: CurrentUpdateGanttPreset): void {
		let i: number = 0;
		const waitingForDOMToLoad = setInterval(() => {
			const classExists = document.getElementsByClassName('invisible-but-there');
			if (classExists.item(0) !== undefined) {
				clearInterval(waitingForDOMToLoad);
				setTimeout(() => {
					this.presetClicked(ev, preset);
				}, 2000);
			}
			if (i > 50) {
				clearInterval(waitingForDOMToLoad);
			}
			i++;
		}, 600);
	}

	/**
	 * apply preset
	 * @param ev
	 * @param preset
	 */
	async presetClicked(ev: Event, preset: CurrentUpdateGanttPreset): Promise<void> {
		console.log(preset);
		this.ganttService.fullyExpanded.next(this.ganttService.checkIfAllExpanded(this.ganttService.items));
		this.ganttService.fullyCollapsed.next(this.ganttService.checkIfAllCollapsed(this.ganttService.items));
		this.collapseIndex = 0;
		this.ganttService.selectedGanttLayout = preset.id;
		this.ganttService.selectedPresetColumns = preset.visibleColumns;
		const filters: CompositeFilterDescriptor = JSON.parse(preset.filters);

		// After doing JSON.parse the date filters become strings which mess up the filterBy call, so converting to dates
		for (const filter of filters.filters) {
			if ('filters' in filter) {
				//a CompositeFilterDescriptor
			} else if (filter.field === 'start' || filter.field === 'trueEnd') {
				filter.value = new Date(filter.value);
			}
		}

		// this.ganttService.selectedGanttGroupNode = preset.selectedGrouping;
		this.ganttService.radioSelection = preset.grouping.type;
		this.ganttService.radioSelectionDelayed = preset.grouping.type;
		this.ganttService.selectedGroupBy = preset.grouping.type === null ? [] : preset.grouping.actvCodeGrouping;
		this.ganttService.selectedGroupByDelayed = preset.grouping.type === null ? [] : preset.grouping.actvCodeGrouping;
		this.ganttService.selectedWbsLevel =
			preset.grouping.type === null
				? { text: 'Please Select', value: 0 }
				: structuredClone(this.ganttService.allWbsLevels.find((l) => l.value === preset.grouping.wbsToLevel));
		const test: number =
			this.ganttService.selectedWbsLevel?.value === null
				? this.ganttService.highestLevel
				: this.ganttService.selectedWbsLevel?.value;
		this.ganttService.puppetLevel = [];
		for (let i: number = 0; i < test; i++) {
			this.ganttService.puppetLevel.push(i);
		}
		//ToDo: Fix styling puppet level on preset click. Set some sort of timeout or do something because puppetlevel isn't being updated before gantt is updated. -KF
		/*if (this.ganttService.radioSelection === 'wbs' || this.ganttService.radioSelection === 'actvCodes') {
			this.ganttService.applyPhysicallyClicked = true;
			this.ganttService.$reportApply.next(true);
		}

		const test: number =
			this.ganttService.selectedWbsLevel?.value === null
				? this.ganttService.highestLevel
				: this.ganttService.selectedWbsLevel?.value;
		this.ganttService.puppetLevel = [];

		for (let i: number = 0; i < test; i++) {
			this.ganttService.puppetLevel.push(i);
		}*/
		this.ganttService.hideSummaryBars = preset.grouping.hideSummaryBars;
		if (preset.grouping.type === 'wbs') {
			await this.ganttService.applyWbsGrouping();
		} else if (preset.grouping.type === 'actvCodes') {
			await this.ganttService.applyGroupBy();
		} else {
			this.ganttService.sort = [
				{
					field: 'end',
					dir: 'asc',
				},
			];
		}
		if (
			this.ganttService.selectedGanttGroupNode !== null &&
			!this.ganttService.existsInGrouping(
				this.ganttService.allActivityTypes,
				this.ganttService.selectedGanttGroupNode.dataItem
			)
		) {
			this.ganttService.selectedGanttGroupNode = null;
		}
		this.ganttService.showOnlyCritical = preset.showingCritical;
		this.ganttService.showLines = preset.showingRelationships;
		const buttonUpdates = [preset.showingRelationships ? 1 : 0, preset.showingCritical ? 0 : 1];
		this.updateButtonGroupsOnPresetClick.emit(buttonUpdates);
		if (this.ganttService.showLines) {
			let i = 0;
			const waitingForExpandedMetrics = setInterval(() => {
				if (this.projectService.$expandedMetrics.value?.totalRelationships !== undefined || i > 50) {
					clearInterval(waitingForExpandedMetrics);
					this.ganttService.generateDependencies(this.projectService.$expandedMetrics.value);
				}
				i++;
			}, 200);
		}
		this.ganttService.filterValue = filters;
		if (this.maskedFilterValue !== null) {
			if (this.maskedFilterValue?.logic !== 'and' || this.maskedFilterValue?.filters?.length > 0) {
				this.ganttService.resetDisabled = false;
				this.resetStateChanged.emit(false);
			}
		} else {
			if (filters.logic !== 'and' || filters.filters.length > 0) {
				this.ganttService.resetDisabled = false;
				this.resetStateChanged.emit(false);
			}
		}
		this.ganttService.checkedColumns = preset.visibleColumns;

		if (this.ganttService.selectedGanttGroupNode !== null) {
			this.ganttService.noSelection = false;
			return;
		} else {
			this.ganttService.noSelection = true;
			setTimeout(() => {
				this.ganttService.sort = structuredClone(this.ganttService.sort);
			}, 200);
		}
		await this.ganttService.updateGanttVisibleItems(
			preset.grouping.type === 'wbs' || preset.grouping.type === 'actvCodes',
			1
		);
		this.ganttService.checkResetBtn();
	}

	/**
	 * trigger confirm delete dialog
	 * @param ev
	 * @param preset
	 */
	deletePreset(ev: Event, preset: CurrentUpdateGanttPreset): void {
		this.ganttService.deletingPreset = preset;
		this.ganttService.ganttPresetDeleteConfirmOpen = true;
		// necessary to avoid bubbling the click event to the presetClicked function -RS
		ev.cancelBubble = true;
		ev.stopPropagation();
	}

	confirmQuickSave(): void {
		const newPreset: CurrentUpdateGanttPreset = {
			filters: JSON.stringify(this.ganttService.filterValue),
			visibleColumns: this.ganttService.checkedColumns,
			name: this.ganttService.quickSaveName,
			isFavorite: this.ganttService.quickSaveIsFavorite,
			id: crypto.randomUUID(),
			grouping: this.ganttService.getGrouping(),
			showingRelationships: this.ganttService.showLines,
			showingCritical: this.ganttService.showOnlyCritical,
		};
		this.ganttService.addNewGanttPreset(newPreset);
		this.ganttService.toggleQuickSaveDialog(false);
	}

	closeConfirmDialog(): void {
		this.ganttService.deletingPreset = null;
		this.ganttService.ganttPresetDeleteConfirmOpen = false;
	}

	openGroupsPopup(): void {
		this.ganttService.$manageWindowOpen.next(true);
		this.windowService.setViewport('reportManage');
	}
	public wbsColors(dataItem: CurrentUpdateGanttItem, i: number, type: 'wbs' | 'actvCode'): string {
		const childI: number = i + 1;
		return type === 'wbs' && dataItem?.childTasks === null && childI >= dataItem?.level
			? 'transparent'
			: dataItem?.level === null || dataItem.level > i
				? this.wbsColorStrings[i % 5]
				: this.wbsColorStrings[(dataItem.level - 1) % 5];
	}
}
