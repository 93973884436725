<div class='card-header-container' style='padding: 0.7em 11px' *ngIf='visualizer'>
	<div class="card-header">
		Schedule Analysis
	</div>
</div>

<div class='card' style='page-break-inside: avoid;'>
	<kendo-gridlayout
		[rows]="visualizer ? ['662.56px'] : isOverview ? ['42,4px', '437.4px'] : ['42.4px', '710px']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='full-width full-height'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' *ngIf='!visualizer' [class.overviewHeader]="isOverview">
			<kendo-gridlayout
				[rows]="['42.4px']"
				[cols]="isOverview ? ['100%'] : ['calc(100% - 52px)', '42px']"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width full-height'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header">
						Schedule Analysis @if (!isOverview) {<span style="font-size: 9px; position: relative; top: -1px;">(ALL ACTIVITIES)</span>}
					</div>
					<tooltip-open type='{{isOverview ? "overview" : "schedule-analysis"}}' style='padding-left: 3px;' [fillColor]="isOverview ? 'overview' : ''"></tooltip-open>
				</kendo-gridlayout-item>
				@if (!isOverview) {
					<kendo-gridlayout-item [row]='1' [col]='2' class='toolbar-item-container'>
						<span style='display: inline-flex; align-items: center; height: min-content; margin: auto;' kendoTooltip *ngIf='currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK"'>
							<button
								kendoButton
								(click)="doExport($event)"
								*ngIf="(project.$expandedMetrics | async) && !isExportRunning"
								[disabled]="isExportRunning"
								title='Export Report'
								themeColor='base'
								class="header-btn"
								[imageUrl]='"/assets/icons/newIcons/excelExport.svg"'
							>
							</button>
							<button
								kendoButton
								*ngIf="(project.$expandedMetrics | async) && isExportRunning"
								title='Exporting...'
								themeColor='base'
								class="header-btn"
								style='padding-top: 2px; padding-bottom: 5px;'
							>
								<kendo-circularprogressbar
									[indeterminate]="true"
									[value]="30"
									[progressColor]='"black"'
									id='schedule-analysis-excel-export-spin'
								></kendo-circularprogressbar>
							</button>
						</span>
						<div kendoTooltip style="text-align: center;" *ngIf='currentProjectCompanyPermissions?.license === "ANALYTICS-BASIC-MPK" || currentProjectCompanyPermissions?.license === "ANALYTICS-BASIC-APK"'>
							<div title='Upgrade to a Professional License to enable Exports' style='margin-top: 9px; margin-right: 4px;'>
								<button kendoButton themeColor="base" class='header-btn' [imageUrl]='"/assets/icons/newIcons/excelExport.svg"' [disabled]='true'></button>
							</div>
						</div>
					</kendo-gridlayout-item>
				}
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='1' *ngIf="(project.$expandedMetrics | async) === null" style='page-break-inside: avoid;'>
			<ion-progress-bar type="indeterminate"></ion-progress-bar>
			<p class="ion-text-center"></p>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='visualizer ? 1 : 2' [col]='1' *ngIf="(project.$expandedMetrics | async)">
			<kendo-gridlayout
				[rows]="isOverview ? ['437.4px'] : ['710px']"
				[cols]="visualizer ? ['calc(100% - 5px)'] : ['minmax(530px, calc((400% / 12) - 5px))', 'calc((800% / 12) - 5px)']"
				[gap]="{ rows: 0, cols: 5 }"
			>
				<kendo-gridlayout-item [row]='1' [col]='1'>
					<kendo-gridlayout
						[gap]="{ rows: 5, cols: 0 }"
					>
						@if (!isOverview) {
							<kendo-gridlayout-item [row]='1' [col]='1'>
								<kendo-gridlayout
									[rows]="['calc((100% - 25px) / 6)', 'calc((100% - 25px) / 6)', 'calc((100% - 25px) / 6)', 'calc((100% - 25px) / 6)', 'calc((100% - 25px) / 6)', 'calc((100% - 25px) / 6)']"
									[cols]="['1fr', '1fr']"
									[gap]="5"
									style='padding: 5px 0 0 5px; height: 265px'
								>
									<kendo-gridlayout-item [row]='1' [col]='1' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'totalActivities'" (click)="loadNewDisplaySet('totalActivities')" class='grid-btn'>
											<div>Total Activities: {{ allTasksArray?.filter(fn2).length | number:'1.0-0'}}</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='1' [col]='2' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'totalRelationships'" (click)="loadNewDisplaySet('totalRelationships')" class='grid-btn'>
											<div>Total Relationships: {{ allRelationships.size | number:'1.0-0'}}</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='2' [col]='1' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'remainingActivities'" (click)="loadNewDisplaySet('remainingActivities')" class='grid-btn'>
											<div>Remaining Activities: {{ remainingActivities.size | number:'1.0-0'}}</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='2' [col]='2' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'fs'" (click)="loadNewDisplaySet('fs')" class='grid-btn'>
											<div><img [src]="'/assets/ui/gantt/PR_FS' + (currentDisplayButton === 'fs' ? '_white' : '' ) + '.png'"> FS: {{ (this.$fs | async) | number: '1.0-1'}}%</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='3' [col]='1' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'Tasks'" (click)="loadNewDisplaySet('Tasks')" class='grid-btn'>
											<div><img [src]="'/assets/ui/activity_type/TT_Task' + (currentDisplayButton === 'Tasks' ? '_white' : '') + '.png'"> Tasks: {{(this.expandedMetrics | async)?.totalActivities?.filter(fn).length | number:'1.0-0'}}</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='3' [col]='2' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'ss'" (click)="loadNewDisplaySet('ss')" class='grid-btn'>
											<div><img [src]="'/assets/ui/gantt/PR_SS' + (currentDisplayButton === 'ss' ? '_white' : '' ) + '.png'"> SS: {{ (this.$ss | async) | number: '1.0-1'}}%</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='4' [col]='1' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'Milestones'" (click)="loadNewDisplaySet('Milestones')" class='grid-btn'>
											<div><img [src]="'/assets/ui/activity_type/TT_Mile' + (currentDisplayButton === 'Milestones' ? '_white' : '') + '.png'"> Milestones: {{(this.expandedMetrics | async)?.milestones?.length | number:'1.0-0'}}</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='4' [col]='2' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'ff'" (click)="loadNewDisplaySet('ff')" class='grid-btn'>
											<div><img [src]="'/assets/ui/gantt/PR_FF' + (currentDisplayButton === 'ff' ? '_white' : '' ) + '.png'"> FF: {{(this.$ff | async) | number: '1.0-1'}}%</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='5' [col]='1' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'LOES'" (click)="loadNewDisplaySet('LOES')" class='grid-btn'>
											<div><img [src]="'/assets/ui/activity_type/TT_LOE' + (currentDisplayButton === 'LOES' ? '_white' : '') + '.png'"> LOE's: {{ loes.size | number:'1.0-0'}}</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='5' [col]='2' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'sf'" (click)="loadNewDisplaySet('sf')" class='grid-btn'>
											<div><img [src]="'/assets/ui/gantt/PR_SF' + (currentDisplayButton === 'sf' ? '_white' : '' ) + '.png'"> SF: {{ (this.$sf | async) | number: '1.0-1'}}%</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='6' [col]='1' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'redundantLogic'" (click)="loadNewDisplaySet('redundantLogic')" class='grid-btn'>
											<div>Redundant Logic: {{ redundantLogic.size }}</div>
										</div>
									</kendo-gridlayout-item>
									<kendo-gridlayout-item [row]='6' [col]='2' class='grid-btn-container'>
										<div [class.selected-button]="currentDisplayButton === 'multipleTies'" (click)="loadNewDisplaySet('multipleTies')" class='grid-btn'>
											<div>Multiple Ties: {{ multipleTies.size }}</div>
										</div>
									</kendo-gridlayout-item>
								</kendo-gridlayout>
							</kendo-gridlayout-item>
						}
						<kendo-gridlayout-item [row]='isOverview ? 1 : 2' [col]='1' [style.padding]='isOverview ? "5px 0 5px 5px" : "0 0 5px 5px"'>
							<kendo-gridlayout
								[rows]="['25px', '1fr']"
								[cols]="['calc(70% - (5px / 3))', 'calc(15% - (5px / 3))', 'calc(15% - (5px / 3))']"
								[gap]="{ rows: 0, cols: 0 }"
								class='warning-bottom-section-container'
							>
								<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container' style='background: transparent; font-weight: 600;'>
									<div class="card-header">
										Potential Schedule Issues
									</div>
									<tooltip-open type="prob-activities"></tooltip-open>
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]='1' [col]='2' class='card-header-container-score-impact' style='background: transparent; font-weight: 600;'>
									<div class="card-header">
										Count
									</div>
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]='1' [col]='3' class='card-header-container-score-impact' style='background: transparent; font-weight: 600;'>
									<div class="card-header">
										QC Impact
									</div>
								</kendo-gridlayout-item>
								<kendo-gridlayout-item [row]='2' [col]='1' [colSpan]="3">
									<kendo-gridlayout
										[rows]="[
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)',
											'calc((100% - 60px) / 13)'
										]"

										[cols]="['calc(70% - (5px / 3))', 'calc(15% - (5px / 3))', 'calc(15% - (5px / 3))']"
										[gap]="5"
										style='padding: 5px 0 0 5px; height: 388px'
									>
										<kendo-gridlayout-item [row]='1' [col]='1' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'highDurationActivities'" (click)="loadNewDisplaySet('highDurationActivities')" class='grid-btn-label'>
												<div>High Duration (RD > 44)</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='1' [col]='2' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'highDurationActivities'" (click)="loadNewDisplaySet('highDurationActivities')" class='grid-btn'>
												<div>{{ (this.expandedMetrics | async)?.highDurationActivities?.length | number:'1.0-0'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='1' [col]='3' class='grid-btn-container'>
											<div class="grid-btn-score-impact" [ngStyle]="{'color': durationPenalty < 0 ? scoreImpactRedColor : scoreImpactGreenColor}" (click)="loadNewDisplaySet('highDurationActivities')" [class.selected-button-issues]="currentDisplayButton === 'highDurationActivities'">
												<div>{{durationPenalty < 0 ? (durationPenalty | number:'1.0-1') + '%' : '-'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='2' [col]='1' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'highFloatActivities'" (click)="loadNewDisplaySet('highFloatActivities')" class='grid-btn-label'>
												<div>High Float (TF > 80 + Critical Float)</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='2' [col]='2' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'highFloatActivities'" (click)="loadNewDisplaySet('highFloatActivities')" class='grid-btn'>
												<div>{{ (this.expandedMetrics | async)?.highFloatActivities?.length | number:'1.0-0'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='2' [col]='3' class='grid-btn-container'>
											<div class="grid-btn-score-impact" [ngStyle]="{'color': floatPenalty < 0 ? scoreImpactRedColor : scoreImpactGreenColor}" (click)="loadNewDisplaySet('highFloatActivities')" [class.selected-button-issues]="currentDisplayButton === 'highFloatActivities'">
												<div>{{floatPenalty < 0 ? (floatPenalty | number:'1.0-1') + '%' : '-'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='3' [col]='1' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'ssFFWithLags'" (click)="loadNewDisplaySet('ssFFWithLags')" class='grid-btn-label'>
												<div>SS/FF with Problematic Lags</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='3' [col]='2' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'ssFFWithLags'" (click)="loadNewDisplaySet('ssFFWithLags')" class='grid-btn'>
												<div>{{ (this.expandedMetrics | async)?.ssFFProblematicLags?.length | number:'1.0-0'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='3' [col]='3' class='grid-btn-container'>
											<div class="grid-btn-score-impact" [ngStyle]="{'color': ssffProblematicPenalty < 0 ? scoreImpactRedColor : scoreImpactGreenColor}" (click)="loadNewDisplaySet('ssFFWithLags')" [class.selected-button-issues]="currentDisplayButton === 'ssFFWithLags'">
												<div>{{ssffProblematicPenalty < 0 ? (ssffProblematicPenalty | number:'1.0-1') + '%' : '-'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='4' [col]='1' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'openStartFinish'" (click)="loadNewDisplaySet('openStartFinish')" class='grid-btn-label'>
												<div>Open Start/Finish</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='4' [col]='2' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'openStartFinish'" (click)="loadNewDisplaySet('openStartFinish')" class='grid-btn'>
												<div>{{ (this.expandedMetrics | async)?.openStartFinish?.length | number:'1.0-0'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='4' [col]='3' class='grid-btn-container'>
											<div class="grid-btn-score-impact" [ngStyle]="{'color': openStartFinishPenalty < 0 ? scoreImpactRedColor : scoreImpactGreenColor}" (click)="loadNewDisplaySet('openStartFinish')" [class.selected-button-issues]="currentDisplayButton === 'openStartFinish'">
												<div>{{openStartFinishPenalty < 0 ? (openStartFinishPenalty | number:'1.0-1') + '%' : '-'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='5' [col]='1' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'missingPredSuccessors'" (click)="loadNewDisplaySet('missingPredSuccessors')" class='grid-btn-label'>
												<div>Missing Predecessors or Successors</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='5' [col]='2' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'missingPredSuccessors'" (click)="loadNewDisplaySet('missingPredSuccessors')" class='grid-btn'>
												<div>{{ (this.expandedMetrics | async)?.missingPredecessors?.length + (this.expandedMetrics | async)?.missingSuccessors?.length | number:'1.0-0'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='5' [col]='3' class='grid-btn-container'>
											<div class="grid-btn-score-impact" [ngStyle]="{'color': missingPredSuccPenalty < 0 ? scoreImpactRedColor : scoreImpactGreenColor}" (click)="loadNewDisplaySet('missingPredSuccessors')" [class.selected-button-issues]="currentDisplayButton === 'missingPredSuccessors'">
												<div>{{missingPredSuccPenalty < 0 ? (missingPredSuccPenalty | number:'1.0-1') + '%' : '-'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='6' [col]='1' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'negativeLags'" (click)="loadNewDisplaySet('negativeLags')" class='grid-btn-label'>
												<div>Negative Lags</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='6' [col]='2' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'negativeLags'" (click)="loadNewDisplaySet('negativeLags')" class='grid-btn'>
												<div>{{ (this.expandedMetrics | async)?.negativeLags?.length | number:'1.0-0'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='6' [col]='3' class='grid-btn-container'>
											<div class="grid-btn-score-impact" [ngStyle]="{'color': negLagPenalty < 0 ? scoreImpactRedColor : scoreImpactGreenColor}" (click)="loadNewDisplaySet('negativeLags')" [class.selected-button-issues]="currentDisplayButton === 'negativeLags'">
												<div>{{negLagPenalty < 0 ? (negLagPenalty | number:'1.0-1') + '%' : '-'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='7' [col]='1' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'fsWithLags'" (click)="loadNewDisplaySet('fsWithLags')" class='grid-btn-label'>
												<div>FS with Lags</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='7' [col]='2' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'fsWithLags'" (click)="loadNewDisplaySet('fsWithLags')" class='grid-btn'>
												<div>{{ (this.expandedMetrics | async)?.fsProblematicLags?.length | number:'1.0-0'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='7' [col]='3' class='grid-btn-container'>
											<div class="grid-btn-score-impact" [ngStyle]="{'color': fsLagPenalty < 0 ? scoreImpactRedColor : scoreImpactGreenColor}" (click)="loadNewDisplaySet('fsWithLags')" [class.selected-button-issues]="currentDisplayButton === 'fsWithLags'">
												<div>{{fsLagPenalty < 0 ? (fsLagPenalty | number:'1.0-1') + '%' : '-'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='8' [col]='1' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'hardConstraints'" (click)="loadNewDisplaySet('hardConstraints')" class='grid-btn-label'>
												<div>Hard Constraints</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='8' [col]='2' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'hardConstraints'" (click)="loadNewDisplaySet('hardConstraints')" class='grid-btn'>
												<div>{{ (this.expandedMetrics | async)?.hardConstraints?.length | number:'1.0-0'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='8' [col]='3' class='grid-btn-container'>
											<div class="grid-btn-score-impact" [ngStyle]="{'color': hardConstraintPenalty < 0 ? scoreImpactRedColor : scoreImpactGreenColor}" (click)="loadNewDisplaySet('hardConstraints')" [class.selected-button-issues]="currentDisplayButton === 'hardConstraints'">
												<div>{{hardConstraintPenalty < 0 ? (hardConstraintPenalty | number:'1.0-1') + '%' : '-'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='9' [col]='1' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'oosCrit'" (click)="loadNewDisplaySet('oosCrit')" class='grid-btn-label'>
												<div>Out of Sequence (Critical)</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='9' [col]='2' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'oosCrit'" (click)="loadNewDisplaySet('oosCrit')" class='grid-btn'>
												<div>{{ (this.expandedMetrics | async)?.outOfSequenceCritical?.length || 0 | number:'1.0-0'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='9' [col]='3' class='grid-btn-container'>
											<div class="grid-btn-score-impact" [ngStyle]="{'color': oosCriticalPenalty < 0 ? scoreImpactRedColor : scoreImpactGreenColor}" (click)="loadNewDisplaySet('oosCrit')" [class.selected-button-issues]="currentDisplayButton === 'oosCrit'">
												<div>{{oosCriticalPenalty < 0 ? (oosCriticalPenalty | number:'1.0-1') + '%' : '-'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='10' [col]='1' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'oosNearCrit'" (click)="loadNewDisplaySet('oosNearCrit')" class='grid-btn-label'>
												<div>Out of Sequence (Near-Critical)</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='10' [col]='2' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'oosNearCrit'" (click)="loadNewDisplaySet('oosNearCrit')" class='grid-btn'>
												<div>{{ (this.expandedMetrics | async)?.outOfSequenceNearCritical?.length || 0 | number:'1.0-0'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='10' [col]='3' class='grid-btn-container'>
											<div class="grid-btn-score-impact" [ngStyle]="{'color': oosNearCriticalPenalty < 0 ? scoreImpactRedColor : scoreImpactGreenColor}" (click)="loadNewDisplaySet('oosNearCrit')" [class.selected-button-issues]="currentDisplayButton === 'oosNearCrit'">
												<div>{{oosNearCriticalPenalty < 0 ? (oosNearCriticalPenalty | number:'1.0-1') + '%' : '-'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='11' [col]='1' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'oosNonCrit'" (click)="loadNewDisplaySet('oosNonCrit')" class='grid-btn-label'>
												<div>Out of Sequence (Non-Critical)</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='11' [col]='2' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'oosNonCrit'" (click)="loadNewDisplaySet('oosNonCrit')" class='grid-btn'>
												<div>{{ (this.expandedMetrics | async)?.outOfSequence?.length - (this.expandedMetrics | async)?.outOfSequenceCritical?.length - (this.expandedMetrics | async)?.outOfSequenceNearCritical?.length | number:'1.0-0'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='11' [col]='3' class='grid-btn-container'>
											<div class="grid-btn-score-impact" [ngStyle]="{'color': oosNonCriticalPenalty < 0 ? scoreImpactRedColor : scoreImpactGreenColor}" (click)="loadNewDisplaySet('oosNonCrit')" [class.selected-button-issues]="currentDisplayButton === 'oosNonCrit'">
												<div>{{oosNonCriticalPenalty < 0 ? (oosNonCriticalPenalty | number:'1.0-1') + '%' : '-'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='12' [col]='1' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'logicDensity'" (click)="loadNewDisplaySet('logicDensity')" class='grid-btn-label'>
												<div>Logic Density</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='12' [col]='2' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'logicDensity'" (click)="loadNewDisplaySet('logicDensity')" class='grid-btn'>
												<div>{{allRelationships.size / allActivities.size | number: '1.0-1'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='12' [col]='3' class='grid-btn-container'>
											<div class="grid-btn-score-impact" [ngStyle]="{'color': relationshipDensityPenalty < 0 ? scoreImpactRedColor : scoreImpactGreenColor}" (click)="loadNewDisplaySet('logicDensity')" [class.selected-button-issues]="currentDisplayButton === 'logicDensity'">
												<div>{{relationshipDensityPenalty < 0 ? (relationshipDensityPenalty | number:'1.0-1') + '%' : '-'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='13' [col]='1' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'sf_prob'" (click)="loadNewDisplaySet('sf_prob')" class='grid-btn-label'>
												<div>Start to Finish Relationships</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='13' [col]='2' class='grid-btn-container'>
											<div [class.selected-button-issues]="currentDisplayButton === 'sf_prob'" (click)="loadNewDisplaySet('sf_prob')" class='grid-btn'>
												<div>{{(this.expandedMetrics | async)?.sf?.length | number:'1.0-0'}}</div>
											</div>
										</kendo-gridlayout-item>
										<kendo-gridlayout-item [row]='13' [col]='3' class='grid-btn-container'>
											<div class="grid-btn-score-impact" [ngStyle]="{'color': sfPenalty < 0 ? scoreImpactRedColor : scoreImpactGreenColor}" (click)="loadNewDisplaySet('sf_prob')" [class.selected-button-issues]="currentDisplayButton === 'sf_prob'">
												<div>{{sfPenalty < 0 ? (sfPenalty | number:'1.0-1') + '%' : '-'}}</div>
											</div>
										</kendo-gridlayout-item>
									</kendo-gridlayout>
								</kendo-gridlayout-item>
							</kendo-gridlayout>
						</kendo-gridlayout-item>
					</kendo-gridlayout>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item *ngIf='!visualizer' [row]='1' [col]='2' class='task-table'>
					@if (!isOverview || (isOverview && isProblematicScheduleActivitiesBtn)) {
						<kendo-textbox
							class='searchInput'
							[placeholder]="scheduleAnalysisSearchBarString"
							[clearButton]="true"
							[size]='"large"'
							[(value)]='searchTerm'
							(valueChange)='updateFilter({searchTerm: $event})'
							[disabled]='loading'
							style="margin-top: 5px;"
						>
							<ng-template kendoTextBoxPrefixTemplate>
								<kendo-svgicon [icon]='svgSearch'></kendo-svgicon>
								<kendo-textbox-separator></kendo-textbox-separator>
							</ng-template>
						</kendo-textbox>
						<div style="text-align: center; font-size: large; padding: 5px 0;">
							<span>{{ tableTitle }} - Showing {{ currentDisplaySet?.length || 0 }} of {{ unfilteredDisplaySet?.length || 0 }}</span>
						</div>
						<kendo-grid
							#grid
							[data]="gridView"
							[kendoGridBinding]="gridData"
							[height]="isOverview ? 363 : 630"
							[rowHeight]='40'
							[loading]='loading'
							[size]='"small"'
							[sortable]='true'
							[sort]="sort"
							(sortChange)="sortChange($event)"
							[filter]='filter'
							(filterChange)="filterChange($event)"
							(pageChange)="pageChange($event)"
							[skip]="skip"
							[pageSize]="pageSize"
							scrollable="virtual"
							[navigable]="false"
							class='sched-grid'
						>
							<kendo-grid-column
								*ngFor='let column of selectedColumns'
								[field]="column.field"
								[title]="column.title"
								[width]='column.width'
								[format]="column?.format"
								headerClass="header-class"
							>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "string"'>
									{{ dataItem[column.field] }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "img"'>
									<img [src]="column.id === 25 ? '/assets/ui/gantt/' + dataItem.pred_type + '.png' : '/assets/ui/activity_type/' + dataItem.task_type + '.png'" style='vertical-align: -1.5px'/>
									{{ column.id === 0 ? dataItem.task_code : column.id === 20 ? taskTypeDictionary[dataItem.task_type] : linkTypeDictionary[dataItem.pred_type] }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "date"'>
									{{ (dataItem[column.field] | date: 'MM/dd/yyyy') || 'N/A' }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "actIfPossible"'>
									{{ dataItem[column.field] ? ((dataItem[column.field] | date: 'MM/dd/yyyy') + ' A') : dataItem[column.otherField] ? (dataItem[column.otherField] | date: 'MM/dd/yyyy') : 'N/A' }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "hours"'>
									{{ dataItem[column.field] | floor }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "calendar"'>
									{{ dataItem?.calendar || 'N/A' }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "status"'>
									{{ tableDataType === 'Relationship' ? taskStatusDictionary[allActivities.get(+dataItem.task_id)?.status_code || allActivities.get(+dataItem.pred_task_id)?.status_code] : taskStatusDictionary[dataItem.status_code] }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "missing"'>
									Missing {{(expandedMetrics | async)?.missingSuccessors?.includes(dataItem) ? 'Successor' : 'Predecessor'}}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "constraint"'>
									{{ constraintDict[dataItem[column.field]] || 'N/A' }}
								</ng-template>
								<ng-template kendoGridCellTemplate let-dataItem *ngIf='column.type === "lag"'>
									{{ dataItem.lagHrs | floor }}
								</ng-template>
							</kendo-grid-column>
						</kendo-grid>
					}
					@if (isOverview && !isProblematicScheduleActivitiesBtn) {
						<div class="warning-div" style="height: 100%">
							Select an issue type to view the activities contained
						</div>
					}
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>
