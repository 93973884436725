import { FloatIndex, TotalFloatIndexArgs } from '../report';

export function progress(activityCompletionIndex: number, periodFloat: number, baselineFloatIndex: number): number {
	return (activityCompletionIndex + periodFloat + baselineFloatIndex) / 3;
}

export function periodicFloat(delta: {
	floatNegative: number;
	floatNone: number;
	floatWeek: number;
	floatMonth: number;
	floatLarge: number;
}): number {
	return (
		Math.max(0, Math.min(0.4, 0.4 - delta.floatNegative / 0.5)) +
		Math.max(0, Math.min(0.4, 0.4 - delta.floatNone / 0.5)) +
		Math.max(0, Math.min(0.1, 0.1 - delta.floatWeek)) +
		Math.max(0, Math.min(0.07, 0.07 - delta.floatMonth)) +
		Math.max(0, Math.min(0.03, 0.03 + delta.floatLarge))
	);
}

export function TotalFloatIndex(args: TotalFloatIndexArgs[]): FloatIndex {
	const stats: FloatIndex = {
		periodDelta: {
			floatNegative: 0,
			floatNone: 0,
			floatWeek: 0,
			floatMonth: 0,
			floatLarge: 0,
			floatAverage: 0,
		},
		baselineDelta: {
			floatNegative: 0,
			floatNone: 0,
			floatWeek: 0,
			floatMonth: 0,
			floatLarge: 0,
			floatAverage: 0,
		},
	};
	const baselineStats = args[0];
	const baselineTotal =
		baselineStats?.floatLarge +
			baselineStats?.floatMonth +
			baselineStats?.floatWeek +
			baselineStats?.floatNegative +
			baselineStats?.floatNone || 1;
	for (let i = 0; i < args.length; i++) {
		const updateStats = args[i];
		const prevStats = i > 0 ? args[i - 1] : undefined;
		if (!updateStats) {
			continue;
		}
		const total =
			args[i].floatWeek + args[i].floatMonth + args[i].floatNone + args[i].floatLarge + args[i].floatNegative || 1;
		const prevTotal = prevStats
			? prevStats.floatWeek +
					prevStats.floatMonth +
					prevStats.floatNone +
					prevStats.floatLarge +
					prevStats.floatNegative || 1
			: 1;
		for (const key in updateStats) {
			const a: number = updateStats[key];
			const b: number = total;
			const c: number = prevStats?.[key] || 0;
			const d: number = prevTotal;
			stats.periodDelta[key] = (a * d - b * c) / (b * d);

			const e: number = baselineStats?.[key] || 0;
			const f: number = baselineTotal;
			stats.baselineDelta[key] = (a * f - b * e) / (b * f);
		}
	}
	return stats;
}
