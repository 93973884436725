import {
	AfterViewInit,
	Component,
	ElementRef,
	HostBinding,
	Input,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import {
	Activity,
	ActivityPredecessor,
	Calendar,
	CalendarVariableAliases,
	ICalendar,
	isDriving,
	tolerantTopologicalSort,
	Xer,
	XerActivity,
	XerCalendar,
	XerProject,
	XerTaskPredecessor,
} from '@rhinoworks/xer-parse';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import {
	GanttBarClickEvent,
	GanttBaselineItem,
	GanttDate,
	GanttItem,
	GanttLinkLineType,
	GanttLinkType,
	GanttPrintService,
	GanttToolbarOptions,
	GanttViewOptions,
	GanttViewType,
	NgxGanttComponent,
} from '@worktile/gantt';
import { differenceInCalendarDays, differenceInDays, isAfter, max, min, startOfDay } from 'date-fns';
import { IButton } from '../../../../models/Project';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { searchIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { SlimmedTaskCommon, UpdateInterface } from '../../../../models/Update/Task';
import { buildEntryFromVariables, sortActivities } from '../../../../util/tasks';
import { ProjectDashboardService } from '../../../../services/project/project.service';

interface CustomGanttItem extends GanttItem {
	tag?: string;
	name: string;
	progressDelay: number;
	isCritical: boolean;
	isNew: boolean;
	changedDuration: boolean;
	changedConstraint: boolean;
	changedLogic: boolean;
}

@Component({
	selector: 'app-schedule-comparison',
	templateUrl: './schedule-comparison.component.html',
	styleUrls: ['./schedule-comparison.component.scss'],
	providers: [GanttPrintService],
	encapsulation: ViewEncapsulation.None,
})
export class ScheduleComparisonComponent implements OnInit, AfterViewInit {
	@Input() isOverview: boolean = false;
	public svgSearch: SVGIcon = searchIcon;
	public allItems: CustomGanttItem[] = [];
	public items: CustomGanttItem[] = [];
	public criticalItemsCount: number = 0;
	GanttLinkLineType = GanttLinkLineType;
	toolbarOptions: GanttToolbarOptions = {
		viewTypes: [GanttViewType.day, GanttViewType.month, GanttViewType.year],
	};

	viewOptions: GanttViewOptions = {
		dateFormat: {
			yearQuarter: `QQQ 'of' yyyy`,
			month: 'LLLL',
			yearMonth: `LLLL yyyy'(week' w ')'`,
		},
	};

	baselineItems: GanttBaselineItem[] = [];

	options = {
		viewType: GanttViewType.day,
	};
	@HostBinding('class.gantt-example-component') class = true;

	@ViewChild('ganttSchedComparison') ganttComponent: NgxGanttComponent;
	@ViewChild('schedComparisonSearch') searchBar: TextBoxComponent;

	showLines = false;
	prevUpdate: UpdateInterface;
	prevTasks = new Map<string, XerActivity>();
	currTasks = new Map<number, XerActivity>();
	currentUpdate: UpdateInterface;
	start: number;
	end: number;
	currentView: string = 'critical';
	isCritical: boolean = true;
	loading = false;

	scheduleOptions: Array<{ text: string; value: number }> = [];
	currUpdateIndex: number = 0;

	tooltipOpen = false;
	tooltipActv: {
		current: SlimmedTaskCommon;
	};
	actvFilters = ['Additions', 'Duration', 'Progress', 'Constraint', 'Logic'] as const;
	public actvFilterCounts: Record<(typeof this.actvFilters)[number], [number, number]> = {
		Additions: [0, 0],
		Duration: [0, 0],
		Progress: [0, 0],
		Constraint: [0, 0],
		Logic: [0, 0],
	};
	searchTerm = '';
	public taskTypeButtons: IButton[] = [
		{
			text: 'Critical',
			value: 0,
			selected: true,
			disabled: false,
		},
		{
			text: 'Show All',
			value: 1,
			disabled: false,
		},
	];
	public relationshipsButtons: IButton[] = [
		{
			text: 'Hide',
			value: 0,
			selected: true,
			disabled: false,
		},
		{
			text: 'Show',
			value: 1,
			disabled: false,
		},
	];
	totalUpdates = 0;
	tasksByUpdate = new Map<string, Set<string>>([]);

	constructor(
		public scheduleStorage: ScheduleStorageService,
		public projectService: ProjectDashboardService,
		private elementRef: ElementRef<HTMLElement>
	) {}

	ngOnInit(): void {
		this.scheduleStorage.$allUpdates.subscribe((updates) => {
			this.totalUpdates = updates.length;
			this.currUpdateIndex = updates.length - 1;
			this.scheduleOptions = updates
				.map((update, i) => ({
					text: !i ? '' : `${i === 1 ? 'Baseline' : `Update ${i - 1}`} to Update ${i}`,
					value: i,
				}))
				.slice(1);
			this.setCurrentIndex(this.scheduleOptions[this.scheduleOptions.length - 1]);
			this.scheduleStorage.grabUpdateTable<XerActivity>(updates[updates.length - 1]._id, 'TASK').then((tasks) => {
				this.tasksByUpdate.set(updates[updates.length - 1]._id, new Set(tasks.map((t) => t.task_code)));
			});
		});
	}

	// Unsuccessful attempt of data date line on this angular gantt. -KF
	/*drawDataDateLine(dataDate: Date): void {
		const i: number = 0;
		const waitForDOMInterval = setInterval(() => {
			const timeline = document.getElementsByClassName('gantt-container-header');
			if (timeline.length > 0 || i > 500) {
				clearInterval(waitForDOMInterval);
				const header = document.getElementsByClassName('gantt-calendar-header');
				const minStart: number = this.ganttComponent.start;
				const maxEnd: number = this.ganttComponent.end;
				const totalTimespan: number = differenceInCalendarDays(maxEnd, minStart);
				const daysElapsed: number = differenceInCalendarDays(dataDate, minStart) + 0.75; // the .75 is not an exact science
				const rightPercent: number = 1 - daysElapsed / (totalTimespan || 1);
				let rightValue: number = header.item(0).clientWidth * rightPercent;
				const dataDateLine = document.getElementsByClassName('today-line');
				if (dataDateLine?.length) {
					//rightValue = 400;
					dataDateLine.item(0).setAttribute('style', 'right:' + rightValue + 'px');
				}
				//hides data date line if its past the gantt timeline
				const ganttTimeline = timeline.item(0).getBoundingClientRect();
				const dateLine = dataDateLine?.item(0).getBoundingClientRect();

				if (dateLine.left < ganttTimeline.left || dateLine.left > ganttTimeline.right) {
					//dataDateLine.item(0).setAttribute('style', 'display: none');
				}
			}
		}, 200);
	}*/

	ngAfterViewInit() {
		setTimeout(() => (this.items.length ? this.ganttComponent.scrollToDate(this.items[0].end) : null), 200);
	}

	setTodayPoint() {
		const todayEle = this.elementRef.nativeElement.getElementsByClassName(
			'gantt-calendar-today-overlay'
		)[1] as HTMLElement;
		const line = this.elementRef.nativeElement.getElementsByClassName('today-line')[0] as HTMLElement;

		const latestDataDate = new Date(
			this.projectService.$currentProjectReport.value.projectCompletionTrend.projectCompletionTrendArray[
				this.currUpdateIndex
			].dataDate
		);
		const ganttDate = new GanttDate(latestDataDate).startOfDay();
		const result =
			this.ganttComponent.view.getXPointByDate(ganttDate) +
			this.ganttComponent.view.getDayOccupancyWidth(ganttDate) / 2;
		if (typeof result === 'number') {
			if (line) {
				todayEle.style.removeProperty('display');
				line.style.left = `${result}px`;
				line.style.top = `0`;
				line.style.bottom = '-5000px';
			}
		} else {
			todayEle.style.display = 'none';
		}
	}

	async setCurrentIndex(index: { text: string; value: number }) {
		if (!index) {
			return;
		}
		this.currUpdateIndex = index.value;
		const updates = this.scheduleStorage.$allUpdates.getValue();
		const prevUpdate = updates[this.currUpdateIndex - 1];
		const currentUpdate = updates[this.currUpdateIndex];
		this.currentUpdate = currentUpdate;
		this.prevUpdate = prevUpdate;
		if (!currentUpdate) {
			return;
		}
		const currTaskArr =
			(await this.scheduleStorage.grabUpdateTable<XerActivity>(updates[this.currUpdateIndex]._id, 'TASK')) || [];
		const currTasksByCode = new Map<string, XerActivity>([]);
		const currTasksById = new Map<number, XerActivity>([]);
		for (const task of currTaskArr) {
			currTasksById.set(task.task_id, task);
			currTasksByCode.set(task.task_code, task);
		}
		this.currTasks = currTasksById;
		const prevTaskArr =
			(await this.scheduleStorage.grabUpdateTable<XerActivity>(updates[this.currUpdateIndex - 1]._id, 'TASK')) || [];
		const prevTasksByCode = new Map<string, XerActivity>([]);
		const prevTasksById = new Map<number, XerActivity>([]);
		for (const task of prevTaskArr) {
			prevTasksById.set(task.task_id, task);
			prevTasksByCode.set(task.task_code, task);
		}
		this.prevTasks = prevTasksByCode;
		const currentPreds = await this.scheduleStorage.grabUpdateTable<XerTaskPredecessor>(
			updates[this.currUpdateIndex]._id,
			'TASKPRED'
		);
		const prevPreds = await this.scheduleStorage.grabUpdateTable<XerTaskPredecessor>(
			updates[this.currUpdateIndex - 1]._id,
			'TASKPRED'
		);
		const currProjects = await this.scheduleStorage.grabUpdateTable<XerProject>(
			updates[this.currUpdateIndex]._id,
			'PROJECT'
		);

		const predsByTaskId: Record<number, XerTaskPredecessor[]> = {};
		const predsByTaskCode = new Map<string, XerTaskPredecessor[]>([]);
		const succsByTaskCode = new Map<string, XerTaskPredecessor[]>([]);
		const succsByTaskId: Record<number, XerTaskPredecessor[]> = {};
		for (const pred of currentPreds) {
			const succActv = currTasksById.get(pred.task_id);
			const predActv = currTasksById.get(pred.pred_task_id);
			if (!succActv || !predActv) {
				continue;
			}
			const existing = predsByTaskId[pred.task_id] || [];
			existing.push(pred);
			predsByTaskId[pred.task_id] = existing;

			predsByTaskCode.set(succActv.task_code, existing);

			const existingSucc = succsByTaskId[pred.pred_task_id] || [];
			existingSucc.push(pred);
			succsByTaskId[pred.pred_task_id] = existingSucc;
			succsByTaskCode.set(predActv.task_code, existingSucc);
		}
		const prevPredsById: Record<number, XerTaskPredecessor[]> = {};
		for (const pred of prevPreds) {
			const existing = prevPredsById[pred.task_id] || [];
			existing.push(pred);
			prevPredsById[pred.task_id] = existing;
		}
		const sortedActvs = sortActivities(currTasksByCode, currTasksById, succsByTaskCode, predsByTaskCode);
		const allCals = await this.scheduleStorage.grabCalendar(updates[this.currUpdateIndex]._id);
		this.allItems = sortedActvs.map((actv): CustomGanttItem => {
			const dataDate: Date = new Date(currentUpdate.dataDate);
			const start: Date = startOfDay(new Date(actv?.act_start_date || actv?.early_start_date));
			const end: Date = startOfDay(new Date(actv?.act_end_date || actv?.early_end_date));
			let completionRatio: number = 0;
			if (isAfter(dataDate, start)) {
				const durationUntilDataDate: number = differenceInCalendarDays(dataDate, start);
				const taskDuration: number = differenceInCalendarDays(end, start);
				completionRatio = durationUntilDataDate / (taskDuration || 1);
				completionRatio = completionRatio > 1 ? 1 : completionRatio;
			}
			const prevActv = prevTasksByCode.get(actv.task_code);
			const actvCalendar = this.scheduleStorage.cachedCalendars
				.get(updates[this.currUpdateIndex]._id)
				.get(actv.clndr_id);

			let progressDelay = 0;
			const lastRecalcDate = currProjects.find((p) => p.proj_id === actv.proj_id)?.last_recalc_date;
			if (prevActv && !prevActv.act_end_date && actvCalendar && lastRecalcDate) {
				const actualDuration =
					actv.target_drtn_hr_cnt -
					actv.remain_drtn_hr_cnt -
					(prevActv.target_drtn_hr_cnt - prevActv.remain_drtn_hr_cnt);
				const expectedStart = min([prevActv.restart_date || prevActv.early_start_date, lastRecalcDate]);
				const expectedEnd = min([prevActv.reend_date || prevActv.late_end_date, lastRecalcDate]);
				const expectedDuration =
					actvCalendar.durationMinutes(expectedStart, expectedEnd) * (actvCalendar.calendar.day_hr_cnt || 8);
				progressDelay = expectedDuration - actualDuration;
			}
			const isNew = !prevActv;
			const preds = predsByTaskId[actv.task_id] || [];
			const prevPredsOfTask = prevPredsById[prevActv?.task_id] || [];
			const changedLogic =
				!isNew &&
				preds.some((currPred) => {
					const prevPred = prevPredsOfTask.find(
						(pastPred) =>
							currTasksById.get(currPred.pred_task_id)?.task_code ===
							prevTasksById.get(pastPred.pred_task_id)?.task_code
					);
					return !prevPred || prevPred.pred_type !== currPred.pred_type;
				});
			return {
				id: actv.task_code,
				name: actv.task_name,
				title: `${actv.task_code}`,
				start: new Date(actv.act_start_date || actv.early_start_date || actv.target_start_date).getTime() / 1000,
				end: new Date(actv.act_end_date || actv.early_end_date || actv.target_end_date).getTime() / 1000,
				progress: completionRatio,
				links: (succsByTaskId[actv.task_id] || []).map((succ) => ({
					type: predTypeToGanttLinkType(succ.pred_type),
					link: currTasksById.get(succ.task_id).task_code,
					// TODO: implement driving check here ~ TW | color: drivingsucc ? 'red' : 'lightgray',
				})),
				color: !actv.act_end_date && actv.driving_path_flag === 'Y' ? '#DF5353FF' : 'rgba(52, 143, 228, 0.3)',
				tag: !prevActv ? 'NEW' : null,
				isCritical: !actv.act_end_date && actv.driving_path_flag === 'Y',
				isNew,
				changedDuration: prevActv && prevActv.target_drtn_hr_cnt !== actv.target_drtn_hr_cnt,
				changedConstraint: prevActv && new Date(prevActv.cstr_date)?.getTime() !== new Date(actv.cstr_date)?.getTime(),
				progressDelay,
				changedLogic,
			};
		});
		this.actvFilterCounts = {
			Additions: [0, 0],
			Duration: [0, 0],
			Progress: [0, 0],
			Constraint: [0, 0],
			Logic: [0, 0],
		};
		this.criticalItemsCount = 0;
		for (const item of this.allItems) {
			const actvPrev = prevTasksByCode.get(item.id);
			if (actvPrev?.act_end_date) {
				continue;
			}
			if (item.isCritical) {
				this.criticalItemsCount++;
			}
			if (item.isNew) {
				this.actvFilterCounts.Additions[0]++;
				if (item.isCritical) {
					this.actvFilterCounts.Additions[1]++;
				}
			}
			if (item.changedDuration) {
				this.actvFilterCounts.Duration[0]++;
				if (item.isCritical) {
					this.actvFilterCounts.Duration[1]++;
				}
			}
			if (item.changedConstraint) {
				this.actvFilterCounts.Constraint[0]++;
				if (item.isCritical) {
					this.actvFilterCounts.Constraint[1]++;
				}
			}
			if (item.progressDelay) {
				this.actvFilterCounts.Progress[0]++;
				if (item.isCritical) {
					this.actvFilterCounts.Progress[1]++;
				}
			}
			if (item.changedLogic) {
				this.actvFilterCounts.Logic[0]++;
				if (item.isCritical) {
					this.actvFilterCounts.Logic[1]++;
				}
			}
		}
		const criticalBtn = this.taskTypeButtons.find((btn) => btn.text === 'Critical');
		const allBtn = this.taskTypeButtons.find((btn) => btn.text === 'Show All');
		criticalBtn.disabled = this.criticalItemsCount === 0;
		if (this.criticalItemsCount === 0) {
			criticalBtn.selected = false;
			allBtn.selected = true;
			this.updateView(false, this.currentView);
		}
		this.baselineItems = Array.from(prevTasksByCode.values()).map(
			(actv): GanttBaselineItem => ({
				id: actv.task_code,
				start: new Date(actv.act_start_date || actv.early_start_date || actv.target_start_date).getTime() / 1000,
				end: new Date(actv.act_end_date || actv.early_end_date || actv.target_end_date).getTime() / 1000,
			})
		);

		this.updateView(this.isCritical, this.currentView);
	}

	updateSearch(term: string) {
		this.updateView(this.isCritical, this.currentView, true);
	}

	/**
	 * buttongroup selection change handler
	 * @param selected
	 * @param btn
	 * @param isRelationshipsBtnGroup
	 */
	public selectionChange(selected: boolean, btn: IButton, isRelationshipsBtnGroup: boolean = false): void {
		btn.selected = selected;
		if (isRelationshipsBtnGroup && btn.text === 'Show') {
			this.showLines = btn.selected;
		}
		this.updateView(isRelationshipsBtnGroup ? this.isCritical : btn.text === 'Critical', this.currentView);
	}

	updateView(isCritical: boolean, view: string, triggeredBySearch = false) {
		this.currentView = view;
		this.isCritical = isCritical;
		this.items = this.allItems
			.filter((item: CustomGanttItem) => {
				const actvPrev = this.prevTasks.get(item.id);
				if (actvPrev?.act_end_date) {
					return false;
				}
				if (this.isCritical && !item.isCritical) {
					return false;
				}
				if (
					this.searchTerm !== '' &&
					!item.name.toLowerCase().includes(this.searchTerm.toLowerCase()) &&
					!item.id.toLowerCase().includes(this.searchTerm.toLowerCase())
				) {
					return false;
				}
				switch (view) {
					case 'Additions':
						return item.isNew;
					case 'Duration':
						return item.changedDuration;
					case 'Constraint':
						return item.changedConstraint;
					case 'Logic':
						return item.changedLogic;
					case 'Progress':
						if (!actvPrev || actvPrev.act_end_date) {
							return false;
						}
						return item.progressDelay !== 0;
					default:
						return true;
				}
			})
			.map(
				(item): CustomGanttItem => ({
					...item,
					links: this.showLines ? item.links : [],
				})
			);

		if (this.items.length) {
			let minStart = this.items[0].start;
			let maxEnd = this.items[this.items.length - 1].end;
			for (const item of this.items) {
				if (item.start < minStart) {
					minStart = item.start;
				}
				if (item.end > maxEnd) {
					maxEnd = item.end;
				}
			}
			this.start = minStart;
			this.end = maxEnd;
		}

		this.loading = true;
		setTimeout(() => {
			this.loading = false;
			setTimeout(() => {
				if (this.items[0]?.end) {
					this.ganttComponent?.scrollToDate(this.items[0]?.end);
				}
				if (triggeredBySearch) {
					this.searchBar.focus();
				}
				this.setTodayPoint();

				//this.drawDataDateLine(new Date(this.updates[this.updates.length - 1].dataDate));
			}, 500);
		}, 200);
	}

	public itemDisabled = (itemArgs: { dataItem: string; index: number }) =>
		itemArgs.dataItem !== 'Show All' && !this.actvFilterCounts?.[itemArgs.dataItem][this.isCritical ? 1 : 0];

	barClick(event: GanttBarClickEvent) {
		const e = event.event as PointerEvent;
		const element = e.target as HTMLElement;
		const previous = this.prevTasks.get(event.item.id);
		const current = Array.from(this.currTasks.values()).find((a) => a.task_code === event.item.id);
		this.tooltipActv = {
			current,
		};
		this.tooltipOpen = true;
	}

	closeTooltip() {
		this.tooltipOpen = false;
	}

	async selectTab(ev): Promise<void> {
		const currUpdateIndex = ev.index;
		const updates = this.scheduleStorage.$allUpdates.getValue();
		const currentUpdate = updates[currUpdateIndex];
		const currTasks = await this.scheduleStorage.grabUpdateTable<XerActivity>(currentUpdate._id, 'TASK');
		const current = currTasks.find((a) => a.task_code === this.tooltipActv.current.task_code);
		if (current) {
			this.tooltipActv = {
				current,
			};
		}

		if (!this.tasksByUpdate.has(currentUpdate._id)) {
			this.tasksByUpdate.set(currentUpdate._id, new Set<string>(currTasks.map((a) => a.task_code)));
		}
	}

	protected readonly searchIcon = searchIcon;
}

const predTypeToGanttLinkType = (predType: string): GanttLinkType => {
	switch (predType) {
		case 'PR_SS':
			return GanttLinkType.ss;
		case 'PR_FF':
			return GanttLinkType.ff;
		case 'PR_FS':
			return GanttLinkType.fs;
		case 'PR_SF':
			return GanttLinkType.sf;
	}
	return GanttLinkType.fs;
};
