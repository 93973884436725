import { DeepRequired, IterableToCount, MapSetToArray } from './helpers';
import { SelectedActivityCodeInterface } from './update';
import { XerActivity, XerActivityCode, XerActivityType, XerCalendar, XerTaskPredecessor } from '@rhinoworks/xer-parse';
import { ActivityInfo, PredecessorInfo } from './activity';
import { Activity } from '@rhinoworks/xer-parse';

export type ProjectTable = {
	projectId: string;
	projectName: string;
	contractCompletion?: Date;
	currentCompletion?: string;
	contractVariance?: number;
	previousVariance?: number;
	projectScore?: number;
	riskScore?: number;
	reliabilityScore?: number;
	generalNote?: string;
	criticalPathNote?: string;
	timeAnalysisNote?: string;
	lastUploadedDate?: string;
	sharePoint?: string;
};

export type ProjectMap = {
	id: string;
	projectName?: string;
	projectType?: string;
	projectLat?: string;
	projectLng?: string;
	varianceRatio?: number;
	pinColor?: string;
	sharePoint?: string;
};

export type CompletionVarianceGraph = {
	projectName?: string;
	scheduleNameArray?: string[];
	contractVarianceArray?: number[];
	dataDateArray?: string[];
};

export type ProjectOverview = {
	scheduleName?: string;
	dataDate: string | Date;
	currentCompletion: Date | string;
	contractCompletion: string;
	previousCompletion: Date | string;
	contractVariance?: number;
	previousVariance: number;
	aegisPOCName?: string;
	aegisPOCEmail?: string;
	aegisPOCPhone?: string;
};

export type CalculationField = {
	CPLI: number;
	TFCI: number;
	BEI: number;
	CEI: number;
	NCCR: number;
	TDA: number;
	projectScore: number;
	riskScore: number;
	reliabilityScore: number;
	progressScore: number;
	predictabilityScore: number;
	qcScore: number;
	preMitigationAvgRiskScore: number;
	postMitigationAvgRiskScore: number;
};

export function getCalculationFieldVars(): CalculationField {
	return {
		CPLI: 0,
		TFCI: 0,
		BEI: 0,
		CEI: 0,
		NCCR: 0,
		TDA: 0,
		projectScore: 0,
		riskScore: 0,
		reliabilityScore: 0,
		progressScore: 0,
		predictabilityScore: 0,
		qcScore: 0,
		preMitigationAvgRiskScore: 0,
		postMitigationAvgRiskScore: 0,
	};
}

export interface ProjectCompletionTrendItem {
	scheduleName?: string;
	dataDate?: string;
	contractVariance?: number;
	previousVariance?: number;
	criticalPathNote?: string;
	timeAnalysisNote?: string;
	currentCompletion?: string;
	contractCompletion?: string;
}

export type MilestoneCompletionTrendItem = {
	scheduleName?: string;
	dataDate?: string;
	contractVariance?: number;
	previousVariance?: number;
	criticalPathNote?: string;
	timeAnalysisNote?: string;
};

export interface ProjectCompletionTrend {
	graphTitle?: string;
	projectCompletionTrendArray: ProjectCompletionTrendItem[];
}

export type MileStoneCompletionTrend = {
	mileStoneTaskName?: string;
	mileStoneTaskCode?: string;
	milestoneCompletionTrendArray: MilestoneCompletionTrendItem[];
};

export type CriticalActivity = {
	activityId: string;
	activityName: string;
	activityType: string;
	criticalStart?: Date;
	criticalFinish?: Date;
};

export type Milestone = {
	activityId?: string;
	activityName?: string;
	currentCompletion?: string;
	previousCompletion?: string;
	baselineCompletion?: string;
	contractCompletion?: string;
	previousVariance?: number;
	baselineVariance?: number;
	contractVariance?: number;
	status_code?: string;
};

export type CriticalLookahead = {
	criticalActivityArray?: CriticalActivity[];
};

export type Milestones = {
	milestoneArray?: Milestone[];
};

export type LastPeriodItem = {
	countCriticalPlanned: number;
	countCriticalCompleted: number;
	criticalPercentComplete: number;
	countNearCriticalPlanned: number;
	countNearCriticalPlannedByNext: number;
	countNearCriticalCompletedByNext: number;
	countNearCriticalCompleted: number;
	nearCriticalPercentComplete: number;
	countNonCriticalPlanned: number;
	countNonCriticalCompleted: number;
	nonCriticalPercentComplete: number;
	countTotalPlanned: number;
	countTotalCompleted: number;
	totalPercentComplete: number;
};

export function getLastPeriodItemVars(): LastPeriodItem {
	return {
		countCriticalPlanned: 0,
		countCriticalCompleted: 0,
		criticalPercentComplete: 0,
		countNearCriticalPlanned: 0,
		countNearCriticalPlannedByNext: 0,
		countNearCriticalCompletedByNext: 0,
		countNearCriticalCompleted: 0,
		nearCriticalPercentComplete: 0,
		countNonCriticalPlanned: 0,
		countNonCriticalCompleted: 0,
		nonCriticalPercentComplete: 0,
		countTotalPlanned: 0,
		countTotalCompleted: 0,
		totalPercentComplete: 0,
	};
}

export type ProjectTrend = {
	criticalCompletionPercentageArray?: number[];
	nearCriticalCompletionPercentageArray?: number[];
	nonCriticalCompletionPercentageArray?: number[];
	totalCompletionPercentageArray?: number[];
	updateArray?: string[];
};

export function getProjectTrendVars(): Required<ProjectTrend> {
	return {
		criticalCompletionPercentageArray: [],
		nearCriticalCompletionPercentageArray: [],
		nonCriticalCompletionPercentageArray: [],
		totalCompletionPercentageArray: [],
		updateArray: [],
	};
}

export type ActivityCompletion = {
	lastPeriodGraph: LastPeriodItem;
	projectTrendGraph: ProjectTrend;
};

export function getActivityCompletionVars(): DeepRequired<
	ActivityCompletion,
	['lastPeriodGraph'] | ['projectTrendGraph']
> {
	return {
		lastPeriodGraph: getLastPeriodItemVars(),
		projectTrendGraph: getProjectTrendVars(),
	};
}

export type QCComponentRelationshipTypes = {
	totalRelationships?: number;
	finishToStart?: number;
	startToFinish?: number;
	startToStart?: number;
	finishToFinish?: number;
};

export type QCComponent = {
	finishToStartWithLags?: number;
	negativeLags?: number;
	missingPredecessorRelationships?: number;
	missingSuccessorRelationships?: number;
	highDuration?: number;
	highFloat?: number;
	relationshipTypes?: QCComponentRelationshipTypes;
	actualsPastTheDataDate?: number;
	hardConstraints?: number;
	softConstraints?: number;
	startToStartRelationshipsWithProblematicLags?: number;
	finishToFinishRelationshipsWithProblematicLags?: number;
	startToStartRelationshipsWithNoCorrespondingFinishToFinish?: number;
};

export function getQCComponentVars(): DeepRequired<QCComponent, ['relationshipTypes']> {
	return {
		finishToStartWithLags: 0,
		negativeLags: 0,
		missingPredecessorRelationships: 0,
		missingSuccessorRelationships: 0,
		highDuration: 0,
		highFloat: 0,
		relationshipTypes: {
			totalRelationships: 0,
			finishToStart: 0,
			startToFinish: 0,
			startToStart: 0,
			finishToFinish: 0,
		},
		actualsPastTheDataDate: 0,
		hardConstraints: 0,
		softConstraints: 0,
		startToStartRelationshipsWithProblematicLags: 0,
		finishToFinishRelationshipsWithProblematicLags: 0,
		startToStartRelationshipsWithNoCorrespondingFinishToFinish: 0,
	};
}

export type ReliabilityComponents = {
	changedActualDates: number | undefined;
	activitiesActualizedOutsideOfUpdatePeriod: number | undefined;
	addedActivitiesToAccountForDelays: number | undefined;
};

export type QualityOfLookahead = {
	numberOfActivitiesInLookahead: number;
	finishToStartWithLags: number;
	negativeLags: number;
	highDuration: number;
	highFloat: number;
	constraints: number;
	hardConstraints: number;
	softConstraints: number;
	startToStartRelationshipsWithProblematicLags: number;
	finishToFinishRelationshipsWithProblematicLags: number;
	startToStartRelationshipsWithNoCorrespondingFinishToFinish: number;
	outOfSequenceActivities: number;
	predecessorDanglesQC: number;
	successorDanglesQC: number;
};

export type QualityControlScore = {
	numberOfTotal: number;
	finishToStartWithLags: number;
	negativeLags: number;
	highDuration: number;
	highFloat: number;
	hardConstraints: number;
	softConstraints: number;
	startToStartRelationshipsWithProblematicLags: number;
	finishToFinishRelationshipsWithProblematicLags: number;
	startToStartRelationshipsWithNoCorrespondingFinishToFinish: number;
	outOfSequenceActivities: number;
	predecessorDanglesQC: number;
	successorDanglesQC: number;
};

export type RiskComponents = {
	currentCriticalPathLogicRevisions: number;
	cutDurationOnCurrentCriticalPath: number;
	previousCriticalPathLogicRevisions: number;
	cutDurationOnCurrentCriticalPathFromThePreviousCriticalPath: number;
};

export type CompletionRatios = {
	criticalCompletionRatio: number | undefined;
	nearCriticalCompletionRatio: number | undefined;
	nonCriticalCompletionRatio: number | undefined;
};

export type TargetDurationAchievement = {
	criticalTDA: number | undefined;
};

export type QualityControl = {
	totalActivities: number;
	totalRelationships: number;
	numberOfRelationshipsToOneActivity: number;
	totalOutOfSequenceActivities: number;
	percentOutOfSequenceActivities: number;
	totalLogicChanges: number;
	currentUpdateLogicChanges: number;
	totalTaskActivities: number;
	totalMilestoneActivities: number;
	totalLOES: number;
	percentFS: number;
	percentSS: number;
	percentFF: number;
	percentSF: number;
	missingPredecessorsSuccessors: number;
	negativeLags: number;
	ssFfProblematic: number;
	fsLags: number;
	highDurationActivities: number;
	highFloatActivities: number;
	actualsPastDataDate: number;
	hardConstraints: number;
	softConstraints: number;
	qcScore: number;
	qcTrending: number[];
	problematicRelationshipsTrending: number[];
	numActualsNoProgressTrending: number[];
	oosTrending: number[];
	actualsPastDataDateTrending: number[];
	noChangeInRemainingTrending: number[];
	numCriticalTrending: number[];
	ssWithoutFFTrending: number[];
	fsWithLagTrending: number[];
	numNoPredSuccTrending: number[];
	logicDensityHistorical: number[];
	retainedLogicHistorical: boolean[];
	countCriticalPlannedTrending: number[];
	countNearCriticalPlannedTrending: number[];
	countNonCriticalPlannedTrending: number[];
	countTotalPlannedTrending: number[];
	ssFfProblematicTrending: number[];
	softConstraintsTrending: number[];
	fsLagPenalty: number;
	missingPredSuccPenalty: number;
	oosCriticalPenalty: number;
	oosNearCriticalPenalty: number;
	oosNonCriticalPenalty: number;
	negLagPenalty: number;
	hardConstraintPenalty: number;
	relationshipDensityPenalty: number;
	durationPenalty: number;
	floatPenalty: number;
	ssffProblematicPenalty: number;
	openStartFinishPenalty: number;
	sfPenalty: number;
};

export type CriticalPathRisk = {
	overallScore: number;
	overallScoreColor: string;
	firstPathTF: number;
	firstPathColor: string;
	secondPathTF: number;
	secondPathColor: string;
	thirdPathTF: number;
	thirdPathColor: string;
	cprTrends: CprTrends;
};

export type CprTrends = {
	firstPathTrend: number[];
	secondPathTrend: number[];
	thirdPathTrend: number[];
	scoreText: string[];
};

export type CriticalPathReliability = {
	overallScore: number;
	overallScoreColor: string;
	overallScoreTrend: Array<{
		activityConsistencyScore: number;
		baseLogicScore: number;
		updateLogicScore: number;
		overallScore: number;
	}>;
	activityConsistencyScore: number;
	baseLogicScore: number;
	updateLogicScore: number;
};

export type PfTable = {
	activityCode: string;
	description: string;
	pf: number;
};

export type Hits = {
	unixDate: number;
	hits: number;
};

export type Probability = {
	unixDate: number;
	prob: number;
};

export type P85HistoricalTrend = {
	dataDate?: string;
	previousVariance?: number;
	projectedDate?: string;
	projectedDateVariance?: number; //days +/- from ccd
	p85Date?: string;
	p85DateVariance?: number; //days +/- from ccd
};

export type PerformanceFactor = {
	pfTable: PfTable[];
	hits: Hits[];
	prob: Probability[];
	overallScoreColor: string;
	overallScore: number;
	selectedActivityCodes: SelectedActivityCodeInterface[] | number[];
	p85HistoricalTrend: P85HistoricalTrend[];
};

export type RiskPage = {
	overallScore: number;
	criticalPathRisk: CriticalPathRisk;
	criticalPathReliability: CriticalPathReliability;
	performanceFactor: PerformanceFactor;
};

export type RiskMitigationHistorical = {
	updateId: string;
	riskId: number;
	preMitigationDateImpact: number;
	preMitigationHits: Hits[];
	postMitigationDateImpact: number;
	postMitigationHits: Hits[];
};

export type FloatStats = {
	negativeFloat: number;
	criticalPathFloat: number;
	nearCriticalPathFloat: number;
	monthFloat: number;
	largeFloat: number;
	total: number;
};

export interface FloatIndex {
	periodDelta: {
		floatNegative: number;
		floatNone: number;
		floatWeek: number;
		floatMonth: number;
		floatLarge: number;
		floatAverage: number;
	};
	baselineDelta: {
		floatNegative: number;
		floatNone: number;
		floatWeek: number;
		floatMonth: number;
		floatLarge: number;
		floatAverage: number;
	};
}

export interface TotalFloatIndexArgs {
	floatNegative: number;
	floatNone: number;
	floatWeek: number;
	floatMonth: number;
	floatLarge: number;
	floatAverage: number;
}

export type FloatHistoricalItem = {
	updateId: string;
} & TotalFloatIndexArgs;

export type ExpandedMetricActivity = XerActivity & { actvCodeIds?: number[]; actvCodeTypeIds?: number[] };

export type ExpandedMetricBaselineTarget = {
	code: string;
	start?: Date;
	finish?: Date;
};

export type ExpandedMetrics = {
	updateId: string;
	version: string;
	totalActivities: ExpandedMetricActivity[];
	totalRelationships: XerTaskPredecessor[];
	activityCodes: XerActivityCode[];
	activityTypes: XerActivityType[];
	milestones: XerActivity[];
	loes: XerActivity[];
	fs: XerTaskPredecessor[];
	ss: XerTaskPredecessor[];
	ff: XerTaskPredecessor[];
	sf: XerTaskPredecessor[];
	outOfSequence: XerTaskPredecessor[];
	missingPredecessors: XerActivity[];
	missingSuccessors: XerActivity[];
	negativeLags: XerTaskPredecessor[];
	ssFFProblematicLags: XerTaskPredecessor[];
	fsProblematicLags: XerTaskPredecessor[];
	highDurationActivities: XerActivity[];
	highFloatActivities: XerActivity[];
	actualsPastDataDate: XerActivity[];
	hardConstraints: XerActivity[];
	softConstraints: XerActivity[];
	calendars: XerCalendar[];
	baselineTargets: ExpandedMetricBaselineTarget[];
};

export function getExpandedMetrics(): Required<ExpandedMetrics> {
	return {
		updateId: '',
		version: '',
		totalActivities: [],
		activityCodes: [],
		activityTypes: [],
		totalRelationships: [],
		milestones: [],
		loes: [],
		fs: [],
		ss: [],
		ff: [],
		sf: [],
		outOfSequence: [],
		missingPredecessors: [],
		missingSuccessors: [],
		negativeLags: [],
		ssFFProblematicLags: [],
		fsProblematicLags: [],
		highDurationActivities: [],
		highFloatActivities: [],
		actualsPastDataDate: [],
		hardConstraints: [],
		softConstraints: [],
		calendars: [],
		baselineTargets: [],
	};
}

export function getExpandedMetricsCounts(): IterableToCount<ExpandedMetrics> {
	return {
		updateId: 0,
		version: 0,
		totalActivities: 0,
		totalRelationships: 0,
		activityCodes: 0,
		activityTypes: 0,
		milestones: 0,
		loes: 0,
		fs: 0,
		ss: 0,
		ff: 0,
		sf: 0,
		outOfSequence: 0,
		missingPredecessors: 0,
		missingSuccessors: 0,
		negativeLags: 0,
		ssFFProblematicLags: 0,
		fsProblematicLags: 0,
		highDurationActivities: 0,
		highFloatActivities: 0,
		actualsPastDataDate: 0,
		hardConstraints: 0,
		softConstraints: 0,
		calendars: 0,
		baselineTargets: 0,
	};
}

export type DCMARelationship = [string, string, string];

export type DCMAMetricVars = {
	incompleteMissingPredSucc: Set<string>;
	negativeLags: Set<DCMARelationship>;
	positiveLags: Set<DCMARelationship>;
	nonFSRelationships: Set<DCMARelationship>;
	hardConstraints: Set<string>;
	highFloat: Set<string>;
	negativeFloat: Set<string>;
	highDuration: Set<string>;
	invalidDates: Set<string>;
	incompleteMissingResources: Set<string>;
	slippedTasks: Set<string>;
	incompleteWithLag: Set<string>;
	numIncompleteTasks: number;
	numIncompleteRelationships: number;
	passesCriticalPathTest: boolean;
	cpli: number;
	bei: number;
};

export type HistoricalValue<MetricType> = Array<
	MetricType extends Iterable<DCMARelationship | string> ? number : MetricType
>;

export interface DCMAMetrics {
	historicalCounts: { [Property in keyof DCMAMetricVars]: HistoricalValue<DCMAMetricVars[Property]> };
	currentMetrics: { [Property in keyof DCMAMetricVars]: MapSetToArray<DCMAMetricVars[Property]> };
}

export type SingleUpdateStats = {
	dataDate: number;
	finishMilestoneCode?: string;
	startMilestoneCode?: string;
	trackedMilestones: Set<string>;
	contractCompletion?: string;
	currentCompletion?: string;
	contractVariance: number;
	activityCodes: Array<XerActivity['task_code']>;
	predecessors: Map<string, PredecessorInfo>;
	drivingPredecessors: Map<string, PredecessorInfo>;
	activityStats: { [code: string]: Partial<ActivityInfo> };
	passesCriticalPathTest: boolean;
	floats: TotalFloatIndexArgs;
	updateId: string;
	selectedProjectId?: number;
	retainedLogic: boolean;
};
