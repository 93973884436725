import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SlimmedTaskCommon, UpdateInterface } from '../../../../models/Update/Task';
import { ExcelExportEvent, GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query/dist/npm/sort-descriptor';
import { ProfileCompanyPermission } from '../../../../models/auth/account-user';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { NavigationBarStorageService } from '../../../../services/common/navigation-bar-storage.service';
import { formatExcelExport } from '../../../../util/excel';
import { cleanDateUTC } from '../../../../util/pipes/date.pipe';
import { IButton } from '../../../../models/Project';
import { differenceInCalendarDays, startOfDay } from 'date-fns';
import { caretAltDownIcon } from '@progress/kendo-svg-icons';
import {
	ActvCodeFilterItem,
	SubCodeFilterItem,
} from '../../overview/activity-completion/activity-completion.component';
import { CostService } from '../../../../services/common/cost.service';
import { XerActivity, XerActivityCode, XerActivityType, XerData, XerTaskResource } from '@rhinoworks/xer-parse';
import { dataFromXer } from '../../../../util/tasks';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';

export const allColumns = require('./activity-costs-columns.json') as {
	columns: ActivityCostsColumns[];
};

function union<T>(array1: T[], array2: T[]): T[] {
	return [...new Set([...array1, ...array2])];
}

interface ActivityCostsGridItem {
	activityCodes: string[];
	activityId: string;
	activityName: string;
	start: Date;
	startIsAct: boolean;
	finish: Date;
	finishIsAct: boolean;
	baselineCost: number;
	costAtCompletion: number;
	actualCost: number;
	remainingCost: number;
	costPercentComplete: number;
	activityCodesString: string;
}

interface ActivityCostsColumns {
	field: string;
	title: string;
	width: number;
	type: string;
	hidden: boolean;
}

@Component({
	selector: 'app-activity-costs',
	templateUrl: './activity-costs.component.html',
	styleUrls: ['./activity-costs.component.scss'],
})
export class ActivityCostsComponent implements OnInit {
	@Input() isOverview: boolean = false;
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	latestUpdateData: ActivityCostsGridItem[] = [];
	@ViewChild(GridComponent)
	public grid: GridComponent;
	public gridView: GridDataResult;
	gridData: Array<any> = [];
	allSubCodes;
	loading: boolean = true;
	public sort: SortDescriptor[] = [
		{
			dir: 'asc',
			field: 'finish',
		},
	];
	public pageSize: number = 100;
	public skip: number = 0;
	selectedColumns: ActivityCostsColumns[] = [];
	currentProjectCompanyPermissions: ProfileCompanyPermission = null;
	public forwardOrBackwardButtons: IButton[] = [
		{
			text: 'All',
			value: null,
			selected: true,
		},
		{
			text: 'Actual',
			value: -1,
		},
		{
			text: 'Planned',
			value: 1,
		},
	];
	selectedStatus: string = 'All';
	selectedTimespan: number = 30;
	dataDate: Date = null;
	public timespanButtons: IButton[] = [
		{
			text: '30',
			value: 30,
			selected: true,
		},
		{
			text: '60',
			value: 60,
		},
		{
			text: '90',
			value: 90,
		},
	];
	icons = {
		caretDown: caretAltDownIcon,
	};
	allActivityCodes: ActvCodeFilterItem[] = [];
	public selectedActivityCodes: any[] = [];
	codesTag: string = '';

	constructor(
		private scheduleService: ScheduleStorageService,
		public projectService: ProjectDashboardService,
		public navBarStorage: NavigationBarStorageService,
		public costService: CostService
	) {
		this.allExportData = this.allExportData.bind(this);
	}

	ngOnInit() {
		this.scheduleService.$allUpdates.pipe(takeUntil(this._unsubscribeAll)).subscribe((updates: UpdateInterface[]) => {
			if (updates?.length > 0 && this.projectService.$currentProjectReport.value) {
				this.updateCostsGrid(updates);
			}
		});
		this.projectService.$currentProjectReport
			.pipe(takeUntil(this._unsubscribeAll), debounceTime(500))
			.subscribe((report) => {
				if (report?.project?.company) {
					this.currentProjectCompanyPermissions = this.navBarStorage.companyPermissionMap.get(report?.project?.company);
				}
				if (report && this.scheduleService.$allUpdates.value.length > 0) {
					this.updateCostsGrid(this.scheduleService.$allUpdates.value);
				}
			});
	}

	async updateCostsGrid(updates: UpdateInterface[]): Promise<void> {
		this.loading = true;
		this.selectedColumns = allColumns.columns;
		this.latestUpdateData = [];
		const latestUpdateData: ActivityCostsGridItem[] = [];
		const latestUpdate: UpdateInterface = updates[updates?.length - 1];
		const latestUpdateXerData = await this.scheduleService.grabUpdateXer(latestUpdate._id);
		this.allActivityCodes = this.generateActivityCodeFilter(latestUpdateXerData);
		const allSubCodes = [];
		this.allActivityCodes.forEach((code) => {
			code.subCodes.forEach((subCode) => {
				const subcodeColumn = {
					title: subCode.name,
					shortName: subCode.shortName,
				};

				allSubCodes.push(subcodeColumn);
			});
		});
		this.allSubCodes = allSubCodes;
		const tasks = await this.scheduleService.grabUpdateTable<XerActivity>(latestUpdate._id, 'TASK');
		const taskResources = await this.scheduleService.grabUpdateTable<XerTaskResource>(latestUpdate._id, 'TASKRSRC');
		const activitiesWithCosts = tasks.filter((t) =>
			taskResources.some(
				(r) => (r.task_id === t.task_id && (r.act_reg_cost || 0) + (r.act_ot_cost || 0)) || r.target_cost
			)
		);
		const actvCodes = await this.scheduleService.grabUpdateTable<XerActivityCode>(latestUpdate._id, 'ACTVCODE');
		const currTaskActv = await this.scheduleService.grabUpdateTable<{
			task_id: number;
			actv_code_type_id: number;
			actv_code_id: number;
			proj_id: number;
		}>(latestUpdate._id, 'TASKACTV');
		const actvsByTask: Record<
			number,
			Array<{
				task_id: number;
				actv_code_type_id: number;
				actv_code_id: number;
				proj_id: number;
			}>
		> = {};
		for (const ta of currTaskActv) {
			if (!actvsByTask[ta.task_id]) {
				actvsByTask[ta.task_id] = [];
			}
			actvsByTask[ta.task_id].push(ta);
		}
		const indexOfCostBaseline: number = this.costService.determineBaselineIndex(
			this.projectService.$currentProjectReport.value?.cashFlowHistorical
		);
		const costBaseline: UpdateInterface = updates[indexOfCostBaseline]; //todo: update this once cost baseline editing becomes a thing - RS
		const baselineTasks = await this.scheduleService.grabUpdateTable<XerActivity>(costBaseline._id, 'TASK');
		const baselineTaskResources = await this.scheduleService.grabUpdateTable<XerTaskResource>(
			costBaseline._id,
			'TASKRSRC'
		);

		activitiesWithCosts.forEach((activity) => {
			const shortNames =
				actvCodes
					.filter((ac) => actvsByTask[activity.task_id]?.some((ta) => ta.actv_code_id === ac.actv_code_id))
					.map((t) => t.short_name) || [];
			const costBaselineMatchingActv = baselineTasks.find((t) => t?.task_code === activity?.task_code);
			const baselineTaskRsrc = baselineTaskResources.find((r) => r.task_id === costBaselineMatchingActv?.task_id);
			const currTaskRsrc = taskResources.find((r) => r.task_id === activity.task_id);
			const actCost = +(currTaskRsrc?.act_reg_cost || 0) + +(currTaskRsrc?.act_ot_cost || 0);

			const targetCost = +(currTaskRsrc?.target_cost || 0);
			if (!actCost && !targetCost) {
				return;
			}
			const activityData: ActivityCostsGridItem = {
				activityCodes: shortNames,
				activityId: activity?.task_code,
				activityName: activity?.task_name,
				start: startOfDay(new Date(activity?.act_start_date || activity?.early_start_date)),
				startIsAct: activity?.act_start_date !== undefined,
				finish: startOfDay(new Date(activity?.act_end_date || activity?.early_end_date)),
				finishIsAct: activity?.act_end_date !== undefined,
				baselineCost: costBaselineMatchingActv === undefined ? null : +baselineTaskRsrc?.target_cost,
				costAtCompletion: targetCost,
				actualCost: actCost,
				remainingCost: targetCost - actCost,
				costPercentComplete: (100 * actCost) / (currTaskRsrc?.target_cost || 1),
				activityCodesString: shortNames?.join(', '),
			};
			latestUpdateData.push(activityData);
		});
		this.latestUpdateData = latestUpdateData;
		this.latestUpdateData.forEach((row) => {
			this.allSubCodes.forEach((code) => {
				row[`activityCode_${code.shortName}`] = row.activityCodes.includes(code.shortName) ? 'x' : '';
			});
		});
		this.dataDate = startOfDay(new Date(latestUpdate.dataDate));
		this.loadActivities();
	}

	public loadActivities(): void {
		const filteredDataset: ActivityCostsGridItem[] = this.latestUpdateData.filter((item: ActivityCostsGridItem) => {
			if (this.selectedStatus === 'Actual' && !item.finishIsAct) {
				return false;
			} else if (
				this.selectedStatus === 'Planned' &&
				(item.finishIsAct || differenceInCalendarDays(item.finish, this.dataDate) > this.selectedTimespan)
			) {
				return false;
			}
			if (this.selectedActivityCodes?.length > 0) {
				let doAnyCodeMatch: boolean = false;
				this.selectedActivityCodes.forEach((code) => {
					if (item.activityCodes.includes(code.shortName)) {
						doAnyCodeMatch = true;
					}
				});
				return doAnyCodeMatch;
			}
			return true;
		});

		if (this.selectedActivityCodes?.length <= 0) {
			let totalActivityCodes = [];
			filteredDataset.forEach((item: ActivityCostsGridItem) => {
				totalActivityCodes = union(item.activityCodes, totalActivityCodes);
			});

			this.allActivityCodes.forEach((code) => {
				let disabledCount = 0;
				code.subCodes.forEach((subCode) => {
					subCode.alwaysDisabled = false;
					if (!totalActivityCodes.includes(subCode.shortName)) {
						subCode.alwaysDisabled = true;
						disabledCount += 1;
					}
				});

				code.alwaysDisabled = disabledCount === code.subCodes.length;
			});
		}

		this.gridView = {
			data: filteredDataset?.slice(this.skip, this.skip + this.pageSize),
			total: filteredDataset?.length,
		};
		this.gridData = filteredDataset;
		this.loading = false;
	}

	reset() {
		this.skip = 0;
		this?.grid?.scrollTo({ row: 0, column: 0 });
	}

	public sortChange(sort: SortDescriptor[]): void {
		this.sort = sort;
		this.loadActivities();
	}

	public allExportData(): ExcelExportData {
		const result: ExcelExportData = {
			data: process(this.gridData, {
				sort: this.sort,
			}).data,
		};
		return result;
	}

	public exportToExcel(): void {
		this.grid.saveAsExcel();
	}

	onExcelExport(e: ExcelExportEvent): void {
		e.preventDefault();
		formatExcelExport(
			e,
			this.projectService?.$currentProjectData?.value?.name + ' Activity Cost Details.xlsx',
			'',
			'Activity Cost Details',
			true,
			'Aegis Project Controls\n' + cleanDateUTC(new Date(), 'MMMM d, yyyy'),
			'I1'
		);
	}

	/**
	 * buttongroup selection change handler
	 * @param selected
	 * @param btn
	 * @param isTimespan
	 */
	public selectionChange(selected: boolean, btn: IButton, isTimespan = false): void {
		btn.selected = selected;
		if (btn.selected) {
			if (isTimespan) {
				this.selectedTimespan = btn.value;
			} else {
				this.selectedStatus = btn.text;
			}
			this.loadActivities();
		}
	}

	public itemDisabled(dataItem: any) {
		return dataItem.alwaysDisabled ? true : dataItem.disabled;
	}

	generateActivityCodeFilter(data: XerData): ActvCodeFilterItem[] {
		const allActivityCodes: ActvCodeFilterItem[] = [];
		const actvTypes = dataFromXer<XerActivityType>(data, 'ACTVTYPE');
		actvTypes.forEach((actvType) => {
			const code: ActvCodeFilterItem = {
				alwaysDisabled: false,
				disabled:
					this.selectedActivityCodes?.length === 0
						? false
						: !this.selectedActivityCodes.some(
								(selActvCode) =>
									selActvCode.shortName === actvType.actv_code_type ||
									(selActvCode?.parentName === actvType.actv_code_type && selActvCode?.parentName !== undefined)
							),
				id: actvType.actv_code_type_id,
				name: actvType.actv_code_type.toString(),
				sequenceNumber: Number(actvType.seq_num),
				shortName: actvType.actv_code_type,
				subCodes: [],
				totalAssignments: 0,
			};
			allActivityCodes.push(code);
		});
		const actvCodes = dataFromXer<XerActivityCode>(data, 'ACTVCODE');
		actvCodes?.forEach((actvCode) => {
			const parent = allActivityCodes.find((code) => code.id === actvCode.actv_code_type_id);
			if (parent !== undefined) {
				const subCode: SubCodeFilterItem = {
					alwaysDisabled: false,
					disabled: false,
					id: actvCode.actv_code_id,
					name: actvCode?.actv_code_name?.toString() || actvCode?.short_name?.toString(),
					parentName: parent.name,
					sequenceNumber: actvCode.seq_num,
					shortName: actvCode.short_name,
					totalAssignments: actvCode.total_assignments || 0,
				};
				if (actvCode.total_assignments > 0) {
					parent.subCodes.push(subCode);
					parent.totalAssignments += actvCode.total_assignments;
				}
			}
		});
		return allActivityCodes.filter((code) => code.totalAssignments > 0);
	}

	/**
	 * multiselect valueChange handler
	 * @param ev
	 */
	filterChanged(ev?: ActvCodeFilterItem[]): void {
		if (this.loading) {
			return;
		}
		this.updateTagText(ev);
		this.loadActivities();
		this.allActivityCodes.forEach((code) => {
			code.disabled =
				this.selectedActivityCodes.length > 0
					? !this.selectedActivityCodes.some((c) => c.parentName === code.name)
					: false;
		});
	}

	/**
	 * multiselect tag text updater
	 * @param ev
	 */
	updateTagText(ev?: ActvCodeFilterItem[]): void {
		if (ev?.length === 0) {
			this.codesTag = '';
		} else {
			const topLevelCode = ev.find((item) => item?.subCodes?.length > 0);
			if (topLevelCode !== undefined) {
				this.codesTag = topLevelCode.name;
			} else {
				this.codesTag = ev?.length === 1 ? ev[0].name : ev?.length + ' codes selected';
			}
		}
	}

	public pageChange(event: PageChangeEvent): void {
		this.skip = event.skip;
		this.loadActivities();
	}
}
