import { differenceInCalendarDays, isSameDay, max } from 'date-fns';
import { Activity, XerActivity } from '@rhinoworks/xer-parse';
import { ActivityInfo } from './activity';
import { SlimmedTaskCommon } from '../../models/Update/Task';

export function getDateVariance(date: Date | undefined, currentCompletion: Date | undefined): number {
	return date && currentCompletion ? differenceInCalendarDays(date, currentCompletion) : 0;
}

export function latestActualDate(activity: XerActivity): Date | undefined {
	const start = activity.act_start_date;
	const finish = activity.act_end_date;
	if (start && finish) {
		return max([start, finish]);
	} else {
		return start || finish;
	}
}

export function hasActualPastDate(activity: XerActivity, dataDate: Date | undefined): boolean {
	const latestActual = latestActualDate(activity);
	return latestActual && dataDate ? latestActual > dataDate : false;
}

export function intersectsDataDate(activity: XerActivity, dataDate: Date): boolean {
	const backupStartDate = activity.early_start_date || activity.target_start_date;
	return (
		!activity.act_end_date &&
		(!!activity.act_start_date ||
			(!!backupStartDate && (backupStartDate < dataDate || isSameDay(backupStartDate, dataDate))))
	);
}
