/**
 * Main type
 *
 * If T is an object, distinguish between non required and required properties,
 * like Omit<T, non-required-props> &
 *
 */
export type DeepRequired<T, P extends string[]> = T extends object
	? Omit<T, Extract<keyof T, P[0]>> &
			Required<{
				[K in Extract<keyof T, P[0]>]: NonNullable<DeepRequired<T[K], ShiftUnion<P>>>;
			}>
	: T;

/**
 * Tuple helper; analogues to array shift
 * We can infer the shifte tuple type with help of generic rest parameters
 * in function types
 * https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-0.html#generic-rest-parameters
 * and type inference in conditional types
 * https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html#type-inference-in-conditional-types
 *
 * Example:
 * Shift<["a", "b", "c"]> = ["b", "c"]
 */
export type Shift<T extends any[]> = ((...t: T) => any) extends (first: any, ...rest: infer Rest) => any ? Rest : never;

/**
 * we want to provide multiple tuples as a union type,
 * so we can represent the different "required property paths".
 * ShiftUnion is a Distributive conditional type, so its automatically
 * distributed over the tuple unions.
 * https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html#distributive-conditional-types
 *
 * Example:
 * ShiftUnion<["a", "b", "c"] | ["one", "two", "three"]>
 * = ["b", "c"] | ["two", "three"]
 */
export type ShiftUnion<T> = T extends any[] ? Shift<T> : never;

export type MapSetToArray<T> =
	T extends Set<string> ? Array<string> : T extends Set<[string, string, string]> ? Array<[string, string, string]> : T;

export type IterableToCount<T> = {
	[K in keyof T]-?: T[K] extends Iterable<any>
		? number
		: T[K] extends Record<string, Iterable<any>>
			? IterableToCount<T[K]>
			: T[K];
};

export function average(array: number[], denominator?: number): number {
	return array.reduce((a, b) => (a || 0) + (b || 0), 0) / (denominator || array.length || 1);
}
