export function compareObj(ref: object, obj: object): boolean {
	let hasDiscrepancy = false;
	for (const [key, value] of Object.entries(ref)) {
		if (key in obj) {
			const objVal = obj[key];
			if (typeof value === 'number' && typeof objVal === 'number') {
				if (similarScore(value, objVal)) {
					continue;
				}
				hasDiscrepancy = true;
				console.log(`Found discrepancy at ${key}. Expected: ${value}, actual: ${obj[key]}`);
			} else {
				const expected = JSON.stringify(value);
				const actual = JSON.stringify(obj[key]);
				if (expected !== actual) {
					hasDiscrepancy = true;
					console.log(`Found discrepancy at ${key}. Expected: ${expected}, actual: ${actual}`);
				}
			}
		}
	}
	return hasDiscrepancy;
}

export function similarScore(score1: number, score2: number): boolean {
	return (
		score1.toFixed(2) === score2.toFixed(2) ||
		(score1 * 100).toFixed(2) === score2.toFixed(2) ||
		score1.toFixed(2) === (100 * score2).toFixed(2)
	);
}

export function arraysAreIdentical(arr1: number[], arr2: number[]): boolean {
	if (arr1.length !== arr2.length) {
		return false;
	}

	for (let i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) {
			return false;
		}
	}

	return true;
}
