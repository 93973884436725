import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { AxisSettings, GaugeColorSettings, SeriesData, SeriesDataSettings } from '../../../../models/ChartSettings';
import { PlotBand } from '@progress/kendo-angular-charts';
import { ProjectReportInterface } from '../../../../models/ProjectReport/ProjectReport';
import { ProjectInterface } from '../../../../models/Project';
import { hasObjChanged } from '../../../../util/projects';

@Component({
	selector: 'app-qc-trend',
	templateUrl: './qc-trend.component.html',
	styleUrls: ['./qc-trend.component.scss'],
})
export class QcTrendComponent implements OnInit, OnDestroy {
	@Input() isOverview: boolean = false;
	@Input() visualizer: boolean = false;
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	$qcScore = new BehaviorSubject<number>(0);
	qcColors: GaugeColorSettings[] = [
		{
			to: 79.999,
			color: '#DF5353',
		},
		{
			from: 80,
			to: 94.999,
			color: '#4fc931',
		},
		{
			from: 95,
			color: '#0059FF',
		},
	];
	public valuePlotBands: PlotBand[] = [
		{
			from: 0,
			to: 80,
			color: '#DF5353',
			opacity: 0.2,
		},
		{
			from: 80,
			to: 95,
			color: '#4fc931',
			opacity: 0.2,
		},
		{
			from: 95,
			to: 100,
			color: '#0059FF',
			opacity: 0.2,
		},
	];
	categories: string[] = [];
	seriesData: SeriesDataSettings[] = [];
	valueAxisItemSettings: AxisSettings[] = [
		{
			title: {
				text: 'Score',
				visible: true,
			},
			labels: {
				format: '{0}%',
			},
			plotBands: this.valuePlotBands,
			min: 0,
			max: 100,
			majorGridLines: {
				visible: false,
			},
		},
	];
	constructor(public projectService: ProjectDashboardService) {}
	ngOnInit(): void {
		this.projectService.$currentProjectReport
			.pipe(takeUntil(this._unsubscribeAll), debounceTime(100))
			.subscribe((report) => {
				this.updateQCGraphs(report);
			});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	updateQCGraphs(report: Omit<ProjectReportInterface, 'project'> & { project?: ProjectInterface }): void {
		if (!report) {
			return;
		}

		this.initCurrentQcTrendingChart(report);
		this.$qcScore.next(report?.qualityControl?.qcScore);
	}

	initCurrentQcTrendingChart(
		report: Pick<ProjectReportInterface, 'qualityControl' | 'baselineUpdateId' | 'updateIds'>
	): void {
		const qcTrending: number[] = report.qualityControl?.qcTrending || [0];
		const categories: string[] = [];
		const data: SeriesData[] = [];
		qcTrending.forEach((val, index) => {
			const category =
				index === 0
					? 'Baseline'
					: 'Update ' + index + (report?.baselineUpdateId === report?.updateIds[index] ? ' ®' : '');
			categories.push(category);
			data.push({
				category,
				value: val,
			});
		});
		if (hasObjChanged(categories, this.categories)) {
			this.categories = categories;
		}
		const seriesData: SeriesDataSettings[] = [
			{
				type: 'line',
				data,
				name: 'QC Value',
				visible: true,
				color: 'black',
				legendItem: {
					type: 'line',
					markers: {
						visible: false,
					},
					highlight: {
						visible: false,
					},
				},
			},
		];
		if (hasObjChanged(this.seriesData, seriesData)) {
			this.seriesData = seriesData;
		}
	}
}
