<kendo-gridlayout
	[rows]="['auto', 'auto', 'auto', 'auto']"
	[cols]="['100%']"
	[gap]="{ rows: 5, cols: 0 }"
	class='grid'
	*ngIf='currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-MPK" && currentProjectCompanyPermissions?.license !== "ANALYTICS-BASIC-APK"'
>
	<kendo-gridlayout-item [row]='1' [col]='1'>
		<app-cumulative-cash-flow></app-cumulative-cash-flow>
	</kendo-gridlayout-item>
	<kendo-gridlayout-item [row]='2' [col]='1'>
		<app-historic-performance></app-historic-performance>
	</kendo-gridlayout-item>
	<kendo-gridlayout-item [row]='3' [col]='1'>
		<app-schedule-index-trending></app-schedule-index-trending>
	</kendo-gridlayout-item>
	<kendo-gridlayout-item [row]='4' [col]='1'>
		<app-activity-costs></app-activity-costs>
	</kendo-gridlayout-item>
</kendo-gridlayout>
