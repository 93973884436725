import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ProjectReportInterface } from '../../../../models/ProjectReport/ProjectReport';
import { hasObjChanged } from '../../../../util/projects';
import {
	FloatConsumptionTableCardComponent,
	FloatConsumptionView,
} from './float-consumption-table-card/float-consumption-table-card.component';
import { AxisLabelsPosition, LegendItemClickEvent } from '@progress/kendo-angular-charts';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { RestService } from '../../../../services/common/rest.service';
import { NavigationBarStorageService } from '../../../../services/common/navigation-bar-storage.service';
import { ProjectInterface } from '../../../../models/Project';
import { ProfileCompanyPermission } from '../../../../models/auth/account-user';
import { caretAltDownIcon, fileExcelIcon, SVGIcon } from '@progress/kendo-svg-icons';
import { AnalyticsDashboardService } from '../../../../services/analytics/analytics.service';
import { ActvCodeFilterItem, SubCodeFilterItem } from '../activity-completion/activity-completion.component';
import { dataFromXer } from '../../../../util/tasks';
import { XerActivity, XerActivityCode, XerActivityType, XerData } from '@rhinoworks/xer-parse';
import { ScheduleStorageService } from '../../../../services/project/schedule-storage.service';
import { UpdateInterface } from '../../../../models/Update/Task';

interface FloatHistoricalChartData {
	data: number[];
	name: string;
}

@Component({
	selector: 'app-float-consumption',
	templateUrl: './float-consumption.component.html',
	styleUrls: ['./float-consumption.component.scss'],
})
export class FloatConsumptionComponent implements OnInit {
	public svgExcel: SVGIcon = fileExcelIcon;
	@Input() visualizer: boolean = false;

	categoryLabelsPosition: AxisLabelsPosition = 'start';
	selectedFloatConsumptionView: FloatConsumptionView = FloatConsumptionView.averageFloat;
	cardActivitiesLabel = new Map<FloatConsumptionView, string>([]);
	floatConsumptionView = FloatConsumptionView;
	isPrevAvgFloatGreater: boolean = false;

	totalActivityCodes = [];
	public selectedActivityCodes: any[] = [];
	allActivityCodes: ActvCodeFilterItem[] = [];
	allSubCodes;
	codesTag: string = '';
	icons = {
		caretDown: caretAltDownIcon,
	};
	loading: boolean = false;

	eventsSubject: Subject<void> = new Subject<void>();
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	categories: string[] = [];
	chartData: FloatHistoricalChartData[] = [];
	FLOAT_VIEW_TO_SERIES = new Map<FloatConsumptionView, string[]>([
		[FloatConsumptionView.averageFloat, ['Avg']],
		[FloatConsumptionView.negative, ['<0']],
		[FloatConsumptionView.criticalPathFloat, ['0']],
		[FloatConsumptionView.nearCriticalFloat, ['1-7']],
		[FloatConsumptionView.monthFloat, ['8-30']],
		[FloatConsumptionView.largeFloat, ['30+']],
	]);
	SERIES_TO_FLOAT_VIEW = new Map<string, FloatConsumptionView>([
		['Avg', FloatConsumptionView.averageFloat],
		['<0', FloatConsumptionView.negative],
		['0', FloatConsumptionView.criticalPathFloat],
		['1-7', FloatConsumptionView.nearCriticalFloat],
		['8-30', FloatConsumptionView.monthFloat],
		['30+', FloatConsumptionView.largeFloat],
	]);
	@ViewChild('gridCard') gridCard: FloatConsumptionTableCardComponent;
	currentProjectCompanyPermissions: ProfileCompanyPermission = null;

	constructor(
		public projectService: ProjectDashboardService,
		public restService: RestService,
		public navBarStorage: NavigationBarStorageService,
		public analyticsService: AnalyticsDashboardService,
		public storage: ScheduleStorageService
	) {}

	ngOnInit(): void {
		this.projectService.$currentProjectReport
			.pipe(takeUntil(this._unsubscribeAll), debounceTime(500))
			.subscribe((report) => {
				if (!report?.floatHistorical) {
					return;
				}
				if (report?.project?.company) {
					this.currentProjectCompanyPermissions = this.navBarStorage.companyPermissionMap.get(report?.project?.company);
				}
				if (this.storage.$allUpdates.value.length === report.updateIds.length) {
					this.updateChartData(this.storage.$allUpdates.value, report, false);
				}
			});

		this.storage.$allUpdates.pipe(takeUntil(this._unsubscribeAll)).subscribe((updates: UpdateInterface[]) => {
			if (this.projectService.$currentProjectReport.value) {
				this.updateChartData(updates, this.projectService.$currentProjectReport.value, false);
			}
		});
	}

	OnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}

	async updateChartData(
		updates: UpdateInterface[],
		report: Omit<ProjectReportInterface, 'project'> & { project?: ProjectInterface },
		skipFilterReset: boolean = false
	) {
		const selectedActivityCodes = new Set<string>(this.selectedActivityCodes.map((item) => item.shortName));
		const latestUpdateXerData = await this.storage.grabUpdateXer(updates[updates.length - 1]?._id);
		const currActvCodes = await this.storage.grabUpdateTable<XerActivityCode>(
			updates[updates.length - 1]._id,
			'ACTVCODE'
		);
		const currTaskActv = await this.storage.grabUpdateTable<{
			task_id: number;
			actv_code_type_id: number;
			actv_code_id: number;
			proj_id: number;
		}>(updates[updates.length - 1]._id, 'TASKACTV');
		const currActvsByTask: Record<
			number,
			Array<{
				task_id: number;
				actv_code_type_id: number;
				actv_code_id: number;
				proj_id: number;
			}>
		> = {};
		for (const ta of currTaskActv) {
			if (!currActvsByTask[ta.task_id]) {
				currActvsByTask[ta.task_id] = [];
			}
			currActvsByTask[ta.task_id].push(ta);
		}
		const currAllTasks = await this.storage.grabUpdateTable<XerActivity>(updates[updates.length - 1]._id, 'TASK');
		this.allActivityCodes = this.generateActivityCodeFilter(latestUpdateXerData);
		const allSubCodes = [];
		this.allActivityCodes.forEach((code) => {
			code.subCodes.forEach((subCode) => {
				const subcodeColumn = {
					title: subCode.name,
					shortName: subCode.shortName,
				};

				allSubCodes.push(subcodeColumn);
			});
		});
		this.allSubCodes = allSubCodes;

		const currShortCodes = new Map<number, string[]>(
			currAllTasks.map((task) => [
				task.task_id,
				currActvCodes
					.filter((ac) => currActvsByTask[task.task_id]?.some((ta) => ta.actv_code_id === ac.actv_code_id))
					.map((t) => t.short_name) || [],
			])
		);
		console.log('report', report);
		const categories: string[] = (report.updateIds || []).map((a, index) =>
			index === 0 ? 'Baseline' : `Update ${index}` + (report?.baselineUpdateId === report.updateIds[index] ? ' ®' : '')
		);

		let chartData;
		if (this.selectedActivityCodes.length <= 0) {
			const totalNumWithFloats: number[] = (report.floatHistorical || []).map(
				(a) => a.floatLarge + a.floatNegative + a.floatNone + a.floatWeek + a.floatMonth || 1
			);
			chartData = [
				{
					data: (report.floatHistorical || []).map((a, index) => (a.floatAverage === 0 ? null : a.floatAverage)),
					name: 'Avg',
					color: '#001489',
				},
				{
					data: (report.floatHistorical || []).map((a, index) =>
						a.floatNegative === 0 ? null : (100 * a.floatNegative) / (totalNumWithFloats[index] || 1)
					),
					name: '<0',
					color: 'rgb(229,113,113)',
				},
				{
					data: (report.floatHistorical || []).map((a, index) =>
						a.floatNone === 0 ? null : (100 * a.floatNone) / (totalNumWithFloats[index] || 1)
					),
					name: '0',
					color: 'rgb(255,229,109)',
				},
				{
					data: (report.floatHistorical || []).map((a, index) =>
						a.floatWeek === 0 ? null : (100 * a.floatWeek) / (totalNumWithFloats[index] || 1)
					),
					name: '1-7',
					color: 'rgb(133,215,115)',
				},
				{
					data: (report.floatHistorical || []).map((a, index) =>
						a.floatMonth === 0 ? null : (100 * a.floatMonth) / (totalNumWithFloats[index] || 1)
					),
					name: '8-30',
					color: 'rgb(40, 112, 247)',
				},
				{
					data: (report.floatHistorical || []).map((a, index) =>
						a.floatLarge === 0 ? null : (100 * a.floatLarge) / (totalNumWithFloats[index] || 1)
					),
					name: '30+',
					color: 'rgb(181,99,255)',
				},
			];
		} else {
			//todo: will be revisited with an idea about activity codes for float consumption
		}

		if (hasObjChanged(chartData, this.chartData)) {
			this.chartData = chartData;
		}
		if (hasObjChanged(categories, this.categories)) {
			this.categories = categories;
		}
	}

	public onLegendItemClick(e: LegendItemClickEvent): void {
		e.preventDefault();

		const seriesName = e.series.name;
		const view = this.SERIES_TO_FLOAT_VIEW.get(seriesName);
		this.selectedFloatConsumptionView =
			this.selectedFloatConsumptionView === view ? FloatConsumptionView.averageFloat : view;
	}

	changeFloatConsumptionView(floatConsumptionView: FloatConsumptionView) {
		this.selectedFloatConsumptionView = floatConsumptionView;
	}

	setCardActivities(cardSet: Map<FloatConsumptionView, string>) {
		this.cardActivitiesLabel = cardSet;
	}

	prevAvgFloatGreater(value: boolean) {
		this.isPrevAvgFloatGreater = value;
	}

	/**
	 * multiselect valueChange handler
	 * @param ev
	 */
	filterChanged(ev?: ActvCodeFilterItem[]): void {
		if (this.loading) {
			return;
		}
		this.updateTagText(ev);

		this.updateChartData(this.storage.$allUpdates.value, this.projectService.$currentProjectReport.value, true).then(
			() => {
				this.allActivityCodes.forEach((code) => {
					let disabledCount = 0;
					code.subCodes.forEach((subCode) => {
						subCode.alwaysDisabled = false;
						if (!this.totalActivityCodes.includes(subCode.shortName)) {
							subCode.alwaysDisabled = true;
							disabledCount += 1;
						}
					});

					code.alwaysDisabled = disabledCount === code.subCodes.length;
				});

				/*this.allActivityCodes.forEach((code) => {
				code.disabled =
					this.selectedActivityCodes.length > 0
						? !this.selectedActivityCodes.some((c) => c.parentName === code.name)
						: false;
			});*/
			}
		);
	}

	/**
	 * multiselect tag text updater
	 * @param ev
	 */
	updateTagText(ev?: ActvCodeFilterItem[]): void {
		if (ev?.length === 0) {
			this.codesTag = '';
		} else {
			const topLevelCode = ev.find((item) => item?.subCodes?.length > 0);
			if (topLevelCode !== undefined) {
				this.codesTag = topLevelCode.name;
			} else {
				this.codesTag = ev?.length === 1 ? ev[0].name : ev?.length + ' codes selected';
			}
		}
	}

	/**
	 * generate activity code/subcode items for the multiselect filter
	 * @param data
	 */
	generateActivityCodeFilter(data: XerData): ActvCodeFilterItem[] {
		const allActivityCodes: ActvCodeFilterItem[] = [];
		const actvTypes = dataFromXer<XerActivityType>(data, 'ACTVTYPE');
		actvTypes.forEach((actvType) => {
			const code: ActvCodeFilterItem = {
				alwaysDisabled: false,
				disabled:
					this.selectedActivityCodes?.length === 0
						? false
						: !this.selectedActivityCodes.some(
								(selActvCode) =>
									selActvCode.shortName === actvType.actv_code_type ||
									(selActvCode?.parentName === actvType.actv_code_type && selActvCode?.parentName !== undefined)
							),
				id: actvType.actv_code_type_id,
				name: actvType.actv_code_type.toString(),
				sequenceNumber: Number(actvType.seq_num),
				shortName: actvType.actv_code_type,
				subCodes: [],
				totalAssignments: 0,
			};
			allActivityCodes.push(code);
		});
		const actvCodes = dataFromXer<XerActivityCode>(data, 'ACTVCODE');
		actvCodes?.forEach((actvCode) => {
			const parent = allActivityCodes.find((code) => code.id === actvCode.actv_code_type_id);
			if (parent !== undefined) {
				const subCode: SubCodeFilterItem = {
					alwaysDisabled: false,
					disabled: false,
					id: actvCode.actv_code_id,
					name: actvCode?.actv_code_name?.toString() || actvCode?.short_name?.toString(),
					parentName: parent.name,
					sequenceNumber: actvCode.seq_num,
					shortName: actvCode.short_name,
					totalAssignments: actvCode.total_assignments || 0,
				};
				if (actvCode.total_assignments > 0) {
					parent.subCodes.push(subCode);
					parent.totalAssignments += actvCode.total_assignments;
				}
			}
		});
		return allActivityCodes.filter((code) => code.totalAssignments > 0);
	}

	public itemDisabled(dataItem: any) {
		return dataItem.alwaysDisabled || dataItem.disabled;
	}
}
