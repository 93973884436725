import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-qc-page',
	templateUrl: './qc-page.component.html',
	styleUrls: ['./qc-page.component.scss'],
})
export class QcPageComponent implements OnInit {
	@Input() visualizer: boolean = false;

	constructor() {}

	ngOnInit(): void {}
}
