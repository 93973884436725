import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
	allColumns,
	CurrentUpdateGanttColumn,
} from '../current-update-gantt-chart/current-update-gantt-chart.component';
import { FilterComponent, FilterExpression } from '@progress/kendo-angular-filter';
import { TreeItem } from '@progress/kendo-angular-treeview';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { XerActivityCode, XerActivityType } from '../../../../../models/Update/Task';
import {
	CurrentUpdateGanttPreset,
	CurrentUpdateGanttPresetInputs,
	GanttGroupingPreset,
} from '../../../../../models/Project';
import { NavigationBarStorageService } from '../../../../../services/common/navigation-bar-storage.service';
import { GanttService, ReportGroup, WbsLevel } from '../../../../../services/project/gantt.service';
import { ProjectDashboardService } from '../../../../../services/project/project.service';
import { GanttActvType } from '../gantt-group-by/gantt-group-by.component';
import { caretAltDownIcon } from '@progress/kendo-svg-icons';
import { FilterItem } from '../../../../portfolio/project-list/project-list/project-list.component';

@Component({
	selector: 'app-gantt-preset-window-content',
	templateUrl: './gantt-preset-window-content.component.html',
	styleUrls: ['./gantt-preset-window-content.component.scss'],
})
export class GanttPresetWindowContentComponent implements OnInit {
	@ViewChild('filter') filter: FilterComponent;
	public hiddenColumns: string[] = [];
	_checkedColumns: any[] = ['0', '1', '2', '3', '4', '5', '6'];
	name: string = '';
	showRelationships: boolean = false;
	actvCodesByType: Map<number, Array<XerActivityCode>> = new Map<number, Array<XerActivityCode>>([]);
	showCritical: boolean = true;
	_id: string = null;
	selectedGanttGroupNode: TreeItem = null;
	isFavorite: boolean = false;
	public filterValue: CompositeFilterDescriptor = { logic: 'and', filters: [] };
	public tempFilters: CompositeFilterDescriptor = { logic: 'and', filters: [] };
	selectedColumns: CurrentUpdateGanttColumn[] = [];
	loading: boolean = false;
	saving: boolean = false;
	isBasedOnOtherPreset: boolean = false;
	allActivityTypes: XerActivityType[] = null;
	untouchedAllActivityTypes: XerActivityType[] = null;
	public statusFilterList: Array<string> = ['Complete', 'Incomplete', 'Not Started'];
	public taskTypeFilterList: Array<string> = [
		'Task Dependent',
		'Resource Dependent',
		'Milestone',
		'Finish Milestone',
		'WBS Summary',
		'Level of Effort',
	];
	public criticalityFilterList: Array<string> = ['Critical', 'Non Critical']; //todo: add 'Near Critical' back when near critical vs critical distinctions are accurate - RS
	public filters: FilterExpression[] = [
		{
			field: 'taskCode',
			title: 'ID',
			editor: 'string',
		},
		{
			field: 'name',
			title: 'Name',
			editor: 'string',
		},
	];
	filtersOrGroupBy: 'filters' | 'groupBy' = 'filters';
	currentTab: number = 0;
	currentlyAppliedWbsLevel: WbsLevel = { text: 'Please Select', value: 0 };
	currentlyAppliedActvTypes: GanttActvType[] = [];
	currentlyAppliedGrouping: 'wbs' | 'actvCodes' = null;
	radioSelectionDelayed: 'wbs' | 'actvCodes' = null;
	otherPresets: CurrentUpdateGanttPreset[] = [];
	basedOn: CurrentUpdateGanttPreset = null;
	selectedFolders: any[] = [];
	icons = {
		caretDown: caretAltDownIcon,
	};

	constructor(
		public ganttService: GanttService,
		public projectService: ProjectDashboardService
	) {}

	ngOnInit() {
		this.ganttService.$reportApply.subscribe((val: boolean) => {
			if (val) {
				this.applyGroupBy();
			}
		});
		this.ganttService.$currentUpdateGanttPresetWindowOpen.subscribe((preset: CurrentUpdateGanttPresetInputs) => {
			this._id = null;
			if (preset !== null && preset) {
				this.updatePresetValues(preset.preset, preset.isBasedOnOtherPreset);
				this.isBasedOnOtherPreset = preset.isBasedOnOtherPreset;
				this.basedOn = preset.isBasedOnOtherPreset ? preset.preset : null;
				this.loading = true;
				this.ganttService.isEdit = !preset.isNew;
				this.actvCodesByType = structuredClone(preset.activityMap);
				this.untouchedAllActivityTypes = structuredClone(preset.groups);
				this.filterValue = preset.currentFilters;
				this.tempFilters = preset.currentFilters;
				this.ganttService.presetWindowContentFilters = preset.currentFilters;
				this.ganttService.originalPreset = preset;
				this.allActivityTypes = structuredClone(preset.groups);
				if (
					this.ganttService.isEdit &&
					this.selectedGanttGroupNode !== null &&
					!this.existsInGrouping(preset.groups, this.selectedGanttGroupNode?.dataItem)
				) {
					this.allActivityTypes.push(this.selectedGanttGroupNode.dataItem);
				}
				const otherPresets: CurrentUpdateGanttPreset[] = this.ganttService.isEdit
					? structuredClone(this.ganttService.userPresets).filter((p: CurrentUpdateGanttPreset) => p.id !== this._id)
					: structuredClone(this.ganttService.userPresets);
				otherPresets.splice(0, 0, {
					id: null,
					name: 'None (New Report)',
					grouping: null,
					showingRelationships: false,
					showingCritical: false,
					filters: null,
					isFavorite: false,
					visibleColumns: null,
				});
				this.otherPresets = otherPresets;
				this.loading = false;
				//this.defaultPreset = this.isFavorite ? 'Yes' : 'No';
				this.updateGroupingVals();
			}
			if (preset === null) {
				this.tempFilters = structuredClone(this.ganttService.tempFilters);
				this.filterValue = structuredClone(this.ganttService.filterValue);
			}
		});
		this.ganttService.$reset.subscribe((val: boolean) => {
			if (val) {
				this.updateGroupingVals();
			}
		});
		this.ganttService.$resetAllClicked.subscribe((val: boolean) => {
			if (val) {
				this.reset(true);
			}
		});
		this.ganttService.$groupByXClicked.subscribe((val: boolean) => {
			if (val) {
				this.currentlyAppliedGrouping = null;
				this.currentlyAppliedWbsLevel = null;
				this.currentlyAppliedActvTypes = [];
				this.ganttService.oldRadioVal = structuredClone(this.ganttService.radioSelection);
				this.ganttService.oldGroupBy = structuredClone(this.ganttService.selectedGroupBy);
				this.ganttService.oldWbsLevel = structuredClone(this.ganttService.selectedWbsLevel);
				this.ganttService.radioSelection = null;
				this.ganttService.selectedGroupBy = [];
				this.ganttService.selectedWbsLevel = { text: 'Please Select', value: 0 };
			} else {
				this.ganttService.radioSelection = structuredClone(this.ganttService.oldRadioVal);
				this.ganttService.selectedGroupBy = structuredClone(this.ganttService.oldGroupBy);
				this.ganttService.selectedWbsLevel = structuredClone(this.ganttService.oldWbsLevel);
			}
		});
	}

	updateName(ev: string): void {
		this.ganttService.presetWindowName = ev;
	}

	/**
	 * update report form values based on input preset
	 * @param preset
	 * @param ignoreName
	 */
	updatePresetValues(preset: CurrentUpdateGanttPreset, ignoreName: boolean = false): void {
		this.checkedColumns = preset.visibleColumns;
		this.showCritical = preset.showingCritical;
		this.showRelationships = preset.showingRelationships;
		this.isFavorite = preset.isFavorite;
		if (!ignoreName) {
			this.name = preset.name;
			this.ganttService.presetWindowName = preset.name;
		}
		this._id = preset.id;
		this.ganttService.radioSelection = preset.grouping.type;
		//this.ganttService.radioSelectionDelayed = preset.preset.grouping.type;
		if (this.ganttService.radioSelection) {
			this.radioSelectionDelayed = this.ganttService.radioSelection;
		}
		const wbsLevel: number = preset.grouping.wbsToLevel;
		this.ganttService.selectedWbsLevel =
			wbsLevel === 0 || wbsLevel === null
				? { text: 'Please Select', value: 0 }
				: { text: 'Level ' + wbsLevel, value: wbsLevel };
		this.ganttService.selectedGroupBy = preset.grouping.actvCodeGrouping;
		this.ganttService.selectedGroupByDelayed = preset.grouping.actvCodeGrouping;
		this.ganttService.hideSummaryBars = preset.grouping.hideSummaryBars;
		const folders: ReportGroup[] = [];
		this.ganttService.reportGroups.forEach((folder: ReportGroup) => {
			if (folder.reportIds.includes(preset.id)) {
				folders.push(folder);
			}
		});
		this.selectedFolders = structuredClone(folders);
	}

	// Moved from gantt-group-by to here because the group by applying wasn't happening unless you switched to 'Grouping' tab and that component opened.
	// Moving it here allowed the apply button to work without having to open 'Grouping' tab. - KF
	async applyGroupBy(): Promise<void> {
		if (!this.ganttService.applyPhysicallyClicked) {
			return;
		}
		//this.originalHideSummaryBars = structuredClone(this.ganttService.hideSummaryBars);
		if (this.ganttService.radioSelection === 'wbs') {
			await this.ganttService.applyWbsGrouping();
			const test: number =
				this.ganttService.selectedWbsLevel?.value === null
					? this.ganttService.highestLevel
					: this.ganttService.selectedWbsLevel?.value;
			this.ganttService.puppetLevel = [];
			for (let i: number = 0; i < test; i++) {
				this.ganttService.puppetLevel.push(i);
			}
			//this.originalSelectedWbsLevel = structuredClone(this.ganttService.selectedWbsLevel);
			//this.testSelectedWbs();
		} else {
			await this.ganttService.applyGroupBy();
			//this.originalSelectedList = structuredClone(this.ganttService.selectedGroupBy);
			//this.testSelectedList();
		}
		this.ganttService.applyGroupBySort();
	}

	existsInGrouping(groups, item) {
		return groups.some((group) => {
			return group.actv_code_type_id === item.actv_code_type_id;
		});
	}

	get checkedColumns() {
		return this._checkedColumns;
	}

	public isDisabled = (dataItem: any) => dataItem?.sequence === 0;

	set checkedColumns(columns: string[]) {
		columns = columns.sort((a: string, b: string) => {
			const valA: number = Number(a);
			const valB: number = Number(b);
			return valA < valB ? -1 : valB < valA ? 1 : 0;
		});
		this._checkedColumns = columns;
		const hiddenColumnFields: string[] = [];
		allColumns.columns.forEach((column: CurrentUpdateGanttColumn) => {
			if (!columns.includes(column.sequence.toString())) {
				hiddenColumnFields.push(column.field);
			}
		});
		this.hiddenColumns = hiddenColumnFields;
	}

	onFilterChange(filter: CompositeFilterDescriptor) {
		this.tempFilters = filter;
		this.ganttService.presetWindowContentFilters = filter;
	}

	save(): void {
		const existingPresets: CurrentUpdateGanttPreset[] = structuredClone(
			this.projectService.$currentProjectData.value?.ganttPresets
		);
		const matchingPreset: CurrentUpdateGanttPreset =
			existingPresets === undefined ? undefined : existingPresets.find((preset) => preset.id === this._id);
		const thisReportId: string = matchingPreset === undefined ? crypto.randomUUID() : this._id;
		if (this.selectedFolders?.length) {
			const newReportFolders: ReportGroup[] = [];
			this.ganttService.reportGroups.forEach((folder) => {
				if (this.selectedFolders.findIndex((f) => f.id === folder.id) !== -1) {
					folder.reportIds.push(thisReportId);
				} else {
					folder.reportIds = folder.reportIds.filter((r) => r !== thisReportId);
				}
				newReportFolders.push(folder);
			});
			this.ganttService.updateReportGroups(newReportFolders);
		}
		if (matchingPreset === undefined) {
			const newPreset: CurrentUpdateGanttPreset = {
				grouping: this.ganttService.getGrouping(),
				showingRelationships: this.showRelationships,
				showingCritical: this.showCritical,
				filters: JSON.stringify(this.tempFilters),
				visibleColumns: this.checkedColumns,
				name: this.name,
				isFavorite: this.isFavorite,
				id: thisReportId,
			};
			this.ganttService.addNewGanttPreset(newPreset);
			this.ganttService.$currentUpdateGanttPresetWindowOpen.next(null);
		} else {
			let newPresets: CurrentUpdateGanttPreset[] = [];
			existingPresets.forEach((preset: CurrentUpdateGanttPreset) => {
				if (preset.id === this._id) {
					newPresets.push({
						grouping: this.ganttService.getGrouping(),
						showingRelationships: this.showRelationships,
						showingCritical: this.showCritical,
						filters: JSON.stringify(this.tempFilters),
						visibleColumns: this.checkedColumns,
						name: this.name,
						isFavorite: this.isFavorite,
						id: thisReportId,
					});
				} else {
					newPresets.push(structuredClone(preset));
				}
			});
			newPresets = newPresets.sort((a: CurrentUpdateGanttPreset, b: CurrentUpdateGanttPreset) => {
				// if (a?.isFavorite && !b?.isFavorite) {
				// 	return -1;
				// }
				// if (b?.isFavorite && !a?.isFavorite) {
				// 	return 1;
				// }
				if (a?.name?.toLowerCase() < b?.name?.toLowerCase()) {
					return -1;
				}
				if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
					return 1;
				}
				return 0;
			});
			this.ganttService.editGanttPreset(newPresets);
			this.ganttService.$currentUpdateGanttPresetWindowOpen.next(null);
		}
	}

	reset(ignoreServiceCalls: boolean = false): void {
		this.isFavorite = false;
		this._checkedColumns = ['0', '1', '2', '3', '4', '5', '6'];
		this.showRelationships = false;
		this.showCritical = true;
		this.filterValue = { logic: 'and', filters: [] };
		this.tempFilters = this.filterValue;
		this.ganttService.presetWindowContentFilters = this.filterValue;
		this.selectedGanttGroupNode = null;
		if (!ignoreServiceCalls) {
			this.ganttService.resetFilters(true);
			this.ganttService.$resetOptions.next(true);
		}
		this.loading = false;
		this.currentlyAppliedGrouping = null;
		this.basedOn = null;
		this.updateGroupingVals();
		//this.radioSelectionDelayed = null;
		this.isDiffThanCurrent();
	}

	apply(): void {
		this.ganttService.applyPhysicallyClicked = true;
		if (this.currentlyAppliedGrouping === null && this.ganttService.$groupByXClicked.value) {
			this.ganttService.radioSelection = null;
			this.ganttService.selectedWbsLevel = { text: 'Please Select', value: 0 };
			this.ganttService.selectedGroupBy = [];
			this.ganttService.$groupByXClicked.next(false);
		}
		if (this.radioSelectionDelayed) {
			this.radioSelectionDelayed = null;
		}
		this.ganttService.tempFilters = structuredClone(this.tempFilters);
		this.filterValue = structuredClone(this.tempFilters);
		this.ganttService.applyFilterChanges();
		this.ganttService.checkedColumns = structuredClone(this.checkedColumns);
		this.ganttService.radioSelectionDelayed = structuredClone(this.ganttService.radioSelection);
		if (this.ganttService.radioSelection === 'wbs' || this.ganttService.radioSelection === 'actvCodes') {
			this.ganttService.$reportApply.next(true);
		} else {
			this.ganttService.resetGroups();
			this.ganttService.sort = [
				{
					field: 'end',
					dir: 'asc',
				},
			];
		}
		this.ganttService.taskTypeButtons[0].selected = structuredClone(this.showCritical);
		this.ganttService.taskTypeButtons[1].selected = !structuredClone(this.showCritical);
		this.ganttService.$showOnlyCritical.next(this.showCritical);
		this.ganttService.relationshipsButtons[0].selected = !structuredClone(this.showRelationships);
		this.ganttService.relationshipsButtons[1].selected = structuredClone(this.showRelationships);
		this.ganttService.$showLines.next(this.showRelationships);
		this.updateGroupingVals();
		this.ganttService.applyPhysicallyClicked = false;
		this.ganttService.selectedGanttLayout = null;
		this.ganttService.checkResetBtn();
	}

	updateGroupingVals(): void {
		this.currentlyAppliedGrouping = structuredClone(this.ganttService.radioSelection);
		this.currentlyAppliedActvTypes = structuredClone(this.ganttService.selectedGroupBy);
		this.currentlyAppliedWbsLevel = structuredClone(this.ganttService.selectedWbsLevel);
	}

	isDiffThanCurrent(): boolean {
		this.ganttService.applyCanBeClicked = !(
			this.saving ||
			(this.ganttService.radioSelection === 'wbs' && this.ganttService.selectedWbsLevel.value === 0) ||
			(this.ganttService.radioSelection === 'actvCodes' && this.ganttService.selectedGroupBy?.length === 0) ||
			(JSON.stringify(this.ganttService.tempFilters) === JSON.stringify(this.tempFilters) &&
				JSON.stringify(this._checkedColumns) === JSON.stringify(this.ganttService._checkedColumns) &&
				(this.radioSelectionDelayed
					? this.currentlyAppliedGrouping === this.radioSelectionDelayed
					: this.currentlyAppliedGrouping === this.ganttService.radioSelectionDelayed) &&
				JSON.stringify(this.currentlyAppliedWbsLevel) === JSON.stringify(this.ganttService.selectedWbsLevel) &&
				JSON.stringify(this.currentlyAppliedActvTypes) === JSON.stringify(this.ganttService.selectedGroupBy) &&
				this.showCritical === this.ganttService.taskTypeButtons[0].selected &&
				this.showRelationships === this.ganttService.relationshipsButtons[1].selected)
		);
		return !this.ganttService.applyCanBeClicked;
	}

	public isSelectedAll(): boolean {
		const allOptions: string[] = structuredClone(this.ganttService.reportGroups)
			.map((g) => g.id)
			.sort((a: string, b: string) => {
				return a < b ? -1 : b < a ? 1 : 0;
			});
		const selectedOptions: string[] = structuredClone(this.selectedFolders)
			.map((g) => g.id)
			.sort((a: string, b: string) => {
				return a < b ? -1 : b < a ? 1 : 0;
			});
		return JSON.stringify(allOptions) === JSON.stringify(selectedOptions);
	}

	/**
	 * toggle selectAll folders
	 */
	onFilterSelectAllClick(): void {
		if (this.selectedFolders?.length === this.ganttService.reportGroups?.length) {
			this.selectedFolders = [];
		} else {
			this.selectedFolders = structuredClone(this.ganttService.reportGroups);
		}
	}

	public editorValueChange(value, currentItem?: FilterDescriptor, useIdFromObj?: boolean): void {
		if (currentItem) {
			currentItem.value = useIdFromObj ? value.actv_code_id.toString() : value;
		}
		this.tempFilters = this.filter.value;
		this.ganttService.presetWindowContentFilters = this.filter.value;
	}

	updateBasedOnSelection(ev: CurrentUpdateGanttPreset): void {
		this.updatePresetValues(ev, true);
	}

	protected readonly allColumns = allColumns;
}
