import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
	customFieldOptionsMapping,
	MitigationRiskScoring,
	PostMitigationRisk,
	PrePostMitigationRisk,
	RiskRegister,
} from '../../../../../models/risk';
import { BehaviorSubject, Subject } from 'rxjs';
import { RestService } from '../../../../../services/common/rest.service';
import { ProjectDashboardService } from '../../../../../services/project/project.service';
import { toTitleCase } from '../../../../../util/strings';
import { isValid } from 'date-fns';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { TaskArrayInterface, XerActivityCode, XerActivityType } from '../../../../../models/Update/Task';
import { takeUntil } from 'rxjs/operators';
import { ExpandedMetricActivity, ExpandedMetrics } from '../../../../../models/ProjectReport/ExpandedMetrics';
import { taskDuration } from '../../../../../util/tasks';
import { cleanDateUTC } from '../../../../../util/pipes/date.pipe';
import { CompositeFilterDescriptor, filterBy, FilterDescriptor, orderBy } from '@progress/kendo-data-query';
import { FilterComponent, FilterExpression } from '@progress/kendo-angular-filter';
import {
	CellClickEvent,
	ColumnVisibilityChangeEvent,
	GridDataResult,
	PageChangeEvent,
} from '@progress/kendo-angular-grid';
import { riskRegisterFormGroup } from '../../../../../services/project/register-edit.service';
import { SortDescriptor } from '@progress/kendo-data-query/dist/npm/sort-descriptor';
import { ColumnResizeArgs } from '@progress/kendo-angular-grid/column-resizing/column-resize.interface';
import { AppWindowService } from '../../../../../services/common/window.service';
import { ScheduleStorageService } from '../../../../../services/project/schedule-storage.service';
import { XerActivity } from '@rhinoworks/xer-parse';

type ActvTypeIndex = `ActvType_${string}`;

type ExpandedActivityInfo = ExpandedMetricActivity & {
	Duration?: number;
	Target_Duration?: number;
	Start?: Date | undefined;
	Finish?: Date | undefined;
	ActvTypeJoined?: string;
	Status?: string;
	ActvCodeTypeJoined?: string;
	ActvCodes?: XerActivityCode[];
	[key: ActvTypeIndex]: string;
};

export interface IButton {
	text: string;
	value: number;
	selected?: boolean;
}

export const riskFormPresets = {
	mitStatus: [
		'Identified',
		'Mitigation Planning',
		'Tracking',
		'Active',
		'Mitigation',
		'Closed Impact',
		'Closed No Impact',
		'Closed No Occurrence',
	],
	category: ['Permitting', 'Environmental', 'Design', 'Schedule', 'Contractual'],
	triggerPhase: ['Planning', 'Design', 'Preconstruction', 'Construction', 'Commissioning'],
	scores: [0, 1, 2, 3, 4, 5],
	mitigationStrategy: ['accept', 'reduce', 'mitigate'],
};

@Component({
	selector: 'app-risk-register-form',
	templateUrl: './risk-register-form.component.html',
	styleUrls: ['./risk-register-form.component.scss'],
})
export class RiskRegisterFormComponent implements OnInit, OnChanges {
	@ViewChild('filter') filter: FilterComponent;
	public gridView: GridDataResult = {
		data: [],
		total: 0,
	};
	public form: FormGroup;
	@Input() riskMitigationData: RiskRegister;
	@Input() registersByActvCode: Map<string, Set<number>> = new Map<string, Set<number>>([]);
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	public presets = riskFormPresets;
	allActivities = new Map<string, TaskArrayInterface>([]);
	activityList: TaskArrayInterface[] = [];
	allCodeShortNames: string[] = [];
	allCodeTypeNames: string[] = [];
	allActivityTypes: Array<XerActivityType> = [];
	nameByCodeId = new Map<number, string>([]);
	actvCodesByType = new Map<number, Array<XerActivityCode>>([]);
	showingOnlySelected = false;
	preMitigationScore = new BehaviorSubject<number>(0);
	postMitigationScore = new BehaviorSubject<number>(0);
	existingEntries: { [field: string]: string[] } = {};
	existingEntriesWithoutTemps: { [field: string]: string[] } = {};
	preMitigationActvImpact = 0;
	preMitigationEstCost = 0;
	postMitigationActvImpact = 0;
	postMitigationEstCost = 0;
	@Output() registerSave = new EventEmitter<RiskRegister>();
	@Output() formTouch = new EventEmitter<boolean>();
	scoreLabels = ['Not Applicable', 'Very Low', 'Low', 'Moderate', 'High', 'Very High'];
	public widthPreferences: Record<string, number> = {};
	initialFormVal = null;
	actvCodesById: Map<number, XerActivityCode> = new Map<number, XerActivityCode>([]);
	actvCodesByTask: Map<number, XerActivityCode[]> = new Map<number, XerActivityCode[]>([]);

	@Input() data: RiskRegister = {
		category: '',
		riskName: '',
		costOwner: {
			name: '',
			email: '',
		},
		description: '',
		effect: '',
		impactedTaskCodes: [],
		mitStatus: '',
		postMitigation: {
			probability: null,
			scheduleImpact: null,
			costImpact: null,
			performanceImpact: null,
			qualityImpact: null,
			activityImpact: null,
			estCost: null,
			impactVarianceMin: 0,
			impactVarianceMax: 0,
		} as PostMitigationRisk,
		preMitigation: {
			probability: null,
			scheduleImpact: null,
			costImpact: null,
			performanceImpact: null,
			qualityImpact: null,
			activityImpact: null,
			estCost: null,
			impactVarianceMin: 0,
			impactVarianceMax: 0,
		} as PrePostMitigationRisk,
		responsibility: {
			name: '',
			email: '',
		},
		riskId: 0,
		riskOwner: {
			name: '',
			email: '',
		},
		strategy: {
			strategy: undefined,
			measures: '',
			statusDate: undefined,
		},
		triggerPhase: '',
		disabled: false,
		costVarianceMin: null,
		costVarianceMax: null,
		isDraft: false,
	};
	@Input() nextRiskId: number;
	@Input() editingRiskRegister: RiskRegister;
	impactsValid = true;
	tabIndex = 0;
	public filterValue: CompositeFilterDescriptor = { logic: 'and', filters: [] };
	public filters: FilterExpression[] = [
		{
			field: 'task_code',
			title: 'ID',
			editor: 'string',
		},
		{
			field: 'task_name',
			title: 'Name',
			editor: 'string',
		},
	];

	public statusFilterList: Array<string> = ['Incomplete', 'Not Started'];

	public skip = 0;
	public sort: SortDescriptor[] = [];

	customFieldOptions = customFieldOptionsMapping;
	missingRequiredFields = [false, false, false, false];

	//right now any previously hidden columns wont matter. Opening Schedule tab will automatically show all columns
	//@Input() hiddenColumns = new Set<string>([]);
	hiddenColumns = new Set<string>([]);

	public tagTypeButtons: IButton[] = [
		{
			text: 'Tagged',
			value: 0,
			selected: false,
		},
		{
			text: 'Show All',
			value: 1,
			selected: true,
		},
	];
	disablePre: boolean = false;
	disablePost: boolean = false;

	constructor(
		private restService: RestService,
		public project: ProjectDashboardService,
		private cd: ChangeDetectorRef,
		public appWindowService: AppWindowService,
		public storage: ScheduleStorageService,
		public schedStorage: ScheduleStorageService
	) {
		const rmt: string = this.project.$currentProjectData?.value?.riskMetricsType;
		this.form = riskRegisterFormGroup(this.data, rmt);
		this.form.get('riskId')?.disable();
	}

	ngOnInit() {
		this.project.$expandedMetrics.pipe(takeUntil(this._unsubscribeAll)).subscribe(async (metrics: ExpandedMetrics) => {
			metrics = metrics as ExpandedMetrics;
			this.allActivityTypes = metrics.activityTypes || [];
			this.allActivities.clear();
			this.activityList = [];
			// const allCodeShorts = new Set<string>([]);
			// const allTaskTypes = new Set<string>([]);
			// const allActivities: ExpandedActivityInfo[] = (metrics.totalActivities as ExpandedActivityInfo[]) || [];
			const tasks = await this.schedStorage.grabUpdateTable<XerActivity>(metrics.updateId, 'TASK');
			const actvsByTask: Record<
				number,
				Array<{
					task_id: number;
					actv_code_type_id: number;
					actv_code_id: number;
					proj_id: number;
				}>
			> = {};
			const latestUpdateId: string =
				this.project.$currentProjectData.value.updateIds[this.project.$currentProjectData.value.updateIds.length - 1];
			const currTaskActv = await this.schedStorage.grabUpdateTable<{
				task_id: number;
				actv_code_type_id: number;
				actv_code_id: number;
				proj_id: number;
			}>(latestUpdateId, 'TASKACTV');
			for (const ta of currTaskActv) {
				if (!actvsByTask[ta.task_id]) {
					actvsByTask[ta.task_id] = [];
				}
				actvsByTask[ta.task_id].push(ta);
			}
			const actvCodes: XerActivityCode[] = await this.schedStorage.grabUpdateTable<XerActivityCode>(
				latestUpdateId,
				'ACTVCODE'
			);
			const promises = [];
			promises.push(tasks);
			promises.push(actvCodes);
			Promise.all(promises).then(() => {
				this.actvCodesById.clear();
				this.actvCodesByTask.clear();
				this.actvCodesByType.clear();
				for (const type of this.allActivityTypes) {
					this.actvCodesByType.set(type.actv_code_type_id, []);
				}
				for (const code of actvCodes) {
					const siblings: XerActivityCode[] = this.actvCodesByType.get(code.actv_code_type_id) || [];
					const codeName: string = code?.actv_code_name || '';
					const shortName: string = code?.short_name || '';
					const fullName: string =
						(codeName ? `${codeName}` : '') + (codeName && shortName ? ' - ' : '') + (shortName || '');
					if (siblings && fullName && !siblings.some((s) => s.actv_code_id === code.actv_code_id)) {
						siblings.push(code);
					}

					this.actvCodesByType.set(code.actv_code_type_id, siblings);
					this.actvCodesById.set(code.actv_code_id, code);
				}
				for (const task of tasks) {
					const activity: ExpandedActivityInfo = structuredClone(task);
					if (task.act_end_date) {
						continue;
					}
					activity.Duration = taskDuration(task);
					activity.Target_Duration = (task.target_drtn_hr_cnt ?? 0) / 8;
					if (task.act_start_date) {
						activity.Status = 'Incomplete';
					} else {
						activity.Status = 'Not Started';
					}
					const actvCodesForTask: XerActivityCode[] = (actvsByTask[task.task_id] || []).map((ta) =>
						this.actvCodesById.get(ta.actv_code_id)
					);
					this.actvCodesByTask.set(task.task_id, actvCodesForTask);
					const actvCodeList: number[] = [];
					actvCodesForTask.forEach((code) => {
						actvCodeList.push(code.actv_code_id);
					});
					const codeShortNames: string[] = [];
					// const codeIds = activity.actvCodeIds || [];
					// for (const id of codeIds) {
					// 	if (metrics.activityCodeMap[id]?.short_name) {
					// 		const codeName = metrics.activityCodeMap[id]?.actv_code_name || '';
					// 		const shortName = metrics.activityCodeMap[id]?.short_name || '';
					// 		const fullName = (codeName ? `${codeName}` : '') + (codeName && shortName ? ' - ' : '') + (shortName || '');
					// 		allCodeShorts.add(fullName);
					// 		codeShortNames.push(fullName);
					// 		this.nameByCodeId.set(id, fullName);
					// 		if (metrics.activityTypeMap[metrics.activityCodeMap[id].actv_code_type_id]) {
					// 			activity[
					// 			'ActvType_' + metrics.activityTypeMap[metrics.activityCodeMap[id].actv_code_type_id].actv_code_type
					// 				] = fullName;
					// 		}
					// 	}
					// }
					// activity.ActvTypeJoined = codeShortNames.join(',');
					const codeTypeNames: string[] = [];
					// const taskTypeIds = activity.actvCodeTypeIds || [];
					// for (const type of taskTypeIds) {
					// 	if (metrics.activityTypeMap[type]?.actv_code_type) {
					// 		codeTypeNames.push(metrics.activityTypeMap[type]?.actv_code_type);
					// 		allTaskTypes.add(metrics.activityTypeMap[type]?.actv_code_type);
					// 	}
					// }
					// activity.ActvCodeTypeJoined = codeTypeNames.join(',');
					activity.ActvCodes = this.actvCodesByTask.get(task.task_id);
					activity.ActvTypeJoined = actvCodeList.join(',');
					const startDate = new Date(task.early_start_date);
					if (isValid(startDate)) {
						activity.Start = startDate;
					}
					const endDate = new Date(task.early_end_date);
					if (isValid(endDate)) {
						activity.Finish = endDate;
					}
					activity.task_code = task.task_code.toString();
					this.allActivities.set(task.task_code, task);
					this.activityList.push(activity);
				}
				// this.allCodeShortNames = Array.from(allCodeShorts);
				// this.allCodeTypeNames = Array.from(allTaskTypes);
				//
				// const actvCodes = metrics.activityCodes || [];
				// for (const code of actvCodes) {
				// 	const siblings = this.actvCodesByType.get(code.actv_code_type_id) || [];
				// 	const codeName = metrics.activityCodeMap[code.actv_code_id]?.actv_code_name || '';
				// 	const shortName = metrics.activityCodeMap[code.actv_code_id]?.short_name || '';
				// 	const fullName = (codeName ? `${codeName}` : '') + (codeName && shortName ? ' - ' : '') + (shortName || '');
				// 	if (siblings && fullName) {
				// 		siblings.push(fullName);
				// 	}
				// 	this.actvCodesByType.set(code.actv_code_type_id, siblings);
				// }
				for (let i = 0; i < 5; i++) {
					this.touchControlsOnTab(i);
				}
				this.form.controls.preMitigationImpactVarianceMin.markAsTouched();
				this.form.controls.postMitigationImpactVarianceMin.markAsTouched();
				this.updateTabValidity();
				this.loadActivities();
			});
		});
		this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe((val) => {
			this.formTouch.emit(true);
			const currentVal = this.form.getRawValue();
			let unsavedChanges = false;
			if (JSON.stringify(currentVal) !== JSON.stringify(this.initialFormVal)) {
				unsavedChanges = true;
			}
			this.storage.unsavedRiskRegisterFormChanges = unsavedChanges;
		});
		for (const [code, registers] of this.registersByActvCode) {
			this.registersByActvCode.set(code, new Set<number>(Array.from(registers)));
		}
		this.project.$currentRiskRegisters.subscribe((registers: RiskRegister[]) => {
			const existingFields = {
				riskOwnerNames: new Set<string>([]),
				costOwnerNames: new Set<string>([]),
				responsibilityNames: new Set<string>([]),
				riskOwnerEmails: new Set<string>([]),
				costOwnerEmails: new Set<string>([]),
				responsibilityEmails: new Set<string>([]),
				category: new Set<string>(riskFormPresets.category),
			};
			for (const register of registers) {
				if (register.riskOwner?.name) {
					existingFields.riskOwnerNames.add(register.riskOwner.name);
				}
				if (register.riskOwner?.email) {
					existingFields.riskOwnerEmails.add(register.riskOwner.email);
				}
				if (register.costOwner?.name) {
					existingFields.costOwnerNames.add(register.costOwner.name);
				}
				if (register.costOwner?.email) {
					existingFields.costOwnerEmails.add(register.costOwner.email);
				}
				if (register.responsibility?.name) {
					existingFields.responsibilityNames.add(register.responsibility.name);
				}
				if (register.responsibility?.email) {
					existingFields.responsibilityEmails.add(register.responsibility.email);
				}
				if (register.category) {
					existingFields.category.add(register.category);
				}
			}
			let unassignedCustomFields =
				this.project.$currentProjectData.value?.preferences?.riskMitigation?.unassignedCustomFields;
			if (unassignedCustomFields === undefined) {
				unassignedCustomFields = {
					riskOwner: {
						name: [],
						email: [],
					},
					costOwner: {
						name: [],
						email: [],
					},
					responsibility: {
						name: [],
						email: [],
					},
					category: [],
				};
			}
			this.customFieldOptions.forEach((field) => {
				const unassignedFieldsArray = field.subpath
					? unassignedCustomFields[field.path][field.subpath]
					: unassignedCustomFields[field.path];
				unassignedFieldsArray.forEach((unassignedField) => {
					existingFields[field.efPath].add(unassignedField);
				});
			});

			this.existingEntries = {
				riskOwnerNames: Array.from(existingFields.riskOwnerNames),
				costOwnerNames: Array.from(existingFields.costOwnerNames),
				responsibilityNames: Array.from(existingFields.responsibilityNames),
				riskOwnerEmails: Array.from(existingFields.riskOwnerEmails),
				costOwnerEmails: Array.from(existingFields.costOwnerEmails),
				responsibilityEmails: Array.from(existingFields.responsibilityEmails),
				category: Array.from(existingFields.category),
			};
			this.existingEntriesWithoutTemps = {
				riskOwnerNames: Array.from(existingFields.riskOwnerNames),
				costOwnerNames: Array.from(existingFields.costOwnerNames),
				responsibilityNames: Array.from(existingFields.responsibilityNames),
				riskOwnerEmails: Array.from(existingFields.riskOwnerEmails),
				costOwnerEmails: Array.from(existingFields.costOwnerEmails),
				responsibilityEmails: Array.from(existingFields.responsibilityEmails),
				category: Array.from(existingFields.category),
			};
			this.loadActivities();
		});
		this.project.$currentProjectData.subscribe((project) => {
			this.widthPreferences = project.preferences.riskMitigation?.columnWidths?.scheduleSelector || {};
		});
		this.loadActivities();
	}

	/**
	 * temporarily update existingFields with current entries for use in dropdowns
	 * @param fieldName
	 */
	updateExistingEntries(fieldName: string): void {
		const control = fieldName === 'category' ? 'category' : fieldName.slice(0, fieldName.length - 2);
		const updatedFields = structuredClone(this.existingEntriesWithoutTemps[fieldName]);
		if (this.form.controls[control]?.value !== undefined && this.form.controls[control].value !== '') {
			updatedFields.push(this.form.controls[control].value);
		}
		this.existingEntries[fieldName] = updatedFields;
		this.updateTabValidity();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('riskMitigationData')) {
			const data = (changes.riskMitigationData.currentValue || {}) as RiskRegister;
			data.impactedTaskCodes = [...(data.impactedTaskCodes || [])];

			const formPatchData: { [key: string]: any } = {};
			for (const [key, value] of Object.entries(data)) {
				if (value !== null && typeof value === 'object' && Object.entries(value).length > 0 && !Array.isArray(value)) {
					for (const [subKey, subValue] of Object.entries(value)) {
						const comboKey = key + toTitleCase(subKey);
						if (comboKey.toLowerCase().includes('date')) {
							const valueAsDate = new Date(subValue);
							if (isValid(valueAsDate)) {
								formPatchData[comboKey] = valueAsDate;
							} else {
								formPatchData[comboKey] = subValue;
							}
						} else {
							formPatchData[comboKey] = subValue;
						}
					}
				} else {
					formPatchData[key] = value;
				}
			}
			if (!Object.values(data).length) {
				formPatchData.riskId = this.nextRiskId;
			}
			if (formPatchData.preMitigationActivityImpact) {
				formPatchData.preMitigationActivityImpact *= 100;
			}
			if (formPatchData.postMitigationActivityImpact) {
				formPatchData.postMitigationActivityImpact *= 100;
			}
			if (formPatchData.preMitigationImpactVarianceMin === 0.001) {
				formPatchData.preMitigationImpactVarianceMin = 0.1;
			}
			if (formPatchData.preMitigationImpactVarianceMin) {
				formPatchData.preMitigationImpactVarianceMin *= 100;
			}
			if (formPatchData.preMitigationImpactVarianceMax === 0.001) {
				formPatchData.preMitigationImpactVarianceMax = 0.1;
			}
			if (formPatchData.preMitigationImpactVarianceMax) {
				formPatchData.preMitigationImpactVarianceMax *= 100;
			}
			if (formPatchData.postMitigationImpactVarianceMin === 0.001) {
				formPatchData.postMitigationImpactVarianceMin = 0.1;
			}
			if (formPatchData.postMitigationImpactVarianceMin) {
				formPatchData.postMitigationImpactVarianceMin *= 100;
			}
			if (formPatchData.postMitigationImpactVarianceMax === 0.001) {
				formPatchData.postMitigationImpactVarianceMax = 0.1;
			}
			if (formPatchData.postMitigationImpactVarianceMax) {
				formPatchData.postMitigationImpactVarianceMax *= 100;
			}
			if (formPatchData.costVarianceMin) {
				formPatchData.costVarianceMin *= 100;
			}
			if (formPatchData.costVarianceMax) {
				formPatchData.costVarianceMax *= 100;
			}
			if (formPatchData.preMitigationEstCost) {
				formPatchData.preMitigationEstCostDisplay =
					'$' + new Intl.NumberFormat('en-us').format(formPatchData.preMitigationEstCost);
			}
			if (formPatchData.postMitigationEstCost) {
				formPatchData.postMitigationEstCostDisplay =
					'$' + new Intl.NumberFormat('en-us').format(formPatchData.postMitigationEstCost);
			}
			this.form.patchValue(formPatchData);
			this.data = data;
			this.adjustMitigationScores();
		}
		if (changes.hasOwnProperty('editingRiskRegister')) {
			if (this.initialFormVal === null) {
				this.initialFormVal = this.form.getRawValue();
			}
			if (changes.editingRiskRegister.currentValue) {
				this.preMitigationActvImpact = changes.editingRiskRegister.currentValue.preMitigation.activityImpact * 100;
				this.postMitigationActvImpact = changes.editingRiskRegister.currentValue.postMitigation.activityImpact * 100;
				this.form.controls.preMitigationImpactVarianceMin.enable();
				this.form.controls.preMitigationImpactVarianceMax.enable();
				this.form.controls.postMitigationImpactVarianceMin.enable();
				this.form.controls.postMitigationImpactVarianceMax.enable();
				// editing/duplicating should have default tagged selected
				this.tagTypeButtons = [
					{
						text: 'Tagged',
						value: 0,
						selected: this.data?.impactedTaskCodes?.length > 0,
					},
					{
						text: 'Show All',
						value: 1,
						selected: this.data?.impactedTaskCodes?.length === 0,
					},
				];
				if (changes.hasOwnProperty('nextRiskId')) {
					const currentEditingRiskId = changes.editingRiskRegister?.currentValue?.riskId;
					const nextRiskId = changes.nextRiskId?.currentValue;
					// update tagged values in registersByActvCode to include the duplicated register's tagged activities
					if (!!currentEditingRiskId && !!nextRiskId && currentEditingRiskId === nextRiskId) {
						this.data.impactedTaskCodes.forEach((taskCode) => {
							const riskId = this.data.riskId || this.form.getRawValue().riskId || this.nextRiskId;
							const actvRegisters = this.registersByActvCode.get(taskCode) || new Set<number>([]);
							actvRegisters.add(riskId);
							this.registersByActvCode.set(taskCode, actvRegisters);
						});
					}
				}
			}
		}
	}

	public applyFilter(value: CompositeFilterDescriptor): void {
		this.activityList = filterBy(Array.from(this.allActivities.values()), value);

		if (this.showingOnlySelected) {
			this.showSelected(this.showingOnlySelected);
		}
		this.loadActivities();
	}
	public pageChange(event: PageChangeEvent): void {
		this.skip = event.skip;
		this.loadActivities();
	}

	public submitForm(isDraft = false): void {
		this.formTouch.emit(false);
		const formData = this.form.getRawValue();
		const mitigation = {
			category: formData.category,
			riskName: formData.riskName,
			costOwner: {
				name: formData.costOwnerName,
				email: formData.costOwnerEmail,
			},
			description: formData.description,
			effect: formData.effect,
			impactedTaskCodes: formData.impactedTaskCodes,
			mitStatus: formData.mitStatus,
			postMitigation: {
				probability: formData.postMitigationProbability,
				scheduleImpact: formData.postMitigationScheduleImpact,
				costImpact: formData.postMitigationCostImpact,
				performanceImpact: formData.postMitigationPerformanceImpact,
				qualityImpact: formData.postMitigationQualityImpact,
				activityImpact: formData.postMitigationActivityImpact,
				estCost: formData.postMitigationEstCost,
				impactVarianceMin:
					formData.postMitigationActivityImpact === null ? null : formData.postMitigationImpactVarianceMin,
				impactVarianceMax:
					formData.postMitigationActivityImpact === null ? null : formData.postMitigationImpactVarianceMax,
			} as PostMitigationRisk,
			preMitigation: {
				probability: formData.preMitigationProbability,
				scheduleImpact: formData.preMitigationScheduleImpact,
				costImpact: formData.preMitigationCostImpact,
				performanceImpact: formData.preMitigationPerformanceImpact,
				qualityImpact: formData.preMitigationQualityImpact,
				activityImpact: formData.preMitigationActivityImpact,
				estCost: formData.preMitigationEstCost,
				impactVarianceMin:
					formData.preMitigationActivityImpact === null ? null : formData.preMitigationImpactVarianceMin,
				impactVarianceMax:
					formData.preMitigationActivityImpact === null ? null : formData.preMitigationImpactVarianceMax,
			} as PrePostMitigationRisk,
			responsibility: {
				name: formData.responsibilityName,
				email: formData.responsibilityEmail,
			},
			riskId: formData.riskId,
			riskOwner: {
				name: formData.riskOwnerName,
				email: formData.riskOwnerEmail,
			},
			strategy: {
				strategy: formData.strategyStrategy,
				measures: formData.strategyMeasures,
				statusDate: formData.strategyStatusDate,
			},
			triggerPhase: formData.triggerPhase,
			disabled: !!formData.disabled,
			costVarianceMin: formData.costVarianceMin,
			costVarianceMax: formData.costVarianceMax,
			isDraft,
			lastUpdated: new Date(),
		} as RiskRegister;
		this.registerSave.emit(mitigation);
	}

	public clearForm(): void {
		this.form.reset();
	}

	adjustMitigationScores() {
		const preScores = {
			probability: this.form.getRawValue().preMitigationProbability,
			scheduleImpact: this.form.getRawValue().preMitigationScheduleImpact,
			costImpact: this.form.getRawValue().preMitigationCostImpact,
			performanceImpact: this.form.getRawValue().preMitigationPerformanceImpact,
			qualityImpact: this.form.getRawValue().preMitigationQualityImpact,
		};
		const postScores = {
			probability: preScores.probability,
			scheduleImpact: this.form.getRawValue().postMitigationScheduleImpact,
			costImpact: this.form.getRawValue().postMitigationCostImpact,
			performanceImpact: this.form.getRawValue().postMitigationPerformanceImpact,
			qualityImpact: this.form.getRawValue().postMitigationQualityImpact,
		};
		const preMitigationScore =
			preScores.probability *
			Math.max(preScores.qualityImpact, preScores.performanceImpact, preScores.costImpact, preScores.scheduleImpact);
		const postMitigationScore =
			postScores.probability *
			Math.max(
				postScores.qualityImpact,
				postScores.performanceImpact,
				postScores.costImpact,
				postScores.scheduleImpact
			);
		this.preMitigationScore.next(preMitigationScore);
		this.postMitigationScore.next(postMitigationScore);
		this.preMitigationActvImpact = this.form.getRawValue().preMitigationActivityImpact;
		this.preMitigationEstCost = this.form.getRawValue().preMitigationEstCost;
		this.postMitigationActvImpact = this.form.getRawValue().postMitigationActivityImpact;
		this.postMitigationEstCost = this.form.getRawValue().postMitigationEstCost;
		this.updateTabValidity();
	}

	tabChange(modifier: number) {
		this.touchControlsOnTab(this.tabIndex);
		this.tabIndex += modifier;
		this.updateTabValidity();
		const schedulesTabIndex: number = this.project.$currentProjectData.value?.riskMetricsType === 'coreRisk' ? 1 : 4;
		if (
			this.tabIndex === schedulesTabIndex &&
			this.editingRiskRegister !== undefined &&
			this?.data?.impactedTaskCodes?.length > 0
		) {
			this.showSelected(true);
		}
		this.updateRiskHeight();
	}

	public onTabSelect(e: SelectEvent): void {
		this.touchControlsOnTab(this.tabIndex);
		this.tabIndex = e.index;
		this.updateTabValidity();
		const schedulesTabIndex: number = this.project.$currentProjectData.value?.riskMetricsType === 'coreRisk' ? 1 : 4;
		if (
			this.tabIndex === schedulesTabIndex &&
			this.editingRiskRegister !== undefined &&
			this?.data?.impactedTaskCodes?.length > 0
		) {
			this.showSelected(true);
		}
		this.updateRiskHeight();
	}

	public sortChange(sort: SortDescriptor[]): void {
		this.sort = sort;
		this.loadActivities();
	}

	public loadActivities(): void {
		this.gridView = {
			data: orderBy(this.activityList, this.sort).slice(this.skip, this.skip + 10),
			total: this.activityList.length,
		};
	}

	/**
	 * touch controls on previous tab to trigger validators check
	 * @param tabIndex
	 */
	touchControlsOnTab(tabIndex: number): void {
		if (this.editingRiskRegister !== undefined) {
			this.form.markAsDirty();
		}
		switch (tabIndex) {
			case 0: {
				this.form.controls?.riskName.markAsTouched();
				this.form.controls?.riskOwnerName.markAsTouched();
				this.form.controls?.riskOwnerEmail.markAsTouched();
				this.form.controls?.costOwnerName.markAsTouched();
				this.form.controls?.costOwnerEmail.markAsTouched();
				this.form.controls?.responsibilityName.markAsTouched();
				this.form.controls?.responsibilityEmail.markAsTouched();
				this.form.controls?.mitStatus.markAsTouched();
				this.form.controls?.category.markAsTouched();
				this.form.controls?.triggerPhase.markAsTouched();
				this.form.controls?.description.markAsTouched();
				this.form.controls?.effect.markAsTouched();
				break;
			}
			case 1: {
				this.form.controls?.preMitigationActivityImpact.markAsTouched();
				this.form.controls?.preMitigationCostImpact.markAsTouched();
				this.form.controls?.preMitigationEstCost.markAsTouched();
				this.form.controls?.preMitigationPerformanceImpact.markAsTouched();
				this.form.controls?.preMitigationProbability.markAsTouched();
				this.form.controls?.preMitigationQualityImpact.markAsTouched();
				this.form.controls?.preMitigationScheduleImpact.markAsTouched();
				break;
			}
			case 2: {
				this.form.controls?.strategyMeasures.markAsTouched();
				this.form.controls?.strategyStatusDate.markAsTouched();
				this.form.controls?.strategyStrategy.markAsTouched();
				break;
			}
			case 3: {
				this.form.controls?.preMitigationProbability.markAsTouched();
				this.form.controls?.postMitigationActivityImpact.markAsTouched();
				this.form.controls?.postMitigationCostImpact.markAsTouched();
				this.form.controls?.postMitigationEstCost.markAsTouched();
				this.form.controls?.postMitigationPerformanceImpact.markAsTouched();
				this.form.controls?.postMitigationQualityImpact.markAsTouched();
				this.form.controls?.postMitigationScheduleImpact.markAsTouched();
				break;
			}
		}
		this.cd.detectChanges();
	}

	toggleActivity(e: CellClickEvent, code?: string) {
		const formData = this.form.getRawValue();
		const taskCode = e?.dataItem?.task_code || code;
		const riskId = this.data.riskId || this.form.getRawValue().riskId || this.nextRiskId;
		if (e?.type !== 'contextmenu' && taskCode) {
			const impactedTaskCodes = new Set<string>([...this.data.impactedTaskCodes]);
			const actvRegisters = this.registersByActvCode.get(taskCode) || new Set<number>([]);
			if (impactedTaskCodes.has(taskCode)) {
				impactedTaskCodes.delete(taskCode);
				actvRegisters.delete(riskId);
			} else {
				impactedTaskCodes.add(taskCode);
				actvRegisters.add(riskId);
			}
			formData.impactedTaskCodes = Array.from(impactedTaskCodes);
			this.data.impactedTaskCodes = [...formData.impactedTaskCodes];
			this.registersByActvCode.set(taskCode, actvRegisters);
			this.form.setValue(formData);
		}
	}

	selectAllActivities(deselect?: boolean) {
		const actvs = this.activityList;
		const formData = this.form.getRawValue();
		const riskId = this.data.riskId || this.form.getRawValue().riskId || this.nextRiskId;
		const impactedTaskCodes = new Set<string>(this.data.impactedTaskCodes);
		for (const actv of actvs) {
			const taskCode = actv.task_code;
			const actvRegisters = this.registersByActvCode.get(taskCode) || new Set<number>([]);
			if (deselect) {
				actvRegisters.delete(riskId);
				impactedTaskCodes.delete(taskCode);
			} else {
				actvRegisters.add(riskId);
				impactedTaskCodes.add(taskCode);
			}
			this.registersByActvCode.set(taskCode, actvRegisters);
		}
		formData.impactedTaskCodes = Array.from(impactedTaskCodes);
		this.data.impactedTaskCodes = formData.impactedTaskCodes;
		this.form.setValue(formData);
	}

	showSelected(show: boolean) {
		this.showingOnlySelected = show;
		if (this.showingOnlySelected) {
			const selectedCodes = new Set<string>(this.data.impactedTaskCodes || []);
			this.activityList = this.activityList.filter((actv) => selectedCodes.has(actv.task_code));
		} else {
			this.activityList = filterBy(Array.from(this.allActivities.values()), this.filterValue);
		}
		this.loadActivities();
	}

	public editorValueChange(value, currentItem?: FilterDescriptor, useIdFromObj?: boolean): void {
		if (currentItem) {
			currentItem.value = useIdFromObj ? value.actv_code_id.toString() : value;
		}
		this.applyFilter(this.filter.value);
		this.loadActivities();
	}

	onFilterChange(filter: CompositeFilterDescriptor) {
		this.applyFilter(filter);
		this.skip = 0;
		this.loadActivities();
		//filter changes increase window height, so let windowService know to keep the window height synced
		setTimeout(() => {
			const newHeight = document.getElementById('rrFormContainer')?.offsetHeight;
			if (newHeight !== undefined) {
				this.appWindowService.$addRiskHeight.next(newHeight + 84.641);
			}
		});
	}

	/**
	 * update badge show variable per tab by checking controls on that tab for errors
	 */
	updateTabValidity() {
		this.touchControlsOnTab(1);
		this.impactsValid = true;
		this.missingRequiredFields = [false, false, false, false];
		if (
			this.form.controls?.riskName.errors?.required ||
			this.form.controls?.riskOwnerName.errors?.required ||
			this.form.controls?.responsibilityName.errors?.required ||
			this.form.controls?.mitStatus.errors?.required ||
			this.form.controls?.category.errors?.required ||
			this.form.controls?.effect.errors?.required
		) {
			this.missingRequiredFields[0] = true;
		}
		if (
			this.form.controls?.preMitigationProbability.errors?.required ||
			this.form.controls?.preMitigationScheduleImpact.errors?.required ||
			this.form.controls?.preMitigationCostImpact.errors?.required ||
			this.form.controls?.preMitigationPerformanceImpact.errors?.required ||
			this.form.controls?.preMitigationQualityImpact.errors?.required
		) {
			this.missingRequiredFields[1] = true;
		}
		if (
			this.preMitigationActvImpact !== null &&
			this.form.controls?.preMitigationImpactVarianceMin.value !== null &&
			this.form.controls?.preMitigationImpactVarianceMin.value > this.preMitigationActvImpact
		) {
			this.missingRequiredFields[1] = true;
			this.impactsValid = false;
		}
		if (this.form.controls?.strategyStrategy.errors?.required) {
			this.missingRequiredFields[2] = true;
		}
		if (
			this.form.controls?.preMitigationProbability.errors?.required ||
			this.form.controls?.postMitigationScheduleImpact.errors?.required ||
			this.form.controls?.postMitigationCostImpact.errors?.required ||
			this.form.controls?.postMitigationPerformanceImpact.errors?.required ||
			this.form.controls?.postMitigationQualityImpact.errors?.required
		) {
			this.missingRequiredFields[3] = true;
		}
		if (
			this.postMitigationActvImpact !== null &&
			this.form.controls?.postMitigationImpactVarianceMin.value !== null &&
			this.form.controls?.postMitigationImpactVarianceMin.value > this.postMitigationActvImpact
		) {
			this.missingRequiredFields[3] = true;
			this.impactsValid = false;
		}
		this.form.updateValueAndValidity();
	}

	/**
	 * go to 1st tab that has errors
	 */
	goToFirstInvalidTab() {
		for (let i = 0; i < 5; i++) {
			if (this.missingRequiredFields[i]) {
				this.tabIndex = i;
				break;
			}
		}
	}

	/**
	 * determines whether a pasted string is valid for an input
	 * @param ev
	 * @param canBeDecimal
	 */
	testPaste(ev, canBeDecimal = false): boolean {
		const clipboardData = ev.clipboardData;
		const pastedData = clipboardData.getData('Text');
		return canBeDecimal ? /^\d*\.?\d+$/.test(pastedData) : /^\d*$/.test(pastedData);
	}

	/**
	 * determines whether a typed character is numeric (only tested on a US keyboard)
	 * @param keyEvent
	 * @param canBeDecimal
	 */
	testNumericKeypress(keyEvent, canBeDecimal = false): boolean {
		if (
			keyEvent.code === 'Backspace' ||
			keyEvent.code === 'Tab' ||
			keyEvent.code.substring(0, 5) === 'Digit' ||
			keyEvent.code.substring(0, 6) === 'Numpad' ||
			(canBeDecimal === true && keyEvent.key === '.')
		) {
			return true;
		}
		return false;
	}

	/**
	 * buttongroup selection change handler
	 * @param selected
	 * @param btn
	 */
	public selectionChange(selected: boolean, btn: IButton): void {
		btn.selected = selected;
		if (selected) {
			this.showSelected(btn.value === 0);
		}
	}

	columnChooserChange(e: ColumnVisibilityChangeEvent) {
		//this.hiddenColumns.clear();
		for (const col of e.columns) {
			//eslint-disable-next-line
			this.hiddenColumns.add(col['field']);
		}
		//this.registerSave.emit(undefined);
	}

	columnResizeChange(e: Array<ColumnResizeArgs>) {
		//eslint-disable-next-line
		this.widthPreferences[e[0].column['field']] = e[0].newWidth;
		this.saveWidths();
	}

	saveWidths() {
		this.restService
			.post('project/preferences/registerColWidth/' + this.project.$currentProjectPageId.value, {
				data: {
					...this.project.$currentProjectData.value.preferences.riskMitigation.columnWidths,
					scheduleSelector: this.widthPreferences,
				},
			})
			.subscribe((resp) => {});
	}

	/**
	 * updates window service add risk register window height based on tab (for restrict movement purposes)
	 */
	updateRiskHeight(): void {
		setTimeout(() => {
			const rrForm = document.getElementById('rrFormContainer');
			const content = rrForm.parentElement.parentElement;
			this.appWindowService.$addRiskHeight.next(content.getBoundingClientRect()?.height + 45);
		});
	}

	/**
	 * returns true if this tab has invalid controls messages that will increase the height of the window
	 * @param tabIndex
	 */
	heightChangingControlInvalid(tabIndex: number): boolean {
		switch (tabIndex) {
			case 1: {
				if (
					this.form.controls?.preMitigationProbability?.invalid ||
					this.form.controls?.preMitigationScheduleImpact?.invalid ||
					this.form.controls?.preMitigationCostImpact?.invalid ||
					this.form.controls?.preMitigationPerformanceImpact?.invalid ||
					this.form.controls?.preMitigationQualityImpact?.invalid
				) {
					return true;
				}
				break;
			}
			case 3: {
				if (
					this.form.controls?.preMitigationProbability?.invalid ||
					this.form.controls?.postMitigationScheduleImpact?.invalid ||
					this.form.controls?.postMitigationCostImpact?.invalid ||
					this.form.controls?.postMitigationPerformanceImpact?.invalid ||
					this.form.controls?.postMitigationQualityImpact?.invalid
				) {
					return true;
				}
				break;
			}
		}
		return false;
	}

	/**
	 * converts masked input back to a number and rounds it to max 2 fraction digits
	 * @param valueField
	 * @param displayField
	 */
	roundExtraDecimals(valueField: string, displayField): void {
		const field = this.form.getRawValue()[displayField];
		if (field !== undefined) {
			const currentVal = field.replace(/[^0-9.]/g, '');
			const fieldVal = currentVal === '' ? null : Math.round(Number(currentVal) * 100) / 100;
			const displyString = currentVal === '' ? '' : '$' + new Intl.NumberFormat('en-us').format(fieldVal);
			//updates actual value for form
			this.form.controls[valueField].patchValue(fieldVal);
			//updates masked display value
			this.form.controls[displayField].patchValue(displyString);
		}
	}

	updateActvImpact(ev: number, preOrPost: string): void {
		if (preOrPost === 'pre') {
			this.preMitigationActvImpact = ev;
			this.disablePre = ev === null;
		} else {
			this.postMitigationActvImpact = ev;
			this.disablePost = ev === null;
		}
		this.updateTabValidity();
	}
}
