<div class='card regular-container'>
	<kendo-gridlayout
		[rows]="['42.4px', 'calc(100% - 42.2px)']"
		[cols]="['100%']"
		[gap]="{ rows: 0, cols: 0 }"
		class='grid'
		style='page-break-inside: avoid; margin-top: 5px'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' class='card-header-container'>
			<kendo-gridlayout
				[rows]="['42.4px']"
				[cols]="['220px', 'calc(100% - 225px)']"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header">
						Schedule Comparison
					</div>
					<tooltip-open type="schedule-comparison"></tooltip-open>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='toolbar-item-container2'>
					<div class='btngroup-label' style='margin-right: -5px;'>
						Relationships:
					</div>
					<kendo-buttongroup width='100%' selection="single" class='btn-group'>
						<button
							*ngFor="let button of relationshipsButtons"
							kendoButton
							[toggleable]="true"
							[selected]="button.selected"
							(selectedChange)="selectionChange($event, button, true)"
							class='item-btn'
							[class.selected-item-btn]='button.selected'
							[disabled]='button.disabled || totalUpdates <= 1'
						>
							<span>
								{{ button.text }}
							</span>
						</button>
					</kendo-buttongroup>
					<div class='btngroup-label' style='margin-right: -5px;'>
						Activities:
					</div>
					<kendo-buttongroup width='100%' selection="single" class='btn-group'>
						<button
							*ngFor="let button of taskTypeButtons"
							kendoButton
							[toggleable]="true"
							[selected]="button.selected"
							(selectedChange)="selectionChange($event, button)"
							class='item-btn'
							[class.selected-item-btn]='button.selected'
							[disabled]='button.disabled || totalUpdates <= 1'
						>
							<span>
								{{ button.text }}
							</span>
						</button>
					</kendo-buttongroup>
					<kendo-dropdownlist
						[data]="scheduleOptions"
						[value]='scheduleOptions[currUpdateIndex - 1]'
						(selectionChange)='setCurrentIndex($event)'
						textField='text'
						valueField='value'
						style='width: 200px; height: 30px; margin: auto 0;'
						[disabled]='totalUpdates <= 1'
					></kendo-dropdownlist>
					<kendo-dropdownlist
						[data]="actvFilters"
						defaultItem='Show All'
						style='width: 130px; height: 30px; margin: auto 0;'
						(selectionChange)='updateView(isCritical, $event)'
						[itemDisabled]="itemDisabled"
						[disabled]='totalUpdates <= 1'
					></kendo-dropdownlist>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>
		@if (totalUpdates > 1 && !scheduleStorage.isLoading) {
			<kendo-gridlayout-item [row]='2' [col]='1' style='background-color: #e5e5e5; border-radius: 0 0 4px 4px;'>
				<div style='display: flex; flex-direction: row; justify-content: center;'>
					<div class='searchbar-container' style='width: 50%; background-color: white;'>
						<kendo-textbox
							style='width: 100%'
							[placeholder]="'Search Activities...'"
							[clearButton]="true"
							[size]='"large"'
							[(value)]='searchTerm'
							(afterValueChangedStr)='updateSearch($event)'
							[disabled]='loading'
							#schedComparisonSearch
							class="searchInput"
						>
							<ng-template kendoTextBoxPrefixTemplate>
								<kendo-svgicon [icon]='svgSearch'></kendo-svgicon>
								<kendo-textbox-separator></kendo-textbox-separator>
							</ng-template>
						</kendo-textbox>
					</div>
					<div class='sched-comp-legend' style='width: 50%; border-radius: 2.5px; border: 1px solid #ebebeb;'>
						<div class='legend-item-container'>
							<div class='legend-bar' style='background-color: rgba(52, 143, 228, 0.3)'>
								<div class='progress-legend-bar' style='background-color: rgba(52, 143, 228, 0.3)'>
								</div>
							</div>
							<span class='sched-comp-legend-item-label'>
						Activity
					</span>
						</div>
						<div class='legend-item-container'>
							<div class='legend-bar' style='background-color: rgba(223, 83, 83, 0.3)'>
								<div class='progress-legend-bar' style='background-color: #DF5353FF'>
								</div>
							</div>
							<span class='sched-comp-legend-item-label'>
						Critical Activity
					</span>
						</div>
						<div class='legend-item-container'>
							<div class='prev-update-bar'>
							</div>
							<span class='sched-comp-legend-item-label'>
						Previous Update Activity
					</span>
						</div>
					</div>
				</div>
				<ngx-gantt
					#ganttSchedComparison
					*ngIf='!loading'
					[items]="items"
					[linkOptions]='{showArrow: showLines, lineType: GanttLinkLineType.straight}'
					[selectable]='false'
					[baselineItems]='baselineItems'
					[viewOptions]='viewOptions'
					[start]='start'
					[end]='end'
					[toolbarOptions]="toolbarOptions"
					(barClick)='barClick($event)'
					style='height: 500px; border-radius: 4px;'
				>
					<ng-template #bar let-item="item">
						<span style="color: #fff">&nbsp;&nbsp;{{ item.tag }} </span>
					</ng-template>
					<ngx-gantt-table>
						<ngx-gantt-column name='Activity ID' width="130px" *ngIf='items.length'>
							<ng-template #cell let-item="item"> {{ item.title }} </ng-template>
						</ngx-gantt-column>
						<ngx-gantt-column name='Activity Name' width="300px" *ngIf='items.length'>
							<ng-template #cell let-item="item"> {{ item.name }} </ng-template>
						</ngx-gantt-column>
					</ngx-gantt-table>
				</ngx-gantt>
				<!--<div class='sched-comp-legend'>
					<div class='legend-item-container'>
						<div class='legend-bar' style='background-color: rgba(52, 143, 228, 0.3)'>
							<div class='progress-legend-bar' style='background-color: rgba(52, 143, 228, 0.3)'>
							</div>
						</div>
						<span class='sched-comp-legend-item-label'>
							Activity
						</span>
					</div>
					<div class='legend-item-container'>
						<div class='legend-bar' style='background-color: rgba(223, 83, 83, 0.3)'>
							<div class='progress-legend-bar' style='background-color: #DF5353FF'>
							</div>
						</div>
						<span class='sched-comp-legend-item-label'>
							Critical Activity
						</span>
					</div>
					<div class='legend-item-container'>
						<div class='prev-update-bar'>
						</div>
						<span class='sched-comp-legend-item-label'>
							Previous Update Activity
						</span>
					</div>
				</div>-->
			</kendo-gridlayout-item>
		} @else if (!scheduleStorage.isLoading) {
			<kendo-gridlayout-item [row]='2' [col]='1' style='border-radius: 0 0 4px 4px;'>
				<div class='warning-div' style='height: 150px'>
					Schedule Comparison requires at least 1 update to enable
				</div>
			</kendo-gridlayout-item>
		} @else {
			<kendo-gridlayout-item [row]='2' [col]='1' style='border-radius: 0 0 4px 4px;'>
				<div class='warning-div' style='height: 150px'>
					Loading...
				</div>
			</kendo-gridlayout-item>
		}
	</kendo-gridlayout>
</div>
<kendo-window
	title="Please provide additional data"
	*ngIf="tooltipOpen"
	(close)="closeTooltip()"
	[minWidth]="250"
	[resizable]='false'
	[width]="500"
>
	<kendo-window-titlebar>
		<span class="k-window-title">{{tooltipActv.current.task_code}} - {{tooltipActv.current.task_name}}</span>
		<button kendoWindowCloseAction></button>
	</kendo-window-titlebar>
	<kendo-tabstrip (tabSelect)="selectTab($event)" [scrollable]="true">
		<kendo-tabstrip-tab *ngFor="let update of (scheduleStorage.$allUpdates | async); index as i"
												[title]='i === 0 ? "Baseline" : "Update " + i'
												[selected]='currUpdateIndex === i'
												[disabled]='tasksByUpdate.has(update._id) && !tasksByUpdate.get(update._id)?.has(tooltipActv.current.task_code)'
		>
			<ng-template kendoTabContent *ngIf='tasksByUpdate.has(update._id) && tasksByUpdate.get(update._id)?.has(tooltipActv.current.task_code)'>
				<p>Start: {{tooltipActv?.current?.act_start_date ? (tooltipActv.current.act_start_date | date: 'MMM d, yyyy') + ' A' : (tooltipActv.current.early_start_date | date: 'MMM d, yyyy')}}</p>
				<p>Finish: {{tooltipActv?.current?.act_end_date ? (tooltipActv.current.act_end_date | date: 'MMM d, yyyy') + ' A' : (tooltipActv.current.early_end_date | date: 'MMM d, yyyy')}}</p>
				<p>Target Duration: {{tooltipActv.current.target_drtn_hr_cnt}} hrs</p>
				<p>Remaining Duration: {{tooltipActv.current.remain_drtn_hr_cnt}} hrs</p>
				<p>Total Float: {{tooltipActv.current.total_float_hr_cnt || 0}} hrs</p>
			</ng-template>
			<ng-template kendoTabContent *ngIf='tasksByUpdate.has(update._id) && !tasksByUpdate.get(update._id)?.has(tooltipActv.current.task_code)'>
				Activity does not exist in this update
			</ng-template>
			<ng-template kendoTabContent *ngIf='!tasksByUpdate.has(update._id)'>
				Loading...
			</ng-template>
		</kendo-tabstrip-tab>
	</kendo-tabstrip>
</kendo-window>
