<div class='card'>
	<kendo-gridlayout
		[rows]="['42.4px', '403px']"
		[cols]="['382px', 'auto']"
		[gap]="{ rows: 0, cols: 0 }"
		class='full-height'
	>
		<kendo-gridlayout-item [row]='1' [col]='1' [colSpan]="2" class='card-header-container'>
			<kendo-gridlayout
				[cols]="['calc(300px)', 'calc(100% - 305px)',]"
				[gap]="{ rows: 0, cols: 5 }"
				class='full-width'
			>
				<kendo-gridlayout-item [row]='1' [col]='1' class='row-start-aligned-container'>
					<div class="card-header">
						Performance Trending
					</div>
				</kendo-gridlayout-item>
				<kendo-gridlayout-item [row]='1' [col]='2' class='row-start-aligned-container' style='justify-content: flex-end'>
					<div style='position: relative; display: flex; flex-direction: row; justify-content: flex-end; gap: 10px; padding-right: 10px;'>
						<ion-button
							style="font-size: 13px; --background: #2ebb2e; margin: auto 0;"
							(click)="submit()"
							size='small'
							[disabled]="!saveButtonEnabled"
						>
				<span>
					{{ 'Save' }}
				</span>
						</ion-button>
					</div>
				</kendo-gridlayout-item>
			</kendo-gridlayout>
		</kendo-gridlayout-item>

		<kendo-gridlayout-item [row]="2" [col]="1" style='margin: 5px 0 5px 5px; border: 1px solid #ededed; border-radius: 4px;'>
			<app-pf-table-dropdown [fromPerformanceTrendingComponent]="true" (selectedActivityCodesEmitter)="updateSelectedCodes($event)" (cancelEnabledChange)='cancelChange($event)' [saveClicked]='saveClicked'></app-pf-table-dropdown>
		</kendo-gridlayout-item>
		<kendo-gridlayout-item [row]='2' [col]='2'>
			<div style='height: 400px; margin-bottom: 3px'>
				<app-chart
					[categories]='categories'
					[seriesData]='seriesData'
					[legendVisible]='true'
					[valueAxis]='valueAxisItemSettings'
				></app-chart>
			</div>
		</kendo-gridlayout-item>
	</kendo-gridlayout>
</div>
