import { Component, Input, OnInit } from '@angular/core';
import { AxisSettings, SeriesData, SeriesDataSettings } from '../../../../models/ChartSettings';
import { ProjectDashboardService } from '../../../../services/project/project.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { CashFlow } from '../../../../models/ProjectReport/ProjectReport';
import { hasObjChanged } from '../../../../util/projects';
import { CashFlowChartData, CostService } from '../../../../services/common/cost.service';
import { ProjectListItem } from '../../../portfolio/project-list/project-list/project-list.component';
import { PlotBand } from '@progress/kendo-angular-charts';

@Component({
	selector: 'app-cumulative-cash-flow',
	templateUrl: './cumulative-cash-flow.component.html',
	styleUrls: ['./cumulative-cash-flow.component.scss'],
})
export class CumulativeCashFlowComponent implements OnInit {
	private _unsubscribeAll: Subject<void> = new Subject<void>();
	@Input() projects = new BehaviorSubject<Array<ProjectListItem>>(null);
	@Input() loading = false;
	@Input() categoriesOther = [];
	@Input() isPeriod = true;
	categories: string[] = [];
	seriesData: SeriesDataSettings[] = [];
	valueAxisItemSettings: AxisSettings[] = [
		{
			name: '',
			title: {
				text: '',
			},
			labels: {
				format: '',
			},
			min: 0,
		},
	];
	currentMonth: string = '';
	categoryPlotBands: PlotBand[] = [];
	months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	constructor(
		public projectService: ProjectDashboardService,
		public costService: CostService
	) {}

	ngOnInit() {
		this.projectService.$currentProjectReport
			.pipe(takeUntil(this._unsubscribeAll), debounceTime(100))
			.subscribe((report) => {
				if (!report?.cashFlowHistorical) {
					return;
				}
				this.valueAxisItemSettings = [
					{
						name: 'cumulative',
						title: {
							text: 'Cumulative Costs',
						},
						labels: {
							format: 'c0',
						},
						min: 0,
					},
				];
				const currentMonthSplit: string[] = report?.projectOverview?.dataDate.toString().split(' ');
				this.currentMonth = currentMonthSplit?.length > 2 ? currentMonthSplit[1] + ' ' + currentMonthSplit[3] : '';
				this.updateChartData(report?.cashFlowHistorical);
			});

		this.projects.subscribe((projects: ProjectListItem[]) => {
			if (projects !== null) {
				const cashFlowCategoriesInterval = setInterval(() => {
					if (this.categoriesOther.length > 0) {
						this.valueAxisItemSettings = this.isPeriod
							? [
									{
										name: 'periodic',
										title: {
											text: 'Periodic Costs',
											visible: false,
										},
										labels: {
											format: 'portfolio',
										},
										min: 0,
									},
								]
							: [
									{
										name: 'cumulative',
										title: {
											text: 'Cumulative Costs',
											visible: false,
										},
										labels: {
											format: 'portfolio',
										},
										min: 0,
									},
								];
						this.updatePortfolioChartData(projects);
						clearInterval(cashFlowCategoriesInterval);
					}
				}, 500);
			}
		});
	}

	/**
	 * grabs the latest month + 1 and next 5 months worth of cash flow data for the portfolio chart (periodic or cumulative)
	 * @param projects
	 */
	updatePortfolioChartData(projects: ProjectListItem[]): void {
		const earlyPeriodicByCategory = new Map<string, number>([]);
		const avgPeriodicByCategory = new Map<string, number>([]);
		const latePeriodicByCategory = new Map<string, number>([]);
		const firstMonth: string = this.categoriesOther[0];
		let cumulativeEarly: number = 0;
		let cumulativeLate: number = 0;
		projects.forEach((project: ProjectListItem) => {
			const cashFlowData: CashFlowChartData = this.costService.projectCashFlowData.get(project._id);
			if (cashFlowData) {
				cashFlowData.earlyPeriodic.forEach((point: SeriesData) => {
					if (this.categoriesOther.includes(point.category)) {
						const existingEarly = earlyPeriodicByCategory.get(point.category);
						const earlyPeriodic = existingEarly === undefined ? point.value : existingEarly + point.value;
						earlyPeriodicByCategory.set(point.category, earlyPeriodic);
					}
				});
				cashFlowData.avgPeriodic.forEach((point: SeriesData) => {
					if (this.categoriesOther.includes(point.category)) {
						const existingAvg = avgPeriodicByCategory.get(point.category);
						const avgPeriodic = existingAvg === undefined ? point.value : existingAvg + point.value;
						avgPeriodicByCategory.set(point.category, avgPeriodic);
					}
				});
				cashFlowData.latePeriodic.forEach((point: SeriesData) => {
					if (this.categoriesOther.includes(point.category)) {
						const existingLate = latePeriodicByCategory.get(point.category);
						const latePeriodic = existingLate === undefined ? point.value : existingLate + point.value;
						latePeriodicByCategory.set(point.category, latePeriodic);
					}
				});
				const earlyCumulativeAtFirstDate: number = cashFlowData.earlyCumulative.find(
					(v: SeriesData) => v.category === firstMonth
				)?.value;
				cumulativeEarly += earlyCumulativeAtFirstDate === undefined ? 0 : earlyCumulativeAtFirstDate;
				const lateCumulativeAtFirstDate: number = cashFlowData.lateCumulative.find(
					(v: SeriesData) => v.category === firstMonth
				)?.value;
				cumulativeLate += lateCumulativeAtFirstDate === undefined ? 0 : lateCumulativeAtFirstDate;
			}
		});
		const portfolioEarlyPeriodicData: SeriesData[] = [];
		const portfolioAvgPeriodicData: SeriesData[] = [];
		const portfolioLatePeriodicData: SeriesData[] = [];
		const portfolioEarlyCumulativeData: SeriesData[] = [];
		const portfolioAvgCumulativeData: SeriesData[] = [];
		const portfolioLateCumulativeData: SeriesData[] = [];
		const categories: string[] = [];
		let cumulativeAvg: number = (cumulativeEarly + cumulativeLate) / 2;
		this.categoriesOther.forEach((category: string) => {
			const earlyVal: number = earlyPeriodicByCategory.get(category);
			const avgVal: number = avgPeriodicByCategory.get(category);
			const lateVal: number = latePeriodicByCategory.get(category);
			portfolioEarlyPeriodicData.push({
				category: category.split(' ')[0],
				value: earlyVal || null,
			});
			portfolioAvgPeriodicData.push({
				category: category.split(' ')[0],
				value: avgVal || null,
			});
			portfolioLatePeriodicData.push({
				category: category.split(' ')[0],
				value: lateVal || null,
			});
			categories.push(category.split(' ')[0]);
			if (category !== firstMonth && earlyVal) {
				cumulativeEarly += earlyVal;
			}
			if (category !== firstMonth && avgVal) {
				cumulativeAvg += avgVal;
			}
			if (category !== firstMonth && lateVal) {
				cumulativeLate += lateVal;
			}
			portfolioEarlyCumulativeData.push({
				category: category.split(' ')[0],
				value: cumulativeEarly,
			});
			portfolioAvgCumulativeData.push({
				category: category.split(' ')[0],
				value: cumulativeAvg,
			});
			portfolioLateCumulativeData.push({
				category: category.split(' ')[0],
				value: cumulativeLate,
			});
		});
		const seriesData: SeriesDataSettings[] = this.isPeriod
			? [
					{
						type: 'column',
						data: portfolioEarlyPeriodicData,
						name: 'Early',
						visible: true,
						color: 'rgb(95, 202, 70)',
						yAxis: 'periodic',
					},
					{
						type: 'column',
						data: portfolioAvgPeriodicData,
						name: 'Avg',
						visible: true,
						color: 'rgb(255, 181, 0)',
						yAxis: 'periodic',
					},
					{
						type: 'column',
						data: portfolioLatePeriodicData,
						name: 'Late',
						visible: true,
						color: '#DF5353',
						yAxis: 'periodic',
					},
				]
			: [
					{
						type: 'line',
						data: portfolioEarlyCumulativeData,
						name: 'Early',
						visible: true,
						color: 'rgb(95, 202, 70)',
						yAxis: 'cumulative',
					},
					{
						type: 'line',
						data: portfolioAvgCumulativeData,
						name: 'Avg',
						visible: true,
						color: 'rgb(255, 181, 0)',
						yAxis: 'cumulative',
					},
					{
						type: 'line',
						data: portfolioLateCumulativeData,
						name: 'Late',
						visible: true,
						color: '#DF5353',
						yAxis: 'cumulative',
					},
				];
		if (hasObjChanged(this.seriesData, seriesData)) {
			this.seriesData = seriesData;
		}
		if (hasObjChanged(this.categories, categories)) {
			this.categories = categories;
		}
	}

	/**
	 * updates chart values with new ones
	 * @param costs
	 */
	updateChartData(costs: CashFlow[]): void {
		const dateString: string = this.projectService.$currentProjectReport.value?.projectOverview?.dataDate.toString();
		const dateSplit: string[] = dateString.split(' ');
		const dataDate: Date = new Date(
			Number(dateSplit[3]),
			this.months.findIndex((m) => m === dateSplit[1])
		);
		const cashFlowData: CashFlowChartData = this.costService.generateCashFlowData(costs, dataDate);
		if (cashFlowData) {
			const seriesData: SeriesDataSettings[] = [
				{
					type: 'line',
					data: cashFlowData.plannedCumulative,
					name: 'Baseline (Early)',
					visible: true,
					color: 'rgb(138, 138, 138)',
					legendItem: {
						type: 'line',
						markers: {
							visible: false,
						},
						highlight: {
							visible: false,
						},
					},
				},
				{
					type: 'line',
					data: cashFlowData.plannedCumulativeLate,
					name: 'Baseline (Late)',
					visible: true,
					color: 'rgba(0, 0, 0, .75)',
					legendItem: {
						type: 'line',
						markers: {
							visible: false,
						},
						highlight: {
							visible: false,
						},
					},
				},
				{
					type: 'line',
					data: cashFlowData.actCumulative,
					name: 'Actual',
					visible: true,
					color: 'rgb(0, 89, 255)',
					legendItem: {
						type: 'line',
						markers: {
							visible: false,
						},
						highlight: {
							visible: false,
						},
					},
				},
				{
					type: 'line',
					data: cashFlowData.forecastEarly,
					name: 'Forecast Early',
					visible: true,
					color: 'rgb(95, 202, 70)',
					legendItem: {
						type: 'line',
						markers: {
							visible: false,
						},
						highlight: {
							visible: false,
						},
						line: {
							dashType: 'dash',
						},
					},
					dashType: 'dot',
				},
				{
					type: 'line',
					data: cashFlowData.forecastAvg,
					name: 'Forecast Avg',
					visible: true,
					color: 'rgb(255, 181, 0)',
					legendItem: {
						type: 'line',
						markers: {
							visible: false,
						},
						highlight: {
							visible: false,
						},
						line: {
							dashType: 'dash',
						},
					},
					dashType: 'dot',
				},
				{
					type: 'line',
					data: cashFlowData.forecastLate,
					name: 'Forecast Late',
					visible: true,
					color: '#DF5353',
					legendItem: {
						type: 'line',
						markers: {
							visible: false,
						},
						highlight: {
							visible: false,
						},
						line: {
							dashType: 'dash',
						},
					},
					dashType: 'dot',
				},
			];
			if (hasObjChanged(this.seriesData, seriesData)) {
				this.seriesData = seriesData;
			}
			if (hasObjChanged(cashFlowData.categories, this.categories)) {
				this.categories = cashFlowData.categories;
			}
			const plotBands: PlotBand[] = [];
			if (this.currentMonth !== '') {
				const index: number = this.categories.findIndex((i) => i === this.currentMonth);
				if (index !== -1) {
					plotBands.push({
						color: '#c8ddeb',
						from: index,
						opacity: 1,
					});
				}
			}
			this.categoryPlotBands = plotBands;
		}
	}
}
